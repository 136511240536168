@function vw($inner,$outer){
	@return ($inner / $outer) * 100 + vw;
}

//line-height calc
@function lh-calc($height,$fontSize){
	@return ($height / $fontSize);
}

// letter-spacing calc
@function lp-calc($space){
	@return ($space / 1000) + em;
}

@mixin p-init{
    padding-left:0;
    padding-right:0;
}
@mixin m-init{
    margin-left:0;
    margin-right:0;
}
@mixin center{
    margin-left:auto;
    margin-right:auto;
}
@mixin ropa{
    font-family: 'Ropa Sans', sans-serif;
}



$breakpoints: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl:1600px
);
@mixin queries( $key ) {
    @media ( min-width: map-get( $breakpoints, $key )) {
      @content;
    }
  }

  .d-pc{
    display:none;
    @include queries(md){
        display:block;
    }
}
.d-sp{
    @include queries(lg){
        display:none;
    }
}
body.now-future{
    font-family: $font-noto,sans-serif;

    $dir: '../img/lp/now-future';
    $future-black: #1A1311;
    $future-pink1:  #F17DB2;
    $future-pink2:  #F48E8C;
    $future-orange:#EE8122;
    $future-blue:  #7FADD8;
    $future-green: #8CBD97;


    .inner-link{
        color:$future-black;
        text-decoration: none;
    }
    .inner-link:hover{
        opacity:1;

        img{
            opacity:1;
        }
    }


    .nf-hero{
        width:100vw;
        height:0;
        padding-top:vw(450,375);
        background-image:url($dir + '/bg-item-sp_01.jpg');
        background-position: top 0 left 0;
        background-size:cover;
        background-repeat: no-repeat;
        position:relative;

        @include queries(md){
            padding-top:vw(1376,3000);
            background-image:url($dir + '/bg-item-pc_01.jpg'); 
            // background-position: center;
        }
    
    }
    .nf-hero-star{
        img{
            position:absolute;
        }
        img:nth-of-type(1){
            width:vw(22.39,375);
            height:vw(28.59,375);
            top:vw(11.5,375);
            left:vw(13.6,375);
            max-width: 33.86px;
            max-height: 43.25px;
            @include queries(md){
                top:vw(138.7,1500);
                left:vw(71.1,1500);
            }
            @include queries(xl){
                top:138.7px;
                left:71.1px;
            }
        }
        img:nth-of-type(2){
            width:vw(17.74,375);
            height:vw(22.77,375);
            bottom:vw(99.9,375);
            left:vw(18.3,375);
            @include queries(md){
                max-width: 24.13px;
                max-height: 27.08px;
                top:vw(255.8,1500);
                left:vw(337.7,1500);
                bottom:auto;
            }
            @include queries(xl){
                top:255.8px;
                left:367.7px;
            }
            @include queries(xxl){
                top:207.8px;
            }
        }
        img:nth-of-type(3){
            width:vw(21.26,375);
            height:vw(22.37,375);
            bottom:vw(23.1,375);
            left:vw(30.3,375);
            max-width: 32.16px;
            max-height:33.84px;
            @include queries(md){
                bottom:vw(48.1,1500);
                left:vw(47.6,1500);
            }
            @include queries(xl){
                bottom:48.1px;
                left:47.6px;
            }
        }
        img:nth-of-type(4){
            width:vw(15.95,375);
            height:vw(17.9,375);
            top:vw(198.1,375);
            left:vw(219.9,375);
            max-width: 26.84px;
            max-height:34.44px;
            @include queries(md){
                top:vw(147.5,1500);
                left:vw(594.4,1500);
            }
            @include queries(xl){
                top:147.5px;
                left:594.4px;
            }

        }
        img:nth-of-type(5){
            width:vw(17.74,375);
            height:vw(22.77,375);
            top:vw(28.7,375);
            right:vw(7.3,375);
            max-width: 26.84px;
            max-height:34.44px;
            @include queries(md){
                top:vw(297.5,1500);
                right:vw(625.7,1500);
            }
            @include queries(xl){
                top:297.5px;
                right:625.7px;
            }
        }
        img:nth-of-type(6){
            width:vw(15.95,375);
            height:vw(17.9,375);
            bottom:vw(52,375);
            right:vw(15.1,375);
            max-width: 26.84px;
            max-height:34.44px;
            @include queries(md){
                top:vw(164.7,1500);
                bottom:auto;
                right:vw(367.5,1500);
            }
            @include queries(xl){
                top:164.7px;
                right:367.5px;
            }
            
        }
        img:nth-of-type(7){
            width:vw(15.95,375);
            height:vw(17.9,375);
            bottom:vw(52,375);
            right:vw(15.1,375);
            max-width: 26.84px;
            max-height:34.44px;
            @include queries(md){
                top:vw(221.4,1500);
                bottom:auto;
                right:vw(84.7,1500);
            }
            @include queries(xl){
                top:221.4px;
                right:84.7px;
            }
            
        }
    }
    .mf-hero-heading2{
        width:vw(325,375);
        bottom:vw(55.1,375);
        position:absolute;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;

        @include queries(md){
            width:vw(858.85,1500); 
            max-width:858.85px;
            bottom:65px;
        }
    }
    .nf-weare{
        width:100vw;
        background-color:$orange;
        text-align: center;
    }
    .nf-weare_heading3{
        & > img{
                width:100%;
                max-width: 304px;
            @include queries(md){
                width:vw(445.24,1200);
                max-width:445.24px;
            }
        }
        width:100vw;
        border-bottom:1px solid $future-black;
        padding-top:vw(48.5,375);
        margin-bottom:0;
        text-align: center;
        padding-bottom:15px;
        @include queries(md){
            width:vw(557.88,1200);
            max-width:557.88px;
            @include center;
            padding-top:70.6px;
            padding-bottom:21.5px;
        }
        
    }
    .nf-weare_text{
        font-size:rem(15);
        line-height:lh-calc(31,15);
        text-align: center;
        margin-top:vw(15,375);
        padding-bottom:vw(49,375);
        @include queries(md){
            margin-top:28.8px;
            font-size:rem(18);
            line-height:lh-calc(40,18);
            padding-bottom:56.4px;
        }
    }
    .nf-members{
        width:100vw;
    
        .nf-members-inner{
            width:100vw;
            max-width:1140px;
            padding:0;
        }
    }
    .nf-members_heading2{
        width:vw(73,375);
        margin-left:auto;
        margin-right:auto;
        margin-top:vw(40,375);
        @include queries(md){
            width:123px; 
            height:74px;
            margin-top:70px;
        }

    }
    .nf-member{
        @include queries(lg){
            margin-top:50px;
        }
    }
    .nf-members_heading2-future{
        width:vw(103,375);
        margin-top:vw(60,375);
        @include queries(md){
            width:123px; 
            height:74px;
            margin-top:75px;
        }
        @include queries(lg){
            width:150px; 
            height:61px;
            margin-top:70px;
        }
    }
    .nf-members-range,
    .nf-now-members-range{
        @include p-init;
        margin-top:15px;
    }

    .nf-members-range:nth-of-type(1){
        margin-top:0px;
    }
    .nf-members-range:nth-of-type(2){
        @include queries(md){
            margin-top:0px;
        }
    }
    // @media(min-width:1025px){
    //     .nf-members-range:nth-of-type(3){
    //         margin-top:0px;
        
    //     }
    // }
    // .nf-members-range:nth-of-type(3){
    //     @include queries(lg){
    //         margin-top:0px;
    //     }
    // }

    @media(min-width:1025px){
        .nf-members-range:nth-of-type(3){
            margin-top:0px;
        }
    }

    .nf-members-range::after{
        content:'';
        display:block;
        width:95vw;
        height:4px;
        background-image:url($dir + '/img-item-dotted-yellow.png');
        background-repeat: repeat-x;
        background-size: 9px;
        background-position: top 0 left 0;
        margin-top:16px;
        @include center;
        @include queries(md){
            width:95%;
            @include center;
        }
    
    }
    .nf-members-range:nth-of-type(1)::before,
    .nf-members-range:nth-of-type(2)::before,
    .nf-members-range:nth-of-type(3)::before{
        content:'';
        display:block;
        width:95vw;
        height:4px;
        @include center;
        background-image:url($dir + '/img-item-dotted-yellow.png');
        background-repeat: repeat-x;
        background-size: 9px;
        background-position: top 0 left 0;
        margin-top:16px;
        margin-bottom:15px;
        @include queries(md){
            width:95%;
            @include center;
        }

        @include queries(lg){
            margin-top:0px;
        }
    }
    .nf-members-range:nth-of-type(2)::before{
        display:none;
        @include queries(md){
            display:block;
            
        }
    }
    .nf-members-range:nth-of-type(3)::before{
        display:none;
        // @include queries(lg){
        //     display:block;
            
        // }
    }
    @media(min-width:1025px){
        .nf-members-range:nth-of-type(3)::before{
            display:block;
        
        }
    }

    .nf-member-future{
        .nf-members-range::after{
            background-image:url($dir + '/img-item-dotted-blue.png');
        }
        .nf-members-range:nth-of-type(1)::before,
        .nf-members-range:nth-of-type(2)::before,
        .nf-members-range:nth-of-type(3)::before{
            background-image:url($dir + '/img-item-dotted-blue.png');
        }
        .nf-members-range:nth-of-type(2)::before{
            display:none;
            @include queries(md){
                display:block;   
            }
        }
        .nf-members-range:nth-of-type(3)::before{
            display:none;
            // @include queries(lg){
            //     display:block; 
            // }
        }
        @media(min-width:1025px){
            .nf-members-range:nth-of-type(3)::before{
                display:block;
            
            }
        }
    }
    .nf-members-range-row{
        width:96vw;
    
        @include center;
        @include queries(xs){
            width:86vw;
        }
        @include queries(xl){
            max-width:365px;
        }
    
    }
    .nf-members_img{
        flex: 0 0 30%;
        text-align: right;
        @include p-init;

        @include queries(md){
            flex: 0 0 16%;
            // padding-right:20px;
            text-align: left;
        }
        // @include queries(lg){
        //     flex: 0 0 16%;
        //     text-align: right;
        // }
        @include queries(xl){
            flex: 0 0 30%;
            text-align: left;
        }
        img{
            max-width:93px;
        }

    }
    @media(min-width:1025px){
        .nf-members_img{
            flex: 0 0 12%;
            text-align: right;
        }
    }
    .nf-member-personal {
        flex: 0 0 65%;
        @include p-init;
        max-width: 100vw;
        @include queries(xs){
            flex: 0 0 70%;
        }
        @include queries(md){
            flex: 0 0 39%;
            padding-left:20px;
        }
        // @include queries(lg){
        //     flex: 0 0 25%;
        //     padding-left:20px;
        // }
        @include queries(xl){
            flex: 0 0 70% !important;
        }
    }

    @media(min-width:1025px){
        .nf-member-personal {
            flex: 0 0 27%;
            padding-left:20px;
        }
    }
    .nf-members_text{
        font-size:rem(13);
        line-height:lh-calc(20,13);
        letter-spacing:lp-calc(50);
        margin-top:5px;

    }
    .nf-members-name_text{
        margin-bottom:0;
        margin-top:20px;
        font-weight:500;
        font-family: 'Noto Sans JP';
        & > span{
            font-size:rem(13);
            margin-left:3px;
        }
    }
    .nf-now-bg{
        width:100vw;
        margin-top:0px;
        background-image:url($dir + '/bg-item_01.jpg');
        background-repeat: repeat;
        background-position: top 0 left 0;
        background-size: contain;
        position:relative;
        
    }

    .nf-future-bg{
        background-image:url($dir + '/bg-item_02.jpg'); 
    }
    .nf-now-bg:nth-of-type(1){
        margin-top:79px;
        @include queries(md){
            margin-top:107px; 
        }
    
        
    }
    .nf-now-bg::before{
        content:'';
        display:block;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color:rgba(#ffffff, 0.93);
        
    }
    .nf-now-heading2{
        padding-top:33px;
        padding-left:vw(25,375);
        font-weight:400;
        @include ropa;
        font-size:rem(27);
        line-height:lh-calc(28,27);
        text-shadow: 3px 3px 6px rgba(#000,0.3);
        display:inline-block;
        position:relative;

        @include queries(md){
            width:100vw;
            display:block;
            font-size:rem(48);
            margin-left:auto;
            padding-right:40px;
            transform: translateY(90px);
            
        }
        @include queries(lg){
            width:100vw;
            max-width:1280px;
            @include center;
            text-align: right;
            // margin-right:vw(90,1500);
        }
        @include queries(xl){
            font-size:rem(70);
            line-height:lh-calc(74,70);
        }
    }




    .nf-future-bg{
        .nf-now-heading2{
            padding-top:52.5px;
            letter-spacing:lp-calc(0);
            
        }
    }


    .nf-now-vertical_text{
        -ms-writing-mode: tb-rl;  //ベンダープレフィックス
        writing-mode: vertical-rl;
        position:absolute;
        width:vw(72,375);
        font-size:rem(20);
        letter-spacing:lp-calc(22);
        font-family:$font-tategaki;
        top:0;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;

        @include queries(sm){
            top:vw(50,373);
        
        }
        @include queries(md){
            top:vw(293,1500);
            font-size:rem(20);
            letter-spacing:lp-calc(27);
            margin-left:auto;
        }
        @media(min-width:860px){
            font-size:rem(25);
        }
        
        @include queries(xl){
            top:373px;
            font-size:rem(30);
            letter-spacing:lp-calc(32);
            width:115px;
            top:333px;
            left:115px;
            right:auto;
            margin-left:0;
            margin-right:0;
        }

        span{
            background-color:rgba($future-pink1,0.95);
            color:$white;
            // opacity: 0.9;
            display: inline-block;
            padding-top:10px;
            padding-bottom:10px;
        }
        span.now-vertical_text01{
            margin-left:12px;
        }
        span.now-vertical_text02{
            margin-top:45px;
            @include queries(md){
                margin-top:25px; 
            }
        }
    }


    .nf-now-bg:nth-of-type(even){
        .nf-now-heading2{
            @include queries(md){
                margin-right:auto;
                margin-left:0;
                padding-left:20px;
                text-align: left;
                
            }
            @include queries(lg){
                text-align: left;
            }
            @include queries(xl){
                text-align: left;
                @include center;
                padding-left:0px;
            }
        }

            .nf-now-vertical_text{
                @include queries(md){
                    margin-left:0;
                }
                @include queries(xl){
                    right:115px;
                    left:auto;
                }
                // @include queries(xl){
                //     right:115px;
                // }
            }
            .nf-now-members-range{

                @include queries(xl){
                    // margin-left:50px;
                }
            }
        

        
    }

    #future01,#future03,#future05{

        @include queries(md){
            text-align: right;
            position: relative;
            padding-right:60px;
        }
        @include queries(lg){
            padding-right:50px;
        }
        @include queries(xxl){
            padding-right:30px;
        }
    }
    #future02,#future04{
    
        @include queries(md){
            text-align: left ;
            position: relative;
            padding-left:20px;
        }
        @include queries(xxl){
            padding-left:0px;
        }
        
    }
    .nf-now-bg:nth-of-type(odd){
        .nf-now-heading2{
            @include queries(md){
                margin-left:auto;
                padding-right:60px;
                text-align: right;
                
            }
        }


        
    }
    .nf-future-bg02,.nf-future-bg04{
        .nf-now-heading2{
            @include queries(md){
                margin-right:0;
                margin-left:0;
                padding-left:40px;
                text-align: left;
                
            }
            @include queries(xl){
                // width:470px;
                // margin-left:90px;
                width: 100vw;
                max-width: 1280px;
                margin-left: auto;
                text-align: left;
                padding-left:0;
                
            }
        }
        .nf-now-vertical{
            .nf-now-vertical_text{
            
                @include queries(md){
                    margin-left:0;
                }
                @include queries(xl){
                    right:115px;
                    left:auto;
                }
            }
        }


            .nf-now-members-range{
                @include queries(xl){
                    margin-left:50px;
                }
            }
        
    }
    .nf-future-bg01,.nf-future-bg03,.nf-future-bg05{
        .nf-now-heading2{
            @include queries(md){
                margin-left:auto;
                margin-right:0;
                
                padding-right:60px;
                text-align:right !important;
                
            }
            @include queries(xl){
                // width:470px;
                // margin-right:90px;
                width: 100vw;
                max-width: 1280px;
                margin-right: auto;
                text-align: right;
                
            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
                @include queries(md){
                    margin-left:auto;
                }
                @include queries(xxl){
                    left:115px;
                    right:auto;
                }
            }
        }

            .nf-now-members-range{
                @include queries(xl){
                    // margin-left:0px;
                }
            }
        
    }



    .nf-now-members-range{
        margin-top:130px;
        
        flex: 0 0 95%;
        @include center;
        @include queries(xs){
            margin-top:100px;
        }
        @include queries(sm){
            margin-top:150px;
        }
    
        @include queries(md){
            margin-top:100px;
            max-width:100%;
            margin-left:0;
        }
        @media(min-width:860px){
            margin-top:20px;
        }
        @include queries(xl){
            margin-top:100px;
        }
        .now-member-range{
            @include center;
            .col-7{
                flex: 0 0 60.33333%;
                max-width:60.33333%; 

                // @include queries(xl){
                //     padding-top:12px;
                //     padding-bottom:10px;
                // }
            }
            .col-5{
                flex: 0 0 39.66667%;
                max-width:39.66667%;
                
                // @include queries(xl){
                //     padding-top:12px;
                //     padding-bottom:12px;
                // }
            }
        }

        .member-range{
            background-image : linear-gradient(to right, $future-black, $future-black 2px, transparent 2px, transparent 6px),
            linear-gradient(to left, $future-black, $future-black 2px, transparent 2px, transparent 6px);  /* 幅2の線を作る */
            background-size: 6px 1px, 6px 1px ;         /* グラデーションの幅・高さを指定 */
            background-position: left top, left bottom ;  /* 背景の開始位置を指定 */
            background-repeat: repeat-x,repeat-x ;       /* 横向きにのみ繰り返す */
            // border-top:1px dashed $future-black;
            // border-bottom:1px dashed $future-black;
            @include p-init;
            padding-left:2px;

        }
    }
    .nf-now-members-name_text{
        font-size:rem(15);
        line-height: lh-calc(18,15);
        letter-spacing: lp-calc(100);
        margin-bottom:0;
        @include queries(md){
            padding-top:0px; 
        }
        span{
            font-size:rem(13);
        }

        span.font-22-ropa{
            @include ropa;
            font-size:rem(22);
            @include queries(md){
                font-size:rem(25); 
            }
        }
    }
    .nf-now-members_img_text {
        margin-bottom:0;
        font-size:rem(15);
        line-height:lh-calc(18,15);
        font-family: 'Noto Sans JP';
        @include queries(xl){
            font-size:rem(17);
            line-height:lh-calc(20,17);
            letter-spacing:lp-calc(100);
        }

        span{
            font-size:rem(12);
            @include queries(xl){
                font-size:rem(14);
            }
        }
    }
    .nf-now-members_text{
        font-size:rem(11);
        line-height: lh-calc(13,11);
        margin-top:24px;
        margin-bottom:0;
        @include queries(xl){
            font-size:rem(12);
            line-height: lh-calc(14,12);
        }
    }
    .nf-future-bg {
        .nf-now-members_text{
            margin-top:12px;
            line-height: lh-calc(18,12);
        }  
    }
    .nf-now-members_img{
        text-align: right;
        @include p-init;
        img{
            max-width:123px;
            @include queries(md){
                max-width:143px;
            }
            @include queries(xl){
                max-width:143px;
            }
        }
    }

    .nf-now-members-items{
        margin-top:35px;
        @include p-init;
        flex: 0 0 90%;
        @include center;
        @include queries(sm){
            flex: 0 0 86%;
        }
        @include queries(md){
            margin-top:vw(268,1500);
            flex: 0 0 52%;
            margin-left:15px;
        }
        @include queries(lg){
            flex: 0 0 53%;
        }
        @include queries(xl){
            margin-top:258px;
            flex: 0 0 61% !important;
            margin-left:0;
            margin-right:0;
        }
    }
    .nf-now-members-col{
        @include p-init;
        position:relative;
        padding-bottom:40px;
        
    }


    .vertical-item_text{
        position:absolute;
        top:15px;
        // left:vw(20,375);
        right:auto;
        bottom:auto;
        font-size:rem(20);

        @include queries(md){
            top:0px;
            font-size:rem(16);
        }
        @media(min-width:860px){
            font-size:rem(16);
        }
        @include queries(xl){
            font-size:rem(20);
            top:0px;
            
        }
        .members-item_text02{
            margin-top:20px;
        }
    }

    .nf-now_text{
        font-size:rem(11);
        line-height: lh-calc(20,11);
        letter-spacing: lp-calc(100);
        margin-top:20px;

    }
    .nf-now-members-col:nth-of-type(odd){

        @include queries(md){
            flex: 0 0 47%;
            max-width: 47%;
        }
        @include queries(xl){
            flex: 0 0 50%;
            max-width: 325px;
        }
    
        .vertical-item_text{
            left:vw(20,375);
            right:auto;
            width:70px;
            @include queries(sm){
                left:0;
                width:90px;
            }
            @include queries(md){
                top:20px;
                left:5px;
                right:auto; 
                width:auto;
            }
            @include queries(xl){
                left:20px;
                right:auto;
            }
        }
        .nf-now_text{
            margin-left:95px;
            margin-right:0;
            
            @include queries(xs){
                margin-left:115px;
            }

            @include queries(md){
                left:auto;
                right:0; 
                width:auto;
                margin-left:75px;
            }
            @include queries(xl){
                margin-left:120px;
            }
        }
    }
    .nf-now-members-col:nth-of-type(even){

        @include queries(md){
            flex: 0 0 47%;
            max-width: 47%;
            margin-left:15px;
        }
        @include queries(xl){
            flex: 0 0 50%;
            max-width: 325px;
            margin-left:45px;
        }
        .vertical-item_text{
            top:15px;
            right:5px;
            left:auto;
            @include queries(xs){
                right:vw(20,375);
            }

            @include queries(md){
                top:20px;
                left:auto;
                right:5px; 
                width:auto;
            }
            @include queries(xl){
                right:20px;
            }
        }
        .nf-now_text{
            margin-left:0px;
            margin-right:95px;

            @include queries(xs){
                margin-right:115px;
            }
            
            
            @include queries(md){
                left:0;
                right:auto; 
                width:auto;
                margin-right:75px;
            }
            @include queries(xl){
                margin-right:120px;
            }

        }
    }

    @mixin bg_now{
        width:100vw;
        max-width:100%;
        height:auto;
        
        background-repeat: no-repeat;
        background-position: top 0px left 0;
        background-size: contain;
        position:relative;
        margin-top:-40px;
        @include p-init;
        @include queries(md){
            width:100vw;
            max-width:100vw;
            background-size: vw(784 + 70,1500);
            @include m-init;
        }
        @include queries(lg){
            margin-top:-80px;
    
        }
        @include queries(xxl){
            max-width:1600px;
            @include center;
            background-size: 784px;
            // background-position: top 0px left 0px;
    
        }

        .row{
            max-width:1200px;
            @include center;
        }
    }
    .bg_now_content{

        padding-top:70%;
    

        @include queries(lg){
            flex: 0 0 47%;
            max-width:47%;
        }
        @include queries(lg){
            flex: 0 0 43%;
            max-width:43%;
        }
        @include queries(xl){
            flex: 0 0 39%;
            max-width: 39%;
            padding-left:0;
            padding-right:0;
        }
    }

    // .nf-now-bg{
    //     .nf-now-inner{
            // @include bg_now;
    //     }
    // }
    // .nf-future-bg{
    //     .nf-now-inner{
    //         @include queries(xl){
    //             background-position: top 0px right 0px;
    //         }
    //     }
    // }
    .bg_now01{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_04.png');
        
        
        @include queries(md){
            background-image:url($dir + '/bg-item-pc_04.png');
            background-position: top 0px left -15px;
        }
        @include queries(xl){
            background-position: top 0px left -55px;
        }
        @include queries(xxl){
            background-position: top 0px left 0px;
        }

        .nf-now-members-name_text{
            margin-top:11px;
            @include queries(xl){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:23px;
            @include queries(md){
                margin-top:28px;
            }
            @include queries(xl){
                margin-top:25px;
            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
            
                @include queries(xl){
                    // left:155px;
                    left:185px;
                }
                @include queries(xxl){
                    top:310px;
                    left:115px;
                }

            }
        }
        
        .nf-now-members-range{
            
            @include queries(md){
                margin-left:0px;
            }
            @media(min-width:820px){
                // margin-left:20px;
            }
            @include queries(xl){
                margin-top:100px;
                // margin-left:-35px;
            }
            @include queries(xxl){
                margin-left:-35px;
            }
        }
    
    
    }
    .bg_now02{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_05.png');
        @include queries(md){
            background-image:url($dir + '/bg-item-pc_05.png');
            background-position: top 0px right -15px;
        }
        @include queries(lg){
            background-position: top 0px right -35px;
        }
        @include queries(xl){
            background-position: top 0px right -55px;
        }
        @include queries(xxl){
            background-position: top 0px right 0px;
        }

        .nf-now-members-name_text{
            margin-top:9px;
            @include queries(md){
                margin-top:11px;
            }
            @include queries(xl){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:13px;
            @include queries(md){
                margin-top:22px;
            }
            @include queries(xl){
                margin-top:19px;
            }
        }

        // .nf-now-members-items{
            // margin-left:0;
        // }
        // .nf-now-members{
        //     margin-left:0;
        // }
        // .bg_now_content{
        //     @include p-init;
        // }

        .nf-now-vertical_text{
        
            span{
                background-color:rgba($future-orange,0.95); 
            }
            span.now-vertical_text02{
                margin-top:65px;
                @include queries(xl){
                    margin-top:35px;
                }
            }
        }
            .nf-now-vertical{
                .nf-now-vertical_text{
                    @include queries(xl){
                        top:290px;
                        right:145px;
                    }
                    @include queries(xxl){
                        top:260px;
                        right:115px;
                    }
                }
            }
        // .nf-now-members-col{
        //     .nf-now-vertical_text{
        //         top:15px;
        //     }
        // }

        .nf-now-members-range{
            margin-top:170px;
            @include queries(xs){
                margin-top:135px;
            }
            @include queries(md){
                margin-left:-40px;
            }
            @media(min-width:860px){
                margin-top:50px;
                // margin-left:20px;
            }
            @include queries(lg){
            
                margin-left:-10px;
            }
            @include queries(xl){
                margin-top:95px;
                margin-left:30px;
            }
            @include queries(xxl){
                margin-left:55px;
            }
        }

        
    
    }
    .bg_now03{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_06.png');



        @include queries(md){
            background-image:url($dir + '/bg-item-pc_06.png');
            background-position: top 0px left -15px;
        }
        @include queries(lg){
            background-position: top 0px left -35px;
        }
        @include queries(xl){
            background-position: top 0px left -55px;
        }
        @include queries(xxl){
            background-position: top 0px left 0px;
        }


        .nf-now-members-name_text{
            margin-top:11px;
            
            @include queries(xl){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:24px;
            @include queries(md){
                margin-top:32px;
            }
            @include queries(xl){
                margin-top:30px;
            }
        }

        
        .nf-now-vertical_text{
            @include queries(xl){
                top:330px;
            }
            @include queries(xxl){
                top:300px;
            }
            top:10px;
            @include queries(xs){
                top:0px;
            }
            @include queries(sm){
                top:100px;
            }
            @include queries(md){
                top:140px;
            }
            span{
                background-color:rgba($future-blue,0.95); 
                // margin-top:20px;
            }
            span.now-vertical_text02{
                margin-top:25px;
            }
            .nf-now-members-col:nth-of-type(odd){
                span.members-item_text01{
                    margin-top:20px;
                }
                span.members-item_text02{
                    margin-top:45px;
                }

            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
            
                @include queries(xl){
                    // left:155px;
                    left:185px;
                }
                @include queries(xxl){
                    // top:310px;
                    left:115px;
                }

            }
        }
    
        .nf-now-members-range{
            margin-top:140px;
            @include queries(xs){
                margin-top:130px;
            }
            @include queries(md){
                margin-top:80px;
                margin-left:0px;
            }
            @media(min-width:860px){
                margin-top:-20px;
                // margin-left:20px;
            }
            @include queries(xl){
                margin-top:95px;
            }
            @include queries(xxl){
                margin-left:-35px;
            }
        }
    

    
    }
    .bg_now04{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_07.png');

        @include queries(md){
            background-image:url($dir + '/bg-item-pc_07.png');
            background-position: top 0px right -35px;
        }
        @include queries(xl){
            background-position: top 0px right -55px;
        }
        @include queries(xxl){
            background-position: top 0px right 0px;
        }


        .nf-now-members-name_text{
            margin-top:11px;
            @include queries(xl){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:24px;
            @include queries(md){
                margin-top:32px;
            }
            @include queries(xl){
                margin-top:30px;
            }
        }


            .nf-now-vertical_text{
                span{
                    background-color:rgba($future-pink2,0.95); 
                }

                span.now-vertical_text02{
                    margin-top:60px;
                    @include queries(md){
                        margin-top:25px;
                    }
                }

            }

        .nf-now-vertical{
            .nf-now-vertical_text{
                @include queries(xl){
                    top:290px;
                    right:145px;
                }
                @include queries(xxl){
                    top:260px;
                    right:115px;
                }
            }
        }

        .nf-now-members-range{
            margin-top:170px;
            @include queries(xs){
                margin-top:120px;
            }
            @include queries(sm){
                margin-top:140px;
            }
            @include queries(md){
                margin-top:50px;
                margin-left:-40px;
            }
            @media(min-width:860px){
                margin-top:80px;
                
            }
            @include queries(lg){
                margin-top:0px;
                margin-left: -10px;
            
            }
            @include queries(xl){
                margin-top:80px;
                margin-left:30px;
            }
            @include queries(xxl){
                margin-left:55px;
            }
        }

    
    
    }
    .bg_now05{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_08.png');
        
        @include queries(md){
            background-image:url($dir + '/bg-item-pc_08.png');
            background-position: top 0px left -15px;
            padding-bottom:98px;
        }
        @include queries(lg){
            background-position: top 0px left -35px;
        }
        @include queries(xl){
            background-position: top 0px left -55px;
        }
        @include queries(xxl){
            background-position: top 0px left 0px;
        }

        .nf-now-members-name_text{
            margin-top:11px;
            @include queries(xl){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:24px;
            @include queries(md){
                margin-top:10px;
            }
            @media(min-width:860px){
                margin-top:33px;
            }
        }
    
        .nf-now-vertical_text{
            span{
                background-color:rgba($future-green,0.95); 
            }

            span.now-vertical_text02{
                margin-top:25px;
            }
        }
        .nf-now-members-col:nth-of-type(2){
            .members-item_text02{
                margin-top:80px;
            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
            
                @include queries(xl){
                    // left:155px;
                    left:185px;
                }
                @include queries(xxl){
                    // top:310px;
                    left:115px;
                }

            }
        }
        
        .nf-now-members-range{
            @include queries(md){
                margin-top:80px;
                margin-left:0px
            }
            @media(min-width:860px){
                margin-top:40px;
                // margin-left:20px
            }
            @include queries(xl){
                margin-top:100px;
            }
            @include queries(xxl){
                margin-left:-35px;
            }
        }
    }
    .bg_future01{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_09.png');
        background-position: top 40px left 0;

        top:30px;
        @include queries(xs){
            top:0px;
        }

        @include queries(md){
            background-image:url($dir + '/bg-item-pc_09.png');
            background-position: top 0px left -15px;
        }
        @include queries(lg){
            background-position: top 0px left -35px;
        }
        @include queries(xl){
            background-position: top 0px left -55px;
        }
        @include queries(xxl){
            background-position: top 0px left 0px;
        }
        


        .nf-now-members-name_text{
            margin-top:11px;
        
            @include queries(xl){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:9px;
            @include queries(md){
                margin-top:20px;
            }
            @include queries(xl){
                margin-top:12px;
            }
        
        }

        .nf-now-vertical_text{

            span.now-vertical_text02{
                margin-top:25px;
            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
            
                @include queries(md){
                    margin-left:auto;
                }
                @include queries(xl){
                    // left:155px;
                    left:185px;
                    right:auto;
                }
                @include queries(xxl){
                    // top:310px;
                    left:115px;
                }

            }
        }


        .nf-now-members-range{
            margin-top:200px;
            @include queries(xs){
                margin-top:150px;
            }
            @include queries(sm){
                margin-top:180px;
            }
            @include queries(md){
                margin-top:110px;
                margin-left:0px
            }
            @media(min-width:860px){
                margin-top:90px;
                // margin-left:20px
            }
            @include queries(xl){
                margin-top:150px;
            }
            @include queries(xxl){
                margin-left:-35px;
            }
        }
    }
    .bg_future02{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_10.png');
        background-position: top 40px left 0;

        top:30px;
        @include queries(xs){
            top:0px;
        }

        @include queries(md){
            background-image:url($dir + '/bg-item-pc_10.png');
            background-position: top 0px right -20px;
        }
        @include queries(lg){
            background-position: top 0px right -35px;
        }
        @include queries(xl){
            background-position: top 0px right -55px;
        }
        @include queries(xxl){
            background-position: top 0px right 0px;
        }

        .nf-now-members-name_text{
            margin-top:9px;
        
            @include queries(xl){
                margin-top:16.7px;
            }
        }

        .nf-now-members_text{
            margin-top:9px;
            @include queries(md){
                margin-top:20px;
            }
            @include queries(xl){
                margin-top:12px;
            }
        
        }

    
    
        .nf-now-vertical_text{
            @include queries(xl){
                top:273px;
                right:185px;
                margin-left:auto;
            }
            @include queries(xxl){
                right:115px;
            }
            span{
                background-color:rgba($future-orange,0.95); 
                padding-top:0px;
            }
            span.now-vertical_text02{
                padding-top:10px;
                margin-top:90px;
            }
            span.members-item_text01,
            span.members-item_text02{
                padding-top:10px;
            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
            
                @include queries(md){
                    margin-left:0;
                }
                @include queries(xl){
                    margin-left:auto;
                }

            }
        }
        // .nf-now-vertical{
        //      @include queries(xl){
        //             top:293px;
        //             right: 185px !important;
        //             left:auto;
        //         }
        //         @include queries(xxl){
        //             right:115px;
        //         }
        // }
        .nf-now-members-range{
            margin-top:250px;
            @include queries(xs){
                margin-top:190px;
            }
            @include queries(md){
                margin-top:170px;
                margin-left:-40px;
            }
            @media(min-width:860px){
                margin-top:160px;
                // margin-left:0px;
            }
            @include queries(lg){
                margin-top:40px;
                margin-left:-10px;
            }
            @include queries(xl){
                margin-top:100px;
                margin-left:30px;
            }
            @include queries(xxl){
                margin-left:55px;
            }
        }
    }
    .bg_future03{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_11.png');
        background-position: top 40px left 0;

        top:30px;
        @include queries(xs){
            top:0px;
        }

        @include queries(md){
            background-image:url($dir + '/bg-item-pc_11.png');
            background-position: top 0px left -15px;
        }
        @include queries(lg){
            background-position: top 0px left -35px;
        }
        @include queries(xl){
            background-position: top 0px left -55px;
        }
        @include queries(xxl){
            background-position: top 0px left 0px;
        }

        .nf-now-members-name_text{
            margin-top:10px;
            @include queries(md){
                margin-top:11px;
                margin-left:0;
            }
            @media(min-width){
                margin-left:20px;
            }
            @include queries(xl){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:10px;
            @include queries(md){
                margin-top:8px;
            }
            @media(min-width:821px){
                margin-top:20px;
            }
            @include queries(xl){
                margin-top:11px;
            }
        
        }


        .nf-now-vertical_text{
            
            span{
                background-color:rgba($future-blue,0.95); 
            }
            span.now-vertical_text02{   
                margin-top:60px;
                @include queries(xl){
                    margin-top:30px;
            
                }
            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
                @include queries(md){
                    margin-left:auto;
                }
                @include queries(xl){
                    top:363px;
                    // left:145px;
                    left:185px;
                    right:auto;
            
                }
                @include queries(xxl){
                    left:115px;
                }
            }
        }

        .nf-now-members-range{
            margin-top:140px;
            @include queries(xs){
                margin-top:180px;
            }
            @include queries(md){
                margin-top:100px;
                margin-left:0px
            }
            @media(min-width:860px){
                margin-top:40px;
            }
            @include queries(xl){
                margin-top:120px;
            }
            @include queries(xxl){
                margin-left:-35px;
            }
        }
    }
    .bg_future04{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_02.png');
        background-position: top 40px left 0;

        top:30px;
        @include queries(xs){
            top:0px;
        }

        @include queries(md){
            background-image:url($dir + '/bg-item-pc_02.png');
            background-position: top 0px right -20px;
        }
        @include queries(lg){
            background-position: top 0px right -35px;
        }
        @include queries(xl){
            background-position: top 0px right -55px;
        }
        @include queries(xxl){
            background-position: top 0px right -0px;
        }

        .nf-now-members-name_text{
            margin-top:6px;
            @include queries(md){
                margin-top:11px;
            }
            @include queries(lg){
                margin-top:17px;
            }
        }

        .nf-now-members_text{
            margin-top:6px;
            @include queries(md){
                margin-top:12px;
            }
            @include queries(lg){
                margin-top:16px;
            }
        
        }
        
            .nf-now-vertical_text{
                @include queries(xl){
                    top:273px;
                    right:185px;
                    margin-left:auto;
                }
                @include queries(xxl){
                    top:243px;
                    right:115px;
                }

                span{
                    background-color:rgba($future-pink2,0.95); 
                }
            }

            // .nf-now-vertical{
            //     @include queries(xl){
            //         top:273px;
            //         right:185px;
            //     }
            //     @include queries(xxl){
            //         top:243px;
            //         right:115px;
            //     }
            // }
        .nf-now-members_text{
            margin-top:8px;
            line-height: lh-calc(14,11);
        }  
        // .nf-now-members-range{
        //     margin-top:190px;
        // }

        .nf-now-vertical{
            .nf-now-vertical_text{
            
                @include queries(md){
                    margin-left:0;
                }
                @include queries(xl){
                    margin-left:auto;
                }

            }
        }

        .nf-now-members-range{
            margin-top:250px;
            @include queries(xs){
                margin-top:190px;
            }
            @include queries(md){
                margin-top:140px;
                margin-left:-40px;
            }
            @media(min-width:860px){
                // margin-top:140px;
                // margin-left:0;
            }
            @include queries(lg){
                margin-top:40px;
                margin-left:-10px;
            }
            @include queries(xl){
                margin-top:130px;
                margin-left:30px;
            }
            @include queries(xxl){
                margin-left:55px;
            }
        }
    }
    .bg_future05{
        @include bg_now;
        background-image:url($dir + '/bg-item-sp_03.png');
        background-position: top 40px left -15px;
        @include queries(md){
            background-image:url($dir + '/bg-item-pc_03.png');
            // background-position: top 0px left 0;
            padding-bottom:150px;
        }
        @include queries(lg){
            background-position: top 0px left -35px;
        }
    
        @include queries(xl){
            padding-bottom:270px;
            background-position: top 0px left -55px;
        }
        @include queries(xxl){
            background-position: top 0px left 0;
        }

        .nf-now-members-name_text{
            margin-top:9px;
            @include queries(md){
                margin-top:11px;
            }
            @include queries(xl){
                margin-top:16px;
            }
        }

        .nf-now-members_text{
            margin-top:10px;
            @include queries(md){
                margin-top:20px;
            }
            @include queries(xl){
                margin-top:13px;
            }
        
        }

    
        .nf-now-vertical_text{
            top:30px;
            
            @include queries(xs){
                top:0px;
                
            }
            @include queries(md){
                top:200px;
                
            }

        
            span{
                background-color:rgba($future-green,0.95); 
                padding-top:0px;
            }
        
            span.now-vertical_text01,
            span.now-vertical_text02{
                padding-top:10px;
            }
            span.members-item_text01,
            span.members-item_text02{
                padding-top:10px;
            }
        }

        .nf-now-vertical{
            .nf-now-vertical_text{
                transform:translateX(-20px);
                @include queries(md){
                    margin-left:auto;
                    transform:translateX(0px);
                }
                @include queries(xl){
                    top:253px;
                    // left:135px;
                    left:185px;
                    right:auto;
            
                }
                @include queries(xxl){
                    left:115px;
                }
            }
        }
        .nf-now-members-col:nth-of-type(odd){
            span.members-item_text01{
                margin-top:0px;
            }
            span.members-item_text02{
                margin-top:25px;
                // padding-top:0;
            }

        }
        .nf-now-members-range{
            margin-top:270px;
            @include queries(xs){
                margin-top:200px;
            }
            @include queries(md){
                margin-top:200px;
                margin-left:0px;
            }
            @media(min-width:860px){
                // margin-left:20px;
            }
            @include queries(lg){
                margin-top:60px;
            }

            @include queries(xl){
                margin-top:100px;
            }
            @include queries(xxl){
                margin-left:-35px;
            }
        }
    }

 

    //2024
    
}