﻿//
// Home
//
body.home {

	//
	// Header
	//
	.header {
		margin-bottom: 0;
	}

	.movie-index{
		width: 100%;
		aspect-ratio: 16/9;
		iframe,video{
			width: 100%;
			height: 100%;
		}
		
	}
	//
	// Hero
	//
	.hero {
		background-color: $orange;
		position: relative;
		.hero-slider{
			width: 100%;
			background-color: #fff;
  
		  }
		  .hero-slider__item{
			width: 100%;
			img{
			  width: 100%;
			}
		}
		.hero-slider__item01{
			background-color: #BDDBD4;
			position: relative;
			// padding-top: 51vw;
			padding-top:0 !important;
			aspect-ratio: 1500/760;
			@media (min-width: 1500px) {
				// padding-top:760px;
			}
			// @include media-breakpoint-down(md) {
			// 	padding-top: 115%;
			// }
			@media(max-width:1024.98px){
				aspect-ratio: 375/415;
			}
			.hero-item{
				position: absolute;
				z-index: 10;
				overflow: hidden;
				animation:stepImg01 2s steps(2) infinite;
				transform:scale(.5);
				opacity:0;
			}
			.hero-item--01{
				left: 3%;
				top: 2%;
				background: url(#{$dir}/home/img_hero-item01@2x.png)no-repeat;
				background-size: 200%;
				width: 19.6%;
    			// height: 41%;
				aspect-ratio: 294/354;
				// @include media-breakpoint-down(md) {
				// 	width: 50%;
				// 	height: 28%;
				// 	top: 23%;
				// 	left: 0;
				// }
				@media (max-width: 1024.98px){
					width: 32%;
					height: 34%;
					top: 21%;
					right: 8%;
					left: auto;
					z-index: 4 !important;
				}
			}
			.hero-item--02{
				top: 34%;
				left: 18%;
				background: url(#{$dir}/home/img_hero-item02@2x.png)no-repeat;
				background-size: 200%;
				width: 18.8%;
				// height: 32%;
				aspect-ratio: 282/245;
				// @include media-breakpoint-down(md) {
				// 	width: 17%;
				// 	height: 23%;
				// 	top: 54%;
				// 	left: 3%;
				// }
				@media (max-width: 1024.98px){
					width: 29%;
        			left: auto;
        			// height: 23%;
        			top: 50%;
					right: 3%;
				}
			}
			.hero-item--03{
				top: 26%;
				right: 23%;
				background: url(#{$dir}/home/img_hero-item03@2x.png)no-repeat;
				background-size: 200%;
				width: 17.5%;
				// height: 41.9%;
				aspect-ratio: 263/319;
				@media (max-width: 1024.98px){
					width: 29.3%;
					// height: 32%;
					top: 43%;
					right: auto;
					left: 3%;
				}
			}
			.hero-item--04{
				top: 6%;
				right: 6%;
				background: url(#{$dir}/home/img_hero-item04@2x.png)no-repeat;
				background-size: 200%;
				width: 18.8%;
				// height: 35%;
				aspect-ratio: 283/270;
				// @include media-breakpoint-down(md) {
				// 	width: 22%;
				// 	height: 21%;
				// 	top: 25%;
				//   right: 0;
				// }
				@media (max-width: 1024.98px){
					width: 29%;
					// height: 25%;
					top: 22%;
					left: 8%;
					right: auto;
				}
			}
			.hero-item--05{
				bottom: 8%;
				left: 1%;
				background: url(#{$dir}/home/img_hero-item05@2x.png)no-repeat;
				background-size: 200%;
				width: 15.8%;
				// height: 35%;
				aspect-ratio: 237/266;
				// @include media-breakpoint-down(md) {
				// 	width: 24.8%;
				// 	height: 23.1%;
				// 	bottom: 26%;
				//   right: 2%;
				// }
				@media (max-width: 1024.98px){
					width: 25.6%;
        			// height: 25.7%;
        			bottom: 1%;
        			right: 2%;
				}
			}
			.hero-item--06{
				bottom: 8%;
				right: 6%;
				background: url(#{$dir}/home/img_hero-item06@2x.png)no-repeat;
				background-size: 200%;
				width: 15.6%;
				// height: 35.2%;
				aspect-ratio: 235/268;
				@include media-breakpoint-down(md) {
					width: 26.1%;
					// height: 26.9%;
					bottom: 1%;
					right: 5%;
				}
			}
			// .hero-item--07{
			// 	display: none;
			// 	top: 41%;
			// 	left: 11%;
			// 	background: url(#{$dir}/home/img_hero-item07@2x.png)no-repeat;
			// 	// background-size: 200%;
			// 	background-size: contain;
			// 	width: 39%;
			// 	height: 19%;
			// 	@include media-breakpoint-down(md) {
			// 		top: 80%;
			// 		width:17%;
			// 	}
			// }
			.hero-img{
				position: absolute;
				right: 0;
				bottom: 0;
				left: -2%;
				margin: auto;
				width: 44%;
				transform: translateY(10%);
				opacity: 0;
				z-index: 5;
				@media (max-width: 1024.98px){
					width: 70%;
					left: 0%;
				}
			}
			.hero-text{
				position: absolute;
				top: 5%;
				// right: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				margin: auto;
				text-align: center;
				display: inline-block;
				width: 35%;
				overflow: hidden;
				margin-top: 24px;
				img{
					display: inline;
					opacity: 0;
					transform: translateX(-20px);
				}
				&:after{
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					// opacity: 0;
					background-color: #fff;
					width:0;
				}
				@include media-breakpoint-down(md) {
					top: 2%;
					width: 100%;
					img{
						width: 90%;
					}
				}
			}
	
	
			// // Slick arrows
			// .slick-arrow {
			// 	cursor: pointer;
			// 	outline: 0;
			// 	display: block;
			// 	width: 60px;
			// 	height: 60px;
			// 	border-radius: 50%;
			// 	background-color: rgba(#000000, 0.5);
			// 	position: absolute;
			// 	z-index: 2;
			// 	top: 50%;
			// 	margin-top: -30px;
	
			// 	&:after {
			// 		content: '';
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 50%;
			// 		display: block;
			// 		width: 15px;
			// 		height: 15px;
			// 		border-top: 3px solid #FFF;
			// 		border-left: 3px solid #FFF;
			// 	}
	
			// 	&.slick-prev {
			// 		left: 10%;
			// 		margin-left: -15px;
			// 		&:before{
			// 			content:none;
			// 		}
	
			// 		&:after {
			// 			transform: translate(-50%, -50%) rotate(-45deg);
			// 			margin-left: 3px;
			// 		}
			// 	}
	
			// 	&.slick-next {
			// 		right: 10%;
			// 		margin-right: -15px;
			// 		&:before{
			// 			content:none;
			// 		}
			// 		&:after {
			// 			transform: translate(-50%, -50%) rotate(135deg);
			// 			margin-left: -3px;
			// 		}
			// 	}
			// }
			// // Slick dots
			// .slick-dots {
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// 	list-style: none;
			// 	margin: 20px 0;
			// 	padding: 0;
			// 	bottom: 0;
	
			// 	> li {
			// 		margin: 0 4px;
			// 		display: flex;
			// 		align-items: center;
	
			// 		button {
			// 			border-radius: 50%;
			// 			background-color: #999999;
			// 			width: rem-calc(8);
			// 			height: rem-calc(8);
			// 			cursor: pointer;
			// 			&:before{
			// 				content:none;
			// 			}
			// 		}
	
			// 		&.slick-active {
			// 			button {
			// 				background-color: $primary;
			// 			}
			// 		}
			// 	}
			// }
			&.is_show{
				.hero-text{
					img{
						transition:opacity .001s ease .3s,transform 1s ease .4s;
						opacity: 1;
						transform: translateX(0);
					}
					&:after{
						transition:width .3s,transform .3s ease .5s;
						width:100%;
						transform: translateX(100%);
					}
				}
				.hero-item{
					transition:1.5s cubic-bezier(0.82, 0.15, 0.21, 1.24) .7s;
					transform:scale(1);
					opacity:1;
				}
				.hero-img{
					transition:.6s ease .5s;
					transform: translateY(0);
					opacity: 1;
				}
			}
		}
		.hero-slider__item02{
		  position: relative;
		  aspect-ratio: 1500/760;
		  @media(max-width:1024.98px){
			aspect-ratio: 375/415;
		}
		  .hero-slider-parts-wrapper {
			  position: absolute;
			  inset: 0;
			  display: flex;
			  justify-content: space-between;
			  @include media-breakpoint-down(md){
				  display: none;
			  }
		  }
		  .hero-slider-link-wrapper{
			  width: 29vw;
			  padding: 5.6rem 3vw 0;
			  display: flex;
			  flex-direction: column;
			  row-gap: 3.6rem;

		  }
		  .slider01-parts{
			  display: inline-block;
			  &._01{
				  // width:unquote('min(10vw, 161px)'); 
				  width:10vw; 
			  }
			  &._02{
				  // width:unquote('min(15vw, 234px)'); 
				  width:15vw; 
			  }
			  &._03{
				  // width:unquote('min(16vw, 240px)'); 
				  width:16vw; 
			  }
			  &._04{
				  // width:unquote('min(13vw, 200px)'); 
				  width:13vw; 
			  }
			  &._05{
				  // width:unquote('min(10vw, 162px)'); 
				  width:10vw; 
			  }
			  &._06{
				  // width:unquote('min(19vw, 297px)'); 
				  width:19vw; 
			  }
			  
		  }
		  .chara-item{
			  width: 5vw;
			  margin-top: 3rem;
			  position: absolute;
			  bottom: 3.125rem;
			  left: 4vw;
		  }
  
		}
		
		// .hero-item{
		// 	position: absolute;
		// 	z-index: 10;
		// 	overflow: hidden;
		// 	animation:stepImg01 2s steps(2) infinite;
		// 	transform:scale(.5);
		// 	opacity:0;
		// }
		// .hero-item--01{
		// 	left: 3%;
		// 	top: 5%;
		// 	background: url(#{$dir}/home/img_hero-item01@2x.png)no-repeat;
		// 	background-size: 200%;
		// 	width: 33.7%;
		// 	height: 42%;
		// 	@include media-breakpoint-down(md) {
		// 		width: 50%;
		// 		height: 28%;
		// 		top: 23%;
		// 		left: 0;
		// 	}
		// }
		// .hero-item--02{
		// 	top: 40%;
		// 	left: 27%;
		// 	background: url(#{$dir}/home/img_hero-item02@2x.png)no-repeat;
		// 	background-size: 200%;
		// 	width: 11.4%;
		// 	height: 35%;
		// 	@include media-breakpoint-down(md) {
		// 		width: 17%;
		// 		height: 23%;
		// 		top: 54%;
		// 		left: 3%;
		// 	}
		// }
		// .hero-item--03{
		// 	top: 4%;
		// 	right: 19%;
		// 	background: url(#{$dir}/home/img_hero-item03@2x.png)no-repeat;
		// 	background-size: 200%;
		// 	width: 16.5%;
		// 	height: 47.3%;
		// 	@include media-breakpoint-down(md) {
		// 		width: 21.9%;
		// 		height: 28%;
		// 		top: 11%;
		// 		right: 25%;
		// 	}
		// }
		// .hero-item--04{
		// 	top: 2%;
		// 	right: 4%;
		// 	background: url(#{$dir}/home/img_hero-item04@2x.png)no-repeat;
		// 	background-size: 200%;
		// 	width: 15.9%;
		// 	height: 34%;
		// 	@include media-breakpoint-down(md) {
		// 		width: 22%;
		// 		height: 21%;
		// 		top: 25%;
  		// 	right: 0;
		// 	}
		// }
		// .hero-item--05{
		// 	bottom: 20%;
		// 	right: 4%;
		// 	background: url(#{$dir}/home/img_hero-item05@2x.png)no-repeat;
		// 	background-size: 200%;
		// 	width: 18%;
		// 	height: 37%;
		// 	@include media-breakpoint-down(md) {
		// 		width: 24.8%;
		// 		height: 23.1%;
		// 		bottom: 26%;
  		// 	right: 2%;
		// 	}
		// }
		// .hero-item--06{
		// 	bottom: 9%;
		// 	right: 20%;
		// 	background: url(#{$dir}/home/img_hero-item06@2x.png)no-repeat;
		// 	background-size: 200%;
		// 	width: 18%;
		// 	height: 37%;
		// 	@include media-breakpoint-down(md) {
		// 		width: 24.2%;
		// 		height: 22.9%;
		// 		bottom: 2%;
		// 		right: 5%;
		// 	}
		// }
		// .hero-item--07{
		// 	top: 41%;
		// 	left: 11%;
		// 	background: url(#{$dir}/home/img_hero-item07@2x.png)no-repeat;
		// 	background-size: 200%;
		// 	width: 12.2%;
		// 	height: 19%;
		// 	@include media-breakpoint-down(md) {
		// 		top: 80%;
		// 		width:17%;
		// 	}
		// }
		// .hero-img{
		// 	position: absolute;
		// 	right: 0;
		// 	bottom: 0;
		// 	left: 0;
		// 	margin: auto;
		// 	width:22%;
		// 	transform: translateY(10%);
		// 	opacity: 0;
		// 	@include media-breakpoint-down(md) {
		// 		width: 40%;
		// 		// top: 2%;
		// 	}
		// }
		// .hero-text{
		// 	position: absolute;
		// 	top: 5%;
		// 	// right: 50%;
		// 	left: 50%;
		// 	transform: translate(-50%, 0);
		// 	margin: auto;
		// 	text-align: center;
		// 	display: inline-block;
		// 	width: 35%;
		// 	overflow: hidden;
		// 	img{
		// 		opacity: 0;
		// 		transform: translateX(-20px);
		// 	}
		// 	&:after{
		// 		content: '';
		// 		display: block;
		// 		position: absolute;
		// 		left: 0;
		// 		top: 0;
		// 		height: 100%;
		// 		// opacity: 0;
		// 		background-color: #fff;
		// 		width:0;
		// 	}
		// 	@include media-breakpoint-down(md) {
		// 		top: 2%;
		// 		width: 100%;
		// 		img{
		// 			width: 90%;
		// 		}
		// 	}
		// }


		// // Slick arrows
		// .slick-arrow {
		// 	cursor: pointer;
		// 	outline: 0;
		// 	display: block;
		// 	width: 60px;
		// 	height: 60px;
		// 	border-radius: 50%;
		// 	background-color: rgba(#000000, 0.5);
		// 	position: absolute;
		// 	z-index: 2;
		// 	top: 50%;
		// 	margin-top: -30px;

		// 	&:after {
		// 		content: '';
		// 		position: absolute;
		// 		top: 50%;
		// 		left: 50%;
		// 		display: block;
		// 		width: 15px;
		// 		height: 15px;
		// 		border-top: 3px solid #FFF;
		// 		border-left: 3px solid #FFF;
		// 	}

		// 	&.slick-prev {
		// 		left: 10%;
		// 		margin-left: -15px;
		// 		&:before{
		// 			content:none;
		// 		}

		// 		&:after {
		// 			transform: translate(-50%, -50%) rotate(-45deg);
		// 			margin-left: 3px;
		// 		}
		// 	}

		// 	&.slick-next {
		// 		right: 10%;
		// 		margin-right: -15px;
		// 		&:before{
		// 			content:none;
		// 		}
		// 		&:after {
		// 			transform: translate(-50%, -50%) rotate(135deg);
		// 			margin-left: -3px;
		// 		}
		// 	}
		// }
		// // Slick dots
		// .slick-dots {
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	list-style: none;
		// 	margin: 20px 0;
		// 	padding: 0;
		// 	bottom: 0;

		// 	> li {
		// 		margin: 0 4px;
		// 		display: flex;
		// 		align-items: center;

		// 		button {
		// 			border-radius: 50%;
		// 			background-color: #999999;
		// 			width: rem-calc(8);
		// 			height: rem-calc(8);
		// 			cursor: pointer;
		// 			&:before{
		// 				content:none;
		// 			}
		// 		}

		// 		&.slick-active {
		// 			button {
		// 				background-color: $primary;
		// 			}
		// 		}
		// 	}
		// }
		// &.is_show{
		// 	.hero-text{
		// 		img{
		// 			transition:opacity .001s ease .3s,transform 1s ease .4s;
		// 			opacity: 1;
		// 			transform: translateX(0);
		// 		}
		// 		&:after{
		// 			transition:width .3s,transform .3s ease .5s;
		// 			width:100%;
		// 			transform: translateX(100%);
		// 		}
		// 	}
		// 	.hero-item{
		// 		transition:1.5s cubic-bezier(0.82, 0.15, 0.21, 1.24) .7s;
		// 		transform:scale(1);
		// 		opacity:1;
		// 	}
		// 	.hero-img{
		// 		transition:.6s ease .5s;
		// 		transform: translateY(0);
		// 		opacity: 1;
		// 	}
		// }
	}
	.hero__elm{
		&.hero__elm--01{
			width: 100%;
			@include media-breakpoint-up(lg) {
				position: absolute;
				left:0;
				bottom:0;
			}
			// @include media-breakpoint-down(md) {
			// 	background-color: $orange;
			// }
			// margin-left: 1%;

		}
		// .hero-slide{
		// 	max-width:446px;
		// 	padding-left: 3%;
		// 	// transform: translateX(-50px);
		// 	// opacity: 0;
		// 	@include media-breakpoint-up(lg) {
		// 		width: 30%;
		// 	}
		// 	@include media-breakpoint-down(sm) {
		// 		width: 100%;
		// 		margin: auto;
		// 	}
		// 	@include media-breakpoint-down(md) {
		// 		padding-left: 10px;
		// 		padding-right: 10px;
		// 		margin:1rem auto 0;
		// 	}
		// 	img{
		// 		width: 100%;
		// 	}
		// }
		// .hero-slide__main{
		// 	margin-bottom: 1rem;
		// }
		// .hero-slide__main,
		// .hero-slide__thumb{
		// 	display: none;
		// 	opacity: 0;
		// 	transition:.2s;
		// 	&.slick-initialized{
		// 		display: block;
		// 		opacity: 1;
		// 	}
		// }
		// .hero-slide__thumb__img{
		// 	margin-right:1rem;
		// 	cursor: pointer;
		// }
		// .hero-slide__main__img,
		// .hero-slide__thumb__img{
		// 	img{
		// 		border-radius: 6px;
		// 	}
		// }
		// .hero-slide__thumb .slick-list{
		// 	padding: 0 20% 0 0!important;
		// }
		.pickup{
			// position: absolute;
			// bottom: 0;
			// left: 0;
			background: rgba(#000,.6);
			color:white;
			margin-top: 1rem;
			padding-left: 3%;
			@include media-breakpoint-down(md) {
				padding:10px;
				margin-top: 0;
			}
			.title{
				font-size: rem-calc(27);
				@include media-breakpoint-down(md) {
					font-size: rem-calc(18);
				}
			}
			a{
				color:white;
				text-decoration: underline;
				@include media-breakpoint-down(md) {
					font-size: rem-calc(14);
				}
			}
		}
		&.is_scrolled{
			.hero-slide{
				transform: translateX(0);
				opacity: 1;
				transition:1s ease 1.3s;
			}
		}
	}
	.section{
		padding:5rem 0;
		position: relative;
	}
	.section--01{
		position: relative;
	}
	.section--02{
		padding: 2.5rem 0 1.5rem;
		@include media-breakpoint-down(md) {
			padding: 3.2rem 0 0;
		}
	}
	.visitors-list{
		display: flex;
		@include media-breakpoint-down(md){
		  box-shadow: 6px 6px 0 black;
		  flex-wrap: wrap;
		  border: 1px solid black;
		}
		li{
		  width: calc(100% / 6);
		  position: relative;
		  z-index: 1;
		  transition: transform 0.3s ease;
		  @include media-breakpoint-down(md){
			width: 100%;
		  }
		  // &:last-of-type{
		  //   a{
		  //     box-shadow: 6px 6px 0 black;
	  
		  //   }
		  // }
		  // &+ li{
		  //   // border-left: 1px solid black;
		  //   @include media-breakpoint-down(md){
		  //     border-left: none;
			 
		  //   }
		  // }
		  &::before{
			@include media-breakpoint-up(lg){
			  content: '';
			  display: inline-block;
			  background-color: black;
			  position: absolute;
			  top: 6px;
			  left: 6px;
			  right: -6px;
			  bottom: -6px;
			  z-index: -1;
			  transition: bottom 0.2s ease,right 0.2s ease;
			}
		  }
		  &:hover{
			@include media-breakpoint-up(lg){
			  transform: translate(6px, 6px);
			}
			&::before{
			  @include media-breakpoint-up(lg){
				bottom: 0;
				right: 0;
				left: 0;
		  
			  }
			}
		  }
		  &:not(:last-of-type){
			a{
			  @include media-breakpoint-up(lg){
				border-right: none;
			  }
			}
		  }
		 
		  a{
			display: block;
			height: 100%;
			padding: 15px 15px 33px;
			color: #333333;
			// font-size: 20px;
			font-size: unquote('min(1.3vw, 20px)'); 
			position: relative;
			line-height: 1.2;
			border: 1px solid black;
			background-color: $white;
			z-index: 1;
			@include media-breakpoint-down(md){
			  font-size: 17px;
			  padding: 13px 21px 13px;
			  border: none;
			  border-bottom: 1px solid black;
			}
		   
			
			&::after{
			  content: '';
			  display: inline-block;
			  width: 16px;
			  height: 18px;
			  background-color: #333;
			  clip-path: path('M8.418,19.307a.5.5,0,0,1-.359-.152L-.359,10.482a.5.5,0,0,1,.005-.7l.274-.274a.5.5,0,0,1,.713.006l7.089,7.316V0a.5.5,0,0,1,.5-.5h.392a.5.5,0,0,1,.5.5V16.829L16.2,9.512a.5.5,0,0,1,.713-.006l.274.274a.5.5,0,0,1,.005.7L8.777,19.155A.5.5,0,0,1,8.418,19.307Z');
			  position: absolute;
			  bottom: 12px;
			  right: 12px;
			  transform: rotate(-90deg);
			  transition: transform 0.3s ease;
			  @include media-breakpoint-down(md){
				top: 0; bottom: 0;
				margin: auto 0;
			  }
			}
			&:hover{
			  text-decoration: none;
			  &::after{
				@include media-breakpoint-up(lg){
				  transform: translateX(5px) rotate(-90deg);
				}
			  }
			}
			.small{
			//   font-size: 14px;
				font-size: unquote('min(0.85vw, 14px)'); 
			  @include media-breakpoint-down(md){
				font-size: 13px;
			  }
	  
			}
			.color-pink{
			  color: #E85298;
			}
		  }
		}
	  }
	// .section{
	// 	&.section--02{
			
	// 		.head__wrap{
	// 			h2{
	// 				&:after{
	// 					background-color: $white;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&.section--04{
	// 		.head__wrap{
	// 			h2{
	// 				&:after{
	// 					background-color: $teal;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&.section--05{
	// 		.head__wrap{
	// 			h2{
	// 				&:after{
	// 					background-color: $p-pink;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	&.section--08{
	// 		.head__wrap{
	// 			h2{
	// 				&:after{
	// 					background-color: #49b7de;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.banner-slider{
		&> .container{
			@include media-breakpoint-up(xl){
				max-width: 1100px !important;
			}
		}
	}
	.banner-slide{
        margin-top: 70px;
		
      }
      .banner-slide__main{
        position: relative;
        padding: 0 34px;
       

        .slide-arrow{
          position: absolute;
          top: 0; bottom: 0;
          margin: auto 0;
          width: 34px;
          border: none;
          background-color: #fff;
          box-shadow: 0 3px 6px rgba(0,0,0,.26);
          transition: box-shadow 0.3s linear, transform 0.3s ease;
          @media(max-width:767.98px){
            width: 26px;
          }
          &:focus{
            outline: none;
            box-shadow: 0 3px 12px rgba(0,0,0,.26);
            transform: scale(1.05);
          }
          span{
            width: 17px;
            height:34px;
            background-color: #000;
            clip-path: polygon(0 0, 100% 50%, 0% 100%);
            @media(max-width:767.98px){
              width: 11px;
              height:23px;
            }
          }

        }
        .prev-arrow{
          left: 0;
          span{
            transform: rotate(-180deg);
          }
        }
        .next-arrow{
          right: 0;
        }
      }
      .banner-slide__main__img{
        aspect-ratio: 396/150;
        transition: transform 0.3s ease;
		@media(min-width:768px){
            transform: scale(0.8);
          }
        &.slick-active{
          
          &.slick-current + .slick-active {
            @media(min-width:768px){
              transform: scale(1);
            }
          }
        }
        img{
          height: 100%;
          width: auto;
          object-fit: contain;
          margin-inline: auto;
        }
      }
	.section--03{
		font-weight: bold;
		.bg{
			background: $white url(#{$dir}/home/bg-section03.png) center/cover no-repeat;
			&.bg-upper{
				padding: 5rem 0 5rem;
				@include media-breakpoint-down(sm){
					padding: 3.5rem 0 2.5rem;
				}
			}
			&.bg-lower{
				padding: 4.2rem 0;
				position: relative;
				@include media-breakpoint-down(sm){
					padding: 2.5rem 0 2rem;
					// margin-top: 30px;
				}
			}
		}
		.intro{
			width: 100%;
			line-height: 1.9;
			
			aspect-ratio: 1500/650;
			
			padding: 32px 0 25px;
			overflow: hidden;
			position: relative;
			z-index: 1;
			@include media-breakpoint-down(md) {
				line-height: 2.1;
				margin-bottom: 0;
				aspect-ratio:none;
				height: calc(100vw * 0.8);
				padding: 20px 0 20px;
			}
			
			&::before{
				content: '';
				display: inline-block;
				width: 100%;
				background: url(#{$dir}/home/bg-section03-intro.jpg) center/cover no-repeat;
				position: absolute;
				inset: 0;
				z-index: -1;
				@include media-breakpoint-down(md) {
					background: url(#{$dir}/home/bg-section03-intro_sp.jpg) center/cover no-repeat;
					
				}
			}
			&.scroll_target{
				&::before{
					transform: scale(1.05);
					opacity: 0;
					transform-origin: center;
				}
				&.is_scrolled{
					&::before{
						animation: show 1.4s linear forwards;
					}
					@keyframes show{
						0%{
							transform: scale(1.05);
							opacity: 0;
							
						}
						50%{
							opacity: 1;
						}
					
						100%{
							transform: scale(1);
							opacity: 1;
							
						}
					}
					.intro__wrap-text {
						p{
							&::before{
								right: 0;
							}
							img{
								animation: left 1s ease 1.2s forwards;
								
	
								@keyframes left{
									0%{
										opacity: 0;
										transform: translateX(-20px);
									}
									100%{
										opacity: 1;
										transform: translateX(0);
									}
								}
							}
						}
	
					}
				}
			}
		}
		.intro__wrap-text {
			p{
				
				width: max-content;
				position: relative;
				z-index: 1;
				padding: 10px 14px;
				@include media-breakpoint-down(sm){
					padding: 0 4px;
				}
				&::before{
					content: '';
					display: inline-block;
					position: absolute;
					inset: 0;
					right: 100%;
					background-color: $white;
					z-index: -1;
					transition: right 0.6s ease 0.6s;
				}
				img{
					opacity: 0;
				}
				.text{
					&_01{
						width: 40vw;
						max-width: 609px;
						@include media-breakpoint-down(md) {
							width: 70vw;
						}
					}
					&_02{
						width: 37vw;
						max-width: 560px;
						@include media-breakpoint-down(md) {
							width: 65vw;
						}
					}
					&_03{
						width: 52vw;
						max-width: 788px;
						@include media-breakpoint-down(md) {
							width: 87vw;
						}
					}

				}
			}
		}
		.intro-lower{
			&.scroll_target{
				.text{
					opacity: 0;
					transform: translateY(5px);
					transition: opacity 1s linear, transform 0.6s linear;
					@for $i from 1 through 3 {
						&--0#{$i} { 
							transition-delay: calc((#{$i} - 1) * 0.6s);
						 }
					}
				}
				&.is_scrolled{
					.text{
						opacity: 1;
						transform: translateY(0);
					}
				}
			}
		}
		.school-intro{
			background-color: #E5F4FA;
			padding: 5.6rem 0 6.25rem;
			@include media-breakpoint-down(sm){
				padding: 3.2rem 0 ;
			}
			
			&__upper{
				.hsc-item{ --school-color: #E85298; }
				.htc-item{ --school-color: #FF7603; }
				.iryo-item{ --school-color: #43AA88; }

				&.scroll_target{
					
					.sc-item{
						.school-intro__img{
							opacity: 0;
							transform: translateY(50px);
							transition: .7s cubic-bezier(.72,-.01,.02,1.41);
							
						}
						@for $i from 1 through 3 {
							&:nth-of-type(#{$i}){
								.school-intro__img{
									transition-delay: calc((#{$i} - 1) * 0.2s);
								}

							}
						}
					}
					&.is_scrolled{
						.sc-item{
							.school-intro__img{
								opacity: 1;
								transform: translateY(0);
							}
						}
					}
				}
			}
			&__img {
				max-width: 330px;
				@include media-breakpoint-down(sm){
					width: 61vw;
				}
			}
			&__list{
				width: 100%;
				border: 3px solid var(--school-color);
				background-color: $white;
				border-radius: 5px;
				@include media-breakpoint-down(sm){
					width: 90%;
					margin-inline: auto;
				}
				li{
					margin: 10px 0;
					&:first-of-type{
						margin-top: 0;
						background-color: var(--school-color);
						min-height: 60px;
						
						@include media-breakpoint-down(sm){
							min-height: 45px;
						}
						a{
							text-align: center;
							&::before{
								background-color: $white;
							}
							&::after{
								border-color: $white;
							}
						}
						img{
							transform: translateX(-10px);
						}
					}
					a{
						width: 100%;
						color: #333;
						font-size: 21px;
						padding: 0 1.9rem;
						display: inline-block;
						position: relative;
						@include media-breakpoint-down(sm){
							font-size: 15px;
							padding: 0 1.3rem;
						}
						&::before,&::after{
							content: '';
							display: inline-block;
							position: absolute;
							top:0; bottom: 0;
							margin: auto 0;
							transition: transform 0.3s ease;
						}
						&::before{
							right: 15px;
							width: 12px;
							height: 2px;
							background: #333;
						}
						&::after{
							right: 14px;
							width: 12px;
							height: 12px;
							border-top: 3px solid #333;
							border-right: 3px solid #333;
							-webkit-transform: rotate(45deg);
							transform: rotate(45deg);
						}
						&:hover{
							text-decoration: none;
							opacity: 1;
							&::before{
								transform: translateX(5px);
							}
							&::after{
								transform: translateX(5px) rotate(45deg);
							}
						}
						
						
					}
				}

			}
			&__mit,&__lower{
				width: 100%;
				max-width: 820px;
				margin-inline: auto;

			}
			&__lower{
				.p-accreditation{
					.item{
					  align-self: flex-start;
					  &:nth-of-type(2){
						margin-top: 120px;
						@include media-breakpoint-down(md){
						  margin-top: 0;
						}
					  }
					  .item-inner{
						position: relative;
						z-index: 1;
						aspect-ratio: 1;
						@include media-breakpoint-down(md){
						  
						  max-width: 400px;
						  margin-inline: auto;
						}
						&::before{
						  content: '';
						  display: inline-block;
						  max-width: 220px;
						  max-height: 220px;
						  aspect-ratio: 1;
						  background: url(#{$dir}/common/bg_gekkei-wh.png) center/cover no-repeat;
						  position: absolute;
						  inset: 0;
						  margin: auto;
						  z-index: -1;
						}
					  }
					  .title{
						font-size: 30px;
						color: #089B99;
						line-height: calc(45 / 30);
						white-space: nowrap;
						@include media-breakpoint-down(md){
						  font-size: 28px;
						}
					  }
					  .text{
						font-size: 16px;
						@include media-breakpoint-down(md){
						  font-size: 15px;
						}
					  }
					}
				  }
			}
		}

	}
	.section--04{
		margin-bottom: 1.75rem;
		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
		&> .container{
			@include media-breakpoint-down(sm){
				padding-inline: 0;
			}
		}
		.head__wrap{
			color:#333;
			@include media-breakpoint-down(md) {
				margin-bottom: 3rem;
			}
		}
		.p-opencampus-img{
			&:nth-of-type(2){
				@include media-breakpoint-down(md){
					width: 56vw;
				}
			}
		}
		.p-opencampus-btn{
			width: 92%;
			max-width: 1000px;
			display: flex;
			gap: 10px 20px;
			@include media-breakpoint-down(md){
			  flex-direction: column;
			}
			
		  }
		// .section__01__block{
		// 	position: relative;
		// }
		// .slick-dots{
		// 	display: flex;
		// 	button{
		// 		background-color: #F0F0F0;
		// 		width: 100%;
		// 		height: 15px;
		// 		position: relative;
		// 		&:before{
		// 			content: "";
		// 			background-color: $p-pink;
		// 			width: 0;
		// 			height: 100%;
		// 			opacity: 1;
		// 		}
		// 	}
		// 	li{
		// 		margin-left: 0;
		// 		width:calc((100% - 16px) / 5);
		// 		height: auto;
		// 		&:not(:last-of-type){
		// 			margin-right: 4px;
		// 		}
		// 		&.slick-active{
		// 			button{
		// 				&:before{
		// 					transition:3.4s linear;
		// 					width: 100%;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		// h3{
		// 	font-weight: bold;
		// 	color:$teal;
		// 	display: inline-block;
		// 	letter-spacing: 2px;
		// 	position: absolute;
		// 	top: -4rem;
		// 	left: 0;
		// 	z-index: 10;
		// 	font-size: rem-calc(20);
		// 	@include media-breakpoint-up(lg) {
		// 		top: -2rem;
		// 		font-size: rem-calc(35);
		// 		padding:.5rem 2rem;
		// 		&:after{
		// 			content: '';
		// 			display: block;
		// 			background-color: rgba(#FCF25D,.7);
		// 			position: absolute;
		// 			left: 0;
		// 			top: 0;
		// 			width: 0;
		// 			height: 100%;
		// 			z-index: -1;
		// 		}
		// 	}
		// 	@include media-breakpoint-up(xl) {
		// 		top: 1rem;
		// 	}
		// 	@include media-breakpoint-only(lg) {
		// 		font-size: rem-calc(30);
		// 	}
		// 	span{
		// 		position: relative;
		// 		@include media-breakpoint-down(md) {
		// 			padding:.5rem;
		// 			margin-bottom: 5px;
		// 			&:after{
		// 				content: '';
		// 				display: block;
		// 				background-color: rgba(#FCF25D,.7);
		// 				position: absolute;
		// 				left: 0;
		// 				top: 0;
		// 				width: 0;
		// 				height: 100%;
		// 				z-index: -1;
		// 			}
		// 		}
		// 	}
		// }
		// .section-heading{
		// 	&.is_scrolled{
		// 		&:after{
		// 			transition:.5s;
		// 			width: 100%;
		// 		}
		// 		span{
		// 			&:after{
		// 				transition:.5s;
		// 				width: 100%;
		// 			}
		// 		}
		// 	}
		// }
		// .first__slide__wrap{
		// 	@include media-breakpoint-up(lg) {
		// 		width:50vw;
		// 		position: absolute;
		// 		left:50%;
		// 		top: 0;
		// 	}
		// }
		// .text__block{
		// 	margin-top: 3rem;
		// 	line-height: 2;
		// 	font-size: rem-calc(16);
		// 	padding:0;
		// 	@include media-breakpoint-up(lg) {
		// 		width: 50%;
		// 	}
		// 	@include media-breakpoint-up(md) {
		// 		padding: 0 1rem 0 4rem;
		// 		padding-top: 7rem;
		// 		font-size: rem-calc(18);
		// 	}
		// }
		// .banner__doc{
		// 	@include media-breakpoint-down(md) {
		// 		margin-top: 2rem;
		// 	}
		// }
		// .banner__reserve{
		// 	@include media-breakpoint-down(md) {
		// 		margin-top: 4rem;
		// 	}
		// }
	}
	.section--05{
		background: repeating-linear-gradient(-45deg, #E2F5FD 0, #E2F5FD 7px, white 0, white 14px);
		.head__wrap{
			color:#333;
		}
		.tab-btns{
			display: flex;
			margin-bottom: 6rem;
			@include media-breakpoint-down(sm) {
				flex-wrap:wrap;
			}
			button{
				@include media-breakpoint-down(sm) {
					width: 50%;
					margin-bottom: 1.5rem;
					img{
						width: 100%;
					}
				}
				border:none;
				background-color: transparent;
				padding:0;
				@include opacity-href();
				position: relative;
				&.is_active{
					&:after{
						content: '';
						display: block;
						position: absolute;
						top: 100%;
						left:0;
						right: 0;
						width: 0;
						height: 0;
						margin: auto;
						border-width:15px 10px 0;
						border-style: solid;
						@include media-breakpoint-down(sm) {
							border-width:12px 8px 0;
						}
					}
					&.tab-btn--01{
						&:after{
							border-color: $black transparent transparent transparent;
						}
					}
					&.tab-btn--02{
						&:after{
							border-color: $c-hair transparent transparent transparent;
						}
					}
					&.tab-btn--03{
						&:after{
							border-color: $c-cook transparent transparent transparent;
						}
					}
					&.tab-btn--04{
						&:after{
							border-color: $c-hukusi transparent transparent transparent;
						}
					}
				}
			}
		}
		.content{
			margin-top: -5rem;
			margin-bottom: 5rem;
			transition: height .6s ease-in-out;
			overflow: hidden;
			position: relative;
			display: none;
			&.is_show{
				display: block!important;
				animation: tab-show .5s linear 0s;
				@keyframes tab-show{ 
					from{
						opacity: 0;
						transform:translate(-20px,20px);
					}
					to{
						opacity: 1;
						transform:translateY(0);
					}
				}
			}
		}
	}
	.section--06{
		.container{
			max-width: 685px !important;
		}
	}
	
	.p-instagram{
		// background-color: $orange;
		background-color: #fff;
		// color:white;
		.f-century{
		  position: relative;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		
		  &::before{
			content: '';
			display: inline-block;
			width: 40px;
			height: 40px;
			background: url(#{$dir}/common/icon_insta.png) center/cover no-repeat;
			margin-right: 12px;
			opacity: 0;
			transition: opacity 0.3s linear 1.5s;
		  }
		}
		.is_scrolled{
		  .f-century::before{
			opacity: 1;
		  }
		}
		.head__wrap{
			color:#333;
		}
	}
	.section--09{
	
			padding-bottom: 150px;
		
		// .second__slide{
		// 	position: relative;
		// }
		.intro{
			font-size: rem-calc(18);
		}
		// button{
		// 	border:0;
		// 	background-color: transparent;
		// 	@include media-breakpoint-up(md) {
		// 		height: 53px;
		// 	}
		// 	@include media-breakpoint-down(sm) {
		// 		width: 50px;
		// 	}
		// 	@include opacity-href();
		// 	&.slick-arrow{
		// 		position: absolute;
		// 		bottom: 0;
		// 		top: 0;
		// 		margin: auto;
		// 		z-index: 10;
		// 	}
		// 	&.prev-arrow{
		// 		left: 0;
		// 		@include media-breakpoint-up(md) {
		// 			left: 28%;
		// 		}
		// 	}
		// 	&.next-arrow{
		// 		right: 0;
		// 		@include media-breakpoint-up(md) {
		// 			right: 28%;
		// 		}
		// 	}
		// }
		// .slide_item{
		// 	margin:0 1rem;
		// 	@include media-breakpoint-up(md) {
		// 		margin:0 4rem;
		// 	}
		// 	padding-top: 1.5rem;
		// 	padding-bottom: 1.5rem;
		// 	&:nth-of-type(even){
		// 		transform: rotate(5deg);
		// 	}
		// 	&:nth-of-type(odd){
		// 		transform: rotate(-5deg);
		// 	}
		// }
		// .slick-list,.second__slide{

		// }
	}
	.p-access{
		background: url(#{$dir}/home/bg_circle01.png)no-repeat;
		background-size: 1105px 1077px;
		background-position: top center;
		padding:5rem 0 8rem;
		.head__wrap{
			color:#333;
		}
	}
	.character-wrap{
		row-gap: 20px;
		.character-img{
			&:nth-of-type(even){
				.chara{
					&:hover{
						transform: translateY(-10px) scale(1.05) rotate(-4deg);
					}
				}
			}
			&:nth-of-type(odd){
				.chara{
					transform-origin: center;
					&:hover{
						transform: translateY(-10px) scale(1.05) rotate(4deg);
					}
				}
			}
		}
		.chara{
			cursor: pointer;
			transition: transform 0.3s ease;
			// &:hover{
			// 	transform: translateY(-10px) scale(1.1) rotate(2deg);
			// }
		}
	}
}
