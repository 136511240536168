//
// Typo
//

// text-decoration
// @mixin text-decoration( $decoration: underline, $decoration-hover: none ) {
// 	text-decoration: $decoration;

// 	&:focus,
// 	&:hover {
// 		text-decoration: $decoration-hover;
// 	}
// }

// ellipsis
@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@mixin opacity() {
	transition:.2s;
	&:hover{
		opacity: .7;
	}
}
@mixin opacity-href() {
	transition:.2s;
	text-decoration: none;
	&:hover{
		opacity: .7;
	}
}

@mixin hover-shadow($shadow:#9a9a9a) {
	transition:.2s;
	&:hover{
		box-shadow: 5px 5px 10px 0px $shadow;
	}
}

@mixin hover-color($color:$primary) {
	transition:.2s;
	&:hover{
		opacity: .7;
		color:$primary;
	}
}

@mixin border-dashed-simple($border-color:$primary,$border-width:50%,$border-space:40px) {
	background-image: linear-gradient(90deg,transparent $border-width,#fff 0,#fff),linear-gradient(90deg,$border-color,$border-color);
	background-size: $border-space 5px, 100% 5px;
}

@mixin second-container($max:800px) {
	max-width:$max;
	margin:auto;
	@include media-breakpoint-only(md) {
		max-width: 720px;
	}
	@include media-breakpoint-down(sm) {
		max-width: 540px;
	}
}



// site global btn
@mixin primary_btn($theme_color:$primary){}
// sp overlay
@mixin overlay($theme_color:$black){
	.overlay{
		position: fixed;
		left:0;
		top:0;
		background-color: rgba($theme_color,.5);
		height:100%;
		width:100vw;
		z-index: -10;
    display: none;
    cursor: pointer;
		&.is_active{
			z-index: 100;
			display: block;
		}
	}
}
//btn primary button
@mixin p-a__primary($theme_color:$primary){
	.p-a__primary{
    text-align: center;
		button{
			display: inline-block;
			padding:.7em 2em;
			background-color: $theme_color;
			color:$white;
			border-radius: 10px;
			box-shadow: 0px 5px #4A2525;
			text-decoration: none;
			border:1px solid $theme_color;
			transition:.2s;
			&:hover{
				transform:translateY(5px);
				box-shadow:none;
				color:$white;
			}
		}
  }
}
@mixin p-btn($theme_color:$primary){
  .p-btn{
    text-align: center;
    a{
      text-decoration: none;
      position: relative;
      z-index: 20;
      // padding:.7rem 2rem .7rem 1rem;
      display: inline-flex;
      &:after,
      &:before{
        content: '';
        position: absolute;
        display: block;
        z-index: 10;
        transition:.2s;
      }
      &:after{
        right: 14px;
        top: 0;
        bottom: 0;
        margin: auto;
        width:14px;
        height: 3px;
        background-color: $black;
      }
      &:before{
        right: 12px;
        top: -1px;
        bottom: 0;
        margin: auto;
        width:10px;
        height: 10px;
        transform: rotate(45deg) translate(0px,1px);
        border-top: 3px solid $black;
        border-right: 3px solid $black;
      }
      span{
        display: inline-block;
        position: relative;
        z-index: 10;
        &:after{
          content: '';
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
          height:100%;
          width:2px;
          background-color: $black;
          transition:.3s;
        }
        &:before{
          content: '';
          display: inline-block;
          position: absolute;
          left: 100%;
          top: 0;
          bottom: 0;
          margin: auto;
          height: calc(100% - 4px);
          width:0;
          background-color: white;
          transition:.3s;
        }
      }
      .txt{
        color:$black;
        font-weight: bold;
        min-width:220px;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        padding: .7rem 2rem .7rem 1rem;
        background: #fff;
        border-width:2px 0 2px 2px;
        border-style:solid;
        border-color:$black;
        // background-color: white;
      }
      .chs{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: absolute;
        bottom: 100%;
        right: 0;
        left: 0;
        margin: auto;
        z-index: -1;
        height: 0;
        // visibility: hidden;
      }
      .ch-head{
        display: block;
        opacity: 0;
        transition:.15s;
        visibility: hidden;
        // transition:.2s;
        // @-moz-document url-prefix() {
        // }
        &.ch-head01{
          // transform: translate(-50px,0);
          transform: translate(40px, 80%);
        }
        &.ch-head02{
          position: relative;
          z-index: 10;
          transform: translate(0,80%);
        }
        &.ch-head03{
          transform: translate(-40px, 80%);
          // transform: translate(50px,0);
        }
      }
      &:hover{
        .chs{
          // visibility: visible;
        }
        &:after{
          right: 3px;
        }
        &:before{
          right: 1px;
        }
        .ch-head{
          opacity: 1;
          visibility: visible;
          &.ch-head01{
            transform: translate(15px, 0);
            @-moz-document url-prefix() {
              transform: translate(15px, 0);
            }
          }
          &.ch-head02{
            transform: translate(0,0);
            @-moz-document url-prefix() {
              transform: translate(0, 1px);
            }
          }
          &.ch-head03{
            transform: translate(-15px, 0);
            @-moz-document url-prefix() {
              transform: translate(-15px, 1px);
            }
          }
        }
        span{
          &:after{
            transition:.2s ease-in-out .1s;
            opacity: 0;
            transform: translateX(70px) rotate(2turn);
          }
          &:before{
            transition:.2s ease-in-out .1s;
            width: 20px;
          }
        }
      }
    }
    &.not-arrow{
      a{
        .txt{
          padding: 0.7rem 1rem;
        }
        &:after,
        &:before{
          content: none;
        }
      }
    }
    &.p-btn--lower{
      a{
        .txt{
          border-width:2px;
          border-color:white;
        }
        box-shadow: 0 2px 5px 1px rgba(black,.15);
        &:after,
        &:before{
          right: 10px;
        }
        &:after{
          height: 1px;
        }
        &:before{
          border-width:1px;
        }
        &:hover{
          &:after,
          &:before{
            right: 6px;
          }
        }
        .txt{
          &:not(.not){
            min-width:220px;
          }
        }
      }
      &.p-btn--back{
        a{
          .txt{
            padding:.7rem 1rem .7rem 2rem;
          }
          &:after{
            right: auto;
            left: 9px;
          }
          &:before{
            // right: 8px;
            right: auto;
            left: 8px;
            top: -1px;
            transform: rotate(-135deg) translate(-1px,1px);
            // border-top: 3px solid $black;
            // border-right: 3px solid $black;
          }
        }
      }
      &.p-btn--dark{
        a{
          &:before{
            border-width:1px;
          }
        }
      }
      &.p-btn--school{
        a{
          .txt{
            color:white;
            background-color: $theme_color;
          }
          &:after{
            background-color: white;
          }
          &:after,
          &:before{
            border-color:white;
          }
        }
      }
      &.p-btn--lg{
        a{
          .txt{
            padding:1.2rem 2rem 1.2rem 1rem;
            min-width:250px;
          }
        }
      }
    }
    &.p-btn--gradient{
      a{
        .txt{
          border-color:white;
          color:white;
          background: linear-gradient(135deg, #ec88de 0%,#82e8f5 100%);
        }
        // &:before,
        &:before{
          border-color:#fff;
        }
        &:after{
          background-color: #fff;
        }
        span{
          &:after{
            background-color: #fff;
          }
        }
      }
    }
    &.p-btn--dark{
      a{
        .txt{
          color:white;
          border-color:white;
          background-color: #333;
        }
        &:after{
          background-color: white;
        }
        &:before{
          border-top: 3px solid white;
          border-right: 3px solid white;
        }
        span{
          &:after{
            background-color: white;
          }
          &:before{
            background-color: #333;
          }
        }
      }
    }
  }
}
//btn primary a tag
@mixin p-btn__primary($theme_color:$primary){
	.p-btn__primary{
		text-align: center;
		a{
			display: inline-block;
			padding:.7em 2em;
			background-color: $theme_color;
			color:$white;
			border-radius: 10px;
			box-shadow: 0px 5px #4A2525;
			text-decoration: none;
			border:1px solid $theme_color;
			transition:.2s;
			&:hover{
				transform:translateY(5px);
				box-shadow:none;
				color:$white;
			}
		}
	}
}
//btn white button
@mixin p-btn__white($theme_color:$primary){
	.p-btn__white{
		text-align: center;
		button{
			display: inline-block;
			padding:.7em 2em;
			background-color: #fff;
			color:$theme_color;
			border:1px solid $theme_color;
			border-radius: 10px;
			box-shadow: 0px 5px $gray;
			text-decoration: none;
			transition:.2s;
			&:hover{
				transform:translateY(5px);
				box-shadow:none;
				color:$theme_color;
			}
		}
  }
}
// btn white a tag
@mixin p-a__white($theme_color:$primary){
  .p-a__white{
    text-align: center;
    a{
      display: inline-block;
      padding:.7em 2em;
      background-color: #fff;
      color:$theme_color;
      border:1px solid $theme_color;
      border-radius: 10px;
      box-shadow: 0px 5px $gray;
      text-decoration: none;
      transition:.2s;
      &:hover{
        transform:translateY(5px);
        box-shadow:none;
        color:$theme_color;
      }
    }
  }
}
//line through
@mixin p-line-through($theme_color:$primary){
  .p-line-through{
    text-decoration: line-through;
  }
}
//big font
// @mixin p-large__fz($theme_color:$primary){
// 	.p-large__fz{
// 		font-size: rem-calc(20);
// 	}
// }
// imporntat txt
@mixin p-important__txt($theme_color:$primary){
	.p-important__txt{
		padding:1rem;
		margin-bottom: 1rem;
		border:5px solid $theme_color;
		p{
			margin-bottom: 0;
		}
	}
}
@mixin blockquote($theme_color:$primary){
	//blockquote
	blockquote{
		padding-left: 2rem;
		border-left: 5px solid $theme_color;
		margin-bottom: 1rem;
		@include media-breakpoint-down(sm) {
			padding-left: 1rem;
		}
		cite{
      display: block;
      text-align: right;
		}
	}
}
//custom list
@mixin p-ul-list($theme_color:$primary){
  &.page{
    &:not(.lp){
      .page-contents{
        ul{
          &:not(.list-unstyled){
            list-style: none;
            padding-left: 0;
            li{
              padding-left: .5rem;
              &:before{
                content:"";
                display: inline-block;
                width:1em;
                height:1em;
                vertical-align: middle;
                background: $theme_color;
                margin-right: .5rem;
                margin-bottom: 2px;
              }
              &.iryo--01{
                &:before{
                  background: #7FADD8;
                }
              }
              &.iryo--02{
                &:before{
                  background: #F48E8C;
                }
              }
              &.iryo--03{
                &:before{
                  background: #8CBD97;
                }
              }
              &.iryo--04{
                &:before{
                  // background: $p-green;
                  background: #FF851C;
                }
              }
              
            }
          }
        }
      }
    }
  }
}
//custom list
@mixin p-custom__list($theme_color:$primary){
	.p-custom__list{
		padding-left: 0;
		li{
			padding-left: .5rem;
			&:before{
				content:"";
				display: inline-block;
				width:1em;
				height:1em;
				border-radius: 50%;
        vertical-align: middle;
				background: $theme_color;
				margin-right: .5rem;
				margin-bottom: 2px;
			}
		}
	}
}
//custom list ver2
@mixin p-custom__list__second($theme_color:$primary){
  .p-custom__list__second{
    padding-left: 0;
    li{
      padding-left: .5rem;
      &:before{
        content:"";
        display: inline-block;
        width:1em;
        height:1em;
        vertical-align: middle;
        background: $theme_color;
        margin-right: .5rem;
        margin-bottom: 2px;
      }
    }
  }
}
// definition dotted
@mixin p-definition__lists__dotted($theme_color:$primary){
	.p-definition__lists__dotted {
		display: flex;
		flex-wrap: wrap;
		dt {
			width: 25%;
			border-bottom: 4px dotted $gray;
			margin-bottom: 0;
			padding:1rem .5em;
      font-weight: normal;
			@include media-breakpoint-down(md) {
        width: 100%;
        padding:1rem 0 0;
        border-bottom: none;
			}
		}
		dd {
			border-bottom: 4px dotted $gray;
			margin-bottom: 0;
			margin-left: 4px;
			padding:1rem .5em;
			width: calc(75% - 4px);
			@include media-breakpoint-down(md) {
				width: 100%;
        margin-left: 0;
        padding: 1rem 0 1rem;
			}
		}

  }
}
// definition dash
@mixin p-definition__lists__dashed($theme_color:$primary){
	.p-definition__lists__dashed {
		display: flex;
		flex-wrap: wrap;
		dt {
			width: 25%;
			border-bottom: 3px dashed $gray;
			margin-bottom: 0;
			padding: 1rem .5em;
      font-weight: normal;
			@include media-breakpoint-down(md) {
        width: 100%;
        padding:1rem 0 0;
        border-bottom: none;
			}
		}
		dd {
			border-bottom: 3px dashed $gray;
			margin-bottom: 0;
			margin-left: 3px;
			padding: 1rem .5em;
			width: calc(75% - 3px);
			@include media-breakpoint-down(md) {
				width: 100%;
        margin-left: 0;
        padding: 1rem 0 1rem;
			}
		}
  }
}
// definition default
@mixin p-definition__site($theme_color:$primary){
	.p-definition__site{
		display: flex;
		flex-wrap: wrap;
		dt {
			width: 25%;
			border-bottom: 1px solid $white;
      padding: .5em;
      color:$white;
      background: $theme_color;
      font-weight: normal;
			@include media-breakpoint-down(md) {
        width: 100%;
        border-bottom: 1px solid $theme_color;
			}
		}
		dd {
			border-bottom: 1px solid #ccc;
			border-right: 1px solid #ccc;
      margin-bottom: 0;
      padding: .5em;
      width: calc(75% - 3px);
      background-color:$white;
			@include media-breakpoint-down(md) {
				width: 100%;
			}
    }
    dt,
    dd{
      border-left: 1px solid $theme_color;
      &:first-of-type{
        @include media-breakpoint-up(lg) {
          border-top: 1px solid #ccc;
        }
      }
      &:last-of-type{
        border-bottom: 1px solid #ccc;
      }
    }
  }
  &.parent-group{
    .p-definition__site{
      dt{
        color:$black;
      }
    }
  }
}
// default table
@mixin table($theme_color:$primary){
  table{
    caption{
      caption-side: top;
      color:$black;
    }
    th,
    td{
      border: 1px solid #dee2e6;
      padding: .75rem;
    }
    th{
      color:$white;
      background-color: $theme_color;
      font-weight: bold;
      @include media-breakpoint-up(md) {
        min-width: 8em;
      }
    }
  }
  &.parent-group{
    table{
      th{
        color: $black;
      }
    }
  }
}
// table calendar ver1
@mixin p-table-wrap($theme_color:$primary){
	.p-table-wrap {
		overflow: auto;
		.table {
			@include media-breakpoint-down(md) {
				min-width: 550px;
			}
			th,
			td {
				min-width: 8em;
			}
			caption {
				caption-side: initial;
				color: $black;
				font-weight: bold;
			}
			thead {
				th {
					background: $theme_color;
					color: $white;
					border-bottom: 0;
				}
			}
			tbody {
				th {
					background: $theme_color;
					color: $white;
				}
			}
		}
		&.p-calendar{
			text-align: center;
			.table{
				min-width: unset;
				tbody{
					td{
						&:first-of-type{
							background-color: $gray-200;
						}
					}
				}
				td,
				th{
					min-width: unset;
				}
			}
		}
  }
  &.parent-group{
    .p-table-wrap {
      overflow: auto;
      .table {
        thead {
          th {
            color: $black;
          }
        }
        tbody {
          th {
            color: $black;
          }
        }
      }
    }
  }
}
// table calendar ver2
@mixin p-table-wrap__second($theme_color:$primary){
	.p-table-wrap__second {
		overflow: auto;
		.table {
			@include media-breakpoint-down(md) {
				min-width: 550px;
			}
			th,
			td {
				min-width: 8em;
			}
			caption {
				caption-side: initial;
				color: $black;
				font-weight: bold;
			}
			thead {
				tr{
					border-bottom: 2px solid $gray-500;
					th{
						border-bottom: 0;
						border-top: 0;
					}
				}
			}
			tbody {
				tr{
					border-bottom: 1px solid $gray-500;
				}
			}
		}
		&.p-striped{
			.table{
				thead {
					tr{
						border-bottom: 2px solid $theme_color;
					}
				}
			}
		}
  }
}
// tab
@mixin p-tab($theme_color:$primary){
	.p-tab{
    margin-bottom: 2rem;
    .nav-tabs{
      padding-left: 0;
      border-bottom: 0;
      .nav-item{
        &:not(:last-of-type){
          margin-right: 2px;
        }
        .nav-link{
          background: $gray-500;
          text-decoration: none;
          color:$white;
          border:1px solid $gray-500;
          &.active{
            background: $theme_color;
            color:$white;
            border:1px solid $theme_color;
          }
        }
      }
    }
    .tab-content{
      border:1px solid $gray-500;
    }
  }
}
// mini block
@mixin p-box__link($theme_color:$primary){
	.p-box__link{
    .box__block{
      margin-bottom: 1rem;
      @include media-breakpoint-down(sm) {
        padding-left:0;
        padding-right:0;
      }
      .inner{
        border:1px solid $theme_color;
        position: relative;
        transition:.2s;
        &:hover{
          opacity: .7;
        }
        p{
          margin-bottom: 0;
        }
        .box__title,
        .box__content{
          padding: 0.5rem 1rem;
        }
        .box__title{
          background:$theme_color;
          a{
            color:$white;
            text-decoration: none;
            font-weight: bold;
            &:before{
              content:"";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width:100%;
              height:100%;
            }
            span{
              position: relative;
              padding-left: 20px;
              &:before{
                content:"";
                display: inline-block;
                position: absolute;
                left:0;
                top:0;
                width:15px;
                height:15px;
                vertical-align: middle;
                border-radius: 50%;
                background-color: $white;
                margin-right: 5px;
              }
              &:after{
                content:"";
                display: inline-block;
                position: absolute;
                left:5px;
                top:3px;
                width:0;
                height:0;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 5px solid $theme_color;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
}
//mini block second
@mixin p-box__link__second($theme_color:$primary){
  .p-box__link__second{
    .box__block{
      margin-bottom: 1rem;
      @include media-breakpoint-down(sm) {
        padding-left:0;
        padding-right:0;
      }
      .inner{
        border:1px solid $theme_color;
        position: relative;
        transition:.2s;
        p{
          margin-bottom: 0;
        }
        .box__title,
        .box__content{
          padding: 0.5rem 1rem;
        }
        .box__title{
          background:$theme_color;
          color:$white;
          span{
            position: relative;
            padding-left: 20px;
            &:before{
              content:"";
              display: inline-block;
              position: absolute;
              left:0;
              top:0;
              width:15px;
              height:15px;
              vertical-align: middle;
              border-radius: 50%;
              background-color: $white;
              margin-right: 5px;
            }
            &:after{
              content:"";
              display: inline-block;
              position: absolute;
              left:5px;
              top:3px;
              width:0;
              height:0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: 5px solid $theme_color;
              vertical-align: middle;
            }
          }
        }
        .box__content{
          ul{
            margin-bottom: 0;
            padding-left: 0;
            li{
              &:not(:last-of-type){
                margin-bottom: 0.5rem;
              }
              a{
                text-decoration: none;
                &:hover{
                  text-decoration: underline;
                }
                &:before{
                  content:"";
                  display: inline-block;
                  border-left:5px solid $theme_color;
                  border-top:5px solid transparent;
                  border-bottom:5px solid transparent;
                  vertical-align: middle;
                  margin-right: 10px;
                  margin-bottom: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
// form
@mixin p-form($theme_color:$primary){
	.p-form{
    form{
      button{
        min-width:160px;
      }
      dl{
        display: flex;
        flex-wrap:wrap;
        border:1px solid $gray-600;
        dt,
        dd{
          padding:1rem;
        }
        dt{
          width:20%;
          background: $gray-300;
          @include media-breakpoint-only(md) {
            width:25%;
          }
          @include media-breakpoint-down(sm) {
            border-bottom: 1px solid $gray-600;
            width:100%;
            br{
              display: none;
            }
            span.badge{
              margin-left: .5rem;
            }
          }
          @include media-breakpoint-up(md) {
            &:not(:last-of-type){
              border-bottom: 1px solid $gray-600;
            }
          }

          span{
            color:$white;
            background: $red;
          }
        }
        dd{
          width:80%;
          @include media-breakpoint-up(md) {
            border-left: 1px solid $gray-600;
          }
          margin-bottom: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          @include media-breakpoint-only(md) {
            width:75%;
          }
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          &:not(:last-of-type){
            border-bottom: 1px solid $gray-600;
          }
          &.radio{
            label{
              margin-right: 1rem;
              position: relative;
              cursor: pointer;
              margin-bottom: 0;
              span{
                position: relative;
                display: flex;
                align-items: center;
                white-space: nowrap;
                padding: 0.4rem 0.2rem 0.4rem 20px;
                &:before{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:0;
                  top:0;
                  bottom:0;
                  margin:auto;
                  width:18px;
                  height:18px;
                  border:1px solid $gray-600;
                  padding-top:5px;
                  padding-bottom: 5px;
                  border-radius: 50%;
                }
                &:after{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:3px;
                  top:0;
                  bottom:0;
                  width:12px;
                  height:12px;
                  margin:auto;
                  background-color: $theme_color;
                  border-radius: 50%;
                  opacity: 0;
                  visibility: hidden;
                  transform:scale(.2);
                  transition:.15s;
                }
              }
              input{
                position: absolute;
                z-index: -1;
                opacity: 0;
                &:checked + span{
                  color:$theme_color;
                  &:after{
                    opacity: 1;
                    visibility: visible;
                    transform:scale(1);
                  }
                }
                &:focus + span{
                  outline:3px solid $gray-500;
                  @include media-breakpoint-up(md) {
                    padding-top:5px;
                    padding-bottom:5px;
                  }
                }
              }
            }
          }
          &.checkbox{
            label{
              margin-right: 1rem;
              cursor: pointer;
              span{
                position: relative;
                display: flex;
                align-items: center;
                white-space: nowrap;
                padding-left: 25px;
                padding-right: 5px;
                &:before{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:0;
                  top:0;
                  bottom:0;
                  margin:auto;
                  width:14px;
                  height:14px;
                  border:1px solid $gray-600;
                  border-radius: 3px;
                  margin-left: 5px;
                }
                &:after{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:12px;
                  top:-7px;
                  bottom:0;
                  width:7px;
                  height:15px;
                  margin:auto;
                  border-right:3px solid $theme_color;
                  border-bottom: 3px solid $theme_color;
                  opacity: 0;
                  visibility: hidden;
                  transform:rotate(45deg) scale(0);
                  transition:.15s;
                }
              }
              input{
                position: absolute;
                left:0;
                top:0;
                opacity: 0;
                &:checked + span{
                  color:$theme_color;
                  &:after{
                    opacity: 1;
                    visibility: visible;
                    animation:checkScale .15s forwards;
                    @keyframes checkScale {
                      0%{
                        transform:rotate(45deg) scale(0);
                      }
                      50%{
                        transform:rotate(45deg) scale(1.4);
                      }
                      100%{
                        transform:rotate(45deg) scale(1);
                      }
                    }
                  }
                }
                & + span{
                  @include media-breakpoint-up(md) {
                    padding-top:5px;
                    padding-bottom:5px;
                  }
                }
                &:focus + span{
                  outline:3px solid $gray-500;
                  // @include media-breakpoint-up(md) {
                  //   padding-top:5px;
                  //   padding-bottom:5px;
                  // }
                }
              }
            }
          }
        }
        input{
          border-radius: 5px;
          border:1px solid $gray-600;
          padding: 5px;
          &[type="radio"]{
            margin-right: 5px;
          }
          &[type="email"]{
            width:70%;
            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }
          &#address{
            width: 100%;
          }
          &:focus{
            outline:3px solid $gray-400;
            @include media-breakpoint-up(md) {
              padding-top:5px;
              padding-bottom: 5px;
            }
          }
        }
        textarea,
        select{
          border-radius: 5px;
          border:1px solid $gray-600;
          &:focus{
            outline:3px solid $gray-400;
          }
        }
        .select__wrap{
          border:1px solid $gray-500;
          overflow: hidden;
          border-radius: 5px;
          padding-right: 30px;
          position: relative;
          &:after{
            content:"";
            display: block;
            position: absolute;
            right:5px;
            top:0;
            bottom:0;
            margin:auto;
            width:0;
            height:0;
            border-top:8px solid $gray-600;
            border-left:5px solid transparent;
            border-right:5px solid transparent;
          }
          select{
            width:200%;
            height: 30px;
            border:none;
            appearance: none;
            padding-left: .5rem;
            &:focus{
              @supports (-ms-ime-align: auto) {
                // Edge のみ適用される
                background: #006ef7;
                color:$white;
              }
            }
          }
        }
        label{
          margin-bottom: 0;
          position: relative;
          margin-bottom: 0.5rem;
          input,
          textarea,
          select{
            &:focus{
              outline:3px solid $gray-400;
              @include media-breakpoint-up(md) {
                padding-top:5px;
                padding-bottom:5px;
              }
              // @include media-breakpoint-up(md) {
              //   padding-top:5px;
              //   padding-bottom: 5px;
              // }
            }
          }
        }
        textarea{
          width:100%;
        }
      }
    }
  }
}
@mixin p-form-second($theme_color:$teal){
	.p-form-second{
    ::placeholder{
      color:$gray-500;
    }
    .p-next-btn__wrap,
    .p-back-btn__wrap{
      button{
        padding: 0.7rem 0.5rem;
      }
    }
    .privacy{
      text-align: center;
      @include media-breakpoint-up(md) {
        display: flex;
      }
      input{
        transform: scale(1.2);
      }
      label{
        margin-right: 1rem;
        cursor: pointer;
        // span{
        //   position: relative;
        //   display: flex;
        //   align-items: center;
        //   white-space: nowrap;
        //   padding-left: 25px;
        //   padding-right: 5px;
        //   &:before{
        //     content:"";
        //     display: inline-block;
        //     position: absolute;
        //     left:0;
        //     top:0;
        //     bottom:0;
        //     margin:auto;
        //     width:14px;
        //     height:14px;
        //     border:1px solid $gray-600;
        //     border-radius: 3px;
        //     margin-left: 5px;
        //   }
        //   &:after{
        //     content:"";
        //     display: inline-block;
        //     position: absolute;
        //     left:12px;
        //     top:-7px;
        //     bottom:0;
        //     width:7px;
        //     height:15px;
        //     margin:auto;
        //     border-right:3px solid $theme_color;
        //     border-bottom: 3px solid $theme_color;
        //     opacity: 0;
        //     visibility: hidden;
        //     transform:rotate(45deg)scale(0);
        //     transition:.15s;
        //   }
        // }
        // input{
        //   position: absolute;
        //   left:0;
        //   top:0;
        //   opacity: 0;
        //   &:checked + span{
        //     color:$theme_color;
        //     &:after{
        //       opacity: 1;
        //       visibility: visible;
        //       animation:checkScale .15s forwards;
        //       @keyframes checkScale {
        //         0%{
        //           transform:rotate(45deg)scale(0);
        //         }
        //         50%{
        //           transform:rotate(45deg)scale(1.4);
        //         }
        //         100%{
        //           transform:rotate(45deg)scale(1);
        //         }
        //       }
        //     }
        //   }
        //   & + span{
        //     @include media-breakpoint-up(md) {
        //       padding-top:5px;
        //       padding-bottom:5px;
        //     }
        //   }
        //   &:focus + span{
        //     outline:3px solid $gray-500;
        //     // @include media-breakpoint-up(md) {
        //     //   padding-top:5px;
        //     //   padding-bottom:5px;
        //     // }
        //   }
        // }
      }
    }
    form{
      font-size: rem-calc(16);
      button{
        min-width:190px;
      }
      dl{
        dt,
        dd{
          padding:1rem 1rem 1rem 0;
        }
        dt{
          font-size: rem-calc(20);
          border-bottom: 3px solid #D8D8D8;
          position: relative;
          padding: 0;
          &:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 100%;
            width:20%;
            height:3px;
            background-color: $theme_color;
          }
          label{
            display: flex;
            align-items: center;
          }
          .badge{
            margin-left: .5rem;
            color:$white;
            background: $red;
            font-size: rem-calc(11);
            border-radius: 0;
            padding:0.3rem .6rem;
          }
        }
        dd{
          margin-bottom: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 1rem;
          @include media-breakpoint-only(md) {
            width:75%;
          }
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          &.radio{
            label{
              margin-right: 1rem;
              position: relative;
              cursor: pointer;
              margin-bottom: 0;
              span{
                position: relative;
                display: flex;
                align-items: center;
                white-space: nowrap;
                padding: 0.4rem 0.2rem 0.4rem 20px;
                &:before{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:0;
                  top:0;
                  bottom:0;
                  margin:auto;
                  width:18px;
                  height:18px;
                  border:1px solid $gray-600;
                  padding-top:5px;
                  padding-bottom: 5px;
                  border-radius: 50%;
                }
                &:after{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:3px;
                  top:0;
                  bottom:0;
                  width:12px;
                  height:12px;
                  margin:auto;
                  background-color: $theme_color;
                  border-radius: 50%;
                  opacity: 0;
                  visibility: hidden;
                  transform:scale(.2);
                  transition:.15s;
                }
              }
              input{
                position: absolute;
                z-index: -1;
                opacity: 0;
                &:checked + span{
                  color:$theme_color;
                  &:after{
                    opacity: 1;
                    visibility: visible;
                    transform:scale(1);
                  }
                }
                &:focus + span{
                  outline:3px solid $gray-500;
                  @include media-breakpoint-up(md) {
                    padding-top:5px;
                    padding-bottom:5px;
                  }
                }
              }
            }
          }
          &.checkbox{
            label{
              margin-right: 1rem;
              cursor: pointer;
              span{
                position: relative;
                display: flex;
                align-items: center;
                white-space: nowrap;
                padding-left: 25px;
                padding-right: 5px;
                &:before{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:0;
                  top:0;
                  bottom:0;
                  margin:auto;
                  width:14px;
                  height:14px;
                  border:1px solid $gray-600;
                  border-radius: 3px;
                  margin-left: 5px;
                }
                &:after{
                  content:"";
                  display: inline-block;
                  position: absolute;
                  left:12px;
                  top:-7px;
                  bottom:0;
                  width:7px;
                  height:15px;
                  margin:auto;
                  border-right:3px solid $theme_color;
                  border-bottom: 3px solid $theme_color;
                  opacity: 0;
                  visibility: hidden;
                  transform:rotate(45deg) scale(0);
                  transition:.15s;
                }
              }
              input{
                position: absolute;
                left:0;
                top:0;
                opacity: 0;
                &:checked + span{
                  color:$theme_color;
                  &:after{
                    opacity: 1;
                    visibility: visible;
                    animation:checkScale .15s forwards;
                    @keyframes checkScale {
                      0%{
                        transform:rotate(45deg) scale(0);
                      }
                      50%{
                        transform:rotate(45deg) scale(1.4);
                      }
                      100%{
                        transform:rotate(45deg) scale(1);
                      }
                    }
                  }
                }
                & + span{
                  @include media-breakpoint-up(md) {
                    padding-top:5px;
                    padding-bottom:5px;
                  }
                }
                &:focus + span{
                  outline:3px solid $gray-500;
                  // @include media-breakpoint-up(md) {
                  //   padding-top:5px;
                  //   padding-bottom:5px;
                  // }
                }
              }
            }
          }
        }
        input{
          // border-radius: 5px;
          border:1px solid $gray-500;
          padding: 5px;
          height:50px;
          &[type="radio"]{
            margin-right: 5px;
          }
          &[type="email"]{
            width:70%;
            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }
          &#address{
            width: 100%;
          }
          &:focus{
            outline:3px solid $gray-400;
            @include media-breakpoint-up(md) {
              padding-top:5px;
              padding-bottom: 5px;
            }
          }
          &#school-name,
          &#name{
            @include media-breakpoint-up(md) {
              width:300px;
            }
          }
          &#school-year{
            @include media-breakpoint-up(md) {
              width:100px;
            }
          }
        }
        textarea,
        select{
          // border-radius: 5px;
          border:1px solid $gray-500;
          background-color: #fff;
          &:focus{
            outline:3px solid $gray-400;
          }
        }
        .select__wrap{
          border:1px solid $gray-500;
          overflow: hidden;
          // border-radius: 5px;
          padding-right: 30px;
          position: relative;
          height: 50px;
          background-color: #fff;
          &:after{
            content:"";
            display: block;
            position: absolute;
            right:5px;
            top:0;
            bottom:0;
            margin:auto;
            width:0;
            height:0;
            border-top:8px solid $gray-600;
            border-left:5px solid transparent;
            border-right:5px solid transparent;
            pointer-events: none;
          }
          select{
            width:200%;
            height: 30px;
            border:none;
            appearance: none;
            padding-left: .5rem;
            height: 50px;
            &:focus{
              @supports (-ms-ime-align: auto) {
                // Edge のみ適用される
                background: #006ef7;
                color:$white;
              }
            }
          }
        }
        label{
          margin-bottom: 0;
          position: relative;
          input,
          textarea,
          select{
            &:focus{
              outline:3px solid $gray-400;
              @include media-breakpoint-up(md) {
                padding-top:5px;
                padding-bottom:5px;
              }
              // @include media-breakpoint-up(md) {
              //   padding-top:5px;
              //   padding-bottom: 5px;
              // }
            }
          }
        }
        textarea{
          width:100%;
        }
      }
    }
  }
}
// form confirm btn
@mixin p-form__btn__wrap($theme_color:$primary){
  .p-form__btn__wrap{
    // @include media-breakpoint-up(md) {
    //   display: flex;
    //   justify-content: center;
    // }
    // .item{
    //   &:nth-of-type(2){
    //     @include media-breakpoint-up(md) {
    //       margin-left: 0.5rem;
    //     }
    //   }
    // }
    .p-back-btn__wrap{
      text-align: center;
      margin-bottom: 0.5rem;
      // display:inline-block;
      // @include media-breakpoint-up(md) {
      //   margin-right: 1rem;
      // }
      button{
        color:$white;
        background: $gray-600;
        padding-left: 30px;
        position: relative;
        transition:.2s;
        &:hover{
          cursor: pointer;
          background: darken($gray-600,5%);
        }
        &:before{
          content:"";
          display: block;
          position: absolute;
          z-index: 1;
          left:15px;
          top:0;
          bottom:0;
          margin:auto;
          width:10px;
          height:10px;
          // background-color: $white;
          border-width:2px 0 0 2px;
          border-color:white 0 0 white;
          border-style: solid;
          transform:rotate(-45deg);
        }
      }
    }
    .p-next-btn__wrap{
      text-align: center;
      margin-bottom: 0.5rem;
      .submit-btn{
        cursor: not-allowed;
        pointer-events: none;
        background-color: $gray-600;
        &.is_agree{
          cursor:pointer;
          pointer-events: auto;
          background-color: $theme_color;
          &:hover{
            background-color: darken($theme_color,10%);
          }
        }
      }
      button{
        color:$white;
        background: $theme_color;
        padding-right: 30px;
        position: relative;
        transition:.2s;
        &:hover{
          cursor: pointer;
          background: darken($theme_color,5%);
        }
        &:before{
          content:"";
          display: block;
          position:absolute;
          z-index:1;
          right:10px;
          top:0;
          bottom:0;
          margin:auto;
          width:10px;
          height:10px;
          // background-color: $white;
          border-width:2px 2px 0 0;
          border-color:white white 0 0;
          border-style: solid;
          transform:rotate(45deg);
        }
      }
    }
  }
}
// breadcrumb
@mixin p-breadcrumb($theme_color:$primary){
  .p-breadcrumb{
    background-color: initial;
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: 0;
    a{
      color:$blue;
    }
    .breadcrumb-item+.breadcrumb-item:before{
      content:">";
    }
    &:nth-of-type(2){
      border-top: 1px dashed $blue;
      border-radius:0;
      padding-bottom: 1rem;
    }
    &:not(:nth-of-type(2)){
      padding-bottom: 0;
      padding-bottom: 0.75rem;
    }
  }
}
// pagination
@mixin p-pagination($theme_color:$primary,$fontColor:white,$numberLinkColor:white){
	.p-pagination{
    padding-left: 0;
    justify-content: center;
    flex-wrap:wrap;
    li{
      @include media-breakpoint-up(lg) {
        margin-bottom: 0.5rem;
      }
    }
    .page-item{
      a{
        border:1px solid $theme_color;
        text-decoration: none;
        color:$numberLinkColor;
        &:hover{
          background: $theme_color;
          color:$fontColor;
        }
      }
      &.active{
        .page-link{
          background-color: $theme_color;
          border-color:$theme_color;
          color:$fontColor;
        }
      }
    }
    .pagination__move__wrap{
      @include media-breakpoint-up(lg) {
        &:first-of-type{
          margin-right: .5rem;
        }
        &:last-of-type{
          margin-left: .5rem;
        }
      }
      @include media-breakpoint-down(md) {
        width:100%;
        &:first-of-type{
          margin-bottom: 1rem;
        }
        &:last-of-type{
          margin-top: 1rem;
        }
      }
    }
    .move_btn__wrap{
      padding-left: 0;
      display: flex;
      @include media-breakpoint-down(md) {
        justify-content: center;
      }
      ul{
        padding-left: 0;
        list-style:none;
      }
      &.move--prev{
        .move_btn{
          @include media-breakpoint-up(lg) {
            margin-right: 0.2rem;
          }
        }
      }
      &.move--next{
        .move_btn{
          @include media-breakpoint-up(lg) {
            margin-left: 0.2rem;
          }
        }
      }
      .move_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include media-breakpoint-down(md) {
          &:nth-of-type(odd){
            margin-right: 5px;
          }
        }
        a{
          @include media-breakpoint-down(md) {
            width:100%;
          }
          transition:.2s;
          &:hover{
            background-color: darken($theme_color,5%);
          }
        }
        &.first-page,
        &.last-page{
          span{
            position: absolute;
            top:0;
            bottom:0;
            margin:auto;
            display: inline-block;
            height:15px;
            &:before{
              content:"";
              position: absolute;
              display: inline-block;
              width:0;
              height:0;
              top:0;
              bottom:0;
              margin:auto;
              border-top: 8px solid transparent;
              border-bottom: 8px solid transparent;
            }
            &:after{
              content:"";
              position: absolute;
              display: inline-block;
              width:0;
              height:0;
              top:0;
              bottom:0;
              margin:auto;
              border-top: 8px solid transparent;
              border-bottom: 8px solid transparent;
            }
          }
        }
        &.first-page{
          a{
            padding-left: 40px;
          }
          span{
            left:10px;
            border-left: 2px solid $fontColor;
            &:before{
              left:0;
              border-right: 10px solid $fontColor;
            }
            &:after{
              left:10px;
              border-right: 10px solid $fontColor;
            }
          }
        }
        &.last-page{
          a{
            padding-right: 40px;
          }
          span{
            right:10px;
            border-right: 2px solid $fontColor;
            &:before{
              right:0;
              border-left: 10px solid $fontColor;
            }
            &:after{
              right:10px;
              border-left: 10px solid $fontColor;
            }
          }
        }
        &.prev-page,
        &.next-page{
          a{
            span{
              position: absolute;
              top: 0;
              bottom: 0;
              margin:auto;
              border-bottom: 8px solid transparent;
              border-top: 8px solid transparent;
              width:0;
              height:0;
            }
          }
        }
        &.prev-page{
          a{
            padding-left: 25px;
            span{
              left:10px;
              border-right: 8px solid $fontColor;
            }
          }
        }
        &.next-page{
          a{
            padding-right: 25px;
          }
          span{
            right:10px;
            border-left: 8px solid $fontColor;
          }
        }
        a{
          padding:.4rem .5rem;
          background-color: $theme_color;
          color:$fontColor;
          text-decoration: none;
          border-radius: 5px;
          position: relative;
          @include media-breakpoint-down(md) {
            text-align: center;
          }
        }
      }
    }
  }
}
// youtube responsive iframe
@mixin p-youtube__wrap($theme_color:$primary){
  .p-youtube__wrap{
    position: relative;
    padding-top: 56.25%;
    width:100%;
    // margin:2rem auto;
    // @include media-breakpoint-down(md) {
    //   // width:100%;
    //   // padding-top: 55%;
    // }
    // @include media-breakpoint-down(sm) {
    //   // padding-top: 75%;
    // }
    iframe{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
    }
  }
}

@mixin p-arrow(){
  .p-arrow{
    a{
      display: inline-block;
      background-color: $black;
      text-align: center;
      padding:.5rem;
      font-weight: bold;
      font-size: rem-calc(16);
      max-width:220px;
      width: 100%;
      position: relative;
      color:white;
      @include opacity-href();
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        width: 11px;
        height: 11px;
        border-bottom: 1px solid white;
        border-right: 1px solid white;
        transform: rotate(-45deg);
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 19px;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: 1px;
        background-color: white;
      }
    }
  }
}

@mixin kyupeen(){
  animation: kyupeen 1.5s infinite linear;
  @keyframes kyupeen {
    0% {
      transform: rotate(10deg) scale(1);
    }
    50% {
      transform: rotate(5deg) scale(.7);
    }
    100% {
      transform: rotate(10deg) scale(1);
    }
  }
}

@mixin font-gradient($pc-font:30,$sp-font:23,$start-color:#ed87de,$end-color:#82e8f5,$center-color:white) {
  .p-gradient{
    font-weight: bold;
    font-size: rem-calc($pc-font);
    @include media-breakpoint-down(sm) {
      font-size: rem-calc($sp-font);
    }
    .gradient__inner{
      position: relative;
      padding:0.3rem 2rem;
      color:white;
      &:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background: linear-gradient(45deg, $start-color 0%,$end-color 100%);
        // z-index: -1;
      }
    }
    .gradient__text{
      opacity: 0;
      transform: translateX(-20px);
    }
    &.is_scrolled{
      .gradient__inner{
        &:before{
          width: 100%;
          transition:width .5s;
        }
      }
      .gradient__text{
        opacity: 1;
        transform: translateX(0);
        transition:1s ease .3s;
      }
    }
  }
}
@mixin p-accordion($theme_color:$primary,$btn_color:white){
  .p-accordion{
    font-size: rem-calc(16);
    margin-bottom: 5rem;
    & > li{
      border-bottom: 1px solid $theme_color;
      &:first-of-type{
        border-top: 1px solid $theme_color;
      }
    }
    .accBtn{
      padding:.5rem;
      background-color: transparent;
      border:none;
      position: relative;
      display: block;
      width: 100%;
      text-align: left;
      padding: 1.4rem 20px;
      @include opacity();
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        width:15px;
        height: 15px;
        background-color: transparent;
        border-width:0 3px 3px 0;
        border-style: solid;
        border-color: transparent $theme_color $theme_color transparent;
        transform: rotate(45deg);
        transition:transform .2s;
      }
      &.is_active{
        background-color: $theme_color;
        color:$btn_color;
        &:after{
          transform: rotate(-135deg);
          border-color:$btn_color;
        }
      }
    }
    .accContent{
      // padding: 0 .5rem;
      // font-weight: bold;
      padding: 10px 20px;
      // margin:1rem 0;
      display: block;
      background: #fff;
      position: relative;
      // animation: tab-show2 .5s linear 0s;
      // @keyframes tab-show2{
      //   from{
      //     opacity: 0;
      //     transform:translateX(20px);
      //   }
      //   to{
      //     opacity: 1;
      //     transform:translateX(0);
      //   }
      // }
      // &:after{
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left:20px;
      //   width:0;
      //   height: 1px;
      //   background-color: $theme_color;
      //   transition:.2s;
      // }
      &.is_hidden{
        // visibility: hidden;
        display: none;
        height:0!important;
      }
      // &.is_show{
      //   &:after{
      //     width:90%;
      //   }
      // }
      // display: none;
    }
  }
}

@mixin p-article(){
  .p-article{
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
    }
    .p-article__item{
      margin-bottom: 2rem;
    }
    &.p-article--01{
      .p-article__item{
        @include media-breakpoint-up(md) {
          width:calc((100% - 10%) / 3);
          &:not(:nth-of-type(3n)):not(:last-of-type){
            margin-right: 5%;
          }
        }
      }
    }
    &.p-article--02{
      .p-article__item{
        @include media-breakpoint-up(md) {
          width:calc((70% - 5%) / 2);
          &:not(:nth-of-type(even)):not(:last-of-type){
            margin-right: 5%;
          }
        }
      }
    }
    &.p-article--01-no-break{
      .p-article__item{
        width:calc((100% - 10%) / 3);
        &:not(:nth-of-type(3n)):not(:last-of-type){
          margin-right: 5%;
        }
      }
    }
    &.p-article--02-no-break{
      .p-article__item{
        width:calc((70% - 5%) / 2);
        &:not(:nth-of-type(even)):not(:last-of-type){
          margin-right: 5%;
        }
      }
    }
    .p-article__head{
      background: transparent;
      margin:0;
      padding:0;
      font-weight: 500;
      font-size: rem-calc(18);
    }
    .p-article__thumb{
      position: relative;
      width: 100%;
      padding-top: 56.25%;
    }
    .p-article__img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      position: absolute;
      left: 0;
      top: 0;
      font-family: 'object-fit: cover;';
    }
    .p-article__description{
      font-size: rem-calc(14);
      margin-top: rem-calc(10);
      line-height: 2;
    }
    .p-article__head{
      margin-top: rem-calc(10);
    }
    .p-article__body{
    }
  }
}


@mixin p-link-block($theme_color:$primary,$fontColor:$primary){
  .p-link-block{
    max-width:850px;
    margin: auto;
    display: flex;
    flex-wrap:wrap;
    .p-link-block__item{
      margin-bottom: 2rem;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: calc((100% - 6%) / 2);
        &:not(:nth-of-type(even)):not(:last-of-type){
          margin-right: 6%;
        }
      }
    }
    .p-link-block__link{
      @include opacity-href();
      color:$black;
      display: block;
    }
    .p-link-block__head{
      border:1px solid $theme_color;
      border-radius: 13px;
      padding: 1rem 2.5rem 1rem 1rem;
      position: relative;
      font-size: rem-calc(18);
      color:$fontColor;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        width: 11px;
        height: 11px;
        border-bottom: 1px solid $theme_color;
        border-right: 1px solid $theme_color;
        transform: rotate(-45deg);
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 19px;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: 1px;
        background-color: $theme_color;
      }
    }
    .p-link-block__description{
      padding: .5rem;
      font-size: rem-calc(14);
    }
  }
}

@mixin p-more($theme_color:$primary,$fontColor:$primary){
  .p-more{
    position: relative;
    height:20em;
    overflow: hidden;
    &.is_full-show{
      height: auto;
      overflow: visible;
    }
    .btn__wrap{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      &.is_show{
        display: none;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 300%;
        background:linear-gradient(to bottom,rgba(white,.5) 0,white 100%);
        z-index: -1;
      }
    }
    .more-btn{
      position: relative;
      border-radius: 100px;
      border:1px solid $theme_color;
      color:$fontColor;
      text-align: center;
      padding:.5rem 2rem;
      margin: auto;
      background-color: #fff;
      width:240px;
      font-size: rem-calc(20);
      display: block;
      // position: relative;
      @include opacity();
      &.is_hidden{
        opacity:0;
        visibility: hidden;
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 30px;
        bottom: 0;
        margin: auto;
        width: 1px;
        height:20px;
        background-color: $theme_color;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        right: 26px;
        bottom: 16px;
        margin: auto;
        width: 10px;
        height:10px;
        transform: rotate(45deg);
        border-bottom: 1px solid $theme_color;
        border-right: 1px solid $theme_color;
      }
    }
  }
}

@mixin head($theme_color:$primary,$fontColor:$black){
  h1{
    &:not(.not){
      font-size: rem-calc(23);
      background-color: $theme_color;
      text-align: center;
      padding:1.3rem .5rem;
      margin: rem(20) 0 rem(30);
      color:$fontColor;
      @include media-breakpoint-up(lg) {
        font-size: rem-calc(25);
        margin: rem(20) 0 rem(40);
      }
    }
  }
  .h1{
    font-size: rem-calc(23);
    background-color: $theme_color;
    text-align: center;
    padding:1.3rem .5rem;
    margin: rem(20) 0 rem(30);
    color:$theme_color;
    font-weight: bold;
    background: repeating-linear-gradient(-45deg,rgba($theme_color,.2),rgba($theme_color,.2) 4px,#fff 0,#fff 6px);
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(25);
      margin: rem(20) 0 rem(40);
    }
  }
  h2{
    &:not(.not){
      font-size: rem-calc(23);
      padding-bottom: 1rem;
      background: linear-gradient(to right,$theme_color 0,$theme_color 30%,#E2E2E2 30%) no-repeat;
      background-size:100% 6px;
      background-position: bottom;
      margin: rem(40) 0 rem(20);
      @include media-breakpoint-up(md) {
        background: linear-gradient(to right,$theme_color 0,$theme_color 15%,#E2E2E2 15%) no-repeat;
        background-size:100% 6px;
        background-position: bottom;
        margin: rem(80) 0 rem(30);
        font-size: rem-calc(25);
      }
    }
  }
  h3{
    &:not(.not){
      font-size: rem-calc(21);
      background-color: $theme_color;
      padding:.4rem .4rem .4rem 1rem;
      margin: rem(30) 0 rem(20);
      color:$fontColor;
      @include media-breakpoint-up(lg) {
        font-size: rem-calc(22);
        margin: rem(30) 0 rem(20);
      }
    }
  }
  h4{
    &:not(.not){
      font-size: rem-calc(21);
      padding-bottom: 0.2rem;
      margin: rem(30) 0 rem(20);
      border-bottom: 2px dotted $black;
      @include media-breakpoint-up(lg) {
        font-size: rem-calc(22);
        margin: rem(30) 0 rem(20);
      }
    }
  }
  h5{
    font-size: rem-calc(20);
    padding-left: 0.5rem;
    margin: rem(30) 0 rem(20);
    border-left: 5px double $black;
  }
  h6{
    font-size: rem-calc(18);
    padding-left: 0.5rem;
    margin: rem(30) 0 rem(20);
    position: relative;
    display: flex;
    align-items: center;
    &:before{
      content: '';
      display: inline-block;
      width:11px;
      height: 3px;
      background-color: $black;
      vertical-align: middle;
      margin-right: 0.3rem;
    }
  }
  &.page02{
    h1{
      background: repeating-linear-gradient(-45deg,rgba($theme_color,.2),rgba($theme_color,.2) 4px,#fff 0,#fff 6px);
      color:$theme_color;
      font-weight: 900;
    }
  }
}

@mixin oc-card($theme_color:$primary,$fontColor:$black){
  .p-oc-card{
    .content{
      transition: height .6s ease-in-out;
      overflow: hidden;
      position: relative;
      @include media-breakpoint-down(sm) {
        padding: 5px;
      }
      &.is_show{
        display: block!important;
        animation: tab-show .5s linear 0s;
        @keyframes tab-show{
          from{
            opacity: 0;
            transform:translate(-20px,20px);
          }
          to{
            opacity: 1;
            transform:translateY(0);
          }
        }
      }
      .oc__info{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding:10px 1.5rem;
        position: relative;
        z-index: 20;
        // @include media-breakpoint-down(sm){
        //   padding:10px 0.5rem;
        // }
      }
      .oc__article{
        height: 100%;
        position: relative;
      }
      .oc__title{
        font-size: rem-calc(18);
        margin-bottom: 1rem;
      }
      .oc-time__head{
        margin-right: 2rem;
      }
      ul{
        @include media-breakpoint-up(md) {
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          justify-content: center;
          // margin-top: -30px;
        }
      }
      li{
        position: relative;
        margin-bottom: 61px;
        background-color: #fff;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
        // width: 100%;
        @include media-breakpoint-up(md) {
          width:40%;
        }
        &:nth-of-type(odd):not(:last-of-type){
          @include media-breakpoint-up(md) {
            margin-right: 100px;
          }
        }
        a{
          height: 100%;
          width: 100%;
          display: block;
          color:$black;
          text-decoration: none;
          cursor: pointer;
          @include opacity();
        }
      }
      time{
        display: flex;
        color:white;
        text-align: center;
        align-items: flex-end;
        .year{
          font-size: rem-calc(15);
          line-height: 1;
        }
        .date{
          font-size: rem-calc(26);
          line-height: 1;
        }
      }
      .thumb{
        position: relative;
        padding-top: 62.5%;
        img{
          width: 100%;
          height: 100%;
          object-fit:cover;
          position: absolute;
          left: 0;
          top: 0;
          font-family: 'object-fit: cover;';
        }
      }
      .study-cat{
        // position: absolute;
        // left:5px;
        // top: 5px;
        min-width:115px;
        background-color: #fff;
        font-weight: bold;
        font-size: rem-calc(15);
        @include media-breakpoint-down(sm){
          min-width:85px;
        }
      }
      figcaption{
        padding:1rem;
        min-height:180px;
        @include media-breakpoint-down(sm) {
          min-height: 140px;
        }
      }
      .oc-cat{
        &.this_closed{
          .oc__article{
            &:after{
              content: 'お申し込みを\A締め切りました';
              white-space: pre;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
              background-color: rgba(black,.8);
              color:white;
              font-weight: bold;
              font-size: rem-calc(18);
              display: flex;
              align-items: center;
              justify-content: center;
              @include media-breakpoint-up(md) {
                font-size: rem-calc(23);
              }
            }
          }
        }
      }
      .oc-cat--hsc{
        .oc__info{
          background-color: $p-pink;
        }
        img{
          border-width:0 5px 5px 5px;
          border-style:solid;
          border-color: $p-pink;
        }
        .study-cat,
        .oc__title,
        .oc-time__head{
          color: $p-pink;
        }
      }
      .oc-cat--htc{
        .oc__info{
          background-color: $p-orange;
        }
        img{
          border-width:0 5px 5px 5px;
          border-style:solid;
          border-color: $p-orange;
        }
        .study-cat,
        .oc__title,
        .oc-time__head{
          color: $p-orange;
        }
      }
      .oc-cat--iryo{
        .oc__info{
          background-color: $c-hukusi;
        }
        img{
          border-width:0 5px 5px 5px;
          border-style:solid;
          border-color: $c-hukusi;
        }
        .study-cat,
        .oc__title,
        .oc-time__head{
          color: $c-hukusi;
        }
      }
    }
  }
  &.parent-group{
    .p-oc-card{
      .content{
        margin-top: -5rem;
        margin-bottom: 80px;
      }
    }
  }
  .p-oc-card__modal{
    /* Animation */
    @keyframes mmfadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes mmfadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    @keyframes mmslideIn {
      from {
        transform: translateY(10%);
      }
      to {
        transform: translateY(0);
      }
    }
    @keyframes mmslideOut {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-10%);
      }
    }
    &.micromodal-slide {
      display: none;
      position: relative;
      z-index: 10000;
    }
    &.micromodal-slide.is-open {
      display: block;
    }
    &.micromodal-slide[aria-hidden="false"] .modal__overlay {
      animation: mmfadeIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
    }
    &.micromodal-slide[aria-hidden="false"] .modal__container {
      animation: mmslideIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
    }
    &.micromodal-slide[aria-hidden="true"] .modal__overlay {
      animation: mmfadeOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
    }
    &.micromodal-slide[aria-hidden="true"] .modal__container {
      animation: mmslideOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
    }
    &.micromodal-slide .modal__container,
    &.micromodal-slide .modal__overlay {
      will-change: transform;
    }
    &.modal-cat{
      &.modal-cat--hsc{
        $catColor:$c-hair;
        .modal__title{
          background-color: $catColor;
        }
        .oc-cat__label{
          background-color: $catColor;
        }
        .oc-detail{
          border-top: 1px solid $catColor;
        }
        .oc-detail__head{
          color:$catColor;
          border-bottom: 1px solid $catColor;
        }
        .oc-detail__body{
          border-bottom: 1px solid $catColor;
        }
      }
      &.modal-cat--htc{
        $catColor:$c-cook;
        .modal__title{
          background-color: $catColor;
        }
        .oc-cat__label{
          background-color: $catColor;
        }
        .oc-detail{
          border-top: 1px solid $catColor;
        }
        .oc-detail__head{
          color:$catColor;
          border-bottom: 1px solid $catColor;
        }
        .oc-detail__body{
          border-bottom: 1px solid $catColor;
        }
      }
      &.modal-cat--iryo{
        $catColor:$c-hukusi;
        .modal__title{
          background-color: $catColor;
        }
        .oc-cat__label{
          background-color: $catColor;
        }
        .oc-detail{
          border-top: 1px solid $catColor;
        }
        .oc-detail__head{
          color:$catColor;
          border-bottom: 1px solid $catColor;
        }
        .oc-detail__body{
          border-bottom: 1px solid $catColor;
        }
      }
    }
    .modal__overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
    }
    .modal__container {
      max-height: 90vh;
      margin: auto 10px;
      overflow-y: auto;
      background-color: #fff;
      padding-top: rem(80);
      padding-bottom: rem(80);
      font-size: rem-calc(14);
      @include media-breakpoint-down(sm) {
        font-size: rem-calc(16);
      }
    }
    .modal__close{
      background-color: $gray;
      color:white;
      border:none;
      padding: 0 10px;
      right: 10px;
      position: absolute;
      top: 10px;
    }
    .modal__close::before {
      content: "\2715";
      font-size: rem-calc(22);
    }
    .modal__close__bottom{
      background-color: $gray;
      text-align: center;
      padding:.5rem 2rem;
      color:white;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border:none;
      font-size: rem-calc(16);
      &::after{
        content: "\2715";
        margin-left: 1rem;
        font-size: rem-calc(20);
      }
    }
    .modal__header {
    }
  
    .modal__title {
      font-weight: bold;
      font-size: rem(20);
      background-color: $c-hair;
      color:white;
      padding:1rem;
    }
    .oc-cat{
      display: flex;
      flex-wrap:wrap;
      font-size: rem-calc(14);
      font-weight: bold;
      .oc-cat__label{
        margin-right: 10px;
        margin-bottom: 8px;
        border-radius: 50px;
        color:white;
        padding:.5rem 1.5rem;
        text-align: center;
      }
    }
    .modal__content {
      margin-top: 2rem;
      margin-bottom: 2rem;
      line-height: 1.5;
      font-size: rem-calc(16);
      .oc-thumb{
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        .oc-thumb__img{
          width: 100%;
          height: 100%;
          object-fit:cover;
          position: absolute;
          left: 0;
          top: 0;
          font-family: 'object-fit: cover;';
        }
      }
    }
    .modal__overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
    }
    .oc-detail{
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap:wrap;
      }
      // align-items: flex-start;
      .oc-detail__head,
      .oc-detail__body{
        padding:1rem;
        margin-bottom: 0;
      }
      .oc-detail__head{
        font-weight: bold;
        text-align: center;
        @include media-breakpoint-up(md) {
          text-align: left;
          flex-basis: 180px;
        }
      }
      .oc-detail__body{
        @include media-breakpoint-down(sm) {
          padding-left: 0;
          padding-right: 0;
          font-size: rem-calc(14);
        }
        @include media-breakpoint-up(md) {
          flex-basis: calc(100% - 180px);
        }
      }
    }
  }
}

@mixin tabBtn{
  .tab-btns2{
    display: flex;
    flex-wrap:wrap;
    margin-bottom: 6rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
    button,a{
      border-width:2px;
      border-style:solid;
      padding:.5rem;
      font-weight: bold;
      display: block;
      width: 100%;
      transition:.2s;
      @include opacity-href();
      position: relative;
      font-size: rem-calc(16);
      @include media-breakpoint-down(md) {
        // width: 50%;
        margin-bottom: 1rem;
        padding:.5rem;
        font-size: rem-calc(14);
      }

      &:focus{
        outline: none;
      }
    }
    // li{
    //   .tab-btn2{
    //     &.tab-btn--01{
    //       border-color:$p-pink;
    //       color:$white;
    //       background-color: $p-pink;
    //       }
    //     &.tab-btn--02,
    //     &.tab-btn--03{
    //       border-color:$p-orange;
    //       color:$white;
    //       background-color: $p-orange;
    //       }
    //     &.tab-btn--04,
    //     &.tab-btn--05,
    //     &.tab-btn--06{
    //       border-color:$c-hukusi;
    //       color:$white;
    //       background-color: $c-hukusi;
    //       }
    //     &.is_active{
    //       .tab-btn--01{
    //         color:$p-pink;
    //         background-color: $white;
    //       }
    //       &.tab-btn--02,
    //       &.tab-btn--03{
    //         color:$p-orange;
    //         background-color: $white;
    //       }
    //       &.tab-btn--04,
    //       &.tab-btn--05,
    //       &.tab-btn--06{
    //         color:$c-hukusi;
    //         background-color: $white;
    //       }
    //     }
    //   }
    // }

    a{
      display:flex;
      justify-content: center;
      align-items: center;
      &:hover{
        opacity:0.7;
        text-decoration: none;
      }
    }
  }
}
@mixin maskIcon($url){
	-webkit-mask-image:url($url);
	mask-image:url($url);
	-webkit-mask-position:center center;
	mask-position:center center;
	-webkit-mask-repeat:no-repeat;
	mask-repeat:no-repeat;
	-webkit-mask-size:contain;
	mask-size:contain;
}
