body.now-future{
    &.nf-2024{
        $dir: '../img/lp/now-future';
        .nf-now_text{
            margin-bottom: 0;
        }
        
        .nf-hero{
            background-image:url($dir + '/2024/bg-item-sp_01.jpg');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_01.jpg'); 
            }
        }
        .nf-hero-star{
            img{
                position:absolute;
            }
            img:nth-of-type(1){
                width:6%;
                height:auto;
                top: 47px;
                left: vw(18,375);
                @include queries(md){
                    top: vw(128,1500);
                    left: vw(208,1500);
                    width: vw(33.86,1500);
                }
            }
            img:nth-of-type(2){
                width:5%;
                height:auto;
                @include queries(md){
                    top:vw(57,1500);
                    left:vw(308,1500);
                    bottom:auto;
                    width: vw(24.13,1500);
                }
            }
            img:nth-of-type(3){
                width:vw(21.26,375);
                height: auto;
                bottom:vw(23.1,375);
                left:vw(30.3,375);
                max-width: 24px;
                @include queries(md){
                    bottom:vw(113,1500);
                    left:vw(296.4,1500);
                    width:vw(24.13,1500);
                }
            }
            img:nth-of-type(4){
                top:vw(109,375);
                left:vw(137,375);
                @include queries(md){
                    top:vw(147.5,1500);
                    left:vw(594.4,1500);
                    width: vw(26.84,1500);
                    height: vw(33.44,1500);
                }
                @include queries(xl){
                    top:147.5px;
                    left:vw(594.4,1500);
                }

            }
            img:nth-of-type(5){
                @include queries(md){
                    width: vw(26.84,1500);
                    height: vw(34.44,1500);
                    top: vw(179,1500);
                    right: vw(576,1500);
                }
                @include queries(xl){
                }
                
            }
            img:nth-of-type(6){
                width:5%;
                height:auto;
                @include queries(md){
                    width: vw(26.83,1500);
                    height: vw(34.44,1500);
                    top:vw(237,1500);
                    bottom:auto;
                    right:vw(396,1500);
                }
                
            }
            img:nth-of-type(7){
                @include queries(md){
                    width: vw(26.83,1500);
                    height: vw(34.44,1500);
                    top: vw(107,1500);
                    right: vw(120,1500);

                }
                
            }
        }
        .nf-members_text{
            &-01{
                line-height:lh-calc(11,13);
                margin:5px 0 15px;
            }
            &-02{
                line-height:lh-calc(15,13);
                margin:5px 0 20px;
            }
        }
        .nf-members-name_text{
            font-size:rem(16);
        &.lh-0{
            line-height:calc(17 / 16);
        }
        }
        .nf-now-heading2{
            padding-left:0;
            margin-left:vw(25,375);
            width:28%;
            min-width:106px;
            max-width:295px;
            position:relative;
            z-index: 1;

            @include queries(md){
                width:100%;
                max-width:1280px;
            }
            img{
                width:100%;
                @include queries(md){
                    max-width:295px;
                }
            }
        }
        .now-member-range .col-7{
            align-self: stretch;
        }
        
        .nf-now-members-items{
            @include queries(xl){
                margin-top:200px;
            }
        }
        #now01.nf-now-heading2,#now02.nf-now-heading2,
        #now03.nf-now-heading2,#now04.nf-now-heading2,
        #now05.nf-now-heading2{
            img{
                width:28%;
                max-width:295px;
                min-width:105px;     
            }
        }
        .bg_now01{
            background-image:url($dir + '/2024/bg-item-sp_04.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_04.png');
            }
            .nf-now-members-range{
                margin-top:226px;
                @include queries(lg){
                    margin-top:100px;
                }
            }
            .nf-now-members-col{
                &:nth-of-type(2){
                    .vertical-item_text{
                        @include queries(md){
                            top: 50px;
                        }
                        @include queries(lg){
                            top: 80px;
                        }
                    }
                }
            }
            .nf-now-vertical{
                .nf-now-vertical_text{
                    @include queries(xl){
                        top:245px;
                    
                    }

                    .now-vertical_text02{
                        margin-top: 20px;
                        @include queries(lg){
                            margin-top: 30px;
                        }
                    }

                }
            }
            .members-item_text02{
                &.add-2024{
                    margin-top:92px;
                }
                &.add-2024-1{
                    margin-top:144px;
                }
            }
        }
        
        .bg_now02{
            background-image:url($dir + '/2024/bg-item-sp_05.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_05.png');
            }
            .nf-now-members-range{
                margin-top:170px;

                @include queries(lg){
                    margin-top: 70px;
                }
            
            }
            h3.nf-now-vertical_text{
                .now-vertical_text02{
                    margin-top: 138px;
                    @include queries(lg){
                        margin-top: 200px;
                    }
                }
            }
            .members-item_text01{
                &.add-2024-1{
                    margin-top:62px;
                }
            }

            .members-item_text02{
                &.add-2024-1{
                    margin-top:46px;
                }
                &.add-2024-2{
                    margin-top:60px;   
                }
                &.add-2024-3{
                    margin-top: 223px;
                    @include queries(md){
                        margin-top: 181px;
                    }
                    @include queries(lg){
                        margin-top: 201px;
                    }
                }
                &.add-2024-4{
                    margin-top: 20px;
                    
                }
        }
    }
        .bg_now03{
            background-image:url($dir + '/2024/bg-item-sp_06.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_06.png');
            }
            .nf-now-vertical{
                .nf-now-vertical_text{
                    @include queries(md){
                        top: 180px;
                    }
                    @include queries(xl){
                        top: 290px;
                    }
                }
            }
            h3.nf-now-vertical_text{
                
                .now-vertical_text02{
                    margin-top: 132px;
                    
                    @include queries(lg){
                        margin-top: 192px;
                    }
                }
            }
            .nf-now-members-range{
                margin-top: 165px;
                @include queries(lg){
                    margin-top: 90px;
                }
                @include queries(xxl){
                    margin-top: 70px;
                }
            }
            .nf-now-vertical_text.vertical-item_text{
                &.add-2024-1{
                    top:20px;
                }

            }
            .members-item_text02{
                &.add-2024-2{
                    margin-top:70px;
                    @include queries(lg){
                        margin-top: 100px;
                    }
                }
                &.add-2024-3{
                    margin-top:179px;
                }
                &.add-2024-4{
                    margin-top:40px;
                    @include queries(lg){
                        margin-top: 70px;
                    }
                }
                &.add-2024-5{
                    margin-top:70px;
                    
                }
                
            }
        }
        .bg_now04{
            background-image:url($dir + '/2024/bg-item-sp_07.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_07.png');
            }
            .nf-now-members-range{
                margin-top:159px;
                @include queries(lg){
                    margin-top: 109px;
                }
            }
            .nf-now-vertical{
                .nf-now-vertical_text{
                    transform: translateX(30px);
                    @include queries(lg){
                        transform: translateX(0);
                    }
                    .now-vertical_text02{
                        margin-top:30px;
                        @include queries(lg){
                            margin-top: 50px;
                        }
                        
                    }
                }
            }
            
            .members-item_text02{
                &.add-2024-1{
                    margin-top:50px;
                    @include queries(lg){
                        margin-top: 40px;
                    }
                }
                &.add-2024-2{
                    margin-top:62px;
                }
                &.add-2024-3{
                    margin-top:80px;
                }
                &.add-2024-4{
                    margin-top:170px;
                }
                
            }
        }
        .bg_now05{
            background-image:url($dir + '/2024/bg-item-sp_08.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_08.png');
            }
            .nf-now-members-range{
                margin-top:155px;
                @include queries(lg){
                    margin-top: 55px;
                }
            }
            .nf-now-vertical {
                .nf-now-vertical_text{
                    transform: translateX(50px);
                    @include queries(lg){
                        transform: translateX(10px);
                        top: 263px;
                    }
                    .now-vertical_text02{
                        margin-top:10px;
                        @include queries(lg){
                            margin-top: 30px;
                        }
                    }
                }
            }
            .members-item_text02{
                &.add-2024-1{
                    margin-top:175px;
                }
                &.add-2024-2{
                    margin-top:140px;
                    @include queries(lg){
                        margin-top:160px;
                    }
                }
                &.add-2024-3{
                    margin-top:106px;
                    @include queries(lg){
                        margin-top:76px;
                    }
                }
            }
        }

        #future01.nf-now-heading2,#future04.nf-now-heading2{
            img{
                width:25%;
                max-width:240px;
                min-width:85px;
            }
        }
        .bg_future01{
            background-image:url($dir + '/2024/bg-item-sp_09.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_09.png');
            }
            .nf-now-members-range{
                margin-top:210px;
                @include queries(lg){
                    margin-top: 140px;
                }
                @include queries(xl){
                    margin-top: 110px;
                }
            }
            .nf-now-vertical {
                .nf-now-vertical_text{
                    top:-30px;
                    // right: 45px;
                    left: 0;
                    right: 45px;
                    left: auto;
                    margin-right: 0;
                    @include queries(md){
                        top:200px;
                        margin-right: auto;
                        left: 0; right: 0;
                        transform: translateX(-30px);
                    }
                    @include queries(xl){top:220px;}
                    .now-vertical_text02{
                        margin-top:197px;
                        @include queries(lg){
                            margin-top: 307px;
                        }
                    }
                }

            }
            .members-item_text02{
                &.add-2024-1{
                    margin-top:20px;
                    
                }
                &.add-2024-2{
                    margin-top:50px;
                    @include queries(lg){
                        margin-top: 105px;
                    }
                    
                }
                &.add-2024-3{
                    margin-top:50px;
                }
                &.add-2024-4{
                    margin-top:50px;
                    @include queries(lg){
                        margin-top: 60px;
                    }
                    
                }
            }
        }
        #future02.nf-now-heading2{
            @include queries(md){
                position:relative;
                z-index:1;
            }
            @include queries(xl){
                column-gap: 30px;
            }
            img{
                &:nth-of-type(1){
                    width:25%;
                    max-width:240px;
                    min-width:85px;
                }
                &:nth-of-type(2){
                    max-width:210px;
                    min-width:77px;
                    width:22%;
                }
            }
        }
        .bg_future02{
            background-image:url($dir + '/2024/bg-item-sp_10.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_10.png');
            }
            .nf-now-vertical {
                .nf-now-vertical_text{
                    @include queries(xl){
                        top:190px;
                    }
                    .now-vertical_text01{
                        padding-top:15px;
                    }
                    .now-vertical_text02{
                    margin-top:121px;
                        @include queries(lg){
                            margin-top: 221px;
                        }
                    }
                }

            }
            .nf-now-members-range{
                margin-top: 225px;
                
                @include queries(lg){
                    margin-top: 140px;
                }
                @include queries(xl){
                    margin-top: 105px;
                }

            }
            .nf-now-members_img{
                // max-width: 100% !important;
                img{
                    // width:100%;
                }
            }
            .now-member-range{
                .col-7{
                    flex: 0 0 54.33333%;
                    max-width: 54.33333%;
                }
                .col-5{
                    flex: 0 0 45.66667%;
                    max-width: 194px;
                }
            }
            .members-item_text02{
                &.add-2024-1{
                    margin-top:120px;
                    
                }
                &.add-2024-2{
                    margin-top:123px;
                    @include queries(lg){
                        margin-top: 133px;
                    }
                }
                &.add-2024-3{
                    margin-top:219px;
                }
                &.add-2024-4{
                    @include queries(lg){
                        margin-top: 60px;
                    }
                }
            }
        }

        #future03.nf-now-heading2{
            @include queries(md){
                position:relative;
                z-index:1;
            }
            @include queries(xl){
                column-gap: 30px;
            }
            img{
                &:nth-of-type(1){
                    max-width:362px;
                    min-width:130px;
                    width:35%;
                }
                &:nth-of-type(2){
                    max-width:315px;
                    min-width:112px;
                    width:30%;
                }
            }
            
        }

        .bg_future03{
            background-image:url($dir + '/2024/bg-item-sp_11.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_11.png');
            }
            .nf-now-vertical {
                .nf-now-vertical_text{
                    @include queries(lg){
                        top:293px;
                        transform:translateX(-30px);
                    }
                
                    .now-vertical_text02{
                    
                    }
                }

            }
            .nf-now-members-range{
                margin-top: 210px;
                @include queries(lg){
                    margin-top: 140px;
                }
                @include queries(xl){
                    margin-top: 100px;
                }
            }
            .members-item_text02{
                &.add-2024-1{
                    margin-top:40px;
                    
                }
                &.add-2024-2{
                    margin-top:138px;
                }
                &.add-2024-3{
                    margin-top:80px;
                }
                &.add-2024-4{
                    margin-top:58px;
                    @include queries(lg){
                        margin-top: 38px;
                    }
                }
            }
            
        }
        #future04.nf-now-heading2{
            img{
                width:22%;
                min-width:85px;
                max-width:233px;
            }
        }
        .bg_future04{
            background-image:url($dir + '/2024/bg-item-sp_02.png');
            @include queries(md){
                background-image:url($dir + '/2024/bg-item-pc_02.png');
            }
            .nf-now-vertical {
                .nf-now-vertical_text{
                    top:30px;
                    @include queries(md){top:140px;}
                    @include queries(lg){top:240px;}
                    .now-vertical_text02{
                    margin-top:140px;
                        @include queries(lg){
                            margin-top:200px;
                        }
                    }
                }
            }
            .nf-now-members-range{
                margin-top:185px;
                @include queries(lg){
                    margin-top: 75px;
                }
                @include queries(xl){
                    margin-top: 45px;
                }
            }
            .nf-now-members-name_text{
                span{
                    @include queries(md){
                        line-height:0.9;
                    }
                    @include queries(lg){
                        line-height:1.2;
                    }
                }
            }
            .now-member-range{
                .col-7{
                    @include queries(md){
                        flex: 0 0 62.33333%;
                        max-width: 62.33333%;
                    }
                    @include queries(lg){
                        flex: 0 0 60.33333%;
                        max-width: 60.33333%;
                    }
                }
                .col-5{
                    @include queries(md){
                        flex: 0 0 37.66667%;
                        max-width: 37.66667%;
                    }
                    @include queries(lg){
                        flex: 0 0 39.66667%;
                        max-width: 39.66667%;
                    }
                }
            }
            .nf-now-members_text{
                margin-top:16px;
            }
            .members-item_text02{
                &.add-2024-1{
                    margin-top:60px;
                }
                &.add-2024-2{
                    margin-top:70px;
                    @include queries(lg){
                        margin-top: 40px;
                    }
                }
                &.add-2024-3{
                    margin-top:32px;
                }
            }
        }

        #future05.nf-now-heading2{
            @include queries(md){
                transform: translateY(100px);
            }
            @include queries(xl){
                transform: translateY(200px);
            }
            img{
                width:52%;
                min-width:197px;
                max-width:554px;
            }
        }
        .nf-now-bg.nf-future-bg{
            .bg_future05{
                background-image:url($dir + '/2024/bg-item-sp_03.png');
                background-position: top left;
                margin-top:64px;
                @include queries(md){
                    background-image:url($dir + '/2024/bg-item-pc_03.png');
                }
                .nf-now-vertical {
                    .nf-now-vertical_text{
                    top:-60px;
                    left:20px;
                    transform: translateX(50px);
                    @include queries(md){
                        left:-30px;
                        top:80px;
                    }
                    @include queries(lg){
                        top:210px;
                        transform: translateX(120px);
                    }
                    @include queries(xl){
                        left:90px;
                     
                    }
                    @include queries(xxl){
                        left:10px;
                     
                    }
                        .now-vertical_text02{
                            margin-top: 10px;
                            @include queries(lg){
                                margin-top: 30px;
                            }
                            
                        }
                    }
                }
                .nf-now-members-range{
                    margin-top:185px;
                    @include queries(lg){
                        margin-top: 125px;
                    }
                }
                .nf-now-vertical_text.vertical-item_text{
                    &.add-2024-1{
                        top:20px;
                    }
                }
                .nf-now-members_text{
                    @include queries(md){
                        margin-top:5px;
                    }
                    @include queries(lg){
                        margin-top:13px;
                    }
                }
                .members-item_text02{
                    &.add-2024-2{
                        margin-top:85px;
                        @include queries(lg){
                            margin-top: 105px;
                        }
                    }
                    &.add-2024-3{
                        margin-top:125px;
                    }
                    &.add-2024-4{
                        margin-top:65px;
                        @include queries(lg){
                            margin-top: 45px;
                        }
                    }
                    &.add-2024-5{
                        margin-top:125px;
                    }
                }
            }

        }
    }
}