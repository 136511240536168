@charset "UTF-8";
@font-face {
  font-family: 'notosansJP';
  font-style: normal;
  font-weight: 400;
  src: url("./NotoSansJP-Regular.woff2") format("woff2"), url("./NotoSansJP-Regular.woff") format("woff"), url("./NotoSansJP-Regular.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: 'notosansJP';
  font-style: normal;
  font-weight: 500;
  src: url("./NotoSansJP-Medium.woff2") format("woff2"), url("./NotoSansJP-Medium.woff") format("woff"), url("./NotoSansJP-Medium.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: 'notosansJP';
  font-style: normal;
  font-weight: 700;
  src: url("./NotoSansJP-Bold.woff2") format("woff2"), url("./NotoSansJP-Bold.woff") format("woff"), url("./NotoSansJP-Bold.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: 'notosansJP';
  font-style: normal;
  font-weight: 900;
  src: url("./NotoSansJP-Black.woff2") format("woff2"), url("./NotoSansJP-Black.woff") format("woff"), url("./NotoSansJP-Black.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: 'TategakiNotosansJP';
  font-style: normal;
  font-weight: 400;
  src: url("./tategaki_NotoSansJP-Regular.woff2") format("woff2"), url("./tategaki_NotoSansJP-Regular.woff") format("woff"), url("./tategaki_NotoSansJP-Regular.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: 'TategakiNotosansJP';
  font-style: normal;
  font-weight: 500;
  src: url("./tategaki_NotoSansJP-Medium.woff2") format("woff2"), url("./tategaki_NotoSansJP-Medium.woff") format("woff"), url("./tategaki_NotoSansJP-Medium.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: 'TategakiNotosansJP';
  font-style: normal;
  font-weight: 700;
  src: url("./tategaki_NotoSansJP-Bold.woff2") format("woff2"), url("./tategaki_NotoSansJP-Bold.woff") format("woff"), url("./tategaki_NotoSansJP-Bold.ttf") format("ttf");
  font-display: swap;
}

@font-face {
  font-family: 'TategakiNotosansJP';
  font-style: normal;
  font-weight: 900;
  src: url("./tategaki_NotoSansJP-Black.woff2") format("woff2"), url("./tategaki_NotoSansJP-Black.woff") format("woff"), url("./tategaki_NotoSansJP-Black.ttf") format("ttf");
  font-display: swap;
}

img {
  max-width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}

img.alignleft {
  float: left;
  margin-right: 1rem;
}

img.alignright {
  float: right;
  margin-left: 1rem;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a:hover img, a:focus img {
  opacity: .8;
}

@-webkit-keyframes stepImg01 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 200% 0;
  }
}

@keyframes stepImg01 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 200% 0;
  }
}

:root {
  --blue: #007bff;
  --indigo: #8160F3;
  --purple: #8A57FC;
  --pink: #DB76A4;
  --red: #dc3545;
  --orange: #F4CB35;
  --yellow: #FCF25D;
  --green: #9AC144;
  --teal: #47B2B0;
  --cyan: #49B4CA;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #F4CB35;
  --secondary: #DB76A4;
  --success: #9AC144;
  --info: #49B4CA;
  --warning: #FCF25D;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 540px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1025px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(33, 34, 36, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "notosansJP";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #333;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(33, 34, 36, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #F4CB35;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #F4CB35;
}

.table tbody + tbody {
  border-top: 2px solid #F4CB35;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #F4CB35;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #F4CB35;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fcf0c6;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f9e496;
}

.table-hover .table-primary:hover {
  background-color: #fbeaae;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #fbeaae;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f5d9e6;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ecb8d0;
}

.table-hover .table-secondary:hover {
  background-color: #f0c5d9;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f0c5d9;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e3eecb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #cadf9e;
}

.table-hover .table-success:hover {
  background-color: #d9e8b8;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d9e8b8;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #cceaf0;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a0d8e3;
}

.table-hover .table-info:hover {
  background-color: #b8e2ea;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b8e2ea;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fefbd2;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdf8ab;
}

.table-hover .table-warning:hover {
  background-color: #fdf9b9;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fdf9b9;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #F4CB35;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #333;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #333;
  background-color: #F4CB35;
  border-color: #F4CB35;
}

.btn-primary:hover {
  color: #333;
  background-color: #f2c211;
  border-color: #e9ba0d;
}

.btn-primary:focus, .btn-primary.focus {
  color: #333;
  background-color: #f2c211;
  border-color: #e9ba0d;
  box-shadow: 0 0 0 0.2rem rgba(215, 180, 53, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #333;
  background-color: #F4CB35;
  border-color: #F4CB35;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #333;
  background-color: #e9ba0d;
  border-color: #ddb00c;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 180, 53, 0.5);
}

.btn-secondary {
  color: #333;
  background-color: #DB76A4;
  border-color: #DB76A4;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #d35890;
  border-color: #d04e89;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #d35890;
  border-color: #d04e89;
  box-shadow: 0 0 0 0.2rem rgba(194, 108, 147, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #333;
  background-color: #DB76A4;
  border-color: #DB76A4;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #d04e89;
  border-color: #ce4482;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 108, 147, 0.5);
}

.btn-success {
  color: #333;
  background-color: #9AC144;
  border-color: #9AC144;
}

.btn-success:hover {
  color: #fff;
  background-color: #84a737;
  border-color: #7d9e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #84a737;
  border-color: #7d9e34;
  box-shadow: 0 0 0 0.2rem rgba(139, 172, 65, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #333;
  background-color: #9AC144;
  border-color: #9AC144;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #7d9e34;
  border-color: #759431;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 172, 65, 0.5);
}

.btn-info {
  color: #333;
  background-color: #49B4CA;
  border-color: #49B4CA;
}

.btn-info:hover {
  color: #fff;
  background-color: #35a1b7;
  border-color: #3399ad;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #35a1b7;
  border-color: #3399ad;
  box-shadow: 0 0 0 0.2rem rgba(70, 161, 179, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #333;
  background-color: #49B4CA;
  border-color: #49B4CA;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3399ad;
  border-color: #3090a4;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 161, 179, 0.5);
}

.btn-warning {
  color: #333;
  background-color: #FCF25D;
  border-color: #FCF25D;
}

.btn-warning:hover {
  color: #333;
  background-color: #fbef37;
  border-color: #fbee2b;
}

.btn-warning:focus, .btn-warning.focus {
  color: #333;
  background-color: #fbef37;
  border-color: #fbee2b;
  box-shadow: 0 0 0 0.2rem rgba(222, 213, 87, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #333;
  background-color: #FCF25D;
  border-color: #FCF25D;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #333;
  background-color: #fbee2b;
  border-color: #fbed1e;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 213, 87, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #333;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #333;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #333;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(218, 219, 220, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #333;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #333;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 219, 220, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #F4CB35;
  border-color: #F4CB35;
}

.btn-outline-primary:hover {
  color: #333;
  background-color: #F4CB35;
  border-color: #F4CB35;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 203, 53, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #F4CB35;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #333;
  background-color: #F4CB35;
  border-color: #F4CB35;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 203, 53, 0.5);
}

.btn-outline-secondary {
  color: #DB76A4;
  border-color: #DB76A4;
}

.btn-outline-secondary:hover {
  color: #333;
  background-color: #DB76A4;
  border-color: #DB76A4;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 118, 164, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #DB76A4;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #333;
  background-color: #DB76A4;
  border-color: #DB76A4;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 118, 164, 0.5);
}

.btn-outline-success {
  color: #9AC144;
  border-color: #9AC144;
}

.btn-outline-success:hover {
  color: #333;
  background-color: #9AC144;
  border-color: #9AC144;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(154, 193, 68, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #9AC144;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #333;
  background-color: #9AC144;
  border-color: #9AC144;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(154, 193, 68, 0.5);
}

.btn-outline-info {
  color: #49B4CA;
  border-color: #49B4CA;
}

.btn-outline-info:hover {
  color: #333;
  background-color: #49B4CA;
  border-color: #49B4CA;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 180, 202, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #49B4CA;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #333;
  background-color: #49B4CA;
  border-color: #49B4CA;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 180, 202, 0.5);
}

.btn-outline-warning {
  color: #FCF25D;
  border-color: #FCF25D;
}

.btn-outline-warning:hover {
  color: #333;
  background-color: #FCF25D;
  border-color: #FCF25D;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 242, 93, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FCF25D;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #333;
  background-color: #FCF25D;
  border-color: #FCF25D;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 242, 93, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #333;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #333;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

.card-group > .card {
  margin-bottom: 15px;
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.7;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #333;
  background-color: #F4CB35;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #333;
  background-color: #e9ba0d;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 203, 53, 0.5);
}

.badge-secondary {
  color: #333;
  background-color: #DB76A4;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #333;
  background-color: #d04e89;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(219, 118, 164, 0.5);
}

.badge-success {
  color: #333;
  background-color: #9AC144;
}

a.badge-success:hover, a.badge-success:focus {
  color: #333;
  background-color: #7d9e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(154, 193, 68, 0.5);
}

.badge-info {
  color: #333;
  background-color: #49B4CA;
}

a.badge-info:hover, a.badge-info:focus {
  color: #333;
  background-color: #3399ad;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(73, 180, 202, 0.5);
}

.badge-warning {
  color: #333;
  background-color: #FCF25D;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #333;
  background-color: #fbee2b;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 242, 93, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #333;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #333;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "notosansJP";
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #F4CB35 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #e9ba0d !important;
}

.bg-secondary {
  background-color: #DB76A4 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d04e89 !important;
}

.bg-success {
  background-color: #9AC144 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #7d9e34 !important;
}

.bg-info {
  background-color: #49B4CA !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3399ad !important;
}

.bg-warning {
  background-color: #FCF25D !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fbee2b !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #F4CB35 !important;
}

.border-top {
  border-top: 1px solid #F4CB35 !important;
}

.border-right {
  border-right: 1px solid #F4CB35 !important;
}

.border-bottom {
  border-bottom: 1px solid #F4CB35 !important;
}

.border-left {
  border-left: 1px solid #F4CB35 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #F4CB35 !important;
}

.border-secondary {
  border-color: #DB76A4 !important;
}

.border-success {
  border-color: #9AC144 !important;
}

.border-info {
  border-color: #49B4CA !important;
}

.border-warning {
  border-color: #FCF25D !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  -ms-grid-row-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(33, 34, 36, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(33, 34, 36, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(33, 34, 36, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #F4CB35 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #d1a70b !important;
}

.text-secondary {
  color: #DB76A4 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #cb397c !important;
}

.text-success {
  color: #9AC144 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #6e8b2e !important;
}

.text-info {
  color: #49B4CA !important;
}

a.text-info:hover, a.text-info:focus {
  color: #2d879a !important;
}

.text-warning {
  color: #FCF25D !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #fbec12 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #333 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(33, 34, 36, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/**
 * State
 */
.is-hidden {
  display: none;
}

.is-show {
  display: block;
}

.is-block {
  display: block;
}

/* utilities
-------------------------------------------------- */
/* float */
.pull-left,
.float-left {
  float: left;
}

.pull-right,
.float-right {
  float: right;
}

/* clear */
.clear {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

/* clearfix */
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

/* white space */
.nowrap {
  white-space: nowrap;
}

footer {
  background-color: #F4CB35;
  color: #333;
  padding: 4rem 0;
  position: relative;
}

footer .footer-link-top {
  position: absolute;
  right: 1.5rem;
  top: -7rem;
}

footer .footer-link-top a {
  display: block;
  text-align: center;
}

footer .tel {
  font-size: 1.875rem;
  transition: .2s;
  text-decoration: none;
  color: #212224;
  white-space: nowrap;
}

footer .tel:hover {
  opacity: .7;
}

footer .time {
  font-size: 0.875rem;
}

footer .container {
  max-width: 1200px;
}

footer .inner {
  flex-wrap: wrap;
}

footer .schools {
  margin-top: 1.5rem;
}

body.child-page footer .schools img {
  border: 1px solid white;
}

body.child-page footer .footer__nav li {
  margin-bottom: 1rem;
}

body.child-page footer .footer__nav a {
  transition: .2s;
  text-decoration: none;
  color: white;
}

body.child-page footer .footer__nav a:hover {
  opacity: .7;
}

body.child-page footer .sub__menu {
  list-style: unset;
  padding-left: 1rem;
}

body.child-page footer .sub__menu li a {
  position: relative;
}

body.child-page footer .sub__menu li a:before {
  content: '';
  display: block;
  position: absolute;
  left: -0.8rem;
  top: 0.5rem;
  margin: auto;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #fff;
}

body.child-page footer .footer__aside {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

body.child-page footer .ch-yt__head {
  font-size: 0.875rem;
  position: relative;
}

body.child-page footer .ch-yt__head:after {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  top: 10px;
  border-bottom: 1px solid #fff;
  width: 18px;
  height: 1px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

body.child-page footer .ch-yt__head:before {
  content: '';
  display: block;
  position: absolute;
  right: 100%;
  top: 10px;
  border-bottom: 1px solid #fff;
  width: 18px;
  height: 1px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

body.child-page footer .ch-label {
  background-color: #fff;
  padding: .1rem 1rem;
}

body.child-page:not(.parent-group) footer {
  padding: 0;
}

body.hsc footer {
  background-color: #EB6EA5;
}

body.hsc footer .footer__nav {
  padding: 5rem 0;
  background: url(../img/common/bg_footer01.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

body.hsc footer .ch-label {
  color: #EB6EA5;
}

body.htc footer {
  background-color: #FF962C;
}

body.htc footer .footer__nav {
  padding: 5rem 0;
  background: url(../img/common/bg_footer02.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

body.htc footer .ch-label {
  color: #FF962C;
}

body.iryo footer {
  background-color: #82C436;
}

body.iryo footer .footer__nav {
  padding: 5rem 0;
  background: url(../img/common/bg_footer03.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

body.iryo footer .ch-label {
  color: #82C436;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.header__inner {
  position: relative;
  background-color: white;
}

.header .schools img {
  border: 1px solid white;
}

.header__logo {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.header__button-container {
  z-index: 100;
  display: flex;
  align-items: center;
}

.header__button-container button {
  background: #212224;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  position: relative;
  transition: 1s;
  position: relative;
}

.header__button-container button:focus {
  outline: none;
}

.header__button-container button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 2px dashed #fff;
  opacity: 0;
}

.header__button-container button .menu {
  font-size: 0.8125rem;
}

.header__button-container button .bars {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 8px 0;
}

.header__button-container button span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.header__button-container button span:nth-of-type(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  margin: auto;
  width: 60%;
  transition: .4s ease .05s;
}

.header__button-container button span:nth-of-type(2) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 60%;
  transition: width .4s,-webkit-transform .4s ease .2s;
  transition: width .4s,transform .4s ease .2s;
  transition: width .4s,transform .4s ease .2s,-webkit-transform .4s ease .2s;
}

.header__button-container button span:nth-of-type(2):after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 0;
  transition: .4s;
  background-color: #fff;
}

.header__button-container button span:nth-of-type(3) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30%;
  margin: auto;
  width: 60%;
  transition: .4s ease .05s;
}

.header__button-container button.is_active span:nth-of-type(1) {
  top: 5px;
  opacity: 0;
}

.header__button-container button.is_active span:nth-of-type(2) {
  width: 30px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: width .4s,-webkit-transform .6s ease-in-out .2s;
  transition: width .4s,transform .6s ease-in-out .2s;
  transition: width .4s,transform .6s ease-in-out .2s,-webkit-transform .6s ease-in-out .2s;
}

.header__button-container button.is_active span:nth-of-type(2):after {
  height: 30px;
}

.header__button-container button.is_active span:nth-of-type(3) {
  bottom: 5px;
  opacity: 0;
}

.visitor {
  position: relative;
  max-width: 410px;
  margin: 0 auto 1rem;
  margin-bottom: 4rem;
}

.visitor .visitor-menu {
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  padding: .5rem 1rem;
  position: relative;
}

.visitor .visitor-menu:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-bottom: 3px solid #212224;
  border-right: 3px solid #212224;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: .2s;
}

.visitor .visitor-menu.is_active:after {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.visitor .visitor-menu {
  border: none;
}

.visitor .visitor-menu__child {
  background: white;
  z-index: 1000;
  opacity: 0;
  overflow: hidden;
  position: relative;
}

.visitor .visitor-menu__child.is-transition {
  transition: height .6s;
}

.visitor .visitor-menu__child.is_opa {
  opacity: 1;
}

.visitor .visitor-menu__child.close {
  height: 0 !important;
}

.visitor .visitor-menu__child ul {
  padding: 1rem;
}

.visitor .visitor-menu__child ul li:not(:last-of-type) {
  margin-bottom: 0.5rem;
  border-bottom: 2px dashed #212224;
}

.visitor .visitor-menu__child a {
  color: #212224;
  display: block;
  font-weight: bold;
  transition: .2s;
  text-decoration: none;
  padding: .5rem 0;
}

.visitor .visitor-menu__child a:hover {
  opacity: .7;
}

.search--pc {
  position: relative;
}

.search--pc .search-btn {
  font-size: 0.75rem;
  border: none;
  width: 80px;
  line-height: 1.3;
}

.search--pc .cse-search-box-pc {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
  transition: .7s;
}

.search--pc .cse-search-box-pc.is_active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.search--pc .seach_text {
  border-radius: 0;
  border: 2px solid #212224;
  padding: 0.5rem;
  min-width: 210px;
}

.search--pc .submit__btn {
  background-color: #212224;
  width: 50px;
  border-radius: 0;
  border: 0;
}

.search--pc .search-btn {
  background-color: #515458;
  color: white;
  height: 100%;
}

.search--sp .cse-search-box-sp {
  display: flex;
  justify-content: center;
  max-width: 410px;
  margin: 0 auto 1rem;
}

.search--sp .seach_text {
  width: calc(100% - 40px);
  height: 40px;
  border: none;
  padding-left: 0.5rem;
}

.search--sp .submit__btn {
  width: 40px;
  height: 40px;
  border: none;
  background-color: #212224;
}

.menu__inner .header__nav-item.tel {
  padding: .5rem;
}

.menu__inner .header__nav-item.tel img {
  margin-right: 0.3rem;
}

.menu__inner .header__nav-item.tel a {
  line-height: 1;
  transition: .2s;
}

.menu__inner .header__nav-item.tel a:hover {
  opacity: .7;
}

.menu__inner .header__nav-item.tel .f-century {
  font-weight: bold;
  font-size: 1.6875rem;
}

.menu__inner .header__nav-item.tel p {
  font-size: 0.8125rem;
}

.menu__inner .header__nav-item.map {
  padding: .5rem;
}

@-moz-document url-prefix() {
  .menu__inner .header__nav-item.map {
    white-space: nowrap;
  }
}

.menu__inner .header__nav-item.map .inner {
  display: inline-block;
  border-radius: 6px;
  background-color: #F4CB35;
  text-align: center;
  color: #333333;
  font-weight: bold;
  font-size: 0.9375rem;
  margin-inline: 0.75rem;
  padding: .3rem .2rem .3rem 0.5rem;
}

.header__menu__main {
  margin: auto;
}

.header__menu__main.is-transition {
  transition: .5s;
}

.header__menu__main a span {
  position: relative;
}

.header__menu__main .header__nav {
  height: 100%;
}

.header__menu__main .header__nav a {
  color: #212224;
  text-decoration: none;
  padding: .5rem;
}

.header__menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  height: calc(100vh - 4rem);
  overflow-y: scroll;
}

.header__menu.is-transition {
  transition: .5s;
}

.header__menu .inner {
  width: 100%;
}

.header__menu a span {
  position: relative;
}

.header__menu a span:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 5px;
  background-size: 8px 8px;
}

.header__menu.is_showes .header__nav a span {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: .4s ease 1s;
}

.header__menu.is_open {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.header__menu.is_open .inner:after, .header__menu.is_open .inner:before {
  height: 100%;
  transition: 0.4s cubic-bezier(0.76, 0.04, 0.51, 0.99) 0.7s;
}

.header__menu .header__nav {
  height: 100%;
}

.header__menu .header__nav a {
  color: white;
  text-decoration: none;
  padding: .5rem;
  display: block;
}

.header__menu .header__nav aside > .sup {
  color: #00778D;
}

body.parent-group .menu__inner .header__nav-item.map .inner {
  padding: .6rem 1.68rem .6rem 1.25rem !important;
}

body.parent-group .header__menu {
  background: #F4CB35;
  padding: 2rem 0;
}

body.parent-group .header__menu.is_open .header__nav {
  padding-top: 1rem;
  border-top: 1px solid #fff;
}

body.parent-group .header__menu .header__nav-item {
  margin-bottom: 2rem;
}

body.parent-group .header__menu .header__nav-item a {
  position: relative;
}

body.parent-group .header__menu .header__nav-item a .i-text {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
  opacity: 0;
}

body.parent-group .header__menu .header__nav-item a:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 50%;
  bottom: -40px;
  margin: auto;
  opacity: 0;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

body.parent-group .header__menu .header__nav-item .en {
  font-size: 1.375rem;
}

body.parent-group .header__menu .header__nav-item .jp {
  font-size: 0.875rem;
}

body.parent-group .header__menu .header__nav-item.is_nav_show a {
  position: relative;
}

body.parent-group .header__menu .header__nav-item.is_nav_show a .i-text {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: .4s ease .6s;
}

body.parent-group .header__menu .header__nav-item .child__item {
  margin-bottom: 1rem;
}

body.parent-group .header__menu .header__nav-item .child__item .child__item__link {
  transition: .2s;
}

body.parent-group .header__menu .header__nav-item .child__item .child__item__link:hover {
  opacity: .7;
}

body.parent-group .header__menu .header__nav-item a {
  position: relative;
  color: #212224;
  font-weight: bold;
}

body.parent-group .header__menu .header__nav-item a .i-text {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
  opacity: 0;
}

body.parent-group .header__menu .header__nav-item .en {
  font-size: 1.375rem;
}

body.parent-group .header__menu .header__nav-item .jp {
  font-size: 0.875rem;
}

body.parent-group .header__menu .header__nav-item.is_nav_show a {
  position: relative;
}

body.parent-group .header__menu .header__nav-item.is_nav_show a .i-text {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: .4s ease .6s;
}

body.parent-group .header__menu .header__nav-item.is_ch_show a:after {
  right: calc(120% + 30px);
  bottom: 0;
  opacity: 1;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
  transition: right 1s ease-in-out .5s,opacity .3s ease .1s,bottom .3s ease .1s,-webkit-transform .4s ease .4s;
  transition: right 1s ease-in-out .5s,opacity .3s ease .1s,bottom .3s ease .1s,transform .4s ease .4s;
  transition: right 1s ease-in-out .5s,opacity .3s ease .1s,bottom .3s ease .1s,transform .4s ease .4s,-webkit-transform .4s ease .4s;
}

body.parent-group .header__menu .header__nav-item.parent__nav {
  position: relative;
}

body.parent-group .header__menu .header__nav-item.parent__nav .sub__menu li a {
  color: #fff;
  text-decoration: none;
  padding: .5rem;
  display: block;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  padding: 1rem 0.5rem;
  transition: .2s;
}

body.parent-group .header__menu .header__nav-item.parent__nav .sub__menu li a:hover {
  opacity: .7;
}

body.parent-group .header__menu .header__nav-item.parent__nav > button {
  display: block;
  text-align: left;
  font-weight: bold;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  background: none;
  padding: 1rem 2rem 1rem .5rem;
  outline: none;
  position: relative;
}

body.parent-group .header__menu .header__nav-item.parent__nav > button:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 17px;
  height: 1px;
  background-color: #fff;
}

body.parent-group .header__menu .header__nav-item.parent__nav > button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 17px;
  background-color: #fff;
  transition: .2s;
}

body.parent-group .header__menu .header__nav-item.parent__nav > button.is_active:before {
  opacity: 0;
  height: 0;
}

body.parent-group .header__inner .menu__inner .header__nav-item.tel {
  color: #00A0E8;
}

body.parent-group .header__inner .menu__inner .header__nav-item.tel a {
  color: #00A0E8;
}

body.opencampus .cv__btns .entry a {
  color: #212224;
  background-color: #FCF25D;
  border: 2px solid white;
}

body.hsc .header__menu {
  background: rgba(232, 82, 152, 0.93);
}

body.hsc .header__inner {
  background-color: #E85298;
}

body.hsc .header__menu__main .sub__menu {
  border-width: 0 2px 2px;
  border-color: transparent #E85298 #E85298;
  border-style: solid;
}

body.hsc .header__menu__main .sub__menu a {
  position: relative;
  color: #E85298;
}

body.hsc .header__menu__main .sub__menu a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #E85298;
}

body.hsc .header__menu__main .sub__menu a:hover:after {
  width: 100%;
  transition: .3s;
}

body.htc .header__menu {
  background: rgba(255, 118, 3, 0.93);
}

body.htc .header__inner {
  background-color: #FF7603;
}

body.htc .header__menu__main .sub__menu {
  border-width: 0 2px 2px;
  border-color: transparent #FF7603 #FF7603;
  border-style: solid;
}

body.htc .header__menu__main .sub__menu a {
  position: relative;
  color: #FF7603;
}

body.htc .header__menu__main .sub__menu a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #FF7603;
}

body.htc .header__menu__main .sub__menu a:hover:after {
  width: 100%;
  transition: .3s;
}

body.iryo .header__menu {
  background: rgba(67, 170, 136, 0.93);
}

body.iryo .header__inner {
  background-color: #43AA88;
}

body.iryo .header__menu__main .sub__menu {
  border-width: 0 2px 2px;
  border-color: transparent #43AA88 #43AA88;
  border-style: solid;
}

body.iryo .header__menu__main .sub__menu a {
  position: relative;
  color: #43AA88;
}

body.iryo .header__menu__main .sub__menu a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #43AA88;
}

body.iryo .header__menu__main .sub__menu a:hover:after {
  width: 100%;
  transition: .3s;
}

body.hsc .header__menu, body.htc .header__menu, body.iryo .header__menu {
  padding: 4rem 0;
}

body.hsc .header__menu__main, body.htc .header__menu__main, body.iryo .header__menu__main {
  height: 100%;
}

body.hsc .menu__inner .header__nav-item.tel, body.htc .menu__inner .header__nav-item.tel, body.iryo .menu__inner .header__nav-item.tel {
  color: white;
}

body.hsc .menu__inner .header__nav-item.tel a, body.htc .menu__inner .header__nav-item.tel a, body.iryo .menu__inner .header__nav-item.tel a {
  color: white;
}

body.hsc .menu__inner .header__nav-item.map .inner, body.htc .menu__inner .header__nav-item.map .inner, body.iryo .menu__inner .header__nav-item.map .inner {
  background-color: #fff;
  text-align: center;
  color: #DF5656;
  font-size: 0.9375rem;
}

body.hsc .header__nav-ls, body.htc .header__nav-ls, body.iryo .header__nav-ls {
  height: 100%;
}

body.hsc .header__nav-ls .header__nav-item:not(:last-of-type), body.htc .header__nav-ls .header__nav-item:not(:last-of-type), body.iryo .header__nav-ls .header__nav-item:not(:last-of-type) {
  margin-right: 1rem;
}

body.hsc .header__nav-ls .header__nav-item.parent__nav, body.htc .header__nav-ls .header__nav-item.parent__nav, body.iryo .header__nav-ls .header__nav-item.parent__nav {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

body.hsc .header__nav-ls .header__nav-item.parent__nav .sub__menu li a, body.htc .header__nav-ls .header__nav-item.parent__nav .sub__menu li a, body.iryo .header__nav-ls .header__nav-item.parent__nav .sub__menu li a {
  text-decoration: none;
  padding: .5rem;
  display: block;
  text-align: left;
  font-weight: bold;
  padding: .7rem 0.5rem;
  font-size: 1rem;
  transition: .2s;
  position: relative;
}

body.hsc .header__nav-ls .header__nav-item.parent__nav .sub__menu li a:hover, body.htc .header__nav-ls .header__nav-item.parent__nav .sub__menu li a:hover, body.iryo .header__nav-ls .header__nav-item.parent__nav .sub__menu li a:hover {
  opacity: .7;
}

body.hsc .header__nav-ls .header__nav-item.parent__nav > button, body.htc .header__nav-ls .header__nav-item.parent__nav > button, body.iryo .header__nav-ls .header__nav-item.parent__nav > button {
  display: block;
  text-align: left;
  font-weight: bold;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  background: none;
  padding: 1rem 2rem 1rem .5rem;
  outline: none;
  position: relative;
}

body.hsc .header__nav-ls .header__nav-item.parent__nav > button:after, body.htc .header__nav-ls .header__nav-item.parent__nav > button:after, body.iryo .header__nav-ls .header__nav-item.parent__nav > button:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 17px;
  height: 1px;
  background-color: #fff;
}

body.hsc .header__nav-ls .header__nav-item.parent__nav > button:before, body.htc .header__nav-ls .header__nav-item.parent__nav > button:before, body.iryo .header__nav-ls .header__nav-item.parent__nav > button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 17px;
  background-color: #fff;
  transition: .2s;
}

body.hsc .header__nav-ls .header__nav-item.parent__nav > button.is_active:before, body.htc .header__nav-ls .header__nav-item.parent__nav > button.is_active:before, body.iryo .header__nav-ls .header__nav-item.parent__nav > button.is_active:before {
  opacity: 0;
  height: 0;
}

body.hsc .header__nav-ls a, body.htc .header__nav-ls a, body.iryo .header__nav-ls a {
  color: #212224;
  text-decoration: none;
  padding: .5rem;
}

body.hsc .header__nav, body.htc .header__nav, body.iryo .header__nav {
  margin-bottom: 5rem;
}

body.hsc .header__nav .sub__menu, body.htc .header__nav .sub__menu, body.iryo .header__nav .sub__menu {
  list-style: unset;
}

body.hsc .header__nav .sub__menu li a, body.htc .header__nav .sub__menu li a, body.iryo .header__nav .sub__menu li a {
  position: relative;
  transition: .2s;
}

body.hsc .header__nav .sub__menu li a:hover, body.htc .header__nav .sub__menu li a:hover, body.iryo .header__nav .sub__menu li a:hover {
  opacity: .7;
}

body.hsc .header__nav .sub__menu li a:before, body.htc .header__nav .sub__menu li a:before, body.iryo .header__nav .sub__menu li a:before {
  content: '';
  display: block;
  position: absolute;
  left: -0.5rem;
  top: 1.2rem;
  margin: auto;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #fff;
}

body.hsc .header__nav aside > .sup, body.htc .header__nav aside > .sup, body.iryo .header__nav aside > .sup {
  color: #FFEBA8;
}

body.hsc .header__nav .header__nav-item.parent__nav > button, body.htc .header__nav .header__nav-item.parent__nav > button, body.iryo .header__nav .header__nav-item.parent__nav > button {
  display: block;
  text-align: left;
  font-weight: bold;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  background: none;
  padding: 1rem 2rem 1rem .5rem;
  outline: none;
  position: relative;
}

body.hsc .header__nav .header__nav-item.parent__nav > button:before, body.htc .header__nav .header__nav-item.parent__nav > button:before, body.iryo .header__nav .header__nav-item.parent__nav > button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 10px 10px 0;
  border-color: white transparent transparent;
  border-style: solid;
  transition: .2s;
}

body.hsc .header__nav .header__nav-item.parent__nav > button.is_active:before, body.htc .header__nav .header__nav-item.parent__nav > button.is_active:before, body.iryo .header__nav .header__nav-item.parent__nav > button.is_active:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

body.hsc .header__menu .parent__nav > a, body.htc .header__menu .parent__nav > a, body.iryo .header__menu .parent__nav > a {
  font-weight: bold;
  font-size: 1.25rem;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(33, 34, 36, 0.5);
  height: 100%;
  width: 100vw;
  z-index: -10;
  display: none;
  cursor: pointer;
}

.overlay.is_active {
  z-index: 100;
  display: block;
}

body.home .header {
  margin-bottom: 0;
}

body.home .movie-index {
  width: 100%;
  aspect-ratio: 16/9;
}

body.home .movie-index iframe, body.home .movie-index video {
  width: 100%;
  height: 100%;
}

body.home .hero {
  background-color: #F4CB35;
  position: relative;
}

body.home .hero .hero-slider {
  width: 100%;
  background-color: #fff;
}

body.home .hero .hero-slider__item {
  width: 100%;
}

body.home .hero .hero-slider__item img {
  width: 100%;
}

body.home .hero .hero-slider__item01 {
  background-color: #BDDBD4;
  position: relative;
  padding-top: 0 !important;
  aspect-ratio: 1500/760;
}

body.home .hero .hero-slider__item01 .hero-item {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  -webkit-animation: stepImg01 2s steps(2) infinite;
  animation: stepImg01 2s steps(2) infinite;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
}

body.home .hero .hero-slider__item01 .hero-item--01 {
  left: 3%;
  top: 2%;
  background: url(../img/home/img_hero-item01@2x.png) no-repeat;
  background-size: 200%;
  width: 19.6%;
  aspect-ratio: 294/354;
}

body.home .hero .hero-slider__item01 .hero-item--02 {
  top: 34%;
  left: 18%;
  background: url(../img/home/img_hero-item02@2x.png) no-repeat;
  background-size: 200%;
  width: 18.8%;
  aspect-ratio: 282/245;
}

body.home .hero .hero-slider__item01 .hero-item--03 {
  top: 26%;
  right: 23%;
  background: url(../img/home/img_hero-item03@2x.png) no-repeat;
  background-size: 200%;
  width: 17.5%;
  aspect-ratio: 263/319;
}

body.home .hero .hero-slider__item01 .hero-item--04 {
  top: 6%;
  right: 6%;
  background: url(../img/home/img_hero-item04@2x.png) no-repeat;
  background-size: 200%;
  width: 18.8%;
  aspect-ratio: 283/270;
}

body.home .hero .hero-slider__item01 .hero-item--05 {
  bottom: 8%;
  left: 1%;
  background: url(../img/home/img_hero-item05@2x.png) no-repeat;
  background-size: 200%;
  width: 15.8%;
  aspect-ratio: 237/266;
}

body.home .hero .hero-slider__item01 .hero-item--06 {
  bottom: 8%;
  right: 6%;
  background: url(../img/home/img_hero-item06@2x.png) no-repeat;
  background-size: 200%;
  width: 15.6%;
  aspect-ratio: 235/268;
}

body.home .hero .hero-slider__item01 .hero-img {
  position: absolute;
  right: 0;
  bottom: 0;
  left: -2%;
  margin: auto;
  width: 44%;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  opacity: 0;
  z-index: 5;
}

body.home .hero .hero-slider__item01 .hero-text {
  position: absolute;
  top: 5%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  margin: auto;
  text-align: center;
  display: inline-block;
  width: 35%;
  overflow: hidden;
  margin-top: 24px;
}

body.home .hero .hero-slider__item01 .hero-text img {
  display: inline;
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.home .hero .hero-slider__item01 .hero-text:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  width: 0;
}

body.home .hero .hero-slider__item01.is_show .hero-text img {
  transition: opacity .001s ease .3s,-webkit-transform 1s ease .4s;
  transition: opacity .001s ease .3s,transform 1s ease .4s;
  transition: opacity .001s ease .3s,transform 1s ease .4s,-webkit-transform 1s ease .4s;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

body.home .hero .hero-slider__item01.is_show .hero-text:after {
  transition: width .3s,-webkit-transform .3s ease .5s;
  transition: width .3s,transform .3s ease .5s;
  transition: width .3s,transform .3s ease .5s,-webkit-transform .3s ease .5s;
  width: 100%;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

body.home .hero .hero-slider__item01.is_show .hero-item {
  transition: 1.5s cubic-bezier(0.82, 0.15, 0.21, 1.24) 0.7s;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

body.home .hero .hero-slider__item01.is_show .hero-img {
  transition: .6s ease .5s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

body.home .hero .hero-slider__item02 {
  position: relative;
  aspect-ratio: 1500/760;
}

body.home .hero .hero-slider__item02 .hero-slider-parts-wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-between;
}

body.home .hero .hero-slider__item02 .hero-slider-link-wrapper {
  width: 29vw;
  padding: 5.6rem 3vw 0;
  display: flex;
  flex-direction: column;
  row-gap: 3.6rem;
}

body.home .hero .hero-slider__item02 .slider01-parts {
  display: inline-block;
}

body.home .hero .hero-slider__item02 .slider01-parts._01 {
  width: 10vw;
}

body.home .hero .hero-slider__item02 .slider01-parts._02 {
  width: 15vw;
}

body.home .hero .hero-slider__item02 .slider01-parts._03 {
  width: 16vw;
}

body.home .hero .hero-slider__item02 .slider01-parts._04 {
  width: 13vw;
}

body.home .hero .hero-slider__item02 .slider01-parts._05 {
  width: 10vw;
}

body.home .hero .hero-slider__item02 .slider01-parts._06 {
  width: 19vw;
}

body.home .hero .hero-slider__item02 .chara-item {
  width: 5vw;
  margin-top: 3rem;
  position: absolute;
  bottom: 3.125rem;
  left: 4vw;
}

body.home .hero__elm.hero__elm--01 {
  width: 100%;
}

body.home .hero__elm .pickup {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  margin-top: 1rem;
  padding-left: 3%;
}

body.home .hero__elm .pickup .title {
  font-size: 1.6875rem;
}

body.home .hero__elm .pickup a {
  color: white;
  text-decoration: underline;
}

body.home .hero__elm.is_scrolled .hero-slide {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: 1s ease 1.3s;
}

body.home .section {
  padding: 5rem 0;
  position: relative;
}

body.home .section--01 {
  position: relative;
}

body.home .section--02 {
  padding: 2.5rem 0 1.5rem;
}

body.home .visitors-list {
  display: flex;
}

body.home .visitors-list li {
  width: calc(100% / 6);
  position: relative;
  z-index: 1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.home .visitors-list li a {
  display: block;
  height: 100%;
  padding: 15px 15px 33px;
  color: #333333;
  font-size: min(1.3vw, 20px);
  position: relative;
  line-height: 1.2;
  border: 1px solid black;
  background-color: #fff;
  z-index: 1;
}

body.home .visitors-list li a::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 18px;
  background-color: #333;
  -webkit-clip-path: path("M8.418,19.307a.5.5,0,0,1-.359-.152L-.359,10.482a.5.5,0,0,1,.005-.7l.274-.274a.5.5,0,0,1,.713.006l7.089,7.316V0a.5.5,0,0,1,.5-.5h.392a.5.5,0,0,1,.5.5V16.829L16.2,9.512a.5.5,0,0,1,.713-.006l.274.274a.5.5,0,0,1,.005.7L8.777,19.155A.5.5,0,0,1,8.418,19.307Z");
  clip-path: path("M8.418,19.307a.5.5,0,0,1-.359-.152L-.359,10.482a.5.5,0,0,1,.005-.7l.274-.274a.5.5,0,0,1,.713.006l7.089,7.316V0a.5.5,0,0,1,.5-.5h.392a.5.5,0,0,1,.5.5V16.829L16.2,9.512a.5.5,0,0,1,.713-.006l.274.274a.5.5,0,0,1,.005.7L8.777,19.155A.5.5,0,0,1,8.418,19.307Z");
  position: absolute;
  bottom: 12px;
  right: 12px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.home .visitors-list li a:hover {
  text-decoration: none;
}

body.home .visitors-list li a .small {
  font-size: min(0.85vw, 14px);
}

body.home .visitors-list li a .color-pink {
  color: #E85298;
}

body.home .banner-slide {
  margin-top: 70px;
}

body.home .banner-slide__main {
  position: relative;
  padding: 0 34px;
}

body.home .banner-slide__main .slide-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 34px;
  border: none;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  transition: box-shadow 0.3s linear, -webkit-transform 0.3s ease;
  transition: box-shadow 0.3s linear, transform 0.3s ease;
  transition: box-shadow 0.3s linear, transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.home .banner-slide__main .slide-arrow:focus {
  outline: none;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

body.home .banner-slide__main .slide-arrow span {
  width: 17px;
  height: 34px;
  background-color: #000;
  -webkit-clip-path: polygon(0 0, 100% 50%, 0% 100%);
  clip-path: polygon(0 0, 100% 50%, 0% 100%);
}

body.home .banner-slide__main .prev-arrow {
  left: 0;
}

body.home .banner-slide__main .prev-arrow span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

body.home .banner-slide__main .next-arrow {
  right: 0;
}

body.home .banner-slide__main__img {
  aspect-ratio: 396/150;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.home .banner-slide__main__img img {
  height: 100%;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
  margin-inline: auto;
}

body.home .section--03 {
  font-weight: bold;
}

body.home .section--03 .bg {
  background: #fff url(../img/home/bg-section03.png) center/cover no-repeat;
}

body.home .section--03 .bg.bg-upper {
  padding: 5rem 0 5rem;
}

body.home .section--03 .bg.bg-lower {
  padding: 4.2rem 0;
  position: relative;
}

body.home .section--03 .intro {
  width: 100%;
  line-height: 1.9;
  aspect-ratio: 1500/650;
  padding: 32px 0 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

body.home .section--03 .intro::before {
  content: '';
  display: inline-block;
  width: 100%;
  background: url(../img/home/bg-section03-intro.jpg) center/cover no-repeat;
  position: absolute;
  inset: 0;
  z-index: -1;
}

body.home .section--03 .intro.scroll_target::before {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  opacity: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
}

body.home .section--03 .intro.scroll_target.is_scrolled::before {
  -webkit-animation: show 1.4s linear forwards;
  animation: show 1.4s linear forwards;
}

@-webkit-keyframes show {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes show {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

body.home .section--03 .intro.scroll_target.is_scrolled .intro__wrap-text p::before {
  right: 0;
}

body.home .section--03 .intro.scroll_target.is_scrolled .intro__wrap-text p img {
  -webkit-animation: left 1s ease 1.2s forwards;
  animation: left 1s ease 1.2s forwards;
}

@-webkit-keyframes left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

body.home .section--03 .intro__wrap-text p {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  z-index: 1;
  padding: 10px 14px;
}

body.home .section--03 .intro__wrap-text p::before {
  content: '';
  display: inline-block;
  position: absolute;
  inset: 0;
  right: 100%;
  background-color: #fff;
  z-index: -1;
  transition: right 0.6s ease 0.6s;
}

body.home .section--03 .intro__wrap-text p img {
  opacity: 0;
}

body.home .section--03 .intro__wrap-text p .text_01 {
  width: 40vw;
  max-width: 609px;
}

body.home .section--03 .intro__wrap-text p .text_02 {
  width: 37vw;
  max-width: 560px;
}

body.home .section--03 .intro__wrap-text p .text_03 {
  width: 52vw;
  max-width: 788px;
}

body.home .section--03 .intro-lower.scroll_target .text {
  opacity: 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  transition: opacity 1s linear, -webkit-transform 0.6s linear;
  transition: opacity 1s linear, transform 0.6s linear;
  transition: opacity 1s linear, transform 0.6s linear, -webkit-transform 0.6s linear;
}

body.home .section--03 .intro-lower.scroll_target .text--01 {
  transition-delay: calc((1 - 1) * 0.6s);
}

body.home .section--03 .intro-lower.scroll_target .text--02 {
  transition-delay: calc((2 - 1) * 0.6s);
}

body.home .section--03 .intro-lower.scroll_target .text--03 {
  transition-delay: calc((3 - 1) * 0.6s);
}

body.home .section--03 .intro-lower.scroll_target.is_scrolled .text {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

body.home .section--03 .school-intro {
  background-color: #E5F4FA;
  padding: 5.6rem 0 6.25rem;
}

body.home .section--03 .school-intro__upper .hsc-item {
  --school-color: #E85298;
}

body.home .section--03 .school-intro__upper .htc-item {
  --school-color: #FF7603;
}

body.home .section--03 .school-intro__upper .iryo-item {
  --school-color: #43AA88;
}

body.home .section--03 .school-intro__upper.scroll_target .sc-item .school-intro__img {
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41);
}

body.home .section--03 .school-intro__upper.scroll_target .sc-item:nth-of-type(1) .school-intro__img {
  transition-delay: calc((1 - 1) * 0.2s);
}

body.home .section--03 .school-intro__upper.scroll_target .sc-item:nth-of-type(2) .school-intro__img {
  transition-delay: calc((2 - 1) * 0.2s);
}

body.home .section--03 .school-intro__upper.scroll_target .sc-item:nth-of-type(3) .school-intro__img {
  transition-delay: calc((3 - 1) * 0.2s);
}

body.home .section--03 .school-intro__upper.scroll_target.is_scrolled .sc-item .school-intro__img {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

body.home .section--03 .school-intro__img {
  max-width: 330px;
}

body.home .section--03 .school-intro__list {
  width: 100%;
  border: 3px solid var(--school-color);
  background-color: #fff;
  border-radius: 5px;
}

body.home .section--03 .school-intro__list li {
  margin: 10px 0;
}

body.home .section--03 .school-intro__list li:first-of-type {
  margin-top: 0;
  background-color: var(--school-color);
  min-height: 60px;
}

body.home .section--03 .school-intro__list li:first-of-type a {
  text-align: center;
}

body.home .section--03 .school-intro__list li:first-of-type a::before {
  background-color: #fff;
}

body.home .section--03 .school-intro__list li:first-of-type a::after {
  border-color: #fff;
}

body.home .section--03 .school-intro__list li:first-of-type img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

body.home .section--03 .school-intro__list li a {
  width: 100%;
  color: #333;
  font-size: 21px;
  padding: 0 1.9rem;
  display: inline-block;
  position: relative;
}

body.home .section--03 .school-intro__list li a::before, body.home .section--03 .school-intro__list li a::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.home .section--03 .school-intro__list li a::before {
  right: 15px;
  width: 12px;
  height: 2px;
  background: #333;
}

body.home .section--03 .school-intro__list li a::after {
  right: 14px;
  width: 12px;
  height: 12px;
  border-top: 3px solid #333;
  border-right: 3px solid #333;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.home .section--03 .school-intro__list li a:hover {
  text-decoration: none;
  opacity: 1;
}

body.home .section--03 .school-intro__list li a:hover::before {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

body.home .section--03 .school-intro__list li a:hover::after {
  -webkit-transform: translateX(5px) rotate(45deg);
  transform: translateX(5px) rotate(45deg);
}

body.home .section--03 .school-intro__mit, body.home .section--03 .school-intro__lower {
  width: 100%;
  max-width: 820px;
  margin-inline: auto;
}

body.home .section--03 .school-intro__lower .p-accreditation .item {
  align-self: flex-start;
}

body.home .section--03 .school-intro__lower .p-accreditation .item:nth-of-type(2) {
  margin-top: 120px;
}

body.home .section--03 .school-intro__lower .p-accreditation .item .item-inner {
  position: relative;
  z-index: 1;
  aspect-ratio: 1;
}

body.home .section--03 .school-intro__lower .p-accreditation .item .item-inner::before {
  content: '';
  display: inline-block;
  max-width: 220px;
  max-height: 220px;
  aspect-ratio: 1;
  background: url(../img/common/bg_gekkei-wh.png) center/cover no-repeat;
  position: absolute;
  inset: 0;
  margin: auto;
  z-index: -1;
}

body.home .section--03 .school-intro__lower .p-accreditation .item .title {
  font-size: 30px;
  color: #089B99;
  line-height: calc(45 / 30);
  white-space: nowrap;
}

body.home .section--03 .school-intro__lower .p-accreditation .item .text {
  font-size: 16px;
}

body.home .section--04 {
  margin-bottom: 1.75rem;
}

body.home .section--04 .head__wrap, body.home .section--04 body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.home .section--04 .head__wrap, body.home .section--04 body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.home .section--04 .head__wrap, body.home .section--04 body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.home .section--04 .head__wrap {
  color: #333;
}

body.home .section--04 .p-opencampus-btn {
  width: 92%;
  max-width: 1000px;
  display: flex;
  gap: 10px 20px;
}

body.home .section--05 {
  background: repeating-linear-gradient(-45deg, #E2F5FD 0, #E2F5FD 7px, white 0, white 14px);
}

body.home .section--05 .head__wrap, body.home .section--05 body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.home .section--05 .head__wrap, body.home .section--05 body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.home .section--05 .head__wrap, body.home .section--05 body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.home .section--05 .head__wrap {
  color: #333;
}

body.home .section--05 .tab-btns {
  display: flex;
  margin-bottom: 6rem;
}

body.home .section--05 .tab-btns button {
  border: none;
  background-color: transparent;
  padding: 0;
  transition: .2s;
  text-decoration: none;
  position: relative;
}

body.home .section--05 .tab-btns button:hover {
  opacity: .7;
}

body.home .section--05 .tab-btns button.is_active:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-width: 15px 10px 0;
  border-style: solid;
}

body.home .section--05 .tab-btns button.is_active.tab-btn--01:after {
  border-color: #212224 transparent transparent transparent;
}

body.home .section--05 .tab-btns button.is_active.tab-btn--02:after {
  border-color: #E85298 transparent transparent transparent;
}

body.home .section--05 .tab-btns button.is_active.tab-btn--03:after {
  border-color: #FF7603 transparent transparent transparent;
}

body.home .section--05 .tab-btns button.is_active.tab-btn--04:after {
  border-color: #43AA88 transparent transparent transparent;
}

body.home .section--05 .content {
  margin-top: -5rem;
  margin-bottom: 5rem;
  transition: height .6s ease-in-out;
  overflow: hidden;
  position: relative;
  display: none;
}

body.home .section--05 .content.is_show {
  display: block !important;
  -webkit-animation: tab-show .5s linear 0s;
  animation: tab-show .5s linear 0s;
}

@-webkit-keyframes tab-show {
  from {
    opacity: 0;
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes tab-show {
  from {
    opacity: 0;
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.home .section--06 .container {
  max-width: 685px !important;
}

body.home .p-instagram {
  background-color: #fff;
}

body.home .p-instagram .f-century {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

body.home .p-instagram .f-century::before {
  content: '';
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(../img/common/icon_insta.png) center/cover no-repeat;
  margin-right: 12px;
  opacity: 0;
  transition: opacity 0.3s linear 1.5s;
}

body.home .p-instagram .is_scrolled .f-century::before {
  opacity: 1;
}

body.home .p-instagram .head__wrap, body.home .p-instagram body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.home .p-instagram .head__wrap, body.home .p-instagram body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.home .p-instagram .head__wrap, body.home .p-instagram body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.home .p-instagram .head__wrap {
  color: #333;
}

body.home .section--09 {
  padding-bottom: 150px;
}

body.home .section--09 .intro {
  font-size: 1.125rem;
}

body.home .p-access {
  background: url(../img/home/bg_circle01.png) no-repeat;
  background-size: 1105px 1077px;
  background-position: top center;
  padding: 5rem 0 8rem;
}

body.home .p-access .head__wrap, body.home .p-access body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.home .p-access .head__wrap, body.home .p-access body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.home .p-access .head__wrap, body.home .p-access body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.home .p-access .head__wrap {
  color: #333;
}

body.home .character-wrap {
  row-gap: 20px;
}

body.home .character-wrap .character-img:nth-of-type(even) .chara:hover {
  -webkit-transform: translateY(-10px) scale(1.05) rotate(-4deg);
  transform: translateY(-10px) scale(1.05) rotate(-4deg);
}

body.home .character-wrap .character-img:nth-of-type(odd) .chara {
  -webkit-transform-origin: center;
  transform-origin: center;
}

body.home .character-wrap .character-img:nth-of-type(odd) .chara:hover {
  -webkit-transform: translateY(-10px) scale(1.05) rotate(4deg);
  transform: translateY(-10px) scale(1.05) rotate(4deg);
}

body.home .character-wrap .chara {
  cursor: pointer;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.page .breadcrumb {
  background: transparent;
  padding: 1rem 0;
}

body.page .breadcrumb .breadcrumb-item {
  font-size: 0.75rem;
  color: #212224;
}

body.page .breadcrumb .breadcrumb-link {
  color: #212224;
  text-decoration: underline;
  transition: .2s;
}

body.page .breadcrumb .breadcrumb-link:hover {
  opacity: .7;
}

body.page .page-contents {
  margin-bottom: 10rem;
}

body.page .page-contents .head-ch {
  position: relative;
  padding: 0 0 .7rem 2.8rem;
}

body.page .page-contents .head-ch:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 34px;
  height: 50px;
}

body.page .page-contents .head-ch.head-ch--01:before {
  background: url(../img/pages/item_head-ch01.png) no-repeat;
  background-size: cover;
}

body.page .page-contents .head-ch.head-ch--02:before {
  background: url(../img/pages/item_head-ch02.png) no-repeat;
  background-size: cover;
}

body.page .page-contents .head-ch.head-ch--03:before {
  background: url(../img/pages/item_head-ch03.png) no-repeat;
  background-size: cover;
}

body.page .page-contents .head-ch.head-ch--04:before {
  background: url(../img/pages/item_head-ch04.png) no-repeat;
  background-size: cover;
}

body.page .page-contents .head-ch.head-ch--05:before {
  background: url(../img/pages/item_head-ch05.png) no-repeat;
  background-size: cover;
}

body.page .page-contents .head-ch.head-ch--06:before {
  background: url(../img/pages/item_head-ch06.png) no-repeat;
  background-size: cover;
}

body.page .tab-btns2 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6rem;
}

body.page .tab-btns2 button, body.page .tab-btns2 a {
  border-width: 2px;
  border-style: solid;
  padding: .5rem;
  font-weight: bold;
  display: block;
  width: 100%;
  transition: .2s;
  transition: .2s;
  text-decoration: none;
  position: relative;
  font-size: 1rem;
}

body.page .tab-btns2 button:hover, body.page .tab-btns2 a:hover {
  opacity: .7;
}

body.page .tab-btns2 button:focus, body.page .tab-btns2 a:focus {
  outline: none;
}

body.page .tab-btns2 a {
  display: flex;
  justify-content: center;
  align-items: center;
}

body.page .tab-btns2 a:hover {
  opacity: 0.7;
  text-decoration: none;
}

body.page .tab-btns2 button, body.page .tab-btns2 a {
  color: #212224;
  background-color: #F4CB35;
  border-color: #F4CB35;
}

body.page .tab-btns2 li.is_active button, body.page .tab-btns2 li.is_active a {
  color: #212224;
  background-color: #fff;
  border-color: #F4CB35;
}

body.page.hsc .tab-btns2 button, body.page.hsc .tab-btns2 a {
  color: #fff;
  background-color: #DB76A4;
  border-color: #DB76A4;
}

body.page.hsc .tab-btns2 li.is_active button, body.page.hsc .tab-btns2 li.is_active a {
  color: #DB76A4;
  background-color: #fff;
  border-color: #DB76A4;
}

body.page.htc .tab-btns2 button, body.page.htc .tab-btns2 a {
  color: #fff;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

body.page.htc .tab-btns2 li.is_active button, body.page.htc .tab-btns2 li.is_active a {
  color: #fd7e14;
  background-color: #fff;
  border-color: #fd7e14;
}

body.page.iryo .tab-btns2 button, body.page.iryo .tab-btns2 a {
  color: #fff;
  background-color: #43AA88;
  border-color: #43AA88;
}

body.page.iryo .tab-btns2 li.is_active button, body.page.iryo .tab-btns2 li.is_active a {
  color: #43AA88;
  background-color: #fff;
  border-color: #43AA88;
}

body.school-home main h2,
body.home main h2 {
  font-size: 1.25rem;
  font-weight: bold;
}

body.school-home main .en,
body.home main .en {
  line-height: 1.2;
}

body.school-home .head__wrap, body.school-home body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.school-home .head__wrap, body.school-home body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.school-home .head__wrap, body.school-home body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.school-home .head__wrap,
body.home .head__wrap,
body.home body.lp.opencampus.parent-group .section__04 .head__wrap,
body.lp.opencampus.parent-group .section__04 body.home .head__wrap,
body.home body.lp.opencampus .section__03 .head__wrap,
body.lp.opencampus .section__03 body.home .head__wrap,
body.home body.lp.opencampus .section__05 .head__wrap,
body.lp.opencampus .section__05 body.home .head__wrap {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3.125rem;
  line-height: 0;
}

body.school-home .head__wrap .en span, body.school-home body.lp.opencampus.parent-group .section__04 .head__wrap .en span, body.lp.opencampus.parent-group .section__04 body.school-home .head__wrap .en span, body.school-home body.lp.opencampus .section__03 .head__wrap .en span, body.lp.opencampus .section__03 body.school-home .head__wrap .en span, body.school-home body.lp.opencampus .section__05 .head__wrap .en span, body.lp.opencampus .section__05 body.school-home .head__wrap .en span,
body.home .head__wrap .en span,
body.home body.lp.opencampus.parent-group .section__04 .head__wrap .en span,
body.lp.opencampus.parent-group .section__04 body.home .head__wrap .en span,
body.home body.lp.opencampus .section__03 .head__wrap .en span,
body.lp.opencampus .section__03 body.home .head__wrap .en span,
body.home body.lp.opencampus .section__05 .head__wrap .en span,
body.lp.opencampus .section__05 body.home .head__wrap .en span {
  font-size: 0rem;
  line-height: 1.2;
}

body.school-home .head__wrap h2, body.school-home body.lp.opencampus.parent-group .section__04 .head__wrap h2, body.lp.opencampus.parent-group .section__04 body.school-home .head__wrap h2, body.school-home body.lp.opencampus .section__03 .head__wrap h2, body.lp.opencampus .section__03 body.school-home .head__wrap h2, body.school-home body.lp.opencampus .section__05 .head__wrap h2, body.lp.opencampus .section__05 body.school-home .head__wrap h2,
body.home .head__wrap h2,
body.home body.lp.opencampus.parent-group .section__04 .head__wrap h2,
body.lp.opencampus.parent-group .section__04 body.home .head__wrap h2,
body.home body.lp.opencampus .section__03 .head__wrap h2,
body.lp.opencampus .section__03 body.home .head__wrap h2,
body.home body.lp.opencampus .section__05 .head__wrap h2,
body.lp.opencampus .section__05 body.home .head__wrap h2 {
  overflow: hidden;
  position: relative;
}

body.school-home .head__wrap h2:after, body.school-home body.lp.opencampus.parent-group .section__04 .head__wrap h2:after, body.lp.opencampus.parent-group .section__04 body.school-home .head__wrap h2:after, body.school-home body.lp.opencampus .section__03 .head__wrap h2:after, body.lp.opencampus .section__03 body.school-home .head__wrap h2:after, body.school-home body.lp.opencampus .section__05 .head__wrap h2:after, body.lp.opencampus .section__05 body.school-home .head__wrap h2:after,
body.home .head__wrap h2:after,
body.home body.lp.opencampus.parent-group .section__04 .head__wrap h2:after,
body.lp.opencampus.parent-group .section__04 body.home .head__wrap h2:after,
body.home body.lp.opencampus .section__03 .head__wrap h2:after,
body.lp.opencampus .section__03 body.home .head__wrap h2:after,
body.home body.lp.opencampus .section__05 .head__wrap h2:after,
body.lp.opencampus .section__05 body.home .head__wrap h2:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #212224;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

body.school-home .head__wrap h2 span, body.school-home body.lp.opencampus.parent-group .section__04 .head__wrap h2 span, body.lp.opencampus.parent-group .section__04 body.school-home .head__wrap h2 span, body.school-home body.lp.opencampus .section__03 .head__wrap h2 span, body.lp.opencampus .section__03 body.school-home .head__wrap h2 span, body.school-home body.lp.opencampus .section__05 .head__wrap h2 span, body.lp.opencampus .section__05 body.school-home .head__wrap h2 span,
body.home .head__wrap h2 span,
body.home body.lp.opencampus.parent-group .section__04 .head__wrap h2 span,
body.lp.opencampus.parent-group .section__04 body.home .head__wrap h2 span,
body.home body.lp.opencampus .section__03 .head__wrap h2 span,
body.lp.opencampus .section__03 body.home .head__wrap h2 span,
body.home body.lp.opencampus .section__05 .head__wrap h2 span,
body.lp.opencampus .section__05 body.home .head__wrap h2 span {
  display: inline-block;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

body.school-home .head__wrap.is_scrolled .en span, body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span, body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span,
body.home .head__wrap.is_scrolled .en span,
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span,
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span {
  font-size: 3.125rem;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(1), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(1), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(1),
body.home .head__wrap.is_scrolled .en span:nth-of-type(1),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(1),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(1) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.125s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(2), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(2), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(2),
body.home .head__wrap.is_scrolled .en span:nth-of-type(2),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(2),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(2) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.25s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(3), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(3), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(3),
body.home .head__wrap.is_scrolled .en span:nth-of-type(3),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(3),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(3) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.375s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(4), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(4), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(4),
body.home .head__wrap.is_scrolled .en span:nth-of-type(4),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(4),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(4) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.5s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(5), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(5), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(5),
body.home .head__wrap.is_scrolled .en span:nth-of-type(5),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(5),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(5) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.625s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(6), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(6), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(6),
body.home .head__wrap.is_scrolled .en span:nth-of-type(6),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(6),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(6) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.75s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(7), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(7), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(7),
body.home .head__wrap.is_scrolled .en span:nth-of-type(7),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(7),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(7) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.875s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(8), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(8), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(8),
body.home .head__wrap.is_scrolled .en span:nth-of-type(8),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(8),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(8) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 1s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(9), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(9), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(9),
body.home .head__wrap.is_scrolled .en span:nth-of-type(9),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(9),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(9) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 1.125s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(10), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(10), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(10),
body.home .head__wrap.is_scrolled .en span:nth-of-type(10),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(10),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(10) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 1.25s;
}

body.school-home .head__wrap.is_scrolled .en span:nth-of-type(11), body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(11), body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span:nth-of-type(11),
body.home .head__wrap.is_scrolled .en span:nth-of-type(11),
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span:nth-of-type(11),
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span:nth-of-type(11) {
  transition: 0.001s cubic-bezier(0.72, -0.01, 0.02, 1.41) 1.375s;
}

body.school-home .head__wrap.is_scrolled h2:after, body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap h2:after, body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap h2:after, body.school-home body.lp.opencampus .section__03 .is_scrolled.head__wrap h2:after, body.lp.opencampus .section__03 body.school-home .is_scrolled.head__wrap h2:after, body.school-home body.lp.opencampus .section__05 .is_scrolled.head__wrap h2:after, body.lp.opencampus .section__05 body.school-home .is_scrolled.head__wrap h2:after,
body.home .head__wrap.is_scrolled h2:after,
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap h2:after,
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap h2:after,
body.home body.lp.opencampus .section__03 .is_scrolled.head__wrap h2:after,
body.lp.opencampus .section__03 body.home .is_scrolled.head__wrap h2:after,
body.home body.lp.opencampus .section__05 .is_scrolled.head__wrap h2:after,
body.lp.opencampus .section__05 body.home .is_scrolled.head__wrap h2:after {
  transition: .4s;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

body.school-home .head__wrap.is_scrolled h2 span, body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap h2 span, body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap h2 span, body.school-home body.lp.opencampus .section__03 .is_scrolled.head__wrap h2 span, body.lp.opencampus .section__03 body.school-home .is_scrolled.head__wrap h2 span, body.school-home body.lp.opencampus .section__05 .is_scrolled.head__wrap h2 span, body.lp.opencampus .section__05 body.school-home .is_scrolled.head__wrap h2 span,
body.home .head__wrap.is_scrolled h2 span,
body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap h2 span,
body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap h2 span,
body.home body.lp.opencampus .section__03 .is_scrolled.head__wrap h2 span,
body.lp.opencampus .section__03 body.home .is_scrolled.head__wrap h2 span,
body.home body.lp.opencampus .section__05 .is_scrolled.head__wrap h2 span,
body.lp.opencampus .section__05 body.home .is_scrolled.head__wrap h2 span {
  transition: .5s ease-in-out .4s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

body.school-home main,
body.lp main {
  font-size: 0.875rem;
}

body.school-home .section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

body.school-home .section--01 {
  position: relative;
  overflow: hidden;
}

body.school-home .hero {
  position: relative;
}

body.school-home .hero .hero-slider__item01 {
  background-color: #DCE2F2 !important;
  position: relative;
  padding-top: 0;
  aspect-ratio: 1500/760;
}

body.school-home .hero .hero-slider__item01 .hero-item {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  -webkit-animation: stepImg01 2s steps(2) infinite;
  animation: stepImg01 2s steps(2) infinite;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
}

body.school-home .hero .hero-slider__item01 .hero-item--01 {
  left: 3%;
  top: 2%;
  background: url(../img/pages/slider/img_hero-item01@2x.png) no-repeat;
  background-size: 200%;
  width: 19.6%;
  height: 41%;
  aspect-ratio: 294/344;
}

body.school-home .hero .hero-slider__item01 .hero-item--02 {
  top: 30%;
  left: 16%;
  background: url(../img/pages/slider/img_hero-item02@2x.png) no-repeat;
  background-size: 200%;
  width: 18.8%;
  height: 32%;
  aspect-ratio: 282/245;
}

body.school-home .hero .hero-slider__item01 .hero-item--03 {
  top: 22%;
  right: 21%;
  background: url(../img/pages/slider/img_hero-item03@2x.png) no-repeat;
  background-size: 200%;
  width: 17.5%;
  height: 41.9%;
  aspect-ratio: 263/319;
}

body.school-home .hero .hero-slider__item01 .hero-item--04 {
  top: 6%;
  right: 6%;
  background: url(../img/pages/slider/img_hero-item04@2x.png) no-repeat;
  background-size: 200%;
  width: 18.8%;
  height: 35%;
  aspect-ratio: 283/270;
}

body.school-home .hero .hero-slider__item01 .hero-item--05 {
  bottom: 8%;
  left: 1%;
  background: url(../img/pages/slider/img_hero-item05@2x.png) no-repeat;
  background-size: 200%;
  width: 15.8%;
  height: 35%;
  aspect-ratio: 237/266;
}

body.school-home .hero .hero-slider__item01 .hero-item--06 {
  bottom: 8%;
  right: 6%;
  background: url(../img/pages/slider/img_hero-item06@2x.png) no-repeat;
  background-size: 200%;
  width: 15.6%;
  height: 35.2%;
  aspect-ratio: 235/268;
}

body.school-home .hero .hero-slider__item01 .hero-img {
  position: absolute;
  right: 0;
  bottom: 0;
  left: -2%;
  margin: auto;
  width: 44%;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  opacity: 0;
  z-index: 5;
}

body.school-home .hero .hero-slider__item01 .hero-text {
  position: absolute;
  top: 5%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  margin: auto;
  text-align: center;
  display: inline-block;
  width: 35%;
  overflow: hidden;
}

body.school-home .hero .hero-slider__item01 .hero-text img {
  display: inline;
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.school-home .hero .hero-slider__item01 .hero-text:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  width: 0;
}

body.school-home .hero .hero-slider__item01.is_show .hero-text img {
  transition: opacity .001s ease .3s,-webkit-transform 1s ease .4s;
  transition: opacity .001s ease .3s,transform 1s ease .4s;
  transition: opacity .001s ease .3s,transform 1s ease .4s,-webkit-transform 1s ease .4s;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

body.school-home .hero .hero-slider__item01.is_show .hero-text:after {
  transition: width .3s,-webkit-transform .3s ease .5s;
  transition: width .3s,transform .3s ease .5s;
  transition: width .3s,transform .3s ease .5s,-webkit-transform .3s ease .5s;
  width: 100%;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

body.school-home .hero .hero-slider__item01.is_show .hero-item {
  transition: 1.5s cubic-bezier(0.82, 0.15, 0.21, 1.24) 0.7s;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

body.school-home .hero .hero-slider__item01.is_show .hero-img {
  transition: .6s ease .5s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

body.school-home .hero__elm.hero__elm--01 {
  width: 100%;
  z-index: 10;
}

body.school-home .hero__elm .pickup {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  margin-top: 1rem;
  padding-left: 3%;
}

body.school-home .hero__elm .pickup .title {
  font-size: 1.6875rem;
  white-space: nowrap;
}

body.school-home .hero__elm .pickup a {
  color: white;
  text-decoration: underline;
}

body.school-home .hero__elm.is_scrolled .hero-slide {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: 1s ease 1.3s;
}

body.child-page.day.page.page02 h1 {
  display: none;
}

body.child-page.day:not(.page2) main {
  margin-bottom: 0;
}

body.child-page.day .day__slide__inner {
  position: relative;
  height: 300px;
  margin: auto;
}

body.child-page.day .day__slide {
  position: relative;
  padding: 2.5rem 0;
}

body.child-page.day .day__slide:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  z-index: -1;
}

body.child-page.day .day__slide .slick-track {
  display: flex;
  align-items: flex-end;
}

body.child-page.day .day__slide .slick-next, body.child-page.day .day__slide .slick-prev {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  width: 0;
  height: 0;
  background-color: transparent;
  -webkit-transform: none;
  transform: none;
  transition: opacity 0.6s linear;
  transition: .2s;
  border-style: solid;
  border-width: 17px 0 17px 20px;
}

body.child-page.day .day__slide .slick-next:hover, body.child-page.day .day__slide .slick-prev:hover {
  opacity: .7;
}

body.child-page.day .day__slide .slick-next.slick-disabled, body.child-page.day .day__slide .slick-prev.slick-disabled {
  opacity: 0.5;
}

body.child-page.day .day__slide .slick-prev {
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

body.child-page.day .day__slide__start, body.child-page.day .day__slide__goal {
  width: auto !important;
}

body.child-page.day .day__slide__item {
  margin-right: 0.5rem;
}

body.child-page.day .day__slide__item img {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

body.child-page.day .day__slide__wrap {
  padding-left: 16%;
  padding-right: 8%;
}

body.child-page.day .day__flow {
  position: relative;
  padding: 2.5rem 0;
}

body.child-page.day .day__flow:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  z-index: -1;
  background: repeating-linear-gradient(-45deg, #FEF7FA, #FEF7FA 13px, #fff 0, #fff 26px);
}

body.child-page.day .day__flow-content {
  gap: 40px 64px;
}

body.child-page.day .day__flow-item {
  width: calc(50% - 32px);
  max-width: 340px;
}

body.child-page.day .day__life__section {
  position: relative;
}

body.child-page.day .day__life__section:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  z-index: -1;
}

body.child-page.day .day__life__section .day__life__link .life__link__lists li::before {
  background: #fff !important;
}

body.child-page.day .day__life__section .day__life__link .life__link__lists li .life__link {
  color: #fff;
}

body.child-page.day .day__life__section .day-insta {
  display: flex;
  max-width: 600px;
  margin: auto;
}

body.child-page.day .day__life__section .day-insta .day-insta__lists {
  display: flex;
  flex-wrap: wrap;
}

body.child-page.day .day__life__section .insta__item {
  position: absolute;
  display: none;
}

body.child-page.day .day__life__section .insta__item.insta__item--01 {
  right: -50px;
  top: 12%;
}

body.child-page.day .day__life__section .insta__item.insta__item--02 {
  left: -50px;
  top: 30%;
}

body.child-page.day .day__life__section .insta__item.insta__item--03 {
  right: -50px;
  bottom: 30%;
}

body.child-page.day .day__life__section .insta__item.insta__item--04 {
  left: -50px;
  bottom: 0;
}

body.child-page.day .day__life__section .day__life__link li {
  margin-bottom: 1rem;
}

body.child-page.day .day__life__section .day__life__link .life__link {
  color: #212224;
  text-decoration: underline;
  transition: .2s;
}

body.child-page.day .day__life__section .day__life__link .life__link:hover {
  opacity: .7;
}

body.child-page.day .day__life__section .life__link__lists {
  max-width: 660px;
  margin: auto;
}

body.child-page.day.hsc .day__slide:before {
  background: repeating-linear-gradient(-45deg, #FEF7FA, #FEF7FA 13px, #fff 0, #fff 26px);
}

body.child-page.day.hsc .day__slide .slick-next, body.child-page.day.hsc .day__slide .slick-prev {
  border-color: transparent transparent transparent #DB1966;
}

body.child-page.day.hsc .day__life__section.day__life__section--01:before {
  background: repeating-linear-gradient(90deg, #EA61A1, #EA61A1 65px, #E85298 0, #E85298 172px);
}

body.child-page.day.hsc .day__life__section.day__life__section--02:before {
  background: repeating-linear-gradient(90deg, #EA61A1, #EA61A1 65px, #E85298 0, #E85298 172px);
}

body.child-page.day.hsc .day__life__section.day__life__section--03:before {
  background: repeating-linear-gradient(90deg, #EA61A1, #EA61A1 65px, #E85298 0, #E85298 172px);
}

body.child-page.day.htc .day__slide:before {
  background: repeating-linear-gradient(-45deg, #FEF6EE, #FEF6EE 13px, #fff 0, #fff 26px);
}

body.child-page.day.htc .day__slide .slick-next, body.child-page.day.htc .day__slide .slick-prev {
  border-color: transparent transparent transparent #FF7603;
}

body.child-page.day.htc .day__life__section.day__life__section--01:before {
  background: repeating-linear-gradient(90deg, #EF8E27, #EF8E27 65px, #F08325 0, #F08325 172px);
}

body.child-page.day.htc .day__life__section.day__life__section--02:before {
  background: repeating-linear-gradient(90deg, #EF8E27, #EF8E27 65px, #F08325 0, #F08325 172px);
}

body.child-page.day.htc .day__life__section.day__life__section--03:before {
  background: repeating-linear-gradient(90deg, #EF8E27, #EF8E27 65px, #F08325 0, #F08325 172px);
}

body.child-page.day.htc .day-acc li {
  border: none;
  margin-bottom: 2rem;
}

body.child-page.day.htc .day-acc .accBtn {
  background-color: #333;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 1.3125rem;
}

body.child-page.day.htc .day-acc .accBtn:after {
  border-color: transparent white white transparent;
  width: 12px;
  height: 12px;
  border-width: 2px;
  right: 11px;
}

body.child-page.day.htc .day-acc .accBtn:before {
  content: '';
  position: absolute;
  margin: auto;
  display: block;
  background-color: #fff;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  top: 0;
  right: 16px;
  bottom: 0;
  width: 2px;
  height: 15px;
}

body.child-page.day.htc .day-acc .accBtn.is_active:before {
  -webkit-transform: rotate(-180deg) translateY(-3px);
  transform: rotate(-180deg) translateY(-3px);
}

body.child-page.day.htc .day-acc .accBtn.cooking {
  background-color: #FA7402;
}

body.child-page.day.htc .day-acc .accBtn.confectionery {
  background-color: #A84F25;
}

body.child-page.day.htc .day-acc .accContent {
  padding: 3rem 0;
}

body.child-page.day.htc .day-acc .accContent .upper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 448px;
  margin-inline: auto;
}

body.child-page.day.iryo .day__slide__title {
  font-size: 1.25rem;
  font-weight: bold;
}

body.child-page.day.iryo .day__slide .slick-prev:before,
body.child-page.day.iryo .day__slide .slick-next:before {
  content: none;
}

body.child-page.day.iryo .day__slide.day__slide--01:before {
  background: #E4EDF9;
}

body.child-page.day.iryo .day__slide.day__slide--01 .slick-next, body.child-page.day.iryo .day__slide.day__slide--01 .slick-prev {
  border-color: transparent transparent transparent #007FC7;
}

body.child-page.day.iryo .day__slide.day__slide--02:before {
  background: #FCE4DF;
}

body.child-page.day.iryo .day__slide.day__slide--02 .slick-next, body.child-page.day.iryo .day__slide.day__slide--02 .slick-prev {
  border-color: transparent transparent transparent #E96E69;
}

body.child-page.day.iryo .day__slide.day__slide--03:before {
  background: #E7F0E6;
}

body.child-page.day.iryo .day__slide.day__slide--03 .slick-next, body.child-page.day.iryo .day__slide.day__slide--03 .slick-prev {
  border-color: transparent transparent transparent #43966C;
}

body.child-page.day.iryo .day__life--kaigo .day__life__section.day__life__section--01:before, body.child-page.day.iryo .day__life--kaigo .day__life__section.day__life__section--02:before, body.child-page.day.iryo .day__life--kaigo .day__life__section.day__life__section--05:before {
  background: repeating-linear-gradient(90deg, #8AB4DB, #8AB4DB 65px, #7FADD8 0, #7FADD8 172px);
}

body.child-page.day.iryo .day__life--kaigo .day__life__section.day__life__section--05 .life__link__lists li {
  list-style-type: none;
}

body.child-page.day.iryo .day__life--kaigo .day__life__section.day__life__section--05 .life__link__lists li:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: .5rem;
  margin-bottom: 2px;
}

body.child-page.day.iryo .day__life--kodomo .day__life__section.day__life__section--01:before, body.child-page.day.iryo .day__life--kodomo .day__life__section.day__life__section--03:before, body.child-page.day.iryo .day__life--kodomo .day__life__section.day__life__section--05:before {
  background: repeating-linear-gradient(90deg, #F59896, #F59896 65px, #F48E8C 0, #F48E8C 172px);
}

body.child-page.day.iryo .day__life--yakugyo .day__life__section.day__life__section--01:before, body.child-page.day.iryo .day__life--yakugyo .day__life__section.day__life__section--04:before, body.child-page.day.iryo .day__life--yakugyo .day__life__section.day__life__section--05:before {
  background: repeating-linear-gradient(90deg, #96C3A0, #96C3A0 65px, #8CBD97 0, #8CBD97 172px);
}

body.child-page.day.iryo .day__life__section.day__life__section--02:before {
  background: repeating-linear-gradient(90deg, #8AB4DB, #8AB4DB 65px, #7FADD8 0, #7FADD8 172px);
}

body.child-page.day.iryo .day__life__section.day__life__section--03:before {
  background: repeating-linear-gradient(90deg, #F59896, #F59896 65px, #F48E8C 0, #F48E8C 172px);
}

body.child-page.day.iryo .day__life__section.day__life__section--04:before {
  background: repeating-linear-gradient(90deg, #96C3A0, #96C3A0 65px, #8CBD97 0, #8CBD97 172px);
}

body.child-page.day.iryo .day-acc li {
  border: none;
  margin-bottom: 2rem;
}

body.child-page.day.iryo .day-acc .accBtn {
  background-color: #333;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 1.3125rem;
}

body.child-page.day.iryo .day-acc .accBtn:after {
  border-color: transparent white white transparent;
  width: 12px;
  height: 12px;
  border-width: 2px;
  right: 11px;
}

body.child-page.day.iryo .day-acc .accBtn:before {
  content: '';
  position: absolute;
  margin: auto;
  display: block;
  background-color: #fff;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  top: 0;
  right: 16px;
  bottom: 0;
  width: 2px;
  height: 15px;
}

body.child-page.day.iryo .day-acc .accBtn.is_active:before {
  -webkit-transform: rotate(-180deg) translateY(-3px);
  transform: rotate(-180deg) translateY(-3px);
}

body.child-page.day.iryo .day-acc .accContent {
  padding: 3rem 0;
}

body.child-page.day.iryo .day__life__chs .life__chs__block {
  margin-bottom: 3rem;
}

body.child-page.day.iryo .life__head {
  font-weight: bold;
  font-size: 2.1875rem;
  color: white;
  text-shadow: 3px 3px 0 #000,1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000,  1px -1px 0 #000, 1px 0 0 #000, -1px  0 0 #000, 0 1px 0 #000,  0 -1px 0 #000;
}

body.child-page.day.iryo .day-insta {
  display: flex;
  max-width: 600px;
  margin: auto;
}

body.child-page.day.iryo .day-insta .day-insta__lists {
  display: flex;
  flex-wrap: wrap;
}

body.child-page.gyouji .page-header h1 {
  background-color: transparent;
  padding: 0;
}

body.child-page.gyouji .intro {
  margin-bottom: 3rem;
}

body.child-page.gyouji .gyouji__block:nth-of-type(odd) {
  background-color: #FBEBF3;
}

body.child-page.gyouji .gyouji__block:nth-of-type(even) {
  background: repeating-linear-gradient(90deg, #FDF5F9, #FDF5F9 11px, #fff 0, #fff 22px);
}

body.child-page.gyouji .gyouji__head {
  margin: 3.5rem 0 0;
}

body.child-page.gyouji .gyouji__head .gyouji__head__num {
  padding: .5rem 1rem;
  color: white;
  background: #EB6EA5;
  font-size: 1.625rem;
  text-align: center;
  position: relative;
}

body.child-page.gyouji .gyouji__head .gyouji__head__num .gyouji__head__month {
  font-size: 1.125rem;
}

body.child-page.gyouji .gyouji__head .gyouji__head__content {
  padding-bottom: 2rem;
}

body.child-page.gyouji .gyouji__head .gyouji__head__lists {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
}

body.child-page.gyouji .gyouji__head .gyouji__head__lists .gyouji__head__item {
  margin-bottom: 0.3rem;
}

body.child-page.gyouji .gyouji__head .gyouji__head__year {
  border: 1px solid #EB6EA5;
  border-radius: 5px;
  color: #EB6EA5;
  padding: 0 0.625rem;
  text-align: center;
  font-size: 0.6875rem;
  margin-left: 0.5rem;
  display: inline-block;
  line-height: 1.4;
}

body.child-page.gyouji .gyouji__content {
  padding: 0 0 1.875rem;
}

body.child-page.gyouji .gyouji__content .gyouji__content__inner {
  max-width: 870px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

body.child-page.gyouji .gyouji__content .gyouji__content__block {
  position: relative;
  text-align: center;
}

body.child-page.gyouji .gyouji__content .gyouji__content__description {
  margin-top: -60px;
}

body.child-page.gyouji .gyouji__content .gyouji__content__description .description__head {
  color: #EB6EA5;
  background-color: #fff;
  text-align: center;
  font-size: 0.9375rem;
  font-weight: bold;
  border-top: 1px solid #EB6EA5;
  border-bottom: 1px solid #EB6EA5;
}

body.child-page.gyouji .gyouji__content .gyouji__content__description .description__img img {
  position: relative;
}

body.child-page.gyouji .gyouji__title img {
  -webkit-transform: translateY(-35px);
  transform: translateY(-35px);
}

body.child-page.gyouji section.colabo {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

body.child-page.gyouji .colabo {
  padding: 0;
  margin-bottom: 2rem;
}

body.child-page.gyouji .colabo__inner {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

body.child-page.gyouji .colabo.colabo--01 {
  background: repeating-linear-gradient(-45deg, #fff 0, #fff 2px, #FADAE8 2px, #FADAE8 25px);
  padding: 1.875rem 0;
}

body.child-page.gyouji .colabo.colabo--02 {
  background: repeating-linear-gradient(-45deg, #fff 0, #fff 2px, #FED3A7 2px, #FED3A7 25px);
  padding: 1.875rem 0;
}

body.child-page.gyouji .colabo.colabo--03 {
  background: repeating-linear-gradient(-45deg, #fff 0, #fff 2px, #E0EAB7 2px, #E0EAB7 25px);
  padding: 1.875rem 0;
}

body.child-page.gyouji .colabo .colabo__content__inner {
  max-width: 870px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

body.child-page.gyouji .colabo .colabo__head {
  margin: 0 0 1rem;
}

body.child-page.gyouji .colabo .colabo__content__block {
  position: relative;
  text-align: center;
}

body.child-page.gyouji .colabo__charm {
  margin-bottom: 2rem;
}

body.child-page.gyouji .colabo__charm__description {
  background-color: #FED3A7;
  padding: 0.625rem 0.9375rem;
  font-weight: bold;
}

body.child-page.gyouji .colabo__htc-iryo {
  max-width: 850px;
  margin: auto;
}

body.child-page.gyouji .sdgs {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  position: relative;
}

body.child-page.gyouji .sdgs__bg {
  position: absolute;
  background-color: #F8DCEA;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: inset(12.5vw 0 0 0);
  clip-path: inset(12.5vw 0 0 0);
  z-index: -1;
}

body.child-page.gyouji .sdgs__bg._01 {
  background-color: #F8DCEA;
}

body.child-page.gyouji .sdgs__bg._03 {
  background-color: #EDF8F2;
}

body.child-page.gyouji .sdgs__icon {
  display: table;
  margin: 0 auto;
  width: 25vw;
  max-width: 230px;
}

body.child-page.gyouji .sdgs__content {
  max-width: 850px;
  margin: 0 auto;
}

body.child-page.gyouji.iryo .gyouji__block:nth-of-type(odd) {
  background-color: #F5F9EC;
}

body.child-page.gyouji.iryo .gyouji__block:nth-of-type(even) {
  background: repeating-linear-gradient(90deg, #F5F9EC, #F5F9EC 11px, #fff 0, #fff 22px);
}

body.child-page.gyouji.iryo .gyouji__head .gyouji__head__num {
  background: #9AC144;
}

body.child-page.gyouji.iryo .gyouji__head .gyouji__head__num:after {
  border-color: transparent transparent transparent #9AC144;
}

body.child-page.gyouji.iryo .gyouji__head .gyouji__head__year {
  border: 1px solid #9AC144;
  color: #9AC144;
}

body.child-page.gyouji.iryo .icon_pink-box {
  width: 1em;
  height: 1em;
}

body.child-page.gyouji.htc .gyouji__block:nth-of-type(odd) {
  background-color: #FDF0E4;
}

body.child-page.gyouji.htc .gyouji__block:nth-of-type(even) {
  background: repeating-linear-gradient(90deg, #FDF0E4, #FDF0E4 11px, #fff 0, #fff 22px);
}

body.child-page.gyouji.htc .gyouji__head .gyouji__head__num {
  background: #FF7603;
}

body.child-page.gyouji.htc .gyouji__head .gyouji__head__num:after {
  border-color: transparent transparent transparent #FF7603;
}

body.child-page.gyouji.htc .gyouji__head .gyouji__head__year {
  border: 1px solid #FF7603;
  color: #FF7603;
}

body.child-page.gyouji.htc .gyouji__content .gyouji__content__description .description__head {
  color: #FF7603;
  border-top: 1px solid #FF7603;
  border-bottom: 1px solid #FF7603;
}

body.child-page.gyouji.htc .icon_pink-box {
  width: 1em;
  height: 1em;
}

body.child-page.gyouji.htc .gyouji__content__description {
  margin-top: -80px;
}

body.child-page.gyouji.htc .sdgs__content {
  width: 100%;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

body.child-page.gyouji.htc .sdgs__bg-top, body.child-page.gyouji.htc .sdgs__bg-bottom {
  width: 100%;
}

body.child-page.gyouji.htc .sdgs__bg-bottom {
  display: flex;
}

body.child-page.gyouji.htc .sdgs__content-wrap {
  width: 100%;
  border-left: 9px solid #CFA55C;
  border-right: 9px solid #CFA55C;
  background-color: #D7EFFC;
  padding: 65px 0 25px;
}

body.child-page.gyouji.htc .sdgs__content-in {
  width: 88%;
  max-width: 857px;
  margin-inline: auto;
  gap: 26px 46px;
}

body.child-page.gyouji.htc .sdgs__content-in .item {
  width: calc(50% - 23px);
}

body.child-page.gyouji.htc .sdgs__content-in .sdgs__btn a {
  width: 100%;
  max-width: 220px;
}

body.child-page.hutankeigen .hutatkeigen__inner {
  max-width: 730px;
  margin: auto;
}

body.child-page.hutankeigen .hutankeigen__block--01 {
  max-width: 630px;
  margin: auto;
}

body.child-page.hutankeigen .page-contents .point {
  text-align: center;
}

body.child-page.hutankeigen .page-contents .point figcaption {
  font-size: 1rem;
  font-weight: bold;
}

body.child-page.hutankeigen .page-contents .bloquote {
  display: flex;
  align-items: flex-start;
}

body.child-page.hutankeigen .page-contents .bloquote .bloquote__text {
  font-weight: bold;
  font-size: 1.25rem;
}

body.child-page.hutankeigen .page-contents .tel__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

body.child-page.hutankeigen .page-contents .tel__wrap .number {
  font-weight: bold;
  font-size: 2.3125rem;
  line-height: 1.2;
}

body.child-page.hutankeigen .page-contents .tel__wrap .number a {
  color: #212224;
  text-decoration: underline;
}

body.child-page.hutankeigen .page-contents .time {
  font-size: 0.9375rem;
}

body.child-page.hutankeigen .page-contents .hutankeigen-acc li {
  border: none;
  margin-bottom: 2rem;
}

body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn {
  background-color: #333;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 1.3125rem;
}

body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn:after {
  border-color: transparent white white transparent;
  width: 12px;
  height: 12px;
  border-width: 2px;
  right: 11px;
}

body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn:before {
  content: '';
  position: absolute;
  margin: auto;
  display: block;
  background-color: #fff;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  top: 0;
  right: 16px;
  bottom: 0;
  width: 2px;
  height: 15px;
}

body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn.is_active:before {
  -webkit-transform: rotate(-180deg) translateY(-3px);
  transform: rotate(-180deg) translateY(-3px);
}

body.child-page.hutankeigen .page-contents .hutankeigen-acc .accContent {
  padding: 3rem 0;
}

body.child-page.hs-teachers main {
  margin-bottom: 0;
}

body.child-page.hs-teachers .f-mintyo {
  font-family: "Noto Serif JP", serif;
}

body.child-page.hs-teachers .section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

body.child-page.hs-teachers .page-header {
  margin-bottom: 7rem;
}

body.child-page.hs-teachers .page-header .head__wrap, body.child-page.hs-teachers .page-header body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .page-header .head__wrap {
  position: relative;
}

body.child-page.hs-teachers .page-header .head__wrap h1, body.child-page.hs-teachers .page-header body.lp.opencampus.parent-group .section__04 .head__wrap h1, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .page-header .head__wrap h1 {
  font-family: "Noto Serif JP", serif;
  position: absolute;
  right: 0;
  left: 0;
  font-size: 2.1875rem;
  font-weight: bold;
  margin: auto;
  color: white;
  z-index: 10;
  padding: 1rem 0.5rem;
}

body.child-page.hs-teachers .page-header .head__wrap h1:before, body.child-page.hs-teachers .page-header body.lp.opencampus.parent-group .section__04 .head__wrap h1:before, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .page-header .head__wrap h1:before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  background-color: rgba(230, 24, 116, 0.6);
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  z-index: -1;
}

body.child-page.hs-teachers .teachers__intro {
  font-family: "Noto Serif JP", serif;
}

body.child-page.hs-teachers .teachers__intro .teachers__intro__text {
  font-size: 1.75rem;
}

body.child-page.hs-teachers .teachers__intro .text__inner {
  line-height: 2.32143;
}

body.child-page.hs-teachers .teachers__intro .text__aside {
  font-size: 1rem;
}

body.child-page.hs-teachers .section__qualification {
  background-color: #F5F1F1;
  position: relative;
}

body.child-page.hs-teachers .section__qualification:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 3rem;
  height: 3rem;
  background-color: #F5F1F1;
  -webkit-transform: rotate(45deg) translate(1rem, 1rem);
  transform: rotate(45deg) translate(1rem, 1rem);
}

body.child-page.hs-teachers .section__qualification .head__wrap, body.child-page.hs-teachers .section__qualification body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .section__qualification .head__wrap {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3.125rem;
}

body.child-page.hs-teachers .section__qualification .sectino__intro {
  font-size: 1rem;
  line-height: 2.13043;
  margin-bottom: 3rem;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__list .qualification__list__inner {
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  height: 100%;
  padding: 1rem 5px;
  font-weight: 500;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__list.qualification__list--01 .qualification__list__inner {
  color: #E9579A;
  border-color: currentColor;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__list.qualification__list--02 .qualification__list__inner {
  color: #9BC823;
  border-color: currentColor;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__list.qualification__list--03 .qualification__list__inner {
  color: #F29117;
  border-color: currentColor;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__list.qualification__list--04 .qualification__list__inner {
  color: #29BBEF;
  border-color: currentColor;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__list.qualification__list--05 .qualification__list__inner {
  color: #ED7DAE;
  border-color: currentColor;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__list.qualification__list--06 .qualification__list__inner {
  color: #5DBFAD;
  border-color: currentColor;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__name {
  font-size: 1.4375rem;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__body {
  font-size: 1.25rem;
  margin-bottom: 0;
}

body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__body.qualification__body--num {
  font-size: 2.1875rem;
}

body.child-page.hs-teachers .section__work {
  position: relative;
}

body.child-page.hs-teachers .section__work:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 3rem;
  height: 3rem;
  background-color: white;
  -webkit-transform: rotate(45deg) translate(1rem, 1rem);
  transform: rotate(45deg) translate(1rem, 1rem);
}

body.child-page.hs-teachers .section__work .head__wrap, body.child-page.hs-teachers .section__work body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .section__work .head__wrap {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3.125rem;
}

body.child-page.hs-teachers .section__work .sectino__intro {
  font-size: 1rem;
  line-height: 2.13043;
  margin-bottom: 3rem;
}

body.child-page.hs-teachers .section__work .work__head {
  font-size: 2.0625rem;
  color: #F29117;
  margin-bottom: 0;
}

body.child-page.hs-teachers .section__work .work__num {
  font-size: 7.5rem;
  line-height: 1;
}

body.child-page.hs-teachers .section__work .work__num__per {
  font-size: 4.375rem;
}

body.child-page.hs-teachers .section__work .work__aside {
  font-size: 1.3125rem;
  color: #F29117;
}

body.child-page.hs-teachers .section__experience {
  background: repeating-linear-gradient(-45deg, #FCF6E1, #FCF6E1 7px, #FEEEB4 0, #FEEEB4 14px);
}

body.child-page.hs-teachers .section__experience .experience__inner {
  background-color: #fff;
  border-radius: 50px;
  padding: 1.875rem 0.625rem 3.75rem;
  margin-bottom: 4.375rem;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__head {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0.625rem 0.5rem;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__head.experience__head--01 {
  background-color: #E9579A;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__head.experience__head--02 {
  background-color: #F29117;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__head.experience__head--03 {
  background-color: #29BBEF;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__head.experience__head--04 {
  background-color: #ED7DAE;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__head.experience__head--05 {
  background-color: #5DBFAD;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__description {
  font-size: 1rem;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 .head {
  font-size: 1.5rem;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 .inquiry__block {
  padding: 1.5rem;
  font-size: 1rem;
  background-color: #F5F5F5;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 .tel__block a {
  background-color: #F5F5F5;
  color: #4B4B4B;
  font-size: 2rem;
  padding: 1rem .5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
  text-decoration: none;
}

body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 .tel__block a:hover {
  opacity: .7;
}

body.child-page.hs-teachers .section__experience .head__wrap, body.child-page.hs-teachers .section__experience body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .section__experience .head__wrap {
  margin-bottom: 1.5625rem;
  text-align: center;
  font-size: 1.75rem;
}

body.child-page.hs-teachers .section__experience .intro__wrap {
  font-size: 1rem;
  line-height: 1.9375;
  margin-bottom: 3.125rem;
}

body.child-page.hs-teachers .section__experience .head {
  position: relative;
  font-weight: 900;
  line-height: 1.57143;
  font-size: 1.75rem;
}

body.child-page.hs-teachers .section__experience .head .head__item {
  display: block;
  margin: auto auto 1rem;
}

body.child-page.hs-teachers .section__experience .marker {
  background: linear-gradient(180deg, transparent 70%, #FFFF63 70%);
}

body.child-page.hs-teachers .section__experience .experience__check {
  font-size: 1.0625rem;
  font-weight: bold;
}

body.child-page.hs-teachers .section__experience .schedule .schedule__head {
  font-weight: bold;
  font-size: 1.4375rem;
}

body.child-page.hs-teachers .section__experience .schedule .schedule__cicle .schedule__cicle__inner {
  font-weight: bold;
  font-size: 1.25rem;
  background-color: #F4CB35;
  border-radius: 50px;
  color: white;
  padding: .5rem 3rem;
}

body.child-page.shakaijin .section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

body.child-page.shakaijin .for-all .for-all__list:not(:last-of-type) {
  margin-right: 20px;
}

body.child-page.shakaijin .intro-strength {
  margin-bottom: 6.875rem;
  font-size: 1.0625rem;
}

body.child-page.shakaijin .page-header .page-head {
  margin-bottom: 5rem;
}

body.child-page.shakaijin .point-head.point-head--01 {
  position: relative;
  line-height: 1;
}

body.child-page.shakaijin .point-head.point-head--01:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 10px;
  width: 100%;
  display: block;
  background-color: #D4EBF5;
  z-index: -1;
}

body.child-page.shakaijin .section__road .road__body01 {
  font-size: 1.4375rem;
  line-height: 2.13043;
}

body.child-page.shakaijin .section__road .road__body02 {
  font-size: 1.4375rem;
  font-weight: bold;
  color: #0092E5;
}

body.child-page.shakaijin .section__road .road__body03 {
  font-size: 2rem;
  font-weight: bold;
  color: #0092E5;
}

body.child-page.shakaijin .section__road .road__body03 .road__body03__strong {
  color: #DE0063;
}

body.child-page.shakaijin .section__road .road__body03 .road__body03__inner .mini__block {
  display: inline-block;
  position: relative;
}

body.child-page.shakaijin .section__road .road__body03 .road__body03__inner .mini__block:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-top: 1px solid #DE0063;
  border-bottom: 1px solid #DE0063;
}

body.child-page.shakaijin .section__road .road__hutan {
  position: relative;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

body.child-page.shakaijin .section__road .road__hutan:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  background-color: #D4EBF5;
  z-index: -1;
}

body.child-page.shakaijin .section__merit .merit__intro {
  text-align: center;
  font-size: 1.4375rem;
  margin-bottom: 3.125rem;
  line-height: 2.13043;
}

body.child-page.shakaijin .section__merit .merit__head.merit__head--02 {
  margin-top: 4.375rem;
}

body.child-page.shakaijin .section__merit .merit__head.merit__head--03 {
  margin-top: 8.75rem;
}

body.child-page.shakaijin .section__merit .marker {
  background: linear-gradient(180deg, transparent 70%, #F4CB35 70%);
}

body.child-page.shakaijin .section__merit .merit__num {
  font-size: 7.5rem;
  line-height: 1;
  font-weight: 900;
  color: #005C9B;
}

body.child-page.shakaijin .section__merit .merit__num__per {
  font-size: 4.375rem;
  font-weight: 900;
  color: #005C9B;
}

body.child-page.shakaijin .section__merit .merit__aside {
  margin-bottom: 8.75rem;
}

body.child-page.shakaijin .section__merit .merit__shikaku {
  font-weight: bold;
  font-size: 2rem;
  color: #0092E5;
  display: inline-block;
  border-bottom: 7px double;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list {
  border: none;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accBtn {
  border-width: 1px;
  border-style: solid;
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 20px;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accBtn:after {
  border-width: 1px;
  right: 15px;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accBtn.is_active {
  color: white !important;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accBtn.is_active:after {
  border-color: transparent #fff #fff transparent !important;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accContent {
  color: white;
  font-weight: normal;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accContent .accContent__intro {
  margin-bottom: 2rem;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accContent .accContent__head {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accContent .accContent__link {
  margin-bottom: 2rem;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accContent .accContent__link a {
  font-size: 1.25rem;
  text-align: center;
  display: block;
  background-color: white;
  padding: .5rem 1.5rem .5rem 1rem;
  border-radius: 50px;
  transition: .2s;
  text-decoration: none;
  position: relative;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accContent .accContent__link a:hover {
  opacity: .7;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list .accContent .accContent__link a:after {
  content: '';
  display: block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-width: 1px;
  border-color: transparent currentColor currentColor transparent;
  border-style: solid;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--01 .accContent__link a {
  color: #E9579A;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--01 .accContent {
  background-color: #E9579A;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--01 .accBtn {
  color: #E9579A;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--01 .accBtn:after {
  border-color: transparent #E9579A #E9579A transparent;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--01 .accBtn.is_active {
  background-color: #E9579A;
  border-color: #E9579A;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--02 .accContent__link a {
  color: #9BC823;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--02 .accContent {
  background-color: #9BC823;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--02 .accBtn {
  color: #9BC823;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--02 .accBtn:after {
  border-color: transparent #9BC823 #9BC823 transparent;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--02 .accBtn.is_active {
  background-color: #9BC823;
  border-color: #9BC823;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--03 .accContent__link a {
  color: #F29117;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--03 .accContent {
  background-color: #F29117;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--03 .accBtn {
  color: #F29117;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--03 .accBtn:after {
  border-color: transparent #F29117 #F29117 transparent;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--03 .accBtn.is_active {
  background-color: #F29117;
  border-color: #F29117;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--04 .accContent__link a {
  color: #29BBEF;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--04 .accContent {
  background-color: #29BBEF;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--04 .accBtn {
  color: #29BBEF;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--04 .accBtn:after {
  border-color: transparent #29BBEF #29BBEF transparent;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--04 .accBtn.is_active {
  background-color: #29BBEF;
  border-color: #29BBEF;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--05 .accContent__link a {
  color: #ED7DAE;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--05 .accContent {
  background-color: #ED7DAE;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--05 .accBtn {
  color: #ED7DAE;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--05 .accBtn:after {
  border-color: transparent #ED7DAE #ED7DAE transparent;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--05 .accBtn.is_active {
  background-color: #ED7DAE;
  border-color: #ED7DAE;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--06 .accContent__link a {
  color: #5DBFAD;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--06 .accContent {
  background-color: #5DBFAD;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--06 .accBtn {
  color: #5DBFAD;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--06 .accBtn:after {
  border-color: transparent #5DBFAD #5DBFAD transparent;
}

body.child-page.shakaijin .section__merit .acc--shikaku .shikaku__list.shikaku__list--06 .accBtn.is_active {
  background-color: #5DBFAD;
  border-color: #5DBFAD;
}

body.child-page.shakaijin .section__interview {
  background-color: #D4EBF5;
}

body.child-page.shakaijin .section__interview .section__head {
  margin-bottom: 3.125rem;
}

body.child-page.shakaijin .section__interview .interview__list {
  margin-bottom: 6.25rem;
}

body.child-page.shakaijin .section__interview .interview__head {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0092E5;
  margin-bottom: 1.5rem;
}

body.child-page.shakaijin .section__interview .interview__name {
  font-size: 1.3125rem;
  font-weight: bold;
  color: #0092E5;
  margin-bottom: 1rem;
}

body.child-page.shakaijin .section__interview .interview__job-change {
  margin-bottom: 1.5rem;
}

body.child-page.shakaijin .section__signal .signal__inner {
  background-color: #FFFAC0;
  padding: 4.375rem 0.625rem;
  border-radius: 45px;
}

body.child-page.shakaijin .section__signal .sub__head {
  color: #F29117;
  font-weight: 500;
  font-size: 1.4375rem;
}

body.child-page.shakaijin .section__signal .section__head {
  color: #F29117;
  font-weight: bold;
  font-size: 2.0625rem;
}

body.child-page.shakaijin .section__signal .section__intro {
  font-size: 1.25rem;
  line-height: 2;
}

body.child-page.shakaijin .section__signal .signal__study-list {
  font-weight: bold;
  font-size: 1.5625rem;
}

body.child-page.shakaijin .section__signal .inquiry__item {
  margin-top: 4.375rem;
}

body.child-page.shakaijin .section__signal .icon-tel {
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

body.child-page.shakaijin .section__signal .txt {
  font-size: 1.25rem;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner {
  position: relative;
  border: 1px solid #1A1311;
  padding: 4rem .5rem 3rem;
  height: 100%;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner:before, body.child-page.shakaijin .section__signal .inquiry__item__inner:after {
  content: '';
  display: block;
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner:before {
  left: -3px;
  top: -3px;
  border-left: 5px solid #1A1311;
  border-top: 5px solid #1A1311;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner:after {
  right: -3px;
  top: -3px;
  border-right: 5px solid #1A1311;
  border-top: 5px solid #1A1311;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner-second {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner-second:before, body.child-page.shakaijin .section__signal .inquiry__item__inner-second:after {
  content: '';
  display: block;
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner-second:before {
  left: -3px;
  bottom: -3px;
  border-left: 5px solid #1A1311;
  border-bottom: 5px solid #1A1311;
}

body.child-page.shakaijin .section__signal .inquiry__item__inner-second:after {
  right: -3px;
  bottom: -3px;
  border-right: 5px solid #1A1311;
  border-bottom: 5px solid #1A1311;
}

body.child-page.shakaijin .section__signal .inquiry__icon {
  position: absolute;
  top: 0;
  left: 50%;
  margin: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #FFFAC0;
  display: inline-block;
  padding: 0 2rem;
}

body.child-page.shakaijin .section__signal .inquiry__description {
  margin-bottom: 2rem;
  font-size: 0.75rem;
}

body.child-page.facility .p-article__head {
  font-size: 1.125rem;
  font-weight: bold;
}

body.child-page.facility .p-article__item {
  margin-top: 10px;
}

body.child-page.facility .p-article__description {
  font-size: 0.875rem;
}

body.child-page.facility .p-article__thumb {
  text-align: center;
  margin-top: 10px;
}

body.child-page.facility .p-article__body {
  margin-top: 15px;
}

body.child-page.htc.page-restaurant h2.head-ch.restaurant {
  background: linear-gradient(90deg, #EFDA3A 0, #EFDA3A 15%, #e2e2e2 0) no-repeat;
  background-size: 100% 6px;
  background-position: bottom;
}

body.child-page.htc.page-restaurant .label {
  min-width: 160px;
  padding: 0.3em 1em;
  display: inline-block;
  border: 1px solid #FA7402;
  color: #FA7402;
  text-align: center;
}

body.child-page.htc.page-restaurant .label.fill02 {
  background-color: #FA7402;
  color: #fff;
}

body.child-page.htc.page-restaurant .label-large {
  font-size: 1.125rem;
  font-weight: bold;
}

body.child-page.htc.page-restaurant .color-fill02 {
  color: #FA7402;
}

body.child-page.htc.page-restaurant .r-5 {
  border-radius: 5px;
}

body.child-page.htc.page-restaurant .box-contact {
  margin-bottom: 50px;
  padding: 70px 30px;
  text-align: center;
  background-size: auto auto;
  background-color: white;
  background-image: -webkit-repeating-linear-gradient(135deg, transparent, transparent 10px, seashell 10px, seashell 20px);
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 10px, seashell 10px, seashell 20px);
}

body.child-page.htc.page-restaurant .page-header {
  position: relative;
  aspect-ratio: 1500/864;
  background-color: #ff7603;
  background-position: top left;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-animation: restaurant-mv-pc 10s linear infinite normal forwards;
  animation: restaurant-mv-pc 10s linear infinite normal forwards;
}

@-webkit-keyframes restaurant-mv-pc {
  0% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
  }
  42% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
  }
  50% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
  }
  92% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
  }
  99.9%, to {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
  }
}

@keyframes restaurant-mv-pc {
  0% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
  }
  42% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
  }
  50% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
  }
  92% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
  }
  99.9%, to {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
  }
}

@-webkit-keyframes restaurant-mv-sp {
  0% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
  }
  42% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
  }
  50% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
  }
  92% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
  }
  99.9%, to {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
  }
}

@keyframes restaurant-mv-sp {
  0% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
  }
  42% {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
  }
  50% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
  }
  92% {
    background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
  }
  99.9%, to {
    background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
  }
}

body.child-page.htc.page-restaurant .page-header .container {
  display: flex;
  align-items: center;
}

body.child-page.htc.page-restaurant .page-header .container h1 {
  display: none;
}

body.child-page.htc.page-restaurant .page-restaurant h4.restaurant__title {
  font-size: 20px;
}

body.child-page.htc.page-restaurant h4.restaurant__title::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 1000px;
  border: 5px solid #FA7402;
  margin-right: 10px;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}

body.child-page.htc.page-restaurant .flow-restaurant-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  counter-reset: count-number;
}

body.child-page.htc.page-restaurant .flow-restaurant-list--item {
  padding-bottom: 40px;
}

body.child-page.htc.page-restaurant .flow-restaurant-list--item:before {
  counter-increment: count-number;
  content: counter(count-number);
  margin-left: auto;
  margin-bottom: 12px;
  display: block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #FA7402;
  color: #fff;
  font-size: 1.625rem;
  font-weight: bold;
  text-align: center;
  margin-inline: auto;
}

body.child-page.htc.page-restaurant .flow-restaurant--title {
  margin-bottom: 0.2em;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

body.child-page.htc.page-restaurant .flow-restaurant--body {
  text-align: center;
}

body.child-page.htc.page-restaurant .flow-restaurant-box--wrapper {
  gap: 20px 30px;
}

body.child-page.htc.page-restaurant .flow-restaurant-box--item {
  width: 100%;
  max-width: 445px;
}

body.hsc .hero, body.htc .hero, body.iryo .hero {
  padding-top: 0 !important;
  position: relative;
}

body.hsc .hero .hero-slider, body.htc .hero .hero-slider, body.iryo .hero .hero-slider {
  width: 100%;
  background-color: #fff;
}

body.hsc .hero .hero-slider__item, body.htc .hero .hero-slider__item, body.iryo .hero .hero-slider__item {
  width: 100%;
}

body.hsc .hero .hero-slider__item img, body.htc .hero .hero-slider__item img, body.iryo .hero .hero-slider__item img {
  width: 100%;
}

body.hsc .hero__elm .pickup, body.htc .hero__elm .pickup, body.iryo .hero__elm .pickup {
  margin-top: 0;
}

body.hsc .section--02, body.htc .section--02, body.iryo .section--02 {
  background: none;
  background-color: #fff;
}

body.hsc .simple-link-nav, body.htc .simple-link-nav, body.iryo .simple-link-nav {
  padding: 16px 0;
}

body.hsc .simple-link-nav > .container, body.htc .simple-link-nav > .container, body.iryo .simple-link-nav > .container {
  max-width: 1100px !important;
}

body.hsc .simple-link-nav li a, body.htc .simple-link-nav li a, body.iryo .simple-link-nav li a {
  text-decoration: underline;
  color: #333;
  width: 100%;
  position: relative;
  white-space: nowrap;
  line-height: 2;
}

body.hsc .simple-link-nav li a::after, body.htc .simple-link-nav li a::after, body.iryo .simple-link-nav li a::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  -webkit-clip-path: path("M5,10l5-4.612-.163-.146L5.116,9.6V0H4.884V9.6L.163,5.243,0,5.388,5,10m0,1.36L-1.487,5.377.173,3.891l3.71,3.428V-1H6.116V7.319l3.71-3.428,1.66,1.486Z");
  clip-path: path("M5,10l5-4.612-.163-.146L5.116,9.6V0H4.884V9.6L.163,5.243,0,5.388,5,10m0,1.36L-1.487,5.377.173,3.891l3.71,3.428V-1H6.116V7.319l3.71-3.428,1.66,1.486Z");
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

body.hsc .simple-link-nav li a:hover, body.htc .simple-link-nav li a:hover, body.iryo .simple-link-nav li a:hover {
  text-decoration: none;
}

body.hsc .banner-slider > .container, body.htc .banner-slider > .container, body.iryo .banner-slider > .container {
  max-width: 1100px !important;
}

body.hsc .banner-slide, body.htc .banner-slide, body.iryo .banner-slide {
  margin-top: 70px;
}

body.hsc .news, body.htc .news, body.iryo .news {
  background: #fff url(../img/pages/bg01.png);
  padding: 60px 0 80px;
}

body.hsc .banner-slide__main, body.htc .banner-slide__main, body.iryo .banner-slide__main {
  position: relative;
  padding: 0 34px;
}

body.hsc .banner-slide__main .slide-arrow, body.htc .banner-slide__main .slide-arrow, body.iryo .banner-slide__main .slide-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 34px;
  border: none;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  transition: box-shadow 0.3s linear, -webkit-transform 0.3s ease;
  transition: box-shadow 0.3s linear, transform 0.3s ease;
  transition: box-shadow 0.3s linear, transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.hsc .banner-slide__main .slide-arrow:focus, body.htc .banner-slide__main .slide-arrow:focus, body.iryo .banner-slide__main .slide-arrow:focus {
  outline: none;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

body.hsc .banner-slide__main .slide-arrow span, body.htc .banner-slide__main .slide-arrow span, body.iryo .banner-slide__main .slide-arrow span {
  width: 17px;
  height: 34px;
  background-color: #000;
  -webkit-clip-path: polygon(0 0, 100% 50%, 0% 100%);
  clip-path: polygon(0 0, 100% 50%, 0% 100%);
}

body.hsc .banner-slide__main .prev-arrow, body.htc .banner-slide__main .prev-arrow, body.iryo .banner-slide__main .prev-arrow {
  left: 0;
}

body.hsc .banner-slide__main .prev-arrow span, body.htc .banner-slide__main .prev-arrow span, body.iryo .banner-slide__main .prev-arrow span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

body.hsc .banner-slide__main .next-arrow, body.htc .banner-slide__main .next-arrow, body.iryo .banner-slide__main .next-arrow {
  right: 0;
}

body.hsc .banner-slide__main__img, body.htc .banner-slide__main__img, body.iryo .banner-slide__main__img {
  aspect-ratio: 396/150;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.hsc .banner-slide__main__img img, body.htc .banner-slide__main__img img, body.iryo .banner-slide__main__img img {
  height: 100%;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
  margin-inline: auto;
}

body.hsc .p-movie, body.htc .p-movie, body.iryo .p-movie {
  margin-top: 80px;
}

body.hsc .p-movie .movie-index, body.htc .p-movie .movie-index, body.iryo .p-movie .movie-index {
  width: 100%;
  max-width: 860px;
  aspect-ratio: 16/9;
  margin-inline: auto;
}

body.hsc .p-movie .movie-index iframe, body.htc .p-movie .movie-index iframe, body.iryo .p-movie .movie-index iframe {
  width: 100%;
  height: 100%;
}

body.hsc .p-point__title, body.htc .p-point__title, body.iryo .p-point__title {
  width: 40vw;
  max-width: 614px;
  margin-inline: auto;
}

body.hsc .p-point__block:nth-of-type(1), body.htc .p-point__block:nth-of-type(1), body.iryo .p-point__block:nth-of-type(1) {
  width: 100%;
  max-width: 1210px;
  margin-inline: auto;
}

body.hsc .p-point__block:nth-of-type(2), body.htc .p-point__block:nth-of-type(2), body.iryo .p-point__block:nth-of-type(2) {
  width: 100%;
  max-width: 680px;
  margin-inline: auto;
}

body.hsc .p-point-lower.p-showY, body.htc .p-point-lower.p-showY, body.iryo .p-point-lower.p-showY {
  -webkit-transform: translate(10px, 30px);
  transform: translate(10px, 30px);
}

body.hsc .p-point-lower.p-showY.is_scrolled, body.htc .p-point-lower.p-showY.is_scrolled, body.iryo .p-point-lower.p-showY.is_scrolled {
  -webkit-transform: translate(10px, 0px);
  transform: translate(10px, 0px);
}

body.hsc .p-accreditation .item, body.htc .p-accreditation .item, body.iryo .p-accreditation .item {
  align-self: flex-start;
}

body.hsc .p-accreditation .item:nth-of-type(2), body.htc .p-accreditation .item:nth-of-type(2), body.iryo .p-accreditation .item:nth-of-type(2) {
  margin-top: 190px;
}

body.hsc .p-accreditation .item .item-inner, body.htc .p-accreditation .item .item-inner, body.iryo .p-accreditation .item .item-inner {
  position: relative;
  z-index: 1;
  aspect-ratio: 1;
}

body.hsc .p-accreditation .item .item-inner::before, body.htc .p-accreditation .item .item-inner::before, body.iryo .p-accreditation .item .item-inner::before {
  content: '';
  display: inline-block;
  max-width: 220px;
  max-height: 220px;
  aspect-ratio: 1;
  background: url(../img/pages/bg_gekkei-wh.png) center/cover no-repeat;
  position: absolute;
  inset: 0;
  margin: auto;
  z-index: -1;
}

body.hsc .p-accreditation .item .title, body.htc .p-accreditation .item .title, body.iryo .p-accreditation .item .title {
  font-size: 30px;
  color: #089B99;
  line-height: calc(45 / 30);
}

body.hsc .p-accreditation .item .text, body.htc .p-accreditation .item .text, body.iryo .p-accreditation .item .text {
  font-size: 16px;
}

body.hsc .p-campus .p-campas__list, body.htc .p-campus .p-campas__list, body.iryo .p-campus .p-campas__list {
  gap: 20px;
}

body.hsc .p-campus .order__elm, body.htc .p-campus .order__elm, body.iryo .p-campus .order__elm {
  width: calc((100% / 4) - 15px);
}

body.hsc .p-campus .order__elm.crown, body.htc .p-campus .order__elm.crown, body.iryo .p-campus .order__elm.crown {
  position: relative;
}

body.hsc .p-campus .order__elm.crown::after, body.htc .p-campus .order__elm.crown::after, body.iryo .p-campus .order__elm.crown::after {
  content: '';
  display: inline-block;
  width: 74px;
  height: 74px;
  background: url(../img/pages/icon-crown.png) center/cover no-repeat;
  position: absolute;
  top: 13px;
  right: 9px;
}

body.hsc .visitors-list, body.htc .visitors-list, body.iryo .visitors-list {
  display: flex;
}

body.hsc .visitors-list li, body.htc .visitors-list li, body.iryo .visitors-list li {
  width: calc(100% / 6);
  position: relative;
  z-index: 1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.hsc .visitors-list li a, body.htc .visitors-list li a, body.iryo .visitors-list li a {
  display: block;
  height: 100%;
  padding: 15px 15px 33px;
  color: #333333;
  font-size: min(1.3vw, 20px);
  position: relative;
  line-height: 1.2;
  border: 1px solid black;
  background-color: #fff;
  z-index: 1;
}

body.hsc .visitors-list li a::after, body.htc .visitors-list li a::after, body.iryo .visitors-list li a::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 18px;
  background-color: #333;
  -webkit-clip-path: path("M8.418,19.307a.5.5,0,0,1-.359-.152L-.359,10.482a.5.5,0,0,1,.005-.7l.274-.274a.5.5,0,0,1,.713.006l7.089,7.316V0a.5.5,0,0,1,.5-.5h.392a.5.5,0,0,1,.5.5V16.829L16.2,9.512a.5.5,0,0,1,.713-.006l.274.274a.5.5,0,0,1,.005.7L8.777,19.155A.5.5,0,0,1,8.418,19.307Z");
  clip-path: path("M8.418,19.307a.5.5,0,0,1-.359-.152L-.359,10.482a.5.5,0,0,1,.005-.7l.274-.274a.5.5,0,0,1,.713.006l7.089,7.316V0a.5.5,0,0,1,.5-.5h.392a.5.5,0,0,1,.5.5V16.829L16.2,9.512a.5.5,0,0,1,.713-.006l.274.274a.5.5,0,0,1,.005.7L8.777,19.155A.5.5,0,0,1,8.418,19.307Z");
  position: absolute;
  bottom: 12px;
  right: 12px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

body.hsc .visitors-list li a:hover, body.htc .visitors-list li a:hover, body.iryo .visitors-list li a:hover {
  text-decoration: none;
}

body.hsc .visitors-list li a .small, body.htc .visitors-list li a .small, body.iryo .visitors-list li a .small {
  font-size: min(0.85vw, 14px);
}

body.hsc .p-opencampus, body.htc .p-opencampus, body.iryo .p-opencampus {
  width: 100%;
  max-width: 1000px;
  margin-inline: auto;
}

body.hsc .p-opencampus-btn, body.htc .p-opencampus-btn, body.iryo .p-opencampus-btn {
  width: 92%;
  max-width: 1000px;
  display: flex;
  gap: 10px 20px;
}

body.hsc .p-instagram, body.htc .p-instagram, body.iryo .p-instagram {
  background-color: #fff;
}

body.hsc .p-instagram .f-century, body.htc .p-instagram .f-century, body.iryo .p-instagram .f-century {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

body.hsc .p-instagram .f-century::before, body.htc .p-instagram .f-century::before, body.iryo .p-instagram .f-century::before {
  content: '';
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(../img/common/icon_insta.png) center/cover no-repeat;
  margin-right: 12px;
  opacity: 0;
  transition: opacity 0.3s linear 1.5s;
}

body.hsc .p-instagram .is_scrolled .f-century::before, body.htc .p-instagram .is_scrolled .f-century::before, body.iryo .p-instagram .is_scrolled .f-century::before {
  opacity: 1;
}

body.hsc .p-now-future, body.htc .p-now-future, body.iryo .p-now-future {
  width: 100%;
  max-width: 900px;
  margin-inline: auto;
}

body.hsc.school-home .head__wrap:not(.other-color), body.hsc.school-home body.lp.opencampus.parent-group .section__04 .head__wrap:not(.other-color), body.lp.opencampus.parent-group .section__04 body.hsc.school-home .head__wrap:not(.other-color) {
  color: #EB6EA5;
}

body.hsc.school-home .hero {
  padding-top: 0;
  background-color: #FAE5EE;
}

body.hsc.school-home .simple-link-nav {
  background-color: #FAE5EE;
}

body.hsc.school-home .section--03, body.hsc.school-home .section--05 {
  background: none;
  background-color: #FAE5EE;
}

body.hsc.school-home .p-open-img:after, body.hsc.school-home .p-open-img:before,
body.hsc.school-home .p-open-img .line {
  background-color: #EB6EA5;
}

body.hsc .p-target-menu {
  background-color: #EB6EA5;
}

body.hsc .p-target-menu a:hover {
  color: #EB6EA5;
}

body.hsc .p-target-menu a:hover:after {
  background-color: #EB6EA5;
}

body.hsc .p-target-menu a:hover:before {
  border-color: #EB6EA5;
}

body.hsc .p-access {
  background: #fff url(../img/pages/hsc/bg_circle01.png) no-repeat;
  background-size: 1105px 1077px;
  background-position: top center;
}

body.hsc .p-access .head__wrap, body.hsc .p-access body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.hsc .p-access .head__wrap, body.lp.opencampus.hsc .p-access .section__03 .head__wrap, body.lp.opencampus.hsc .section__03 .p-access .head__wrap, body.hsc .p-access body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.hsc .p-access .head__wrap, body.hsc .p-access body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.hsc .p-access .head__wrap {
  color: #EB6EA5;
}

body.hsc .p-access .p-gradient {
  font-weight: bold;
  font-size: 1.875rem;
}

body.hsc .p-access .p-gradient .gradient__inner {
  position: relative;
  padding: 0.3rem 2rem;
  color: white;
}

body.hsc .p-access .p-gradient .gradient__inner:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, #ed87de 0%, #82e8f5 100%);
}

body.hsc .p-access .p-gradient .gradient__text {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.hsc .p-access .p-gradient.is_scrolled .gradient__inner:before {
  width: 100%;
  transition: width .5s;
}

body.hsc .p-access .p-gradient.is_scrolled .gradient__text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 1s ease .3s;
}

body.hsc .section--04 {
  background-color: #fff;
}

body.hsc .section--05 .container {
  max-width: 1000px !important;
}

body.hsc .section--06 {
  background-color: white;
  overflow: hidden;
}

body.hsc .section--06 .inner {
  position: relative;
  font-weight: bold;
}

body.hsc .section--06 .inner h3 {
  background: #333;
  font-weight: bold;
  margin-bottom: 1.5rem;
  opacity: 0;
}

body.hsc .section--06 .inner h3.is_scrolled {
  transition: 2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
  opacity: 1;
}

body.hsc .section--06 .elm__block.elm__block--img {
  width: 30vw;
  min-width: 450px;
}

body.hsc .section--06 .second__content {
  max-width: 965px;
  margin: auto;
}

body.hsc .section--06 .second__content li {
  margin-bottom: 1.25rem;
}

body.hsc .section--06 .second__head {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

body.hsc .section--06 .bg__item {
  position: absolute;
}

body.hsc .section--06 .bg__item.bg__item--01 {
  width: 17vw;
  max-width: 269px;
  top: 50%;
  left: 2%;
}

body.hsc .section--06 .bg__item.bg__item--02 {
  width: 14vw;
  max-width: 217px;
  top: 45%;
  right: 5%;
}

body.hsc .section--07 {
  background: repeating-linear-gradient(-45deg, #FEF7FA, #FEF7FA 13px, #fff 0, #fff 26px);
}

body.hsc .section--08 {
  background: #fff url(../img/pages/bg01.png);
}

body.hsc .section--09 {
  background-color: #fff;
}

body.hsc .section--09 .title {
  background: rgba(235, 110, 165, 0.9);
}

body.hsc .section--10, body.hsc .section--11 {
  background-color: #fff;
}

body.hsc .visitors-list a .color-pink {
  color: #E85298;
}

body.htc.school-home .head__wrap:not(.other-color), body.htc.school-home body.lp.opencampus.parent-group .section__04 .head__wrap:not(.other-color), body.lp.opencampus.parent-group .section__04 body.htc.school-home .head__wrap:not(.other-color) {
  color: #FF962C;
}

body.htc.school-home .hero {
  background-color: #FFF1DB;
}

body.htc.school-home .simple-link-nav {
  background-color: #FFF1DB;
}

body.htc.school-home .p-open-img:after, body.htc.school-home .p-open-img:before,
body.htc.school-home .p-open-img .line {
  background-color: #FF962C;
}

body.htc .color-orange {
  color: #FA7402;
}

body.htc .section--03, body.htc .section--05 {
  background: none;
  background-color: #FFF1DB;
}

body.htc .p-target-menu {
  background-color: #FF962C;
}

body.htc .p-target-menu a:hover {
  color: #FF962C;
}

body.htc .p-target-menu a:hover:after {
  background-color: #FF962C;
}

body.htc .p-target-menu a:hover:before {
  border-color: #FF962C;
}

body.htc .p-instagram .head__wrap, body.htc .p-instagram body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.htc .p-instagram .head__wrap, body.lp.opencampus.htc .p-instagram .section__03 .head__wrap, body.lp.opencampus.htc .section__03 .p-instagram .head__wrap, body.htc .p-instagram body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.htc .p-instagram .head__wrap, body.htc .p-instagram body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.htc .p-instagram .head__wrap {
  color: #FA7402;
}

body.htc .p-access {
  background: #fff url(../img/pages/htc/bg_circle01.png) no-repeat;
  background-size: 1105px 1077px;
  background-position: top center;
}

body.htc .p-access .head__wrap, body.htc .p-access body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.htc .p-access .head__wrap, body.lp.opencampus.htc .p-access .section__03 .head__wrap, body.lp.opencampus.htc .section__03 .p-access .head__wrap, body.htc .p-access body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.htc .p-access .head__wrap, body.htc .p-access body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.htc .p-access .head__wrap {
  color: #FF962C;
}

body.htc .p-access .p-gradient {
  font-weight: bold;
  font-size: 1.875rem;
}

body.htc .p-access .p-gradient .gradient__inner {
  position: relative;
  padding: 0.3rem 2rem;
  color: white;
}

body.htc .p-access .p-gradient .gradient__inner:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, #00BAB4 0%, #92D514 100%);
}

body.htc .p-access .p-gradient .gradient__text {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.htc .p-access .p-gradient.is_scrolled .gradient__inner:before {
  width: 100%;
  transition: width .5s;
}

body.htc .p-access .p-gradient.is_scrolled .gradient__text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 1s ease .3s;
}

body.htc .section--04 {
  background-color: #fff;
}

body.htc .section--05 > .container {
  max-width: 1000px !important;
}

body.htc .section--06 {
  background-color: #fff;
  color: #333;
  overflow: hidden;
}

body.htc .section--06 .head__wrap, body.htc .section--06 body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.htc .section--06 .head__wrap, body.lp.opencampus.htc .section--06 .section__03 .head__wrap, body.lp.opencampus.htc .section__03 .section--06 .head__wrap, body.htc .section--06 body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.htc .section--06 .head__wrap, body.htc .section--06 body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.htc .section--06 .head__wrap {
  color: #FA7402;
}

body.htc .section--06 .course__title {
  font-size: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  border-bottom: 6px solid #333;
  margin-bottom: 35px;
  font-weight: 700;
}

body.htc .section--06 .course__title.license {
  font-size: 45px;
}

body.htc .section--06 .course__title.license span {
  font-size: 35px;
}

body.htc .section--06 .course__text {
  font-size: 28px;
}

body.htc .section--06 .elm {
  max-width: 930px;
  margin-inline: auto;
}

body.htc .section--06 .elm__card-wrap {
  gap: 30px;
}

body.htc .section--06 .elm__card-item {
  width: calc(50% - 15px);
}

body.htc .section--06 .elm__card-item img {
  width: 100%;
  -webkit-filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.25));
}

body.htc .section--06 .course__lower {
  padding-top: 50px;
}

body.htc .section--06 .course__item__link {
  background-color: #333;
  display: flex;
  align-items: center;
  color: #333;
  transition: .2s;
  text-decoration: none;
  position: relative;
  margin-bottom: 0.5rem;
  padding: .5rem 1.8rem .5rem .5rem;
}

body.htc .section--06 .course__item__link:hover {
  opacity: .7;
}

body.htc .section--06 .course__item__link:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 14px;
  bottom: 0;
  margin: auto;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 10px;
  height: 10px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

body.htc .section--06 .course__item__link:before {
  content: '';
  display: block;
  position: absolute;
  right: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
  width: 15px;
  height: 2px;
}

body.htc .p-accreditation .item .title {
  color: #FA7402;
}

body.htc .section--07 {
  background: repeating-linear-gradient(-45deg, #FEF6EE, #FEF6EE 13px, #fff 0, #fff 26px);
}

body.htc .p-campus .p-gradient {
  font-weight: bold;
  font-size: 1.875rem;
}

body.htc .p-campus .p-gradient .gradient__inner {
  position: relative;
  padding: 0.3rem 2rem;
  color: white;
}

body.htc .p-campus .p-gradient .gradient__inner:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, #00BAB4 0%, #92D514 100%);
}

body.htc .p-campus .p-gradient .gradient__text {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.htc .p-campus .p-gradient.is_scrolled .gradient__inner:before {
  width: 100%;
  transition: width .5s;
}

body.htc .p-campus .p-gradient.is_scrolled .gradient__text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 1s ease .3s;
}

body.htc .section--08 {
  background: #fff url(../img/pages/bg01.png);
}

body.htc .section--08 .title {
  background: rgba(255, 150, 44, 0.9);
}

body.htc .section--09, body.htc .section--10, body.htc .section--11 {
  background-color: #fff;
}

body.iryo.school-home .head__wrap:not(.other-color), body.iryo.school-home body.lp.opencampus.parent-group .section__04 .head__wrap:not(.other-color), body.lp.opencampus.parent-group .section__04 body.iryo.school-home .head__wrap:not(.other-color) {
  color: #43AA88;
}

body.iryo.school-home .hero {
  background-color: #E6F8DA;
}

body.iryo.school-home .simple-link-nav {
  background-color: #E6F8DA;
}

body.iryo.school-home .section--03, body.iryo.school-home .section--05 {
  background: none;
  background-color: #E6F8DA;
}

body.iryo.school-home .section--05 > .container {
  max-width: 1000px !important;
}

body.iryo.school-home .section--06 {
  background-color: #fff;
  overflow: hidden;
}

body.iryo.school-home .section--06 .head__wrap, body.iryo.school-home .section--06 body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.iryo.school-home .section--06 .head__wrap {
  margin-bottom: 2.5rem;
  color: #43AA88;
}

body.iryo.school-home .section--06 .head__wrap h2::after, body.iryo.school-home .section--06 body.lp.opencampus.parent-group .section__04 .head__wrap h2::after, body.lp.opencampus.parent-group .section__04 body.iryo.school-home .section--06 .head__wrap h2::after {
  background-color: #43AA88;
}

body.iryo.school-home .section--06 .course__item {
  margin-bottom: 1rem;
}

body.iryo.school-home .section--06 .inner {
  position: relative;
  font-weight: bold;
}

body.iryo.school-home .elm {
  max-width: 1000px;
  margin-inline: auto;
}

body.iryo.school-home .elm__card-item {
  width: calc(50% - 30px);
}

body.iryo.school-home .elm__card-item img {
  width: 100%;
  -webkit-filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.25));
}

body.iryo.school-home .elm__card-wrap {
  gap: 40px 60px;
}

body.iryo.school-home .p-open-img:after, body.iryo.school-home .p-open-img:before,
body.iryo.school-home .p-open-img .line {
  background-color: #82C436;
}

body.iryo .p-target-menu {
  background-color: #82C436;
}

body.iryo .p-target-menu a:hover {
  color: #82C436;
}

body.iryo .p-target-menu a:hover:after {
  background-color: #82C436;
}

body.iryo .p-target-menu a:hover:before {
  border-color: #82C436;
}

body.iryo .p-instagram {
  background-color: #82C436;
  color: white;
}

body.iryo .p-access {
  background: #fff url(../img/pages/iryo/bg_circle01.png) no-repeat;
  background-size: 1105px 1077px;
  background-position: top center;
}

body.iryo .p-access .head__wrap, body.iryo .p-access body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.iryo .p-access .head__wrap, body.lp.opencampus.iryo .p-access .section__03 .head__wrap, body.lp.opencampus.iryo .section__03 .p-access .head__wrap, body.iryo .p-access body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.iryo .p-access .head__wrap, body.iryo .p-access body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.iryo .p-access .head__wrap {
  color: #82C436;
}

body.iryo .p-access .p-gradient {
  font-weight: bold;
  font-size: 1.875rem;
}

body.iryo .p-access .p-gradient .gradient__inner {
  position: relative;
  padding: 0.3rem 2rem;
  color: white;
}

body.iryo .p-access .p-gradient .gradient__inner:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, #FFA3A7 0%, #62B7FF 100%);
}

body.iryo .p-access .p-gradient .gradient__text {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.iryo .p-access .p-gradient.is_scrolled .gradient__inner:before {
  width: 100%;
  transition: width .5s;
}

body.iryo .p-access .p-gradient.is_scrolled .gradient__text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 1s ease .3s;
}

body.iryo .section--04 {
  background-color: #fff;
}

body.iryo .section--07 {
  background: repeating-linear-gradient(-45deg, #EDF7F8, #EDF7F8 13px, #fff 0, #fff 26px);
}

body.iryo .p-campus .p-gradient {
  font-weight: bold;
  font-size: 1.875rem;
}

body.iryo .p-campus .p-gradient .gradient__inner {
  position: relative;
  padding: 0.3rem 2rem;
  color: white;
}

body.iryo .p-campus .p-gradient .gradient__inner:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, #FFA3A7 0%, #62B7FF 100%);
}

body.iryo .p-campus .p-gradient .gradient__text {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.iryo .p-campus .p-gradient.is_scrolled .gradient__inner:before {
  width: 100%;
  transition: width .5s;
}

body.iryo .p-campus .p-gradient.is_scrolled .gradient__text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 1s ease .3s;
}

body.iryo .section--08 {
  background: #fff url(../img/pages/bg01.png);
}

body.iryo .section--08 .title {
  background: rgba(130, 196, 54, 0.9);
}

body.iryo .section--09, body.iryo .section--10, body.iryo .section--11, body.iryo .section--12 {
  background-color: #fff;
}

body.iryo .visitors-list a .color-pink {
  color: #43AA88;
}

body.iryo .section--12 {
  color: #333;
}

body.iryo .section--12 .head__wrap, body.iryo .section--12 body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.iryo .section--12 .head__wrap, body.lp.opencampus.iryo .section--12 .section__03 .head__wrap, body.lp.opencampus.iryo .section__03 .section--12 .head__wrap, body.iryo .section--12 body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.iryo .section--12 .head__wrap, body.iryo .section--12 body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.iryo .section--12 .head__wrap {
  color: #43AA88;
}

body.common main {
  margin-bottom: 10rem;
}

body.common.search .head__wrap, body.common.search body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.common.search .head__wrap, body.common.search body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.common.search .head__wrap, body.common.search body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.common.search .head__wrap {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3.125rem;
}

body.common.search .section__head {
  font-weight: bold;
  font-size: 1.5625rem;
  position: relative;
}

body.common.search .section__head:before, body.common.search .section__head:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 230px;
  height: 7px;
}

body.common.search .section__head:before {
  left: calc(100% + 35px);
  background: url(../img/common/img_page_search_item08.svg) no-repeat;
  background-size: cover;
}

body.common.search .section__head:after {
  right: calc(100% + 35px);
  background: url(../img/common/img_page_search_item08.svg) no-repeat;
  background-size: cover;
}

body.common.search .job-lists__item {
  margin-bottom: 2.5rem;
}

body.common.search .job-lists {
  margin-bottom: 3rem;
}

body.common.search .section {
  padding: 6rem 0;
}

body.common.search .section .p-btn a {
  font-size: 0.625rem;
}

body.common.search .section .p-btn a .txt {
  color: #212224 !important;
  min-width: 160px;
}

body.common.search .section .job__thumb {
  width: 100%;
}

body.common.search .section .job__name {
  font-size: 1.125rem;
  font-weight: bold;
}

body.common.search .section .job__description {
  font-size: 0.875rem;
}

body.common.search .section.section__biyo {
  background-color: #FEF4F9;
}

body.common.search .section.section__biyo .section__head,
body.common.search .section.section__biyo .job__name {
  color: #F172AC;
}

body.common.search .section.section__biyo .job__thumb {
  border-bottom: 2px solid #F172AC;
}

body.common.search .section.section__food {
  background-color: #FFF4EB;
}

body.common.search .section.section__food .section__head,
body.common.search .section.section__food .job__name {
  color: #FF7603;
}

body.common.search .section.section__food .job__thumb {
  border-bottom: 2px solid #FF7603;
}

body.common.search .section.section__fukushi {
  background-color: #EDF7FB;
}

body.common.search .section.section__fukushi .section__head,
body.common.search .section.section__fukushi .job__name {
  color: #1A9CCC;
}

body.common.search .section.section__fukushi .job__thumb {
  border-bottom: 2px solid #1A9CCC;
}

body.common.search .section.section__hoiku {
  background-color: #FDF6F6;
}

body.common.search .section.section__hoiku .section__head,
body.common.search .section.section__hoiku .job__name {
  color: #E48E8F;
}

body.common.search .section.section__hoiku .job__thumb {
  border-bottom: 2px solid #E48E8F;
}

body.common.search .section.section__iryo {
  background-color: #F0F8F6;
}

body.common.search .section.section__iryo .section__head,
body.common.search .section.section__iryo .job__name {
  color: #43AA88;
}

body.common.search .section.section__iryo .job__thumb {
  border-bottom: 2px solid #43AA88;
}

body.common.entrance .section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

body.common.entrance .small__head {
  position: relative;
  text-align: center;
  font-size: 1.375rem;
  font-weight: bold;
  margin-bottom: 2.8125rem;
}

body.common.entrance .small__head:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 15px;
  width: 100%;
  display: block;
  background-color: #BCD6EF;
  z-index: -1;
}

body.common.entrance .point {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

body.common.entrance .section__employment {
  position: relative;
}

body.common.entrance .section__employment:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  background: url(../img/common/img_page_entrance_bg01.jpg) no-repeat;
  height: 100%;
  z-index: -1;
}

body.common.entrance .section__employment .employment__wrap {
  color: white;
}

body.common.entrance .section__employment .employment__wrap .link-item img {
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.26);
}

body.common.entrance .section__employment .achievement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body.common.entrance .section__employment .achievement .achievement__head {
  position: relative;
  padding: 0 1rem;
  font-size: 1.875rem;
}

body.common.entrance .section__employment .achievement .achievement__head:after, body.common.entrance .section__employment .achievement .achievement__head:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 3px;
  height: 1.2em;
  background: white;
}

body.common.entrance .section__employment .achievement .achievement__head:before {
  left: 0;
  -webkit-transform: skew(25deg);
  transform: skew(25deg);
}

body.common.entrance .section__employment .achievement .achievement__head:after {
  right: 0;
  -webkit-transform: skew(-25deg);
  transform: skew(-25deg);
}

body.common.entrance .section__employment .achievement .achievement__content {
  font-size: 6.8125rem;
  line-height: 1;
  font-weight: bold;
}

body.common.entrance .section__employment .achievement .achievement__content__per {
  font-size: 4.4375rem;
}

body.common.entrance .section__dormitory {
  background: repeating-linear-gradient(-45deg, #def2fb, #def2fb 7px, #eef8fd 0, #eef8fd 14px);
  padding-bottom: 8rem;
}

body.common.entrance .section__inquiry {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 9rem;
}

body.common.entrance .section__inquiry:before, body.common.entrance .section__inquiry:after {
  content: '';
  position: absolute;
  left: 0;
  display: block;
  width: 100vw;
  height: 35px;
  background: repeating-linear-gradient(55deg, #C5E6CC, #C5E6CC 10px, #fff 0, #fff 20px, #E6C5DA 0, #E6C5DA 30px, #fff 0, #fff 40px);
}

body.common.entrance .section__inquiry:before {
  top: 0;
}

body.common.entrance .section__inquiry:after {
  bottom: 0;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item {
  margin-top: 4.375rem;
}

body.common.entrance .section__inquiry .entrance__inquiry .icon-tel {
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner {
  position: relative;
  border: 1px solid #1A1311;
  padding: 4rem .5rem 3rem;
  height: 100%;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner:before, body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner:after {
  content: '';
  display: block;
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner:before {
  left: -3px;
  top: -3px;
  border-left: 5px solid #1A1311;
  border-top: 5px solid #1A1311;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner:after {
  right: -3px;
  top: -3px;
  border-right: 5px solid #1A1311;
  border-top: 5px solid #1A1311;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner-second {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner-second:before, body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner-second:after {
  content: '';
  display: block;
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner-second:before {
  left: -3px;
  bottom: -3px;
  border-left: 5px solid #1A1311;
  border-bottom: 5px solid #1A1311;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__item__inner-second:after {
  right: -3px;
  bottom: -3px;
  border-right: 5px solid #1A1311;
  border-bottom: 5px solid #1A1311;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__icon {
  position: absolute;
  top: 0;
  left: 50%;
  margin: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  display: inline-block;
  padding: 0 2rem;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__head {
  margin-bottom: 2.1875rem;
}

body.common.entrance .section__inquiry .entrance__inquiry .inquiry__description {
  margin-bottom: 3rem;
  font-size: 0.75rem;
}

body.common.dormitory .section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

body.common.dormitory .dormitory__intro {
  font-size: 1.125rem;
  color: #1A9CCC;
  line-height: 2;
  margin-bottom: 5rem;
}

body.common.dormitory .dormitory__slide__item {
  text-align: center;
}

body.common.dormitory .dormitory__slide__item img {
  max-height: 383px;
  margin-left: auto;
  margin-right: auto;
}

body.common.dormitory .page__links {
  margin: 5.625rem 0 2.1875rem;
}

body.common.dormitory .section__dormitory .head__wrap, body.common.dormitory .section__dormitory body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.common.dormitory .section__dormitory .head__wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6.25rem;
}

body.common.dormitory .section__dormitory .head__item {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: none;
}

body.common.dormitory .section__dormitory .head__item.head__item--01 {
  left: 0;
}

body.common.dormitory .section__dormitory .head__item.head__item--02 {
  right: 0;
}

body.common.dormitory .section__mv {
  margin-bottom: 3.125rem;
}

body.common.dormitory .madori__lists {
  font-size: 0.8125rem;
}

body.common.dormitory .slick-dots li {
  margin: 0 1px;
}

body.common.dormitory .slick-dots li:before {
  content: none !important;
}

body.common.dormitory .slick-dots li button {
  position: relative;
}

body.common.dormitory .slick-dots li button:before {
  font-size: 0.9375rem;
  left: 0;
}

body.common.dormitory .slick-dots li.slick-active button:before {
  color: #FFEC00;
}

body.page-btm-link .page-contents {
  margin-bottom: 0px;
}

.link-page-btm {
  margin-top: 4rem;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 4.6875rem 0 6.25rem;
}

.link-page-btm:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  background-color: #11A2B4;
}

.link-page-btm.link-page-btm--01::before {
  background-color: #43aa88;
}

.link-page-btm.link-page-btm--02::before {
  background-color: #FA7402;
}

.link-page-btm.link-page-btm--03::before {
  background-color: #43966c;
}

.link-page-btm .link-page-btm--heading {
  margin-bottom: 1rem;
  padding: 0px 25px;
  position: relative;
  z-index: 1;
  font-size: 1.25rem;
  color: #fff;
  font-weight: bold;
}

.link-page-btm .link-page-btm--heading::before,
.link-page-btm .link-page-btm--heading::after {
  content: "";
  display: block;
  width: 6px;
  height: 1.2rem;
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  left: 6px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
}

.link-page-btm .link-page-btm--heading::after {
  left: auto;
  right: 6px;
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}

.link-page-btm .link-page-btm--btn {
  padding: 20px 0px;
  position: relative;
  z-index: 1;
}

.list-merit--wrap {
  padding: 2rem 50px;
  position: relative;
}

.list-merit--wrap::before,
.list-merit--wrap::after {
  content: "";
  display: block;
  position: absolute;
  top: 25%;
  margin-top: -15px;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.list-merit--wrap::before {
  left: 0px;
  width: 55px;
  height: 36px;
}

.list-merit--wrap::after {
  right: 0px;
  width: 60px;
  height: 42px;
}

body.child-page .list-merit--wrap::before {
  background-image: url("../img/pages/hsc/deco-jobpractical-merit-left.svg");
}

body.child-page .list-merit--wrap::after {
  background-image: url("../img/pages/hsc/deco-jobpractical-merit-right.svg");
}

main {
  margin-top: 80px;
}

.no-event {
  pointer-events: none;
  text-decoration: none !important;
}

.hov:hover {
  opacity: 0.8 !important;
}

.none-deco:hover {
  text-decoration: none;
}

.d-xl--none {
  display: none;
}

.f-century {
  font-family: century-gothic, sans-serif;
  font-style: italic;
  font-weight: 700;
}

.f-noto {
  font-family: "notosansJP";
}

.f-notojp {
  font-family: 'Noto Sans JP';
}

.fs-normal {
  font-style: normal;
}

.lh-1 {
  line-height: 1;
}

.text-teal {
  color: #47B2B0;
}

.yellow-marker {
  background: linear-gradient(transparent 70%, #FFEC00 70%);
}

.f-10 {
  font-size: 0.625rem;
}

.f-12 {
  font-size: 0.75rem !important;
}

.f-13 {
  font-size: 0.8125rem !important;
}

.f-14 {
  font-size: 0.875rem;
}

.f-16 {
  font-size: 1rem;
}

.f-18 {
  font-size: 1.125rem;
}

.f-20 {
  font-size: 1.25rem;
}

.f-23 {
  font-size: 1.4375rem;
}

.f-24 {
  font-size: 1.5rem;
}

.pb-6 {
  padding-bottom: 4rem;
}

.pb-7 {
  padding-bottom: 4.5rem;
}

.pb-8 {
  padding-bottom: 5rem;
}

.mt-4h {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 4.5rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.mt-9 {
  margin-top: 5.5rem !important;
}

.mt-10 {
  margin-top: 6rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-4h {
  margin-bottom: 2rem !important;
}

.mb-7 {
  margin-bottom: 4.5rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.mb-9 {
  margin-bottom: 5.5rem !important;
}

.mb-10 {
  margin-bottom: 6rem !important;
}

.text-hsc {
  color: #E85298 !important;
}

.text-htc {
  color: #FF7603 !important;
}

.text-iryo {
  color: #43AA88 !important;
}

.text-teal {
  color: #47B2B0 !important;
}

.d-shadow {
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15));
}

.font-weibhg-bolder {
  font-weight: 800 !important;
}

.fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.wrapper {
  overflow-x: hidden;
}

ul,
ol {
  padding-left: 1.5rem;
}

.container .container {
  padding-right: 0;
  padding-left: 0;
}

.slick-slider {
  display: none;
  opacity: 0;
}

.slick-slider.slick-initialized {
  display: block;
  opacity: 1;
  transition: .2s;
}

.y-maker {
  background: linear-gradient(transparent 60%, #ff6 60%);
}

body .inner__container {
  max-width: 1020px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}

body.noscroll {
  overflow: hidden;
}

body .p-breadcrumb {
  background-color: initial;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 0;
}

body .p-breadcrumb a {
  color: #007bff;
}

body .p-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: ">";
}

body .p-breadcrumb:nth-of-type(2) {
  border-top: 1px dashed #007bff;
  border-radius: 0;
  padding-bottom: 1rem;
}

body .p-breadcrumb:not(:nth-of-type(2)) {
  padding-bottom: 0;
  padding-bottom: 0.75rem;
}

body .p-youtube__wrap {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

body .p-youtube__wrap iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

body .p-btn {
  text-align: center;
}

body .p-btn a {
  text-decoration: none;
  position: relative;
  z-index: 20;
  display: inline-flex;
}

body .p-btn a:after, body .p-btn a:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 10;
  transition: .2s;
}

body .p-btn a:after {
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 3px;
  background-color: #212224;
}

body .p-btn a:before {
  right: 12px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg) translate(0px, 1px);
  transform: rotate(45deg) translate(0px, 1px);
  border-top: 3px solid #212224;
  border-right: 3px solid #212224;
}

body .p-btn a span {
  display: inline-block;
  position: relative;
  z-index: 10;
}

body .p-btn a span:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #212224;
  transition: .3s;
}

body .p-btn a span:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: calc(100% - 4px);
  width: 0;
  background-color: white;
  transition: .3s;
}

body .p-btn a .txt {
  color: #212224;
  font-weight: bold;
  min-width: 220px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: .7rem 2rem .7rem 1rem;
  background: #fff;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #212224;
}

body .p-btn a .chs {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -1;
  height: 0;
}

body .p-btn a .ch-head {
  display: block;
  opacity: 0;
  transition: .15s;
  visibility: hidden;
}

body .p-btn a .ch-head.ch-head01 {
  -webkit-transform: translate(40px, 80%);
  transform: translate(40px, 80%);
}

body .p-btn a .ch-head.ch-head02 {
  position: relative;
  z-index: 10;
  -webkit-transform: translate(0, 80%);
  transform: translate(0, 80%);
}

body .p-btn a .ch-head.ch-head03 {
  -webkit-transform: translate(-40px, 80%);
  transform: translate(-40px, 80%);
}

body .p-btn a:hover:after {
  right: 3px;
}

body .p-btn a:hover:before {
  right: 1px;
}

body .p-btn a:hover .ch-head {
  opacity: 1;
  visibility: visible;
}

body .p-btn a:hover .ch-head.ch-head01 {
  -webkit-transform: translate(15px, 0);
  transform: translate(15px, 0);
}

@-moz-document url-prefix() {
  body .p-btn a:hover .ch-head.ch-head01 {
    transform: translate(15px, 0);
  }
}

body .p-btn a:hover .ch-head.ch-head02 {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

@-moz-document url-prefix() {
  body .p-btn a:hover .ch-head.ch-head02 {
    transform: translate(0, 1px);
  }
}

body .p-btn a:hover .ch-head.ch-head03 {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
}

@-moz-document url-prefix() {
  body .p-btn a:hover .ch-head.ch-head03 {
    transform: translate(-15px, 1px);
  }
}

body .p-btn a:hover span:after {
  transition: .2s ease-in-out .1s;
  opacity: 0;
  -webkit-transform: translateX(70px) rotate(2turn);
  transform: translateX(70px) rotate(2turn);
}

body .p-btn a:hover span:before {
  transition: .2s ease-in-out .1s;
  width: 20px;
}

body .p-btn.not-arrow a .txt {
  padding: 0.7rem 1rem;
}

body .p-btn.not-arrow a:after, body .p-btn.not-arrow a:before {
  content: none;
}

body .p-btn.p-btn--lower a {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.15);
}

body .p-btn.p-btn--lower a .txt {
  border-width: 2px;
  border-color: white;
}

body .p-btn.p-btn--lower a:after, body .p-btn.p-btn--lower a:before {
  right: 10px;
}

body .p-btn.p-btn--lower a:after {
  height: 1px;
}

body .p-btn.p-btn--lower a:before {
  border-width: 1px;
}

body .p-btn.p-btn--lower a:hover:after, body .p-btn.p-btn--lower a:hover:before {
  right: 6px;
}

body .p-btn.p-btn--lower a .txt:not(.not) {
  min-width: 220px;
}

body .p-btn.p-btn--lower.p-btn--back a .txt {
  padding: .7rem 1rem .7rem 2rem;
}

body .p-btn.p-btn--lower.p-btn--back a:after {
  right: auto;
  left: 9px;
}

body .p-btn.p-btn--lower.p-btn--back a:before {
  right: auto;
  left: 8px;
  top: -1px;
  -webkit-transform: rotate(-135deg) translate(-1px, 1px);
  transform: rotate(-135deg) translate(-1px, 1px);
}

body .p-btn.p-btn--lower.p-btn--dark a:before {
  border-width: 1px;
}

body .p-btn.p-btn--lower.p-btn--school a .txt {
  color: white;
  background-color: #F4CB35;
}

body .p-btn.p-btn--lower.p-btn--school a:after {
  background-color: white;
}

body .p-btn.p-btn--lower.p-btn--school a:after, body .p-btn.p-btn--lower.p-btn--school a:before {
  border-color: white;
}

body .p-btn.p-btn--lower.p-btn--lg a .txt {
  padding: 1.2rem 2rem 1.2rem 1rem;
  min-width: 250px;
}

body .p-btn.p-btn--gradient a .txt {
  border-color: white;
  color: white;
  background: linear-gradient(135deg, #ec88de 0%, #82e8f5 100%);
}

body .p-btn.p-btn--gradient a:before {
  border-color: #fff;
}

body .p-btn.p-btn--gradient a:after {
  background-color: #fff;
}

body .p-btn.p-btn--gradient a span:after {
  background-color: #fff;
}

body .p-btn.p-btn--dark a .txt {
  color: white;
  border-color: white;
  background-color: #333;
}

body .p-btn.p-btn--dark a:after {
  background-color: white;
}

body .p-btn.p-btn--dark a:before {
  border-top: 3px solid white;
  border-right: 3px solid white;
}

body .p-btn.p-btn--dark a span:after {
  background-color: white;
}

body .p-btn.p-btn--dark a span:before {
  background-color: #333;
}

body.opencampus .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.opencampus .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.opencampus .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.opencampus .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.opencampus .p-form-second ::placeholder {
  color: #adb5bd;
}

body.opencampus .p-form-second .p-next-btn__wrap button,
body.opencampus .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.opencampus .p-form-second .privacy {
  text-align: center;
}

body.opencampus .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.opencampus .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.opencampus .p-form-second form {
  font-size: 1rem;
}

body.opencampus .p-form-second form button {
  min-width: 190px;
}

body.opencampus .p-form-second form dl dt,
body.opencampus .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.opencampus .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.opencampus .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #47B2B0;
}

body.opencampus .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.opencampus .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.opencampus .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.opencampus .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.opencampus .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.opencampus .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.opencampus .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #47B2B0;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.opencampus .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.opencampus .p-form-second form dl dd.radio label input:checked + span {
  color: #47B2B0;
}

body.opencampus .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.opencampus .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.opencampus .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.opencampus .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.opencampus .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.opencampus .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #47B2B0;
  border-bottom: 3px solid #47B2B0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.opencampus .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.opencampus .p-form-second form dl dd.checkbox label input:checked + span {
  color: #47B2B0;
}

body.opencampus .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@-webkit-keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.opencampus .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.opencampus .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.opencampus .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.opencampus .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.opencampus .p-form-second form dl input#address {
  width: 100%;
}

body.opencampus .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.opencampus .p-form-second form dl textarea,
body.opencampus .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.opencampus .p-form-second form dl textarea:focus,
body.opencampus .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.opencampus .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.opencampus .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.opencampus .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.opencampus .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.opencampus .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.opencampus .p-form-second form dl label input:focus,
body.opencampus .p-form-second form dl label textarea:focus,
body.opencampus .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.opencampus .p-form-second form dl textarea {
  width: 100%;
}

body.opencampus .p-oc-card .content {
  transition: height .6s ease-in-out;
  overflow: hidden;
  position: relative;
}

body.opencampus .p-oc-card .content.is_show {
  display: block !important;
  -webkit-animation: tab-show .5s linear 0s;
  animation: tab-show .5s linear 0s;
}

@keyframes tab-show {
  from {
    opacity: 0;
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.opencampus .p-oc-card .content .oc__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 1.5rem;
  position: relative;
  z-index: 20;
}

body.opencampus .p-oc-card .content .oc__article {
  height: 100%;
  position: relative;
}

body.opencampus .p-oc-card .content .oc__title {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

body.opencampus .p-oc-card .content .oc-time__head {
  margin-right: 2rem;
}

body.opencampus .p-oc-card .content li {
  position: relative;
  margin-bottom: 61px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

body.opencampus .p-oc-card .content li a {
  height: 100%;
  width: 100%;
  display: block;
  color: #212224;
  text-decoration: none;
  cursor: pointer;
  transition: .2s;
}

body.opencampus .p-oc-card .content li a:hover {
  opacity: .7;
}

body.opencampus .p-oc-card .content time {
  display: flex;
  color: white;
  text-align: center;
  align-items: flex-end;
}

body.opencampus .p-oc-card .content time .year {
  font-size: 0.9375rem;
  line-height: 1;
}

body.opencampus .p-oc-card .content time .date {
  font-size: 1.625rem;
  line-height: 1;
}

body.opencampus .p-oc-card .content .thumb {
  position: relative;
  padding-top: 62.5%;
}

body.opencampus .p-oc-card .content .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

body.opencampus .p-oc-card .content .study-cat {
  min-width: 115px;
  background-color: #fff;
  font-weight: bold;
  font-size: 0.9375rem;
}

body.opencampus .p-oc-card .content figcaption {
  padding: 1rem;
  min-height: 180px;
}

body.opencampus .p-oc-card .content .oc-cat.this_closed .oc__article:after {
  content: 'お申し込みを\A締め切りました';
  white-space: pre;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-weight: bold;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.opencampus .p-oc-card .content .oc-cat--hsc .oc__info {
  background-color: #DB76A4;
}

body.opencampus .p-oc-card .content .oc-cat--hsc img {
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: #DB76A4;
}

body.opencampus .p-oc-card .content .oc-cat--hsc .study-cat,
body.opencampus .p-oc-card .content .oc-cat--hsc .oc__title,
body.opencampus .p-oc-card .content .oc-cat--hsc .oc-time__head {
  color: #DB76A4;
}

body.opencampus .p-oc-card .content .oc-cat--htc .oc__info {
  background-color: #fd7e14;
}

body.opencampus .p-oc-card .content .oc-cat--htc img {
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: #fd7e14;
}

body.opencampus .p-oc-card .content .oc-cat--htc .study-cat,
body.opencampus .p-oc-card .content .oc-cat--htc .oc__title,
body.opencampus .p-oc-card .content .oc-cat--htc .oc-time__head {
  color: #fd7e14;
}

body.opencampus .p-oc-card .content .oc-cat--iryo .oc__info {
  background-color: #43AA88;
}

body.opencampus .p-oc-card .content .oc-cat--iryo img {
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: #43AA88;
}

body.opencampus .p-oc-card .content .oc-cat--iryo .study-cat,
body.opencampus .p-oc-card .content .oc-cat--iryo .oc__title,
body.opencampus .p-oc-card .content .oc-cat--iryo .oc-time__head {
  color: #43AA88;
}

body.opencampus.parent-group .p-oc-card .content {
  margin-top: -5rem;
  margin-bottom: 80px;
}

body.opencampus .p-oc-card__modal {
  /* Animation */
}

@-webkit-keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes mmslideIn {
  from {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes mmslideIn {
  from {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
}

@keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
}

body.opencampus .p-oc-card__modal.micromodal-slide {
  display: none;
  position: relative;
  z-index: 10000;
}

body.opencampus .p-oc-card__modal.micromodal-slide.is-open {
  display: block;
}

body.opencampus .p-oc-card__modal.micromodal-slide[aria-hidden="false"] .modal__overlay {
  -webkit-animation: mmfadeIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  animation: mmfadeIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

body.opencampus .p-oc-card__modal.micromodal-slide[aria-hidden="false"] .modal__container {
  -webkit-animation: mmslideIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  animation: mmslideIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

body.opencampus .p-oc-card__modal.micromodal-slide[aria-hidden="true"] .modal__overlay {
  -webkit-animation: mmfadeOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  animation: mmfadeOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

body.opencampus .p-oc-card__modal.micromodal-slide[aria-hidden="true"] .modal__container {
  -webkit-animation: mmslideOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  animation: mmslideOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

body.opencampus .p-oc-card__modal.micromodal-slide .modal__container,
body.opencampus .p-oc-card__modal.micromodal-slide .modal__overlay {
  will-change: transform;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--hsc .modal__title {
  background-color: #E85298;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--hsc .oc-cat__label {
  background-color: #E85298;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--hsc .oc-detail {
  border-top: 1px solid #E85298;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--hsc .oc-detail__head {
  color: #E85298;
  border-bottom: 1px solid #E85298;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--hsc .oc-detail__body {
  border-bottom: 1px solid #E85298;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--htc .modal__title {
  background-color: #FF7603;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--htc .oc-cat__label {
  background-color: #FF7603;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--htc .oc-detail {
  border-top: 1px solid #FF7603;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--htc .oc-detail__head {
  color: #FF7603;
  border-bottom: 1px solid #FF7603;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--htc .oc-detail__body {
  border-bottom: 1px solid #FF7603;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--iryo .modal__title {
  background-color: #43AA88;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--iryo .oc-cat__label {
  background-color: #43AA88;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--iryo .oc-detail {
  border-top: 1px solid #43AA88;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--iryo .oc-detail__head {
  color: #43AA88;
  border-bottom: 1px solid #43AA88;
}

body.opencampus .p-oc-card__modal.modal-cat.modal-cat--iryo .oc-detail__body {
  border-bottom: 1px solid #43AA88;
}

body.opencampus .p-oc-card__modal .modal__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

body.opencampus .p-oc-card__modal .modal__container {
  max-height: 90vh;
  margin: auto 10px;
  overflow-y: auto;
  background-color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
  font-size: 0.875rem;
}

body.opencampus .p-oc-card__modal .modal__close {
  background-color: #333;
  color: white;
  border: none;
  padding: 0 10px;
  right: 10px;
  position: absolute;
  top: 10px;
}

body.opencampus .p-oc-card__modal .modal__close::before {
  content: "\2715";
  font-size: 1.375rem;
}

body.opencampus .p-oc-card__modal .modal__close__bottom {
  background-color: #333;
  text-align: center;
  padding: .5rem 2rem;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1rem;
}

body.opencampus .p-oc-card__modal .modal__close__bottom::after {
  content: "\2715";
  margin-left: 1rem;
  font-size: 1.25rem;
}

body.opencampus .p-oc-card__modal .modal__title {
  font-weight: bold;
  font-size: 1.25rem;
  background-color: #E85298;
  color: white;
  padding: 1rem;
}

body.opencampus .p-oc-card__modal .oc-cat {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  font-weight: bold;
}

body.opencampus .p-oc-card__modal .oc-cat .oc-cat__label {
  margin-right: 10px;
  margin-bottom: 8px;
  border-radius: 50px;
  color: white;
  padding: .5rem 1.5rem;
  text-align: center;
}

body.opencampus .p-oc-card__modal .modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  font-size: 1rem;
}

body.opencampus .p-oc-card__modal .modal__content .oc-thumb {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

body.opencampus .p-oc-card__modal .modal__content .oc-thumb .oc-thumb__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

body.opencampus .p-oc-card__modal .modal__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

body.opencampus .p-oc-card__modal .oc-detail .oc-detail__head,
body.opencampus .p-oc-card__modal .oc-detail .oc-detail__body {
  padding: 1rem;
  margin-bottom: 0;
}

body.opencampus .p-oc-card__modal .oc-detail .oc-detail__head {
  font-weight: bold;
  text-align: center;
}

body.opencampus .p-arrow a {
  display: inline-block;
  background-color: #212224;
  text-align: center;
  padding: .5rem;
  font-weight: bold;
  font-size: 1rem;
  max-width: 220px;
  width: 100%;
  position: relative;
  color: white;
  transition: .2s;
  text-decoration: none;
}

body.opencampus .p-arrow a:hover {
  opacity: .7;
}

body.opencampus .p-arrow a:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.opencampus .p-arrow a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 19px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 1px;
  background-color: white;
}

body.page .p-article .p-article__item {
  margin-bottom: 2rem;
}

body.page .p-article.p-article--01-no-break .p-article__item {
  width: calc((100% - 10%) / 3);
}

body.page .p-article.p-article--01-no-break .p-article__item:not(:nth-of-type(3n)):not(:last-of-type) {
  margin-right: 5%;
}

body.page .p-article.p-article--02-no-break .p-article__item {
  width: calc((70% - 5%) / 2);
}

body.page .p-article.p-article--02-no-break .p-article__item:not(:nth-of-type(even)):not(:last-of-type) {
  margin-right: 5%;
}

body.page .p-article .p-article__head {
  background: transparent;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1.125rem;
}

body.page .p-article .p-article__thumb {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

body.page .p-article .p-article__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

body.page .p-article .p-article__description {
  font-size: 0.875rem;
  margin-top: 0.625rem;
  line-height: 2;
}

body.page .p-article .p-article__head {
  margin-top: 0.625rem;
}

body.parent-group .p-important__txt {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 5px solid #F4CB35;
}

body.parent-group .p-important__txt p {
  margin-bottom: 0;
}

body.parent-group blockquote {
  padding-left: 2rem;
  border-left: 5px solid #F4CB35;
  margin-bottom: 1rem;
}

body.parent-group blockquote cite {
  display: block;
  text-align: right;
}

body.parent-group.page:not(.lp) .page-contents ul:not(.list-unstyled) {
  list-style: none;
  padding-left: 0;
}

body.parent-group.page:not(.lp) .page-contents ul:not(.list-unstyled) li {
  padding-left: .5rem;
}

body.parent-group.page:not(.lp) .page-contents ul:not(.list-unstyled) li:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background: #212224;
  margin-right: .5rem;
  margin-bottom: 2px;
}

body.parent-group.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--01:before {
  background: #7FADD8;
}

body.parent-group.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--02:before {
  background: #F48E8C;
}

body.parent-group.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--03:before {
  background: #8CBD97;
}

body.parent-group.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--04:before {
  background: #FF851C;
}

body.parent-group .p-definition__lists__dotted {
  display: flex;
  flex-wrap: wrap;
}

body.parent-group .p-definition__lists__dotted dt {
  width: 25%;
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  padding: 1rem .5em;
  font-weight: normal;
}

body.parent-group .p-definition__lists__dotted dd {
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  margin-left: 4px;
  padding: 1rem .5em;
  width: calc(75% - 4px);
}

body.parent-group .p-definition__site {
  display: flex;
  flex-wrap: wrap;
}

body.parent-group .p-definition__site dt {
  width: 25%;
  border-bottom: 1px solid #fff;
  padding: .5em;
  color: #fff;
  background: #F4CB35;
  font-weight: normal;
}

body.parent-group .p-definition__site dd {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-bottom: 0;
  padding: .5em;
  width: calc(75% - 3px);
  background-color: #fff;
}

body.parent-group .p-definition__site dt,
body.parent-group .p-definition__site dd {
  border-left: 1px solid #F4CB35;
}

body.parent-group .p-definition__site dt:last-of-type,
body.parent-group .p-definition__site dd:last-of-type {
  border-bottom: 1px solid #ccc;
}

body.parent-group.parent-group .p-definition__site dt {
  color: #212224;
}

body.parent-group table caption {
  caption-side: top;
  color: #212224;
}

body.parent-group table th,
body.parent-group table td {
  border: 1px solid #dee2e6;
  padding: .75rem;
}

body.parent-group table th {
  color: #fff;
  background-color: #F4CB35;
  font-weight: bold;
}

body.parent-group.parent-group table th {
  color: #212224;
}

body.parent-group .p-table-wrap {
  overflow: auto;
}

body.parent-group .p-table-wrap .table th,
body.parent-group .p-table-wrap .table td {
  min-width: 8em;
}

body.parent-group .p-table-wrap .table caption {
  caption-side: initial;
  color: #212224;
  font-weight: bold;
}

body.parent-group .p-table-wrap .table thead th {
  background: #F4CB35;
  color: #fff;
  border-bottom: 0;
}

body.parent-group .p-table-wrap .table tbody th {
  background: #F4CB35;
  color: #fff;
}

body.parent-group .p-table-wrap.p-calendar {
  text-align: center;
}

body.parent-group .p-table-wrap.p-calendar .table {
  min-width: unset;
}

body.parent-group .p-table-wrap.p-calendar .table tbody td:first-of-type {
  background-color: #e9ecef;
}

body.parent-group .p-table-wrap.p-calendar .table td,
body.parent-group .p-table-wrap.p-calendar .table th {
  min-width: unset;
}

body.parent-group.parent-group .p-table-wrap {
  overflow: auto;
}

body.parent-group.parent-group .p-table-wrap .table thead th {
  color: #212224;
}

body.parent-group.parent-group .p-table-wrap .table tbody th {
  color: #212224;
}

body.parent-group .p-form form button {
  min-width: 160px;
}

body.parent-group .p-form form dl {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #6c757d;
}

body.parent-group .p-form form dl dt,
body.parent-group .p-form form dl dd {
  padding: 1rem;
}

body.parent-group .p-form form dl dt {
  width: 20%;
  background: #dee2e6;
}

body.parent-group .p-form form dl dt span {
  color: #fff;
  background: #dc3545;
}

body.parent-group .p-form form dl dd {
  width: 80%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

body.parent-group .p-form form dl dd:not(:last-of-type) {
  border-bottom: 1px solid #6c757d;
}

body.parent-group .p-form form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.parent-group .p-form form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.parent-group .p-form form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.parent-group .p-form form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #F4CB35;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.parent-group .p-form form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.parent-group .p-form form dl dd.radio label input:checked + span {
  color: #F4CB35;
}

body.parent-group .p-form form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.parent-group .p-form form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.parent-group .p-form form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.parent-group .p-form form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.parent-group .p-form form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.parent-group .p-form form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #F4CB35;
  border-bottom: 3px solid #F4CB35;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.parent-group .p-form form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.parent-group .p-form form dl dd.checkbox label input:checked + span {
  color: #F4CB35;
}

body.parent-group .p-form form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.parent-group .p-form form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.parent-group .p-form form dl input {
  border-radius: 5px;
  border: 1px solid #6c757d;
  padding: 5px;
}

body.parent-group .p-form form dl input[type="radio"] {
  margin-right: 5px;
}

body.parent-group .p-form form dl input[type="email"] {
  width: 70%;
}

body.parent-group .p-form form dl input#address {
  width: 100%;
}

body.parent-group .p-form form dl input:focus {
  outline: 3px solid #ced4da;
}

body.parent-group .p-form form dl textarea,
body.parent-group .p-form form dl select {
  border-radius: 5px;
  border: 1px solid #6c757d;
}

body.parent-group .p-form form dl textarea:focus,
body.parent-group .p-form form dl select:focus {
  outline: 3px solid #ced4da;
}

body.parent-group .p-form form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  border-radius: 5px;
  padding-right: 30px;
  position: relative;
}

body.parent-group .p-form form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

body.parent-group .p-form form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
}

@supports (-ms-ime-align: auto) {
  body.parent-group .p-form form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.parent-group .p-form form dl label {
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0.5rem;
}

body.parent-group .p-form form dl label input:focus,
body.parent-group .p-form form dl label textarea:focus,
body.parent-group .p-form form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.parent-group .p-form form dl textarea {
  width: 100%;
}

body.parent-group .p-form__btn__wrap .p-back-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.parent-group .p-form__btn__wrap .p-back-btn__wrap button {
  color: #fff;
  background: #6c757d;
  padding-left: 30px;
  position: relative;
  transition: .2s;
}

body.parent-group .p-form__btn__wrap .p-back-btn__wrap button:hover {
  cursor: pointer;
  background: #60686f;
}

body.parent-group .p-form__btn__wrap .p-back-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 0 0 2px;
  border-color: white 0 0 white;
  border-style: solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.parent-group .p-form__btn__wrap .p-next-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.parent-group .p-form__btn__wrap .p-next-btn__wrap .submit-btn {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #6c757d;
}

body.parent-group .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  cursor: pointer;
  pointer-events: auto;
  background-color: #F4CB35;
}

body.parent-group .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #e9ba0d;
}

body.parent-group .p-form__btn__wrap .p-next-btn__wrap button {
  color: #fff;
  background: #F4CB35;
  padding-right: 30px;
  position: relative;
  transition: .2s;
}

body.parent-group .p-form__btn__wrap .p-next-btn__wrap button:hover {
  cursor: pointer;
  background: #f3c51d;
}

body.parent-group .p-form__btn__wrap .p-next-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 2px 0 0;
  border-color: white white 0 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.parent-group .p-accordion {
  font-size: 1rem;
  margin-bottom: 5rem;
}

body.parent-group .p-accordion > li {
  border-bottom: 1px solid #F4CB35;
}

body.parent-group .p-accordion > li:first-of-type {
  border-top: 1px solid #F4CB35;
}

body.parent-group .p-accordion .accBtn {
  padding: .5rem;
  background-color: transparent;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 1.4rem 20px;
  transition: .2s;
}

body.parent-group .p-accordion .accBtn:hover {
  opacity: .7;
}

body.parent-group .p-accordion .accBtn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: transparent #F4CB35 #F4CB35 transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

body.parent-group .p-accordion .accBtn.is_active {
  background-color: #F4CB35;
  color: #212224;
}

body.parent-group .p-accordion .accBtn.is_active:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-color: #212224;
}

body.parent-group .p-accordion .accContent {
  padding: 10px 20px;
  display: block;
  background: #fff;
  position: relative;
}

body.parent-group .p-accordion .accContent.is_hidden {
  display: none;
  height: 0 !important;
}

body.parent-group .p-pagination {
  padding-left: 0;
  justify-content: center;
  flex-wrap: wrap;
}

body.parent-group .p-pagination .page-item a {
  border: 1px solid #F4CB35;
  text-decoration: none;
  color: #212224;
}

body.parent-group .p-pagination .page-item a:hover {
  background: #F4CB35;
  color: #212224;
}

body.parent-group .p-pagination .page-item.active .page-link {
  background-color: #F4CB35;
  border-color: #F4CB35;
  color: #212224;
}

body.parent-group .p-pagination .move_btn__wrap {
  padding-left: 0;
  display: flex;
}

body.parent-group .p-pagination .move_btn__wrap ul {
  padding-left: 0;
  list-style: none;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn a {
  transition: .2s;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn a:hover {
  background-color: #f3c51d;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.first-page span, body.parent-group .p-pagination .move_btn__wrap .move_btn.last-page span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  height: 15px;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.first-page span:before, body.parent-group .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.first-page span:after, body.parent-group .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.first-page a {
  padding-left: 40px;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.first-page span {
  left: 10px;
  border-left: 2px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.first-page span:before {
  left: 0;
  border-right: 10px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.first-page span:after {
  left: 10px;
  border-right: 10px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.last-page a {
  padding-right: 40px;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.last-page span {
  right: 10px;
  border-right: 2px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  right: 0;
  border-left: 10px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  right: 10px;
  border-left: 10px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.prev-page a span, body.parent-group .p-pagination .move_btn__wrap .move_btn.next-page a span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  width: 0;
  height: 0;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.prev-page a {
  padding-left: 25px;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.prev-page a span {
  left: 10px;
  border-right: 8px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.next-page a {
  padding-right: 25px;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn.next-page span {
  right: 10px;
  border-left: 8px solid #212224;
}

body.parent-group .p-pagination .move_btn__wrap .move_btn a {
  padding: .4rem .5rem;
  background-color: #F4CB35;
  color: #212224;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
}

body.parent-group .p-link-block {
  max-width: 850px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

body.parent-group .p-link-block .p-link-block__item {
  margin-bottom: 2rem;
  width: 100%;
}

body.parent-group .p-link-block .p-link-block__link {
  transition: .2s;
  text-decoration: none;
  color: #212224;
  display: block;
}

body.parent-group .p-link-block .p-link-block__link:hover {
  opacity: .7;
}

body.parent-group .p-link-block .p-link-block__head {
  border: 1px solid #F4CB35;
  border-radius: 13px;
  padding: 1rem 2.5rem 1rem 1rem;
  position: relative;
  font-size: 1.125rem;
  color: #212224;
}

body.parent-group .p-link-block .p-link-block__head:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  border-bottom: 1px solid #F4CB35;
  border-right: 1px solid #F4CB35;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.parent-group .p-link-block .p-link-block__head:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 19px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 1px;
  background-color: #F4CB35;
}

body.parent-group .p-link-block .p-link-block__description {
  padding: .5rem;
  font-size: 0.875rem;
}

body.parent-group .p-more {
  position: relative;
  height: 20em;
  overflow: hidden;
}

body.parent-group .p-more.is_full-show {
  height: auto;
  overflow: visible;
}

body.parent-group .p-more .btn__wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

body.parent-group .p-more .btn__wrap.is_show {
  display: none;
}

body.parent-group .p-more .btn__wrap:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, white 100%);
  z-index: -1;
}

body.parent-group .p-more .more-btn {
  position: relative;
  border-radius: 100px;
  border: 1px solid #F4CB35;
  color: #212224;
  text-align: center;
  padding: .5rem 2rem;
  margin: auto;
  background-color: #fff;
  width: 240px;
  font-size: 1.25rem;
  display: block;
  transition: .2s;
}

body.parent-group .p-more .more-btn:hover {
  opacity: .7;
}

body.parent-group .p-more .more-btn.is_hidden {
  opacity: 0;
  visibility: hidden;
}

body.parent-group .p-more .more-btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 20px;
  background-color: #F4CB35;
}

body.parent-group .p-more .more-btn:before {
  content: '';
  display: block;
  position: absolute;
  right: 26px;
  bottom: 16px;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: 1px solid #F4CB35;
  border-right: 1px solid #F4CB35;
}

body.parent-group.page h1:not(.not) {
  font-size: 1.4375rem;
  background-color: #F4CB35;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #212224;
}

body.parent-group.page .h1 {
  font-size: 1.4375rem;
  background-color: #F4CB35;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #F4CB35;
  font-weight: bold;
  background: repeating-linear-gradient(-45deg, rgba(244, 203, 53, 0.2), rgba(244, 203, 53, 0.2) 4px, #fff 0, #fff 6px);
}

body.parent-group.page h2:not(.not) {
  font-size: 1.4375rem;
  padding-bottom: 1rem;
  background: linear-gradient(to right, #F4CB35 0, #F4CB35 30%, #E2E2E2 30%) no-repeat;
  background-size: 100% 6px;
  background-position: bottom;
  margin: 2.5rem 0 1.25rem;
}

body.parent-group.page h3:not(.not) {
  font-size: 1.3125rem;
  background-color: #F4CB35;
  padding: .4rem .4rem .4rem 1rem;
  margin: 1.875rem 0 1.25rem;
  color: #212224;
}

body.parent-group.page h4:not(.not) {
  font-size: 1.3125rem;
  padding-bottom: 0.2rem;
  margin: 1.875rem 0 1.25rem;
  border-bottom: 2px dotted #212224;
}

body.parent-group.page h5 {
  font-size: 1.25rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  border-left: 5px double #212224;
}

body.parent-group.page h6 {
  font-size: 1.125rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
}

body.parent-group.page h6:before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 3px;
  background-color: #212224;
  vertical-align: middle;
  margin-right: 0.3rem;
}

body.parent-group.page.page02 h1 {
  background: repeating-linear-gradient(-45deg, rgba(244, 203, 53, 0.2), rgba(244, 203, 53, 0.2) 4px, #fff 0, #fff 6px);
  color: #F4CB35;
  font-weight: 900;
}

body.parent-group.page .p-article .p-article__item {
  margin-bottom: 2rem;
}

body.parent-group.page .p-article.p-article--01-no-break .p-article__item {
  width: calc((100% - 10%) / 3);
}

body.parent-group.page .p-article.p-article--01-no-break .p-article__item:not(:nth-of-type(3n)):not(:last-of-type) {
  margin-right: 5%;
}

body.parent-group.page .p-article.p-article--02-no-break .p-article__item {
  width: calc((70% - 5%) / 2);
}

body.parent-group.page .p-article.p-article--02-no-break .p-article__item:not(:nth-of-type(even)):not(:last-of-type) {
  margin-right: 5%;
}

body.parent-group.page .p-article .p-article__head {
  background: transparent;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1.125rem;
}

body.parent-group.page .p-article .p-article__thumb {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

body.parent-group.page .p-article .p-article__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

body.parent-group.page .p-article .p-article__description {
  font-size: 0.875rem;
  margin-top: 0.625rem;
  line-height: 2;
}

body.parent-group.page .p-article .p-article__head {
  margin-top: 0.625rem;
}

body.parent-group.page .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.parent-group.page .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.parent-group.page .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.parent-group.page .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.parent-group.page .p-form-second ::placeholder {
  color: #adb5bd;
}

body.parent-group.page .p-form-second .p-next-btn__wrap button,
body.parent-group.page .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.parent-group.page .p-form-second .privacy {
  text-align: center;
}

body.parent-group.page .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.parent-group.page .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.parent-group.page .p-form-second form {
  font-size: 1rem;
}

body.parent-group.page .p-form-second form button {
  min-width: 190px;
}

body.parent-group.page .p-form-second form dl dt,
body.parent-group.page .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.parent-group.page .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.parent-group.page .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #F4CB35;
}

body.parent-group.page .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.parent-group.page .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.parent-group.page .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.parent-group.page .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.parent-group.page .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.parent-group.page .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.parent-group.page .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #F4CB35;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.parent-group.page .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.parent-group.page .p-form-second form dl dd.radio label input:checked + span {
  color: #F4CB35;
}

body.parent-group.page .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.parent-group.page .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.parent-group.page .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.parent-group.page .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.parent-group.page .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.parent-group.page .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #F4CB35;
  border-bottom: 3px solid #F4CB35;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.parent-group.page .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.parent-group.page .p-form-second form dl dd.checkbox label input:checked + span {
  color: #F4CB35;
}

body.parent-group.page .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.parent-group.page .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.parent-group.page .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.parent-group.page .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.parent-group.page .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.parent-group.page .p-form-second form dl input#address {
  width: 100%;
}

body.parent-group.page .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.parent-group.page .p-form-second form dl textarea,
body.parent-group.page .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.parent-group.page .p-form-second form dl textarea:focus,
body.parent-group.page .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.parent-group.page .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.parent-group.page .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.parent-group.page .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.parent-group.page .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.parent-group.page .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.parent-group.page .p-form-second form dl label input:focus,
body.parent-group.page .p-form-second form dl label textarea:focus,
body.parent-group.page .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.parent-group.page .p-form-second form dl textarea {
  width: 100%;
}

body.parent-group.page .p-btn {
  text-align: center;
}

body.parent-group.page .p-btn a {
  text-decoration: none;
  position: relative;
  z-index: 20;
  display: inline-flex;
}

body.parent-group.page .p-btn a:after, body.parent-group.page .p-btn a:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 10;
  transition: .2s;
}

body.parent-group.page .p-btn a:after {
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 3px;
  background-color: #212224;
}

body.parent-group.page .p-btn a:before {
  right: 12px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg) translate(0px, 1px);
  transform: rotate(45deg) translate(0px, 1px);
  border-top: 3px solid #212224;
  border-right: 3px solid #212224;
}

body.parent-group.page .p-btn a span {
  display: inline-block;
  position: relative;
  z-index: 10;
}

body.parent-group.page .p-btn a span:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #212224;
  transition: .3s;
}

body.parent-group.page .p-btn a span:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: calc(100% - 4px);
  width: 0;
  background-color: white;
  transition: .3s;
}

body.parent-group.page .p-btn a .txt {
  color: #212224;
  font-weight: bold;
  min-width: 220px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: .7rem 2rem .7rem 1rem;
  background: #fff;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #212224;
}

body.parent-group.page .p-btn a .chs {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -1;
  height: 0;
}

body.parent-group.page .p-btn a .ch-head {
  display: block;
  opacity: 0;
  transition: .15s;
  visibility: hidden;
}

body.parent-group.page .p-btn a .ch-head.ch-head01 {
  -webkit-transform: translate(40px, 80%);
  transform: translate(40px, 80%);
}

body.parent-group.page .p-btn a .ch-head.ch-head02 {
  position: relative;
  z-index: 10;
  -webkit-transform: translate(0, 80%);
  transform: translate(0, 80%);
}

body.parent-group.page .p-btn a .ch-head.ch-head03 {
  -webkit-transform: translate(-40px, 80%);
  transform: translate(-40px, 80%);
}

body.parent-group.page .p-btn a:hover:after {
  right: 3px;
}

body.parent-group.page .p-btn a:hover:before {
  right: 1px;
}

body.parent-group.page .p-btn a:hover .ch-head {
  opacity: 1;
  visibility: visible;
}

body.parent-group.page .p-btn a:hover .ch-head.ch-head01 {
  -webkit-transform: translate(15px, 0);
  transform: translate(15px, 0);
}

@-moz-document url-prefix() {
  body.parent-group.page .p-btn a:hover .ch-head.ch-head01 {
    transform: translate(15px, 0);
  }
}

body.parent-group.page .p-btn a:hover .ch-head.ch-head02 {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

@-moz-document url-prefix() {
  body.parent-group.page .p-btn a:hover .ch-head.ch-head02 {
    transform: translate(0, 1px);
  }
}

body.parent-group.page .p-btn a:hover .ch-head.ch-head03 {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
}

@-moz-document url-prefix() {
  body.parent-group.page .p-btn a:hover .ch-head.ch-head03 {
    transform: translate(-15px, 1px);
  }
}

body.parent-group.page .p-btn a:hover span:after {
  transition: .2s ease-in-out .1s;
  opacity: 0;
  -webkit-transform: translateX(70px) rotate(2turn);
  transform: translateX(70px) rotate(2turn);
}

body.parent-group.page .p-btn a:hover span:before {
  transition: .2s ease-in-out .1s;
  width: 20px;
}

body.parent-group.page .p-btn.not-arrow a .txt {
  padding: 0.7rem 1rem;
}

body.parent-group.page .p-btn.not-arrow a:after, body.parent-group.page .p-btn.not-arrow a:before {
  content: none;
}

body.parent-group.page .p-btn.p-btn--lower a {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.15);
}

body.parent-group.page .p-btn.p-btn--lower a .txt {
  border-width: 2px;
  border-color: white;
}

body.parent-group.page .p-btn.p-btn--lower a:after, body.parent-group.page .p-btn.p-btn--lower a:before {
  right: 10px;
}

body.parent-group.page .p-btn.p-btn--lower a:after {
  height: 1px;
}

body.parent-group.page .p-btn.p-btn--lower a:before {
  border-width: 1px;
}

body.parent-group.page .p-btn.p-btn--lower a:hover:after, body.parent-group.page .p-btn.p-btn--lower a:hover:before {
  right: 6px;
}

body.parent-group.page .p-btn.p-btn--lower a .txt:not(.not) {
  min-width: 220px;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--back a .txt {
  padding: .7rem 1rem .7rem 2rem;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--back a:after {
  right: auto;
  left: 9px;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--back a:before {
  right: auto;
  left: 8px;
  top: -1px;
  -webkit-transform: rotate(-135deg) translate(-1px, 1px);
  transform: rotate(-135deg) translate(-1px, 1px);
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--dark a:before {
  border-width: 1px;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--school a .txt {
  color: white;
  background-color: #F4CB35;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--school a:after {
  background-color: white;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--school a:after, body.parent-group.page .p-btn.p-btn--lower.p-btn--school a:before {
  border-color: white;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--lg a .txt {
  padding: 1.2rem 2rem 1.2rem 1rem;
  min-width: 250px;
}

body.parent-group.page .p-btn.p-btn--gradient a .txt {
  border-color: white;
  color: white;
  background: linear-gradient(135deg, #ec88de 0%, #82e8f5 100%);
}

body.parent-group.page .p-btn.p-btn--gradient a:before {
  border-color: #fff;
}

body.parent-group.page .p-btn.p-btn--gradient a:after {
  background-color: #fff;
}

body.parent-group.page .p-btn.p-btn--gradient a span:after {
  background-color: #fff;
}

body.parent-group.page .p-btn.p-btn--dark a .txt {
  color: white;
  border-color: white;
  background-color: #333;
}

body.parent-group.page .p-btn.p-btn--dark a:after {
  background-color: white;
}

body.parent-group.page .p-btn.p-btn--dark a:before {
  border-top: 3px solid white;
  border-right: 3px solid white;
}

body.parent-group.page .p-btn.p-btn--dark a span:after {
  background-color: white;
}

body.parent-group.page .p-btn.p-btn--dark a span:before {
  background-color: #333;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--school a .txt {
  color: #212224;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--school a:after {
  background-color: #212224;
}

body.parent-group.page .p-btn.p-btn--lower.p-btn--school a:after, body.parent-group.page .p-btn.p-btn--lower.p-btn--school a:before {
  border-color: #212224;
}

body.parent-group.page .p-form__btn__wrap .p-next-btn__wrap button.is_agree {
  color: #212224;
}

body.parent-group.page .p-form-second form dl dd.radio label input:checked + span {
  color: #212224;
}

body.hsc .p-important__txt {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 5px solid #E85298;
}

body.hsc .p-important__txt p {
  margin-bottom: 0;
}

body.hsc blockquote {
  padding-left: 2rem;
  border-left: 5px solid #E85298;
  margin-bottom: 1rem;
}

body.hsc blockquote cite {
  display: block;
  text-align: right;
}

body.hsc.page:not(.lp) .page-contents ul:not(.list-unstyled) {
  list-style: none;
  padding-left: 0;
}

body.hsc.page:not(.lp) .page-contents ul:not(.list-unstyled) li {
  padding-left: .5rem;
}

body.hsc.page:not(.lp) .page-contents ul:not(.list-unstyled) li:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background: #E85298;
  margin-right: .5rem;
  margin-bottom: 2px;
}

body.hsc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--01:before {
  background: #7FADD8;
}

body.hsc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--02:before {
  background: #F48E8C;
}

body.hsc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--03:before {
  background: #8CBD97;
}

body.hsc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--04:before {
  background: #FF851C;
}

body.hsc .p-definition__lists__dotted {
  display: flex;
  flex-wrap: wrap;
}

body.hsc .p-definition__lists__dotted dt {
  width: 25%;
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  padding: 1rem .5em;
  font-weight: normal;
}

body.hsc .p-definition__lists__dotted dd {
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  margin-left: 4px;
  padding: 1rem .5em;
  width: calc(75% - 4px);
}

body.hsc .p-definition__site {
  display: flex;
  flex-wrap: wrap;
}

body.hsc .p-definition__site dt {
  width: 25%;
  border-bottom: 1px solid #fff;
  padding: .5em;
  color: #fff;
  background: #E85298;
  font-weight: normal;
}

body.hsc .p-definition__site dd {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-bottom: 0;
  padding: .5em;
  width: calc(75% - 3px);
  background-color: #fff;
}

body.hsc .p-definition__site dt,
body.hsc .p-definition__site dd {
  border-left: 1px solid #E85298;
}

body.hsc .p-definition__site dt:last-of-type,
body.hsc .p-definition__site dd:last-of-type {
  border-bottom: 1px solid #ccc;
}

body.hsc.parent-group .p-definition__site dt {
  color: #212224;
}

body.hsc table caption {
  caption-side: top;
  color: #212224;
}

body.hsc table th,
body.hsc table td {
  border: 1px solid #dee2e6;
  padding: .75rem;
}

body.hsc table th {
  color: #fff;
  background-color: #E85298;
  font-weight: bold;
}

body.hsc.parent-group table th {
  color: #212224;
}

body.hsc .p-table-wrap {
  overflow: auto;
}

body.hsc .p-table-wrap .table th,
body.hsc .p-table-wrap .table td {
  min-width: 8em;
}

body.hsc .p-table-wrap .table caption {
  caption-side: initial;
  color: #212224;
  font-weight: bold;
}

body.hsc .p-table-wrap .table thead th {
  background: #E85298;
  color: #fff;
  border-bottom: 0;
}

body.hsc .p-table-wrap .table tbody th {
  background: #E85298;
  color: #fff;
}

body.hsc .p-table-wrap.p-calendar {
  text-align: center;
}

body.hsc .p-table-wrap.p-calendar .table {
  min-width: unset;
}

body.hsc .p-table-wrap.p-calendar .table tbody td:first-of-type {
  background-color: #e9ecef;
}

body.hsc .p-table-wrap.p-calendar .table td,
body.hsc .p-table-wrap.p-calendar .table th {
  min-width: unset;
}

body.hsc.parent-group .p-table-wrap {
  overflow: auto;
}

body.hsc.parent-group .p-table-wrap .table thead th {
  color: #212224;
}

body.hsc.parent-group .p-table-wrap .table tbody th {
  color: #212224;
}

body.hsc .p-form form button {
  min-width: 160px;
}

body.hsc .p-form form dl {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #6c757d;
}

body.hsc .p-form form dl dt,
body.hsc .p-form form dl dd {
  padding: 1rem;
}

body.hsc .p-form form dl dt {
  width: 20%;
  background: #dee2e6;
}

body.hsc .p-form form dl dt span {
  color: #fff;
  background: #dc3545;
}

body.hsc .p-form form dl dd {
  width: 80%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

body.hsc .p-form form dl dd:not(:last-of-type) {
  border-bottom: 1px solid #6c757d;
}

body.hsc .p-form form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.hsc .p-form form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.hsc .p-form form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.hsc .p-form form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #E85298;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.hsc .p-form form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.hsc .p-form form dl dd.radio label input:checked + span {
  color: #E85298;
}

body.hsc .p-form form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.hsc .p-form form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.hsc .p-form form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.hsc .p-form form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.hsc .p-form form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.hsc .p-form form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #E85298;
  border-bottom: 3px solid #E85298;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.hsc .p-form form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.hsc .p-form form dl dd.checkbox label input:checked + span {
  color: #E85298;
}

body.hsc .p-form form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.hsc .p-form form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.hsc .p-form form dl input {
  border-radius: 5px;
  border: 1px solid #6c757d;
  padding: 5px;
}

body.hsc .p-form form dl input[type="radio"] {
  margin-right: 5px;
}

body.hsc .p-form form dl input[type="email"] {
  width: 70%;
}

body.hsc .p-form form dl input#address {
  width: 100%;
}

body.hsc .p-form form dl input:focus {
  outline: 3px solid #ced4da;
}

body.hsc .p-form form dl textarea,
body.hsc .p-form form dl select {
  border-radius: 5px;
  border: 1px solid #6c757d;
}

body.hsc .p-form form dl textarea:focus,
body.hsc .p-form form dl select:focus {
  outline: 3px solid #ced4da;
}

body.hsc .p-form form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  border-radius: 5px;
  padding-right: 30px;
  position: relative;
}

body.hsc .p-form form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

body.hsc .p-form form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
}

@supports (-ms-ime-align: auto) {
  body.hsc .p-form form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.hsc .p-form form dl label {
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0.5rem;
}

body.hsc .p-form form dl label input:focus,
body.hsc .p-form form dl label textarea:focus,
body.hsc .p-form form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.hsc .p-form form dl textarea {
  width: 100%;
}

body.hsc .p-form__btn__wrap .p-back-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.hsc .p-form__btn__wrap .p-back-btn__wrap button {
  color: #fff;
  background: #6c757d;
  padding-left: 30px;
  position: relative;
  transition: .2s;
}

body.hsc .p-form__btn__wrap .p-back-btn__wrap button:hover {
  cursor: pointer;
  background: #60686f;
}

body.hsc .p-form__btn__wrap .p-back-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 0 0 2px;
  border-color: white 0 0 white;
  border-style: solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.hsc .p-form__btn__wrap .p-next-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.hsc .p-form__btn__wrap .p-next-btn__wrap .submit-btn {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #6c757d;
}

body.hsc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  cursor: pointer;
  pointer-events: auto;
  background-color: #E85298;
}

body.hsc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #e2257d;
}

body.hsc .p-form__btn__wrap .p-next-btn__wrap button {
  color: #fff;
  background: #E85298;
  padding-right: 30px;
  position: relative;
  transition: .2s;
}

body.hsc .p-form__btn__wrap .p-next-btn__wrap button:hover {
  cursor: pointer;
  background: #e53b8b;
}

body.hsc .p-form__btn__wrap .p-next-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 2px 0 0;
  border-color: white white 0 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.hsc .p-pagination {
  padding-left: 0;
  justify-content: center;
  flex-wrap: wrap;
}

body.hsc .p-pagination .page-item a {
  border: 1px solid #E85298;
  text-decoration: none;
  color: #E85298;
}

body.hsc .p-pagination .page-item a:hover {
  background: #E85298;
  color: white;
}

body.hsc .p-pagination .page-item.active .page-link {
  background-color: #E85298;
  border-color: #E85298;
  color: white;
}

body.hsc .p-pagination .move_btn__wrap {
  padding-left: 0;
  display: flex;
}

body.hsc .p-pagination .move_btn__wrap ul {
  padding-left: 0;
  list-style: none;
}

body.hsc .p-pagination .move_btn__wrap .move_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

body.hsc .p-pagination .move_btn__wrap .move_btn a {
  transition: .2s;
}

body.hsc .p-pagination .move_btn__wrap .move_btn a:hover {
  background-color: #e53b8b;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.first-page span, body.hsc .p-pagination .move_btn__wrap .move_btn.last-page span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  height: 15px;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.first-page span:before, body.hsc .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.first-page span:after, body.hsc .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.first-page a {
  padding-left: 40px;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.first-page span {
  left: 10px;
  border-left: 2px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.first-page span:before {
  left: 0;
  border-right: 10px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.first-page span:after {
  left: 10px;
  border-right: 10px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.last-page a {
  padding-right: 40px;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.last-page span {
  right: 10px;
  border-right: 2px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  right: 0;
  border-left: 10px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  right: 10px;
  border-left: 10px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.prev-page a span, body.hsc .p-pagination .move_btn__wrap .move_btn.next-page a span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  width: 0;
  height: 0;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.prev-page a {
  padding-left: 25px;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.prev-page a span {
  left: 10px;
  border-right: 8px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.next-page a {
  padding-right: 25px;
}

body.hsc .p-pagination .move_btn__wrap .move_btn.next-page span {
  right: 10px;
  border-left: 8px solid white;
}

body.hsc .p-pagination .move_btn__wrap .move_btn a {
  padding: .4rem .5rem;
  background-color: #E85298;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
}

body.hsc .p-accordion {
  font-size: 1rem;
  margin-bottom: 5rem;
}

body.hsc .p-accordion > li {
  border-bottom: 1px solid #E85298;
}

body.hsc .p-accordion > li:first-of-type {
  border-top: 1px solid #E85298;
}

body.hsc .p-accordion .accBtn {
  padding: .5rem;
  background-color: transparent;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 1.4rem 20px;
  transition: .2s;
}

body.hsc .p-accordion .accBtn:hover {
  opacity: .7;
}

body.hsc .p-accordion .accBtn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: transparent #E85298 #E85298 transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

body.hsc .p-accordion .accBtn.is_active {
  background-color: #E85298;
  color: white;
}

body.hsc .p-accordion .accBtn.is_active:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-color: white;
}

body.hsc .p-accordion .accContent {
  padding: 10px 20px;
  display: block;
  background: #fff;
  position: relative;
}

body.hsc .p-accordion .accContent.is_hidden {
  display: none;
  height: 0 !important;
}

body.hsc .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.hsc .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.hsc .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.hsc .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.hsc .p-form-second ::placeholder {
  color: #adb5bd;
}

body.hsc .p-form-second .p-next-btn__wrap button,
body.hsc .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.hsc .p-form-second .privacy {
  text-align: center;
}

body.hsc .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.hsc .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.hsc .p-form-second form {
  font-size: 1rem;
}

body.hsc .p-form-second form button {
  min-width: 190px;
}

body.hsc .p-form-second form dl dt,
body.hsc .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.hsc .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.hsc .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #E85298;
}

body.hsc .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.hsc .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.hsc .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.hsc .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.hsc .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.hsc .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.hsc .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #E85298;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.hsc .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.hsc .p-form-second form dl dd.radio label input:checked + span {
  color: #E85298;
}

body.hsc .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.hsc .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.hsc .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.hsc .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.hsc .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.hsc .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #E85298;
  border-bottom: 3px solid #E85298;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.hsc .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.hsc .p-form-second form dl dd.checkbox label input:checked + span {
  color: #E85298;
}

body.hsc .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.hsc .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.hsc .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.hsc .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.hsc .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.hsc .p-form-second form dl input#address {
  width: 100%;
}

body.hsc .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.hsc .p-form-second form dl textarea,
body.hsc .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.hsc .p-form-second form dl textarea:focus,
body.hsc .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.hsc .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.hsc .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.hsc .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.hsc .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.hsc .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.hsc .p-form-second form dl label input:focus,
body.hsc .p-form-second form dl label textarea:focus,
body.hsc .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.hsc .p-form-second form dl textarea {
  width: 100%;
}

body.hsc.page h1:not(.not) {
  font-size: 1.4375rem;
  background-color: #E85298;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #fff;
}

body.hsc.page .h1 {
  font-size: 1.4375rem;
  background-color: #E85298;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #E85298;
  font-weight: bold;
  background: repeating-linear-gradient(-45deg, rgba(232, 82, 152, 0.2), rgba(232, 82, 152, 0.2) 4px, #fff 0, #fff 6px);
}

body.hsc.page h2:not(.not) {
  font-size: 1.4375rem;
  padding-bottom: 1rem;
  background: linear-gradient(to right, #E85298 0, #E85298 30%, #E2E2E2 30%) no-repeat;
  background-size: 100% 6px;
  background-position: bottom;
  margin: 2.5rem 0 1.25rem;
}

body.hsc.page h3:not(.not) {
  font-size: 1.3125rem;
  background-color: #E85298;
  padding: .4rem .4rem .4rem 1rem;
  margin: 1.875rem 0 1.25rem;
  color: #fff;
}

body.hsc.page h4:not(.not) {
  font-size: 1.3125rem;
  padding-bottom: 0.2rem;
  margin: 1.875rem 0 1.25rem;
  border-bottom: 2px dotted #212224;
}

body.hsc.page h5 {
  font-size: 1.25rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  border-left: 5px double #212224;
}

body.hsc.page h6 {
  font-size: 1.125rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
}

body.hsc.page h6:before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 3px;
  background-color: #212224;
  vertical-align: middle;
  margin-right: 0.3rem;
}

body.hsc.page.page02 h1 {
  background: repeating-linear-gradient(-45deg, rgba(232, 82, 152, 0.2), rgba(232, 82, 152, 0.2) 4px, #fff 0, #fff 6px);
  color: #E85298;
  font-weight: 900;
}

body.hsc.page .p-link-block {
  max-width: 850px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

body.hsc.page .p-link-block .p-link-block__item {
  margin-bottom: 2rem;
  width: 100%;
}

body.hsc.page .p-link-block .p-link-block__link {
  transition: .2s;
  text-decoration: none;
  color: #212224;
  display: block;
}

body.hsc.page .p-link-block .p-link-block__link:hover {
  opacity: .7;
}

body.hsc.page .p-link-block .p-link-block__head {
  border: 1px solid #E85298;
  border-radius: 13px;
  padding: 1rem 2.5rem 1rem 1rem;
  position: relative;
  font-size: 1.125rem;
  color: #E85298;
}

body.hsc.page .p-link-block .p-link-block__head:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  border-bottom: 1px solid #E85298;
  border-right: 1px solid #E85298;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.hsc.page .p-link-block .p-link-block__head:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 19px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 1px;
  background-color: #E85298;
}

body.hsc.page .p-link-block .p-link-block__description {
  padding: .5rem;
  font-size: 0.875rem;
}

body.hsc.page .p-more {
  position: relative;
  height: 20em;
  overflow: hidden;
}

body.hsc.page .p-more.is_full-show {
  height: auto;
  overflow: visible;
}

body.hsc.page .p-more .btn__wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

body.hsc.page .p-more .btn__wrap.is_show {
  display: none;
}

body.hsc.page .p-more .btn__wrap:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, white 100%);
  z-index: -1;
}

body.hsc.page .p-more .more-btn {
  position: relative;
  border-radius: 100px;
  border: 1px solid #E85298;
  color: #E85298;
  text-align: center;
  padding: .5rem 2rem;
  margin: auto;
  background-color: #fff;
  width: 240px;
  font-size: 1.25rem;
  display: block;
  transition: .2s;
}

body.hsc.page .p-more .more-btn:hover {
  opacity: .7;
}

body.hsc.page .p-more .more-btn.is_hidden {
  opacity: 0;
  visibility: hidden;
}

body.hsc.page .p-more .more-btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 20px;
  background-color: #E85298;
}

body.hsc.page .p-more .more-btn:before {
  content: '';
  display: block;
  position: absolute;
  right: 26px;
  bottom: 16px;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: 1px solid #E85298;
  border-right: 1px solid #E85298;
}

body.hsc.page .p-btn {
  text-align: center;
}

body.hsc.page .p-btn a {
  text-decoration: none;
  position: relative;
  z-index: 20;
  display: inline-flex;
}

body.hsc.page .p-btn a:after, body.hsc.page .p-btn a:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 10;
  transition: .2s;
}

body.hsc.page .p-btn a:after {
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 3px;
  background-color: #212224;
}

body.hsc.page .p-btn a:before {
  right: 12px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg) translate(0px, 1px);
  transform: rotate(45deg) translate(0px, 1px);
  border-top: 3px solid #212224;
  border-right: 3px solid #212224;
}

body.hsc.page .p-btn a span {
  display: inline-block;
  position: relative;
  z-index: 10;
}

body.hsc.page .p-btn a span:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #212224;
  transition: .3s;
}

body.hsc.page .p-btn a span:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: calc(100% - 4px);
  width: 0;
  background-color: white;
  transition: .3s;
}

body.hsc.page .p-btn a .txt {
  color: #212224;
  font-weight: bold;
  min-width: 220px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: .7rem 2rem .7rem 1rem;
  background: #fff;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #212224;
}

body.hsc.page .p-btn a .chs {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -1;
  height: 0;
}

body.hsc.page .p-btn a .ch-head {
  display: block;
  opacity: 0;
  transition: .15s;
  visibility: hidden;
}

body.hsc.page .p-btn a .ch-head.ch-head01 {
  -webkit-transform: translate(40px, 80%);
  transform: translate(40px, 80%);
}

body.hsc.page .p-btn a .ch-head.ch-head02 {
  position: relative;
  z-index: 10;
  -webkit-transform: translate(0, 80%);
  transform: translate(0, 80%);
}

body.hsc.page .p-btn a .ch-head.ch-head03 {
  -webkit-transform: translate(-40px, 80%);
  transform: translate(-40px, 80%);
}

body.hsc.page .p-btn a:hover:after {
  right: 3px;
}

body.hsc.page .p-btn a:hover:before {
  right: 1px;
}

body.hsc.page .p-btn a:hover .ch-head {
  opacity: 1;
  visibility: visible;
}

body.hsc.page .p-btn a:hover .ch-head.ch-head01 {
  -webkit-transform: translate(15px, 0);
  transform: translate(15px, 0);
}

@-moz-document url-prefix() {
  body.hsc.page .p-btn a:hover .ch-head.ch-head01 {
    transform: translate(15px, 0);
  }
}

body.hsc.page .p-btn a:hover .ch-head.ch-head02 {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

@-moz-document url-prefix() {
  body.hsc.page .p-btn a:hover .ch-head.ch-head02 {
    transform: translate(0, 1px);
  }
}

body.hsc.page .p-btn a:hover .ch-head.ch-head03 {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
}

@-moz-document url-prefix() {
  body.hsc.page .p-btn a:hover .ch-head.ch-head03 {
    transform: translate(-15px, 1px);
  }
}

body.hsc.page .p-btn a:hover span:after {
  transition: .2s ease-in-out .1s;
  opacity: 0;
  -webkit-transform: translateX(70px) rotate(2turn);
  transform: translateX(70px) rotate(2turn);
}

body.hsc.page .p-btn a:hover span:before {
  transition: .2s ease-in-out .1s;
  width: 20px;
}

body.hsc.page .p-btn.not-arrow a .txt {
  padding: 0.7rem 1rem;
}

body.hsc.page .p-btn.not-arrow a:after, body.hsc.page .p-btn.not-arrow a:before {
  content: none;
}

body.hsc.page .p-btn.p-btn--lower a {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.15);
}

body.hsc.page .p-btn.p-btn--lower a .txt {
  border-width: 2px;
  border-color: white;
}

body.hsc.page .p-btn.p-btn--lower a:after, body.hsc.page .p-btn.p-btn--lower a:before {
  right: 10px;
}

body.hsc.page .p-btn.p-btn--lower a:after {
  height: 1px;
}

body.hsc.page .p-btn.p-btn--lower a:before {
  border-width: 1px;
}

body.hsc.page .p-btn.p-btn--lower a:hover:after, body.hsc.page .p-btn.p-btn--lower a:hover:before {
  right: 6px;
}

body.hsc.page .p-btn.p-btn--lower a .txt:not(.not) {
  min-width: 220px;
}

body.hsc.page .p-btn.p-btn--lower.p-btn--back a .txt {
  padding: .7rem 1rem .7rem 2rem;
}

body.hsc.page .p-btn.p-btn--lower.p-btn--back a:after {
  right: auto;
  left: 9px;
}

body.hsc.page .p-btn.p-btn--lower.p-btn--back a:before {
  right: auto;
  left: 8px;
  top: -1px;
  -webkit-transform: rotate(-135deg) translate(-1px, 1px);
  transform: rotate(-135deg) translate(-1px, 1px);
}

body.hsc.page .p-btn.p-btn--lower.p-btn--dark a:before {
  border-width: 1px;
}

body.hsc.page .p-btn.p-btn--lower.p-btn--school a .txt {
  color: white;
  background-color: #E85298;
}

body.hsc.page .p-btn.p-btn--lower.p-btn--school a:after {
  background-color: white;
}

body.hsc.page .p-btn.p-btn--lower.p-btn--school a:after, body.hsc.page .p-btn.p-btn--lower.p-btn--school a:before {
  border-color: white;
}

body.hsc.page .p-btn.p-btn--lower.p-btn--lg a .txt {
  padding: 1.2rem 2rem 1.2rem 1rem;
  min-width: 250px;
}

body.hsc.page .p-btn.p-btn--gradient a .txt {
  border-color: white;
  color: white;
  background: linear-gradient(135deg, #ec88de 0%, #82e8f5 100%);
}

body.hsc.page .p-btn.p-btn--gradient a:before {
  border-color: #fff;
}

body.hsc.page .p-btn.p-btn--gradient a:after {
  background-color: #fff;
}

body.hsc.page .p-btn.p-btn--gradient a span:after {
  background-color: #fff;
}

body.hsc.page .p-btn.p-btn--dark a .txt {
  color: white;
  border-color: white;
  background-color: #333;
}

body.hsc.page .p-btn.p-btn--dark a:after {
  background-color: white;
}

body.hsc.page .p-btn.p-btn--dark a:before {
  border-top: 3px solid white;
  border-right: 3px solid white;
}

body.hsc.page .p-btn.p-btn--dark a span:after {
  background-color: white;
}

body.hsc.page .p-btn.p-btn--dark a span:before {
  background-color: #333;
}

body.hsc.page .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.hsc.page .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.hsc.page .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.hsc.page .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.hsc.page .p-form-second ::placeholder {
  color: #adb5bd;
}

body.hsc.page .p-form-second .p-next-btn__wrap button,
body.hsc.page .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.hsc.page .p-form-second .privacy {
  text-align: center;
}

body.hsc.page .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.hsc.page .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.hsc.page .p-form-second form {
  font-size: 1rem;
}

body.hsc.page .p-form-second form button {
  min-width: 190px;
}

body.hsc.page .p-form-second form dl dt,
body.hsc.page .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.hsc.page .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.hsc.page .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #E85298;
}

body.hsc.page .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.hsc.page .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.hsc.page .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.hsc.page .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.hsc.page .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.hsc.page .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.hsc.page .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #E85298;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.hsc.page .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.hsc.page .p-form-second form dl dd.radio label input:checked + span {
  color: #E85298;
}

body.hsc.page .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.hsc.page .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.hsc.page .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.hsc.page .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.hsc.page .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.hsc.page .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #E85298;
  border-bottom: 3px solid #E85298;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.hsc.page .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.hsc.page .p-form-second form dl dd.checkbox label input:checked + span {
  color: #E85298;
}

body.hsc.page .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.hsc.page .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.hsc.page .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.hsc.page .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.hsc.page .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.hsc.page .p-form-second form dl input#address {
  width: 100%;
}

body.hsc.page .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.hsc.page .p-form-second form dl textarea,
body.hsc.page .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.hsc.page .p-form-second form dl textarea:focus,
body.hsc.page .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.hsc.page .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.hsc.page .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.hsc.page .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.hsc.page .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.hsc.page .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.hsc.page .p-form-second form dl label input:focus,
body.hsc.page .p-form-second form dl label textarea:focus,
body.hsc.page .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.hsc.page .p-form-second form dl textarea {
  width: 100%;
}

body.htc .p-important__txt {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 5px solid #FF7603;
}

body.htc .p-important__txt p {
  margin-bottom: 0;
}

body.htc blockquote {
  padding-left: 2rem;
  border-left: 5px solid #FF7603;
  margin-bottom: 1rem;
}

body.htc blockquote cite {
  display: block;
  text-align: right;
}

body.htc.page:not(.lp) .page-contents ul:not(.list-unstyled) {
  list-style: none;
  padding-left: 0;
}

body.htc.page:not(.lp) .page-contents ul:not(.list-unstyled) li {
  padding-left: .5rem;
}

body.htc.page:not(.lp) .page-contents ul:not(.list-unstyled) li:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background: #FF7603;
  margin-right: .5rem;
  margin-bottom: 2px;
}

body.htc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--01:before {
  background: #7FADD8;
}

body.htc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--02:before {
  background: #F48E8C;
}

body.htc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--03:before {
  background: #8CBD97;
}

body.htc.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--04:before {
  background: #FF851C;
}

body.htc .p-definition__lists__dotted {
  display: flex;
  flex-wrap: wrap;
}

body.htc .p-definition__lists__dotted dt {
  width: 25%;
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  padding: 1rem .5em;
  font-weight: normal;
}

body.htc .p-definition__lists__dotted dd {
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  margin-left: 4px;
  padding: 1rem .5em;
  width: calc(75% - 4px);
}

body.htc .p-definition__site {
  display: flex;
  flex-wrap: wrap;
}

body.htc .p-definition__site dt {
  width: 25%;
  border-bottom: 1px solid #fff;
  padding: .5em;
  color: #fff;
  background: #FF7603;
  font-weight: normal;
}

body.htc .p-definition__site dd {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-bottom: 0;
  padding: .5em;
  width: calc(75% - 3px);
  background-color: #fff;
}

body.htc .p-definition__site dt,
body.htc .p-definition__site dd {
  border-left: 1px solid #FF7603;
}

body.htc .p-definition__site dt:last-of-type,
body.htc .p-definition__site dd:last-of-type {
  border-bottom: 1px solid #ccc;
}

body.htc.parent-group .p-definition__site dt {
  color: #212224;
}

body.htc table caption {
  caption-side: top;
  color: #212224;
}

body.htc table th,
body.htc table td {
  border: 1px solid #dee2e6;
  padding: .75rem;
}

body.htc table th {
  color: #fff;
  background-color: #FF7603;
  font-weight: bold;
}

body.htc.parent-group table th {
  color: #212224;
}

body.htc .p-table-wrap {
  overflow: auto;
}

body.htc .p-table-wrap .table th,
body.htc .p-table-wrap .table td {
  min-width: 8em;
}

body.htc .p-table-wrap .table caption {
  caption-side: initial;
  color: #212224;
  font-weight: bold;
}

body.htc .p-table-wrap .table thead th {
  background: #FF7603;
  color: #fff;
  border-bottom: 0;
}

body.htc .p-table-wrap .table tbody th {
  background: #FF7603;
  color: #fff;
}

body.htc .p-table-wrap.p-calendar {
  text-align: center;
}

body.htc .p-table-wrap.p-calendar .table {
  min-width: unset;
}

body.htc .p-table-wrap.p-calendar .table tbody td:first-of-type {
  background-color: #e9ecef;
}

body.htc .p-table-wrap.p-calendar .table td,
body.htc .p-table-wrap.p-calendar .table th {
  min-width: unset;
}

body.htc.parent-group .p-table-wrap {
  overflow: auto;
}

body.htc.parent-group .p-table-wrap .table thead th {
  color: #212224;
}

body.htc.parent-group .p-table-wrap .table tbody th {
  color: #212224;
}

body.htc .p-form form button {
  min-width: 160px;
}

body.htc .p-form form dl {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #6c757d;
}

body.htc .p-form form dl dt,
body.htc .p-form form dl dd {
  padding: 1rem;
}

body.htc .p-form form dl dt {
  width: 20%;
  background: #dee2e6;
}

body.htc .p-form form dl dt span {
  color: #fff;
  background: #dc3545;
}

body.htc .p-form form dl dd {
  width: 80%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

body.htc .p-form form dl dd:not(:last-of-type) {
  border-bottom: 1px solid #6c757d;
}

body.htc .p-form form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.htc .p-form form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.htc .p-form form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.htc .p-form form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #FF7603;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.htc .p-form form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.htc .p-form form dl dd.radio label input:checked + span {
  color: #FF7603;
}

body.htc .p-form form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.htc .p-form form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.htc .p-form form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.htc .p-form form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.htc .p-form form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.htc .p-form form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #FF7603;
  border-bottom: 3px solid #FF7603;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.htc .p-form form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.htc .p-form form dl dd.checkbox label input:checked + span {
  color: #FF7603;
}

body.htc .p-form form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.htc .p-form form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.htc .p-form form dl input {
  border-radius: 5px;
  border: 1px solid #6c757d;
  padding: 5px;
}

body.htc .p-form form dl input[type="radio"] {
  margin-right: 5px;
}

body.htc .p-form form dl input[type="email"] {
  width: 70%;
}

body.htc .p-form form dl input#address {
  width: 100%;
}

body.htc .p-form form dl input:focus {
  outline: 3px solid #ced4da;
}

body.htc .p-form form dl textarea,
body.htc .p-form form dl select {
  border-radius: 5px;
  border: 1px solid #6c757d;
}

body.htc .p-form form dl textarea:focus,
body.htc .p-form form dl select:focus {
  outline: 3px solid #ced4da;
}

body.htc .p-form form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  border-radius: 5px;
  padding-right: 30px;
  position: relative;
}

body.htc .p-form form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

body.htc .p-form form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
}

@supports (-ms-ime-align: auto) {
  body.htc .p-form form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.htc .p-form form dl label {
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0.5rem;
}

body.htc .p-form form dl label input:focus,
body.htc .p-form form dl label textarea:focus,
body.htc .p-form form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.htc .p-form form dl textarea {
  width: 100%;
}

body.htc .p-form__btn__wrap .p-back-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.htc .p-form__btn__wrap .p-back-btn__wrap button {
  color: #fff;
  background: #6c757d;
  padding-left: 30px;
  position: relative;
  transition: .2s;
}

body.htc .p-form__btn__wrap .p-back-btn__wrap button:hover {
  cursor: pointer;
  background: #60686f;
}

body.htc .p-form__btn__wrap .p-back-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 0 0 2px;
  border-color: white 0 0 white;
  border-style: solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.htc .p-form__btn__wrap .p-next-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.htc .p-form__btn__wrap .p-next-btn__wrap .submit-btn {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #6c757d;
}

body.htc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  cursor: pointer;
  pointer-events: auto;
  background-color: #FF7603;
}

body.htc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #cf5e00;
}

body.htc .p-form__btn__wrap .p-next-btn__wrap button {
  color: #fff;
  background: #FF7603;
  padding-right: 30px;
  position: relative;
  transition: .2s;
}

body.htc .p-form__btn__wrap .p-next-btn__wrap button:hover {
  cursor: pointer;
  background: #e96a00;
}

body.htc .p-form__btn__wrap .p-next-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 2px 0 0;
  border-color: white white 0 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.htc .p-pagination {
  padding-left: 0;
  justify-content: center;
  flex-wrap: wrap;
}

body.htc .p-pagination .page-item a {
  border: 1px solid #FF7603;
  text-decoration: none;
  color: #FF7603;
}

body.htc .p-pagination .page-item a:hover {
  background: #FF7603;
  color: white;
}

body.htc .p-pagination .page-item.active .page-link {
  background-color: #FF7603;
  border-color: #FF7603;
  color: white;
}

body.htc .p-pagination .move_btn__wrap {
  padding-left: 0;
  display: flex;
}

body.htc .p-pagination .move_btn__wrap ul {
  padding-left: 0;
  list-style: none;
}

body.htc .p-pagination .move_btn__wrap .move_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

body.htc .p-pagination .move_btn__wrap .move_btn a {
  transition: .2s;
}

body.htc .p-pagination .move_btn__wrap .move_btn a:hover {
  background-color: #e96a00;
}

body.htc .p-pagination .move_btn__wrap .move_btn.first-page span, body.htc .p-pagination .move_btn__wrap .move_btn.last-page span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  height: 15px;
}

body.htc .p-pagination .move_btn__wrap .move_btn.first-page span:before, body.htc .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.htc .p-pagination .move_btn__wrap .move_btn.first-page span:after, body.htc .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.htc .p-pagination .move_btn__wrap .move_btn.first-page a {
  padding-left: 40px;
}

body.htc .p-pagination .move_btn__wrap .move_btn.first-page span {
  left: 10px;
  border-left: 2px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn.first-page span:before {
  left: 0;
  border-right: 10px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn.first-page span:after {
  left: 10px;
  border-right: 10px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn.last-page a {
  padding-right: 40px;
}

body.htc .p-pagination .move_btn__wrap .move_btn.last-page span {
  right: 10px;
  border-right: 2px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  right: 0;
  border-left: 10px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  right: 10px;
  border-left: 10px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn.prev-page a span, body.htc .p-pagination .move_btn__wrap .move_btn.next-page a span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  width: 0;
  height: 0;
}

body.htc .p-pagination .move_btn__wrap .move_btn.prev-page a {
  padding-left: 25px;
}

body.htc .p-pagination .move_btn__wrap .move_btn.prev-page a span {
  left: 10px;
  border-right: 8px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn.next-page a {
  padding-right: 25px;
}

body.htc .p-pagination .move_btn__wrap .move_btn.next-page span {
  right: 10px;
  border-left: 8px solid white;
}

body.htc .p-pagination .move_btn__wrap .move_btn a {
  padding: .4rem .5rem;
  background-color: #FF7603;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
}

body.htc .p-accordion {
  font-size: 1rem;
  margin-bottom: 5rem;
}

body.htc .p-accordion > li {
  border-bottom: 1px solid #FF7603;
}

body.htc .p-accordion > li:first-of-type {
  border-top: 1px solid #FF7603;
}

body.htc .p-accordion .accBtn {
  padding: .5rem;
  background-color: transparent;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 1.4rem 20px;
  transition: .2s;
}

body.htc .p-accordion .accBtn:hover {
  opacity: .7;
}

body.htc .p-accordion .accBtn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: transparent #FF7603 #FF7603 transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

body.htc .p-accordion .accBtn.is_active {
  background-color: #FF7603;
  color: white;
}

body.htc .p-accordion .accBtn.is_active:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-color: white;
}

body.htc .p-accordion .accContent {
  padding: 10px 20px;
  display: block;
  background: #fff;
  position: relative;
}

body.htc .p-accordion .accContent.is_hidden {
  display: none;
  height: 0 !important;
}

body.htc .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.htc .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.htc .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.htc .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.htc .p-form-second ::placeholder {
  color: #adb5bd;
}

body.htc .p-form-second .p-next-btn__wrap button,
body.htc .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.htc .p-form-second .privacy {
  text-align: center;
}

body.htc .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.htc .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.htc .p-form-second form {
  font-size: 1rem;
}

body.htc .p-form-second form button {
  min-width: 190px;
}

body.htc .p-form-second form dl dt,
body.htc .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.htc .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.htc .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #FF7603;
}

body.htc .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.htc .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.htc .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.htc .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.htc .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.htc .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.htc .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #FF7603;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.htc .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.htc .p-form-second form dl dd.radio label input:checked + span {
  color: #FF7603;
}

body.htc .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.htc .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.htc .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.htc .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.htc .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.htc .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #FF7603;
  border-bottom: 3px solid #FF7603;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.htc .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.htc .p-form-second form dl dd.checkbox label input:checked + span {
  color: #FF7603;
}

body.htc .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.htc .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.htc .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.htc .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.htc .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.htc .p-form-second form dl input#address {
  width: 100%;
}

body.htc .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.htc .p-form-second form dl textarea,
body.htc .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.htc .p-form-second form dl textarea:focus,
body.htc .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.htc .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.htc .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.htc .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.htc .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.htc .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.htc .p-form-second form dl label input:focus,
body.htc .p-form-second form dl label textarea:focus,
body.htc .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.htc .p-form-second form dl textarea {
  width: 100%;
}

body.htc.page h1:not(.not) {
  font-size: 1.4375rem;
  background-color: #FF7603;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #fff;
}

body.htc.page .h1 {
  font-size: 1.4375rem;
  background-color: #FF7603;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #FF7603;
  font-weight: bold;
  background: repeating-linear-gradient(-45deg, rgba(255, 118, 3, 0.2), rgba(255, 118, 3, 0.2) 4px, #fff 0, #fff 6px);
}

body.htc.page h2:not(.not) {
  font-size: 1.4375rem;
  padding-bottom: 1rem;
  background: linear-gradient(to right, #FF7603 0, #FF7603 30%, #E2E2E2 30%) no-repeat;
  background-size: 100% 6px;
  background-position: bottom;
  margin: 2.5rem 0 1.25rem;
}

body.htc.page h3:not(.not) {
  font-size: 1.3125rem;
  background-color: #FF7603;
  padding: .4rem .4rem .4rem 1rem;
  margin: 1.875rem 0 1.25rem;
  color: #fff;
}

body.htc.page h4:not(.not) {
  font-size: 1.3125rem;
  padding-bottom: 0.2rem;
  margin: 1.875rem 0 1.25rem;
  border-bottom: 2px dotted #212224;
}

body.htc.page h5 {
  font-size: 1.25rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  border-left: 5px double #212224;
}

body.htc.page h6 {
  font-size: 1.125rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
}

body.htc.page h6:before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 3px;
  background-color: #212224;
  vertical-align: middle;
  margin-right: 0.3rem;
}

body.htc.page.page02 h1 {
  background: repeating-linear-gradient(-45deg, rgba(255, 118, 3, 0.2), rgba(255, 118, 3, 0.2) 4px, #fff 0, #fff 6px);
  color: #FF7603;
  font-weight: 900;
}

body.htc.page .p-link-block {
  max-width: 850px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

body.htc.page .p-link-block .p-link-block__item {
  margin-bottom: 2rem;
  width: 100%;
}

body.htc.page .p-link-block .p-link-block__link {
  transition: .2s;
  text-decoration: none;
  color: #212224;
  display: block;
}

body.htc.page .p-link-block .p-link-block__link:hover {
  opacity: .7;
}

body.htc.page .p-link-block .p-link-block__head {
  border: 1px solid #FF7603;
  border-radius: 13px;
  padding: 1rem 2.5rem 1rem 1rem;
  position: relative;
  font-size: 1.125rem;
  color: #FF7603;
}

body.htc.page .p-link-block .p-link-block__head:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  border-bottom: 1px solid #FF7603;
  border-right: 1px solid #FF7603;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.htc.page .p-link-block .p-link-block__head:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 19px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 1px;
  background-color: #FF7603;
}

body.htc.page .p-link-block .p-link-block__description {
  padding: .5rem;
  font-size: 0.875rem;
}

body.htc.page .p-more {
  position: relative;
  height: 20em;
  overflow: hidden;
}

body.htc.page .p-more.is_full-show {
  height: auto;
  overflow: visible;
}

body.htc.page .p-more .btn__wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

body.htc.page .p-more .btn__wrap.is_show {
  display: none;
}

body.htc.page .p-more .btn__wrap:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, white 100%);
  z-index: -1;
}

body.htc.page .p-more .more-btn {
  position: relative;
  border-radius: 100px;
  border: 1px solid #FF7603;
  color: #FF7603;
  text-align: center;
  padding: .5rem 2rem;
  margin: auto;
  background-color: #fff;
  width: 240px;
  font-size: 1.25rem;
  display: block;
  transition: .2s;
}

body.htc.page .p-more .more-btn:hover {
  opacity: .7;
}

body.htc.page .p-more .more-btn.is_hidden {
  opacity: 0;
  visibility: hidden;
}

body.htc.page .p-more .more-btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 20px;
  background-color: #FF7603;
}

body.htc.page .p-more .more-btn:before {
  content: '';
  display: block;
  position: absolute;
  right: 26px;
  bottom: 16px;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: 1px solid #FF7603;
  border-right: 1px solid #FF7603;
}

body.htc.page .p-btn {
  text-align: center;
}

body.htc.page .p-btn a {
  text-decoration: none;
  position: relative;
  z-index: 20;
  display: inline-flex;
}

body.htc.page .p-btn a:after, body.htc.page .p-btn a:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 10;
  transition: .2s;
}

body.htc.page .p-btn a:after {
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 3px;
  background-color: #212224;
}

body.htc.page .p-btn a:before {
  right: 12px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg) translate(0px, 1px);
  transform: rotate(45deg) translate(0px, 1px);
  border-top: 3px solid #212224;
  border-right: 3px solid #212224;
}

body.htc.page .p-btn a span {
  display: inline-block;
  position: relative;
  z-index: 10;
}

body.htc.page .p-btn a span:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #212224;
  transition: .3s;
}

body.htc.page .p-btn a span:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: calc(100% - 4px);
  width: 0;
  background-color: white;
  transition: .3s;
}

body.htc.page .p-btn a .txt {
  color: #212224;
  font-weight: bold;
  min-width: 220px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: .7rem 2rem .7rem 1rem;
  background: #fff;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #212224;
}

body.htc.page .p-btn a .chs {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -1;
  height: 0;
}

body.htc.page .p-btn a .ch-head {
  display: block;
  opacity: 0;
  transition: .15s;
  visibility: hidden;
}

body.htc.page .p-btn a .ch-head.ch-head01 {
  -webkit-transform: translate(40px, 80%);
  transform: translate(40px, 80%);
}

body.htc.page .p-btn a .ch-head.ch-head02 {
  position: relative;
  z-index: 10;
  -webkit-transform: translate(0, 80%);
  transform: translate(0, 80%);
}

body.htc.page .p-btn a .ch-head.ch-head03 {
  -webkit-transform: translate(-40px, 80%);
  transform: translate(-40px, 80%);
}

body.htc.page .p-btn a:hover:after {
  right: 3px;
}

body.htc.page .p-btn a:hover:before {
  right: 1px;
}

body.htc.page .p-btn a:hover .ch-head {
  opacity: 1;
  visibility: visible;
}

body.htc.page .p-btn a:hover .ch-head.ch-head01 {
  -webkit-transform: translate(15px, 0);
  transform: translate(15px, 0);
}

@-moz-document url-prefix() {
  body.htc.page .p-btn a:hover .ch-head.ch-head01 {
    transform: translate(15px, 0);
  }
}

body.htc.page .p-btn a:hover .ch-head.ch-head02 {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

@-moz-document url-prefix() {
  body.htc.page .p-btn a:hover .ch-head.ch-head02 {
    transform: translate(0, 1px);
  }
}

body.htc.page .p-btn a:hover .ch-head.ch-head03 {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
}

@-moz-document url-prefix() {
  body.htc.page .p-btn a:hover .ch-head.ch-head03 {
    transform: translate(-15px, 1px);
  }
}

body.htc.page .p-btn a:hover span:after {
  transition: .2s ease-in-out .1s;
  opacity: 0;
  -webkit-transform: translateX(70px) rotate(2turn);
  transform: translateX(70px) rotate(2turn);
}

body.htc.page .p-btn a:hover span:before {
  transition: .2s ease-in-out .1s;
  width: 20px;
}

body.htc.page .p-btn.not-arrow a .txt {
  padding: 0.7rem 1rem;
}

body.htc.page .p-btn.not-arrow a:after, body.htc.page .p-btn.not-arrow a:before {
  content: none;
}

body.htc.page .p-btn.p-btn--lower a {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.15);
}

body.htc.page .p-btn.p-btn--lower a .txt {
  border-width: 2px;
  border-color: white;
}

body.htc.page .p-btn.p-btn--lower a:after, body.htc.page .p-btn.p-btn--lower a:before {
  right: 10px;
}

body.htc.page .p-btn.p-btn--lower a:after {
  height: 1px;
}

body.htc.page .p-btn.p-btn--lower a:before {
  border-width: 1px;
}

body.htc.page .p-btn.p-btn--lower a:hover:after, body.htc.page .p-btn.p-btn--lower a:hover:before {
  right: 6px;
}

body.htc.page .p-btn.p-btn--lower a .txt:not(.not) {
  min-width: 220px;
}

body.htc.page .p-btn.p-btn--lower.p-btn--back a .txt {
  padding: .7rem 1rem .7rem 2rem;
}

body.htc.page .p-btn.p-btn--lower.p-btn--back a:after {
  right: auto;
  left: 9px;
}

body.htc.page .p-btn.p-btn--lower.p-btn--back a:before {
  right: auto;
  left: 8px;
  top: -1px;
  -webkit-transform: rotate(-135deg) translate(-1px, 1px);
  transform: rotate(-135deg) translate(-1px, 1px);
}

body.htc.page .p-btn.p-btn--lower.p-btn--dark a:before {
  border-width: 1px;
}

body.htc.page .p-btn.p-btn--lower.p-btn--school a .txt {
  color: white;
  background-color: #FF7603;
}

body.htc.page .p-btn.p-btn--lower.p-btn--school a:after {
  background-color: white;
}

body.htc.page .p-btn.p-btn--lower.p-btn--school a:after, body.htc.page .p-btn.p-btn--lower.p-btn--school a:before {
  border-color: white;
}

body.htc.page .p-btn.p-btn--lower.p-btn--lg a .txt {
  padding: 1.2rem 2rem 1.2rem 1rem;
  min-width: 250px;
}

body.htc.page .p-btn.p-btn--gradient a .txt {
  border-color: white;
  color: white;
  background: linear-gradient(135deg, #ec88de 0%, #82e8f5 100%);
}

body.htc.page .p-btn.p-btn--gradient a:before {
  border-color: #fff;
}

body.htc.page .p-btn.p-btn--gradient a:after {
  background-color: #fff;
}

body.htc.page .p-btn.p-btn--gradient a span:after {
  background-color: #fff;
}

body.htc.page .p-btn.p-btn--dark a .txt {
  color: white;
  border-color: white;
  background-color: #333;
}

body.htc.page .p-btn.p-btn--dark a:after {
  background-color: white;
}

body.htc.page .p-btn.p-btn--dark a:before {
  border-top: 3px solid white;
  border-right: 3px solid white;
}

body.htc.page .p-btn.p-btn--dark a span:after {
  background-color: white;
}

body.htc.page .p-btn.p-btn--dark a span:before {
  background-color: #333;
}

body.htc.page .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.htc.page .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.htc.page .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.htc.page .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.htc.page .p-form-second ::placeholder {
  color: #adb5bd;
}

body.htc.page .p-form-second .p-next-btn__wrap button,
body.htc.page .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.htc.page .p-form-second .privacy {
  text-align: center;
}

body.htc.page .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.htc.page .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.htc.page .p-form-second form {
  font-size: 1rem;
}

body.htc.page .p-form-second form button {
  min-width: 190px;
}

body.htc.page .p-form-second form dl dt,
body.htc.page .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.htc.page .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.htc.page .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #FF7603;
}

body.htc.page .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.htc.page .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.htc.page .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.htc.page .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.htc.page .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.htc.page .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.htc.page .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #FF7603;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.htc.page .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.htc.page .p-form-second form dl dd.radio label input:checked + span {
  color: #FF7603;
}

body.htc.page .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.htc.page .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.htc.page .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.htc.page .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.htc.page .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.htc.page .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #FF7603;
  border-bottom: 3px solid #FF7603;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.htc.page .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.htc.page .p-form-second form dl dd.checkbox label input:checked + span {
  color: #FF7603;
}

body.htc.page .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.htc.page .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.htc.page .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.htc.page .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.htc.page .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.htc.page .p-form-second form dl input#address {
  width: 100%;
}

body.htc.page .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.htc.page .p-form-second form dl textarea,
body.htc.page .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.htc.page .p-form-second form dl textarea:focus,
body.htc.page .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.htc.page .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.htc.page .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.htc.page .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.htc.page .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.htc.page .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.htc.page .p-form-second form dl label input:focus,
body.htc.page .p-form-second form dl label textarea:focus,
body.htc.page .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.htc.page .p-form-second form dl textarea {
  width: 100%;
}

body.iryo .p-important__txt {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 5px solid #43AA88;
}

body.iryo .p-important__txt p {
  margin-bottom: 0;
}

body.iryo blockquote {
  padding-left: 2rem;
  border-left: 5px solid #43AA88;
  margin-bottom: 1rem;
}

body.iryo blockquote cite {
  display: block;
  text-align: right;
}

body.iryo.page:not(.lp) .page-contents ul:not(.list-unstyled) {
  list-style: none;
  padding-left: 0;
}

body.iryo.page:not(.lp) .page-contents ul:not(.list-unstyled) li {
  padding-left: .5rem;
}

body.iryo.page:not(.lp) .page-contents ul:not(.list-unstyled) li:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background: #43AA88;
  margin-right: .5rem;
  margin-bottom: 2px;
}

body.iryo.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--01:before {
  background: #7FADD8;
}

body.iryo.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--02:before {
  background: #F48E8C;
}

body.iryo.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--03:before {
  background: #8CBD97;
}

body.iryo.page:not(.lp) .page-contents ul:not(.list-unstyled) li.iryo--04:before {
  background: #FF851C;
}

body.iryo .p-definition__lists__dotted {
  display: flex;
  flex-wrap: wrap;
}

body.iryo .p-definition__lists__dotted dt {
  width: 25%;
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  padding: 1rem .5em;
  font-weight: normal;
}

body.iryo .p-definition__lists__dotted dd {
  border-bottom: 4px dotted #333;
  margin-bottom: 0;
  margin-left: 4px;
  padding: 1rem .5em;
  width: calc(75% - 4px);
}

body.iryo .p-definition__site {
  display: flex;
  flex-wrap: wrap;
}

body.iryo .p-definition__site dt {
  width: 25%;
  border-bottom: 1px solid #fff;
  padding: .5em;
  color: #fff;
  background: #43AA88;
  font-weight: normal;
}

body.iryo .p-definition__site dd {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-bottom: 0;
  padding: .5em;
  width: calc(75% - 3px);
  background-color: #fff;
}

body.iryo .p-definition__site dt,
body.iryo .p-definition__site dd {
  border-left: 1px solid #43AA88;
}

body.iryo .p-definition__site dt:last-of-type,
body.iryo .p-definition__site dd:last-of-type {
  border-bottom: 1px solid #ccc;
}

body.iryo.parent-group .p-definition__site dt {
  color: #212224;
}

body.iryo table caption {
  caption-side: top;
  color: #212224;
}

body.iryo table th,
body.iryo table td {
  border: 1px solid #dee2e6;
  padding: .75rem;
}

body.iryo table th {
  color: #fff;
  background-color: #43AA88;
  font-weight: bold;
}

body.iryo.parent-group table th {
  color: #212224;
}

body.iryo .p-table-wrap {
  overflow: auto;
}

body.iryo .p-table-wrap .table th,
body.iryo .p-table-wrap .table td {
  min-width: 8em;
}

body.iryo .p-table-wrap .table caption {
  caption-side: initial;
  color: #212224;
  font-weight: bold;
}

body.iryo .p-table-wrap .table thead th {
  background: #43AA88;
  color: #fff;
  border-bottom: 0;
}

body.iryo .p-table-wrap .table tbody th {
  background: #43AA88;
  color: #fff;
}

body.iryo .p-table-wrap.p-calendar {
  text-align: center;
}

body.iryo .p-table-wrap.p-calendar .table {
  min-width: unset;
}

body.iryo .p-table-wrap.p-calendar .table tbody td:first-of-type {
  background-color: #e9ecef;
}

body.iryo .p-table-wrap.p-calendar .table td,
body.iryo .p-table-wrap.p-calendar .table th {
  min-width: unset;
}

body.iryo.parent-group .p-table-wrap {
  overflow: auto;
}

body.iryo.parent-group .p-table-wrap .table thead th {
  color: #212224;
}

body.iryo.parent-group .p-table-wrap .table tbody th {
  color: #212224;
}

body.iryo .p-form form button {
  min-width: 160px;
}

body.iryo .p-form form dl {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #6c757d;
}

body.iryo .p-form form dl dt,
body.iryo .p-form form dl dd {
  padding: 1rem;
}

body.iryo .p-form form dl dt {
  width: 20%;
  background: #dee2e6;
}

body.iryo .p-form form dl dt span {
  color: #fff;
  background: #dc3545;
}

body.iryo .p-form form dl dd {
  width: 80%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

body.iryo .p-form form dl dd:not(:last-of-type) {
  border-bottom: 1px solid #6c757d;
}

body.iryo .p-form form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.iryo .p-form form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.iryo .p-form form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.iryo .p-form form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #43AA88;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.iryo .p-form form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.iryo .p-form form dl dd.radio label input:checked + span {
  color: #43AA88;
}

body.iryo .p-form form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.iryo .p-form form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.iryo .p-form form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.iryo .p-form form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.iryo .p-form form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.iryo .p-form form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #43AA88;
  border-bottom: 3px solid #43AA88;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.iryo .p-form form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.iryo .p-form form dl dd.checkbox label input:checked + span {
  color: #43AA88;
}

body.iryo .p-form form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.iryo .p-form form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.iryo .p-form form dl input {
  border-radius: 5px;
  border: 1px solid #6c757d;
  padding: 5px;
}

body.iryo .p-form form dl input[type="radio"] {
  margin-right: 5px;
}

body.iryo .p-form form dl input[type="email"] {
  width: 70%;
}

body.iryo .p-form form dl input#address {
  width: 100%;
}

body.iryo .p-form form dl input:focus {
  outline: 3px solid #ced4da;
}

body.iryo .p-form form dl textarea,
body.iryo .p-form form dl select {
  border-radius: 5px;
  border: 1px solid #6c757d;
}

body.iryo .p-form form dl textarea:focus,
body.iryo .p-form form dl select:focus {
  outline: 3px solid #ced4da;
}

body.iryo .p-form form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  border-radius: 5px;
  padding-right: 30px;
  position: relative;
}

body.iryo .p-form form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

body.iryo .p-form form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
}

@supports (-ms-ime-align: auto) {
  body.iryo .p-form form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.iryo .p-form form dl label {
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0.5rem;
}

body.iryo .p-form form dl label input:focus,
body.iryo .p-form form dl label textarea:focus,
body.iryo .p-form form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.iryo .p-form form dl textarea {
  width: 100%;
}

body.iryo .p-form__btn__wrap .p-back-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.iryo .p-form__btn__wrap .p-back-btn__wrap button {
  color: #fff;
  background: #6c757d;
  padding-left: 30px;
  position: relative;
  transition: .2s;
}

body.iryo .p-form__btn__wrap .p-back-btn__wrap button:hover {
  cursor: pointer;
  background: #60686f;
}

body.iryo .p-form__btn__wrap .p-back-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 0 0 2px;
  border-color: white 0 0 white;
  border-style: solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.iryo .p-form__btn__wrap .p-next-btn__wrap {
  text-align: center;
  margin-bottom: 0.5rem;
}

body.iryo .p-form__btn__wrap .p-next-btn__wrap .submit-btn {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #6c757d;
}

body.iryo .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  cursor: pointer;
  pointer-events: auto;
  background-color: #43AA88;
}

body.iryo .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #35856b;
}

body.iryo .p-form__btn__wrap .p-next-btn__wrap button {
  color: #fff;
  background: #43AA88;
  padding-right: 30px;
  position: relative;
  transition: .2s;
}

body.iryo .p-form__btn__wrap .p-next-btn__wrap button:hover {
  cursor: pointer;
  background: #3c9879;
}

body.iryo .p-form__btn__wrap .p-next-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-width: 2px 2px 0 0;
  border-color: white white 0 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.iryo .p-pagination {
  padding-left: 0;
  justify-content: center;
  flex-wrap: wrap;
}

body.iryo .p-pagination .page-item a {
  border: 1px solid #43AA88;
  text-decoration: none;
  color: #43AA88;
}

body.iryo .p-pagination .page-item a:hover {
  background: #43AA88;
  color: white;
}

body.iryo .p-pagination .page-item.active .page-link {
  background-color: #43AA88;
  border-color: #43AA88;
  color: white;
}

body.iryo .p-pagination .move_btn__wrap {
  padding-left: 0;
  display: flex;
}

body.iryo .p-pagination .move_btn__wrap ul {
  padding-left: 0;
  list-style: none;
}

body.iryo .p-pagination .move_btn__wrap .move_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

body.iryo .p-pagination .move_btn__wrap .move_btn a {
  transition: .2s;
}

body.iryo .p-pagination .move_btn__wrap .move_btn a:hover {
  background-color: #3c9879;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.first-page span, body.iryo .p-pagination .move_btn__wrap .move_btn.last-page span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  height: 15px;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.first-page span:before, body.iryo .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.first-page span:after, body.iryo .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.first-page a {
  padding-left: 40px;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.first-page span {
  left: 10px;
  border-left: 2px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.first-page span:before {
  left: 0;
  border-right: 10px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.first-page span:after {
  left: 10px;
  border-right: 10px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.last-page a {
  padding-right: 40px;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.last-page span {
  right: 10px;
  border-right: 2px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  right: 0;
  border-left: 10px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  right: 10px;
  border-left: 10px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.prev-page a span, body.iryo .p-pagination .move_btn__wrap .move_btn.next-page a span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  width: 0;
  height: 0;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.prev-page a {
  padding-left: 25px;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.prev-page a span {
  left: 10px;
  border-right: 8px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.next-page a {
  padding-right: 25px;
}

body.iryo .p-pagination .move_btn__wrap .move_btn.next-page span {
  right: 10px;
  border-left: 8px solid white;
}

body.iryo .p-pagination .move_btn__wrap .move_btn a {
  padding: .4rem .5rem;
  background-color: #43AA88;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
}

body.iryo .p-accordion {
  font-size: 1rem;
  margin-bottom: 5rem;
}

body.iryo .p-accordion > li {
  border-bottom: 1px solid #43AA88;
}

body.iryo .p-accordion > li:first-of-type {
  border-top: 1px solid #43AA88;
}

body.iryo .p-accordion .accBtn {
  padding: .5rem;
  background-color: transparent;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 1.4rem 20px;
  transition: .2s;
}

body.iryo .p-accordion .accBtn:hover {
  opacity: .7;
}

body.iryo .p-accordion .accBtn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: transparent #43AA88 #43AA88 transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

body.iryo .p-accordion .accBtn.is_active {
  background-color: #43AA88;
  color: white;
}

body.iryo .p-accordion .accBtn.is_active:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-color: white;
}

body.iryo .p-accordion .accContent {
  padding: 10px 20px;
  display: block;
  background: #fff;
  position: relative;
}

body.iryo .p-accordion .accContent.is_hidden {
  display: none;
  height: 0 !important;
}

body.iryo .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.iryo .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.iryo .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.iryo .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.iryo .p-form-second ::placeholder {
  color: #adb5bd;
}

body.iryo .p-form-second .p-next-btn__wrap button,
body.iryo .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.iryo .p-form-second .privacy {
  text-align: center;
}

body.iryo .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.iryo .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.iryo .p-form-second form {
  font-size: 1rem;
}

body.iryo .p-form-second form button {
  min-width: 190px;
}

body.iryo .p-form-second form dl dt,
body.iryo .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.iryo .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.iryo .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #43AA88;
}

body.iryo .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.iryo .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.iryo .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.iryo .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.iryo .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.iryo .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.iryo .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #43AA88;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.iryo .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.iryo .p-form-second form dl dd.radio label input:checked + span {
  color: #43AA88;
}

body.iryo .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.iryo .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.iryo .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.iryo .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.iryo .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.iryo .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #43AA88;
  border-bottom: 3px solid #43AA88;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.iryo .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.iryo .p-form-second form dl dd.checkbox label input:checked + span {
  color: #43AA88;
}

body.iryo .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.iryo .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.iryo .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.iryo .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.iryo .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.iryo .p-form-second form dl input#address {
  width: 100%;
}

body.iryo .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.iryo .p-form-second form dl textarea,
body.iryo .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.iryo .p-form-second form dl textarea:focus,
body.iryo .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.iryo .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.iryo .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.iryo .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.iryo .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.iryo .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.iryo .p-form-second form dl label input:focus,
body.iryo .p-form-second form dl label textarea:focus,
body.iryo .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.iryo .p-form-second form dl textarea {
  width: 100%;
}

body.iryo.page h1:not(.not) {
  font-size: 1.4375rem;
  background-color: #43AA88;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #fff;
}

body.iryo.page .h1 {
  font-size: 1.4375rem;
  background-color: #43AA88;
  text-align: center;
  padding: 1.3rem .5rem;
  margin: 1.25rem 0 1.875rem;
  color: #43AA88;
  font-weight: bold;
  background: repeating-linear-gradient(-45deg, rgba(67, 170, 136, 0.2), rgba(67, 170, 136, 0.2) 4px, #fff 0, #fff 6px);
}

body.iryo.page h2:not(.not) {
  font-size: 1.4375rem;
  padding-bottom: 1rem;
  background: linear-gradient(to right, #43AA88 0, #43AA88 30%, #E2E2E2 30%) no-repeat;
  background-size: 100% 6px;
  background-position: bottom;
  margin: 2.5rem 0 1.25rem;
}

body.iryo.page h3:not(.not) {
  font-size: 1.3125rem;
  background-color: #43AA88;
  padding: .4rem .4rem .4rem 1rem;
  margin: 1.875rem 0 1.25rem;
  color: #fff;
}

body.iryo.page h4:not(.not) {
  font-size: 1.3125rem;
  padding-bottom: 0.2rem;
  margin: 1.875rem 0 1.25rem;
  border-bottom: 2px dotted #212224;
}

body.iryo.page h5 {
  font-size: 1.25rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  border-left: 5px double #212224;
}

body.iryo.page h6 {
  font-size: 1.125rem;
  padding-left: 0.5rem;
  margin: 1.875rem 0 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
}

body.iryo.page h6:before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 3px;
  background-color: #212224;
  vertical-align: middle;
  margin-right: 0.3rem;
}

body.iryo.page.page02 h1 {
  background: repeating-linear-gradient(-45deg, rgba(67, 170, 136, 0.2), rgba(67, 170, 136, 0.2) 4px, #fff 0, #fff 6px);
  color: #43AA88;
  font-weight: 900;
}

body.iryo.page .p-link-block {
  max-width: 850px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

body.iryo.page .p-link-block .p-link-block__item {
  margin-bottom: 2rem;
  width: 100%;
}

body.iryo.page .p-link-block .p-link-block__link {
  transition: .2s;
  text-decoration: none;
  color: #212224;
  display: block;
}

body.iryo.page .p-link-block .p-link-block__link:hover {
  opacity: .7;
}

body.iryo.page .p-link-block .p-link-block__head {
  border: 1px solid #43AA88;
  border-radius: 13px;
  padding: 1rem 2.5rem 1rem 1rem;
  position: relative;
  font-size: 1.125rem;
  color: #43AA88;
}

body.iryo.page .p-link-block .p-link-block__head:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  border-bottom: 1px solid #43AA88;
  border-right: 1px solid #43AA88;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.iryo.page .p-link-block .p-link-block__head:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 19px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 1px;
  background-color: #43AA88;
}

body.iryo.page .p-link-block .p-link-block__description {
  padding: .5rem;
  font-size: 0.875rem;
}

body.iryo.page .p-more {
  position: relative;
  height: 20em;
  overflow: hidden;
}

body.iryo.page .p-more.is_full-show {
  height: auto;
  overflow: visible;
}

body.iryo.page .p-more .btn__wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

body.iryo.page .p-more .btn__wrap.is_show {
  display: none;
}

body.iryo.page .p-more .btn__wrap:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, white 100%);
  z-index: -1;
}

body.iryo.page .p-more .more-btn {
  position: relative;
  border-radius: 100px;
  border: 1px solid #43AA88;
  color: #43AA88;
  text-align: center;
  padding: .5rem 2rem;
  margin: auto;
  background-color: #fff;
  width: 240px;
  font-size: 1.25rem;
  display: block;
  transition: .2s;
}

body.iryo.page .p-more .more-btn:hover {
  opacity: .7;
}

body.iryo.page .p-more .more-btn.is_hidden {
  opacity: 0;
  visibility: hidden;
}

body.iryo.page .p-more .more-btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 20px;
  background-color: #43AA88;
}

body.iryo.page .p-more .more-btn:before {
  content: '';
  display: block;
  position: absolute;
  right: 26px;
  bottom: 16px;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: 1px solid #43AA88;
  border-right: 1px solid #43AA88;
}

body.iryo.page .p-btn {
  text-align: center;
}

body.iryo.page .p-btn a {
  text-decoration: none;
  position: relative;
  z-index: 20;
  display: inline-flex;
}

body.iryo.page .p-btn a:after, body.iryo.page .p-btn a:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 10;
  transition: .2s;
}

body.iryo.page .p-btn a:after {
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 3px;
  background-color: #212224;
}

body.iryo.page .p-btn a:before {
  right: 12px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg) translate(0px, 1px);
  transform: rotate(45deg) translate(0px, 1px);
  border-top: 3px solid #212224;
  border-right: 3px solid #212224;
}

body.iryo.page .p-btn a span {
  display: inline-block;
  position: relative;
  z-index: 10;
}

body.iryo.page .p-btn a span:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #212224;
  transition: .3s;
}

body.iryo.page .p-btn a span:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: calc(100% - 4px);
  width: 0;
  background-color: white;
  transition: .3s;
}

body.iryo.page .p-btn a .txt {
  color: #212224;
  font-weight: bold;
  min-width: 220px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: .7rem 2rem .7rem 1rem;
  background: #fff;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #212224;
}

body.iryo.page .p-btn a .chs {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -1;
  height: 0;
}

body.iryo.page .p-btn a .ch-head {
  display: block;
  opacity: 0;
  transition: .15s;
  visibility: hidden;
}

body.iryo.page .p-btn a .ch-head.ch-head01 {
  -webkit-transform: translate(40px, 80%);
  transform: translate(40px, 80%);
}

body.iryo.page .p-btn a .ch-head.ch-head02 {
  position: relative;
  z-index: 10;
  -webkit-transform: translate(0, 80%);
  transform: translate(0, 80%);
}

body.iryo.page .p-btn a .ch-head.ch-head03 {
  -webkit-transform: translate(-40px, 80%);
  transform: translate(-40px, 80%);
}

body.iryo.page .p-btn a:hover:after {
  right: 3px;
}

body.iryo.page .p-btn a:hover:before {
  right: 1px;
}

body.iryo.page .p-btn a:hover .ch-head {
  opacity: 1;
  visibility: visible;
}

body.iryo.page .p-btn a:hover .ch-head.ch-head01 {
  -webkit-transform: translate(15px, 0);
  transform: translate(15px, 0);
}

@-moz-document url-prefix() {
  body.iryo.page .p-btn a:hover .ch-head.ch-head01 {
    transform: translate(15px, 0);
  }
}

body.iryo.page .p-btn a:hover .ch-head.ch-head02 {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

@-moz-document url-prefix() {
  body.iryo.page .p-btn a:hover .ch-head.ch-head02 {
    transform: translate(0, 1px);
  }
}

body.iryo.page .p-btn a:hover .ch-head.ch-head03 {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
}

@-moz-document url-prefix() {
  body.iryo.page .p-btn a:hover .ch-head.ch-head03 {
    transform: translate(-15px, 1px);
  }
}

body.iryo.page .p-btn a:hover span:after {
  transition: .2s ease-in-out .1s;
  opacity: 0;
  -webkit-transform: translateX(70px) rotate(2turn);
  transform: translateX(70px) rotate(2turn);
}

body.iryo.page .p-btn a:hover span:before {
  transition: .2s ease-in-out .1s;
  width: 20px;
}

body.iryo.page .p-btn.not-arrow a .txt {
  padding: 0.7rem 1rem;
}

body.iryo.page .p-btn.not-arrow a:after, body.iryo.page .p-btn.not-arrow a:before {
  content: none;
}

body.iryo.page .p-btn.p-btn--lower a {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.15);
}

body.iryo.page .p-btn.p-btn--lower a .txt {
  border-width: 2px;
  border-color: white;
}

body.iryo.page .p-btn.p-btn--lower a:after, body.iryo.page .p-btn.p-btn--lower a:before {
  right: 10px;
}

body.iryo.page .p-btn.p-btn--lower a:after {
  height: 1px;
}

body.iryo.page .p-btn.p-btn--lower a:before {
  border-width: 1px;
}

body.iryo.page .p-btn.p-btn--lower a:hover:after, body.iryo.page .p-btn.p-btn--lower a:hover:before {
  right: 6px;
}

body.iryo.page .p-btn.p-btn--lower a .txt:not(.not) {
  min-width: 220px;
}

body.iryo.page .p-btn.p-btn--lower.p-btn--back a .txt {
  padding: .7rem 1rem .7rem 2rem;
}

body.iryo.page .p-btn.p-btn--lower.p-btn--back a:after {
  right: auto;
  left: 9px;
}

body.iryo.page .p-btn.p-btn--lower.p-btn--back a:before {
  right: auto;
  left: 8px;
  top: -1px;
  -webkit-transform: rotate(-135deg) translate(-1px, 1px);
  transform: rotate(-135deg) translate(-1px, 1px);
}

body.iryo.page .p-btn.p-btn--lower.p-btn--dark a:before {
  border-width: 1px;
}

body.iryo.page .p-btn.p-btn--lower.p-btn--school a .txt {
  color: white;
  background-color: #43AA88;
}

body.iryo.page .p-btn.p-btn--lower.p-btn--school a:after {
  background-color: white;
}

body.iryo.page .p-btn.p-btn--lower.p-btn--school a:after, body.iryo.page .p-btn.p-btn--lower.p-btn--school a:before {
  border-color: white;
}

body.iryo.page .p-btn.p-btn--lower.p-btn--lg a .txt {
  padding: 1.2rem 2rem 1.2rem 1rem;
  min-width: 250px;
}

body.iryo.page .p-btn.p-btn--gradient a .txt {
  border-color: white;
  color: white;
  background: linear-gradient(135deg, #ec88de 0%, #82e8f5 100%);
}

body.iryo.page .p-btn.p-btn--gradient a:before {
  border-color: #fff;
}

body.iryo.page .p-btn.p-btn--gradient a:after {
  background-color: #fff;
}

body.iryo.page .p-btn.p-btn--gradient a span:after {
  background-color: #fff;
}

body.iryo.page .p-btn.p-btn--dark a .txt {
  color: white;
  border-color: white;
  background-color: #333;
}

body.iryo.page .p-btn.p-btn--dark a:after {
  background-color: white;
}

body.iryo.page .p-btn.p-btn--dark a:before {
  border-top: 3px solid white;
  border-right: 3px solid white;
}

body.iryo.page .p-btn.p-btn--dark a span:after {
  background-color: white;
}

body.iryo.page .p-btn.p-btn--dark a span:before {
  background-color: #333;
}

body.iryo.page .p-form-second ::-webkit-input-placeholder {
  color: #adb5bd;
}

body.iryo.page .p-form-second ::-moz-placeholder {
  color: #adb5bd;
}

body.iryo.page .p-form-second :-ms-input-placeholder {
  color: #adb5bd;
}

body.iryo.page .p-form-second ::-ms-input-placeholder {
  color: #adb5bd;
}

body.iryo.page .p-form-second ::placeholder {
  color: #adb5bd;
}

body.iryo.page .p-form-second .p-next-btn__wrap button,
body.iryo.page .p-form-second .p-back-btn__wrap button {
  padding: 0.7rem 0.5rem;
}

body.iryo.page .p-form-second .privacy {
  text-align: center;
}

body.iryo.page .p-form-second .privacy input {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

body.iryo.page .p-form-second .privacy label {
  margin-right: 1rem;
  cursor: pointer;
}

body.iryo.page .p-form-second form {
  font-size: 1rem;
}

body.iryo.page .p-form-second form button {
  min-width: 190px;
}

body.iryo.page .p-form-second form dl dt,
body.iryo.page .p-form-second form dl dd {
  padding: 1rem 1rem 1rem 0;
}

body.iryo.page .p-form-second form dl dt {
  font-size: 1.25rem;
  border-bottom: 3px solid #D8D8D8;
  position: relative;
  padding: 0;
}

body.iryo.page .p-form-second form dl dt:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 20%;
  height: 3px;
  background-color: #43AA88;
}

body.iryo.page .p-form-second form dl dt label {
  display: flex;
  align-items: center;
}

body.iryo.page .p-form-second form dl dt .badge {
  margin-left: .5rem;
  color: #fff;
  background: #dc3545;
  font-size: 0.6875rem;
  border-radius: 0;
  padding: 0.3rem .6rem;
}

body.iryo.page .p-form-second form dl dd {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

body.iryo.page .p-form-second form dl dd.radio label {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

body.iryo.page .p-form-second form dl dd.radio label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.4rem 0.2rem 0.4rem 20px;
}

body.iryo.page .p-form-second form dl dd.radio label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.iryo.page .p-form-second form dl dd.radio label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  background-color: #43AA88;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: .15s;
}

body.iryo.page .p-form-second form dl dd.radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.iryo.page .p-form-second form dl dd.radio label input:checked + span {
  color: #43AA88;
}

body.iryo.page .p-form-second form dl dd.radio label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.iryo.page .p-form-second form dl dd.radio label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.iryo.page .p-form-second form dl dd.checkbox label {
  margin-right: 1rem;
  cursor: pointer;
}

body.iryo.page .p-form-second form dl dd.checkbox label span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.iryo.page .p-form-second form dl dd.checkbox label span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.iryo.page .p-form-second form dl dd.checkbox label span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #43AA88;
  border-bottom: 3px solid #43AA88;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.iryo.page .p-form-second form dl dd.checkbox label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.iryo.page .p-form-second form dl dd.checkbox label input:checked + span {
  color: #43AA88;
}

body.iryo.page .p-form-second form dl dd.checkbox label input:checked + span:after {
  opacity: 1;
  visibility: visible;
  -webkit-animation: checkScale .15s forwards;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: rotate(45deg) scale(1.4);
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

body.iryo.page .p-form-second form dl dd.checkbox label input:focus + span {
  outline: 3px solid #adb5bd;
}

body.iryo.page .p-form-second form dl input {
  border: 1px solid #adb5bd;
  padding: 5px;
  height: 50px;
}

body.iryo.page .p-form-second form dl input[type="radio"] {
  margin-right: 5px;
}

body.iryo.page .p-form-second form dl input[type="email"] {
  width: 70%;
}

body.iryo.page .p-form-second form dl input#address {
  width: 100%;
}

body.iryo.page .p-form-second form dl input:focus {
  outline: 3px solid #ced4da;
}

body.iryo.page .p-form-second form dl textarea,
body.iryo.page .p-form-second form dl select {
  border: 1px solid #adb5bd;
  background-color: #fff;
}

body.iryo.page .p-form-second form dl textarea:focus,
body.iryo.page .p-form-second form dl select:focus {
  outline: 3px solid #ced4da;
}

body.iryo.page .p-form-second form dl .select__wrap {
  border: 1px solid #adb5bd;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  height: 50px;
  background-color: #fff;
}

body.iryo.page .p-form-second form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  pointer-events: none;
}

body.iryo.page .p-form-second form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: .5rem;
  height: 50px;
}

@supports (-ms-ime-align: auto) {
  body.iryo.page .p-form-second form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.iryo.page .p-form-second form dl label {
  margin-bottom: 0;
  position: relative;
}

body.iryo.page .p-form-second form dl label input:focus,
body.iryo.page .p-form-second form dl label textarea:focus,
body.iryo.page .p-form-second form dl label select:focus {
  outline: 3px solid #ced4da;
}

body.iryo.page .p-form-second form dl textarea {
  width: 100%;
}

body.single .news-cat {
  min-width: 115px;
  color: #fff;
  font-weight: bold;
  font-size: .875rem;
  background-color: #333;
  padding: 0.2rem 0.5rem;
  margin-left: 1rem;
}

.p-attention {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.p-attention .p-attention__inner {
  position: relative;
}

.p-attention .p-attention__inner:after, .p-attention .p-attention__inner:before {
  content: '';
  display: block;
  width: 10px;
  height: 1.5em;
  border-width: 0 3px;
  border-style: solid;
  border-color: #212224;
  position: absolute;
  bottom: 0;
}

.p-attention .p-attention__inner:after {
  right: -1.5em;
  -webkit-transform: skewX(-35deg);
  transform: skewX(-35deg);
}

.p-attention .p-attention__inner:before {
  left: -1.5em;
  -webkit-transform: skewX(35deg);
  transform: skewX(35deg);
}

.p-attention.p-attention--01 {
  color: #DB1966;
}

.p-attention.p-attention--01 .p-attention__inner:after, .p-attention.p-attention--01 .p-attention__inner:before {
  border-color: #DB1966;
}

.p-attention.p-attention--02 {
  color: #FF7603;
}

.p-attention.p-attention--02 .p-attention__inner:after, .p-attention.p-attention--02 .p-attention__inner:before {
  border-color: #FF7603;
}

.p-attention.p-attention--03 {
  color: #43AA88;
}

.p-attention.p-attention--03 .p-attention__inner:after, .p-attention.p-attention--03 .p-attention__inner:before {
  border-color: #43AA88;
}

.p-order .order__elm {
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
}

.p-order.is_scrolled .order__elm {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.p-order.is_scrolled .order__elm:nth-of-type(1) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.1s;
}

.p-order.is_scrolled .order__elm:nth-of-type(2) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.2s;
}

.p-order.is_scrolled .order__elm:nth-of-type(3) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.3s;
}

.p-order.is_scrolled .order__elm:nth-of-type(4) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.4s;
}

.p-order.is_scrolled .order__elm:nth-of-type(5) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.5s;
}

.p-order.is_scrolled .order__elm:nth-of-type(6) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.6s;
}

.p-order.is_scrolled .order__elm:nth-of-type(7) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.7s;
}

.p-order.is_scrolled .order__elm:nth-of-type(8) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.8s;
}

.p-order.is_scrolled .order__elm:nth-of-type(9) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 0.9s;
}

.p-order.is_scrolled .order__elm:nth-of-type(10) {
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.5) 1s;
}

.p-open-img {
  position: relative;
  overflow: hidden;
}

.p-open-img:after, .p-open-img:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 49%;
  height: 150%;
}

.p-open-img:after {
  left: 0;
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.p-open-img:before {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  right: 0;
}

.p-open-img .line {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 2%;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.p-open-img.is_scrolled:after, .p-open-img.is_scrolled:before {
  transition: -webkit-transform .4s ease .5s;
  transition: transform .4s ease .5s;
  transition: transform .4s ease .5s, -webkit-transform .4s ease .5s;
}

.p-open-img.is_scrolled:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.p-open-img.is_scrolled:before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.p-open-img.is_scrolled .line {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  transition: -webkit-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s;
}

.p-show {
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
}

.p-show.is_scrolled {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: 2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
}

.p-showY {
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
}

.p-showY.is_scrolled {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: 2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
}

.p-showY-reverse {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  opacity: 0;
}

.p-showY-reverse.is_scrolled {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: 2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
}

.p-showX {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  opacity: 0;
}

.p-showX.is_scrolled {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: 2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
}

.p-showX-reverse {
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
}

.p-showX-reverse.is_scrolled {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: 2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
}

.p-target-menu {
  color: white;
}

.p-target-menu ul {
  max-width: 880px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid white;
}

.p-target-menu h2,
.p-target-menu h3 {
  font-size: 1.5625rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.p-target-menu li {
  width: calc(100% / 3);
}

.p-target-menu a {
  border: 1px solid white;
  color: white;
  text-decoration: none;
  font-weight: bold;
  display: block;
  position: relative;
  padding: .5rem;
  transition: .2s;
}

.p-target-menu a:after {
  content: '';
  display: block;
  position: absolute;
  right: 10px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 17px;
  height: 1px;
  background-color: #fff;
}

.p-target-menu a:before {
  content: '';
  display: block;
  position: absolute;
  right: 11px;
  top: -1px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.p-target-menu a:hover {
  background-color: #fff;
  color: #47B2B0;
}

.p-target-menu a:hover:after {
  background-color: #47B2B0;
}

.p-target-menu a:hover:before {
  border-color: #47B2B0;
}

.p-now-future {
  background-color: #fff;
}

.p-now-future a {
  position: relative;
}

.p-now-future a:after, .p-now-future a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: white;
}

.p-now-future a:after {
  left: 50%;
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.p-now-future a:before {
  right: 50%;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.p-now-future a.is_scrolled:after, .p-now-future a.is_scrolled:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: 0.4s cubic-bezier(0.72, -0.01, 0.11, 1);
}

.p-instagram .intro {
  font-size: 1.5rem;
}

.p-instagram .head__wrap, .p-instagram body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .p-instagram .head__wrap, .p-instagram body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 .p-instagram .head__wrap, .p-instagram body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 .p-instagram .head__wrap {
  color: white;
}

.p-instagram .insta-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin: auto auto 5rem;
}

.p-instagram .insta-list li {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  width: calc(100% / 3);
}

.p-instagram .insta-list li a {
  padding-top: 100%;
  position: relative;
  display: block;
  overflow: hidden;
}

.p-instagram .insta-list li a img {
  position: absolute;
  left: 0;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover;';
}

.p-instagram .insta-list li.instagram__album a:after {
  content: '';
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  background: url(../img/common/icon_insta-album.svg) no-repeat;
  background-size: cover;
  width: 15px;
  height: 15px;
  opacity: .8;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(1) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.11111s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(2) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.22222s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(3) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.33333s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(4) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.44444s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(5) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.55556s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(6) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.66667s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(7) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.77778s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(8) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 0.88889s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(9) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 1s;
}

.p-instagram .insta-list.is_scrolled li:nth-of-type(10) {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: 0.7s cubic-bezier(0.72, -0.01, 0.02, 1.41) 1.11111s;
}

.p-access {
  padding: 5rem 0 8rem;
}

.p-access .gmap {
  max-width: 900px;
  margin: auto;
}

.p-info {
  background-color: #47B2B0;
}

.p-info .container .head__wrap, .p-info .container body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .p-info .container .head__wrap, .p-info .container body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 .p-info .container .head__wrap, .p-info .container body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 .p-info .container .head__wrap {
  color: #FCF25D;
}

.p-info .bg-elm {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: -4rem auto 0;
}

.p-info .inner {
  max-width: 440px;
  margin: auto;
  color: white;
  text-align: center;
}

.p-info .list-elm {
  position: relative;
}

.p-info .text-elm {
  font-weight: bold;
  position: relative;
  z-index: 5;
}

.p-info .text-elm.text-elm--01 {
  font-size: 2.1875rem;
  line-height: 1.4;
  margin-bottom: 3.3125rem;
}

.p-info .text-elm.text-elm--02 {
  font-size: 1rem;
  margin-bottom: 4.375rem;
}

.p-info .text-elm.text-elm--03 {
  font-size: 2.1875rem;
  line-height: 1.4;
  margin-bottom: 3.3125rem;
}

.p-info .img-elm.img-elm--02 {
  margin-bottom: 3.75rem;
}

.p-news__lower .head {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  background-color: #FCF25D;
  padding: .7rem;
  margin-bottom: 3rem;
}

.p-news__lower .head.icon-news, .p-news__lower .head.icon-entrance {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.p-news__lower .head.icon-news::before, .p-news__lower .head.icon-entrance::before {
  content: '';
  display: inline-block;
}

.p-news__lower .head.icon-news::before {
  width: 22px;
  height: 20px;
  background: url(../img/common/icon-news.svg) center/cover no-repeat;
}

.p-news__lower .head.icon-entrance::before {
  width: 37px;
  height: 22px;
  background: url(../img/common/icon-entrance.svg) center/cover no-repeat;
}

.p-news__lower .new {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-20%, -50%);
  transform: translate(-20%, -50%);
  color: white;
  background: linear-gradient(to bottom, #5E7FDC 0%, #ED87DE 100%);
  border-radius: 50%;
  display: inline-block;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  font-size: 1rem;
}

.p-news__lower .news__wrap .news__block {
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(33, 34, 36, 0.15);
}

.p-news__lower .news__block--entrance .head {
  background-color: #47B2B0;
  color: white;
}

.p-news__lower .news__block--gray .head {
  background-color: #5A5A5A;
  color: white;
}

.p-news__lower .news__lists a {
  display: flex;
  align-items: flex-start;
  color: #212224;
  transition: .2s;
  text-decoration: none;
}

.p-news__lower .news__lists a:hover {
  opacity: .7;
}

.p-news__lower .news__lists li {
  padding: 0 2.5rem 2rem;
  margin: 0 2rem 2rem;
}

.p-news__lower .news__lists li:not(:last-of-type) {
  border-bottom: 1px dashed #333;
}

.p-news__lower .thumb {
  position: relative;
  padding-top: 21%;
  width: 35%;
}

.p-news__lower .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

.p-news__lower time {
  margin-bottom: 1rem;
}

.p-news__lower .title {
  margin-bottom: 0;
  font-size: 1rem;
}

.p-news__lower .content {
  width: 65%;
  padding-left: 2rem;
}

.p-news {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.p-news .new {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: white;
  background: linear-gradient(to bottom, #5E7FDC 0%, #ED87DE 100%);
  border-radius: 50%;
  display: inline-block;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  font-size: 1.125rem;
}

.p-news a {
  text-decoration: none;
  color: #212224;
  display: block;
}

.p-news a:hover {
  opacity: .8;
}

.p-news li {
  position: relative;
  margin-top: 40px;
}

.p-news time {
  font-weight: bold;
}

.p-news time .date {
  font-size: 1rem;
}

.p-news .thumb {
  position: relative;
  padding-top: 62.5%;
}

.p-news .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

.p-news .study-cat {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 115px;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: #333;
  padding: .2rem .5rem;
}

.p-news figcaption {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  min-height: 180px;
}

.p-news .school-cat--01 figure {
  border-bottom: 5px solid #DB76A4;
}

.p-news .school-cat--02 figure {
  border-bottom: 5px solid #fd7e14;
}

.p-news .school-cat--03 figure {
  border-bottom: 5px solid #9AC144;
}

.p-news__archive {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.p-news__archive .news__lists {
  display: flex;
  flex-wrap: wrap;
}

.p-news__archive .tab-contents {
  width: 100%;
}

.p-news__archive .new {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: white;
  background: linear-gradient(to bottom, #5E7FDC 0%, #ED87DE 100%);
  border-radius: 50%;
  display: inline-block;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  font-size: 1.125rem;
}

.p-news__archive a {
  text-decoration: none;
  color: #212224;
  display: block;
}

.p-news__archive a:hover {
  opacity: .8;
}

.p-news__archive li {
  position: relative;
  margin-top: 40px;
}

.p-news__archive time {
  font-weight: bold;
}

.p-news__archive time .date {
  font-size: 1rem;
}

.p-news__archive .thumb {
  position: relative;
  padding-top: 62.5%;
}

.p-news__archive .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

.p-news__archive .study-cat {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 115px;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: #333;
  padding: .2rem .5rem;
}

.p-news__archive figcaption {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  min-height: 180px;
}

.p-news__archive .tab-btns {
  display: flex;
  margin-bottom: 6rem;
}

.p-news__archive .tab-btns button {
  width: 25%;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: .2s;
  text-decoration: none;
  position: relative;
}

.p-news__archive .tab-btns button:hover {
  opacity: .7;
}

.p-news__archive .tab-btns button.is_active:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-width: 15px 10px 0;
  border-style: solid;
}

.p-news__archive .tab-btns button.is_active.tab-btn--01:after {
  border-color: #212224 transparent transparent transparent;
}

.p-news__archive .tab-btns button.is_active.tab-btn--02:after {
  border-color: #E85298 transparent transparent transparent;
}

.p-news__archive .tab-btns button.is_active.tab-btn--03:after {
  border-color: #FF7603 transparent transparent transparent;
}

.p-news__archive .tab-btns button.is_active.tab-btn--04:after {
  border-color: #43AA88 transparent transparent transparent;
}

.p-news__archive.p-news__archive--02 .tab-btns button.is_active.tab-btn--02:after {
  border-color: #fcf25d transparent transparent transparent;
}

.p-news__archive.p-news__archive--02 .tab-btns button.is_active.tab-btn--03:after {
  border-color: #12a2b3 transparent transparent transparent;
}

.p-news__archive .content {
  margin-top: -5rem;
  margin-bottom: 5rem;
  transition: height .6s ease-in-out;
  position: relative;
  display: none;
}

.p-news__archive .content.is_show {
  display: block !important;
  -webkit-animation: tab-show .5s linear 0s;
  animation: tab-show .5s linear 0s;
}

@keyframes tab-show {
  from {
    opacity: 0;
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.p-news__archive .school-cat--01 figure {
  border-bottom: 5px solid #DB76A4;
}

.p-news__archive .school-cat--02 figure {
  border-bottom: 5px solid #fd7e14;
}

.p-news__archive .school-cat--03 figure {
  border-bottom: 5px solid #9AC144;
}

.p-aside01 .head {
  font-size: 1.625rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(45deg, #03B2FF 0%, #4AEAFF 100%);
  padding: .7rem;
  margin-bottom: 1rem;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}

.p-aside01 .aside01__content .twitter__link {
  transition: .2s;
  text-decoration: none;
  color: #212224;
}

.p-aside01 .aside01__content .twitter__link:hover {
  opacity: .7;
}

.p-aside01 .aside01__content .thumb,
.p-aside01 .aside01__content .content {
  width: 100%;
}

.p-aside01 .aside01__block__inner {
  padding: 0 1.875rem 2.1875rem;
}

.p-aside01 .aside01__wrap .aside01__block.aside01__block--twitter .inner {
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(33, 34, 36, 0.15);
}

.p-aside01 .thumb {
  position: relative;
  padding-top: 38%;
  height: 0;
}

.p-aside01 .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'object-fit: cover;';
}

.p-aside01 time {
  margin-bottom: 1rem;
}

.p-aside01 .title {
  margin-bottom: 0;
  font-size: 1rem;
}

.p-aside01 .content {
  width: 65%;
  padding-left: 1rem;
}

.p-aside02 .aside02__wrap .aside02__block {
  position: relative;
  width: calc(100% - 10px);
}

.p-aside02 .aside02__wrap .aside02__block:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(20px, 20px);
  transform: translate(20px, 20px);
  background: linear-gradient(to bottom, #82e8f5 0%, #ed87de 100%);
  z-index: -1;
}

.p-aside02 .flag.flag--01 {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.p-aside02 .flag.flag--02 {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

body.htc .p-aside02 .aside02__wrap .aside02__block:after {
  background: linear-gradient(to bottom, #92D514 0%, #00BAB4 100%);
}

body.iryo .p-aside02 .aside02__wrap .aside02__block:after {
  background: linear-gradient(180deg, #62B7FF 0%, #BBACFA 50%, #FFA3A7 100%);
}

.p-campus {
  position: relative;
}

.p-campus .p-gradient {
  font-weight: bold;
  font-size: 1.875rem;
}

.p-campus .p-gradient .gradient__inner {
  position: relative;
  padding: 0.3rem 2rem;
  color: white;
}

.p-campus .p-gradient .gradient__inner:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, #ed87de 0%, #82e8f5 100%);
}

.p-campus .p-gradient .gradient__text {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

.p-campus .p-gradient.is_scrolled .gradient__inner:before {
  width: 100%;
  transition: width .5s;
}

.p-campus .p-gradient.is_scrolled .gradient__text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 1s ease .3s;
}

.p-campus .img__block {
  margin-top: 5rem;
}

.p-oc {
  background: url(../img/pages/bg_oc01.jpg) no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.p-oc .oc__btn {
  z-index: 10;
  display: inline-block;
  position: relative;
}

.p-oc .oc__btn .chs {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -1;
  visibility: hidden;
}

.p-oc .oc__btn .ch-head {
  display: block;
  opacity: 0;
  transition: .2s;
}

.p-oc .oc__btn .ch-head.ch-head01 {
  -webkit-transform: translate(40px, 80%);
  transform: translate(40px, 80%);
}

.p-oc .oc__btn .ch-head.ch-head02 {
  position: relative;
  z-index: 10;
  -webkit-transform: translate(0, 80%);
  transform: translate(0, 80%);
}

.p-oc .oc__btn .ch-head.ch-head03 {
  -webkit-transform: translate(-40px, 80%);
  transform: translate(-40px, 80%);
}

.p-oc .oc__btn:hover .chs {
  visibility: visible;
}

.p-oc .oc__btn:hover:after {
  right: 2px;
}

.p-oc .oc__btn:hover:before {
  right: -1px;
}

.p-oc .oc__btn:hover .ch-head {
  opacity: 1;
}

.p-oc .oc__btn:hover .ch-head.ch-head01 {
  -webkit-transform: translate(15px, 0);
  transform: translate(15px, 0);
}

.p-oc .oc__btn:hover .ch-head.ch-head02 {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.p-oc .oc__btn:hover .ch-head.ch-head03 {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
}

.p-oc .head__wrap, .p-oc body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .p-oc .head__wrap, .p-oc body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 .p-oc .head__wrap, .p-oc body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 .p-oc .head__wrap {
  color: white;
}

.p-oc .point__elm01 {
  position: absolute;
  left: 28%;
  top: -1%;
}

.p-oc .point__elm02 {
  position: absolute;
  right: 28%;
  top: -3%;
}

.p-oc .point__elm03 {
  position: absolute;
  left: -2%;
  top: -5%;
}

.p-oc .ch-elm01 {
  position: absolute;
  bottom: -10px;
  z-index: 10;
  left: -30px;
}

.p-oc .ch-elm02 {
  position: absolute;
  bottom: -10px;
  z-index: 10;
  right: -30px;
}

.p-oc .item-01 .pic__01 {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.p-oc .item-01 .pic__01.is_scrolled {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: -webkit-transform 0.4s cubic-bezier(0.42, 0, 0.37, 2);
  transition: transform 0.4s cubic-bezier(0.42, 0, 0.37, 2);
  transition: transform 0.4s cubic-bezier(0.42, 0, 0.37, 2), -webkit-transform 0.4s cubic-bezier(0.42, 0, 0.37, 2);
}

.p-oc .item-02 {
  margin-bottom: 2.5rem;
  overflow: hidden;
}

.p-oc .item-02 strong {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  font-size: 2.1875rem;
  font-weight: bold;
  text-align: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  color: white;
  display: inline-block;
  margin: 1px auto;
}

.p-oc .item-02 strong span {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  display: inline-block;
}

.p-oc .item-02.is_scrolled strong {
  transition: -webkit-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.p-oc .item-03 {
  color: white;
  margin-bottom: 2.5rem;
  font-size: 0.875rem;
}

.p-oc .item-04 {
  margin-bottom: 2.5rem;
}

@-webkit-keyframes jump-ch {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jump-ch {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes seesaw {
  from {
    -webkit-transform: rotate(-0.5deg);
    transform: rotate(-0.5deg);
  }
  to {
    -webkit-transform: rotate(0.5deg);
    transform: rotate(0.5deg);
  }
}

@keyframes seesaw {
  from {
    -webkit-transform: rotate(-0.5deg);
    transform: rotate(-0.5deg);
  }
  to {
    -webkit-transform: rotate(0.5deg);
    transform: rotate(0.5deg);
  }
}

.p-oc .item-04.is_scrolled .item-04__main {
  -webkit-animation: seesaw 1.5s linear infinite alternate;
  animation: seesaw 1.5s linear infinite alternate;
}

.p-oc .item-04.is_scrolled .ch-elm01 {
  display: inline-block;
  -webkit-animation: jump-ch 3s ease-in-out infinite;
  animation: jump-ch 3s ease-in-out infinite;
}

.p-oc .item-04.is_scrolled .ch-elm02 {
  display: inline-block;
  -webkit-animation: jump-ch 3s ease-in-out infinite 1.5s;
  animation: jump-ch 3s ease-in-out infinite 1.5s;
}

.p-oc .point-lists {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  max-width: 1155px;
  margin: auto;
  margin-bottom: 3.75rem;
}

.p-oc .point-lists li {
  background-color: #FCF25D;
  border-radius: 13px;
  text-align: center;
  font-size: 1.125rem;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: calc( (100% - 9%) / 4);
}

.p-oc .point-lists li span {
  width: 100%;
  font-size: 0.8125rem;
}

.p-employment li {
  border-radius: 13px;
  margin-bottom: 1.5rem;
}

.p-employment a {
  position: relative;
  color: white;
  display: block;
  border-radius: 13px;
  overflow: hidden;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.p-employment a img {
  transition: -webkit-filter .2s;
  transition: filter .2s;
  transition: filter .2s, -webkit-filter .2s;
}

.p-employment a:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.p-employment a:hover img {
  opacity: 1;
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

.p-employment .title {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: red;
  padding: 1rem .5rem;
  text-align: center;
  font-weight: bold;
  width: 100%;
}

.p-video-content#p-video-content {
  background-color: transparent;
  padding: 56.25% 0 0;
  position: relative;
}

.p-video-content .play-btn {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: none;
}

.p-video-content .play-btn.is_hidden {
  opacity: 0;
  visibility: hidden;
  transition: .2s;
}

.p-video-content #video {
  position: absolute;
  left: 0;
  top: 0;
}

.banner__intext {
  padding-top: 50px;
}

.banner__intext span {
  position: absolute;
  top: -50px;
  left: 50%;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.banner__intext a {
  position: relative;
}

#p-img-content {
  background-color: transparent;
  height: 62vh;
  position: relative;
}

#p-img-content #image {
  pointer-events: none;
  position: fixed;
  inset: 0;
  z-index: -1;
}

#p-img-content #image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center top;
  object-position: center top;
}

.banner__reserve {
  position: relative;
  z-index: 10;
}

.banner__reserve a {
  position: relative;
  display: inline-block;
  z-index: 10;
  transition: .2s;
}

.banner__reserve a:hover {
  opacity: 1;
  -webkit-transform: scale(1) rotate(3deg);
  transform: scale(1) rotate(3deg);
}

.banner__reserve a:hover img {
  opacity: 1;
}

.banner__reserve .human {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.banner__reserve .text {
  width: 100%;
  max-width: 285px;
  position: relative;
  z-index: -1;
  opacity: 0;
  margin-bottom: 10px;
  -webkit-transform: translateY(200%);
  transform: translateY(200%);
}

.banner__reserve ul {
  position: absolute;
  left: 0;
  top: -30px;
  display: flex;
  z-index: -1;
  width: 100%;
}

.banner__reserve ul li {
  margin-left: 0.5rem;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.banner__reserve ul li:not(:nth-of-type(4)) {
  width: 13%;
  max-width: 47px;
}

.banner__reserve ul li:nth-of-type(4) {
  width: 13%;
  max-width: 56px;
}

.banner__reserve.is_scrolled .human {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: 0.4s cubic-bezier(0.42, 0, 0.37, 2) 1.05s;
}

.banner__reserve.is_scrolled .text {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: 0.4s cubic-bezier(0.42, 0, 0.37, 2);
}

.banner__reserve.is_scrolled ul li {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.banner__reserve.is_scrolled ul li:nth-of-type(1) {
  transition: 0.2s ease 0.16667s;
}

.banner__reserve.is_scrolled ul li:nth-of-type(2) {
  transition: 0.2s ease 0.33333s;
}

.banner__reserve.is_scrolled ul li:nth-of-type(3) {
  transition: 0.2s ease 0.5s;
}

.banner__reserve.is_scrolled ul li:nth-of-type(4) {
  transition: 0.2s ease 0.66667s;
}

.banner__reserve.is_scrolled ul li:nth-of-type(5) {
  transition: 0.2s ease 0.83333s;
}

.banner__doc a {
  transition: .2s;
  display: inline-block;
}

.banner__doc a:hover {
  opacity: 1;
  -webkit-transform: scale(1) rotate(3deg);
  transform: scale(1) rotate(3deg);
}

.banner__doc a:hover img {
  opacity: 1;
}

.banner__doc a .text {
  width: 100%;
  max-width: 354px;
  position: relative;
  z-index: -1;
  opacity: 0;
  margin-bottom: 10px;
  -webkit-transform: translateY(200%);
  transform: translateY(200%);
}

.banner__doc.is_scrolled .text {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  z-index: 0;
  transition: 0.4s cubic-bezier(0.42, 0, 0.37, 2);
}

.cv__btns {
  position: fixed;
  display: flex;
  text-align: center;
  margin: 0;
  z-index: 99;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  top: 100px;
  right: 0;
}

.cv__btns a {
  transition: .2s;
  text-decoration: none;
  font-size: 1.0625rem;
  justify-content: center;
  display: flex;
  align-items: center;
  line-height: 1;
}

.cv__btns a:hover {
  opacity: .7;
}

.cv__btns .document span {
  word-break: keep-all;
}

.cv__btns .document a {
  color: #47B2B0;
  border: 2px solid #47B2B0;
  background-color: white;
  padding: 1rem .5rem;
}

.cv__btns .entry a {
  color: #212224;
  background-color: #DB76A4;
  border: 2px solid white;
  padding: 1rem .5rem;
}

/* ↓ オープンキャンパス各校用 ↓ */
.opencampus.hsc .cv__btns .document a {
  color: #E85298;
  border-color: #E85298;
}

/* ↑ オープンキャンパス各校用 ↑ */
.cv__btns--sp {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  margin-bottom: 0;
  text-align: center;
  transition: .4s;
}

.cv__btns--sp.is_show {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.cv__btns--sp a {
  color: white;
  font-size: 0.625rem;
  font-weight: bold;
  display: block;
  letter-spacing: 4px;
  padding: 0.5rem 0.2rem;
}

.cv__btns--sp li {
  width: calc(60% / 3);
}

.cv__btns--sp li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.cv__btns--sp li.document a {
  background-color: #47B2B0;
}

.cv__btns--sp li.inquiry a {
  background-color: #1867D3;
}

.cv__btns--sp li.qa a {
  background-color: #06C755;
}

.cv__btns--sp li.entry {
  width: 40%;
}

.cv__btns--sp li.entry a {
  background-color: #DB76A4;
}

.wrap-open.scroll_target {
  position: relative;
}

.wrap-open.scroll_target::after {
  content: '';
  display: inline-block;
  background-color: #fff;
  position: absolute;
  inset: 0;
  transition: right 1s cubic-bezier(0.72, 0.02, 0.26, 1);
}

.wrap-open.scroll_target.is_scrolled::after {
  right: 100%;
}

.order-show.scroll_target > * {
  opacity: 0;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  transition: opacity 0.3s ease, -webkit-transform 1s ease;
  transition: transform 1s ease, opacity 0.3s ease;
  transition: transform 1s ease, opacity 0.3s ease, -webkit-transform 1s ease;
}

.order-show.scroll_target > .show1 {
  transition-delay: calc(1 * 0.2s);
}

.order-show.scroll_target > .show2 {
  transition-delay: calc(2 * 0.2s);
}

.order-show.scroll_target > .show3 {
  transition-delay: calc(3 * 0.2s);
}

.order-show.scroll_target > .show4 {
  transition-delay: calc(4 * 0.2s);
}

.order-show.scroll_target > .show5 {
  transition-delay: calc(5 * 0.2s);
}

.order-show.scroll_target.is_scrolled > * {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.d-pc {
  display: none;
}

body.now-future {
  font-family: "notosansJP", sans-serif;
}

body.now-future .inner-link {
  color: #1A1311;
  text-decoration: none;
}

body.now-future .inner-link:hover {
  opacity: 1;
}

body.now-future .inner-link:hover img {
  opacity: 1;
}

body.now-future .nf-hero {
  width: 100vw;
  height: 0;
  padding-top: 120vw;
  background-image: url("../img/lp/now-future/bg-item-sp_01.jpg");
  background-position: top 0 left 0;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

body.now-future .nf-hero-star img {
  position: absolute;
}

body.now-future .nf-hero-star img:nth-of-type(1) {
  width: 5.97067vw;
  height: 7.624vw;
  top: 3.06667vw;
  left: 3.62667vw;
  max-width: 33.86px;
  max-height: 43.25px;
}

body.now-future .nf-hero-star img:nth-of-type(2) {
  width: 4.73067vw;
  height: 6.072vw;
  bottom: 26.64vw;
  left: 4.88vw;
}

body.now-future .nf-hero-star img:nth-of-type(3) {
  width: 5.66933vw;
  height: 5.96533vw;
  bottom: 6.16vw;
  left: 8.08vw;
  max-width: 32.16px;
  max-height: 33.84px;
}

body.now-future .nf-hero-star img:nth-of-type(4) {
  width: 4.25333vw;
  height: 4.77333vw;
  top: 52.82667vw;
  left: 58.64vw;
  max-width: 26.84px;
  max-height: 34.44px;
}

body.now-future .nf-hero-star img:nth-of-type(5) {
  width: 4.73067vw;
  height: 6.072vw;
  top: 7.65333vw;
  right: 1.94667vw;
  max-width: 26.84px;
  max-height: 34.44px;
}

body.now-future .nf-hero-star img:nth-of-type(6) {
  width: 4.25333vw;
  height: 4.77333vw;
  bottom: 13.86667vw;
  right: 4.02667vw;
  max-width: 26.84px;
  max-height: 34.44px;
}

body.now-future .nf-hero-star img:nth-of-type(7) {
  width: 4.25333vw;
  height: 4.77333vw;
  bottom: 13.86667vw;
  right: 4.02667vw;
  max-width: 26.84px;
  max-height: 34.44px;
}

body.now-future .mf-hero-heading2 {
  width: 86.66667vw;
  bottom: 14.69333vw;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .nf-weare {
  width: 100vw;
  background-color: #F4CB35;
  text-align: center;
}

body.now-future .nf-weare_heading3 {
  width: 100vw;
  border-bottom: 1px solid #1A1311;
  padding-top: 12.93333vw;
  margin-bottom: 0;
  text-align: center;
  padding-bottom: 15px;
}

body.now-future .nf-weare_heading3 > img {
  width: 100%;
  max-width: 304px;
}

body.now-future .nf-weare_text {
  font-size: 0.9375rem;
  line-height: 2.06667;
  text-align: center;
  margin-top: 4vw;
  padding-bottom: 13.06667vw;
}

body.now-future .nf-members {
  width: 100vw;
}

body.now-future .nf-members .nf-members-inner {
  width: 100vw;
  max-width: 1140px;
  padding: 0;
}

body.now-future .nf-members_heading2 {
  width: 19.46667vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10.66667vw;
}

body.now-future .nf-members_heading2-future {
  width: 27.46667vw;
  margin-top: 16vw;
}

body.now-future .nf-members-range,
body.now-future .nf-now-members-range {
  padding-left: 0;
  padding-right: 0;
  margin-top: 15px;
}

body.now-future .nf-members-range:nth-of-type(1) {
  margin-top: 0px;
}

body.now-future .nf-members-range::after {
  content: '';
  display: block;
  width: 95vw;
  height: 4px;
  background-image: url("../img/lp/now-future/img-item-dotted-yellow.png");
  background-repeat: repeat-x;
  background-size: 9px;
  background-position: top 0 left 0;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .nf-members-range:nth-of-type(1)::before,
body.now-future .nf-members-range:nth-of-type(2)::before,
body.now-future .nf-members-range:nth-of-type(3)::before {
  content: '';
  display: block;
  width: 95vw;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../img/lp/now-future/img-item-dotted-yellow.png");
  background-repeat: repeat-x;
  background-size: 9px;
  background-position: top 0 left 0;
  margin-top: 16px;
  margin-bottom: 15px;
}

body.now-future .nf-members-range:nth-of-type(2)::before {
  display: none;
}

body.now-future .nf-members-range:nth-of-type(3)::before {
  display: none;
}

body.now-future .nf-member-future .nf-members-range::after {
  background-image: url("../img/lp/now-future/img-item-dotted-blue.png");
}

body.now-future .nf-member-future .nf-members-range:nth-of-type(1)::before,
body.now-future .nf-member-future .nf-members-range:nth-of-type(2)::before,
body.now-future .nf-member-future .nf-members-range:nth-of-type(3)::before {
  background-image: url("../img/lp/now-future/img-item-dotted-blue.png");
}

body.now-future .nf-member-future .nf-members-range:nth-of-type(2)::before {
  display: none;
}

body.now-future .nf-member-future .nf-members-range:nth-of-type(3)::before {
  display: none;
}

body.now-future .nf-members-range-row {
  width: 96vw;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .nf-members_img {
  flex: 0 0 30%;
  text-align: right;
  padding-left: 0;
  padding-right: 0;
}

body.now-future .nf-members_img img {
  max-width: 93px;
}

body.now-future .nf-member-personal {
  flex: 0 0 65%;
  padding-left: 0;
  padding-right: 0;
  max-width: 100vw;
}

body.now-future .nf-members_text {
  font-size: 0.8125rem;
  line-height: 1.53846;
  letter-spacing: 0.05em;
  margin-top: 5px;
}

body.now-future .nf-members-name_text {
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 500;
  font-family: 'Noto Sans JP';
}

body.now-future .nf-members-name_text > span {
  font-size: 0.8125rem;
  margin-left: 3px;
}

body.now-future .nf-now-bg {
  width: 100vw;
  margin-top: 0px;
  background-image: url("../img/lp/now-future/bg-item_01.jpg");
  background-repeat: repeat;
  background-position: top 0 left 0;
  background-size: contain;
  position: relative;
}

body.now-future .nf-future-bg {
  background-image: url("../img/lp/now-future/bg-item_02.jpg");
}

body.now-future .nf-now-bg:nth-of-type(1) {
  margin-top: 79px;
}

body.now-future .nf-now-bg::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.93);
}

body.now-future .nf-now-heading2 {
  padding-top: 33px;
  padding-left: 6.66667vw;
  font-weight: 400;
  font-family: 'Ropa Sans', sans-serif;
  font-size: 1.6875rem;
  line-height: 1.03704;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  display: inline-block;
  position: relative;
}

body.now-future .nf-future-bg .nf-now-heading2 {
  padding-top: 52.5px;
  letter-spacing: 0em;
}

body.now-future .nf-now-vertical_text {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  position: absolute;
  width: 19.2vw;
  font-size: 1.25rem;
  letter-spacing: 0.022em;
  font-family: "TategakiNotosansJP";
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .nf-now-vertical_text span {
  background-color: rgba(241, 125, 178, 0.95);
  color: #fff;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
}

body.now-future .nf-now-vertical_text span.now-vertical_text01 {
  margin-left: 12px;
}

body.now-future .nf-now-vertical_text span.now-vertical_text02 {
  margin-top: 45px;
}

body.now-future .nf-now-members-range {
  margin-top: 130px;
  flex: 0 0 95%;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .nf-now-members-range .now-member-range {
  margin-left: auto;
  margin-right: auto;
}

body.now-future .nf-now-members-range .now-member-range .col-7 {
  flex: 0 0 60.33333%;
  max-width: 60.33333%;
}

body.now-future .nf-now-members-range .now-member-range .col-5 {
  flex: 0 0 39.66667%;
  max-width: 39.66667%;
}

body.now-future .nf-now-members-range .member-range {
  background-image: linear-gradient(to right, #1A1311, #1A1311 2px, transparent 2px, transparent 6px), linear-gradient(to left, #1A1311, #1A1311 2px, transparent 2px, transparent 6px);
  /* 幅2の線を作る */
  background-size: 6px 1px, 6px 1px;
  /* グラデーションの幅・高さを指定 */
  background-position: left top, left bottom;
  /* 背景の開始位置を指定 */
  background-repeat: repeat-x,repeat-x;
  /* 横向きにのみ繰り返す */
  padding-left: 0;
  padding-right: 0;
  padding-left: 2px;
}

body.now-future .nf-now-members-name_text {
  font-size: 0.9375rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}

body.now-future .nf-now-members-name_text span {
  font-size: 0.8125rem;
}

body.now-future .nf-now-members-name_text span.font-22-ropa {
  font-family: 'Ropa Sans', sans-serif;
  font-size: 1.375rem;
}

body.now-future .nf-now-members_img_text {
  margin-bottom: 0;
  font-size: 0.9375rem;
  line-height: 1.2;
  font-family: 'Noto Sans JP';
}

body.now-future .nf-now-members_img_text span {
  font-size: 0.75rem;
}

body.now-future .nf-now-members_text {
  font-size: 0.6875rem;
  line-height: 1.18182;
  margin-top: 24px;
  margin-bottom: 0;
}

body.now-future .nf-future-bg .nf-now-members_text {
  margin-top: 12px;
  line-height: 1.5;
}

body.now-future .nf-now-members_img {
  text-align: right;
  padding-left: 0;
  padding-right: 0;
}

body.now-future .nf-now-members_img img {
  max-width: 123px;
}

body.now-future .nf-now-members-items {
  margin-top: 35px;
  padding-left: 0;
  padding-right: 0;
  flex: 0 0 90%;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .nf-now-members-col {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  padding-bottom: 40px;
}

body.now-future .vertical-item_text {
  position: absolute;
  top: 15px;
  right: auto;
  bottom: auto;
  font-size: 1.25rem;
}

body.now-future .vertical-item_text .members-item_text02 {
  margin-top: 20px;
}

body.now-future .nf-now_text {
  font-size: 0.6875rem;
  line-height: 1.81818;
  letter-spacing: 0.1em;
  margin-top: 20px;
}

body.now-future .nf-now-members-col:nth-of-type(odd) .vertical-item_text {
  left: 5.33333vw;
  right: auto;
  width: 70px;
}

body.now-future .nf-now-members-col:nth-of-type(odd) .nf-now_text {
  margin-left: 95px;
  margin-right: 0;
}

body.now-future .nf-now-members-col:nth-of-type(even) .vertical-item_text {
  top: 15px;
  right: 5px;
  left: auto;
}

body.now-future .nf-now-members-col:nth-of-type(even) .nf-now_text {
  margin-left: 0px;
  margin-right: 95px;
}

body.now-future .bg_now_content {
  padding-top: 70%;
}

body.now-future .bg_now01 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_04.png");
}

body.now-future .bg_now01 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_now01 .nf-now-members-name_text {
  margin-top: 11px;
}

body.now-future .bg_now01 .nf-now-members_text {
  margin-top: 23px;
}

body.now-future .bg_now02 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_05.png");
}

body.now-future .bg_now02 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_now02 .nf-now-members-name_text {
  margin-top: 9px;
}

body.now-future .bg_now02 .nf-now-members_text {
  margin-top: 13px;
}

body.now-future .bg_now02 .nf-now-vertical_text span {
  background-color: rgba(238, 129, 34, 0.95);
}

body.now-future .bg_now02 .nf-now-vertical_text span.now-vertical_text02 {
  margin-top: 65px;
}

body.now-future .bg_now02 .nf-now-members-range {
  margin-top: 170px;
}

body.now-future .bg_now03 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_06.png");
}

body.now-future .bg_now03 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_now03 .nf-now-members-name_text {
  margin-top: 11px;
}

body.now-future .bg_now03 .nf-now-members_text {
  margin-top: 24px;
}

body.now-future .bg_now03 .nf-now-vertical_text {
  top: 10px;
}

body.now-future .bg_now03 .nf-now-vertical_text span {
  background-color: rgba(127, 173, 216, 0.95);
}

body.now-future .bg_now03 .nf-now-vertical_text span.now-vertical_text02 {
  margin-top: 25px;
}

body.now-future .bg_now03 .nf-now-vertical_text .nf-now-members-col:nth-of-type(odd) span.members-item_text01 {
  margin-top: 20px;
}

body.now-future .bg_now03 .nf-now-vertical_text .nf-now-members-col:nth-of-type(odd) span.members-item_text02 {
  margin-top: 45px;
}

body.now-future .bg_now03 .nf-now-members-range {
  margin-top: 140px;
}

body.now-future .bg_now04 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_07.png");
}

body.now-future .bg_now04 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_now04 .nf-now-members-name_text {
  margin-top: 11px;
}

body.now-future .bg_now04 .nf-now-members_text {
  margin-top: 24px;
}

body.now-future .bg_now04 .nf-now-vertical_text span {
  background-color: rgba(244, 142, 140, 0.95);
}

body.now-future .bg_now04 .nf-now-vertical_text span.now-vertical_text02 {
  margin-top: 60px;
}

body.now-future .bg_now04 .nf-now-members-range {
  margin-top: 170px;
}

body.now-future .bg_now05 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_08.png");
}

body.now-future .bg_now05 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_now05 .nf-now-members-name_text {
  margin-top: 11px;
}

body.now-future .bg_now05 .nf-now-members_text {
  margin-top: 24px;
}

body.now-future .bg_now05 .nf-now-vertical_text span {
  background-color: rgba(140, 189, 151, 0.95);
}

body.now-future .bg_now05 .nf-now-vertical_text span.now-vertical_text02 {
  margin-top: 25px;
}

body.now-future .bg_now05 .nf-now-members-col:nth-of-type(2) .members-item_text02 {
  margin-top: 80px;
}

body.now-future .bg_future01 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_09.png");
  background-position: top 40px left 0;
  top: 30px;
}

body.now-future .bg_future01 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_future01 .nf-now-members-name_text {
  margin-top: 11px;
}

body.now-future .bg_future01 .nf-now-members_text {
  margin-top: 9px;
}

body.now-future .bg_future01 .nf-now-vertical_text span.now-vertical_text02 {
  margin-top: 25px;
}

body.now-future .bg_future01 .nf-now-members-range {
  margin-top: 200px;
}

body.now-future .bg_future02 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_10.png");
  background-position: top 40px left 0;
  top: 30px;
}

body.now-future .bg_future02 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_future02 .nf-now-members-name_text {
  margin-top: 9px;
}

body.now-future .bg_future02 .nf-now-members_text {
  margin-top: 9px;
}

body.now-future .bg_future02 .nf-now-vertical_text span {
  background-color: rgba(238, 129, 34, 0.95);
  padding-top: 0px;
}

body.now-future .bg_future02 .nf-now-vertical_text span.now-vertical_text02 {
  padding-top: 10px;
  margin-top: 90px;
}

body.now-future .bg_future02 .nf-now-vertical_text span.members-item_text01,
body.now-future .bg_future02 .nf-now-vertical_text span.members-item_text02 {
  padding-top: 10px;
}

body.now-future .bg_future02 .nf-now-members-range {
  margin-top: 250px;
}

body.now-future .bg_future03 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_11.png");
  background-position: top 40px left 0;
  top: 30px;
}

body.now-future .bg_future03 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_future03 .nf-now-members-name_text {
  margin-top: 10px;
}

body.now-future .bg_future03 .nf-now-members_text {
  margin-top: 10px;
}

body.now-future .bg_future03 .nf-now-vertical_text span {
  background-color: rgba(127, 173, 216, 0.95);
}

body.now-future .bg_future03 .nf-now-vertical_text span.now-vertical_text02 {
  margin-top: 60px;
}

body.now-future .bg_future03 .nf-now-members-range {
  margin-top: 140px;
}

body.now-future .bg_future04 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_02.png");
  background-position: top 40px left 0;
  top: 30px;
}

body.now-future .bg_future04 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_future04 .nf-now-members-name_text {
  margin-top: 6px;
}

body.now-future .bg_future04 .nf-now-members_text {
  margin-top: 6px;
}

body.now-future .bg_future04 .nf-now-vertical_text span {
  background-color: rgba(244, 142, 140, 0.95);
}

body.now-future .bg_future04 .nf-now-members_text {
  margin-top: 8px;
  line-height: 1.27273;
}

body.now-future .bg_future04 .nf-now-members-range {
  margin-top: 250px;
}

body.now-future .bg_future05 {
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: top 0px left 0;
  background-size: contain;
  position: relative;
  margin-top: -40px;
  padding-left: 0;
  padding-right: 0;
  background-image: url("../img/lp/now-future/bg-item-sp_03.png");
  background-position: top 40px left -15px;
}

body.now-future .bg_future05 .row {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body.now-future .bg_future05 .nf-now-members-name_text {
  margin-top: 9px;
}

body.now-future .bg_future05 .nf-now-members_text {
  margin-top: 10px;
}

body.now-future .bg_future05 .nf-now-vertical_text {
  top: 30px;
}

body.now-future .bg_future05 .nf-now-vertical_text span {
  background-color: rgba(140, 189, 151, 0.95);
  padding-top: 0px;
}

body.now-future .bg_future05 .nf-now-vertical_text span.now-vertical_text01,
body.now-future .bg_future05 .nf-now-vertical_text span.now-vertical_text02 {
  padding-top: 10px;
}

body.now-future .bg_future05 .nf-now-vertical_text span.members-item_text01,
body.now-future .bg_future05 .nf-now-vertical_text span.members-item_text02 {
  padding-top: 10px;
}

body.now-future .bg_future05 .nf-now-vertical .nf-now-vertical_text {
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

body.now-future .bg_future05 .nf-now-members-col:nth-of-type(odd) span.members-item_text01 {
  margin-top: 0px;
}

body.now-future .bg_future05 .nf-now-members-col:nth-of-type(odd) span.members-item_text02 {
  margin-top: 25px;
}

body.now-future .bg_future05 .nf-now-members-range {
  margin-top: 270px;
}

body.kita-t-b-w-lower {
  font-family: "notosansJP";
}

body.kita-t-b-w-lower main p {
  margin-bottom: 0;
}

body.kita-t-b-w-lower main nav .breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
}

body.kita-t-b-w-lower main nav .breadcrumb li, body.kita-t-b-w-lower main nav .breadcrumb a, body.kita-t-b-w-lower main nav .breadcrumb span {
  font-size: 12px;
  color: #000;
}

body.kita-t-b-w-lower .section {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

body.kita-t-b-w-lower .d-sp-ss {
  display: none;
}

body.kita-t-b-w-lower .d-xl-flex {
  display: none;
}

body.kita-t-b-w-lower .kl-hero {
  width: 100%;
  height: 0;
  padding-top: 161.86667vw;
  background-repeat: no-repeat;
  background-position: top 0 left 0;
  background-size: cover;
  position: relative;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero {
  background-image: url("../img/lp/kitaweru/kodomo-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero {
  background-image: url("../img/lp/kitaweru/kaigo-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero {
  background-image: url("../img/lp/kitaweru/yakugyo-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .hsc-course-inner .kl-hero {
  background-image: url("../img/lp/hsc/course/hsc-bg-item01_sp.jpg");
}

body.kita-t-b-w-lower .plean-btn {
  width: 187px;
  text-align: center;
  border-radius: 30px;
  padding: 10px 0;
  cursor: pointer;
}

body.kita-t-b-w-lower .plean-btn p {
  font-size: 0.9375rem;
  margin-bottom: 0;
}

body.kita-t-b-w-lower .bg-white {
  background-color: #fff;
}

body.kita-t-b-w-lower .kl-arrow span {
  display: block;
  position: relative;
}

body.kita-t-b-w-lower .kl-arrow span::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 15px;
}

body.kita-t-b-w-lower .kl-arrow span.up-a::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-arrow span::after {
  background-image: url("../img/lp/kitaweru/kodomo-item_08.svg");
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-arrow span::after {
  background-image: url("../img/lp/kitaweru/yakugyo-item_29.svg");
}

body.kita-t-b-w-lower .kl-hero_heddigng2_img {
  position: absolute;
  top: 36px;
  left: 2.66667vw;
}

body.kita-t-b-w-lower .border-weru-kodomo {
  border: 1px solid #F48E8C;
  color: #F48E8C;
}

body.kita-t-b-w-lower .border-weru-yakugyo {
  border: 1px solid #43966C;
  color: #43966C;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero_heddigng2_img {
  width: 94.66667vw;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_heddigng2_img {
  width: 91.46667vw;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero_heddigng2_img {
  width: 87.2vw;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-hero_heddigng2_img {
  width: 48.8vw;
}

body.kita-t-b-w-lower .kl-hero_text_img {
  position: absolute;
  top: 103.46667vw;
  left: 2.66667vw;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero_text_img {
  width: 51.46667vw;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_text_img {
  width: 66.4vw;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero_text_img {
  width: 81.6vw;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-hero_text_img {
  width: 59.2vw;
}

body.kita-t-b-w-lower .kl-point {
  width: 100%;
  position: relative;
}

body.kita-t-b-w-lower .kl-point-inner {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-point:before {
  content: '';
  display: block;
  background-image: url("../img/lp/kitaweru/common-item_22.svg");
  background-repeat: repeat;
  background-size: 192px 114px;
  background-position: top 0 left 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-point {
  background-color: #F48E8C;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point {
  background-color: #7FADD8;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-point {
  background-color: #8CBD97;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-point {
  background-color: #EB6EA5;
}

body.kita-t-b-w-lower .p-btn--dark {
  margin-top: 25px;
}

body.kita-t-b-w-lower .p-btn--dark a span:before {
  background-color: transparent;
}

body.kita-t-b-w-lower .kl-point_text {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  position: relative;
}

body.kita-t-b-w-lower .kl-point_text::before,
body.kita-t-b-w-lower .kl-point_text::after {
  content: '';
  display: inline-block;
  width: 21px;
  height: 24px;
  background-image: url("../img/lp/kitaweru/common-item_05.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-point_text::before {
  left: 30px;
  -webkit-transform: translate(-3px, 10px);
  transform: translate(-3px, 10px);
}

body.kita-t-b-w-lower .kl-point_text::after {
  right: 30px;
  -webkit-transform: translate(0, 10px) rotate(65deg);
  transform: translate(0, 10px) rotate(65deg);
}

body.kita-t-b-w-lower .kl-point_col {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

body.kita-t-b-w-lower .kl-point_col .kl-point_text {
  display: inline-block;
  margin-top: 30px;
}

body.kita-t-b-w-lower .kl-point_col:nth-of-type(1) .kl-point_text {
  margin-top: 70px;
}

body.kita-t-b-w-lower .kl-point_col:nth-of-type(1) .kl-point_text::before {
  left: -20px;
}

body.kita-t-b-w-lower .kl-point_col:nth-of-type(1) .kl-point_text::after {
  right: -20px;
}

body.kita-t-b-w-lower .kl-point_col:nth-of-type(2) .kl-point_text::before {
  left: -20px;
}

body.kita-t-b-w-lower .kl-point_col:nth-of-type(2) .kl-point_text::after {
  right: -20px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-point_col:nth-of-type(2) .kl-point_text::before {
  top: auto;
  bottom: 15px;
  left: -25px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-point_col:nth-of-type(2) .kl-point_text::after {
  top: auto;
  bottom: 15px;
  right: -25px;
}

body.kita-t-b-w-lower .kl-point_col:nth-of-type(3) .kl-point_text::before {
  left: -20px;
}

body.kita-t-b-w-lower .kl-point_col:nth-of-type(3) .kl-point_text::after {
  right: -20px;
}

body.kita-t-b-w-lower .kl-point_row {
  padding-bottom: 80px;
}

body.kita-t-b-w-lower .kl-image {
  width: 100%;
  background: repeating-linear-gradient(-45deg, #fef7fa, #fef7fa 10px, #fff 0, #fff 20px);
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-image {
  background: repeating-linear-gradient(-45deg, #EDF8F5, #EDF8F5 10px, #fff 0, #fff 20px);
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-image {
  background: repeating-linear-gradient(-45deg, #EDF8F5, #EDF8F5 10px, #fff 0, #fff 20px);
}

body.kita-t-b-w-lower .kl-img-inner {
  width: 100%;
  max-width: 1220px;
  margin-inline: auto;
  padding-top: 20px;
  position: relative;
}

body.kita-t-b-w-lower .kl-img-inner .parts img {
  display: inline-block;
  position: absolute;
}

body.kita-t-b-w-lower .kl-img-inner .parts img:nth-of-type(1) {
  width: 30vw;
  top: 40%;
  left: 4%;
}

body.kita-t-b-w-lower .kl-img-inner .parts img:nth-of-type(2) {
  width: 35vw;
  top: 40%;
  right: 4%;
}

body.kita-t-b-w-lower .kl-img-inner .image-item {
  width: 92%;
  margin-inline: auto;
  margin-bottom: 0;
  position: relative;
}

body.kita-t-b-w-lower .kl-img-inner .image-item img {
  width: 100%;
}

body.kita-t-b-w-lower .kl-qualification {
  width: 100%;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification {
  background-color: #F48E8C;
  position: relative;
}

body.kita-t-b-w-lower .kl-qualification-inner {
  width: 94.66667vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 40px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-inner {
  padding-top: 40px;
  padding-bottom: 180px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-inner {
  padding-top: 10px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification {
  background-color: #7FADD8;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification {
  background-color: #8CBD97;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-qualification {
  background-color: #EB6EA5;
}

body.kita-t-b-w-lower .kl-qualification_heading2 {
  width: 94.66667vw;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.33333;
  background-color: #FCF25D;
  padding: 4px 15.5px;
}

body.kita-t-b-w-lower .kl-qualification-flex {
  width: 100%;
  display: flex;
  margin-left: 10px;
  position: relative;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-flex {
  position: static;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-flex {
  justify-content: center;
}

body.kita-t-b-w-lower .kl-qualification_heading3 {
  width: 35px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification_heading3 {
  margin-top: 5px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_heading3 {
  margin-left: -20px;
}

body.kita-t-b-w-lower .kl-qualification-list {
  width: 83%;
  margin-top: 5px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-list {
  margin-top: 0;
  margin-left: 10px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-list {
  order: 2;
  margin-left: 10px;
}

body.kita-t-b-w-lower .kl-qualification_ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 15px;
}

body.kita-t-b-w-lower .kl-qualification_ul li {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-bottom: 8px;
}

body.kita-t-b-w-lower .kl-qualification_ul li::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  margin-right: 10px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification_ul {
  margin-top: 15px;
  position: relative;
}

body.kita-t-b-w-lower .kl-qualification_item_text {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 1;
}

body.kita-t-b-w-lower .kl-qualification_item_text img {
  width: min(400px,92%);
  margin-inline: auto;
  display: block;
}

body.kita-t-b-w-lower .kl-qualification_item_img {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img {
  top: auto;
}

body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img .kl-qualification_item_text {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img .kl-qualification_item_text img {
  width: min(400px,92%);
  margin-inline: auto;
  display: block;
}

body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img:nth-of-type(1) {
  width: min(108px,7vw);
  bottom: 25%;
  right: 0;
}

body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img:nth-of-type(2) {
  width: min(57px,4vw);
  bottom: 12%;
  right: 10%;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification__late {
  order: 3;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

body.kita-t-b-w-lower .kl-qualification-btn {
  position: absolute;
  top: 186px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-btn {
  top: 206px;
}

body.kita-t-b-w-lower .kl-qualification-item_btn {
  position: relative;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

body.kita-t-b-w-lower .kl-qualification-item_btn p {
  position: relative;
}

body.kita-t-b-w-lower .kl-qualification-item_btn p::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 13.42px;
  background-image: url("../img/lp/kitaweru/kodomo-item_08.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 16.2px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-item_btn {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-btn {
  top: 200px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-btn .kl-qualification-item_btn {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

body.kita-t-b-w-lower .kl-qualification-item_btn::before,
body.kita-t-b-w-lower .kl-qualification-item_btn::after {
  content: '';
  display: inline-block;
  width: 71px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

body.kita-t-b-w-lower .kl-qualification-item_btn::before {
  left: -83px;
}

body.kita-t-b-w-lower .kl-qualification-item_btn::after {
  right: -83px;
}

body.kita-t-b-w-lower .kl-qualification_ul01 {
  margin-bottom: 130px;
}

body.kita-t-b-w-lower .kl-qualification_img {
  text-align: center;
  margin-left: auto;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification_ul01 {
  margin-bottom: 0;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification_ul02 {
  margin-top: 0;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-inner {
  padding-bottom: 55px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-flex {
  flex-wrap: wrap;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-list {
  margin-top: 0;
  margin-left: 10px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_img {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 29px;
  margin-left: 0px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_img img {
  width: 100%;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_img img {
  max-width: 315px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-inner {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-flex {
  flex-wrap: wrap;
  margin-left: 0;
  justify-content: center;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_heading3 {
  order: 1;
  margin-top: 30px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul_01 {
  margin-bottom: 0px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul02 {
  margin-top: -30px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul02 li:nth-of-type(1) {
  padding-top: 30px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul {
  padding-left: 0;
}

body.kita-t-b-w-lower .kl-study {
  width: 100%;
}

body.kita-t-b-w-lower .kl-study-inner {
  margin-left: auto;
  margin-right: auto;
  width: min(1220px,92%);
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-study-inner {
  width: min(1220px,92%);
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study-inner {
  width: min(1220px,92%);
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study-inner {
  width: min(1220px,92%);
}

body.kita-t-b-w-lower .ki-study-project {
  width: 100%;
  margin-top: 45px;
}

body.kita-t-b-w-lower .kl-study__upper {
  width: 100%;
}

body.kita-t-b-w-lower .kl-study__title--sub {
  width: 100%;
  max-width: 423px;
  margin-inline: auto;
}

body.kita-t-b-w-lower .kl-study__title--sub img {
  width: 100%;
}

body.kita-t-b-w-lower .kl-study__img img {
  width: 100%;
}

body.kita-t-b-w-lower .kl-study__text {
  font-size: min(18px,3.7vw);
}

body.kita-t-b-w-lower .kl-study__lower--title {
  font-size: 21px;
  color: #fff;
  font-weight: bold;
  background-color: #E96E69;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study__lower--title {
  background-color: #007FC7;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-study__lower--title {
  background-color: #43966C;
}

body.kita-t-b-w-lower .kl-study__content {
  width: 100%;
  margin-inline: auto;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study_heading4 {
  color: #007FC7;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-study_heading4 {
  color: #43966C;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .ki-study-project {
  margin-top: 0px;
}

body.kita-t-b-w-lower .kl-schedule {
  margin-top: 83px;
  width: 100%;
  background-color: #F48E8C;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule {
  margin-top: 0 !important;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule {
  margin-top: 0 !important;
  background-color: #007FC7;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule {
  margin-top: 0 !important;
  background-color: #43966C;
}

body.kita-t-b-w-lower .kl-schedule-border {
  height: 10px;
  width: 100%;
  background-color: #F48E8C;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-border {
  background-color: #007FC7;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-border {
  background-color: #8CBD97;
}

body.kita-t-b-w-lower .kl-schedule-bg {
  width: 100%;
  height: 0;
  padding-top: 75.46667%;
  background-image: url("../img/lp/kitaweru/kodomo-bg-item-sp_02.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: relative;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule-bg {
  background-size: cover;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-bg {
  background-size: cover;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-bg {
  background-size: cover;
}

body.kita-t-b-w-lower .kl-schedule-bg-inner {
  width: 94.66667vw;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-schedule-bg-inner img {
  width: 74.66667vw;
  position: absolute;
  bottom: 20px;
  left: 10px;
}

body.kita-t-b-w-lower .kl-schedule-bg-inner::before {
  content: '';
  display: inline-block;
  width: 143px;
  height: 143px;
  background-image: url("../img/lp/kitaweru/kodomo-item_22.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: absolute;
  top: -40px;
  left: 0;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule-bg-inner::before {
  top: 20px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-bg-inner::before {
  top: 20px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-bg-inner::before {
  top: 20px;
}

body.kita-t-b-w-lower .kl-schedule__three-power {
  background-color: #fff;
  padding: 20px 0 51px 0;
}

body.kita-t-b-w-lower .kl-schedule__three-power--inner {
  width: min(1220px,92%);
  margin-inline: auto;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule__three-power--inner {
  width: min(1000px,92%);
}

body.kita-t-b-w-lower .kl-schedule__three-power--list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 7px 1fr 7px 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px 7px;
}

body.kita-t-b-w-lower .kl-schedule__three-power--lower {
  width: min(1000px,78%);
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-bg-inner::before {
  background-image: url("../img/lp/kitaweru/yakugyo-item_24.svg");
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-bg {
  background-image: url("../img/lp/kitaweru/yakugyo-bg-item-sp_02.jpg");
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt {
  width: 100%;
  background-color: #43966C;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt-inner {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  margin-bottom: 40px;
  color: #fff;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule_paragraph {
  font-size: 0.875rem;
  line-height: 2;
  margin-bottom: 15px;
  max-width: 750px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule_paragraph-samll {
  display: block;
  width: 100%;
  font-size: 0.75rem;
  line-height: 1.83333;
  display: inline-flex;
  max-width: 823px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule_paragraph-samll span:nth-of-type(2) {
  margin-left: 5px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt_img:nth-of-type(1) {
  display: none;
  position: absolute;
  bottom: 50px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt_img:nth-of-type(2) {
  display: none;
  position: absolute;
  right: 40px;
  bottom: 50px;
}

body.kita-t-b-w-lower .kl-schedule-60-inner {
  width: 94.66667vw;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-schedule-60_heading2_img {
  text-align: center;
  width: 85.07042vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  position: relative;
}

body.kita-t-b-w-lower .kl-schedule-60_heading2_img::before,
body.kita-t-b-w-lower .kl-schedule-60_heading2_img::after {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: absolute;
}

body.kita-t-b-w-lower .kl-schedule-60_heading2_img::before {
  width: 34.87px;
  height: 44.68px;
  background-image: url("../img/lp/kitaweru/kodomo-item_24.svg");
  bottom: -60.68px;
  left: -20px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-60_heading2_img::before {
  background-image: url("../img/lp/kitaweru/yakugyo-item_38.svg");
}

body.kita-t-b-w-lower .kl-schedule-60_heading2_img::after {
  width: 47.3px;
  height: 56.64px;
  background-image: url("../img/lp/kitaweru/kodomo-item_25.svg");
  bottom: -67.3px;
  right: -20px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-60_heading2_img::after {
  background-image: url("../img/lp/kitaweru/yakugyo-item_39.svg");
}

body.kita-t-b-w-lower .kl-schedule-60_heading3_img {
  width: 46.93333vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 37px;
  text-align: center;
  margin-bottom: 20px;
}

body.kita-t-b-w-lower .kl-schedule-slide {
  width: 100%;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-slide {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -37px;
  padding-top: 20px;
}

body.kita-t-b-w-lower .slick-track {
  display: flex;
}

body.kita-t-b-w-lower .slick-slide {
  height: auto !important;
}

body.kita-t-b-w-lower .slick-arrow:before {
  content: "" !important;
}

body.kita-t-b-w-lower .prev-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: 1000;
  opacity: 1;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body.kita-t-b-w-lower .prev-arrow.slick-disabled,
body.kita-t-b-w-lower .next-arrow.slick-disabled {
  cursor: default;
  opacity: 0;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body.kita-t-b-w-lower .next-arrow {
  position: absolute;
  width: 26px;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body.kita-t-b-w-lower .slick-track {
  display: flex;
}

body.kita-t-b-w-lower .slick-slide {
  height: auto !important;
}

body.kita-t-b-w-lower .slick-arrow:before {
  content: "" !important;
}

body.kita-t-b-w-lower .prev-arrow,
body.kita-t-b-w-lower .next-arrow,
body.kita-t-b-w-lower .start-arrow,
body.kita-t-b-w-lower .end-arrow {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 26px;
  height: 48px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 1;
  cursor: pointer;
}

body.kita-t-b-w-lower .prev-arrow {
  left: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

body.kita-t-b-w-lower .next-arrow {
  right: 0;
}

body.kita-t-b-w-lower .start-arrow {
  left: -33px;
}

body.kita-t-b-w-lower .end-arrow {
  right: -33px;
}

body.kita-t-b-w-lower .prev-arrow {
  background-image: url("../img/lp/kitaweru/common-item_13.svg");
}

body.kita-t-b-w-lower .next-arrow {
  background-image: url("../img/lp/kitaweru/common-item_13.svg");
}

body.kita-t-b-w-lower .start-arrow.slick-disabled {
  width: 30px;
  height: 172px;
  border-radius: 5px;
  z-index: 100;
  -webkit-animation-name: a;
  animation-name: a;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

body.kita-t-b-w-lower .end-arrow.slick-disabled {
  width: 30px;
  height: 172px;
  -webkit-animation-name: a;
  animation-name: a;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes a {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes a {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes b {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes b {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

body.kita-t-b-w-lower .start-arrow.slick-disabled,
body.kita-t-b-w-lower .end-arrow.slick-disabled {
  cursor: default;
  width: 30px;
  height: 172px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .start-arrow {
  background-image: url("../img/lp/kitaweru/common-item_30.svg");
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .end-arrow {
  background-image: url("../img/lp/kitaweru/common-item_14.svg");
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .start-arrow.slick-disabled {
  background-image: url("../img/lp/kitaweru/common-item_17.svg") !important;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .end-arrow.slick-disabled {
  background-image: url("../img/lp/kitaweru/common-item_29.svg") !important;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .start-arrow {
  background-image: url("../img/lp/kitaweru/common-item_31.svg");
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .end-arrow {
  background-image: url("../img/lp/kitaweru/common-item_15.svg");
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .start-arrow.slick-disabled {
  background-image: url("../img/lp/kitaweru/common-item_16.svg") !important;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .end-arrow.slick-disabled {
  background-image: url("../img/lp/kitaweru/common-item_28.svg") !important;
}

body.kita-t-b-w-lower .hsc-course-inner .start-arrow {
  background-image: url("../img/lp/hsc/course/common-item04.svg");
}

body.kita-t-b-w-lower .hsc-course-inner .end-arrow {
  background-image: url("../img/lp/hsc/course/common-item03.svg");
}

body.kita-t-b-w-lower .hsc-course-inner .start-arrow.slick-disabled {
  background-image: url("../img/lp/hsc/course/common-item02.svg") !important;
}

body.kita-t-b-w-lower .hsc-course-inner .end-arrow.slick-disabled {
  background-image: url("../img/lp/hsc/course/common-item01.svg") !important;
}

body.kita-t-b-w-lower .kl-schedule-slide-inner {
  padding-bottom: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body.kita-t-b-w-lower .kl-schedule-slide-item {
  width: 89%;
  max-width: 290px;
  display: flex;
  border: 1px solid #fff;
  border-radius: 13px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 43px;
  padding: 1px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule-slide-item {
  border-radius: 0;
  border: none;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide-item {
  border-radius: 0;
  border: none;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-slide-item {
  border-radius: 0;
  border: none;
}

body.kita-t-b-w-lower .kl-schedule-teacher {
  width: 100%;
  background-color: #fff;
}

body.kita-t-b-w-lower .kl-schedule-teacher-item {
  max-width: 340px;
  -webkit-transform: translate(-5px);
  transform: translate(-5px);
}

body.kita-t-b-w-lower .kl-schedule-slide-item-tag {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -25px;
  left: -10px;
  z-index: 10;
}

body.kita-t-b-w-lower .kl-schedule-slide_img {
  width: 43%;
  overflow: hidden;
  border-radius: 0;
  -webkit-transform: scale(1) translate(0);
  transform: scale(1) translate(0);
}

body.kita-t-b-w-lower .kl-schedule-slide_img img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}

body.kita-t-b-w-lower .kl-schedule-slide_content {
  width: 57%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule-slide_content {
  background-color: #ECE2D4;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide_content {
  background-color: #ECE2D4;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-slide_content {
  background-color: #ECE2D4;
}

body.kita-t-b-w-lower .kl-schedule-slide_heading4 {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.53333;
  color: #fff;
  margin-top: 15px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule-slide_heading4 {
  color: #1A1311;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide_heading4 {
  color: #1A1311;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-slide_heading4 {
  color: #1A1311;
}

body.kita-t-b-w-lower .kl-schedule-slide_paragraph {
  font-size: 0.75rem;
  line-height: 1.69231;
  margin-top: 10px;
  margin-bottom: 0;
}

body.kita-t-b-w-lower .kl-schedule-oneday {
  width: 100%;
  background-color: #FFEDEA;
  padding-bottom: 40px;
}

body.kita-t-b-w-lower .kl-schedule-oneday-inner {
  width: 94.66667vw;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-schedule-oneday-heading {
  padding-top: 24px;
  font-size: 0.875rem;
}

body.kita-t-b-w-lower .kl-schedule-oneday-heading strong {
  font-size: 1.125rem;
}

body.kita-t-b-w-lower .kl-schedule-oneday-start {
  display: flex;
  margin-top: 27.7px;
}

body.kita-t-b-w-lower .kl-schedule-oneday-start-slide {
  width: 82%;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kls-oneday-slide-item {
  width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

body.kita-t-b-w-lower .kls-oneday-slide-item_paragraph {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F48E8C;
  color: #fff;
  font-size: 0.875rem;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule {
  background-color: #007FC7;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-bg {
  background-image: url("../img/lp/kitaweru/kaigo-bg-item-sp_02.jpg");
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-bg-inner::before {
  background-image: url("../img/lp/kitaweru/kaigo-item_18.svg");
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-60_heading2_img::before {
  width: 45px;
  height: 37px;
  background-image: url("../img/lp/kitaweru/kaigo-item_19.svg");
  bottom: -60.68px;
  left: -20px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-60_heading2_img::after {
  width: 53px;
  height: 46px;
  background-image: url("../img/lp/kitaweru/kaigo-item_20.svg");
  bottom: -67.3px;
  right: -20px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -37px;
  padding-top: 20px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide-item::before {
  background-image: url("../img/lp/kitaweru/common-item_02.svg");
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide-item.kl-schedule-slide-item_02::before {
  background-image: url("../img/lp/kitaweru/common-item_12.svg");
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-oneday {
  background-color: #EAF4EA;
}

body.kita-t-b-w-lower .kl-curriculum-inner {
  width: 94.66667vw;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-curriculum {
  width: 100%;
  padding-top: 116px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table.kl-curriculum-tag {
  position: relative;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table.kl-curriculum-tag.style {
  margin-top: 100px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table.kl-curriculum-tag caption {
  position: absolute;
  top: -60px;
  left: 0;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table.kl-curriculum-tag caption span {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  background-color: #F48E8C;
  padding: 4px 16px;
  border-radius: 15px;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum-inner {
  position: relative;
}

body.kita-t-b-w-lower .kl-curriculum_heading2 {
  font-size: 1.4375rem;
  font-weight: 500;
  width: 92%;
  max-width: 1110px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
}

body.kita-t-b-w-lower .kl-curriculum_heading2::before {
  content: '';
  display: inline-block;
  width: 44.8px;
  height: 50px;
  background-image: url("../img/lp/kitaweru/common-item_03.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: absolute;
  top: -10px;
  left: -25px;
  z-index: 10;
}

body.kita-t-b-w-lower .kl-curriculum-title_border {
  display: block;
  width: 100%;
  height: 6px;
  background-color: #E2E2E2;
  border-radius: 4px;
  position: relative;
  margin-top: -5px;
}

body.kita-t-b-w-lower .kl-curriculum-title_border::before {
  content: '';
  width: inline-block;
  width: 95px;
  height: 6px;
  background-color: #43AA88;
  position: absolute;
  top: 0;
  left: 0px;
  border-radius: 6px;
}

body.kita-t-b-w-lower .kl-curriculum_tables {
  margin-top: 25px;
  display: flex;
  overflow-x: scroll;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum_tables {
  margin-top: 0;
  padding-top: 65px;
}

body.kita-t-b-w-lower .kl-curriculum_tables-hidden {
  margin-top: 0;
  display: flex;
  overflow-x: scroll;
}

body.kita-t-b-w-lower .kl-curriculum_tables-hidden .table {
  width: auto;
  min-width: 310px;
  margin-top: 50px;
}

body.kita-t-b-w-lower .accordion-item {
  display: none;
}

body.kita-t-b-w-lower .table {
  min-width: 310px;
  margin-right: 35px;
  border: 1px solid #1A1311;
}

body.kita-t-b-w-lower .table tr th {
  background-color: #1A1311;
  color: #fff;
  border: 1px solid #6F6F6F;
}

body.kita-t-b-w-lower .table tr td {
  border: 1px solid #6F6F6F;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

body.kita-t-b-w-lower .table tr td:nth-of-type(1) {
  border-right: 1px solid #6F6F6F !important;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .table {
  min-width: 260px;
  margin-right: 15px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .table-wrapper {
  width: 32%;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 310px;
}

body.kita-t-b-w-lower .kl-curriculum_btn::before,
body.kita-t-b-w-lower .kl-curriculum_btn::after {
  background-color: #F48E8C;
}

body.kita-t-b-w-lower .kl-curriculum_btn::before {
  left: -78px;
}

body.kita-t-b-w-lower .kl-curriculum_btn::after {
  right: -78px;
}

body.kita-t-b-w-lower .kl-curriculum_btn {
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
  margin-top: 20px;
}

body.kita-t-b-w-lower .kl-calc {
  width: 100%;
  padding-bottom: 71.5px;
}

body.kita-t-b-w-lower .kl-calc-inner {
  width: 94.66667vw;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-calc_img {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 81.5px;
  flex-wrap: wrap;
}

body.kita-t-b-w-lower .kl-calc_img img {
  width: 98px;
}

body.kita-t-b-w-lower .kl-calc_img img:nth-of-type(2) {
  margin-left: 17px;
  margin-right: 17px;
  width: 27px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-calc_img {
  padding-bottom: 0px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_img {
  display: block;
}

body.kita-t-b-w-lower .kl-calc_paragraph {
  width: 100%;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1.33333;
  text-align: center;
  margin-top: 25px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-curriculum_tables {
  display: flex;
  flex-direction: column;
  overflow-x: visible;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .table-wrapper {
  display: flex;
  overflow-x: scroll;
  order: 1;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .table {
  min-width: 260px;
  margin-right: 20px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc-inner {
  width: 100%;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc {
  width: 100%;
  order: 3;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-text {
  width: 100%;
  order: 2;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_img {
  display: flex;
  justify-content: center;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_img img {
  max-width: 90px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_paragraph-small {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  margin-top: 5px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_paragraph-small span {
  font-size: 0.75rem;
  line-height: 2.33333;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc__pwrap {
  border: 2px solid #43AA88;
  border-radius: 10px;
  max-width: 331px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_img {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_paragraph {
  padding-top: 20px;
  margin-top: 0;
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_paragraph-small {
  padding-bottom: 20px;
}

body.kita-t-b-w-lower .kl-interview .movie-index {
  max-width: 678px;
}

body.kita-t-b-w-lower .kl-links {
  width: 100%;
  background: repeating-linear-gradient(-45deg, #fef7fa, #fef7fa 10px, #fff 0, #fff 20px);
  padding-top: 60px;
  padding-bottom: 100px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-links {
  background: repeating-linear-gradient(-45deg, #EDF8F5, #EDF8F5 10px, #fff 0, #fff 20px);
}

body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-links {
  background: repeating-linear-gradient(-45deg, #EDF8F5, #EDF8F5 10px, #fff 0, #fff 20px);
}

body.kita-t-b-w-lower .kl-links-inner {
  width: 94.66667vw;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kl-links_ul {
  list-style-type: none;
  padding-left: 0;
}

body.kita-t-b-w-lower .kl-links_li {
  margin-bottom: 15px;
}

body.kita-t-b-w-lower .table tr th:nth-of-type(2),
body.kita-t-b-w-lower .table tr td:nth-of-type(2) {
  text-align: center;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-curriculum {
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2 {
  width: 100%;
  background-color: #7FADD8;
  padding-top: 35px;
  padding-bottom: 60px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2-second-wrapeer {
  width: 92%;
  margin-inline: auto;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01 {
  width: min(480px,75%);
  margin-left: 3vw;
  position: relative;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01::before {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 left 0;
  position: absolute;
  width: min(100px,18vw);
  aspect-ratio: 71/184;
  background-image: url("../img/lp/kitaweru/kaigo-item_04_sp.png");
  right: -25%;
  bottom: -15%;
  z-index: 1;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__track {
  position: relative;
  z-index: 1;
}

body.kita-t-b-w-lower .d-pc {
  display: none;
}

body.kita-t-b-w-lower .kitaweru-kodomo-inner .accordion-item {
  display: none;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__track {
  max-width: 355px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  position: relative;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__track img {
  width: 100%;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__track::after {
  content: '';
  display: block;
  width: 60px;
  height: 87px;
  background: url("../img/lp/kitaweru/kaigo-item_23.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  bottom: -23px;
  left: 0;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__point {
  max-width: 355px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__point img {
  width: 100%;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-field .field02 {
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-field .field04 li {
  min-height: 72px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375;
  padding: 13px 20px 13px 55px;
  border: 3px dotted #F08300;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-field .field04 li::before {
  content: '';
  width: 45px;
  height: 45px;
  background: url("../img/lp/kitatech/htc-common-cook.svg") center/cover no-repeat;
  position: absolute;
  top: -3px;
  left: -3px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup h2 {
  margin-bottom: 34px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-text {
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: double 4px #333333;
  margin-bottom: 34px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup h3.bg-htc-spline {
  font-size: 22px;
  padding: 10px 0;
  background: repeating-linear-gradient(-45deg, #FFF1E6, #FFF1E6 10px, #fff 0, #fff 20px);
  margin-bottom: 24px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 14px 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card {
  border: none;
  background-color: #FFF3C9;
  padding: 10px 0 5px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .title {
  font-size: 15px;
  padding: 0 10px;
  margin-bottom: 12px;
  font-weight: 700;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .img {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .img .comment {
  position: absolute;
  top: -5px;
  right: 0;
  text-align: right;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .img .comment img {
  width: 63%;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .text {
  font-size: 14px;
  line-height: 1.57143;
  margin-bottom: 10px;
  padding: 0 10px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero {
  background-image: url("../img/lp/kitatech/course1/htc-c1-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_heddigng2_img {
  width: 100%;
  max-width: 255px;
  top: 16px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_text_img {
  width: 100%;
  max-width: 198px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-point {
  background-color: #F08300;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-point_row {
  justify-content: center;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup h2 {
  max-width: 920px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification {
  background-color: #fff;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_heading2 {
  width: 100%;
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 60px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-inner {
  width: 100%;
  padding-top: 0;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-flex {
  flex-direction: column;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_c1_heading3 {
  width: 93%;
  background-color: #F08300;
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 0.1em;
  line-height: 1.5;
  padding-top: 15px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  margin-top: 100px;
  margin-bottom: 0;
  border-radius: 20px 20px 0 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_heading3 {
  width: 100%;
  max-width: 350px;
  margin-top: 34px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-list {
  margin-left: 0;
  margin-top: 0px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-bg {
  width: 100%;
  background-color: #F08300;
  padding-bottom: 77.9px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_paragraph {
  margin-top: -10px;
  margin-left: 15px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .md-only {
  display: none;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-study_heading4 {
  color: #F08300;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-study-inner {
  width: 100%;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus {
  margin-top: 60px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus-inner {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus_img01 {
  width: 100%;
  max-width: 391px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus_img02 {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus_paragraph {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 0.875rem;
  line-height: 2.14286;
  font-weight: 700;
  margin-top: 25px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus-grid {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus-grid_row .kl-focus-grid_col {
  padding: 0;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus-grid_row {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .p-btn.p-btn--lower.p-btn--school a {
  background-color: #F08300;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-curriculum_heading2::before {
  width: 34px;
  height: 51px;
  background-image: url("../img/lp/kitatech/common-item_05.png");
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-curriculum-title_border {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-curriculum-title_border::before {
  background-color: #F08300;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .table {
  border: 0;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table {
  display: flex;
  width: 100%;
  align-items: baseline;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table th, body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td {
  vertical-align: middle;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td {
  border-right: 1px solid #6f6f6f;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table th {
  border-right: 1px solid #F08300;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table th:last-of-type {
  border-right: 0 !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td:last-of-type {
  border-right: 0 !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .kl-item_th01,
body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .kl-item_th02,
body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .kl-item_th03,
body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .kl-item_th04 {
  min-width: 110px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .table {
  min-width: 100%;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .table01 td {
  padding: 10px !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .table01 th {
  padding: 12px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .table02 td {
  line-height: 1;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td:first-of-type {
  border-right: 1px solid #6f6f6f !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table th:nth-of-type(1),
body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td:nth-of-type(1) {
  width: 50% !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table th:nth-of-type(2),
body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td:nth-of-type(2) {
  width: 17% !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table th:nth-of-type(3),
body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td:nth-of-type(3) {
  width: 17% !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table th:nth-of-type(4),
body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table td:nth-of-type(4) {
  width: 17% !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .bg-orange {
  background-color: #FCC48B;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table02 {
  margin-top: 50px;
  margin-left: 230px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table02 caption {
  caption-side: bottom;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table02 th, body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table02 td {
  line-height: 1 !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item_text {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 0.875rem;
  width: 5%;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item__caption {
  font-size: 0.875rem;
  margin-left: 0px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item__caption::before {
  content: '';
  display: inline-block;
  width: 26px;
  height: 13px;
  background-color: #FCC48B;
  margin-right: 5px;
}

body.kita-t-b-w-lower .kita-htc-lower-inner .txt.bg-or {
  background-color: #F08300 !important;
  border-color: transparent !important;
}

body.kita-t-b-w-lower .kita-htc-lower-inner.kt-course1 .kl-curriculum {
  padding-top: 0;
}

body.kita-t-b-w-lower .kita-htc-lower-inner.kt-course1 .kl-calc {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kita-htc-lower-inner.kt-course1 .kl-calc .kl-calc_img {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-hero {
  background-image: url("../img/lp/kitatech/course2/htc-c2-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 {
  max-width: 420px;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 tr:last-of-type th:nth-of-type(2),
body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 tr:last-of-type td:nth-of-type(2) {
  border-right: none;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 tr th:nth-of-type(2),
body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 tr td:nth-of-type(2) {
  border-right: 1px solid #6f6f6f !important;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-curriculum {
  padding-top: 0;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item_text {
  width: 2%;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-curriculum-inner .kl-curriculum_tables {
  overflow: visible;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table {
  flex-wrap: wrap;
  justify-content: center;
  min-width: 300px;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table th:first-of-type,
body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table td:first-of-type {
  width: 20% !important;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 {
  width: 58%;
  margin-right: 0;
  min-width: auto;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 .kl-curriculum_td {
  border-right: 0 !important;
}

body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .kl-curriculum-comparison {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero {
  background-image: url("../img/lp/kitatech/course3/htc-c3-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_heddigng2_img {
  width: 91vw;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_heddigng2_img img {
  width: 100%;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_text_img {
  width: 49vw;
  max-width: 183px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-field .field04 li {
  border-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-field .field04 li::before {
  background: url("../img/lp/kitatech/htc-common-cook2.svg") center/cover no-repeat;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content .card .text {
  font-size: 14px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type {
  grid-column: auto / span 2;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .wide {
  width: 50%;
  max-width: 346px;
  padding-right: 0;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .wide img {
  width: 100%;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .text {
  width: calc(50% - 10px);
  padding: 0 10px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification {
  margin-top: 80px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification-bg {
  padding-bottom: 40px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification-list {
  width: 100%;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_img {
  width: 100%;
  max-width: 350px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_c1_heading3 {
  margin-top: 0 !important;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_ul {
  margin-left: 30px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_ul:last-of-type {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_ul li {
  text-indent: -20px;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_ul li span {
  font-size: 0.75rem;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-item-table .table01 th, body.kita-t-b-w-lower .kita-htc-course3-inner .kl-item-table .table01 td {
  padding: 6px !important;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification-flex {
  align-items: center;
  margin-left: 0;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-point {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification-bg {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_c1_heading3 {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_ul li {
  color: #fff;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-curriculum {
  padding-top: 0;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-calc {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kita-htc-course3-inner .kl-calc .kl-calc_img {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-point {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification-bg {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_c1_heading3 {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_ul li {
  color: #fff;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-hero {
  background-image: url("../img/lp/kitatech/course4/htc-c4-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_img .kl-qualification_img_01 {
  text-align: right;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_img .kl-qualification_img_01 img {
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  max-width: 132px;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_img .kl-qualification_img_02 {
  text-align: center;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_img .kl-qualification_img_02 img {
  max-width: 229px;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification-bg {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_ul li span {
  font-size: 0.625rem;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table {
  align-items: flex-start;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table th:nth-of-type(2),
body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table td:nth-of-type(2) {
  width: 20% !important;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table th:nth-of-type(1),
body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table td:nth-of-type(1) {
  width: 20% !important;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table01 th, body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table01 td {
  padding: 10px !important;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table02 {
  margin-left: 30px;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-point {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification-bg {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_c1_heading3 {
  background-color: #A84F25;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_ul li {
  color: #fff;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-curriculum {
  padding-top: 0;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-calc {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kita-htc-course4-inner .kl-calc .kl-calc_img {
  padding-bottom: 0;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero {
  background-image: url("../img/lp/kitatech/course5/htc-c5-bg-item-sp_01.jpg");
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_heddigng2_img {
  max-width: 341px;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-point {
  background-color: #FA7402;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification-bg {
  background-color: #FA7402;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_c1_heading3 {
  background-color: #FA7402;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_text_img {
  max-width: 306px;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification-flex {
  margin-left: 0;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_heading3 {
  max-width: auto;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification-list {
  width: 84%;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_ul {
  margin-left: 10px;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_ul li {
  text-indent: -30px;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-q_paragraph {
  font-size: 1rem;
  letter-spacing: 0.1em;
  line-height: 1.875;
  font-weight: 700;
  color: #fff;
  text-decoration: underline;
  margin-top: 40px;
  text-align: center;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links_text {
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.57895;
  text-align: center;
  color: #F08300;
  position: relative;
  display: inline-block;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links_text::before,
body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links_text::after {
  content: '';
  display: inline-block;
  width: 70px;
  height: 1px;
  background-color: #F08300;
  position: absolute;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links_text::before {
  top: 35px;
  left: -50px;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links_text::after {
  top: 35px;
  right: -50px;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links-inner {
  text-align: center;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links-inner img {
  display: block;
  width: 100%;
  text-align: center;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links-inner img:nth-of-type(1) {
  max-width: 350px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links-inner img:nth-of-type(2) {
  max-width: 268px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-curriculum_table-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .table {
  max-width: 510px;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .table th, body.kita-t-b-w-lower .kita-htc-course5-inner .table td {
  width: auto !important;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .table th:nth-of-type(1),
body.kita-t-b-w-lower .kita-htc-course5-inner .table td:nth-of-type(1) {
  width: 35% !important;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .table th:nth-of-type(2),
body.kita-t-b-w-lower .kita-htc-course5-inner .table td:nth-of-type(2) {
  width: 15% !important;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .table th:nth-of-type(3),
body.kita-t-b-w-lower .kita-htc-course5-inner .table td:nth-of-type(3) {
  width: 50% !important;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-calc_img {
  display: block;
  -ms-grid-row-align: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-item-table td.kl-curriculum_text {
  border-bottom: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  text-align: right;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-item-table .table01 {
  min-width: 550px;
}

body.kita-t-b-w-lower .kita-htc-course5-inner .kl-curriculum_tables {
  overflow-x: scroll;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item {
  align-self: baseline;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item--inner {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item-ta {
  font-size: 1rem;
  margin-top: 0;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item-tb {
  font-size: 0.875rem;
  color: #212224;
  border-bottom: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 4px 10px;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item_ul {
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item_ul .course__slide-item_li {
  position: relative;
  font-size: 0.75rem;
  padding-left: 10px;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item_ul .course__slide-item_li:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 3px;
  left: -13px;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item-text {
  font-size: 0.8125rem;
  padding: 10px;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .wrap-2col .col-wrap {
  width: calc(50% - 1px);
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .wrap-2col .col-wrap:not(:last-of-type) {
  border-right: 2px solid #E4E4E4;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--01 .course__slide-item--inner {
  background-color: #FAD9DE;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--01 .course__slide-item-ta {
  background-color: #E73273;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--01 .course__slide-item-tb {
  background-color: #F085A7;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--01 .course__slide-item_li:before {
  background-color: #E73273;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--02 .course__slide-item--inner {
  background-color: #E3D9EB;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--02 .course__slide-item-ta {
  background-color: #915DA3;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--02 .course__slide-item-tb {
  background-color: #C59AC0;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--02 .course__slide-item_li:before {
  background-color: #915DA3;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--03 .course__slide-item--inner {
  background-color: #E3EFD1;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--03 .course__slide-item-ta {
  background-color: #6FBA2C;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--03 .course__slide-item-tb {
  background-color: #B3C984;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--03 .course__slide-item_li:before {
  background-color: #6FBA2C;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--04 .course__slide-item--inner {
  background-color: #EDF2D1;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--04 .course__slide-item-ta {
  background-color: #ABC808;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--04 .course__slide-item-tb {
  background-color: #CCDC6C;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--04 .course__slide-item_li:before {
  background-color: #ABC808;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--05 .course__slide-item--inner {
  background-color: #FBDDE3;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--05 .course__slide-item-ta {
  background-color: #E95383;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--05 .course__slide-item-tb {
  background-color: #F297B3;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--05 .course__slide-item_li:before {
  background-color: #E95383;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--06 .course__slide-item--inner {
  background-color: #DBEFF4;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--06 .course__slide-item-ta {
  background-color: #1BB8CE;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--06 .course__slide-item-tb {
  background-color: #7AD3E0;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--06 .course__slide-item_li:before {
  background-color: #1BB8CE;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--07 .course__slide-item--inner {
  background-color: #E4F2EC;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--07 .course__slide-item-ta {
  background-color: #76C5AB;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--07 .course__slide-item-tb {
  background-color: #ACDBCB;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--07 .course__slide-item_li:before {
  background-color: #76C5AB;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--08 .course__slide-item--inner {
  background-color: #FDE8C8;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--08 .course__slide-item-ta {
  background-color: #F39800;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--08 .course__slide-item-tb {
  background-color: #F6B86E;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--08 .course__slide-item_li:before {
  background-color: #F39800;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--09 .course__slide-item--inner {
  background-color: #DFE9F6;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--09 .course__slide-item-ta {
  background-color: #2990D0;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--09 .course__slide-item-tb {
  background-color: #90B4D6;
}

body.kita-t-b-w-lower .hsc-course-inner .course__slide-item--09 .course__slide-item_li:before {
  background-color: #2990D0;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_tables .table1 {
  width: 100%;
  max-width: 310px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--01 {
  width: 357px;
  min-width: 357px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--01 p {
  font-size: 1rem;
  font-weight: 700;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--01 .table1 tr th {
  width: 55%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--01 .table1 tr td {
  width: 55%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 {
  width: 588px;
  min-width: 588px;
  margin-left: 30px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 p {
  font-size: 1rem;
  font-weight: 700;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 .table2 tr th:nth-of-type(1) {
  width: 30%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 .table2 tr th:nth-of-type(2) {
  width: 50%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 .table2 tr th:nth-of-type(3) {
  width: 20%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 .table2 tr td:nth-of-type(1) {
  width: 30%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 .table2 tr td.kl-curriculum_table-border {
  border-right: 1px solid #6F6F6F;
  width: 50%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_table-item--02 .table2 tr td:nth-of-type(3) {
  width: 20%;
  padding: 5px;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-study .hsc-course-study:first-of-type {
  margin-top: 0;
}

body.kita-t-b-w-lower .hsc-course-inner .kl-study .hsc-course-study:last-of-type {
  padding-bottom: 60px;
}

body.kita-lower-shokujitsu .link-page-btm {
  position: relative;
}

body.kita-lower-shokujitsu .link-page-btm::before {
  background-color: #43966C;
}

body.kita-lower-shokujitsu .link-page-btm::after {
  content: '';
  display: block;
  background-image: url("../img/pages/iryo/img-shokujitsu07.png");
  position: absolute;
  width: 100vw;
  background-repeat: repeat;
  background-size: 312px;
  background-position: top 0 left 0;
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  margin-left: -50vw;
}

body.kita-lower-shokujitsu div.list-merit--wrap::before {
  background-image: url("../img/pages/iryo/img-shokujitsu-item_01.svg");
}

body.kita-lower-shokujitsu div.list-merit--wrap::after {
  background-image: url("../img/pages/iryo/img-shokujitsu-item_02.svg");
}

body.hsc.course .kl__inner {
  max-width: 1130px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

body.hsc.course .color-505050 {
  color: #505050;
}

body.hsc.course .kl__title {
  width: 100%;
  background-color: #F5B6D2;
  margin-bottom: 40px;
}

body.hsc.course .kl__title .wrap {
  display: flex;
  -webkit-column-gap: 50px;
  -moz-column-gap: 50px;
  column-gap: 50px;
  align-items: center;
  padding: 60px 0;
}

body.hsc.course .kl__title h2 {
  background-color: #E85298;
  padding: 6px 50px;
  color: #fff;
  max-width: 310px;
  font-size: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

body.hsc.course .kl__title span.text {
  font-size: 20px;
}

body.hsc.course .kl__title .parts {
  position: absolute;
  right: 5%;
  top: 19px;
}

body.hsc.course .kl__title .parts._02 {
  top: 56px;
}

body.hsc.course .kl-parts1 {
  background: repeating-linear-gradient(-45deg, #fef7fa, #fef7fa 10px, #fff 0, #fff 20px);
}

body.hsc.course .kl-parts1 .ksp-inner {
  width: 100%;
  max-width: 1220px;
  margin-inline: auto;
  position: relative;
  width: 92%;
}

body.hsc.course .kl-parts1 .ksp-wrap {
  width: 100%;
  margin-left: -80px;
  margin-left: 0px;
}

body.hsc.course .kl-parts1 .ksp-wrap .text {
  width: 76%;
  max-width: 914px;
}

body.hsc.course .kl-parts1 .ksp-wrap .img {
  width: 24%;
  max-width: 294px;
}

body.hsc.course .kl-parts1 .ksp-wrap img {
  width: 100%;
}

body.hsc.course .kl-parts1 .kl-parts1 {
  background: repeating-linear-gradient(-45deg, #fef7fa, #fef7fa 10px, #fff 0, #fff 20px);
}

body.hsc.course .kl-parts1 .ksp-inner {
  width: 100%;
  max-width: 1220px;
  margin-inline: auto;
  position: relative;
  width: 92%;
}

body.hsc.course .kl-parts1 .ksp-wrap {
  width: 100%;
  margin-left: -80px;
  position: relative;
  margin-left: 0px;
}

body.hsc.course .kl-parts1 .ksp-wrap .text {
  width: 76%;
  max-width: 914px;
}

body.hsc.course .kl-parts1 .ksp-wrap .img {
  width: 24%;
  max-width: 294px;
}

body.hsc.course .kl-parts1 .ksp-wrap img {
  width: 100%;
}

body.hsc.course .kl-parts1 .point__item {
  position: absolute;
  width: 100%;
}

body.hsc.course .kl-parts1 .point__item--01 {
  max-width: 186px;
  width: 12vw;
  top: 34px;
  left: -8vw;
}

body.hsc.course .kl-parts1 .point__item--02 {
  max-width: 214px;
  width: 14vw;
  top: 54px;
  right: -2vw;
}

body.hsc.course .kl01 {
  position: relative;
  width: 100%;
}

body.hsc.course .kl01 .kl-lower .text {
  font-size: 20px;
}

body.hsc.course .kl02__title {
  margin-bottom: 40px;
}

body.hsc.course .kl02__images {
  max-width: 852px;
  width: 88%;
  margin-inline: auto;
}

body.hsc.course .kl02__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 960px;
  margin-inline: auto;
  margin-top: 40px;
}

body.hsc.course .kl02__list ul li {
  margin-bottom: 10px;
  font-size: 18px;
}

body.hsc.course .kl02__list ul li span.quali__label {
  color: white;
  background-color: black;
  text-align: center;
  display: -ms-inline-grid;
  display: inline-grid;
  place-items: center;
  width: 83px;
  font-size: 0.875rem;
  font-weight: bold;
  margin-right: 0.7rem;
  padding: 2px 5px;
}

body.hsc.course .kl02 h4.nk02__title {
  font-size: 30px;
  color: #E85298;
}

body.hsc.course .kl02__lower {
  max-width: 960px;
  margin-inline: auto;
}

body.hsc.course .kl02__lower img {
  min-width: 382px;
}

body.hsc.course .kl03__inner {
  max-width: 1000px;
  margin-inline: auto;
}

body.hsc.course .kl03__upper {
  width: 100%;
}

body.hsc.course .kl03__upper img {
  width: 100%;
}

body.hsc.course .kl03__mit {
  display: -ms-grid;
  display: grid;
  width: 94%;
  max-width: 860px;
  margin-inline: auto;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

body.hsc.course .kl03__mit .item {
  background-color: #FDEEF4;
  padding: 20px 25px;
  color: #333333;
}

body.hsc.course .kl03__mit .item .img-wrap {
  width: 100%;
}

body.hsc.course .kl03__mit .item .img-wrap.check {
  position: relative;
}

body.hsc.course .kl03__mit .item .img-wrap.check::after {
  content: '';
  display: inline-block;
  width: 56px;
  height: 28px;
  background: url("../img/lp/hsc/course//img_course_item19.svg") center/cover no-repeat;
  position: absolute;
  top: -4px;
  right: -15px;
}

body.hsc.course .kl03__mit .item .img-wrap img {
  width: 100%;
}

body.hsc.course .kl03__lower {
  width: 100%;
  position: relative;
}

body.hsc.course .kl04__top {
  width: 90%;
  margin-inline: auto;
}

body.hsc.course .kl04__top img {
  width: 100%;
}

body.hsc.course .kl04__text {
  color: #333333;
}

body.hsc.course .kl04__lower {
  width: 92%;
  max-width: 876px;
  margin-inline: auto;
}

body.hsc.course .kl05__upper {
  max-width: 856px;
  margin-inline: auto;
}

body.hsc.course .kl05__upper .img-wrap {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 36px 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
}

body.hsc.course .kl05__upper .img-wrap .item {
  position: relative;
}

body.hsc.course .kl05__upper .img-wrap .item::before {
  content: '';
  display: inline-block;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20px;
  right: -20px;
}

body.hsc.course .kl05__upper .img-wrap .item:nth-of-type(1)::before {
  background: url("../img/lp/hsc/course/img_course_item25a.svg") center/cover no-repeat;
}

body.hsc.course .kl05__upper .img-wrap .item:nth-of-type(2)::before {
  background: url("../img/lp/hsc/course/img_course_item26a.svg") center/cover no-repeat;
}

body.hsc.course .kl05__upper .img-wrap .item:nth-of-type(3)::before {
  background: url("../img/lp/hsc/course/img_course_item27a.svg") center/cover no-repeat;
}

body.hsc.course .kl05__upper .img-wrap .item:nth-of-type(4)::before {
  background: url("../img/lp/hsc/course/img_course_item28a.svg") center/cover no-repeat;
}

body.hsc.course .kl05__mit {
  width: 100%;
}

body.hsc.course .kl05__mit .img-wrap {
  width: 100%;
  margin-inline: auto;
}

body.hsc.course .kl05__mit .img-wrap img {
  width: 100%;
}

body.hsc.course .kl-movie .video-indexwrap {
  margin-bottom: 100px !important;
}

body.hsc.course .section__tech-materials .materials__items {
  max-width: 1000px;
  margin: auto;
  background-color: #FDEEF4;
  position: relative;
  padding: 1rem 10px;
  margin-bottom: 6.75rem;
}

body.hsc.course .section__tech-materials .materials__items .item {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

body.hsc.course .section__tech-materials .materials__item {
  padding: 20px 0;
}

body.hsc.course .section__tech-materials .examination__header {
  font-size: 1.4375rem;
  background-color: #000;
  color: white;
  font-weight: bold;
  padding: .1rem 2rem;
  margin-bottom: 5rem;
}

body.hsc.course .section__tech-materials .examination__inner .examination__item {
  position: relative;
}

body.hsc.course .section__tech-materials .examination__inner .examination__item .examination__item__inner {
  position: relative;
}

body.hsc.course .section__tech-materials .examination__inner .examination__item .examination__item__inner:after {
  content: '';
  position: absolute;
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
  display: block;
  margin: auto;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  border: 5px solid #E85298;
  z-index: -1;
}

body.hsc.course .section__tech-materials .examination__inner .examination__head {
  font-size: 1.875rem;
  font-weight: 900;
  background-color: #E85298;
  padding: .2rem 1rem;
  color: white;
  display: inline-block;
}

body.hsc.course .section__tech-materials .examination__inner .examination__per {
  font-size: 4.375rem;
  font-weight: 900;
  color: #DB1966;
  line-height: 1;
  position: relative;
}

body.hsc.course .section__tech-materials .examination__inner .examination__per span {
  font-size: 2.125rem;
}

body.hsc.course .section__tech-materials .examination__inner .examination__per .hukidashi {
  position: absolute;
  left: 0;
  top: 100%;
}

body.hsc.course .course__slide .slick-arrow {
  transition: .2s;
  transition: .2s;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  pointer-events: all;
}

body.hsc.course .course__slide .slick-arrow:hover {
  opacity: .7;
}

body.hsc.course .course__slide .slick-arrow.slick-disabled {
  pointer-events: none;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

body.hsc.course .course__slide .slick-next {
  background-color: transparent;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 0;
  width: 0;
  border-width: 22px 0 22px 22px;
  border-style: solid;
  border-color: transparent transparent transparent #E85298;
  z-index: 10;
}

body.hsc.course .course__slide .slick-prev {
  background-color: transparent;
  left: 0;
  top: 0;
  right: auto;
  bottom: 0;
  margin: auto;
  height: 0;
  width: 0;
  border-width: 22px 22px 22px 0;
  border-style: solid;
  border-color: transparent #E85298 transparent transparent;
  z-index: 10;
}

body.hsc.course .course__slide .course__slide-item {
  margin-right: 2.5rem;
}

body.hsc.course .course__slide .slick-slide img {
  margin: auto;
}

body.hsc .section__course.section {
  padding-top: 0;
}

body.hsc .kl-s__stregths {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

body.hsc .section__corabo__text {
  background-color: #EB6EA5;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-top: 100px;
  padding-bottom: 100px;
}

body.lp {
  font-family: "notosansJP";
}

body.lp .text-orange {
  color: #fd7e14;
}

body.lp .text-green {
  color: #43AA88;
}

body.lp .text-pink {
  color: #DB76A4;
}

body.lp .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  background-color: #47B2B0;
}

body.lp .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #388e8c;
}

body.lp .p-form__btn__wrap .p-next-btn__wrap button:hover {
  background: #40a09e;
}

body.lp .hero {
  margin-bottom: 8rem;
}

body.lp.opencampus .section {
  padding-top: 6.5625rem;
  padding-bottom: 6.5625rem;
}

body.lp.opencampus .head__wrap:not(.not), body.lp.opencampus.parent-group .section__04 .head__wrap:not(.not) {
  text-align: center;
  -webkit-transform: rotate(-6deg);
  transform: rotate(-6deg);
  position: relative;
  margin-bottom: 2.5rem;
  opacity: 0;
}

body.lp.opencampus .head__wrap:not(.not) h2, body.lp.opencampus.parent-group .section__04 .head__wrap:not(.not) h2 {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  color: #333333;
}

body.lp.opencampus .head__wrap:not(.not) .f-century {
  color: #DB76A4;
  font-size: 1.5625rem;
  letter-spacing: 0.05em;
}

body.lp.opencampus .head__wrap.is_scrolled:not(.not) {
  -webkit-animation: buruburu .5s ease-in-out forwards;
  animation: buruburu .5s ease-in-out forwards;
}

@-webkit-keyframes buruburu {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  60% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  80% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
}

@keyframes buruburu {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  60% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  80% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
}

body.lp.opencampus .oc-main-under {
  display: block;
  padding-top: 3.2rem;
  padding-bottom: 5rem;
}

body.lp.opencampus .oc-main-under__block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

body.lp.opencampus .oc-main-under__block-item {
  padding: 0 3px;
  width: calc(100% / 4);
}

body.lp.opencampus .oc-schedule {
  background-color: #FCF25D;
}

body.lp.opencampus .oc-schedule-heading {
  position: relative;
  padding-bottom: 30px;
  text-align: center;
}

body.lp.opencampus .oc-schedule-heading::after {
  content: '';
  position: absolute;
  bottom: 1px;
  right: 50%;
  display: block;
  width: 38px;
  height: 30px;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

body.lp.opencampus .oc-schedule-heading-text {
  display: block;
  width: 100%;
  padding: 1em 10px;
  color: #fff;
  letter-spacing: .1em;
  font-size: 1.5rem;
  font-weight: bold;
}

body.lp.opencampus .oc-schedule__block {
  padding: 4.5rem .5rem;
  position: relative;
  z-index: 4;
}

body.lp.opencampus .oc-schedule__block__container {
  max-width: 1020px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}

body.lp.opencampus .oc-schedule__list {
  display: flex;
  margin-top: -5%;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
}

body.lp.opencampus .oc-schedule__list-item {
  width: 21.25%;
  margin-top: 5%;
  margin-right: 5%;
}

body.lp.opencampus .oc-schedule__list-item:last-of-type {
  margin-right: 0;
}

body.lp.opencampus .oc-schedule__list-item-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 200px;
  height: 200px;
  border: 4px solid transparent;
  border-radius: 50%;
  background-color: #fff;
}

body.lp.opencampus .oc-schedule__list-item-wrap::before {
  content: '';
  position: absolute;
  bottom: -10px;
  right: -10px;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}

body.lp.opencampus .oc-schedule__list-item-wrap p {
  color: #696969;
  font-weight: bold;
}

body.lp.opencampus .oc-schedule__list-item-wrap p.year {
  font-size: 1.5625rem;
}

body.lp.opencampus .oc-schedule__list-item-wrap p.date {
  font-size: 2.625rem;
}

body.lp.opencampus .oc-schedule__list-item-wrap p.week {
  font-size: 1.5625rem;
}

body.lp.opencampus .oc-schedule.oc-schedule-hsc .oc-schedule-heading::after {
  background-image: url(../img/lp/oc/hsc/oc_schedule-arrow.svg);
  background-size: contain;
}

body.lp.opencampus .oc-schedule.oc-schedule-hsc .oc-schedule-heading-text {
  background-color: #DB76A4;
}

body.lp.opencampus .oc-schedule.oc-schedule-hsc .oc-schedule__list-item-wrap {
  border-color: #DB76A4;
}

body.lp.opencampus .oc-schedule.oc-schedule-hsc .oc-schedule__list-item-wrap::before {
  background-color: #DB76A4;
}

body.lp.opencampus .oc-schedule.oc-schedule-htc .oc-schedule-heading::after {
  background-image: url(../img/lp/oc/htc/oc_schedule-arrow.svg);
  background-size: contain;
}

body.lp.opencampus .oc-schedule.oc-schedule-htc .oc-schedule-heading-text {
  background-color: #FF7603;
}

body.lp.opencampus .oc-schedule.oc-schedule-htc .oc-schedule__list-item-wrap {
  border-color: #FF7603;
}

body.lp.opencampus .oc-schedule.oc-schedule-htc .oc-schedule__list-item-wrap::before {
  background-color: #FF7603;
}

body.lp.opencampus .oc-schedule.oc-schedule-iryo .oc-schedule-heading::after {
  background-image: url(../img/lp/oc/iryo/oc_schedule-arrow.svg);
  background-size: contain;
}

body.lp.opencampus .oc-schedule.oc-schedule-iryo .oc-schedule-heading-text {
  background-color: #43AA88;
}

body.lp.opencampus .oc-schedule.oc-schedule-iryo .oc-schedule__list-item-wrap {
  border-color: #43AA88;
}

body.lp.opencampus .oc-schedule.oc-schedule-iryo .oc-schedule__list-item-wrap::before {
  background-color: #43AA88;
}

body.lp.opencampus .section__01 {
  margin-bottom: 7rem;
  margin-top: 4rem;
}

body.lp.opencampus .section__01 h2 {
  font-weight: bold;
  color: #47B2B0;
  display: inline-block;
  letter-spacing: 2px;
  position: absolute;
  top: -4rem;
  left: 0;
  z-index: 10;
  font-size: 1.25rem;
}

body.lp.opencampus .section__01 h2 span {
  position: relative;
}

body.lp.opencampus .section__01 .section-heading.is_scrolled:after {
  transition: .5s;
  width: 100%;
}

body.lp.opencampus .section__01 .section-heading.is_scrolled span:after {
  transition: .5s;
  width: 100%;
}

body.lp.opencampus .section__01 .slick-dots {
  display: flex;
}

body.lp.opencampus .section__01 .slick-dots button {
  background-color: #F0F0F0;
  width: 100%;
  height: 15px;
  position: relative;
}

body.lp.opencampus .section__01 .slick-dots button:before {
  content: "";
  background-color: #DB76A4;
  width: 0;
  height: 100%;
  opacity: 1;
}

body.lp.opencampus .section__01 .slick-dots li {
  margin-left: 0;
  width: calc((100% - 16px) / 6);
  height: auto;
}

body.lp.opencampus .section__01 .slick-dots li:not(:last-of-type) {
  margin-right: 4px;
}

body.lp.opencampus .section__01 .slick-dots li.slick-active button:before {
  transition: 3.4s linear;
  width: 100%;
}

body.lp.opencampus .section__01 .section__01__block__inner {
  margin-bottom: 5rem;
  position: relative;
}

body.lp.opencampus .section__01 .section__01__block {
  position: relative;
}

body.lp.opencampus .section__01 .text__block {
  margin-top: 3rem;
  line-height: 2;
  font-size: 1rem;
  padding: 0;
}

body.lp.opencampus.parent-group .hero {
  margin-bottom: 0;
}

body.lp.opencampus.parent-group h1 {
  margin-bottom: 0;
}

body.lp.opencampus.parent-group h1 img {
  width: 100%;
}

body.lp.opencampus.parent-group .head__wrap:not(.not) {
  color: #47B2B0;
}

body.lp.opencampus.parent-group .section__imgs {
  padding: 55px 0 80px;
}

body.lp.opencampus.parent-group .section__imgs .inner {
  width: 97%;
  margin: 0 auto;
  max-width: 1300px;
}

body.lp.opencampus.parent-group .section__imgs .imgs-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 6px 1fr 6px 1fr 6px 1fr;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
}

body.lp.opencampus.parent-group .section__00 h2 {
  text-align: center;
  color: white;
  background-color: #DB76A4;
  font-weight: bold;
  font-size: 1.5625rem;
  padding: 1rem 0.5rem;
  position: relative;
  margin-bottom: 0;
}

body.lp.opencampus.parent-group .section__00 h2:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: auto;
  border-width: 20px 10px 0 10px;
  border-color: #DB76A4 transparent transparent transparent;
  border-style: solid;
  width: 0;
  height: 0;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap {
  background-color: #FCF25D;
  padding: 4.5rem .5rem;
  position: relative;
  z-index: -1;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block {
  font-weight: bold;
  color: #696969;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: -5%;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block li .item {
  border-radius: 50%;
  border: 4px solid #DB76A4;
  background-color: white;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: auto;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block li .item:after {
  content: '';
  display: block;
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #DB76A4;
  z-index: -1;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block .year {
  font-size: 1.5625rem;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block .date {
  font-size: 2.625rem;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block .week {
  font-size: 1.5625rem;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #47B2B0;
  z-index: 10;
}

body.lp.opencampus.parent-group .section__00 .schedule__wrap.is_scrolled:after {
  transition: 0.6s cubic-bezier(0.72, 0.02, 0.26, 1);
  width: 0;
}

body.lp.opencampus.parent-group .main__inner {
  background: repeating-linear-gradient(-45deg, #DEF2FB 0, #DEF2FB 7px, #EEF8FD 0, #EEF8FD 14px);
}

body.lp.opencampus.parent-group .main__inner--01 {
  padding: 6.2rem 0 0;
}

body.lp.opencampus.parent-group .main__inner--02 {
  padding-top: 10rem;
}

body.lp.opencampus.parent-group .section__02 {
  padding: 4.2rem 10px;
  position: relative;
}

body.lp.opencampus.parent-group .section__02:before {
  content: '';
  display: block;
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  z-index: 4;
  height: calc(100% - 13%);
  background: url(../img/lp/oc/img_section2_cracker.png) repeat-x;
}

body.lp.opencampus.parent-group .section__02 .inner__container {
  position: relative;
  z-index: 10;
}

body.lp.opencampus.parent-group .section__02 .item {
  position: absolute;
  z-index: 5;
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

body.lp.opencampus.parent-group .section__02 .item.is_scrolled {
  -webkit-filter: brightness(1);
  filter: brightness(1);
  transition: 1s ease-out;
}

body.lp.opencampus.parent-group .section__02 .item--01 {
  left: -50px;
  top: 0;
  width: 28%;
  max-width: 420px;
}

body.lp.opencampus.parent-group .section__02 .item--02 {
  right: 0;
  top: 0;
  width: 24%;
  max-width: 367px;
}

body.lp.opencampus.parent-group .section__02 .item--03 {
  left: -40px;
  bottom: -100px;
  width: 21%;
  max-width: 294px;
}

body.lp.opencampus.parent-group .section__02 .item--04 {
  right: -10px;
  bottom: -120px;
  width: 21%;
  max-width: 293px;
}

body.lp.opencampus.parent-group .section__02 .lg__container {
  max-width: 1400px;
  margin: auto;
  background-color: #fff;
  border-radius: 40px;
  padding: 4.5rem 0 4rem;
  position: relative;
}

body.lp.opencampus.parent-group .section__02 .future__lists .thumb {
  position: relative;
}

body.lp.opencampus.parent-group .section__02 .future__lists .future__name {
  color: white;
  font-weight: bold;
  width: 100%;
  display: block;
  padding: .5rem;
  font-size: 1.25rem;
}

body.lp.opencampus.parent-group .section__02 .future__lists .future__name.label--pink {
  background-color: #DB76A4;
}

body.lp.opencampus.parent-group .section__02 .future__lists .future__name.label--orange {
  background-color: #fd7e14;
}

body.lp.opencampus.parent-group .section__02 .future__lists .future__name.label--green {
  background-color: #43AA88;
}

body.lp.opencampus.parent-group .section__04 {
  margin-bottom: 10rem;
  padding-left: 10px;
  padding-right: 10px;
}

body.lp.opencampus.parent-group .section__04 .container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: url(../img/lp/oc/img_section4_bg.jpg) no-repeat;
  background-size: cover;
}

body.lp.opencampus.parent-group .section__04.is_active .container {
  padding-bottom: 14rem;
}

body.lp.opencampus.parent-group .section__04 .head__wrap {
  color: #DB76A4;
}

body.lp.opencampus.parent-group .section__04 .point__lists {
  position: relative;
}

body.lp.opencampus.parent-group .section__04 .point__lists li {
  position: static;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--01 .click__content {
  background-color: #FAD0D5;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--01 .click__content:after {
  border-color: transparent transparent #FAD0D5 transparent;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--01 figcaption {
  background: linear-gradient(transparent 60%, #FAD0D5 60%);
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--01 button.is_active img {
  border: 10px solid #FAD0D5;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--01 .label {
  background-color: #FAD0D5;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--02 .click__content {
  background-color: #B6E3E2;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--02 .click__content:after {
  border-color: transparent transparent #B6E3E2 transparent;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--02 figcaption {
  background: linear-gradient(transparent 60%, #B6E3E2 60%);
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--02 button.is_active img {
  border: 10px solid #B6E3E2;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--02 .label {
  background-color: #B6E3E2;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--03 .click__content {
  background-color: #FDDE88;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--03 .click__content:after {
  border-color: transparent transparent #FDDE88 transparent;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--03 figcaption {
  background: linear-gradient(transparent 60%, #FDDE88 60%);
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--03 button.is_active img {
  border: 10px solid #FDDE88;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--03 .label {
  background-color: #FDDE88;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--04 .click__content {
  background-color: #C3E6A4;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--04 .click__content:after {
  border-color: transparent transparent #C3E6A4 transparent;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--04 figcaption {
  background: linear-gradient(transparent 60%, #C3E6A4 60%);
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--04 button.is_active img {
  border: 10px solid #C3E6A4;
}

body.lp.opencampus.parent-group .section__04 .point__lists li.point__content--04 .label {
  background-color: #C3E6A4;
}

body.lp.opencampus.parent-group .section__04 figure {
  text-align: center;
}

body.lp.opencampus.parent-group .section__04 figure button {
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  transition: .2s;
}

body.lp.opencampus.parent-group .section__04 figure button:hover {
  opacity: .7;
}

body.lp.opencampus.parent-group .section__04 figure button img {
  border-radius: 50%;
}

body.lp.opencampus.parent-group .section__04 figure button .label {
  position: absolute;
  left: 0;
  top: 0;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

body.lp.opencampus.parent-group .section__04 figure button.is_active .label {
  color: #dc3545;
}

body.lp.opencampus.parent-group .section__04 figure .click__content {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  width: 100%;
  font-weight: bold;
  font-size: 1.125rem;
  text-align: left;
  border-radius: 30px;
  padding: 2rem;
}

body.lp.opencampus.parent-group .section__04 figure .click__content:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  border-width: 0 15px 40px;
  border-style: solid;
}

body.lp.opencampus.parent-group .section__04 figure .click__content.is_active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: .2s;
}

body.lp.opencampus.parent-group .section__03 .head__wrap {
  color: #DB76A4;
}

body.lp.opencampus.parent-group .section__03 .head__wrap h2:before {
  background: url(../img/lp/oc/img_section3_pink-star.svg) no-repeat;
  background-size: cover;
}

body.lp.opencampus.parent-group .section__03 .head__wrap h2:after {
  background: url(../img/lp/oc/img_section3_pink-star.svg) no-repeat;
  background-size: cover;
}

body.lp.opencampus.parent-group .section__03 + .inner__container {
  margin-bottom: 6.2rem;
}

body.lp.opencampus.parent-group .section__03 .inner__container {
  background-color: #fff;
  border-radius: 20px;
  padding-top: 4rem;
  padding-bottom: 8rem;
  margin-bottom: 10rem;
  position: relative;
}

body.lp.opencampus.parent-group .section__03 .inner__container.is_scrolled .ch--01,
body.lp.opencampus.parent-group .section__03 .inner__container.is_scrolled .ch--03 {
  transition: .5s;
  -webkit-transform: translate(0);
  transform: translate(0);
}

body.lp.opencampus.parent-group .section__03 .ch--01 {
  position: absolute;
  left: 14%;
  bottom: 100%;
  -webkit-transform: translate(210px, 0px);
  transform: translate(210px, 0px);
}

body.lp.opencampus.parent-group .section__03 .ch--02 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  margin: auto;
  z-index: 10;
}

body.lp.opencampus.parent-group .section__03 .ch--03 {
  position: absolute;
  right: 14%;
  bottom: 100%;
  -webkit-transform: translate(-190px, 0px);
  transform: translate(-190px, 0px);
}

body.lp.opencampus.parent-group .section__03 .merit-item.is_scrolled .merit_ch {
  -webkit-animation: ch_jump .4s forwards;
  animation: ch_jump .4s forwards;
}

@-webkit-keyframes ch_jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ch_jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.lp.opencampus.parent-group .section__03 .merit_ch {
  margin: auto;
}

body.lp.opencampus.parent-group .section__05 {
  margin-bottom: 5rem;
  margin-top: 6rem;
}

body.lp.opencampus.parent-group .section__05 .head__wrap-text p {
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 1.94444;
  margin: 3.2rem 0 3.8rem;
  color: #000;
}

body.lp.opencampus.parent-group .section__06 {
  margin-bottom: 5rem;
}

body.lp.opencampus.parent-group .section__06 .number .head {
  background-color: #47B2B0;
}

body.lp.opencampus.parent-group .section__06 .number .contents {
  color: #47B2B0;
  background-color: #E2F9F9;
}

body.lp.opencampus.parent-group .section__06 .number .current-num {
  color: #47B2B0;
  border: 3px solid #47B2B0;
}

body.lp.opencampus.parent-group .section__06:not(:last-of-type) .number:after {
  background: url(../img/lp/oc/img_section6_step01.svg) no-repeat;
  background-position: 0 0;
}

body.lp.opencampus.parent-group .section__07 .container {
  padding-top: 4rem;
  background: #fff url(../img/lp/oc/img_section7_bg01.png) no-repeat;
  background-position: center bottom;
  background-size: 100%;
}

body.lp.opencampus.parent-group .section__07 .item01 {
  position: absolute;
  left: 0;
  top: 20px;
}

body.lp.opencampus.parent-group .section__07 li .tab-btn2.tab-btn--01 {
  border-color: #DB76A4;
  color: #fff;
  background-color: #DB76A4;
}

body.lp.opencampus.parent-group .section__07 li .tab-btn2.tab-btn--02, body.lp.opencampus.parent-group .section__07 li .tab-btn2.tab-btn--03 {
  border-color: #fd7e14;
  color: #fff;
  background-color: #fd7e14;
}

body.lp.opencampus.parent-group .section__07 li .tab-btn2.tab-btn--04, body.lp.opencampus.parent-group .section__07 li .tab-btn2.tab-btn--05, body.lp.opencampus.parent-group .section__07 li .tab-btn2.tab-btn--06 {
  border-color: #43AA88;
  color: #fff;
  background-color: #43AA88;
}

body.lp.opencampus.parent-group .section__07 li.is_active .tab-btn2.tab-btn--01 {
  color: #DB76A4;
  background-color: #fff;
}

body.lp.opencampus.parent-group .section__07 li.is_active .tab-btn2.tab-btn--02, body.lp.opencampus.parent-group .section__07 li.is_active .tab-btn2.tab-btn--03 {
  color: #fd7e14;
  background-color: #fff;
}

body.lp.opencampus.parent-group .section__07 li.is_active .tab-btn2.tab-btn--04, body.lp.opencampus.parent-group .section__07 li.is_active .tab-btn2.tab-btn--05, body.lp.opencampus.parent-group .section__07 li.is_active .tab-btn2.tab-btn--06 {
  color: #43AA88;
  background-color: #fff;
}

body.lp.opencampus.parent-group .section__08 .inner__container {
  padding-bottom: 80px;
}

body.lp.opencampus.parent-group .section__10 .text {
  color: #47B2B0;
}

body.lp.opencampus.parent-group .section__13 h2 {
  background: #47B2B0;
}

body.lp.opencampus.child-page .section__01 {
  margin-top: 12rem;
}

body.lp.opencampus.child-page .section__01 h2 {
  top: -7.5rem;
}

body.lp.opencampus.child-page .section__01 .text__block {
  line-height: 2.14286;
}

body.lp.opencampus.child-page .section__10 .section-heading {
  font-weight: bold;
  display: inline-block;
  letter-spacing: 2px;
  font-size: 1.25rem;
  position: relative;
}

body.lp.opencampus.child-page .section__10 .section-heading span {
  position: relative;
}

body.lp.opencampus.child-page .section__10 .section-heading.is_scrolled:after {
  transition: .5s;
  width: 100%;
}

body.lp.opencampus.child-page .section__10 .section-heading.is_scrolled span:after {
  transition: .5s;
  width: 100%;
}

body.lp.opencampus.hsc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  background-color: #E85298;
}

body.lp.opencampus.hsc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #e2257d;
}

body.lp.opencampus.hsc .p-form__btn__wrap .p-next-btn__wrap button:hover {
  background: #e53b8b;
}

body.lp.opencampus.hsc .head__wrap:not(.not) {
  color: #E85298;
}

body.lp.opencampus.hsc .ex__wrap {
  margin-bottom: 3rem;
}

body.lp.opencampus.hsc .ex__wrap .text-cyaan {
  color: #499cac;
}

body.lp.opencampus.hsc .ex__wrap P {
  text-align: center;
  font-size: 1.1rem;
}

body.lp.opencampus.hsc .hero img {
  width: 100%;
}

body.lp.opencampus.hsc .section__01 .text__block {
  line-height: 2.14286;
}

body.lp.opencampus.hsc .section__03 {
  padding-top: 5.9375rem;
  padding-bottom: 5.9375rem;
  background: repeating-linear-gradient(-45deg, #EE7EB2, #EE7EB2 10px, #EE86B6 0, #EE86B6 20px);
}

body.lp.opencampus.hsc .section__03 .head__wrap h2:before, body.lp.opencampus.hsc .section__03 .head__wrap h2:after {
  background: url(../img/lp/oc/hsc/img_section3_star.svg) no-repeat;
  background-size: cover;
}

body.lp.opencampus.hsc .section__03 .outer__container {
  position: relative;
  margin: auto;
  max-width: 1020px;
}

body.lp.opencampus.hsc .section__03 .outer__container::before {
  content: "";
  display: block;
  position: absolute;
  left: -160px;
  bottom: -120px;
  background: url(../img/lp/oc/img_section3_dots.svg) no-repeat;
  background-size: cover;
  width: 197px;
  height: 200px;
}

body.lp.opencampus.hsc .section__03 .outer__container::after {
  content: "";
  display: block;
  position: absolute;
  right: -30px;
  top: 30px;
  width: 127px;
  height: 127px;
  background: url(../img/lp/oc/hsc/img_section3_triangle.svg) no-repeat;
  background-size: cover;
  z-index: 0;
}

body.lp.opencampus.hsc .section__03 .outer__container .inner__container {
  margin-bottom: 0;
  padding-bottom: 3rem;
  z-index: 1;
}

body.lp.opencampus.hsc .section__03 .outer__container .inner__container::before, body.lp.opencampus.hsc .section__03 .outer__container .inner__container::after {
  display: none;
}

body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch_top {
  bottom: 80%;
}

body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch--04 {
  right: 68%;
}

body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch--05 {
  right: calc(50% - (127px / 2));
}

body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch--06 {
  right: 20%;
  bottom: 90%;
}

body.lp.opencampus.hsc .section__03 .outer__container .merit-item .merit_ch {
  bottom: auto;
  top: calc(100% + 20px);
  right: calc(50% - (127px / 2));
}

body.lp.opencampus.hsc .section__03 .inner__container {
  margin-bottom: 0;
  padding-bottom: 3rem;
}

body.lp.opencampus.hsc .section__03 .merit_ch {
  position: absolute;
  bottom: -19%;
  right: 0;
}

body.lp.opencampus.hsc .section__06 .number .head {
  background-color: #E85298;
}

body.lp.opencampus.hsc .section__06 .number .contents {
  color: #E85298;
  background-color: #FFEFF6;
}

body.lp.opencampus.hsc .section__06 .number .current-num {
  color: #E85298;
  border: 3px solid #E85298;
}

body.lp.opencampus.hsc .section__06 li {
  position: relative;
}

body.lp.opencampus.hsc .section__06 li:not(:last-of-type) .number:after {
  background: url(../img/lp/oc/hsc/img_section6_step01.svg) no-repeat;
  background-position: 0 0;
}

body.lp.opencampus.hsc .section__07 {
  background-color: #E85298;
}

body.lp.opencampus.hsc .section__07 .head__wrap {
  color: white;
}

body.lp.opencampus.hsc .section__07 .head__wrap:after {
  background: transparent;
}

body.lp.opencampus.hsc .section__07 .experience__item {
  position: absolute;
}

body.lp.opencampus.hsc .section__07 .experience__item.experience__item--01 {
  top: -2rem;
  left: 0;
  -webkit-transform: translateX(-60%);
  transform: translateX(-60%);
}

body.lp.opencampus.hsc .section__07 .experience__item.experience__item--02 {
  width: 50%;
  left: -3%;
  bottom: -27%;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

body.lp.opencampus.hsc .section__07 .experience__item.experience__item--03 {
  top: -5rem;
  right: 0;
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
  width: 60%;
}

body.lp.opencampus.hsc .section__07 .experience__item.experience__item--04 {
  right: 0;
  width: 90%;
  bottom: -26%;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  width: 50%;
}

body.lp.opencampus.hsc .section__08 .inner__container {
  padding-bottom: 0;
}

body.lp.opencampus.hsc .section__10 .text {
  color: #E85298;
}

body.lp.opencampus.hsc .section__10 .section-heading {
  color: #E85298;
}

body.lp.opencampus.hsc .section__13 h2 {
  background: #E85298;
}

body.lp.opencampus.htc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  background-color: #FF7603;
}

body.lp.opencampus.htc .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #cf5e00;
}

body.lp.opencampus.htc .p-form__btn__wrap .p-next-btn__wrap button:hover {
  background: #e96a00;
}

body.lp.opencampus.htc .head__wrap:not(.not) {
  color: #FF7603;
}

body.lp.opencampus.htc .ex__wrap {
  margin-bottom: 3rem;
}

body.lp.opencampus.htc .ex__wrap .text-cyaan {
  color: #499cac;
}

body.lp.opencampus.htc .ex__wrap P {
  text-align: center;
  font-size: 1.1rem;
}

body.lp.opencampus.htc .hero {
  width: 100%;
}

body.lp.opencampus.htc .hero img {
  width: 100%;
}

body.lp.opencampus.htc .section__01 {
  margin-bottom: 6.5625rem;
}

body.lp.opencampus.htc .section__01 .text__block {
  line-height: 2.14286;
}

body.lp.opencampus.htc .section__03 {
  padding-top: 5.9375rem;
  padding-bottom: 5.9375rem;
  background: repeating-linear-gradient(-45deg, #FE974A, #FE974A 10px, #FE9F4E 0, #FE9F4E 20px);
}

body.lp.opencampus.htc .section__03 .head__wrap h2:before, body.lp.opencampus.htc .section__03 .head__wrap h2:after {
  background: url(../img/lp/oc/htc/img_section3_star.svg) no-repeat;
  background-size: cover;
}

body.lp.opencampus.htc .section__03 .outer__container {
  position: relative;
  margin: auto;
  max-width: 1020px;
}

body.lp.opencampus.htc .section__03 .outer__container::before {
  content: "";
  display: block;
  position: absolute;
  left: -160px;
  bottom: -120px;
  background: url(../img/lp/oc/img_section3_dots.svg) no-repeat;
  background-size: cover;
  width: 197px;
  height: 200px;
}

body.lp.opencampus.htc .section__03 .outer__container::after {
  content: "";
  display: block;
  position: absolute;
  right: -30px;
  top: 30px;
  width: 127px;
  height: 127px;
  background: url(../img/lp/oc/htc/img_section3_triangle.svg) no-repeat;
  background-size: cover;
  z-index: 0;
}

body.lp.opencampus.htc .section__03 .outer__container .inner__container {
  margin-bottom: 0;
  padding-bottom: 3rem;
  z-index: 1;
}

body.lp.opencampus.htc .section__03 .outer__container .inner__container::before, body.lp.opencampus.htc .section__03 .outer__container .inner__container::after {
  display: none;
}

body.lp.opencampus.htc .section__03 .outer__container .merit_ch {
  position: absolute;
  bottom: -19%;
  z-index: 0;
}

body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch_top {
  bottom: 80%;
}

body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch--04 {
  right: 68%;
}

body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch--05 {
  right: calc(50% - (127px / 2));
}

body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch--06 {
  right: 20%;
  bottom: 90%;
}

body.lp.opencampus.htc .section__03 .outer__container .merit-item .merit_ch {
  bottom: auto;
  top: calc(100% + 20px);
  right: calc(50% - (127px / 2));
  left: auto;
}

body.lp.opencampus.htc .section__03 .inner__container {
  margin-bottom: 0;
  padding-bottom: 3rem;
}

body.lp.opencampus.htc .section__03 .merit_ch {
  position: absolute;
  bottom: -19%;
  z-index: 10;
}

body.lp.opencampus.htc .section__03 .merit_ch.merit_ch--01 {
  left: 31%;
}

body.lp.opencampus.htc .section__03 .merit_ch.merit_ch--02 {
  right: 31%;
}

body.lp.opencampus.htc .section__06 .number .head {
  background-color: #FF7603;
}

body.lp.opencampus.htc .section__06 .number .contents {
  color: #FF7603;
  background-color: #FFF5EF;
}

body.lp.opencampus.htc .section__06 .number .current-num {
  color: #FF7603;
  border: 3px solid #FF7603;
}

body.lp.opencampus.htc .section__06 .piano {
  max-width: none;
  width: 380px;
}

body.lp.opencampus.htc .section__06 li {
  position: relative;
}

body.lp.opencampus.htc .section__06 li:not(:last-of-type) .number:after {
  background: url(../img/lp/oc/htc/img_section6_step01.svg) no-repeat;
  background-position: 0 0;
}

body.lp.opencampus.htc .section__07 {
  background-color: #FF7603;
}

body.lp.opencampus.htc .section__07 .head__wrap {
  color: white;
}

body.lp.opencampus.htc .section__07 .head__wrap:after {
  background: transparent;
}

body.lp.opencampus.htc .section__07 .experience__item {
  position: absolute;
}

body.lp.opencampus.htc .section__07 .experience__item.experience__item--01 {
  top: -8.5rem;
  left: -0.5rem;
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
  width: 40%;
}

body.lp.opencampus.htc .section__07 .experience__item.experience__item--02 {
  width: 50%;
  left: -3%;
  bottom: -27%;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

body.lp.opencampus.htc .section__07 .experience__item.experience__item--03 {
  top: 4rem;
  right: 0;
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
  width: 40%;
}

body.lp.opencampus.htc .section__07 .experience__item.experience__item--04 {
  right: 0;
  bottom: 9%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  width: 30%;
}

body.lp.opencampus.htc .section__07 li .tab-btn2.tab-btn--02, body.lp.opencampus.htc .section__07 li .tab-btn2.tab-btn--03 {
  border-color: #fff;
  color: #fff;
  background-color: #FF7603;
}

body.lp.opencampus.htc .section__07 li.is_active .tab-btn2.tab-btn--02, body.lp.opencampus.htc .section__07 li.is_active .tab-btn2.tab-btn--03 {
  color: #FF7603;
  background-color: #fff;
}

body.lp.opencampus.htc .section__08 .inner__container {
  padding-bottom: 0;
}

body.lp.opencampus.htc .section__10 .text {
  color: #FF7603;
}

body.lp.opencampus.htc .section__10 .section-heading {
  color: #FF7603;
}

body.lp.opencampus.htc .section__13 h2 {
  background: #FF7603;
}

body.lp.opencampus.iryo .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree {
  background-color: #43AA88;
}

body.lp.opencampus.iryo .p-form__btn__wrap .p-next-btn__wrap .submit-btn.is_agree:hover {
  background-color: #35856b;
}

body.lp.opencampus.iryo .p-form__btn__wrap .p-next-btn__wrap button:hover {
  background: #3c9879;
}

body.lp.opencampus.iryo .head__wrap:not(.not) {
  color: #43AA88;
}

body.lp.opencampus.iryo .hero {
  width: 100%;
}

body.lp.opencampus.iryo .hero img {
  width: 100%;
}

body.lp.opencampus.iryo .section__01 {
  margin-bottom: 6.5625rem;
}

body.lp.opencampus.iryo .section__01 .text__block {
  line-height: 2.14286;
}

body.lp.opencampus.iryo .section__03 {
  padding-top: 5.9375rem;
  padding-bottom: 5.9375rem;
  background: repeating-linear-gradient(-45deg, #7BBCA7, #7BBCA7 10px, #7BC3AB 0, #7BC3AB 20px);
}

body.lp.opencampus.iryo .section__03 .head__wrap h2:before, body.lp.opencampus.iryo .section__03 .head__wrap h2:after {
  background: url(../img/lp/oc/iryo/img_section3_star.svg) no-repeat;
  background-size: cover;
}

body.lp.opencampus.iryo .section__03 .outer__container {
  position: relative;
  margin: auto;
  max-width: 1020px;
}

body.lp.opencampus.iryo .section__03 .outer__container::before {
  content: "";
  display: block;
  position: absolute;
  left: -160px;
  bottom: -120px;
  background: url(../img/lp/oc/img_section3_dots.svg) no-repeat;
  background-size: cover;
  width: 197px;
  height: 200px;
}

body.lp.opencampus.iryo .section__03 .outer__container::after {
  content: "";
  display: block;
  position: absolute;
  right: -30px;
  top: 30px;
  width: 127px;
  height: 127px;
  background: url(../img/lp/oc/iryo/img_section3_triangle.svg) no-repeat;
  background-size: cover;
  z-index: 0;
}

body.lp.opencampus.iryo .section__03 .outer__container .f-century {
  color: #43AA88;
}

body.lp.opencampus.iryo .section__03 .outer__container .inner__container {
  margin-bottom: 0;
  padding-bottom: 3rem;
  z-index: 1;
}

body.lp.opencampus.iryo .section__03 .outer__container .inner__container::before, body.lp.opencampus.iryo .section__03 .outer__container .inner__container::after {
  display: none;
}

body.lp.opencampus.iryo .section__03 .outer__container .merit_ch {
  position: absolute;
  bottom: -19%;
  z-index: 0;
}

body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch_top {
  bottom: 80%;
}

body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch--04 {
  right: 68%;
}

body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch--05 {
  right: calc(50% - (127px / 2));
}

body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch--06 {
  right: 20%;
  bottom: 90%;
}

body.lp.opencampus.iryo .section__03 .outer__container .merit-item .merit_ch {
  bottom: auto;
  top: calc(100% + 20px);
  right: calc(50% - (127px / 2));
  left: auto;
}

body.lp.opencampus.iryo .section__03 .inner__container {
  margin-bottom: 0;
  padding-bottom: 3rem;
}

body.lp.opencampus.iryo .section__03 .merit_ch {
  position: absolute;
  bottom: -19%;
  z-index: 10;
}

body.lp.opencampus.iryo .section__03 .merit_ch.merit_ch--01 {
  left: 20%;
}

body.lp.opencampus.iryo .section__03 .merit_ch.merit_ch--02 {
  left: 44%;
}

body.lp.opencampus.iryo .section__03 .merit_ch.merit_ch--03 {
  right: 18%;
}

body.lp.opencampus.iryo .section__06 .number .head {
  background-color: #43AA88;
}

body.lp.opencampus.iryo .section__06 .number .contents {
  color: #43AA88;
  background-color: #F3FFEF;
}

body.lp.opencampus.iryo .section__06 .number .current-num {
  color: #43AA88;
  border: 3px solid #43AA88;
}

body.lp.opencampus.iryo .section__06 li {
  position: relative;
}

body.lp.opencampus.iryo .section__06 li:not(:last-of-type) .number:after {
  background: url(../img/lp/oc/iryo/img_section6_step01.svg) no-repeat;
  background-position: 0 0;
}

body.lp.opencampus.iryo .section__06 .schedule__wrap > .item01 {
  right: 0;
}

body.lp.opencampus.iryo .section__07 {
  background-color: #43AA88;
}

body.lp.opencampus.iryo .section__07 .head__wrap {
  color: white;
}

body.lp.opencampus.iryo .section__07 .head__wrap:after {
  background: transparent;
}

body.lp.opencampus.iryo .section__07 .experience__item {
  position: absolute;
}

body.lp.opencampus.iryo .section__07 .experience__item.experience__item--01 {
  top: -10rem;
  left: 2rem;
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
  width: 35%;
}

body.lp.opencampus.iryo .section__07 .experience__item.experience__item--02 {
  width: 40%;
  left: 1%;
  bottom: -18%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

body.lp.opencampus.iryo .section__07 .experience__item.experience__item--03 {
  top: 4rem;
  right: 0;
  -webkit-transform: translateX(10%);
  transform: translateX(10%);
  width: 40%;
  display: none;
}

body.lp.opencampus.iryo .section__07 li .tab-btn2.tab-btn--04, body.lp.opencampus.iryo .section__07 li .tab-btn2.tab-btn--05, body.lp.opencampus.iryo .section__07 li .tab-btn2.tab-btn--06 {
  border-color: #fff;
  color: #fff;
  background-color: #43AA88;
}

body.lp.opencampus.iryo .section__07 li.is_active .tab-btn2.tab-btn--04, body.lp.opencampus.iryo .section__07 li.is_active .tab-btn2.tab-btn--05, body.lp.opencampus.iryo .section__07 li.is_active .tab-btn2.tab-btn--06 {
  color: #43AA88;
  background-color: #fff;
}

body.lp.opencampus.iryo .section__08 .inner__container {
  padding-bottom: 0;
}

body.lp.opencampus.iryo .section__10 .text {
  color: #43AA88;
}

body.lp.opencampus.iryo .section__10 .section-heading {
  color: #43AA88;
}

body.lp.opencampus.iryo .section__13 h2 {
  background: #43AA88;
}

body.lp.opencampus .main__inner.oc-inner-hsc {
  padding-top: 3rem;
  padding-bottom: 8rem;
  background-image: url(../img/lp/oc/hsc/hsc_oc_inner-bg.png);
  background-size: contain;
}

body.lp.opencampus .main__inner.oc-inner-hsc .section__03 {
  background: none;
}

body.lp.opencampus .main__inner.oc-inner-htc {
  padding-top: 3rem;
  padding-bottom: 8rem;
  background-image: url(../img/lp/oc/htc/htc_oc_inner-bg.png);
  background-size: contain;
}

body.lp.opencampus .main__inner.oc-inner-htc .section__03 {
  background: none;
}

body.lp.opencampus .main__inner.oc-inner-htc .f-century {
  color: #FF7603;
}

body.lp.opencampus .main__inner.oc-inner-iryo {
  padding-top: 3rem;
  padding-bottom: 8rem;
  background-image: url(../img/lp/oc/iryo/iryo_oc_inner-bg.png);
  background-size: contain;
}

body.lp.opencampus .main__inner.oc-inner-iryo .section__03 {
  background: none;
}

body.lp.opencampus .main__inner.oc-inner-iryo .f-century {
  color: #43AA88;
}

body.lp.opencampus .section__03 {
  padding-left: 10px;
  padding-right: 10px;
}

body.lp.opencampus.parent-group .section__03 .head__wrap h2, body.lp.opencampus.hsc .section__03 .head__wrap h2, body.lp.opencampus.htc .section__03 .head__wrap h2, body.lp.opencampus.iryo .section__03 .head__wrap h2, body.lp.opencampus .section__03 .head__wrap h2 {
  position: relative;
}

body.lp.opencampus.parent-group .section__03 .head__wrap h2:before, body.lp.opencampus.hsc .section__03 .head__wrap h2:before, body.lp.opencampus.htc .section__03 .head__wrap h2:before, body.lp.opencampus.iryo .section__03 .head__wrap h2:before, body.lp.opencampus .section__03 .head__wrap h2:before {
  content: '';
  display: block;
  position: absolute;
  left: -70px;
  top: -50px;
  width: 51px;
  height: 61px;
}

body.lp.opencampus.parent-group .section__03 .head__wrap h2:after, body.lp.opencampus.hsc .section__03 .head__wrap h2:after, body.lp.opencampus.htc .section__03 .head__wrap h2:after, body.lp.opencampus.iryo .section__03 .head__wrap h2:after, body.lp.opencampus .section__03 .head__wrap h2:after {
  content: '';
  display: block;
  position: absolute;
  right: -60px;
  top: 30px;
  width: 51px;
  height: 61px;
}

body.lp.opencampus .section__03 .is_scrolled.head__wrap h2:before, body.lp.opencampus .section__03 .is_scrolled.head__wrap h2:after {
  -webkit-animation: kyupeen 1.5s infinite linear;
  animation: kyupeen 1.5s infinite linear;
}

@-webkit-keyframes kyupeen {
  0% {
    -webkit-transform: rotate(10deg) scale(1);
    transform: rotate(10deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(5deg) scale(0.7);
    transform: rotate(5deg) scale(0.7);
  }
  100% {
    -webkit-transform: rotate(10deg) scale(1);
    transform: rotate(10deg) scale(1);
  }
}

@keyframes kyupeen {
  0% {
    -webkit-transform: rotate(10deg) scale(1);
    transform: rotate(10deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(5deg) scale(0.7);
    transform: rotate(5deg) scale(0.7);
  }
  100% {
    -webkit-transform: rotate(10deg) scale(1);
    transform: rotate(10deg) scale(1);
  }
}

body.lp.opencampus .section__03 .inner__container {
  background-color: #fff;
  border-radius: 20px;
  padding-top: 4rem;
  padding-bottom: 8rem;
  margin-bottom: 10rem;
  position: relative;
}

body.lp.opencampus .section__03 .merit-item .merit_img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

body.lp.opencampus .section__03 .merit-item.is_scrolled .merit_img {
  transition: .3s ease .2s;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

body.lp.opencampus .section__03 .merit_ch.is_scrolled {
  -webkit-animation: ch_jump .4s forwards;
  animation: ch_jump .4s forwards;
}

@keyframes ch_jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.lp.opencampus .section__03 .star {
  position: absolute;
}

body.lp.opencampus .section__03 .star--01 {
  right: 6%;
  top: 30%;
}

body.lp.opencampus .section__03 .star--02 {
  right: 35%;
  bottom: 13%;
}

body.lp.opencampus .section__03 .star--03 {
  left: 35%;
  bottom: 13%;
}

body.lp.opencampus .section__03 .star--04 {
  left: 4%;
  bottom: 20%;
}

body.lp.opencampus .section__03 .star--05 {
  left: 6%;
  top: 30%;
}

body.lp.opencampus .section__05 figure {
  margin-bottom: 0;
}

body.lp.opencampus .section__05 .more-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100px;
  border: 1px solid #47B2B0;
  color: #47B2B0;
  text-align: center;
  padding: .5rem 2rem;
  margin: auto;
  background-color: #fff;
  width: 240px;
  font-size: 1.25rem;
  display: block;
  transition: .2s;
}

body.lp.opencampus .section__05 .more-btn:hover {
  opacity: .7;
}

body.lp.opencampus .section__05 .more-btn.is_hidden {
  opacity: 0;
  visibility: hidden;
}

body.lp.opencampus .section__05 .more-btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 20px;
  background-color: #47B2B0;
}

body.lp.opencampus .section__05 .more-btn:before {
  content: '';
  display: block;
  position: absolute;
  right: 26px;
  bottom: 16px;
  margin: auto;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: 1px solid #47B2B0;
  border-right: 1px solid #47B2B0;
}

body.lp.opencampus .section__05 .tab-btns {
  display: flex;
  margin-bottom: 8rem;
}

body.lp.opencampus .section__05 .tab-btns button {
  border: none;
  background-color: transparent;
  padding: 0;
  transition: .2s;
  text-decoration: none;
  position: relative;
}

body.lp.opencampus .section__05 .tab-btns button:hover {
  opacity: .7;
}

body.lp.opencampus .section__05 .tab-btns button.is_active:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-width: 15px 10px 0;
  border-style: solid;
}

body.lp.opencampus .section__05 .tab-btns button.is_active.tab-btn--01:after {
  border-color: #212224 transparent transparent transparent;
}

body.lp.opencampus .section__05 .tab-btns button.is_active.tab-btn--02:after {
  border-color: #E85298 transparent transparent transparent;
}

body.lp.opencampus .section__05 .tab-btns button.is_active.tab-btn--03:after {
  border-color: #FF7603 transparent transparent transparent;
}

body.lp.opencampus .section__05 .tab-btns button.is_active.tab-btn--04:after {
  border-color: #43AA88 transparent transparent transparent;
}

body.lp.opencampus .section__06 {
  margin-bottom: 10rem;
  padding-left: 10px;
  padding-right: 10px;
}

body.lp.opencampus .section__06 .container {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 1rem;
  background: url(../img/lp/oc/img_section6_bg.jpg) no-repeat;
  background-size: cover;
}

body.lp.opencampus .section__06 .piano {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 54%;
  max-width: 705px;
}

body.lp.opencampus .section__06 .schedule__wrap {
  position: relative;
  z-index: 10;
}

body.lp.opencampus .section__06 .schedule__wrap > .item01 {
  position: absolute;
  right: -10%;
  top: -12%;
}

body.lp.opencampus .section__06 .head__wrap, body.lp.opencampus.parent-group .section__06 .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .section__06 .head__wrap {
  margin-bottom: 7rem;
}

body.lp.opencampus .section__06 ol:after {
  content: '';
  display: block;
  clear: both;
}

body.lp.opencampus .section__06 li {
  position: relative;
}

body.lp.opencampus .section__06 li:not(:last-of-type) .number:after {
  content: '';
  display: block;
  position: absolute;
  width: 220px;
  height: 35px;
  z-index: -1;
}

body.lp.opencampus .section__06 li.is_scrolled:not(:last-of-type) .number:after {
  -webkit-animation: asiato 3s steps(6) infinite;
  animation: asiato 3s steps(6) infinite;
}

@-webkit-keyframes asiato {
  to {
    background-position: 0 -210px;
  }
}

@keyframes asiato {
  to {
    background-position: 0 -210px;
  }
}

body.lp.opencampus .section__06 li.is_scrolled .number .s-flag,
body.lp.opencampus .section__06 li.is_scrolled .number .e-flag {
  opacity: 1;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  transition: 1s ease .5s;
}

body.lp.opencampus .section__06 .number {
  position: relative;
}

body.lp.opencampus .section__06 .number .s-flag,
body.lp.opencampus .section__06 .number .e-flag {
  position: absolute;
  bottom: 100%;
  left: 0;
  -webkit-transform-origin: 10% 100%;
  transform-origin: 10% 100%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  z-index: -1;
  opacity: 0;
}

body.lp.opencampus .section__06 .number .head {
  display: flex;
  align-items: center;
  font-size: 1.5625rem;
  color: white;
  font-weight: bold;
}

body.lp.opencampus .section__06 .number .contents {
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem 0;
}

body.lp.opencampus .section__06 .number .current-num {
  background-color: #fff;
  font-size: 2.1875rem;
  width: 60px;
  text-align: center;
}

body.lp.opencampus .section__06 .number .head__title {
  width: calc(100% - 60px);
  padding: .5rem;
  line-height: 1.3;
}

body.lp.opencampus .section__06 .number.number--01 .ch01 {
  position: absolute;
  right: 10px;
  bottom: 90px;
}

body.lp.opencampus .section__06 .number.number--01 .item01 {
  position: absolute;
  bottom: 30%;
  left: 130%;
  z-index: -1;
}

body.lp.opencampus .section__06 .number.number--02 .ch01 {
  position: absolute;
  right: 10px;
  bottom: 100%;
}

body.lp.opencampus .section__06 .number.number--02 .ocs {
  font-size: 1.3125rem;
}

body.lp.opencampus .section__06 .number.number--03 .ch01 {
  position: absolute;
  right: 10px;
  top: -8%;
  z-index: 10;
}

body.lp.opencampus .section__06 .number.number--03 .item01 {
  position: absolute;
  left: -10px;
  top: 20%;
}

body.lp.opencampus .section__06 .number.number--03 .item02 {
  position: absolute;
  right: 0px;
  top: 24%;
}

body.lp.opencampus .section__06 .number.number--04 .ch01 {
  position: absolute;
  left: 10px;
  bottom: 100%;
  z-index: 10;
}

body.lp.opencampus .section__06 .number.number--04 .ch02 {
  position: absolute;
  right: 10px;
  top: 40%;
  z-index: 10;
}

body.lp.opencampus .section__06 .number.number--04 .item01 {
  position: absolute;
  left: -10px;
  top: 20%;
}

body.lp.opencampus .section__06 .number.number--05 .ch01 {
  position: absolute;
  right: 10px;
  top: 20%;
  z-index: 10;
}

body.lp.opencampus .section__07 {
  margin-bottom: 8rem;
}

body.lp.opencampus .section__07 .container {
  position: relative;
}

body.lp.opencampus .section__07 .scroll {
  position: absolute;
  right: 0;
  bottom: 10px;
}

body.lp.opencampus .section__07 .tab-btns2 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6rem;
}

body.lp.opencampus .section__07 .tab-btns2 button {
  border-width: 2px;
  border-style: solid;
  padding: .5rem;
  font-weight: bold;
  display: block;
  width: 100%;
  transition: .2s;
  transition: .2s;
  text-decoration: none;
  position: relative;
  font-size: 1rem;
}

body.lp.opencampus .section__07 .tab-btns2 button:hover {
  opacity: .7;
}

body.lp.opencampus .section__07 .content {
  transition: height .6s ease-in-out;
  position: relative;
  display: none;
}

body.lp.opencampus .section__07 .content.is_show {
  display: block;
  -webkit-animation: tab-show .5s linear 0s;
  animation: tab-show .5s linear 0s;
}

@keyframes tab-show {
  from {
    opacity: 0;
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.lp.opencampus .section__08 {
  margin-bottom: 11rem;
}

body.lp.opencampus .section__08 .head__wrap, body.lp.opencampus.parent-group .section__08 .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .section__08 .head__wrap {
  margin-bottom: 4rem;
}

body.lp.opencampus .section__08 .head__wrap.is_scrolled h2 .item01 {
  transition: .8s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  display: inline-block;
}

body.lp.opencampus .section__08 .item01 {
  position: absolute;
  left: -5%;
  bottom: -80%;
  text-align: center;
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
}

body.lp.opencampus .section__08 h2 {
  position: relative;
  display: inline-block;
  max-width: 760px;
  width: 100%;
  font-size: 1.625rem;
  padding: 1.8rem 0.5rem;
}

body.lp.opencampus .section__08 h2:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 40px 0 0;
  border-style: solid;
}

body.lp.opencampus .section__08 .content {
  position: relative;
}

body.lp.opencampus .section__08 .ch01, body.lp.opencampus .section__08 .ch02, body.lp.opencampus .section__08 .ch03 {
  position: absolute;
  width: 100%;
  width: 88%;
  width: 66%;
}

body.lp.opencampus .section__08 .ch01 .fukidashi, body.lp.opencampus .section__08 .ch02 .fukidashi, body.lp.opencampus .section__08 .ch03 .fukidashi {
  -webkit-transform: scale(0);
  transform: scale(0);
  width: 100%;
  margin: 0;
}

body.lp.opencampus .section__08 .ch01 .ch, body.lp.opencampus .section__08 .ch02 .ch, body.lp.opencampus .section__08 .ch03 .ch {
  margin-top: -1rem;
  max-width: 118px;
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
}

body.lp.opencampus .section__08 .ch01.is_scrolled .ch, body.lp.opencampus .section__08 .ch02.is_scrolled .ch, body.lp.opencampus .section__08 .ch03.is_scrolled .ch {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: 1s cubic-bezier(0.08, 0.27, 0.17, 1);
}

body.lp.opencampus .section__08 .ch01.is_scrolled .fukidashi, body.lp.opencampus .section__08 .ch02.is_scrolled .fukidashi, body.lp.opencampus .section__08 .ch03.is_scrolled .fukidashi {
  transition: 0.3s cubic-bezier(0.42, 0, 0.37, 1.5) 1s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

body.lp.opencampus .section__08 .ch01 {
  left: 144px;
  top: 26px;
  max-width: 345px;
}

body.lp.opencampus .section__08 .ch02 {
  right: 156px;
  top: 56px;
  max-width: 272px;
}

body.lp.opencampus .section__08 .ch03 {
  right: 137px;
  bottom: 34px;
  max-width: 269px;
}

body.lp.opencampus .section__09 {
  margin-bottom: 10rem;
}

body.lp.opencampus .section__09 .container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  border: 30px solid #F5B5A7;
  background: #fff url(../img/lp/oc/img_section9_bg01.png) no-repeat;
  background-size: cover;
}

body.lp.opencampus .section__09 .content {
  position: relative;
  max-width: 807px;
  margin: auto;
}

body.lp.opencampus .section__10 {
  position: relative;
  z-index: 5;
  margin-bottom: 10rem;
}

body.lp.opencampus .section__10 .container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
}

body.lp.opencampus .section__10 .item01 {
  position: absolute;
  left: -50px;
  top: 20px;
  z-index: -1;
}

body.lp.opencampus .section__10 .item02 {
  position: absolute;
  right: -50px;
  top: -40px;
  z-index: -1;
}

body.lp.opencampus .section__10 .content {
  position: relative;
  padding-top: 5rem;
  -webkit-animation: tate 1.5s ease-in-out infinite alternate;
  animation: tate 1.5s ease-in-out infinite alternate;
}

@-webkit-keyframes tate {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes tate {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.lp.opencampus .section__10 .content .inner {
  -webkit-animation: tate 1.5s ease-in-out infinite alternate;
  animation: tate 1.5s ease-in-out infinite alternate;
}

@keyframes tate {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.lp.opencampus .section__10 .pic01 {
  margin-top: -1rem;
}

body.lp.opencampus .section__10 .text {
  font-weight: 800;
  font-size: 1.6875rem;
  position: relative;
  z-index: 5;
  margin-bottom: 0;
}

body.lp.opencampus .section__10 .text.is_scrolled .clowd__block {
  -webkit-animation: tate 1.5s ease-in-out infinite alternate;
  animation: tate 1.5s ease-in-out infinite alternate;
}

@keyframes tate {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body.lp.opencampus .section__10 .text.is_scrolled .clowd {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  -webkit-animation: yoko 1.8s ease-in-out infinite alternate;
  animation: yoko 1.8s ease-in-out infinite alternate;
}

@-webkit-keyframes yoko {
  0% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes yoko {
  0% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

body.lp.opencampus .section__10 .text .clowd__block {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: -1;
}

body.lp.opencampus .section__11 {
  margin-bottom: 8rem;
}

body.lp.opencampus .section__11 .acc {
  font-size: 1rem;
  margin-bottom: 5rem;
}

body.lp.opencampus .section__11 .acc > li {
  border-bottom: 1px solid #009389;
}

body.lp.opencampus .section__11 .acc > li:first-of-type {
  border-top: 1px solid #009389;
}

body.lp.opencampus .section__11 .acc .accBtn {
  background-color: transparent;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 1.4rem 2rem 1.4rem 20px;
  transition: .2s;
}

body.lp.opencampus .section__11 .acc .accBtn:hover {
  opacity: .7;
}

body.lp.opencampus .section__11 .acc .accBtn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 28px;
  height: 2px;
  background-color: #009389;
}

body.lp.opencampus .section__11 .acc .accBtn:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 22px;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 28px;
  background-color: #009389;
  transition: .2s;
}

body.lp.opencampus .section__11 .acc .accBtn.is_active {
  background-color: #fff;
}

body.lp.opencampus .section__11 .acc .accBtn.is_active:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

body.lp.opencampus .section__11 .acc .accContent {
  padding: 2rem 20px;
  display: block;
  background: #fff;
  position: relative;
  -webkit-animation: tab-show2 .5s linear 0s;
  animation: tab-show2 .5s linear 0s;
}

@-webkit-keyframes tab-show2 {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes tab-show2 {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

body.lp.opencampus .section__11 .acc .accContent:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  width: 0;
  height: 1px;
  background-color: #009389;
  transition: .2s;
}

body.lp.opencampus .section__11 .acc .accContent.is_hidden {
  display: none;
  height: 0 !important;
}

body.lp.opencampus .section__11 .acc .accContent.is_show:after {
  width: 90%;
}

body.lp.opencampus .section__12 {
  position: relative;
  margin-bottom: 10rem;
}

body.lp.opencampus .section__12 .head__wrap, body.lp.opencampus.parent-group .section__12 .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .section__12 .head__wrap {
  margin-bottom: 6rem;
}

body.lp.opencampus .section__12 .container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background: #fff;
  position: relative;
}

body.lp.opencampus .section__12 .item01 {
  position: absolute;
  left: -100px;
  top: 10px;
}

body.lp.opencampus .section__12 .item02 {
  position: absolute;
  right: -50px;
  top: -20px;
}

body.lp.opencampus .section__12 .item03 {
  position: absolute;
  left: -8%;
  bottom: -6%;
}

body.lp.opencampus .section__12 .content {
  font-size: 1rem;
  line-height: 1.9;
}

body.lp.opencampus .section__12 .title {
  font-weight: bold;
  font-size: 1.25rem;
  color: #47B2B0;
}

body.lp.opencampus .section__12 .line-link {
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
}

body.lp.opencampus .section__12 .line-link a {
  color: white;
  transition: .2s;
  text-decoration: none;
  background-color: #40BB0F;
  display: inline-block;
  padding: 1rem 2rem;
}

body.lp.opencampus .section__12 .line-link a:hover {
  opacity: .7;
}

body.lp.opencampus .section__13 .container {
  background-color: #fff;
  padding-bottom: 3rem;
}

body.lp.opencampus .section__13 h2 {
  font-weight: bold;
  font-size: 1.5625rem;
  text-align: center;
  padding: 1rem .5rem;
  color: white;
  margin: 0 -10px 2rem;
}

body.lp .section__07 .section__08 div.content {
  display: block !important;
  padding-top: 0;
}

body.lp .section__07 .section__08 h2.s8_heading {
  background-image: url(../img/lp/oc/img-section8-bg-item_sp.svg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 20px !important;
  max-width: 863px;
}

body.lp .section__07 .section__08 h2.s8_heading img.item01 {
  top: 120px;
  z-index: 1;
}

body.lp .section__07 .section__08 .s8_img {
  position: relative;
}

body.lp .section__07 .section__08 .s8_img img {
  width: 100%;
  max-width: 756px;
}

body.lp .section__08:not(.parent-group) div.content {
  padding-top: 45px;
}

body.lp .section__08:not(.parent-group) h2.s8_heading {
  background-image: url(../img/lp/oc/img-section8-bg-item_sp.svg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 20px !important;
  max-width: 863px;
}

body.lp .section__08:not(.parent-group) h2.s8_heading img.item01 {
  top: 115px;
  z-index: 1;
}

body.lp .section__08:not(.parent-group) h2.s8_heading.hsc {
  background-image: url(../img/lp/oc/hsc/img-section8-bg-item-hsc_sp.svg);
}

body.lp .section__08:not(.parent-group) h2.s8_heading.htc {
  background-image: url(../img/lp/oc/htc/img-section8-bg-item-htc_sp.svg);
}

body.lp .section__08:not(.parent-group) h2.s8_heading.iryo {
  background-image: url(../img/lp/oc/iryo/img-section8-bg-item-iryo_sp.svg);
}

body.lp .section__08:not(.parent-group) .s8_img {
  position: relative;
}

body.lp .section__08:not(.parent-group) .s8_img img {
  width: 100%;
  max-width: 756px;
}

body.interview .mv {
  padding: 25px 0 69px;
  position: relative;
  overflow: clip;
}

body.interview .mv__bg {
  position: absolute;
  inset: 0;
  background-color: #F4CB35;
  z-index: -2;
}

body.interview .mv__bg::after {
  content: '';
  display: inline-block;
  background: url("../img/lp/interview/bg-interview-parts01.png") top left/532px 215px repeat;
  position: absolute;
  inset: 0;
  z-index: -1;
}

body.interview .mv__slider .slick-list {
  overflow: visible;
}

body.interview .mv__slider .slider-item {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 600px;
  perspective: 600px;
  -webkit-perspective-origin: left top;
  perspective-origin: left top;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: 20px;
  position: relative;
  max-width: 190px;
}

@keyframes b {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -90deg);
    transform: rotate3d(0, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, -90deg);
    transform: rotate3d(0, 1, 0, -90deg);
  }
}

@-webkit-keyframes b-sp {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -90deg);
    transform: rotate3d(0, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, -90deg);
    transform: rotate3d(0, 1, 0, -90deg);
  }
}

@keyframes b-sp {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -90deg);
    transform: rotate3d(0, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, -90deg);
    transform: rotate3d(0, 1, 0, -90deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 90deg);
    transform: rotate3d(0, 1, 0, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

@-webkit-keyframes s {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes s {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

body.interview .mv__slider .slider-item:hover {
  opacity: 1;
}

body.interview .mv__slider .slider-item:hover img {
  opacity: 1;
}

body.interview .mv__slider .slider-item .top {
  position: relative;
  z-index: -1;
}

body.interview .mv__slider .slider-item .top::before {
  content: '';
  display: inline-block;
  width: 56px;
  height: 41px;
  position: absolute;
  top: 16%;
  right: 0;
}

body.interview .mv__slider .slider-item .top img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

body.interview .mv__slider .slider-item .top img.before {
  position: relative;
  transition: opacity 0.3s cubic-bezier(0.84, 0.05, 0.94, 0.68);
}

body.interview .mv__slider .slider-item .top img.after {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  transition: opacity 0.3s cubic-bezier(0.84, 0.05, 0.94, 0.68) 0.1s;
  -webkit-transform: rotate3d(0, 1, 0, 90deg);
  transform: rotate3d(0, 1, 0, 90deg);
  z-index: 2;
}

body.interview .mv__slider .slider-item.interview--01 .top::before {
  background: url("../img/lp/interview/text-interveiw-fukidashi01@2x.png") center/cover no-repeat;
}

body.interview .mv__slider .slider-item.interview--02 .top::before {
  background: url("../img/lp/interview/text-interveiw-fukidashi02@2x.png") center/cover no-repeat;
  top: 10%;
  right: 5%;
}

body.interview .mv__slider .slider-item.interview--03 .top::before {
  background: url("../img/lp/interview/text-interveiw-fukidashi03@2x.png") center/cover no-repeat;
  top: 19%;
  right: 0;
}

body.interview .mv__slider .slider-item.interview--04 .top::before {
  background: url("../img/lp/interview/text-interveiw-fukidashi04@2x.png") center/cover no-repeat;
  right: 5%;
}

body.interview .mv__slider .slider-item.interview--05 .top::before {
  background: url("../img/lp/interview/text-interveiw-fukidashi05@2x.png") center/cover no-repeat;
}

body.interview .mv__slider .slider-item.interview--06 .top::before {
  background: url("../img/lp/interview/text-interveiw-fukidashi06@2x.png") center/cover no-repeat;
}

body.interview .mv__slider .slider-item .bottom {
  width: 187px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
}

body.interview .mv__slider .slider-item .bottom img {
  width: 100%;
}

body.interview .mv__slider .slider-item:nth-of-type(odd) .bottom {
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

body.interview .mv__slider .slider-item:nth-of-type(even) .bottom {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

@-webkit-keyframes rr {
  0% {
  }
  20% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  60% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  80% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
}

@keyframes rr {
  0% {
  }
  20% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  60% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  80% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
}

body.interview .mv__slider .slide-arrow {
  background-color: transparent;
  border: none;
  width: 50px;
  height: 50px;
  background: url("../img/lp/interview/img-interview-btn.png") center/cover no-repeat;
  position: absolute;
  top: 30%;
  z-index: 5;
  transition: opacity 0.3s linear;
}

body.interview .mv__slider .slide-arrow.prev-arrow:focus, body.interview .mv__slider .slide-arrow.next-arrow:focus {
  border-radius: 1000px;
}

body.interview .mv__slider .slide-arrow.prev-arrow {
  left: 15%;
}

body.interview .mv__slider .slide-arrow.next-arrow {
  right: 15%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

body.interview .mv__slider:not(.first) .slide-arrow {
  opacity: 1;
}

body.interview .individual {
  position: relative;
  padding: 53px 0 100px;
}

body.interview .individual__bg {
  position: absolute;
  inset: 0;
  background: url("../img/lp/interview/bg-interview-individual.png") top left/contain repeat;
  z-index: -1;
}

body.interview .individual__item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(110, 89, 21, 0.16);
  padding: 26px 15px 40px;
  position: relative;
}

body.interview .individual__item .category {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

body.interview .individual__item.hsc {
  --department-color:#D85691;
}

body.interview .individual__item.hsc .category {
  background: url("../img/lp/interview/text-interview-kitabi.svg") center/cover no-repeat;
  width: 58%;
  aspect-ratio: 214/45;
}

body.interview .individual__item.htc {
  --department-color:#EE8122;
}

body.interview .individual__item.htc .category {
  background: url("../img/lp/interview/text-interview-kitatech.svg") center/cover no-repeat;
  width: 96%;
  aspect-ratio: 334/45;
}

body.interview .individual__item.htc.course {
  --department-color:#A57E58;
}

body.interview .individual__item.iryo .category {
  background: url("../img/lp/interview/text-interview-kitawel.svg") center/cover no-repeat;
  width: 88%;
  aspect-ratio: 325/46;
}

body.interview .individual__item.iryo.kaigo {
  --department-color:#5692C9;
}

body.interview .individual__item.iryo.kodomo {
  --department-color:#EB817F;
}

body.interview .individual__item.iryo.yakugyo {
  --department-color:#6EB57E;
}

body.interview .individual__item .top .student {
  position: relative;
  z-index: 1;
}

body.interview .individual__item .top .student .person {
  width: 47%;
}

body.interview .individual__item .top .student .text {
  width: 53%;
  margin-bottom: 40px;
}

body.interview .individual__item .top .student .department {
  display: inline;
  font-weight: 700;
  color: #fff;
  padding: 2px 5px;
  line-height: 2;
  background-color: var(--department-color);
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

body.interview .individual__item .top .student .name {
  font-size: 1rem;
  font-weight: 700;
}

body.interview .individual__item .top .student .name .sml {
  font-size: 0.6875rem;
  font-weight: 400;
}

body.interview .individual__item .item-content .q-title {
  font-size: 0.875rem;
  font-weight: 700;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  column-gap: 8px;
  margin-bottom: 12px;
}

body.interview .individual__item .item-content .q-title .q-icon {
  font-size: 0.8125rem;
  color: #fff;
  background-color: var(--department-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 1000px;
}

body.interview .individual__item .item-content .a-title {
  color: var(--department-color);
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 6px;
  border-bottom: 1px solid #A3A3A3;
  margin-bottom: 12px;
}

body.interview .individual__item .item-content .state-img {
  margin-bottom: 60px;
}

body.interview .individual__item .item-content .state-img img {
  border-radius: 5px;
}

body.interview .individual__item .item-content .desc-text {
  letter-spacing: 0.08em;
  line-height: calc(24 / 14);
  margin-bottom: 20px;
}

body.interview .individual__item .item-content .p-btn .txt {
  width: 278px;
  min-width: initial;
  border-width: 1px 0 1px 1px;
  font-weight: 400;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

body.interview .individual__item .item-content .p-btn span::after {
  width: 1px;
}

body.now-future.nf-2024 .nf-now_text {
  margin-bottom: 0;
}

body.now-future.nf-2024 .nf-hero {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_01.jpg");
}

body.now-future.nf-2024 .nf-hero-star img {
  position: absolute;
}

body.now-future.nf-2024 .nf-hero-star img:nth-of-type(1) {
  width: 6%;
  height: auto;
  top: 47px;
  left: 4.8vw;
}

body.now-future.nf-2024 .nf-hero-star img:nth-of-type(2) {
  width: 5%;
  height: auto;
}

body.now-future.nf-2024 .nf-hero-star img:nth-of-type(3) {
  width: 5.66933vw;
  height: auto;
  bottom: 6.16vw;
  left: 8.08vw;
  max-width: 24px;
}

body.now-future.nf-2024 .nf-hero-star img:nth-of-type(4) {
  top: 29.06667vw;
  left: 36.53333vw;
}

body.now-future.nf-2024 .nf-hero-star img:nth-of-type(6) {
  width: 5%;
  height: auto;
}

body.now-future.nf-2024 .nf-members_text-01 {
  line-height: 0.84615;
  margin: 5px 0 15px;
}

body.now-future.nf-2024 .nf-members_text-02 {
  line-height: 1.15385;
  margin: 5px 0 20px;
}

body.now-future.nf-2024 .nf-members-name_text {
  font-size: 1rem;
}

body.now-future.nf-2024 .nf-members-name_text.lh-0 {
  line-height: calc(17 / 16);
}

body.now-future.nf-2024 .nf-now-heading2 {
  padding-left: 0;
  margin-left: 6.66667vw;
  width: 28%;
  min-width: 106px;
  max-width: 295px;
  position: relative;
  z-index: 1;
}

body.now-future.nf-2024 .nf-now-heading2 img {
  width: 100%;
}

body.now-future.nf-2024 .now-member-range .col-7 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

body.now-future.nf-2024 #now01.nf-now-heading2 img, body.now-future.nf-2024 #now02.nf-now-heading2 img,
body.now-future.nf-2024 #now03.nf-now-heading2 img, body.now-future.nf-2024 #now04.nf-now-heading2 img,
body.now-future.nf-2024 #now05.nf-now-heading2 img {
  width: 28%;
  max-width: 295px;
  min-width: 105px;
}

body.now-future.nf-2024 .bg_now01 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_04.png");
}

body.now-future.nf-2024 .bg_now01 .nf-now-members-range {
  margin-top: 226px;
}

body.now-future.nf-2024 .bg_now01 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
  margin-top: 20px;
}

body.now-future.nf-2024 .bg_now01 .members-item_text02.add-2024 {
  margin-top: 92px;
}

body.now-future.nf-2024 .bg_now01 .members-item_text02.add-2024-1 {
  margin-top: 144px;
}

body.now-future.nf-2024 .bg_now02 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_05.png");
}

body.now-future.nf-2024 .bg_now02 .nf-now-members-range {
  margin-top: 170px;
}

body.now-future.nf-2024 .bg_now02 h3.nf-now-vertical_text .now-vertical_text02 {
  margin-top: 138px;
}

body.now-future.nf-2024 .bg_now02 .members-item_text01.add-2024-1 {
  margin-top: 62px;
}

body.now-future.nf-2024 .bg_now02 .members-item_text02.add-2024-1 {
  margin-top: 46px;
}

body.now-future.nf-2024 .bg_now02 .members-item_text02.add-2024-2 {
  margin-top: 60px;
}

body.now-future.nf-2024 .bg_now02 .members-item_text02.add-2024-3 {
  margin-top: 223px;
}

body.now-future.nf-2024 .bg_now02 .members-item_text02.add-2024-4 {
  margin-top: 20px;
}

body.now-future.nf-2024 .bg_now03 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_06.png");
}

body.now-future.nf-2024 .bg_now03 h3.nf-now-vertical_text .now-vertical_text02 {
  margin-top: 132px;
}

body.now-future.nf-2024 .bg_now03 .nf-now-members-range {
  margin-top: 165px;
}

body.now-future.nf-2024 .bg_now03 .nf-now-vertical_text.vertical-item_text.add-2024-1 {
  top: 20px;
}

body.now-future.nf-2024 .bg_now03 .members-item_text02.add-2024-2 {
  margin-top: 70px;
}

body.now-future.nf-2024 .bg_now03 .members-item_text02.add-2024-3 {
  margin-top: 179px;
}

body.now-future.nf-2024 .bg_now03 .members-item_text02.add-2024-4 {
  margin-top: 40px;
}

body.now-future.nf-2024 .bg_now03 .members-item_text02.add-2024-5 {
  margin-top: 70px;
}

body.now-future.nf-2024 .bg_now04 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_07.png");
}

body.now-future.nf-2024 .bg_now04 .nf-now-members-range {
  margin-top: 159px;
}

body.now-future.nf-2024 .bg_now04 .nf-now-vertical .nf-now-vertical_text {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}

body.now-future.nf-2024 .bg_now04 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
  margin-top: 30px;
}

body.now-future.nf-2024 .bg_now04 .members-item_text02.add-2024-1 {
  margin-top: 50px;
}

body.now-future.nf-2024 .bg_now04 .members-item_text02.add-2024-2 {
  margin-top: 62px;
}

body.now-future.nf-2024 .bg_now04 .members-item_text02.add-2024-3 {
  margin-top: 80px;
}

body.now-future.nf-2024 .bg_now04 .members-item_text02.add-2024-4 {
  margin-top: 170px;
}

body.now-future.nf-2024 .bg_now05 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_08.png");
}

body.now-future.nf-2024 .bg_now05 .nf-now-members-range {
  margin-top: 155px;
}

body.now-future.nf-2024 .bg_now05 .nf-now-vertical .nf-now-vertical_text {
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

body.now-future.nf-2024 .bg_now05 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
  margin-top: 10px;
}

body.now-future.nf-2024 .bg_now05 .members-item_text02.add-2024-1 {
  margin-top: 175px;
}

body.now-future.nf-2024 .bg_now05 .members-item_text02.add-2024-2 {
  margin-top: 140px;
}

body.now-future.nf-2024 .bg_now05 .members-item_text02.add-2024-3 {
  margin-top: 106px;
}

body.now-future.nf-2024 #future01.nf-now-heading2 img, body.now-future.nf-2024 #future04.nf-now-heading2 img {
  width: 25%;
  max-width: 240px;
  min-width: 85px;
}

body.now-future.nf-2024 .bg_future01 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_09.png");
}

body.now-future.nf-2024 .bg_future01 .nf-now-members-range {
  margin-top: 210px;
}

body.now-future.nf-2024 .bg_future01 .nf-now-vertical .nf-now-vertical_text {
  top: -30px;
  left: 0;
  right: 45px;
  left: auto;
  margin-right: 0;
}

body.now-future.nf-2024 .bg_future01 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
  margin-top: 197px;
}

body.now-future.nf-2024 .bg_future01 .members-item_text02.add-2024-1 {
  margin-top: 20px;
}

body.now-future.nf-2024 .bg_future01 .members-item_text02.add-2024-2 {
  margin-top: 50px;
}

body.now-future.nf-2024 .bg_future01 .members-item_text02.add-2024-3 {
  margin-top: 50px;
}

body.now-future.nf-2024 .bg_future01 .members-item_text02.add-2024-4 {
  margin-top: 50px;
}

body.now-future.nf-2024 #future02.nf-now-heading2 img:nth-of-type(1) {
  width: 25%;
  max-width: 240px;
  min-width: 85px;
}

body.now-future.nf-2024 #future02.nf-now-heading2 img:nth-of-type(2) {
  max-width: 210px;
  min-width: 77px;
  width: 22%;
}

body.now-future.nf-2024 .bg_future02 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_10.png");
}

body.now-future.nf-2024 .bg_future02 .nf-now-vertical .nf-now-vertical_text .now-vertical_text01 {
  padding-top: 15px;
}

body.now-future.nf-2024 .bg_future02 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
  margin-top: 121px;
}

body.now-future.nf-2024 .bg_future02 .nf-now-members-range {
  margin-top: 225px;
}

body.now-future.nf-2024 .bg_future02 .now-member-range .col-7 {
  flex: 0 0 54.33333%;
  max-width: 54.33333%;
}

body.now-future.nf-2024 .bg_future02 .now-member-range .col-5 {
  flex: 0 0 45.66667%;
  max-width: 194px;
}

body.now-future.nf-2024 .bg_future02 .members-item_text02.add-2024-1 {
  margin-top: 120px;
}

body.now-future.nf-2024 .bg_future02 .members-item_text02.add-2024-2 {
  margin-top: 123px;
}

body.now-future.nf-2024 .bg_future02 .members-item_text02.add-2024-3 {
  margin-top: 219px;
}

body.now-future.nf-2024 #future03.nf-now-heading2 img:nth-of-type(1) {
  max-width: 362px;
  min-width: 130px;
  width: 35%;
}

body.now-future.nf-2024 #future03.nf-now-heading2 img:nth-of-type(2) {
  max-width: 315px;
  min-width: 112px;
  width: 30%;
}

body.now-future.nf-2024 .bg_future03 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_11.png");
}

body.now-future.nf-2024 .bg_future03 .nf-now-members-range {
  margin-top: 210px;
}

body.now-future.nf-2024 .bg_future03 .members-item_text02.add-2024-1 {
  margin-top: 40px;
}

body.now-future.nf-2024 .bg_future03 .members-item_text02.add-2024-2 {
  margin-top: 138px;
}

body.now-future.nf-2024 .bg_future03 .members-item_text02.add-2024-3 {
  margin-top: 80px;
}

body.now-future.nf-2024 .bg_future03 .members-item_text02.add-2024-4 {
  margin-top: 58px;
}

body.now-future.nf-2024 #future04.nf-now-heading2 img {
  width: 22%;
  min-width: 85px;
  max-width: 233px;
}

body.now-future.nf-2024 .bg_future04 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_02.png");
}

body.now-future.nf-2024 .bg_future04 .nf-now-vertical .nf-now-vertical_text {
  top: 30px;
}

body.now-future.nf-2024 .bg_future04 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
  margin-top: 140px;
}

body.now-future.nf-2024 .bg_future04 .nf-now-members-range {
  margin-top: 185px;
}

body.now-future.nf-2024 .bg_future04 .nf-now-members_text {
  margin-top: 16px;
}

body.now-future.nf-2024 .bg_future04 .members-item_text02.add-2024-1 {
  margin-top: 60px;
}

body.now-future.nf-2024 .bg_future04 .members-item_text02.add-2024-2 {
  margin-top: 70px;
}

body.now-future.nf-2024 .bg_future04 .members-item_text02.add-2024-3 {
  margin-top: 32px;
}

body.now-future.nf-2024 #future05.nf-now-heading2 img {
  width: 52%;
  min-width: 197px;
  max-width: 554px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 {
  background-image: url("../img/lp/now-future/2024/bg-item-sp_03.png");
  background-position: top left;
  margin-top: 64px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical .nf-now-vertical_text {
  top: -60px;
  left: 20px;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
  margin-top: 10px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-members-range {
  margin-top: 185px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical_text.vertical-item_text.add-2024-1 {
  top: 20px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .members-item_text02.add-2024-2 {
  margin-top: 85px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .members-item_text02.add-2024-3 {
  margin-top: 125px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .members-item_text02.add-2024-4 {
  margin-top: 65px;
}

body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .members-item_text02.add-2024-5 {
  margin-top: 125px;
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
  .d-lg--none {
    display: none;
  }
  .p-aside01 .title {
    font-size: 0.875rem;
    margin-top: .5rem;
  }
  body.lp.opencampus .section__08 .ch01 {
    width: 33%;
    top: 16%;
    left: 13%;
  }
  body.lp.opencampus .section__08 .ch02 {
    width: 26%;
    top: 19%;
    right: 15%;
  }
  body.lp.opencampus .section__08 .ch03 {
    width: 26%;
  }
  body.lp .section__08:not(.parent-group) div.s8-wrap_heading {
    margin-bottom: 0;
  }
}

@media (max-width: 1025px) {
  body.hsc .divide-color {
    background-color: rgba(219, 118, 164, 0.63);
    width: 100vw;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  body.hsc .divide-color {
    background-color: rgba(219, 118, 164, 0.63);
    width: 100vw;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  body.hsc .divide-color ul {
    padding: 0 10px;
  }
  body.htc .divide-color {
    background-color: rgba(246, 184, 110, 0.63);
    width: 100vw;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  body.htc .divide-color ul {
    padding: 0 10px;
  }
  body.iryo .divide-color {
    background-color: rgba(179, 201, 132, 0.63);
    width: 100vw;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  body.iryo .divide-color ul {
    padding: 0 10px;
  }
}

@media (max-width: 1024.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
  .lg-only {
    display: none;
  }
  .md-down {
    display: block;
  }
  .header .aside__links > ul {
    border-top: 1px solid white;
  }
  .header__inner {
    display: flex;
    justify-content: space-between;
  }
  .header__menu__main .header__nav .header__nav-item .time {
    font-size: 0.625rem;
  }
  .header__menu__main .header__nav a {
    display: block;
    text-align: left;
    font-weight: bold;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 1rem 0.5rem;
  }
  .header__menu .header__nav a {
    display: block;
    font-weight: bold;
    color: #fff;
    padding: 1rem 0.5rem;
  }
  body.parent-group .header__menu .header__nav-item {
    margin-bottom: 0;
  }
  body.parent-group .header__menu .header__nav-item.parent__nav .sub__menu {
    display: none;
  }
  body.parent-group .header__menu .header__nav-item.parent__nav .sub__menu li {
    margin-left: 1.5rem;
    border-left: 1px solid #fff;
  }
  body.parent-group .header__menu .header__nav-item.parent__nav .sub__menu li a {
    padding: 1rem;
  }
  body.hsc .header__menu, body.htc .header__menu, body.iryo .header__menu {
    padding: 1rem 0;
  }
  body.hsc .header__nav-ls, body.htc .header__nav-ls, body.iryo .header__nav-ls {
    display: none;
  }
  body.hsc .header__nav-ls .header__nav-item.parent__nav .sub__menu, body.htc .header__nav-ls .header__nav-item.parent__nav .sub__menu, body.iryo .header__nav-ls .header__nav-item.parent__nav .sub__menu {
    display: none;
  }
  body.hsc .header__nav-ls .header__nav-item.parent__nav .sub__menu li, body.htc .header__nav-ls .header__nav-item.parent__nav .sub__menu li, body.iryo .header__nav-ls .header__nav-item.parent__nav .sub__menu li {
    margin-left: 1.5rem;
    border-left: 1px solid #fff;
  }
  body.hsc .header__nav-ls .header__nav-item.parent__nav .sub__menu li a, body.htc .header__nav-ls .header__nav-item.parent__nav .sub__menu li a, body.iryo .header__nav-ls .header__nav-item.parent__nav .sub__menu li a {
    padding: 1rem;
  }
  body.hsc .header__nav-ls a, body.htc .header__nav-ls a, body.iryo .header__nav-ls a {
    display: block;
    text-align: left;
    font-weight: bold;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 1rem 0.5rem;
  }
  body.hsc .header__nav, body.htc .header__nav, body.iryo .header__nav {
    margin-bottom: 3rem;
  }
  body.hsc .header__nav .sub__menu li a:before, body.htc .header__nav .sub__menu li a:before, body.iryo .header__nav .sub__menu li a:before {
    top: 1.7rem;
  }
  body.hsc .header__nav .header__nav-item.parent__nav .sub__menu, body.htc .header__nav .header__nav-item.parent__nav .sub__menu, body.iryo .header__nav .header__nav-item.parent__nav .sub__menu {
    display: none;
  }
  body.home .hero .hero-slider__item01 {
    aspect-ratio: 375/415;
  }
  body.home .hero .hero-slider__item01 .hero-item--01 {
    width: 32%;
    height: 34%;
    top: 21%;
    right: 8%;
    left: auto;
    z-index: 4 !important;
  }
  body.home .hero .hero-slider__item01 .hero-item--02 {
    width: 29%;
    left: auto;
    top: 50%;
    right: 3%;
  }
  body.home .hero .hero-slider__item01 .hero-item--03 {
    width: 29.3%;
    top: 43%;
    right: auto;
    left: 3%;
  }
  body.home .hero .hero-slider__item01 .hero-item--04 {
    width: 29%;
    top: 22%;
    left: 8%;
    right: auto;
  }
  body.home .hero .hero-slider__item01 .hero-item--05 {
    width: 25.6%;
    bottom: 1%;
    right: 2%;
  }
  body.home .hero .hero-slider__item01 .hero-item--06 {
    width: 26.1%;
    bottom: 1%;
    right: 5%;
  }
  body.home .hero .hero-slider__item01 .hero-img {
    width: 70%;
    left: 0%;
  }
  body.home .hero .hero-slider__item01 .hero-text {
    top: 2%;
    width: 100%;
  }
  body.home .hero .hero-slider__item01 .hero-text img {
    width: 90%;
  }
  body.home .hero .hero-slider__item02 {
    aspect-ratio: 375/415;
  }
  body.home .hero .hero-slider__item02 .hero-slider-parts-wrapper {
    display: none;
  }
  body.home .hero__elm .pickup {
    padding: 10px;
    margin-top: 0;
  }
  body.home .hero__elm .pickup .title {
    font-size: 1.125rem;
  }
  body.home .hero__elm .pickup a {
    font-size: 0.875rem;
  }
  body.home .section--02 {
    padding: 3.2rem 0 0;
  }
  body.home .visitors-list {
    box-shadow: 6px 6px 0 black;
    flex-wrap: wrap;
    border: 1px solid black;
  }
  body.home .visitors-list li {
    width: 100%;
  }
  body.home .visitors-list li a {
    font-size: 17px;
    padding: 13px 21px 13px;
    border: none;
    border-bottom: 1px solid black;
  }
  body.home .visitors-list li a::after {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  body.home .visitors-list li a .small {
    font-size: 13px;
  }
  body.home .section--03 .intro {
    line-height: 2.1;
    margin-bottom: 0;
    aspect-ratio: none;
    height: calc(100vw * 0.8);
    padding: 20px 0 20px;
  }
  body.home .section--03 .intro::before {
    background: url(../img/home/bg-section03-intro_sp.jpg) center/cover no-repeat;
  }
  body.home .section--03 .intro__wrap-text p .text_01 {
    width: 70vw;
  }
  body.home .section--03 .intro__wrap-text p .text_02 {
    width: 65vw;
  }
  body.home .section--03 .intro__wrap-text p .text_03 {
    width: 87vw;
  }
  body.home .section--03 .school-intro__lower .p-accreditation .item:nth-of-type(2) {
    margin-top: 0;
  }
  body.home .section--03 .school-intro__lower .p-accreditation .item .item-inner {
    max-width: 400px;
    margin-inline: auto;
  }
  body.home .section--03 .school-intro__lower .p-accreditation .item .title {
    font-size: 28px;
  }
  body.home .section--03 .school-intro__lower .p-accreditation .item .text {
    font-size: 15px;
  }
  body.home .section--04 .head__wrap, body.home .section--04 body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.home .section--04 .head__wrap, body.home .section--04 body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.home .section--04 .head__wrap, body.home .section--04 body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.home .section--04 .head__wrap {
    margin-bottom: 3rem;
  }
  body.home .section--04 .p-opencampus-img:nth-of-type(2) {
    width: 56vw;
  }
  body.home .section--04 .p-opencampus-btn {
    flex-direction: column;
  }
  body.page .tab-btns2 {
    margin-bottom: 0;
  }
  body.page .tab-btns2 button, body.page .tab-btns2 a {
    margin-bottom: 1rem;
    padding: .5rem;
    font-size: 0.875rem;
  }
  body.school-home .hero .hero-slider__item01 {
    padding-top: 0;
    aspect-ratio: 375/552;
  }
  body.school-home .hero .hero-slider__item01 .hero-item--01 {
    width: 32%;
    height: 34%;
    top: 21%;
    right: 8%;
    left: auto;
    z-index: 4 !important;
  }
  body.school-home .hero .hero-slider__item01 .hero-item--02 {
    width: 29%;
    left: auto;
    height: 23%;
    top: 50%;
    right: 3%;
  }
  body.school-home .hero .hero-slider__item01 .hero-item--03 {
    width: 29.3%;
    height: 32%;
    top: 43%;
    right: auto;
    left: 3%;
  }
  body.school-home .hero .hero-slider__item01 .hero-item--04 {
    width: 29%;
    height: 25%;
    top: 22%;
    left: 8%;
    right: auto;
  }
  body.school-home .hero .hero-slider__item01 .hero-item--05 {
    width: 25.6%;
    height: 25.7%;
    bottom: 1%;
    right: 2%;
  }
  body.school-home .hero .hero-slider__item01 .hero-item--06 {
    width: 26.1%;
    height: 26.9%;
    bottom: 1%;
    right: 5%;
  }
  body.school-home .hero .hero-slider__item01 .hero-img {
    width: 90%;
    left: 0%;
  }
  body.school-home .hero .hero-slider__item01 .hero-text {
    top: 2%;
    width: 100%;
  }
  body.school-home .hero .hero-slider__item01 .hero-text img {
    width: 90%;
  }
  body.school-home .hero__elm .pickup {
    padding: 10px;
  }
  body.school-home .hero__elm .pickup .title {
    font-size: 1.125rem;
  }
  body.school-home .hero__elm .pickup a {
    font-size: 0.875rem;
  }
  body.child-page.day.htc .day-acc .accContent {
    padding: 1.5rem 0;
  }
  body.child-page.gyouji .gyouji__content .gyouji__content__inner {
    flex-wrap: wrap;
  }
  body.child-page.gyouji.htc .sdgs__content-wrap {
    border-width: 7px;
  }
  body.child-page.htc.page-restaurant .page-restaurant h4.restaurant__title {
    font-size: 18px;
  }
  body.child-page.htc.page-restaurant h4.restaurant__title::before {
    width: 20px;
    height: 20px;
  }
  body.hsc .hero__elm .pickup, body.htc .hero__elm .pickup, body.iryo .hero__elm .pickup {
    margin-top: -19.4%;
    position: relative;
    z-index: 3;
  }
  body.hsc .simple-link-nav > .container, body.htc .simple-link-nav > .container, body.iryo .simple-link-nav > .container {
    padding-inline: 40px;
  }
  body.hsc .p-movie, body.htc .p-movie, body.iryo .p-movie {
    margin-top: 0;
  }
  body.hsc .p-point__title, body.htc .p-point__title, body.iryo .p-point__title {
    width: 72vw;
  }
  body.hsc .p-accreditation .item:nth-of-type(2), body.htc .p-accreditation .item:nth-of-type(2), body.iryo .p-accreditation .item:nth-of-type(2) {
    margin-top: 0;
  }
  body.hsc .p-accreditation .item .item-inner, body.htc .p-accreditation .item .item-inner, body.iryo .p-accreditation .item .item-inner {
    max-width: 400px;
    margin-inline: auto;
  }
  body.hsc .p-accreditation .item .title, body.htc .p-accreditation .item .title, body.iryo .p-accreditation .item .title {
    font-size: 28px;
  }
  body.hsc .p-accreditation .item .text, body.htc .p-accreditation .item .text, body.iryo .p-accreditation .item .text {
    font-size: 15px;
  }
  body.hsc .p-campus .p-campas__list, body.htc .p-campus .p-campas__list, body.iryo .p-campus .p-campas__list {
    gap: 10px;
  }
  body.hsc .p-campus .order__elm, body.htc .p-campus .order__elm, body.iryo .p-campus .order__elm {
    width: calc((100% / 2) - 5px);
  }
  body.hsc .p-campus .order__elm.crown::after, body.htc .p-campus .order__elm.crown::after, body.iryo .p-campus .order__elm.crown::after {
    width: 45px;
    height: 45px;
    top: 5px;
    right: 5px;
  }
  body.hsc .visitors-list, body.htc .visitors-list, body.iryo .visitors-list {
    box-shadow: 6px 6px 0 black;
    flex-wrap: wrap;
    border: 1px solid black;
  }
  body.hsc .visitors-list li, body.htc .visitors-list li, body.iryo .visitors-list li {
    width: 100%;
  }
  body.hsc .visitors-list li a, body.htc .visitors-list li a, body.iryo .visitors-list li a {
    font-size: 17px;
    padding: 13px 21px 13px;
    border: none;
    border-bottom: 1px solid black;
  }
  body.hsc .visitors-list li a::after, body.htc .visitors-list li a::after, body.iryo .visitors-list li a::after {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  body.hsc .visitors-list li a .small, body.htc .visitors-list li a .small, body.iryo .visitors-list li a .small {
    font-size: 13px;
  }
  body.hsc .p-opencampus-img:nth-of-type(2), body.htc .p-opencampus-img:nth-of-type(2), body.iryo .p-opencampus-img:nth-of-type(2) {
    max-width: 55vw;
  }
  body.hsc .p-opencampus-btn, body.htc .p-opencampus-btn, body.iryo .p-opencampus-btn {
    flex-direction: column;
  }
  .f-m-16 {
    font-size: 1rem;
  }
  body.parent-group .p-definition__lists__dotted dt {
    width: 100%;
    padding: 1rem 0 0;
    border-bottom: none;
  }
  body.parent-group .p-definition__lists__dotted dd {
    width: 100%;
    margin-left: 0;
    padding: 1rem 0 1rem;
  }
  body.parent-group .p-definition__site dt {
    width: 100%;
    border-bottom: 1px solid #F4CB35;
  }
  body.parent-group .p-definition__site dd {
    width: 100%;
  }
  body.parent-group .p-table-wrap .table {
    min-width: 550px;
  }
  body.parent-group .p-pagination .pagination__move__wrap {
    width: 100%;
  }
  body.parent-group .p-pagination .pagination__move__wrap:first-of-type {
    margin-bottom: 1rem;
  }
  body.parent-group .p-pagination .pagination__move__wrap:last-of-type {
    margin-top: 1rem;
  }
  body.parent-group .p-pagination .move_btn__wrap {
    justify-content: center;
  }
  body.parent-group .p-pagination .move_btn__wrap .move_btn:nth-of-type(odd) {
    margin-right: 5px;
  }
  body.parent-group .p-pagination .move_btn__wrap .move_btn a {
    width: 100%;
  }
  body.parent-group .p-pagination .move_btn__wrap .move_btn a {
    text-align: center;
  }
  body.hsc .p-definition__lists__dotted dt {
    width: 100%;
    padding: 1rem 0 0;
    border-bottom: none;
  }
  body.hsc .p-definition__lists__dotted dd {
    width: 100%;
    margin-left: 0;
    padding: 1rem 0 1rem;
  }
  body.hsc .p-definition__site dt {
    width: 100%;
    border-bottom: 1px solid #E85298;
  }
  body.hsc .p-definition__site dd {
    width: 100%;
  }
  body.hsc .p-table-wrap .table {
    min-width: 550px;
  }
  body.hsc .p-pagination .pagination__move__wrap {
    width: 100%;
  }
  body.hsc .p-pagination .pagination__move__wrap:first-of-type {
    margin-bottom: 1rem;
  }
  body.hsc .p-pagination .pagination__move__wrap:last-of-type {
    margin-top: 1rem;
  }
  body.hsc .p-pagination .move_btn__wrap {
    justify-content: center;
  }
  body.hsc .p-pagination .move_btn__wrap .move_btn:nth-of-type(odd) {
    margin-right: 5px;
  }
  body.hsc .p-pagination .move_btn__wrap .move_btn a {
    width: 100%;
  }
  body.hsc .p-pagination .move_btn__wrap .move_btn a {
    text-align: center;
  }
  body.htc .p-definition__lists__dotted dt {
    width: 100%;
    padding: 1rem 0 0;
    border-bottom: none;
  }
  body.htc .p-definition__lists__dotted dd {
    width: 100%;
    margin-left: 0;
    padding: 1rem 0 1rem;
  }
  body.htc .p-definition__site dt {
    width: 100%;
    border-bottom: 1px solid #FF7603;
  }
  body.htc .p-definition__site dd {
    width: 100%;
  }
  body.htc .p-table-wrap .table {
    min-width: 550px;
  }
  body.htc .p-pagination .pagination__move__wrap {
    width: 100%;
  }
  body.htc .p-pagination .pagination__move__wrap:first-of-type {
    margin-bottom: 1rem;
  }
  body.htc .p-pagination .pagination__move__wrap:last-of-type {
    margin-top: 1rem;
  }
  body.htc .p-pagination .move_btn__wrap {
    justify-content: center;
  }
  body.htc .p-pagination .move_btn__wrap .move_btn:nth-of-type(odd) {
    margin-right: 5px;
  }
  body.htc .p-pagination .move_btn__wrap .move_btn a {
    width: 100%;
  }
  body.htc .p-pagination .move_btn__wrap .move_btn a {
    text-align: center;
  }
  body.iryo .p-definition__lists__dotted dt {
    width: 100%;
    padding: 1rem 0 0;
    border-bottom: none;
  }
  body.iryo .p-definition__lists__dotted dd {
    width: 100%;
    margin-left: 0;
    padding: 1rem 0 1rem;
  }
  body.iryo .p-definition__site dt {
    width: 100%;
    border-bottom: 1px solid #43AA88;
  }
  body.iryo .p-definition__site dd {
    width: 100%;
  }
  body.iryo .p-table-wrap .table {
    min-width: 550px;
  }
  body.iryo .p-pagination .pagination__move__wrap {
    width: 100%;
  }
  body.iryo .p-pagination .pagination__move__wrap:first-of-type {
    margin-bottom: 1rem;
  }
  body.iryo .p-pagination .pagination__move__wrap:last-of-type {
    margin-top: 1rem;
  }
  body.iryo .p-pagination .move_btn__wrap {
    justify-content: center;
  }
  body.iryo .p-pagination .move_btn__wrap .move_btn:nth-of-type(odd) {
    margin-right: 5px;
  }
  body.iryo .p-pagination .move_btn__wrap .move_btn a {
    width: 100%;
  }
  body.iryo .p-pagination .move_btn__wrap .move_btn a {
    text-align: center;
  }
  .p-news__lower .new {
    width: 30px;
    height: 30px;
    font-size: 0.625rem;
  }
  .p-news__lower .news__lists li {
    padding: 0 10px 1rem;
    margin: 0 0 1rem;
  }
  .p-news__lower .title {
    font-size: 0.875rem;
  }
  .p-news__lower .content {
    padding-left: 10px;
  }
  .p-aside01 .aside01__wrap .aside01__block.aside01__block--twitter {
    margin-bottom: 5rem;
  }
  .p-aside02 .flag {
    width: 40%;
  }
  .p-oc .point__elm01 {
    left: -8%;
    bottom: 38%;
    top: auto;
  }
  .p-oc .point__elm02 {
    right: -11%;
    top: -14%;
  }
  .p-oc .ch-elm01 {
    left: 0;
  }
  .p-oc .ch-elm02 {
    right: 0;
  }
  .p-oc .item-01 .inner {
    position: relative;
    display: inline-block;
  }
  .p-oc .item-04 {
    margin-bottom: 4rem;
  }
  .p-oc .point-lists li {
    width: calc( (100% - 2%) / 2);
    margin-bottom: 2%;
    padding: 1rem .5rem;
  }
  .p-oc .point-lists li:nth-of-type(odd) {
    margin-right: 2%;
  }
  .p-video-content {
    margin-bottom: 3rem;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .y2 .y2-img img {
    width: 100%;
  }
  body.hsc.course .kl__title .wrap {
    padding: 20px 0 30px;
    flex-direction: column;
    row-gap: 15px;
  }
  body.hsc.course .kl__title .parts {
    display: none;
  }
  body.hsc.course .kl01 .kl-lower .text {
    font-size: 16px;
  }
  body.hsc.course .kl02__list {
    -ms-grid-columns: (1fr)[1];
    grid-template-columns: repeat(1, 1fr);
    margin-left: 4vw;
  }
  body.lp .container .inner__container {
    padding-left: 0;
    padding-right: 0;
  }
  body.lp.opencampus .oc-main-under {
    padding-top: 0;
    padding-bottom: 4.5rem;
  }
  body.lp.opencampus .oc-main-under__block-item {
    padding: 1px;
    width: calc(100%/ 2);
  }
  body.lp.opencampus .oc-schedule-heading-text {
    font-size: 1.1rem;
  }
  body.lp.opencampus .oc-schedule__block {
    padding: 3.5rem .5rem;
  }
  body.lp.opencampus .oc-schedule__list-item:nth-of-type(even) {
    margin-right: 0;
  }
  body.lp.opencampus .oc-schedule__list-item {
    width: 47.5%;
  }
  body.lp.opencampus .section__01 h2 span {
    padding: .5rem;
    margin-bottom: 5px;
  }
  body.lp.opencampus .section__01 h2 span:after {
    content: '';
    display: block;
    background-color: rgba(252, 242, 93, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: -1;
  }
  body.lp.opencampus .section__01 .banner__doc {
    margin-top: 2rem;
  }
  body.lp.opencampus .section__01 .banner__reserve {
    margin-top: 4rem;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap {
    padding: 3.5rem .5rem;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block li {
    width: 47.5%;
    margin-top: 5%;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block li:not(:nth-of-type(even)) {
    margin-right: 5%;
  }
  body.lp.opencampus.parent-group .section__02 .lg__container {
    padding: 4.5rem 0 4rem;
    border-radius: 20px;
  }
  body.lp.opencampus.parent-group .section__04 .container {
    background: url(../img/lp/oc/img_section4_bg_sp.jpg) no-repeat;
    background-size: cover;
  }
  body.lp.opencampus.parent-group .section__04.is_active .container {
    padding-bottom: 5rem;
  }
  body.lp.opencampus.parent-group .section__04 .head__wrap {
    margin-bottom: 5rem;
  }
  body.lp.opencampus.parent-group .section__04 .point__lists li {
    margin-bottom: 3rem;
  }
  body.lp.opencampus.parent-group .section__04 figure {
    margin-bottom: 0;
  }
  body.lp.opencampus.parent-group .section__04 figure button {
    margin: auto;
  }
  body.lp.opencampus.parent-group .section__04 figure .click__content {
    height: 0;
    margin-top: 2rem;
    padding: 0 2rem;
    font-size: 1rem;
  }
  body.lp.opencampus.parent-group .section__04 figure .click__content:after {
    left: calc(50% - 15px);
    border-width: 0 10px 20px;
  }
  body.lp.opencampus.parent-group .section__04 figure .click__content.is_active {
    padding: 2rem;
    height: 100%;
  }
  body.lp.opencampus.parent-group .section__03 + .inner__container {
    margin-bottom: 4.2rem;
  }
  body.lp.opencampus.parent-group .section__05 .head__wrap-text p {
    font-size: 14px;
    margin: 2.2rem 0 3.2rem;
  }
  body.lp.opencampus.parent-group .section__07 .container {
    background: #fff url(../img/lp/oc/img_section7_bg01_sp.png) no-repeat;
    background-size: 100%;
    background-position: center bottom;
  }
  body.lp.opencampus.parent-group .section__07 .item01 {
    display: none;
  }
  body.lp.opencampus.parent-group .section__08 .inner__container {
    padding-bottom: 0;
  }
  body.lp.opencampus.child-page .section__10 .section-heading span {
    padding: .5rem;
    margin-bottom: 5px;
  }
  body.lp.opencampus.child-page .section__10 .section-heading span:after {
    content: '';
    display: block;
    background-color: rgba(252, 242, 93, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: -1;
  }
  body.lp.opencampus.hsc .ex__wrap p {
    text-align: start;
    font-size: 1rem;
  }
  body.lp.opencampus.hsc .ex__wrap br {
    display: none;
  }
  body.lp.opencampus.hsc .section__03 .outer__container::before, body.lp.opencampus.hsc .section__03 .outer__container::after {
    display: none;
  }
  body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch_top {
    top: -50px;
    bottom: auto;
    display: block;
    width: 74px;
  }
  body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch--05 {
    right: calc(50% - (74px / 2));
  }
  body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch--06 {
    bottom: -60px;
  }
  body.lp.opencampus.hsc .section__03 .outer__container .merit-item {
    padding-bottom: 6rem;
  }
  body.lp.opencampus.hsc .section__03 .outer__container .merit-item .merit_ch {
    top: auto;
    bottom: 5%;
    right: calc(50% - (74px / 2));
    display: block;
    width: 74px;
  }
  body.lp.opencampus.hsc .section__03 .merit_ch {
    display: none;
  }
  body.lp.opencampus.hsc .section__08 .ch03 {
    bottom: 5%;
  }
  body.lp.opencampus.htc .ex__wrap p {
    text-align: start;
    font-size: 1rem;
  }
  body.lp.opencampus.htc .ex__wrap br {
    display: none;
  }
  body.lp.opencampus.htc .section__03 .outer__container::before, body.lp.opencampus.htc .section__03 .outer__container::after {
    display: none;
  }
  body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch_top {
    top: -50px;
    bottom: auto;
    display: block;
    width: 74px;
  }
  body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch--05 {
    right: calc(50% - (74px / 2));
  }
  body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch--06 {
    bottom: -60px;
  }
  body.lp.opencampus.htc .section__03 .outer__container .merit-item {
    padding-bottom: 6rem;
  }
  body.lp.opencampus.htc .section__03 .outer__container .merit-item .merit_ch {
    top: auto;
    bottom: 5%;
    right: calc(50% - (74px / 2));
    display: block;
    width: 74px;
  }
  body.lp.opencampus.htc .section__03 .merit_ch {
    display: none;
  }
  body.lp.opencampus.htc .section__06 .piano {
    width: 50%;
    position: static;
    display: block;
    margin-left: auto;
  }
  body.lp.opencampus.htc .section__08 .ch03 {
    bottom: 5%;
  }
  body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch_top {
    top: -50px;
    bottom: auto;
    display: block;
    width: 74px;
  }
  body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch--05 {
    right: calc(50% - (74px / 2));
  }
  body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch--06 {
    bottom: -60px;
  }
  body.lp.opencampus.iryo .section__03 .outer__container .merit-item {
    padding-bottom: 6rem;
  }
  body.lp.opencampus.iryo .section__03 .outer__container .merit-item .merit_ch {
    top: auto;
    bottom: 5%;
    right: calc(50% - (74px / 2));
    display: block;
    width: 74px;
  }
  body.lp.opencampus.iryo .section__03 .merit_ch {
    display: none;
  }
  body.lp.opencampus.iryo .section__06 .schedule__wrap > .item01 {
    top: -170px;
    max-width: 200px;
  }
  body.lp.opencampus.iryo .section__07 .experience__item.experience__item--01 {
    max-width: 160px;
  }
  body.lp.opencampus.iryo .section__07 .experience__item.experience__item--02 {
    max-width: 160px;
  }
  body.lp.opencampus.iryo .section__08 .ch03 {
    bottom: 5%;
  }
  body.lp.opencampus .section__06 .container {
    background: url(../img/lp/oc/img_section4_bg_sp.jpg) no-repeat;
    background-size: cover;
  }
  body.lp.opencampus .section__06 .piano {
    width: 84%;
  }
  body.lp.opencampus .section__06 .schedule__wrap > .item01 {
    max-width: 300px;
    width: 55%;
    right: -5%;
    top: -26vw;
  }
  body.lp.opencampus .section__06 .head__wrap, body.lp.opencampus.parent-group .section__06 .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .section__06 .head__wrap {
    margin-bottom: 10rem;
  }
  body.lp.opencampus .section__06 li.is_scrolled:not(:last-of-type) .number:after {
    top: 105%;
  }
  body.lp.opencampus .section__06 li:nth-of-type(odd) .number:after {
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg);
    left: 15%;
  }
  body.lp.opencampus .section__06 li:nth-of-type(even) .number:after {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
    right: 15%;
  }
  body.lp.opencampus .section__06 li {
    margin-top: 7rem;
  }
  body.lp.opencampus .section__06 .number {
    max-width: 480px;
    margin: auto;
  }
  body.lp.opencampus .section__06 .number .head {
    font-size: 1.3125rem;
  }
  body.lp.opencampus .section__06 .number .contents .pic {
    max-width: 90%;
    margin: auto;
  }
  body.lp.opencampus .section__06 .number .current-num {
    padding: 0.6rem 5px;
    line-height: 1.4;
  }
  body.lp.opencampus .section__06 .number .head__title .title {
    font-size: 1.125rem;
  }
  body.lp.opencampus .section__06 .number.number--01 .ch01 {
    max-width: 100px;
    width: 30%;
    bottom: auto;
    top: -25px;
    right: 0;
  }
  body.lp.opencampus .section__06 .number.number--01 .item01 {
    bottom: 95%;
    left: 30%;
    width: 29%;
  }
  body.lp.opencampus .section__06 .number.number--02 .ch01 {
    width: 17%;
    max-width: 70px;
  }
  body.lp.opencampus .section__06 .number.number--03 .ch01 {
    top: -5%;
    right: 0;
    z-index: 10;
    width: 35%;
  }
  body.lp.opencampus .section__06 .number.number--03 .item01 {
    display: none;
  }
  body.lp.opencampus .section__06 .number.number--03 .item02 {
    display: none;
  }
  body.lp.opencampus .section__06 .number.number--04 .ch01 {
    width: 40%;
    max-width: 170px;
  }
  body.lp.opencampus .section__06 .number.number--04 .ch02 {
    top: 90%;
    width: 17%;
  }
  body.lp.opencampus .section__06 .number.number--05 .ch01 {
    width: 19%;
    right: 0;
  }
  body.lp.opencampus .section__07 .tab-btns2 {
    margin-bottom: 0;
  }
  body.lp.opencampus .section__07 .tab-btns2 button {
    margin-bottom: 1rem;
    padding: .5rem;
    font-size: 0.875rem;
  }
  body.lp.opencampus .section__07 .content {
    padding: 2rem 0;
  }
  body.lp.opencampus .section__08 .content {
    padding-top: 4rem;
  }
  body.lp.opencampus .section__08 .ch01 {
    width: 48%;
    left: 1%;
  }
  body.lp.opencampus .section__08 .ch02 {
    width: 36%;
    right: 2%;
  }
  body.lp.opencampus .section__08 .ch03 {
    width: 35%;
    right: 2%;
    bottom: 10%;
  }
  body.lp.opencampus .section__09 {
    margin-bottom: 5rem;
  }
  body.lp.opencampus .section__09 .container {
    background: #fff url(../img/lp/oc/img_section9_bg01_sp.png) no-repeat;
    background-size: cover;
    border: 15px solid #F5B5A7;
  }
  body.lp.opencampus .section__09 .content {
    max-width: 390px;
  }
  body.lp.opencampus .section__09 .ch02 img {
    margin-top: -50%;
  }
  body.lp.opencampus .section__10 .item01 {
    width: 26%;
    left: 0;
    top: -10%;
  }
  body.lp.opencampus .section__10 .item02 {
    width: 21%;
    right: 0;
  }
  body.lp.opencampus .section__12 .item01 {
    width: 40%;
    left: 0;
    top: -2%;
  }
  body.lp.opencampus .section__12 .item02 {
    width: 21%;
    right: 0;
    top: -30px;
  }
  body.lp .section__07 .section__08 div.content {
    overflow-x: visible;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
  .sm-only {
    display: block;
  }
  footer {
    padding: 4rem 0 5rem;
  }
  footer .footer-link-top {
    right: 0.5rem;
    top: -5rem;
  }
  footer .footer-link-top .ch {
    width: 60px;
  }
  footer .footer-link-top .txt {
    width: 90px;
  }
  footer .company__info .logo {
    margin: auto;
    text-align: center;
    margin-bottom: 1rem;
  }
  .header__logo {
    margin-right: 1rem;
  }
  .header__button-container button {
    height: 70px;
    width: 70px;
  }
  .header__menu__main {
    display: none;
  }
  body.parent-group .header__menu .header__nav-item.is_ch_show a:after {
    right: calc(140% + 30px);
  }
  body.home .banner-slide__main .slide-arrow {
    width: 26px;
  }
  body.home .banner-slide__main .slide-arrow span {
    width: 11px;
    height: 23px;
  }
  body.home .section--03 .bg.bg-upper {
    padding: 3.5rem 0 2.5rem;
  }
  body.home .section--03 .bg.bg-lower {
    padding: 2.5rem 0 2rem;
  }
  body.home .section--03 .intro__wrap-text p {
    padding: 0 4px;
  }
  body.home .section--03 .school-intro {
    padding: 3.2rem 0;
  }
  body.home .section--03 .school-intro__img {
    width: 61vw;
  }
  body.home .section--03 .school-intro__list {
    width: 90%;
    margin-inline: auto;
  }
  body.home .section--03 .school-intro__list li:first-of-type {
    min-height: 45px;
  }
  body.home .section--03 .school-intro__list li a {
    font-size: 15px;
    padding: 0 1.3rem;
  }
  body.home .section--04 {
    margin-bottom: 4rem;
  }
  body.home .section--04 > .container {
    padding-inline: 0;
  }
  body.home .section--05 .tab-btns {
    flex-wrap: wrap;
  }
  body.home .section--05 .tab-btns button {
    width: 50%;
    margin-bottom: 1.5rem;
  }
  body.home .section--05 .tab-btns button img {
    width: 100%;
  }
  body.home .section--05 .tab-btns button.is_active:after {
    border-width: 12px 8px 0;
  }
  body.school-home .head__wrap, body.school-home body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.school-home .head__wrap, body.school-home body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.school-home .head__wrap, body.school-home body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.school-home .head__wrap,
  body.home .head__wrap,
  body.home body.lp.opencampus.parent-group .section__04 .head__wrap,
  body.lp.opencampus.parent-group .section__04 body.home .head__wrap,
  body.home body.lp.opencampus .section__03 .head__wrap,
  body.lp.opencampus .section__03 body.home .head__wrap,
  body.home body.lp.opencampus .section__05 .head__wrap,
  body.lp.opencampus .section__05 body.home .head__wrap {
    font-size: 2.5rem;
  }
  body.school-home .head__wrap.is_scrolled .en span, body.school-home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span, body.lp.opencampus.parent-group .section__04 body.school-home .is_scrolled.head__wrap .en span,
  body.home .head__wrap.is_scrolled .en span,
  body.home body.lp.opencampus.parent-group .section__04 .is_scrolled.head__wrap .en span,
  body.lp.opencampus.parent-group .section__04 body.home .is_scrolled.head__wrap .en span {
    font-size: 2.5rem;
  }
  body {
    font-size: 0.875rem;
  }
  body.child-page.day .day__slide__inner {
    max-width: 440px;
  }
  body.child-page.day .day__flow-item {
    width: 100%;
    margin-inline: auto;
  }
  body.child-page.day .day__life__section .day__life__link {
    font-size: 0.875rem;
  }
  body.child-page.day.htc .day-acc .accBtn {
    padding: 1rem 10px;
  }
  body.child-page.day.iryo .day-acc .accBtn {
    padding: 1rem 10px;
  }
  body.child-page.gyouji.htc .sdgs__content {
    width: 92%;
  }
  body.child-page.gyouji.htc .sdgs__content-wrap {
    padding: 24px 0 20px;
    border-width: 5px;
  }
  body.child-page.gyouji.htc .sdgs__content-in .item {
    width: 100%;
  }
  body.child-page.hutankeigen .page-contents .graf__wrap {
    overflow: scroll;
  }
  body.child-page.hutankeigen .page-contents .graf__wrap .graf__img {
    width: 130%;
    max-width: 130%;
  }
  body.child-page.hutankeigen .page-contents .tel__wrap .tel-icon {
    width: 46px;
  }
  body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn {
    padding: 1rem 10px;
  }
  body.child-page.hs-teachers .page-header .head__wrap h1, body.child-page.hs-teachers .page-header body.lp.opencampus.parent-group .section__04 .head__wrap h1, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .page-header .head__wrap h1 {
    top: 90%;
  }
  body.child-page.hs-teachers .section__experience .head .head__item {
    width: 18.66667%;
  }
  body.child-page.htc.page-restaurant .page-header {
    background-size: 100%;
    aspect-ratio: 375/607;
    -webkit-animation: restaurant-mv-sp 10s linear infinite normal forwards;
    animation: restaurant-mv-sp 10s linear infinite normal forwards;
  }
  body.child-page.htc.page-restaurant .page-header .container {
    align-items: flex-start;
    padding-top: 50px;
  }
  body.child-page.htc.page-restaurant .flow-restaurant--title {
    max-width: 445px;
    margin-inline: auto;
  }
  body.child-page.htc.page-restaurant .flow-restaurant--body {
    max-width: 445px;
    margin-inline: auto;
    text-align: left;
  }
  body.child-page.htc.page-restaurant .restaurant-three {
    max-width: 445px;
    margin-inline: auto;
  }
  body.hsc .section--02, body.htc .section--02, body.iryo .section--02 {
    padding-bottom: 40px;
  }
  body.hsc .news, body.htc .news, body.iryo .news {
    padding: 30px 0 80px;
  }
  body.hsc .banner-slide__main .slide-arrow, body.htc .banner-slide__main .slide-arrow, body.iryo .banner-slide__main .slide-arrow {
    width: 26px;
  }
  body.hsc .banner-slide__main .slide-arrow span, body.htc .banner-slide__main .slide-arrow span, body.iryo .banner-slide__main .slide-arrow span {
    width: 11px;
    height: 23px;
  }
  body.hsc .p-access .p-gradient {
    font-size: 1.4375rem;
  }
  body.hsc .section--06 .inner h3 {
    text-align: center;
    font-size: 1.5625rem;
    padding: .5rem;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  body.hsc .section--06 .inner h3.is_scrolled {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  body.hsc .section--06 .elm__block.elm__block--img {
    width: 100%;
    min-width: auto;
  }
  body.hsc .section--06 .bg__item.bg__item--01 {
    display: none;
  }
  body.hsc .section--06 .bg__item.bg__item--02 {
    display: none;
  }
  body.htc .p-access .p-gradient {
    font-size: 1.4375rem;
  }
  body.htc .section--06 .course__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  body.htc .section--06 .course__title.license {
    font-size: 26px;
  }
  body.htc .section--06 .course__title.license span {
    font-size: 21px;
  }
  body.htc .section--06 .course__text {
    font-size: 16px;
  }
  body.htc .section--06 .elm__card-item {
    width: 100%;
  }
  body.htc .p-campus .p-gradient {
    font-size: 1.25rem;
  }
  body.iryo.school-home .elm__card-item {
    width: 100%;
  }
  body.iryo .p-access .p-gradient {
    font-size: 1.4375rem;
  }
  body.iryo .p-campus .p-gradient {
    font-size: 1.25rem;
  }
  main {
    margin-top: 70px;
  }
  .d-md--none {
    display: none;
  }
  .f-s-17 {
    font-size: 1.0625rem;
  }
  .f-s-10 {
    font-size: 0.625rem;
  }
  .f-s-12 {
    font-size: 0.75rem;
  }
  .f-s-13 {
    font-size: 0.8125rem;
  }
  .f-s-14 {
    font-size: 0.875rem;
  }
  .f-s-16 {
    font-size: 1rem;
  }
  body .inner__container {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
  body.opencampus .p-form-second form dl dd {
    width: 100%;
  }
  body.opencampus .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  body.opencampus .p-oc-card .content {
    padding: 5px;
  }
  body.opencampus .p-oc-card .content .study-cat {
    min-width: 85px;
  }
  body.opencampus .p-oc-card .content figcaption {
    min-height: 140px;
  }
  body.opencampus .p-oc-card__modal .modal__container {
    font-size: 1rem;
  }
  body.opencampus .p-oc-card__modal .oc-detail .oc-detail__body {
    padding-left: 0;
    padding-right: 0;
    font-size: 0.875rem;
  }
  body.parent-group blockquote {
    padding-left: 1rem;
  }
  body.parent-group .p-form form dl dt {
    border-bottom: 1px solid #6c757d;
    width: 100%;
  }
  body.parent-group .p-form form dl dt br {
    display: none;
  }
  body.parent-group .p-form form dl dt span.badge {
    margin-left: .5rem;
  }
  body.parent-group .p-form form dl dd {
    width: 100%;
  }
  body.parent-group .p-form form dl input[type="email"] {
    width: 100%;
  }
  body.parent-group.page .p-form-second form dl dd {
    width: 100%;
  }
  body.parent-group.page .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  body.hsc blockquote {
    padding-left: 1rem;
  }
  body.hsc .p-form form dl dt {
    border-bottom: 1px solid #6c757d;
    width: 100%;
  }
  body.hsc .p-form form dl dt br {
    display: none;
  }
  body.hsc .p-form form dl dt span.badge {
    margin-left: .5rem;
  }
  body.hsc .p-form form dl dd {
    width: 100%;
  }
  body.hsc .p-form form dl input[type="email"] {
    width: 100%;
  }
  body.hsc .p-form-second form dl dd {
    width: 100%;
  }
  body.hsc .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  body.hsc.page .p-form-second form dl dd {
    width: 100%;
  }
  body.hsc.page .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  body.htc blockquote {
    padding-left: 1rem;
  }
  body.htc .p-form form dl dt {
    border-bottom: 1px solid #6c757d;
    width: 100%;
  }
  body.htc .p-form form dl dt br {
    display: none;
  }
  body.htc .p-form form dl dt span.badge {
    margin-left: .5rem;
  }
  body.htc .p-form form dl dd {
    width: 100%;
  }
  body.htc .p-form form dl input[type="email"] {
    width: 100%;
  }
  body.htc .p-form-second form dl dd {
    width: 100%;
  }
  body.htc .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  body.htc.page .p-form-second form dl dd {
    width: 100%;
  }
  body.htc.page .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  body.iryo blockquote {
    padding-left: 1rem;
  }
  body.iryo .p-form form dl dt {
    border-bottom: 1px solid #6c757d;
    width: 100%;
  }
  body.iryo .p-form form dl dt br {
    display: none;
  }
  body.iryo .p-form form dl dt span.badge {
    margin-left: .5rem;
  }
  body.iryo .p-form form dl dd {
    width: 100%;
  }
  body.iryo .p-form form dl input[type="email"] {
    width: 100%;
  }
  body.iryo .p-form-second form dl dd {
    width: 100%;
  }
  body.iryo .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  body.iryo.page .p-form-second form dl dd {
    width: 100%;
  }
  body.iryo.page .p-form-second form dl input[type="email"] {
    width: 100%;
  }
  .p-target-menu li {
    width: 50%;
  }
  .p-info .container .head__wrap, .p-info .container body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .p-info .container .head__wrap, .p-info .container body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 .p-info .container .head__wrap, .p-info .container body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 .p-info .container .head__wrap {
    margin-bottom: 6rem;
  }
  .p-info .bg-elm {
    width: 50%;
    max-width: 200px;
  }
  .p-info .text-elm.text-elm--01 {
    font-size: 1.4375rem;
    margin-bottom: 1.25rem;
  }
  .p-info .text-elm.text-elm--02 {
    font-size: 0.875rem;
    margin-bottom: 2.5rem;
  }
  .p-info .text-elm.text-elm--03 {
    font-size: 1.4375rem;
    margin-bottom: 1.25rem;
  }
  .p-news__lower .head {
    font-size: 1.125rem;
  }
  .p-news figcaption {
    min-height: 140px;
  }
  .p-news__archive figcaption {
    min-height: 140px;
  }
  .p-news__archive .tab-btns {
    flex-wrap: wrap;
  }
  .p-news__archive .tab-btns button {
    width: 50%;
    margin-bottom: 1.5rem;
  }
  .p-news__archive .tab-btns button img {
    width: 100%;
  }
  .p-news__archive .tab-btns button.is_active:after {
    border-width: 12px 8px 0;
  }
  .p-aside01 .head {
    font-size: 1rem;
  }
  .p-aside01 .thumb {
    padding-top: 68%;
  }
  .p-aside01 .content {
    padding-left: 0;
  }
  .p-aside02 .aside02__wrap .aside02__block:after {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  .p-campus .p-gradient {
    font-size: 1.25rem;
  }
  .p-campus .p-gradient span {
    width: 100%;
    text-align: center;
  }
  .p-oc .point__elm03 {
    left: 1%;
    top: 10%;
    width: 30%;
  }
  .p-oc .ch-elm01 {
    width: 10%;
    bottom: -30%;
  }
  .p-oc .ch-elm02 {
    width: 10%;
    bottom: -30%;
  }
  .p-oc .item-01 {
    width: 90%;
    margin: auto;
  }
  .p-oc .item-02 {
    margin-top: -25%;
  }
  .p-oc .item-02 strong {
    font-size: 1.5625rem;
    display: block;
    padding: .3rem 0;
    text-shadow: 0 0 3px #838383;
  }
  .p-oc .point-lists li {
    font-size: 0.75rem;
    min-height: 43px;
  }
  .p-oc .point-lists li:last-of-type {
    line-height: 1.1;
  }
  #p-img-content {
    height: 41vh;
  }
  .banner__reserve .human {
    max-width: 302px;
    width: 30%;
  }
  .banner__reserve .text {
    margin-bottom: 5px;
    -webkit-transform: translateY(170%);
    transform: translateY(170%);
    top: calc(-35% - 10px);
  }
  .banner__doc a .text {
    margin-bottom: 5px;
    -webkit-transform: translateY(170%);
    transform: translateY(170%);
    top: calc(-35% - 10px);
  }
  .cv__btns {
    display: none;
  }
  .cv__btns li {
    width: 50%;
  }
  .cv__btns a img {
    margin-right: 0.5rem;
  }
  body.kita-t-b-w-lower main nav .breadcrumb li, body.kita-t-b-w-lower main nav .breadcrumb a, body.kita-t-b-w-lower main nav .breadcrumb span {
    font-size: 11px;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-hero_text_img {
    top: auto;
    bottom: 16%;
  }
  body.hsc.course .kl__title {
    margin-bottom: 20px;
  }
  body.hsc.course .kl__title h2 {
    font-size: 21px;
    padding: 6px 30px;
  }
  body.hsc.course .kl__title span.text {
    font-size: 14px;
  }
  body.hsc.course .kl-parts1 .ksp-wrap .text {
    width: 100%;
  }
  body.hsc.course .kl-parts1 .ksp-wrap .img {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
  body.hsc.course .kl-parts1 .ksp-wrap .text {
    width: 100%;
  }
  body.hsc.course .kl-parts1 .ksp-wrap .img {
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  body.hsc.course .kl-parts1 .point__item--01 {
    width: 30vw;
    max-width: 113px;
    top: 40vw;
    left: 0;
  }
  body.hsc.course .kl-parts1 .point__item--02 {
    max-width: 132px;
    width: 35vw;
    top: 32vw;
    right: 0;
  }
  body.hsc.course .kl02__list ul li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  body.hsc.course .kl02__list ul li span.quali__label {
    font-size: 0.8125rem;
    width: 75px;
  }
  body.hsc.course .kl02 h4.nk02__title {
    font-size: 5vw;
  }
  body.hsc.course .kl03__mit {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
  body.hsc.course .kl04__top {
    width: 75vw;
    margin-inline: auto;
  }
  body.hsc.course .kl04__lower {
    width: 100%;
  }
  body.hsc.course .kl05__upper .img-wrap {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    gap: 26px;
  }
  body.hsc.course .kl05__upper .img-wrap .item::before {
    right: -5px;
  }
  body.hsc.course .kl-movie .video-indexwrap {
    margin-bottom: 60px !important;
  }
  body.hsc.course .section__tech-materials .materials__item:first-of-type {
    border-bottom: 3px dashed #F5B6D2;
  }
  body.hsc.course .course__slide .course__slide-item {
    margin-left: 2.5rem;
  }
  body.lp.opencampus .head__wrap:not(.not) h2, body.lp.opencampus.parent-group .section__04 .head__wrap:not(.not) h2 {
    font-size: 1.4375rem;
  }
  body.lp.opencampus .head__wrap:not(.not) .f-century {
    font-size: 0.875rem;
  }
  body.lp.opencampus .oc-schedule__block {
    margin: 0 -10px;
  }
  body.lp.opencampus .oc-schedule__block__container {
    max-width: 540px;
  }
  body.lp.opencampus .oc-schedule__list-item-wrap {
    width: 150px;
    height: 150px;
  }
  body.lp.opencampus .oc-schedule__list-item-wrap p.year {
    font-size: 1.25rem;
  }
  body.lp.opencampus .oc-schedule__list-item-wrap p.date {
    font-size: 1.5625rem;
  }
  body.lp.opencampus .oc-schedule__list-item-wrap p.week {
    font-size: 1.25rem;
  }
  body.lp.opencampus .section__01 {
    margin-bottom: 4rem;
    margin-top: 8rem;
  }
  body.lp.opencampus.parent-group .section__imgs {
    padding: 40px 0 70px;
  }
  body.lp.opencampus.parent-group .section__imgs .imgs-wrapper {
    -ms-grid-columns: 1fr 3px 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 3px;
  }
  body.lp.opencampus.parent-group .section__00 h2 {
    margin: 0 -10px;
    font-size: 1.25rem;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap {
    margin: 0 -10px;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block li .item {
    width: 150px;
    height: 150px;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block .year {
    font-size: 1.25rem;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block .date {
    font-size: 1.5625rem;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block .week {
    font-size: 1.25rem;
  }
  body.lp.opencampus.parent-group .main__inner--01 {
    padding: 3.8rem 0 0;
  }
  body.lp.opencampus.parent-group .section__02 {
    padding: 2.5rem 10px;
  }
  body.lp.opencampus.parent-group .section__02 .item--01 {
    left: -5%;
    width: 43%;
    max-width: 190px;
  }
  body.lp.opencampus.parent-group .section__02 .item--02 {
    width: 43%;
    max-width: 200px;
  }
  body.lp.opencampus.parent-group .section__02 .item--03 {
    bottom: -1%;
    left: -10px;
    width: 38%;
    max-width: 190px;
  }
  body.lp.opencampus.parent-group .section__02 .item--04 {
    bottom: -1%;
    width: 38%;
    max-width: 190px;
  }
  body.lp.opencampus.parent-group .section__04 {
    margin-bottom: 5rem;
  }
  body.lp.opencampus.parent-group .section__03 .head__wrap {
    margin-bottom: 5rem;
  }
  body.lp.opencampus.parent-group .section__03 .inner__container {
    margin-bottom: 20px;
  }
  body.lp.opencampus.parent-group .section__03 .inner__container {
    padding-bottom: 2rem;
  }
  body.lp.opencampus.parent-group .section__03 .ch--01 {
    width: 20%;
    max-width: 100px;
  }
  body.lp.opencampus.parent-group .section__03 .ch--02 {
    width: 20%;
    max-width: 100px;
  }
  body.lp.opencampus.parent-group .section__03 .ch--03 {
    width: 20%;
    max-width: 100px;
  }
  body.lp.opencampus.parent-group .section__03 .merit_ch {
    max-width: 90px;
    width: 24%;
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    margin-bottom: 1rem;
  }
  body.lp.opencampus.parent-group .section__05 {
    margin-top: 60px;
  }
  body.lp.opencampus.hsc .hero {
    margin-bottom: 3rem;
  }
  body.lp.opencampus.hsc .section__05 {
    padding-top: 60px;
  }
  body.lp.opencampus.hsc .section__07 .experience__item.experience__item--01 {
    width: 70%;
    top: -4rem;
  }
  body.lp.opencampus.hsc .section__07 .experience__item.experience__item--02 {
    max-width: 37%;
  }
  body.lp.opencampus.hsc .section__07 .experience__item.experience__item--03 {
    max-width: 210px;
  }
  body.lp.opencampus.hsc .section__07 .experience__item.experience__item--04 {
    max-width: 40%;
  }
  body.lp.opencampus.hsc .section__08 .ch03 {
    bottom: -3%;
  }
  body.lp.opencampus.htc .hero {
    margin-bottom: 3rem;
  }
  body.lp.opencampus.htc .section__05 {
    padding-top: 60px;
  }
  body.lp.opencampus.htc .section__08 .ch03 {
    bottom: -3%;
  }
  body.lp.opencampus.iryo .hero {
    margin-bottom: 3rem;
  }
  body.lp.opencampus.iryo .section__05 {
    padding-top: 60px;
  }
  body.lp.opencampus.iryo .section__08 .ch03 {
    bottom: -3%;
  }
  body.lp.opencampus.parent-group .section__03 .head__wrap, body.lp.opencampus.hsc .section__03 .head__wrap, body.lp.opencampus.htc .section__03 .head__wrap, body.lp.opencampus.iryo .section__03 .head__wrap, body.lp.opencampus .section__03 .head__wrap {
    margin-bottom: 5rem;
  }
  body.lp.opencampus.parent-group .section__03 .head__wrap h2:before, body.lp.opencampus.hsc .section__03 .head__wrap h2:before, body.lp.opencampus.htc .section__03 .head__wrap h2:before, body.lp.opencampus.iryo .section__03 .head__wrap h2:before, body.lp.opencampus .section__03 .head__wrap h2:before {
    left: 1%;
    top: 160%;
  }
  body.lp.opencampus.parent-group .section__03 .head__wrap h2:after, body.lp.opencampus.hsc .section__03 .head__wrap h2:after, body.lp.opencampus.htc .section__03 .head__wrap h2:after, body.lp.opencampus.iryo .section__03 .head__wrap h2:after, body.lp.opencampus .section__03 .head__wrap h2:after {
    right: 5%;
    top: 160%;
  }
  body.lp.opencampus .section__03 .inner__container {
    padding-bottom: 2rem;
  }
  body.lp.opencampus .section__03 .star--01 {
    right: -16%;
    top: 18%;
  }
  body.lp.opencampus .section__03 .star--02 {
    right: 2%;
    bottom: 2%;
  }
  body.lp.opencampus .section__03 .star--03 {
    left: 2%;
    bottom: 2%;
  }
  body.lp.opencampus .section__03 .star--04 {
    left: 0%;
    bottom: 27%;
  }
  body.lp.opencampus .section__03 .star--05 {
    left: -2%;
    top: 24%;
  }
  body.lp.opencampus.parent-group .section__05 .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .section__05 .head__wrap, body.lp.opencampus .section__05 .head__wrap {
    margin-bottom: 3rem;
  }
  body.lp.opencampus .section__05 .tab-btns {
    flex-wrap: wrap;
  }
  body.lp.opencampus .section__05 .tab-btns button {
    width: 50%;
    margin-bottom: 1.5rem;
  }
  body.lp.opencampus .section__05 .tab-btns button img {
    width: 100%;
  }
  body.lp.opencampus .section__05 .tab-btns button.is_active:after {
    border-width: 12px 8px 0;
  }
  body.lp.opencampus .section__06 .container {
    padding-bottom: 5rem;
  }
  body.lp.opencampus .section__06 .number.number--04 .ch01 {
    left: auto;
    right: 0;
  }
  body.lp.opencampus .section__07 .content {
    overflow-x: scroll;
  }
  body.lp.opencampus .section__07 .content img {
    max-width: none;
  }
  body.lp.opencampus .section__08 .item01 {
    width: 14%;
  }
  body.lp.opencampus .section__08 h2 {
    font-size: 1.25rem;
    padding: 1.5rem 0.5rem;
  }
  body.lp.opencampus .section__08 .ch01 .ch, body.lp.opencampus .section__08 .ch02 .ch, body.lp.opencampus .section__08 .ch03 .ch {
    width: 18%;
  }
  body.lp.opencampus .section__08 .ch01 {
    width: 53%;
    left: -5%;
    top: 10%;
  }
  body.lp.opencampus .section__08 .ch02 {
    width: 41%;
    top: 17%;
    right: -2%;
  }
  body.lp.opencampus .section__08 .ch03 {
    width: 40%;
    right: -2%;
    bottom: 6%;
  }
  body.lp.opencampus .section__10 .head__wrap, body.lp.opencampus.parent-group .section__10 .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 .section__10 .head__wrap {
    margin-bottom: 1rem;
  }
  body.lp.opencampus .section__10 .text {
    font-size: 0.875rem;
  }
  body.lp.opencampus .section__12 .item03 {
    display: none;
  }
  body.lp.opencampus .section__12 .content {
    font-size: 0.875rem;
  }
  body.lp.opencampus .section__12 .title {
    font-size: 1.125rem;
    margin-top: 0.5rem;
  }
  body.lp.opencampus .section__12 .line-link {
    font-size: 1rem;
  }
  body.lp.opencampus .section__12 .line-link a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body.lp.opencampus .section__12 .line-link a img {
    width: 60px;
  }
  body.lp .section__07 .section__08 div.content {
    padding-top: 30px;
  }
}

@media (max-width: 660px) {
  body.child-page.gyouji.htc .sdgs__content-wrap {
    border-width: 0.9vw;
  }
}

@media (max-width: 591.98px) {
  body.lp.opencampus.hsc .section__08 .ch01 {
    width: 53%;
    top: 15%;
  }
  body.lp.opencampus.hsc .section__08 .ch02 {
    width: 42%;
  }
  body.lp.opencampus.hsc .section__08 .ch03 {
    width: 40%;
  }
  body.lp.opencampus.htc .section__08 .ch01 {
    width: 53%;
    top: 15%;
  }
  body.lp.opencampus.htc .section__08 .ch02 {
    width: 42%;
  }
  body.lp.opencampus.htc .section__08 .ch03 {
    width: 40%;
  }
  body.lp.opencampus.iryo .section__08 .ch01 {
    width: 53%;
    top: 15%;
  }
  body.lp.opencampus.iryo .section__08 .ch02 {
    width: 42%;
  }
  body.lp.opencampus.iryo .section__08 .ch03 {
    width: 40%;
  }
  body.lp.opencampus .section__08 .item01 {
    left: 0;
    top: 80%;
    bottom: auto;
    width: 17%;
    max-width: 109px;
  }
  body.lp.opencampus .section__08 .ch01 {
    width: 60%;
    top: 14%;
  }
  body.lp.opencampus .section__08 .ch02 {
    width: 46%;
    top: 24%;
  }
  body.lp.opencampus .section__08 .ch03 {
    width: 43%;
  }
}

@media (max-width: 571.98px) {
  body.interview .mv__slider .slider-item .top {
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
  }
  body.interview .mv__slider .slider-item .top img.after {
    top: -30px;
  }
  body.interview .mv__slider .slider-item.interview--01 .top img.after {
    top: -30px;
  }
  body.interview .mv__slider .slider-item.interview--02 .top {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  body.interview .mv__slider .slider-item.interview--02 .top img.after {
    top: -70px;
  }
  body.interview .mv__slider .slider-item.interview--03 .top {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  body.interview .mv__slider .slider-item.interview--03 .top img.after {
    top: -50px;
  }
  body.interview .mv__slider .slider-item.interview--04 .top {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  body.interview .mv__slider .slider-item.interview--04 .top img.after {
    top: -60px;
  }
  body.interview .mv__slider .slider-item.interview--05 .top {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
  }
  body.interview .mv__slider .slider-item.interview--06 .top {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
  body.interview .mv__slider .slider-item.interview--06 .top img.after {
    top: -45px;
  }
}

@media (any-hover: none) and (max-width: 571.98px) {
  body.interview .mv__slider .slider-item.interview--01.current .top::before {
    top: 25% !important;
  }
  body.interview .mv__slider .slider-item.interview--02.current .top::before {
    top: 15% !important;
  }
  body.interview .mv__slider .slider-item.interview--03.current .top::before {
    top: 20% !important;
  }
  body.interview .mv__slider .slider-item.interview--04.current .top::before {
    top: 26% !important;
  }
  body.interview .mv__slider .slider-item.interview--05.current .top::before {
    top: 25% !important;
  }
  body.interview .mv__slider .slider-item.interview--06.current .top::before {
    top: 28% !important;
  }
}

@media (any-hover: hover) and (max-width: 571.98px) {
  body.interview .mv__slider .slider-item.interview--01:hover .top::before {
    top: 25% !important;
  }
  body.interview .mv__slider .slider-item.interview--02:hover .top::before {
    top: 15% !important;
  }
  body.interview .mv__slider .slider-item.interview--03:hover .top::before {
    top: 20% !important;
  }
  body.interview .mv__slider .slider-item.interview--04:hover .top::before {
    top: 26% !important;
  }
  body.interview .mv__slider .slider-item.interview--05:hover .top::before {
    top: 25% !important;
  }
  body.interview .mv__slider .slider-item.interview--06:hover .top::before {
    top: 28% !important;
  }
}

@media (max-width: 539.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
  body.lp.opencampus.hsc .section__03 .outer__container .merit_ch.merit_ch--06 {
    right: 10%;
  }
  body.lp.opencampus.htc .section__03 .outer__container .merit_ch.merit_ch--06 {
    right: 10%;
  }
  body.lp.opencampus.iryo .section__03 .outer__container .merit_ch.merit_ch--06 {
    right: 10%;
  }
}

@media (max-width: 360px) {
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table {
    min-width: 0px;
  }
}

@media (max-width: 340px) {
  .cv__btns--sp a {
    font-size: 1vw;
  }
}

@media (min-width: 375px) {
  body.now-future .nf-members-range-row {
    width: 86vw;
  }
  body.now-future .nf-member-personal {
    flex: 0 0 70%;
  }
  body.now-future .nf-now-members-range {
    margin-top: 100px;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) .nf-now_text {
    margin-left: 115px;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) .vertical-item_text {
    right: 5.33333vw;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) .nf-now_text {
    margin-right: 115px;
  }
  body.now-future .bg_now02 .nf-now-members-range {
    margin-top: 135px;
  }
  body.now-future .bg_now03 .nf-now-vertical_text {
    top: 0px;
  }
  body.now-future .bg_now03 .nf-now-members-range {
    margin-top: 130px;
  }
  body.now-future .bg_now04 .nf-now-members-range {
    margin-top: 120px;
  }
  body.now-future .bg_future01 {
    top: 0px;
  }
  body.now-future .bg_future01 .nf-now-members-range {
    margin-top: 150px;
  }
  body.now-future .bg_future02 {
    top: 0px;
  }
  body.now-future .bg_future02 .nf-now-members-range {
    margin-top: 190px;
  }
  body.now-future .bg_future03 {
    top: 0px;
  }
  body.now-future .bg_future03 .nf-now-members-range {
    margin-top: 180px;
  }
  body.now-future .bg_future04 {
    top: 0px;
  }
  body.now-future .bg_future04 .nf-now-members-range {
    margin-top: 190px;
  }
  body.now-future .bg_future05 .nf-now-vertical_text {
    top: 0px;
  }
  body.now-future .bg_future05 .nf-now-members-range {
    margin-top: 200px;
  }
  body.kita-t-b-w-lower .d-sp-ss {
    display: block;
  }
  body.kita-t-b-w-lower .kl-qualification_heading3 {
    width: 45px;
  }
  body.kita-t-b-w-lower .kl-qualification-list {
    width: auto;
    margin-top: 24px;
    margin-left: 15px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-list {
    margin-left: 15px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-btn {
    top: 220px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_img {
    width: 84vw;
  }
  body.kita-t-b-w-lower .start-arrow {
    left: -25px;
  }
  body.kita-t-b-w-lower .end-arrow {
    right: -25px;
  }
  body.kita-t-b-w-lower .start-arrow.slick-disabled,
  body.kita-t-b-w-lower .end-arrow.slick-disabled {
    width: 35px;
    height: 190px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide-item {
    width: 100%;
    max-width: 331px;
  }
  body.kita-t-b-w-lower .kl-schedule-teacher-item {
    max-width: 340px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide_heading4 {
    font-size: 0.9375rem;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-start-slide {
    width: 89%;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  body.kita-t-b-w-lower .kls-oneday-slide-item {
    max-width: 274px;
  }
  body.kita-t-b-w-lower .kl-curriculum_heading2 {
    padding-left: 25px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .table {
    min-width: 310px;
    margin-right: 20px;
  }
  body.kita-t-b-w-lower .kl-calc_img img {
    width: 123px;
  }
  body.kita-t-b-w-lower .kl-calc_img img:nth-of-type(2) {
    width: 27px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .table {
    min-width: 310px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc_img img {
    max-width: 129px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_heddigng2_img {
    top: 36px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table .table {
    min-width: 310px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table02 {
    margin-left: 170px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_heddigng2_img {
    max-width: 341px;
  }
  body.lp .section__07 .section__08 h2.s8_heading img.item01 {
    top: 90px;
  }
  body.lp .section__08:not(.parent-group) div.content {
    padding-top: 55px;
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading img.item01 {
    top: 90px;
  }
}

@media (min-width: 540px) {
  .container, .container-sm {
    max-width: 540px;
  }
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .d-md {
    display: none;
  }
  body.hsc.course .kl02__title {
    margin-bottom: 30px;
  }
  body.lp .section__07 .section__08 h2.s8_heading {
    padding-left: 40px !important;
  }
  body.lp .section__08:not(.parent-group) div.content {
    padding-top: 100px;
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading {
    padding-left: 40px !important;
  }
  body.interview .mv__slider .slider-item {
    max-width: 100%;
  }
  body.interview .mv__slider .slider-item .top {
    padding-bottom: max(80px, 7vw);
  }
  body.interview .mv__slider .slider-item .bottom {
    width: 197px;
    bottom: 0;
  }
  body.interview .mv__slider .slide-arrow.prev-arrow, body.interview .mv__slider .slide-arrow.next-arrow {
    left: 0;
    right: 0;
    margin-inline: auto;
  }
  body.interview .mv__slider .slide-arrow.prev-arrow {
    translate: -160px 0;
  }
  body.interview .mv__slider .slide-arrow.next-arrow {
    translate: 160px 0;
  }
}

@media (min-width: 572px) {
  body.interview .mv__slider .slider-item .top::before {
    right: 5%;
  }
  body.interview .mv__slider .slider-item.interview--01 .top::before {
    top: 19%;
  }
  body.interview .mv__slider .slider-item.interview--04 .top::before {
    top: 16%;
    right: 12%;
  }
  body.interview .mv__slider .slider-item.interview--05 .top::before {
    top: 19%;
  }
  body.interview .mv__slider .slider-item.interview--06 .top::before {
    right: 10%;
  }
}

@media (min-width: 576px) {
  body.now-future .nf-now-vertical_text {
    top: 13.40483vw;
  }
  body.now-future .nf-now-members-range {
    margin-top: 150px;
  }
  body.now-future .nf-now-members-items {
    flex: 0 0 86%;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) .vertical-item_text {
    left: 0;
    width: 90px;
  }
  body.now-future .bg_now03 .nf-now-vertical_text {
    top: 100px;
  }
  body.now-future .bg_now04 .nf-now-members-range {
    margin-top: 140px;
  }
  body.now-future .bg_future01 .nf-now-members-range {
    margin-top: 180px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_heading3 {
    margin-left: -50px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-inner {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kl-study__content {
    width: min(1000px,78%);
  }
  body.kita-t-b-w-lower .prev-arrow,
  body.kita-t-b-w-lower .next-arrow,
  body.kita-t-b-w-lower .start-arrow,
  body.kita-t-b-w-lower .end-arrow {
    width: 28px;
    height: 50px;
  }
  body.kita-t-b-w-lower .prev-arrow {
    left: 25px;
  }
  body.kita-t-b-w-lower .next-arrow {
    right: 25px;
  }
  body.kita-t-b-w-lower .start-arrow.slick-disabled,
  body.kita-t-b-w-lower .end-arrow.slick-disabled {
    width: 49px;
    height: 236px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide-item {
    width: 100%;
    max-width: 381px;
  }
  body.kita-t-b-w-lower .kl-schedule-teacher-item {
    max-width: 395px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide_img {
    width: 48%;
  }
  body.kita-t-b-w-lower .kls-oneday-slide-item {
    max-width: 340px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .table {
    margin-right: 35px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__track {
    max-width: 455px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__point {
    max-width: 455px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table02 {
    margin-left: 20px;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item_text {
    width: 5%;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 {
    margin-right: 35px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_heddigng2_img {
    top: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_text_img {
    max-width: 285px;
    top: 450px;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-hero_heddigng2_img {
    top: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_heddigng2_img {
    max-width: 450px;
    top: 150px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  footer .company__info .inner {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  .search--pc .search-btn {
    font-size: 0.6875rem;
  }
  .menu__inner .header__nav-item.tel {
    padding: 0rem 0.5rem;
  }
  .menu__inner .header__nav-item.tel .f-century {
    font-size: 1.25rem;
  }
  .menu__inner .header__nav-item.map .inner {
    font-size: 0.875rem;
  }
  .header__menu__main .header__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .header__menu__main .header__nav .header__nav-item:not(.tel):not(.map) {
    display: none;
  }
  body.child-page.day .day__slide__inner {
    max-width: 560px;
  }
  body.child-page.day.iryo .day__life__chs .life__chs__block:not(:last-of-type) {
    margin-right: 1rem;
  }
  body.opencampus .p-form-second form dl dd {
    width: 75%;
  }
  body.parent-group .p-form form dl dt {
    width: 25%;
  }
  body.parent-group .p-form form dl dd {
    width: 75%;
  }
  body.parent-group.page .p-form-second form dl dd {
    width: 75%;
  }
  body.hsc .p-form form dl dt {
    width: 25%;
  }
  body.hsc .p-form form dl dd {
    width: 75%;
  }
  body.hsc .p-form-second form dl dd {
    width: 75%;
  }
  body.hsc.page .p-form-second form dl dd {
    width: 75%;
  }
  body.htc .p-form form dl dt {
    width: 25%;
  }
  body.htc .p-form form dl dd {
    width: 75%;
  }
  body.htc .p-form-second form dl dd {
    width: 75%;
  }
  body.htc.page .p-form-second form dl dd {
    width: 75%;
  }
  body.iryo .p-form form dl dt {
    width: 25%;
  }
  body.iryo .p-form form dl dd {
    width: 75%;
  }
  body.iryo .p-form-second form dl dd {
    width: 75%;
  }
  body.iryo.page .p-form-second form dl dd {
    width: 75%;
  }
  .p-oc .ch-elm01 {
    bottom: -40px;
  }
  .p-oc .ch-elm02 {
    bottom: -40px;
  }
  body.lp.opencampus .section__08 .ch01 .ch, body.lp.opencampus .section__08 .ch02 .ch, body.lp.opencampus .section__08 .ch03 .ch {
    width: 23%;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .sm-only {
    display: none;
  }
  footer .footer-link-top + .inner {
    flex-wrap: nowrap;
  }
  footer .sns {
    width: 33%;
  }
  footer .sdg {
    width: 30%;
  }
  footer .inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  footer .company__info {
    width: 37%;
  }
  body.child-page footer .ch-yt {
    margin-left: auto;
    margin-top: -2rem;
  }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .header__inner nav {
    flex: 1 1 auto;
  }
  .menu__inner {
    display: flex;
    width: calc(100% - 295px);
    justify-content: flex-end;
  }
  .header__menu {
    display: flex;
    justify-content: center;
  }
  body.parent-group .header__menu .header__nav-item .en {
    font-size: 1.875rem;
  }
  body.parent-group .header__menu .header__nav-item .jp {
    font-size: 1.125rem;
  }
  body.parent-group .header__menu .header__nav-item .en {
    font-size: 1.875rem;
  }
  body.parent-group .header__menu .header__nav-item .jp {
    font-size: 1.125rem;
  }
  body.home .banner-slide__main__img {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  body.home .banner-slide__main__img.slick-active.slick-current + .slick-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  body.child-page.day .day__slide .slick-next, body.child-page.day .day__slide .slick-prev {
    border-width: 30px 0 30px 35px;
  }
  body.child-page.day .day__slide__item {
    margin-right: 1.5rem;
  }
  body.child-page.day .day__slide__wrap {
    padding-left: 80px;
    padding-right: 60px;
  }
  body.child-page.day .day__slide__wrap .slick-list {
    padding: 0 20% 0 0 !important;
  }
  body.child-page.day .day__life__section .insta__item {
    display: block;
  }
  body.child-page.day .day__life__section .day__life__link {
    display: flex;
    justify-content: center;
  }
  body.child-page.day.htc .day-acc .accBtn {
    font-size: 1.5625rem;
  }
  body.child-page.day.htc .day-acc .accBtn:after {
    width: 20px;
    height: 20px;
    right: 39px;
    border-width: 4px;
  }
  body.child-page.day.htc .day-acc .accBtn:before {
    bottom: 5px;
    right: 47px;
    width: 4px;
    height: 25px;
  }
  body.child-page.day.iryo .day__slide__title {
    font-size: 1.5625rem;
  }
  body.child-page.day.iryo .day-acc .accBtn {
    font-size: 1.5625rem;
  }
  body.child-page.day.iryo .day-acc .accBtn:after {
    width: 20px;
    height: 20px;
    right: 39px;
    border-width: 4px;
  }
  body.child-page.day.iryo .day-acc .accBtn:before {
    bottom: 5px;
    right: 47px;
    width: 4px;
    height: 25px;
  }
  body.child-page.day.iryo .day__life__chs {
    display: flex;
    justify-content: center;
  }
  body.child-page.day.iryo .life__head {
    text-shadow: 5px 5px 0 #000,1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000,  1px -1px 0 #000, 1px 0 0 #000, -1px  0 0 #000, 0 1px 0 #000,  0 -1px 0 #000;
    font-size: 4rem;
  }
  body.child-page.gyouji .intro {
    font-size: 1.25rem;
  }
  body.child-page.gyouji .gyouji__head {
    margin: 4rem 0 0;
  }
  body.child-page.gyouji .gyouji__head .gyouji__head__num {
    font-size: 2.1875rem;
  }
  body.child-page.gyouji .gyouji__head .gyouji__head__num .gyouji__head__month {
    font-size: 1.5rem;
  }
  body.child-page.gyouji .gyouji__head .gyouji__head__lists {
    font-size: 1.25rem;
  }
  body.child-page.gyouji .gyouji__head .gyouji__head__year {
    font-size: 1rem;
  }
  body.child-page.gyouji .gyouji__content {
    padding: 0 0 3.75rem;
  }
  body.child-page.gyouji .gyouji__content .gyouji__content__description .description__head {
    font-size: 1.375rem;
  }
  body.child-page.gyouji .gyouji__content .gyouji__content__description .description__content {
    max-width: 410px;
  }
  body.child-page.gyouji .colabo.colabo--01 {
    padding: 3.75rem 0 1.875rem;
  }
  body.child-page.gyouji .colabo.colabo--02 {
    padding: 3.75rem 0 1.875rem;
  }
  body.child-page.gyouji .colabo.colabo--03 {
    padding: 3.75rem 0 1.875rem;
  }
  body.child-page.gyouji .colabo__charm__description {
    padding: 2.1875rem;
    font-size: 1rem;
  }
  body.child-page.gyouji .colabo__htc-iryo {
    text-align: center;
    width: 100% !important;
  }
  body.child-page.hutankeigen .hutankeigen__block--01 .hutankeigen__block__text {
    font-size: 1.25rem;
    font-weight: bold;
  }
  body.child-page.hutankeigen .page-contents .point figcaption {
    font-size: 1.25rem;
    font-weight: 900;
  }
  body.child-page.hutankeigen .page-contents .tel__wrap .number {
    font-size: 4.4375rem;
  }
  body.child-page.hutankeigen .page-contents .time {
    font-size: 1.75rem;
  }
  body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn {
    font-size: 1.5625rem;
  }
  body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn:after {
    width: 20px;
    height: 20px;
    right: 39px;
    border-width: 4px;
  }
  body.child-page.hutankeigen .page-contents .hutankeigen-acc .accBtn:before {
    bottom: 5px;
    right: 47px;
    width: 4px;
    height: 25px;
  }
  body.child-page.hs-teachers .section {
    padding-bottom: 7.5rem;
  }
  body.child-page.hs-teachers .page-header {
    margin-bottom: 4rem;
  }
  body.child-page.hs-teachers .page-header .head__wrap h1, body.child-page.hs-teachers .page-header body.lp.opencampus.parent-group .section__04 .head__wrap h1, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .page-header .head__wrap h1 {
    bottom: 5%;
  }
  body.child-page.hs-teachers .teachers__intro .teachers__intro__text {
    font-size: 2rem;
  }
  body.child-page.hs-teachers .teachers__intro .text__inner {
    line-height: 2.21875;
    letter-spacing: 0.094em;
  }
  body.child-page.hs-teachers .teachers__intro .text__aside {
    font-size: 1.25rem;
  }
  body.child-page.hs-teachers .section__qualification .head__wrap, body.child-page.hs-teachers .section__qualification body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .section__qualification .head__wrap {
    font-size: 2.5rem;
  }
  body.child-page.hs-teachers .section__qualification .sectino__intro {
    font-size: 1.4375rem;
  }
  body.child-page.hs-teachers .section__qualification .qualification__lists .qualification__name {
    font-size: 1.25rem;
  }
  body.child-page.hs-teachers .section__work .head__wrap, body.child-page.hs-teachers .section__work body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .section__work .head__wrap {
    font-size: 2.5rem;
  }
  body.child-page.hs-teachers .section__work .sectino__intro {
    font-size: 1.4375rem;
    margin-bottom: 5rem;
  }
  body.child-page.hs-teachers .section__work .work__head {
    font-size: 3.0625rem;
  }
  body.child-page.hs-teachers .section__work .work__num {
    font-size: 12.6875rem;
  }
  body.child-page.hs-teachers .section__work .work__num__per {
    font-size: 7.8125rem;
  }
  body.child-page.hs-teachers .section__work .work__aside {
    font-size: 1.875rem;
  }
  body.child-page.hs-teachers .section__experience .experience__inner {
    padding: 6.25rem 3.125rem;
    margin-bottom: 6.25rem;
  }
  body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__head {
    font-size: 1.8125rem;
  }
  body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--02 .experience__description {
    font-size: 1.25rem;
  }
  body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 {
    padding: 3.75rem 3.125rem;
  }
  body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 .head {
    font-size: 1.625rem;
  }
  body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 .inquiry__block {
    font-size: 1.3125rem;
  }
  body.child-page.hs-teachers .section__experience .experience__inner.experience__inner--04 .tel__block a {
    padding: .5rem;
    font-size: 2.3125rem;
  }
  body.child-page.hs-teachers .section__experience .head__wrap, body.child-page.hs-teachers .section__experience body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.child-page.hs-teachers .section__experience .head__wrap {
    font-size: 2.6875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.375rem;
  }
  body.child-page.hs-teachers .section__experience .intro__wrap {
    font-size: 1.4375rem;
    line-height: 2.13043;
    margin-bottom: 8.125rem;
  }
  body.child-page.hs-teachers .section__experience .head {
    font-size: 2.6875rem;
    line-height: 1.90698;
  }
  body.child-page.hs-teachers .section__experience .head .head__item {
    margin: auto;
    position: absolute;
    right: 105%;
    bottom: 0;
  }
  body.child-page.hs-teachers .section__experience .experience__check {
    font-size: 1.4375rem;
  }
  body.child-page.hs-teachers .section__experience .schedule {
    display: flex;
    flex-wrap: wrap;
  }
  body.child-page.hs-teachers .section__experience .schedule .schedule__block {
    width: 50%;
  }
  body.child-page.hs-teachers .section__experience .schedule .schedule__block:first-of-type {
    padding-right: 5%;
    border-right: 1px solid #707070;
  }
  body.child-page.hs-teachers .section__experience .schedule .schedule__block:last-of-type {
    padding-left: 5%;
  }
  body.child-page.hs-teachers .section__experience .schedule .schedule__head {
    font-size: 2.5625rem;
  }
  body.child-page.hs-teachers .section__experience .schedule .schedule__cicle .schedule__cicle__inner {
    font-size: 1.625rem;
  }
  body.child-page.shakaijin .section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  body.child-page.shakaijin .for-all {
    display: flex;
    flex-wrap: wrap;
  }
  body.child-page.shakaijin .for-all .for-all__list {
    width: calc((100% - 120px) / 7);
  }
  body.child-page.shakaijin .intro-strength {
    font-size: 1.75rem;
  }
  body.child-page.shakaijin .page-header .page-head {
    margin-bottom: 6.875rem;
  }
  body.child-page.shakaijin .section__merit .merit__intro {
    margin-bottom: 6.25rem;
  }
  body.child-page.shakaijin .section__merit .merit__head.merit__head--02 {
    margin-top: 8.75rem;
  }
  body.child-page.shakaijin .section__merit .merit__num {
    font-size: 14.75rem;
  }
  body.child-page.shakaijin .section__merit .merit__num__per {
    font-size: 4.9375rem;
  }
  body.child-page.shakaijin .section__merit .merit__aside {
    font-size: 1.125rem;
  }
  body.child-page.shakaijin .section__interview .section__head {
    margin-bottom: 5.625rem;
  }
  body.child-page.shakaijin .section__interview .interview__list {
    margin-bottom: 4.375rem;
  }
  body.child-page.shakaijin .section__interview .interview__head {
    font-size: 1.875rem;
  }
  body.child-page.shakaijin .section__interview .interview__content {
    font-size: 1.25rem;
  }
  body.child-page.shakaijin .section__signal .sub__head {
    font-size: 2rem;
  }
  body.child-page.shakaijin .section__signal .section__head {
    font-size: 2.875rem;
  }
  body.child-page.shakaijin .section__signal .inquiry__description {
    font-size: 1rem;
  }
  body.child-page.facility .p-article__img {
    max-width: 220px;
  }
  body.child-page.facility .p-article__head {
    margin-top: 15px;
  }
  body.child-page.facility .p-article__item--m {
    margin-top: 40px;
  }
  body.child-page.facility .p-article__thumb {
    margin-top: 0;
  }
  body.child-page.facility .p-article__body {
    margin-top: 0;
  }
  body.hsc .simple-link-nav, body.htc .simple-link-nav, body.iryo .simple-link-nav {
    padding: 42px 0 38px;
    margin-bottom: 70px;
  }
  body.hsc .simple-link-nav li a, body.htc .simple-link-nav li a, body.iryo .simple-link-nav li a {
    padding-right: 20px;
  }
  body.hsc .simple-link-nav li a::after, body.htc .simple-link-nav li a::after, body.iryo .simple-link-nav li a::after {
    right: 0;
  }
  body.hsc .banner-slide__main__img, body.htc .banner-slide__main__img, body.iryo .banner-slide__main__img {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  body.hsc .banner-slide__main__img.slick-active.slick-current + .slick-active, body.htc .banner-slide__main__img.slick-active.slick-current + .slick-active, body.iryo .banner-slide__main__img.slick-active.slick-current + .slick-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  body.hsc .section--06 .inner {
    display: flex;
  }
  body.hsc .section--06 .inner h3 {
    padding: .5rem .5rem .5rem 5rem;
    -webkit-transform: translateX(-110px);
    transform: translateX(-110px);
    width: calc(100% + 80px);
  }
  body.hsc .section--06 .inner h3.is_scrolled {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }
  body.hsc .section--06 .second__head:before, body.hsc .section--06 .second__head:after {
    content: "";
    height: 1px;
    flex-grow: 1;
    background-color: #fff;
  }
  body.hsc .section--06 .second__head:before {
    margin-right: 1rem;
  }
  body.hsc .section--06 .second__head:after {
    margin-left: 1rem;
  }
  body.common.search .head__wrap, body.common.search body.lp.opencampus.parent-group .section__04 .head__wrap, body.lp.opencampus.parent-group .section__04 body.common.search .head__wrap, body.common.search body.lp.opencampus .section__03 .head__wrap, body.lp.opencampus .section__03 body.common.search .head__wrap, body.common.search body.lp.opencampus .section__05 .head__wrap, body.lp.opencampus .section__05 body.common.search .head__wrap {
    margin-bottom: 4.375rem;
  }
  body.common.entrance .small__head {
    font-size: 1.5625rem;
  }
  body.common.entrance .section__employment:before {
    height: 72%;
    background: url(../img/common/img_page_entrance_bg01.jpg) no-repeat;
    background-size: cover;
  }
  body.common.entrance .section__employment .achievement .achievement__content {
    font-size: 8.6875rem;
  }
  body.common.entrance .section__employment .achievement .achievement__content__per {
    font-size: 5.6875rem;
  }
  body.common.entrance .section__inquiry .entrance__inquiry .inquiry__description {
    font-size: 1rem;
  }
  body.common.dormitory .dormitory__intro {
    margin-bottom: 8.75rem;
    line-height: 1.88889;
    padding-left: 9%;
    font-size: 1.375rem;
  }
  body.common.dormitory .page__links {
    margin: 3.75rem 0 1.25rem;
  }
  body.common.dormitory .section__dormitory .head {
    display: inline-block;
    position: relative;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  body.common.dormitory .section__dormitory .head__item {
    display: inline;
  }
  body.common.dormitory .section__mv {
    margin-bottom: 6.875rem;
  }
  .link-page-btm {
    padding: 30px;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .mt-md-4h {
    margin-top: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 4rem !important;
  }
  .mt-md-7 {
    margin-top: 4.5rem !important;
  }
  .mt-md-8 {
    margin-top: 5rem !important;
  }
  .mt-md-9 {
    margin-top: 5.5rem !important;
  }
  .mt-md-10 {
    margin-top: 6rem !important;
  }
  .mb-md-4h {
    margin-bottom: 2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4rem !important;
  }
  .mb-md-7 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 5.5rem !important;
  }
  .mb-md-10 {
    margin-bottom: 6rem !important;
  }
  .mb-md-12 {
    margin-bottom: 8rem !important;
  }
  body.opencampus .p-form-second .privacy {
    display: flex;
  }
  body.opencampus .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.opencampus .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.opencampus .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.opencampus .p-form-second form dl input#school-name, body.opencampus .p-form-second form dl input#name {
    width: 300px;
  }
  body.opencampus .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.opencampus .p-form-second form dl label input:focus,
  body.opencampus .p-form-second form dl label textarea:focus,
  body.opencampus .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.opencampus .p-oc-card .content ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
  }
  body.opencampus .p-oc-card .content li {
    width: 40%;
  }
  body.opencampus .p-oc-card .content li:nth-of-type(odd):not(:last-of-type) {
    margin-right: 100px;
  }
  body.opencampus .p-oc-card .content .oc-cat.this_closed .oc__article:after {
    font-size: 1.4375rem;
  }
  body.opencampus .p-oc-card__modal .oc-detail {
    display: flex;
    flex-wrap: wrap;
  }
  body.opencampus .p-oc-card__modal .oc-detail .oc-detail__head {
    text-align: left;
    flex-basis: 180px;
  }
  body.opencampus .p-oc-card__modal .oc-detail .oc-detail__body {
    flex-basis: calc(100% - 180px);
  }
  body.page .p-article {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  body.page .p-article.p-article--01 .p-article__item {
    width: calc((100% - 10%) / 3);
  }
  body.page .p-article.p-article--01 .p-article__item:not(:nth-of-type(3n)):not(:last-of-type) {
    margin-right: 5%;
  }
  body.page .p-article.p-article--02 .p-article__item {
    width: calc((70% - 5%) / 2);
  }
  body.page .p-article.p-article--02 .p-article__item:not(:nth-of-type(even)):not(:last-of-type) {
    margin-right: 5%;
  }
  body.parent-group table th {
    min-width: 8em;
  }
  body.parent-group .p-form form dl dt:not(:last-of-type) {
    border-bottom: 1px solid #6c757d;
  }
  body.parent-group .p-form form dl dd {
    border-left: 1px solid #6c757d;
  }
  body.parent-group .p-form form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.parent-group .p-form form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.parent-group .p-form form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.parent-group .p-form form dl label input:focus,
  body.parent-group .p-form form dl label textarea:focus,
  body.parent-group .p-form form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.parent-group .p-link-block .p-link-block__item {
    width: calc((100% - 6%) / 2);
  }
  body.parent-group .p-link-block .p-link-block__item:not(:nth-of-type(even)):not(:last-of-type) {
    margin-right: 6%;
  }
  body.parent-group.page h2:not(.not) {
    background: linear-gradient(to right, #F4CB35 0, #F4CB35 15%, #E2E2E2 15%) no-repeat;
    background-size: 100% 6px;
    background-position: bottom;
    margin: 5rem 0 1.875rem;
    font-size: 1.5625rem;
  }
  body.parent-group.page .p-article {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  body.parent-group.page .p-article.p-article--01 .p-article__item {
    width: calc((100% - 10%) / 3);
  }
  body.parent-group.page .p-article.p-article--01 .p-article__item:not(:nth-of-type(3n)):not(:last-of-type) {
    margin-right: 5%;
  }
  body.parent-group.page .p-article.p-article--02 .p-article__item {
    width: calc((70% - 5%) / 2);
  }
  body.parent-group.page .p-article.p-article--02 .p-article__item:not(:nth-of-type(even)):not(:last-of-type) {
    margin-right: 5%;
  }
  body.parent-group.page .p-form-second .privacy {
    display: flex;
  }
  body.parent-group.page .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.parent-group.page .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.parent-group.page .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.parent-group.page .p-form-second form dl input#school-name, body.parent-group.page .p-form-second form dl input#name {
    width: 300px;
  }
  body.parent-group.page .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.parent-group.page .p-form-second form dl label input:focus,
  body.parent-group.page .p-form-second form dl label textarea:focus,
  body.parent-group.page .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc table th {
    min-width: 8em;
  }
  body.hsc .p-form form dl dt:not(:last-of-type) {
    border-bottom: 1px solid #6c757d;
  }
  body.hsc .p-form form dl dd {
    border-left: 1px solid #6c757d;
  }
  body.hsc .p-form form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc .p-form form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc .p-form form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc .p-form form dl label input:focus,
  body.hsc .p-form form dl label textarea:focus,
  body.hsc .p-form form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc .p-form-second .privacy {
    display: flex;
  }
  body.hsc .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc .p-form-second form dl input#school-name, body.hsc .p-form-second form dl input#name {
    width: 300px;
  }
  body.hsc .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.hsc .p-form-second form dl label input:focus,
  body.hsc .p-form-second form dl label textarea:focus,
  body.hsc .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc.page h2:not(.not) {
    background: linear-gradient(to right, #E85298 0, #E85298 15%, #E2E2E2 15%) no-repeat;
    background-size: 100% 6px;
    background-position: bottom;
    margin: 5rem 0 1.875rem;
    font-size: 1.5625rem;
  }
  body.hsc.page .p-link-block .p-link-block__item {
    width: calc((100% - 6%) / 2);
  }
  body.hsc.page .p-link-block .p-link-block__item:not(:nth-of-type(even)):not(:last-of-type) {
    margin-right: 6%;
  }
  body.hsc.page .p-form-second .privacy {
    display: flex;
  }
  body.hsc.page .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc.page .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc.page .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.hsc.page .p-form-second form dl input#school-name, body.hsc.page .p-form-second form dl input#name {
    width: 300px;
  }
  body.hsc.page .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.hsc.page .p-form-second form dl label input:focus,
  body.hsc.page .p-form-second form dl label textarea:focus,
  body.hsc.page .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc table th {
    min-width: 8em;
  }
  body.htc .p-form form dl dt:not(:last-of-type) {
    border-bottom: 1px solid #6c757d;
  }
  body.htc .p-form form dl dd {
    border-left: 1px solid #6c757d;
  }
  body.htc .p-form form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc .p-form form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc .p-form form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc .p-form form dl label input:focus,
  body.htc .p-form form dl label textarea:focus,
  body.htc .p-form form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc .p-form-second .privacy {
    display: flex;
  }
  body.htc .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc .p-form-second form dl input#school-name, body.htc .p-form-second form dl input#name {
    width: 300px;
  }
  body.htc .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.htc .p-form-second form dl label input:focus,
  body.htc .p-form-second form dl label textarea:focus,
  body.htc .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc.page h2:not(.not) {
    background: linear-gradient(to right, #FF7603 0, #FF7603 15%, #E2E2E2 15%) no-repeat;
    background-size: 100% 6px;
    background-position: bottom;
    margin: 5rem 0 1.875rem;
    font-size: 1.5625rem;
  }
  body.htc.page .p-link-block .p-link-block__item {
    width: calc((100% - 6%) / 2);
  }
  body.htc.page .p-link-block .p-link-block__item:not(:nth-of-type(even)):not(:last-of-type) {
    margin-right: 6%;
  }
  body.htc.page .p-form-second .privacy {
    display: flex;
  }
  body.htc.page .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc.page .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc.page .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.htc.page .p-form-second form dl input#school-name, body.htc.page .p-form-second form dl input#name {
    width: 300px;
  }
  body.htc.page .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.htc.page .p-form-second form dl label input:focus,
  body.htc.page .p-form-second form dl label textarea:focus,
  body.htc.page .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo table th {
    min-width: 8em;
  }
  body.iryo .p-form form dl dt:not(:last-of-type) {
    border-bottom: 1px solid #6c757d;
  }
  body.iryo .p-form form dl dd {
    border-left: 1px solid #6c757d;
  }
  body.iryo .p-form form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo .p-form form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo .p-form form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo .p-form form dl label input:focus,
  body.iryo .p-form form dl label textarea:focus,
  body.iryo .p-form form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo .p-form-second .privacy {
    display: flex;
  }
  body.iryo .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo .p-form-second form dl input#school-name, body.iryo .p-form-second form dl input#name {
    width: 300px;
  }
  body.iryo .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.iryo .p-form-second form dl label input:focus,
  body.iryo .p-form-second form dl label textarea:focus,
  body.iryo .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo.page h2:not(.not) {
    background: linear-gradient(to right, #43AA88 0, #43AA88 15%, #E2E2E2 15%) no-repeat;
    background-size: 100% 6px;
    background-position: bottom;
    margin: 5rem 0 1.875rem;
    font-size: 1.5625rem;
  }
  body.iryo.page .p-link-block .p-link-block__item {
    width: calc((100% - 6%) / 2);
  }
  body.iryo.page .p-link-block .p-link-block__item:not(:nth-of-type(even)):not(:last-of-type) {
    margin-right: 6%;
  }
  body.iryo.page .p-form-second .privacy {
    display: flex;
  }
  body.iryo.page .p-form-second form dl dd.radio label input:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo.page .p-form-second form dl dd.checkbox label input + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo.page .p-form-second form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  body.iryo.page .p-form-second form dl input#school-name, body.iryo.page .p-form-second form dl input#name {
    width: 300px;
  }
  body.iryo.page .p-form-second form dl input#school-year {
    width: 100px;
  }
  body.iryo.page .p-form-second form dl label input:focus,
  body.iryo.page .p-form-second form dl label textarea:focus,
  body.iryo.page .p-form-second form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .p-attention {
    margin-bottom: 3rem;
    font-size: 1.75rem;
  }
  .p-instagram .insta-list li.instagram__album a:after {
    width: 25px;
    height: 25px;
  }
  .p-news__lower .news__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .p-news__lower .news__wrap .news__block {
    width: calc((100% - 2.5rem) / 2);
  }
  .p-news__lower .news__wrap .news__block:first-of-type {
    margin-right: 2.5rem;
  }
  .p-aside01 .aside01__content .twitter__link {
    display: flex;
    justify-content: space-between;
  }
  .p-aside01 time {
    font-size: 1.1875rem;
  }
  .p-aside02 .aside02__wrap {
    display: flex;
  }
  .p-aside02 .aside02__wrap .aside02__block {
    width: calc((100% - 2.5rem) / 2);
  }
  .p-aside02 .aside02__wrap .aside02__block:first-of-type {
    margin-right: 2.5rem;
  }
  .p-campus .p-gradient {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .p-campus .content__block {
    margin-top: 12rem;
  }
  .p-campus .first {
    min-width: 765px;
  }
  .p-campus .second {
    min-width: 630px;
  }
  .p-oc .item-03 {
    font-size: 1.125rem;
  }
  .cv__btns a img {
    margin-bottom: 0.5rem;
  }
  .cv__btns .document {
    margin-bottom: 0.5rem;
  }
  .cv__btns .document a {
    padding: 2rem .8rem;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .cv__btns .entry a {
    padding: 2rem .8rem;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .cv__btns--sp {
    display: none;
  }
  .d-pc {
    display: block;
  }
  body.now-future .nf-hero {
    padding-top: 45.86667vw;
    background-image: url("../img/lp/now-future/bg-item-pc_01.jpg");
  }
  body.now-future .nf-hero-star img:nth-of-type(1) {
    top: 9.24667vw;
    left: 4.74vw;
  }
  body.now-future .nf-hero-star img:nth-of-type(2) {
    max-width: 24.13px;
    max-height: 27.08px;
    top: 17.05333vw;
    left: 22.51333vw;
    bottom: auto;
  }
  body.now-future .nf-hero-star img:nth-of-type(3) {
    bottom: 3.20667vw;
    left: 3.17333vw;
  }
  body.now-future .nf-hero-star img:nth-of-type(4) {
    top: 9.83333vw;
    left: 39.62667vw;
  }
  body.now-future .nf-hero-star img:nth-of-type(5) {
    top: 19.83333vw;
    right: 41.71333vw;
  }
  body.now-future .nf-hero-star img:nth-of-type(6) {
    top: 10.98vw;
    bottom: auto;
    right: 24.5vw;
  }
  body.now-future .nf-hero-star img:nth-of-type(7) {
    top: 14.76vw;
    bottom: auto;
    right: 5.64667vw;
  }
  body.now-future .mf-hero-heading2 {
    width: 57.25667vw;
    max-width: 858.85px;
    bottom: 65px;
  }
  body.now-future .nf-weare_heading3 > img {
    width: 37.10333vw;
    max-width: 445.24px;
  }
  body.now-future .nf-weare_heading3 {
    width: 46.49vw;
    max-width: 557.88px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70.6px;
    padding-bottom: 21.5px;
  }
  body.now-future .nf-weare_text {
    margin-top: 28.8px;
    font-size: 1.125rem;
    line-height: 2.22222;
    padding-bottom: 56.4px;
  }
  body.now-future .nf-members_heading2 {
    width: 123px;
    height: 74px;
    margin-top: 70px;
  }
  body.now-future .nf-members_heading2-future {
    width: 123px;
    height: 74px;
    margin-top: 75px;
  }
  body.now-future .nf-members-range:nth-of-type(2) {
    margin-top: 0px;
  }
  body.now-future .nf-members-range::after {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  body.now-future .nf-members-range:nth-of-type(1)::before,
  body.now-future .nf-members-range:nth-of-type(2)::before,
  body.now-future .nf-members-range:nth-of-type(3)::before {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  body.now-future .nf-members-range:nth-of-type(2)::before {
    display: block;
  }
  body.now-future .nf-member-future .nf-members-range:nth-of-type(2)::before {
    display: block;
  }
  body.now-future .nf-members_img {
    flex: 0 0 16%;
    text-align: left;
  }
  body.now-future .nf-member-personal {
    flex: 0 0 39%;
    padding-left: 20px;
  }
  body.now-future .nf-now-bg:nth-of-type(1) {
    margin-top: 107px;
  }
  body.now-future .nf-now-heading2 {
    width: 100vw;
    display: block;
    font-size: 3rem;
    margin-left: auto;
    padding-right: 40px;
    -webkit-transform: translateY(90px);
    transform: translateY(90px);
  }
  body.now-future .nf-now-vertical_text {
    top: 19.53333vw;
    font-size: 1.25rem;
    letter-spacing: 0.027em;
    margin-left: auto;
  }
  body.now-future .nf-now-vertical_text span.now-vertical_text02 {
    margin-top: 25px;
  }
  body.now-future .nf-now-bg:nth-of-type(even) .nf-now-heading2 {
    margin-right: auto;
    margin-left: 0;
    padding-left: 20px;
    text-align: left;
  }
  body.now-future .nf-now-bg:nth-of-type(even) .nf-now-vertical_text {
    margin-left: 0;
  }
  body.now-future #future01, body.now-future #future03, body.now-future #future05 {
    text-align: right;
    position: relative;
    padding-right: 60px;
  }
  body.now-future #future02, body.now-future #future04 {
    text-align: left;
    position: relative;
    padding-left: 20px;
  }
  body.now-future .nf-now-bg:nth-of-type(odd) .nf-now-heading2 {
    margin-left: auto;
    padding-right: 60px;
    text-align: right;
  }
  body.now-future .nf-future-bg02 .nf-now-heading2, body.now-future .nf-future-bg04 .nf-now-heading2 {
    margin-right: 0;
    margin-left: 0;
    padding-left: 40px;
    text-align: left;
  }
  body.now-future .nf-future-bg02 .nf-now-vertical .nf-now-vertical_text, body.now-future .nf-future-bg04 .nf-now-vertical .nf-now-vertical_text {
    margin-left: 0;
  }
  body.now-future .nf-future-bg01 .nf-now-heading2, body.now-future .nf-future-bg03 .nf-now-heading2, body.now-future .nf-future-bg05 .nf-now-heading2 {
    margin-left: auto;
    margin-right: 0;
    padding-right: 60px;
    text-align: right !important;
  }
  body.now-future .nf-future-bg01 .nf-now-vertical .nf-now-vertical_text, body.now-future .nf-future-bg03 .nf-now-vertical .nf-now-vertical_text, body.now-future .nf-future-bg05 .nf-now-vertical .nf-now-vertical_text {
    margin-left: auto;
  }
  body.now-future .nf-now-members-range {
    margin-top: 100px;
    max-width: 100%;
    margin-left: 0;
  }
  body.now-future .nf-now-members-name_text {
    padding-top: 0px;
  }
  body.now-future .nf-now-members-name_text span.font-22-ropa {
    font-size: 1.5625rem;
  }
  body.now-future .nf-now-members_img img {
    max-width: 143px;
  }
  body.now-future .nf-now-members-items {
    margin-top: 17.86667vw;
    flex: 0 0 52%;
    margin-left: 15px;
  }
  body.now-future .vertical-item_text {
    top: 0px;
    font-size: 1rem;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) {
    flex: 0 0 47%;
    max-width: 47%;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) .vertical-item_text {
    top: 20px;
    left: 5px;
    right: auto;
    width: auto;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) .nf-now_text {
    left: auto;
    right: 0;
    width: auto;
    margin-left: 75px;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) {
    flex: 0 0 47%;
    max-width: 47%;
    margin-left: 15px;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) .vertical-item_text {
    top: 20px;
    left: auto;
    right: 5px;
    width: auto;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) .nf-now_text {
    left: 0;
    right: auto;
    width: auto;
    margin-right: 75px;
  }
  body.now-future .bg_now01 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_now01 {
    background-image: url("../img/lp/now-future/bg-item-pc_04.png");
    background-position: top 0px left -15px;
  }
  body.now-future .bg_now01 .nf-now-members_text {
    margin-top: 28px;
  }
  body.now-future .bg_now01 .nf-now-members-range {
    margin-left: 0px;
  }
  body.now-future .bg_now02 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_now02 {
    background-image: url("../img/lp/now-future/bg-item-pc_05.png");
    background-position: top 0px right -15px;
  }
  body.now-future .bg_now02 .nf-now-members-name_text {
    margin-top: 11px;
  }
  body.now-future .bg_now02 .nf-now-members_text {
    margin-top: 22px;
  }
  body.now-future .bg_now02 .nf-now-members-range {
    margin-left: -40px;
  }
  body.now-future .bg_now03 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_now03 {
    background-image: url("../img/lp/now-future/bg-item-pc_06.png");
    background-position: top 0px left -15px;
  }
  body.now-future .bg_now03 .nf-now-members_text {
    margin-top: 32px;
  }
  body.now-future .bg_now03 .nf-now-vertical_text {
    top: 140px;
  }
  body.now-future .bg_now03 .nf-now-members-range {
    margin-top: 80px;
    margin-left: 0px;
  }
  body.now-future .bg_now04 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_now04 {
    background-image: url("../img/lp/now-future/bg-item-pc_07.png");
    background-position: top 0px right -35px;
  }
  body.now-future .bg_now04 .nf-now-members_text {
    margin-top: 32px;
  }
  body.now-future .bg_now04 .nf-now-vertical_text span.now-vertical_text02 {
    margin-top: 25px;
  }
  body.now-future .bg_now04 .nf-now-members-range {
    margin-top: 50px;
    margin-left: -40px;
  }
  body.now-future .bg_now05 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_now05 {
    background-image: url("../img/lp/now-future/bg-item-pc_08.png");
    background-position: top 0px left -15px;
    padding-bottom: 98px;
  }
  body.now-future .bg_now05 .nf-now-members_text {
    margin-top: 10px;
  }
  body.now-future .bg_now05 .nf-now-members-range {
    margin-top: 80px;
    margin-left: 0px;
  }
  body.now-future .bg_future01 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_future01 {
    background-image: url("../img/lp/now-future/bg-item-pc_09.png");
    background-position: top 0px left -15px;
  }
  body.now-future .bg_future01 .nf-now-members_text {
    margin-top: 20px;
  }
  body.now-future .bg_future01 .nf-now-vertical .nf-now-vertical_text {
    margin-left: auto;
  }
  body.now-future .bg_future01 .nf-now-members-range {
    margin-top: 110px;
    margin-left: 0px;
  }
  body.now-future .bg_future02 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_future02 {
    background-image: url("../img/lp/now-future/bg-item-pc_10.png");
    background-position: top 0px right -20px;
  }
  body.now-future .bg_future02 .nf-now-members_text {
    margin-top: 20px;
  }
  body.now-future .bg_future02 .nf-now-vertical .nf-now-vertical_text {
    margin-left: 0;
  }
  body.now-future .bg_future02 .nf-now-members-range {
    margin-top: 170px;
    margin-left: -40px;
  }
  body.now-future .bg_future03 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_future03 {
    background-image: url("../img/lp/now-future/bg-item-pc_11.png");
    background-position: top 0px left -15px;
  }
  body.now-future .bg_future03 .nf-now-members-name_text {
    margin-top: 11px;
    margin-left: 0;
  }
  body.now-future .bg_future03 .nf-now-members_text {
    margin-top: 8px;
  }
  body.now-future .bg_future03 .nf-now-vertical .nf-now-vertical_text {
    margin-left: auto;
  }
  body.now-future .bg_future03 .nf-now-members-range {
    margin-top: 100px;
    margin-left: 0px;
  }
  body.now-future .bg_future04 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_future04 {
    background-image: url("../img/lp/now-future/bg-item-pc_02.png");
    background-position: top 0px right -20px;
  }
  body.now-future .bg_future04 .nf-now-members-name_text {
    margin-top: 11px;
  }
  body.now-future .bg_future04 .nf-now-members_text {
    margin-top: 12px;
  }
  body.now-future .bg_future04 .nf-now-vertical .nf-now-vertical_text {
    margin-left: 0;
  }
  body.now-future .bg_future04 .nf-now-members-range {
    margin-top: 140px;
    margin-left: -40px;
  }
  body.now-future .bg_future05 {
    width: 100vw;
    max-width: 100vw;
    background-size: 56.93333vw;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .bg_future05 {
    background-image: url("../img/lp/now-future/bg-item-pc_03.png");
    padding-bottom: 150px;
  }
  body.now-future .bg_future05 .nf-now-members-name_text {
    margin-top: 11px;
  }
  body.now-future .bg_future05 .nf-now-members_text {
    margin-top: 20px;
  }
  body.now-future .bg_future05 .nf-now-vertical_text {
    top: 200px;
  }
  body.now-future .bg_future05 .nf-now-vertical .nf-now-vertical_text {
    margin-left: auto;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  body.now-future .bg_future05 .nf-now-members-range {
    margin-top: 200px;
    margin-left: 0px;
  }
  body.kita-t-b-w-lower .section {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  body.kita-t-b-w-lower .kl-hero {
    padding-top: 57.6vw;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero {
    background-image: url("../img/lp/kitaweru/kodomo-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero {
    background-image: url("../img/lp/kitaweru/kaigo-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero {
    background-image: url("../img/lp/kitaweru/yakugyo-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-hero {
    background-image: url("../img/lp/hsc/course/hsc-bg-item01-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kl-hero_heddigng2_img {
    top: 63px;
    left: 50px;
    right: auto;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-hero-inner__block {
    position: absolute;
    top: 50%;
    left: 10px;
    margin: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-hero_heddigng2_img {
    top: auto;
    position: static;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-hero_text_img {
    position: static;
    top: auto;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero_heddigng2_img {
    width: 47.91667vw;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_heddigng2_img {
    width: 47.91667vw;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero_heddigng2_img {
    width: 47.91667vw;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-hero_heddigng2_img {
    width: 19vw;
    max-width: 285px;
  }
  body.kita-t-b-w-lower .kl-hero_text_img {
    top: 39.52802vw;
    left: 50px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero_text_img {
    width: 22.13542vw;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_text_img {
    width: 22.13542vw;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero_text_img {
    width: 22.13542vw;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-hero_text_img {
    width: 19.86667vw;
    max-width: 298px;
  }
  body.kita-t-b-w-lower .kl-point-inner {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kl-point_text {
    font-size: 1rem;
    -webkit-transform: scale(0.97);
    transform: scale(0.97);
  }
  body.kita-t-b-w-lower .kl-point_col {
    padding-left: 0;
    padding-right: 0;
  }
  body.kita-t-b-w-lower .kl-point_col .kl-point_text {
    margin-top: 63px;
  }
  body.kita-t-b-w-lower .kl-point_col:nth-of-type(1) .kl-point_text {
    margin-top: 63px;
  }
  body.kita-t-b-w-lower .kl-img-inner .parts img:nth-of-type(1) {
    width: min(184px,12vw);
    top: 54px;
    left: 0%;
  }
  body.kita-t-b-w-lower .kl-img-inner .parts img:nth-of-type(2) {
    width: min(214px,14vw);
    top: 54px;
    right: 0%;
  }
  body.kita-t-b-w-lower .kl-img-inner .image-item {
    width: min(920px,71vw);
    margin-bottom: -10px;
  }
  body.kita-t-b-w-lower .kl-point_btn {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  body.kita-t-b-w-lower .kl-qualification-inner {
    position: relative;
    padding-top: 15px;
    padding-bottom: 35px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-inner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  body.kita-t-b-w-lower .kl-qualification_heading2 {
    width: 355px;
    margin-left: 90px;
    margin-right: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_heading3 {
    margin-left: 0;
  }
  body.kita-t-b-w-lower .kl-qualification-list {
    display: flex;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification-list {
    margin-left: 0;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-list {
    flex-direction: column;
    margin-top: 0;
  }
  body.kita-t-b-w-lower .kl-qualification_ul {
    margin-top: 30px;
  }
  body.kita-t-b-w-lower .kl-qualification_ul li {
    font-size: 1.125rem;
    line-height: 1.22222;
  }
  body.kita-t-b-w-lower .kl-qualification_item_text {
    position: static;
    margin: 15px 0 0 20px;
    width: min(270px,20%);
  }
  body.kita-t-b-w-lower .kl-qualification_item_text img {
    width: 100%;
  }
  body.kita-t-b-w-lower .kl-qualification_item_img {
    position: absolute;
    display: inline-block;
  }
  body.kita-t-b-w-lower .kl-qualification_item_img:nth-of-type(1) {
    width: 6.11979vw;
    bottom: 30px;
    right: 134px;
  }
  body.kita-t-b-w-lower .kl-qualification_item_img:nth-of-type(2) {
    width: 7.03125vw;
    bottom: 25px;
    right: 74px;
  }
  body.kita-t-b-w-lower .kl-qualification_item_img:nth-of-type(3) {
    width: 3.90625vw;
    bottom: 50px;
    right: 44px;
  }
  body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img .kl-qualification_item_text {
    position: static;
    margin: 15px 0 0 20px;
    width: min(270px,20%);
  }
  body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img .kl-qualification_item_text img {
    width: 100%;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification__late {
    margin-top: 0;
    width: auto;
    max-width: 288px;
  }
  body.kita-t-b-w-lower .kl-qualification_ul01 {
    margin-bottom: 0;
    margin-left: 20px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification_ul01 {
    margin-top: 35px;
  }
  body.kita-t-b-w-lower .kl-qualification_ul02 {
    margin-top: 27px;
    margin-left: 45px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-qualification_ul02 {
    margin-top: 35px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-flex {
    margin-top: 53px;
    align-items: center;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-list {
    margin-top: 0px;
    margin-top: 10px;
    margin-left: 20px;
    width: 48%;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_ul {
    margin-top: 10px;
    padding-left: 0;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_img {
    width: 40%;
    margin-top: 0px;
    margin-left: 0;
    text-align: left;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_img img {
    width: 80%;
    max-width: 388px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_item_img:nth-of-type(1) {
    width: 25px;
    height: 50px;
    bottom: 30px;
    right: 40px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_item_img:nth-of-type(2) {
    width: 43px;
    height: 44px;
    bottom: 15px;
    right: 0px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-inner {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-flex {
    align-items: center;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_heading3 {
    width: 35px;
    margin-top: 35px;
    margin-top: 0;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul_01 {
    margin-bottom: 0px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul02 {
    margin-top: 0;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul02 li:nth-of-type(1) {
    padding-top: 0px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul {
    margin-left: 10px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul li {
    font-size: 1rem;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:nth-of-type(1) {
    width: 40px;
    height: 40px;
    bottom: 0px;
    right: 50px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:nth-of-type(2) {
    width: 34px;
    height: 55px;
    bottom: -15px;
    right: 5px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:first-of-type {
    bottom: -25px;
  }
  body.kita-t-b-w-lower .ki-study-project {
    width: 71.68142vw;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kl-study__upper {
    width: min(704px,56vw);
  }
  body.kita-t-b-w-lower .kl-study__wrap {
    gap: 24px;
  }
  body.kita-t-b-w-lower .kl-study__img {
    width: calc(55% - 24px);
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study__img {
    width: calc(43% - 24px);
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-study__img {
    width: calc(41% - 24px);
  }
  body.kita-t-b-w-lower .kl-study__text {
    width: 48%;
    font-size: min(18px,1.6vw);
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study__text {
    width: 57%;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-study__text {
    width: 59%;
  }
  body.kita-t-b-w-lower .kl-study__lower--title {
    font-size: 34px;
  }
  body.kita-t-b-w-lower .kl-study__content {
    width: min(1000px,92%);
  }
  body.kita-t-b-w-lower .kl-schedule {
    margin-top: 120px;
  }
  body.kita-t-b-w-lower .kl-schedule-border {
    height: 15px;
  }
  body.kita-t-b-w-lower .kl-schedule-bg {
    padding-top: 30.66667%;
    background-image: url("../img/lp/kitaweru/kodomo-bg-item-pc_02.jpg");
  }
  body.kita-t-b-w-lower .kl-schedule-bg-inner {
    max-width: 1220px;
  }
  body.kita-t-b-w-lower .kl-schedule-bg-inner img {
    width: 46.31268vw;
  }
  body.kita-t-b-w-lower .kl-schedule-bg-inner::before {
    width: 158px;
    height: 158px;
    left: 7.37463vw;
    top: -50px;
    left: 0;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule-bg-inner::before {
    top: -50px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-bg-inner::before {
    top: -50px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-bg-inner::before {
    top: -50px;
  }
  body.kita-t-b-w-lower .kl-schedule__three-power {
    padding: 30px 0 51px 0;
  }
  body.kita-t-b-w-lower .kl-schedule__three-power--list {
    gap: 16px;
    -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule__three-power--list {
    gap: 16px;
    -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
  body.kita-t-b-w-lower .kl-schedule__three-power--lower {
    width: min(1000px,88%);
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-bg {
    background-image: url("../img/lp/kitaweru/yakugyo-bg-item-pc_02.jpg");
  }
  body.kita-t-b-w-lower .kl-schedule-60-inner {
    max-width: 1220px;
  }
  body.kita-t-b-w-lower .kl-schedule-60_heading2_img {
    width: 43.22917vw;
    margin-top: 81px;
  }
  body.kita-t-b-w-lower .kl-schedule-60_heading2_img::before {
    width: 34px;
    height: 49px;
    bottom: -20.68px;
    left: -55px;
  }
  body.kita-t-b-w-lower .kl-schedule-60_heading2_img::after {
    width: 47px;
    height: 54px;
    bottom: -22.3px;
    right: -60px;
  }
  body.kita-t-b-w-lower .kl-schedule-60_heading3_img {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-60_heading3_img {
    margin-bottom: 10px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-60_heading3_img {
    margin-bottom: 10px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-slide {
    background-color: #8CBD97;
    padding-top: 40px;
  }
  body.kita-t-b-w-lower .next-arrow {
    width: 34px;
    right: 50px;
  }
  body.kita-t-b-w-lower .prev-arrow,
  body.kita-t-b-w-lower .next-arrow,
  body.kita-t-b-w-lower .start-arrow,
  body.kita-t-b-w-lower .end-arrow {
    width: 28px;
    height: 50px;
  }
  body.kita-t-b-w-lower .prev-arrow {
    left: 30px;
  }
  body.kita-t-b-w-lower .next-arrow {
    right: -5px;
  }
  body.kita-t-b-w-lower .start-arrow {
    left: -35px;
  }
  body.kita-t-b-w-lower .end-arrow {
    right: -35px;
  }
  body.kita-t-b-w-lower .start-arrow.slick-disabled,
  body.kita-t-b-w-lower .end-arrow.slick-disabled {
    width: 40px;
    height: 194px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide-inner {
    padding-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kl-schedule-slide-inner {
    max-width: 1120px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide-item {
    margin-top: 85px;
    max-width: 583px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-schedule-slide-item {
    max-width: 555px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide-item {
    max-width: 555px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-slide-item {
    max-width: 555px;
  }
  body.kita-t-b-w-lower .kl-schedule-teacher {
    background-color: #F48E8C;
    padding-bottom: 70px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-teacher {
    background-color: #007FC7;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-teacher {
    background-color: #43966C;
  }
  body.kita-t-b-w-lower .kl-schedule-teacher-item {
    max-width: 605px;
    -webkit-transform: translate(-10px);
    transform: translate(-10px);
  }
  body.kita-t-b-w-lower .kl-schedule-slide-item-tag {
    width: 123px;
    height: 123px;
    top: -45px;
    left: -21px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide_img {
    width: 100%;
    max-width: 290px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide_content {
    width: 50%;
    padding-left: 17px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide_heading4 {
    font-size: 1.25rem;
    margin-top: 26px;
    line-height: 1.4;
  }
  body.kita-t-b-w-lower .kl-schedule-slide_paragraph {
    font-size: 0.875rem;
    line-height: 1.78571;
    margin-top: 20px;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-inner {
    max-width: 1190px;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-heading strong {
    font-size: 1.25rem;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-heading {
    font-size: 1rem;
    padding-top: 31px;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-start-slide {
    width: 82%;
    max-width: 596px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  body.kita-t-b-w-lower .kls-oneday-slide-item {
    max-width: 280px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-bg {
    background-image: url("../img/lp/kitaweru/kaigo-bg-item-pc_02.jpg");
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-60_heading2_img::before {
    width: 27px;
    height: 39px;
    bottom: -20.68px;
    left: -55px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-60_heading2_img::after {
    width: 37px;
    height: 50px;
    bottom: -22.3px;
    right: -60px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide {
    padding-top: 40px;
    background-color: #7FADD8;
  }
  body.kita-t-b-w-lower .kl-curriculum-inner {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table {
    max-width: 31%;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table.last-item {
    margin-top: 85px;
    max-width: 310px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::before,
  body.kita-t-b-w-lower .kl-curriculum_btn::after {
    width: 260px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::before {
    left: -270px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::after {
    right: -270px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum_btn {
    position: absolute;
    bottom: -55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
  body.kita-t-b-w-lower .kl-calc {
    padding-bottom: 81.5px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-calc_img {
    margin-top: 70px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .table {
    margin-right: 35px;
  }
  body.kita-t-b-w-lower .kl-links_ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  body.kita-t-b-w-lower .kl-links_li {
    width: 48%;
    max-width: 481px;
  }
  body.kita-t-b-w-lower .kl-links_li:nth-of-type(even) {
    margin-left: 18px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2 {
    padding-top: 75px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01 {
    margin-inline: auto;
    text-align: center;
    margin-bottom: 15px;
    width: min(600px,75%);
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01 img {
    max-width: 603px;
    width: 100%;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01::before {
    background-image: url("../img/lp/kitaweru/kaigo-item_04.png");
    right: auto;
    left: -9vw;
    bottom: -5.8vw;
    aspect-ratio: 145/235;
    width: min(145px,11vw);
  }
  body.kita-t-b-w-lower .d-pc {
    display: block;
  }
  body.kita-t-b-w-lower .d-sp-md {
    display: none;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .accordion-item .kl-curriculum_tables {
    margin-top: 0;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2-second-wrapeer {
    display: flex;
    gap: 20px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__track {
    align-self: flex-end;
    max-width: 415px;
    width: 42.5%;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__point {
    max-width: 564px;
    width: 57.5%;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-field .field04 li {
    font-size: 25px;
    min-height: 90px;
    flex-wrap: wrap;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup h2 {
    margin-bottom: 54px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-text {
    font-size: 16px;
    margin-bottom: 45px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup h3.bg-htc-spline {
    font-size: 25px;
    margin-bottom: 30px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content {
    -ms-grid-columns: 1fr 25px 1fr 25px 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 25px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .title {
    font-size: 18px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .img {
    padding: 0 10px;
    margin-bottom: 15px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .img .comment {
    right: 10px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .y2 .levelup-content {
    -ms-grid-columns: 1fr 34px 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 34px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .y2 .levelup-content .card .title, body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .y2 .levelup-content .card .img, body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .y2 .levelup-content .card .text {
    padding: 0 30px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero {
    background-image: url("../img/lp/kitatech/course1/htc-c1-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_heddigng2_img {
    max-width: 350px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_text_img {
    max-width: 225px;
    top: 34.528vw;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_heading2 {
    max-width: 488px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 63px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-flex {
    flex-direction: row;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_c1_heading3 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_heading3 {
    max-width: 47px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_ul {
    width: 41%;
    margin-left: 35px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_ul li {
    text-indent: -25px;
    font-size: 1rem;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_paragraph {
    width: 84%;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .md-only {
    display: block;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-study_heading4 {
    margin-top: 25px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus {
    margin-top: 120px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus_paragraph {
    font-size: 1rem;
    line-height: 1.875;
    margin-top: 18px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus-grid {
    max-width: 766px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus-grid_row .kl-focus-grid_col {
    padding: 15px !important;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-item-table02 {
    margin-left: 20px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner.kt-course1 .kl-hero_text_img {
    width: 23vw;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-hero {
    background-image: url("../img/lp/kitatech/course2/htc-c2-bg-item-mid_01.jpg");
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero {
    background-image: url("../img/lp/kitatech/course3/htc-c3-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_heddigng2_img {
    width: 42vw;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_text_img {
    width: 19vw;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content .card .text {
    font-size: 16px;
    margin-bottom: 15px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .wide {
    position: relative;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .wide::after {
    content: '';
    display: inline-block;
    width: 100%;
    max-width: 325px;
    aspect-ratio: 315/230;
    background: url("../img/lp/kitatech/course3/htc-c3-levelup-m12-img_pc.png") center/cover no-repeat;
    position: absolute;
    right: -20px;
    bottom: -30px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_c1_heading3 {
    font-size: 1.25rem;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_img {
    margin-top: 20px;
    margin-left: 10px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification-flex {
    align-items: flex-start;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-hero {
    background-image: url("../img/lp/kitatech/course4/htc-c4-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification-list {
    width: 90%;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_img {
    margin-top: -20px;
    width: 41%;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_img .kl-qualification_img_01 img {
    -webkit-transform: translate(30px, 30px);
    transform: translate(30px, 30px);
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_ul {
    width: 43%;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_ul li span {
    font-size: 0.75rem;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-item-table .table02 {
    margin-left: 0;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-curriculum-inner {
    max-width: 1110px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero {
    background-image: url("../img/lp/kitatech/course5/htc-c5-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_heddigng2_img {
    max-width: 450px;
    top: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_text_img {
    max-width: 271px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_heading3 {
    max-width: 220px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_ul {
    width: 80%;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-q_paragraph {
    margin-top: 20px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-links-inner img:nth-of-type(1) {
    margin-top: 40px;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_headding {
    width: 100%;
    max-width: 89px;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_text_02 {
    width: 45%;
    right: auto;
    left: 37%;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_text_01 {
    width: 45%;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study {
    margin-top: 200px;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-study .hsc-course-study:last-of-type {
    padding-bottom: 100px;
  }
  body.hsc.course .kl-hero-inner__block .kl-hero_text_img {
    margin-top: 61px;
    max-width: 348px;
  }
  body.hsc.course .section__tech-materials .materials__items {
    margin-bottom: 5.375rem;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
  }
  body.hsc.course .section__tech-materials .materials__item {
    width: calc((100% - 1rem) / 2);
    padding: 0 20px;
  }
  body.hsc.course .section__tech-materials .materials__item:first-of-type {
    border-right: 3px dashed #F5B6D2;
  }
  body.hsc.course .course__slide .slick-next {
    border-width: 35px 0 35px 35px;
  }
  body.hsc.course .course__slide .slick-prev {
    border-width: 35px 35px 35px 0;
  }
  body.hsc.course .course__slide .course__slide-item {
    margin-right: 2rem;
  }
  body.hsc.course .course__slide .slick-slider {
    padding-right: 8%;
  }
  body.hsc.course .course__slide .slick-list {
    margin-right: 3rem;
    margin-left: 3rem;
    padding: 0 20% 0 0 !important;
  }
  body.lp .hero {
    margin-bottom: 5rem;
  }
  body.lp.opencampus .section__01 h2 {
    font-size: 1.875rem;
  }
  body.lp.opencampus.parent-group .hero {
    margin-bottom: 0;
  }
  body.lp.opencampus.parent-group .section__03 .inner__container:after {
    content: '';
    display: block;
    position: absolute;
    right: -30px;
    top: -30px;
    background: url(../img/lp/oc/img_section3_triangle.svg) no-repeat;
    background-size: cover;
    width: 127px;
    height: 127px;
  }
  body.lp.opencampus.parent-group .section__03 .inner__container:before {
    left: -170px;
    bottom: -150px;
  }
  body.lp.opencampus.parent-group .section__03 .merit_ch {
    position: absolute;
    top: 110%;
    right: 0;
    left: 0;
  }
  body.lp.opencampus.child-page .section__01 h2 {
    top: -9rem;
  }
  body.lp.opencampus.child-page .section__10 .section-heading {
    font-size: 1.875rem;
  }
  body.lp.opencampus.hsc .section__03 .inner__container:after {
    background: url(../img/lp/oc/hsc/img_section3_triangle.svg) no-repeat;
    background-size: cover;
  }
  body.lp.opencampus.hsc .section__03 .inner__container:before {
    left: -160px;
    bottom: -60px;
  }
  body.lp.opencampus.hsc .section__07 .experience__item.experience__item--02 {
    width: auto;
    left: 0;
    bottom: -2rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  body.lp.opencampus.hsc .section__07 .experience__item.experience__item--03 {
    top: -11rem;
    -webkit-transform: translateX(36%);
    transform: translateX(36%);
    width: auto;
  }
  body.lp.opencampus.hsc .section__07 .experience__item.experience__item--04 {
    width: auto;
    bottom: 0;
    -webkit-transform: translateX(40%);
    transform: translateX(40%);
  }
  body.lp.opencampus.htc .section__03 .inner__container:after {
    background: url(../img/lp/oc/htc/img_section3_triangle.svg) no-repeat;
    background-size: cover;
  }
  body.lp.opencampus.htc .section__03 .inner__container:before {
    left: -160px;
    bottom: -60px;
  }
  body.lp.opencampus.htc .section__07 .experience__item.experience__item--04 {
    width: auto;
    bottom: 0;
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
  }
  body.lp.opencampus.iryo .section__03 .inner__container:after {
    background: url(../img/lp/oc/iryo/img_section3_triangle.svg) no-repeat;
    background-size: cover;
  }
  body.lp.opencampus.iryo .section__03 .inner__container:before {
    left: -160px;
    bottom: -60px;
  }
  body.lp.opencampus .section__03 .inner__container:after {
    content: '';
    display: block;
    position: absolute;
    right: -30px;
    top: -30px;
    width: 127px;
    height: 127px;
  }
  body.lp.opencampus .section__03 .inner__container:before {
    content: '';
    display: block;
    position: absolute;
    left: -170px;
    bottom: -150px;
    background: url(../img/lp/oc/img_section3_dots.svg) no-repeat;
    background-size: cover;
    width: 197px;
    height: 200px;
  }
  body.lp.opencampus .section__07 .scroll {
    display: none;
  }
  body.lp .section__07 .section__08 div.s8-wrap_heading {
    text-align: center;
  }
  body.lp .section__07 .section__08 h2.s8_heading {
    padding-left: 90px !important;
  }
  body.lp .section__07 .section__08 h2.s8_heading img.item01 {
    top: 20px;
  }
  body.lp .section__08:not(.parent-group) div.s8-wrap_heading {
    text-align: center;
  }
  body.lp .section__08:not(.parent-group) div.content {
    padding-top: 90px;
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading {
    padding-left: 90px !important;
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading img.item01 {
    top: 20px;
    left: 0;
  }
  body.interview .mv {
    padding: 53px 0 70px;
  }
  body.interview .individual__content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 20px;
  }
  body.interview .individual__item {
    -ms-grid-row-align: self-start;
    align-self: self-start;
  }
  body.interview .individual__item.hsc .student .text .d-range-none {
    display: none;
  }
  body.now-future.nf-2024 .nf-hero {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_01.jpg");
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(1) {
    top: 8.53333vw;
    left: 13.86667vw;
    width: 2.25733vw;
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(2) {
    top: 3.8vw;
    left: 20.53333vw;
    bottom: auto;
    width: 1.60867vw;
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(3) {
    bottom: 7.53333vw;
    left: 19.76vw;
    width: 1.60867vw;
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(4) {
    top: 9.83333vw;
    left: 39.62667vw;
    width: 1.78933vw;
    height: 2.22933vw;
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(5) {
    width: 1.78933vw;
    height: 2.296vw;
    top: 11.93333vw;
    right: 38.4vw;
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(6) {
    width: 1.78867vw;
    height: 2.296vw;
    top: 15.8vw;
    bottom: auto;
    right: 26.4vw;
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(7) {
    width: 1.78867vw;
    height: 2.296vw;
    top: 7.13333vw;
    right: 8vw;
  }
  body.now-future.nf-2024 .nf-now-heading2 {
    width: 100%;
    max-width: 1280px;
  }
  body.now-future.nf-2024 .nf-now-heading2 img {
    max-width: 295px;
  }
  body.now-future.nf-2024 .bg_now01 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_04.png");
  }
  body.now-future.nf-2024 .bg_now01 .nf-now-members-col:nth-of-type(2) .vertical-item_text {
    top: 50px;
  }
  body.now-future.nf-2024 .bg_now02 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_05.png");
  }
  body.now-future.nf-2024 .bg_now02 .members-item_text02.add-2024-3 {
    margin-top: 181px;
  }
  body.now-future.nf-2024 .bg_now03 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_06.png");
  }
  body.now-future.nf-2024 .bg_now03 .nf-now-vertical .nf-now-vertical_text {
    top: 180px;
  }
  body.now-future.nf-2024 .bg_now04 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_07.png");
  }
  body.now-future.nf-2024 .bg_now05 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_08.png");
  }
  body.now-future.nf-2024 .bg_future01 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_09.png");
  }
  body.now-future.nf-2024 .bg_future01 .nf-now-vertical .nf-now-vertical_text {
    top: 200px;
    margin-right: auto;
    left: 0;
    right: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  body.now-future.nf-2024 #future02.nf-now-heading2 {
    position: relative;
    z-index: 1;
  }
  body.now-future.nf-2024 .bg_future02 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_10.png");
  }
  body.now-future.nf-2024 #future03.nf-now-heading2 {
    position: relative;
    z-index: 1;
  }
  body.now-future.nf-2024 .bg_future03 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_11.png");
  }
  body.now-future.nf-2024 .bg_future04 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_02.png");
  }
  body.now-future.nf-2024 .bg_future04 .nf-now-vertical .nf-now-vertical_text {
    top: 140px;
  }
  body.now-future.nf-2024 .bg_future04 .nf-now-members-name_text span {
    line-height: 0.9;
  }
  body.now-future.nf-2024 .bg_future04 .now-member-range .col-7 {
    flex: 0 0 62.33333%;
    max-width: 62.33333%;
  }
  body.now-future.nf-2024 .bg_future04 .now-member-range .col-5 {
    flex: 0 0 37.66667%;
    max-width: 37.66667%;
  }
  body.now-future.nf-2024 #future05.nf-now-heading2 {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 {
    background-image: url("../img/lp/now-future/2024/bg-item-pc_03.png");
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical .nf-now-vertical_text {
    left: -30px;
    top: 80px;
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-members_text {
    margin-top: 5px;
  }
}

@media (min-aspect-ratio: 16 / 10) and (min-height: 780px) {
  body.child-page.htc.page-restaurant .page-header {
    min-height: calc(100vh - 80px);
    aspect-ratio: initial;
    background-position: center;
    background-size: cover;
  }
}

@media (min-width: 821px) {
  body.now-future .bg_future03 .nf-now-members_text {
    margin-top: 20px;
  }
}

@media (min-width: 860px) {
  body.now-future .nf-now-vertical_text {
    font-size: 1.5625rem;
  }
  body.now-future .nf-now-members-range {
    margin-top: 20px;
  }
  body.now-future .vertical-item_text {
    font-size: 1rem;
  }
  body.now-future .bg_now02 .nf-now-members-range {
    margin-top: 50px;
  }
  body.now-future .bg_now03 .nf-now-members-range {
    margin-top: -20px;
  }
  body.now-future .bg_now04 .nf-now-members-range {
    margin-top: 80px;
  }
  body.now-future .bg_now05 .nf-now-members_text {
    margin-top: 33px;
  }
  body.now-future .bg_now05 .nf-now-members-range {
    margin-top: 40px;
  }
  body.now-future .bg_future01 .nf-now-members-range {
    margin-top: 90px;
  }
  body.now-future .bg_future02 .nf-now-members-range {
    margin-top: 160px;
  }
  body.now-future .bg_future03 .nf-now-members-range {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .d-sp {
    display: none;
  }
  body.now-future .nf-member {
    margin-top: 50px;
  }
  body.now-future .nf-members_heading2-future {
    width: 150px;
    height: 61px;
    margin-top: 70px;
  }
  body.now-future .nf-members-range:nth-of-type(1)::before,
  body.now-future .nf-members-range:nth-of-type(2)::before,
  body.now-future .nf-members-range:nth-of-type(3)::before {
    margin-top: 0px;
  }
  body.now-future .nf-now-heading2 {
    width: 100vw;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
  }
  body.now-future .nf-now-bg:nth-of-type(even) .nf-now-heading2 {
    text-align: left;
  }
  body.now-future #future01, body.now-future #future03, body.now-future #future05 {
    padding-right: 50px;
  }
  body.now-future .nf-now-members-items {
    flex: 0 0 53%;
  }
  body.now-future .bg_now_content {
    flex: 0 0 47%;
    max-width: 47%;
  }
  body.now-future .bg_now_content {
    flex: 0 0 43%;
    max-width: 43%;
  }
  body.now-future .bg_now01 {
    margin-top: -80px;
  }
  body.now-future .bg_now02 {
    margin-top: -80px;
  }
  body.now-future .bg_now02 {
    background-position: top 0px right -35px;
  }
  body.now-future .bg_now02 .nf-now-members-range {
    margin-left: -10px;
  }
  body.now-future .bg_now03 {
    margin-top: -80px;
  }
  body.now-future .bg_now03 {
    background-position: top 0px left -35px;
  }
  body.now-future .bg_now04 {
    margin-top: -80px;
  }
  body.now-future .bg_now04 .nf-now-members-range {
    margin-top: 0px;
    margin-left: -10px;
  }
  body.now-future .bg_now05 {
    margin-top: -80px;
  }
  body.now-future .bg_now05 {
    background-position: top 0px left -35px;
  }
  body.now-future .bg_future01 {
    margin-top: -80px;
  }
  body.now-future .bg_future01 {
    background-position: top 0px left -35px;
  }
  body.now-future .bg_future02 {
    margin-top: -80px;
  }
  body.now-future .bg_future02 {
    background-position: top 0px right -35px;
  }
  body.now-future .bg_future02 .nf-now-members-range {
    margin-top: 40px;
    margin-left: -10px;
  }
  body.now-future .bg_future03 {
    margin-top: -80px;
  }
  body.now-future .bg_future03 {
    background-position: top 0px left -35px;
  }
  body.now-future .bg_future04 {
    margin-top: -80px;
  }
  body.now-future .bg_future04 {
    background-position: top 0px right -35px;
  }
  body.now-future .bg_future04 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_future04 .nf-now-members_text {
    margin-top: 16px;
  }
  body.now-future .bg_future04 .nf-now-members-range {
    margin-top: 40px;
    margin-left: -10px;
  }
  body.now-future .bg_future05 {
    margin-top: -80px;
  }
  body.now-future .bg_future05 {
    background-position: top 0px left -35px;
  }
  body.now-future .bg_future05 .nf-now-members-range {
    margin-top: 60px;
  }
  body.kita-t-b-w-lower .kl-point_text {
    font-size: 1.25rem;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-inner {
    max-width: 845px;
  }
  body.kita-t-b-w-lower .kl-qualification_heading2 {
    margin-left: 100px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification__late {
    max-width: 388px;
    margin-left: 50px;
  }
  body.kita-t-b-w-lower .kl-qualification_ul01 {
    margin-left: 30px;
  }
  body.kita-t-b-w-lower .kl-qualification_ul02 {
    margin-left: 70px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-inner {
    padding-top: 35px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_heading3 {
    width: 47px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul li {
    margin-bottom: 13px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:nth-of-type(2) {
    right: -15px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:first-of-type {
    right: 25px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt_img:nth-of-type(1) {
    display: inline-block;
    width: 40px;
    height: 38px;
    right: 100px;
    bottom: 20px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt_img:nth-of-type(2) {
    display: inline-block;
    width: 46px;
    height: 46px;
  }
  body.kita-t-b-w-lower .next-arrow {
    right: 100px;
  }
  body.kita-t-b-w-lower .prev-arrow {
    left: -15px;
  }
  body.kita-t-b-w-lower .next-arrow {
    right: -30px;
  }
  body.kita-t-b-w-lower .start-arrow {
    left: -50px;
  }
  body.kita-t-b-w-lower .end-arrow {
    right: -50px;
  }
  body.kita-t-b-w-lower .start-arrow.slick-disabled,
  body.kita-t-b-w-lower .end-arrow.slick-disabled {
    width: 35px;
    height: 173px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide-item-tag {
    width: 98px;
    height: 98px;
    top: -45px;
    left: -21px;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-start-slide {
    width: 100%;
    max-width: 790px;
  }
  body.kita-t-b-w-lower .kls-oneday-slide-item {
    max-width: 250px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table {
    width: 100%;
    max-width: 310px;
  }
  body.kita-t-b-w-lower .kl-curriculum_heading2::before {
    left: -34.8px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::before,
  body.kita-t-b-w-lower .kl-curriculum_btn::after {
    width: 350px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::before {
    left: -380px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::after {
    right: -380px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01::before {
    left: -165px;
    bottom: -5.2vw;
  }
  body.kita-t-b-w-lower .d-sp {
    display: none;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-levelup .levelup-content .card .img .comment img {
    width: 100%;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_ul li {
    font-size: 1.125rem;
    padding: 5px 0px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .md-only {
    display: none;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-hero {
    background-image: url("../img/lp/kitatech/course2/htc-c2-bg-item-pc_01.jpg");
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .wide::after {
    right: -40px;
    bottom: 10px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification-flex {
    width: 84%;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_headding {
    max-width: 111px;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_text_02 {
    width: 50%;
    left: 35%;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_text_01 {
    margin-left: 38px;
    width: 50%;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum__scroll {
    display: none;
  }
  body.now-future.nf-2024 .bg_now01 .nf-now-members-range {
    margin-top: 100px;
  }
  body.now-future.nf-2024 .bg_now01 .nf-now-members-col:nth-of-type(2) .vertical-item_text {
    top: 80px;
  }
  body.now-future.nf-2024 .bg_now01 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
    margin-top: 30px;
  }
  body.now-future.nf-2024 .bg_now02 .nf-now-members-range {
    margin-top: 70px;
  }
  body.now-future.nf-2024 .bg_now02 h3.nf-now-vertical_text .now-vertical_text02 {
    margin-top: 200px;
  }
  body.now-future.nf-2024 .bg_now02 .members-item_text02.add-2024-3 {
    margin-top: 201px;
  }
  body.now-future.nf-2024 .bg_now03 h3.nf-now-vertical_text .now-vertical_text02 {
    margin-top: 192px;
  }
  body.now-future.nf-2024 .bg_now03 .nf-now-members-range {
    margin-top: 90px;
  }
  body.now-future.nf-2024 .bg_now03 .members-item_text02.add-2024-2 {
    margin-top: 100px;
  }
  body.now-future.nf-2024 .bg_now03 .members-item_text02.add-2024-4 {
    margin-top: 70px;
  }
  body.now-future.nf-2024 .bg_now04 .nf-now-members-range {
    margin-top: 109px;
  }
  body.now-future.nf-2024 .bg_now04 .nf-now-vertical .nf-now-vertical_text {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  body.now-future.nf-2024 .bg_now04 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
    margin-top: 50px;
  }
  body.now-future.nf-2024 .bg_now04 .members-item_text02.add-2024-1 {
    margin-top: 40px;
  }
  body.now-future.nf-2024 .bg_now05 .nf-now-members-range {
    margin-top: 55px;
  }
  body.now-future.nf-2024 .bg_now05 .nf-now-vertical .nf-now-vertical_text {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    top: 263px;
  }
  body.now-future.nf-2024 .bg_now05 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
    margin-top: 30px;
  }
  body.now-future.nf-2024 .bg_now05 .members-item_text02.add-2024-2 {
    margin-top: 160px;
  }
  body.now-future.nf-2024 .bg_now05 .members-item_text02.add-2024-3 {
    margin-top: 76px;
  }
  body.now-future.nf-2024 .bg_future01 .nf-now-members-range {
    margin-top: 140px;
  }
  body.now-future.nf-2024 .bg_future01 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
    margin-top: 307px;
  }
  body.now-future.nf-2024 .bg_future01 .members-item_text02.add-2024-2 {
    margin-top: 105px;
  }
  body.now-future.nf-2024 .bg_future01 .members-item_text02.add-2024-4 {
    margin-top: 60px;
  }
  body.now-future.nf-2024 .bg_future02 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
    margin-top: 221px;
  }
  body.now-future.nf-2024 .bg_future02 .nf-now-members-range {
    margin-top: 140px;
  }
  body.now-future.nf-2024 .bg_future02 .members-item_text02.add-2024-2 {
    margin-top: 133px;
  }
  body.now-future.nf-2024 .bg_future02 .members-item_text02.add-2024-4 {
    margin-top: 60px;
  }
  body.now-future.nf-2024 .bg_future03 .nf-now-vertical .nf-now-vertical_text {
    top: 293px;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  body.now-future.nf-2024 .bg_future03 .nf-now-members-range {
    margin-top: 140px;
  }
  body.now-future.nf-2024 .bg_future03 .members-item_text02.add-2024-4 {
    margin-top: 38px;
  }
  body.now-future.nf-2024 .bg_future04 .nf-now-vertical .nf-now-vertical_text {
    top: 240px;
  }
  body.now-future.nf-2024 .bg_future04 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
    margin-top: 200px;
  }
  body.now-future.nf-2024 .bg_future04 .nf-now-members-range {
    margin-top: 75px;
  }
  body.now-future.nf-2024 .bg_future04 .nf-now-members-name_text span {
    line-height: 1.2;
  }
  body.now-future.nf-2024 .bg_future04 .now-member-range .col-7 {
    flex: 0 0 60.33333%;
    max-width: 60.33333%;
  }
  body.now-future.nf-2024 .bg_future04 .now-member-range .col-5 {
    flex: 0 0 39.66667%;
    max-width: 39.66667%;
  }
  body.now-future.nf-2024 .bg_future04 .members-item_text02.add-2024-2 {
    margin-top: 40px;
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical .nf-now-vertical_text {
    top: 210px;
    -webkit-transform: translateX(120px);
    transform: translateX(120px);
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical .nf-now-vertical_text .now-vertical_text02 {
    margin-top: 30px;
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-members-range {
    margin-top: 125px;
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-members_text {
    margin-top: 13px;
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .members-item_text02.add-2024-2 {
    margin-top: 105px;
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .members-item_text02.add-2024-4 {
    margin-top: 45px;
  }
}

@media (min-width: 1024.98px) and (max-width: 1365px) {
  .menu__inner .header__nav-item.map {
    display: none;
  }
}

@media (min-width: 1024.98px) and (max-width: 1200px) {
  .menu__inner .header__nav-item.tel .f-century {
    font-size: 1.25rem;
  }
  .menu__inner .header__nav-item.tel p {
    font-size: 0.625rem;
  }
  body.hsc .header__nav-ls .header__nav-item:not(:last-of-type), body.htc .header__nav-ls .header__nav-item:not(:last-of-type), body.iryo .header__nav-ls .header__nav-item:not(:last-of-type) {
    margin-right: 0.5rem;
  }
  body.hsc .header__nav-ls a, body.htc .header__nav-ls a, body.iryo .header__nav-ls a {
    padding: .5rem .3rem;
  }
}

@media (min-width: 1025px) and (max-width: 1199.98px) {
  .p-aside01 .aside01__block__inner {
    padding: 0 1rem 1.6875rem;
  }
  .p-aside01 .thumb {
    padding-top: 27%;
  }
  .p-aside01 .title {
    -webkit-line-clamp: 5;
  }
  .p-oc .point__elm01 {
    left: 25%;
  }
  .p-oc .point__elm02 {
    right: 25%;
  }
}

@media (min-width: 1025px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1020px;
  }
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .md-down {
    display: none;
  }
  .header__menu__main .header__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 1rem;
  }
  .header__menu__main .header__nav .header__nav-item {
    position: relative;
    padding: 0 0.5vw;
  }
  .header__menu__main .header__nav .header__nav-item > a {
    position: relative;
    display: block;
  }
  .header__menu__main .header__nav .header__nav-item > a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 2px;
    background: #212224;
    transition: .2s;
  }
  body.parent-group .header__menu {
    padding: 4rem 30px;
  }
  body.parent-group .header__menu.is_open .header__nav {
    padding-top: 2.6rem;
  }
  body.parent-group .header__menu .header__nav {
    margin-bottom: 7rem;
  }
  body.parent-group .header__menu .header__nav-item {
    margin-bottom: 3rem;
    position: relative;
  }
  body.parent-group .header__menu .header__nav-item {
    margin-bottom: 3rem;
  }
  body.parent-group .header__menu .header__nav-item {
    position: relative;
  }
  body.parent-group .header__menu .header__nav-item.parent__nav .sub__menu {
    position: absolute;
    top: 100%;
    left: -200%;
    right: -200%;
    margin: auto;
    visibility: hidden;
    z-index: 1000;
    opacity: 0;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    width: 350px;
    padding: 1rem;
    transition: .5s;
    background: #212224;
  }
  body.parent-group .header__menu .header__nav-item.parent__nav .sub__menu li {
    margin-bottom: 0.5rem;
  }
  body.parent-group .header__menu .header__nav-item.parent__nav:hover .sub__menu {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  body.hsc .menu__inner .header__nav-item .single-link a, body.htc .menu__inner .header__nav-item .single-link a, body.iryo .menu__inner .header__nav-item .single-link a {
    font-size: 1rem;
  }
  body.hsc .header__nav-ls, body.htc .header__nav-ls, body.iryo .header__nav-ls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  body.hsc .header__nav-ls .header__nav-item, body.htc .header__nav-ls .header__nav-item, body.iryo .header__nav-ls .header__nav-item {
    position: relative;
  }
  body.hsc .header__nav-ls .header__nav-item > a, body.htc .header__nav-ls .header__nav-item > a, body.iryo .header__nav-ls .header__nav-item > a {
    display: block;
    position: relative;
    font-weight: bold;
    font-size: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  body.hsc .header__nav-ls .header__nav-item > a:before, body.htc .header__nav-ls .header__nav-item > a:before, body.iryo .header__nav-ls .header__nav-item > a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    margin: auto;
    transition: .2s;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent white;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }
  body.hsc .header__nav-ls .header__nav-item > a .f-century, body.htc .header__nav-ls .header__nav-item > a .f-century, body.iryo .header__nav-ls .header__nav-item > a .f-century {
    font-size: 0.875rem;
  }
  body.hsc .header__nav-ls .header__nav-item:hover > a, body.htc .header__nav-ls .header__nav-item:hover > a, body.iryo .header__nav-ls .header__nav-item:hover > a {
    transition: .2s;
  }
  body.hsc .header__nav-ls .header__nav-item:hover > a:hover, body.htc .header__nav-ls .header__nav-item:hover > a:hover, body.iryo .header__nav-ls .header__nav-item:hover > a:hover {
    opacity: .7;
  }
  body.hsc .header__nav-ls .header__nav-item.parent__nav .sub__menu, body.htc .header__nav-ls .header__nav-item.parent__nav .sub__menu, body.iryo .header__nav-ls .header__nav-item.parent__nav .sub__menu {
    position: absolute;
    top: 100%;
    left: -50px;
    margin: auto;
    visibility: hidden;
    z-index: 1000;
    opacity: 0;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    width: 350px;
    padding: 15px 15px 15px 50px;
    transition: .5s;
    background: rgba(255, 255, 255, 0.93);
  }
  body.hsc .header__nav-ls .header__nav-item.parent__nav .sub__menu li, body.htc .header__nav-ls .header__nav-item.parent__nav .sub__menu li, body.iryo .header__nav-ls .header__nav-item.parent__nav .sub__menu li {
    margin-bottom: 0.5rem;
  }
  body.hsc .header__nav-ls .header__nav-item.parent__nav:hover .sub__menu, body.htc .header__nav-ls .header__nav-item.parent__nav:hover .sub__menu, body.iryo .header__nav-ls .header__nav-item.parent__nav:hover .sub__menu {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  body.home .hero__elm.hero__elm--01 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  body.home .visitors-list li::before {
    content: '';
    display: inline-block;
    background-color: black;
    position: absolute;
    top: 6px;
    left: 6px;
    right: -6px;
    bottom: -6px;
    z-index: -1;
    transition: bottom 0.2s ease,right 0.2s ease;
  }
  body.home .visitors-list li:hover {
    -webkit-transform: translate(6px, 6px);
    transform: translate(6px, 6px);
  }
  body.home .visitors-list li:hover::before {
    bottom: 0;
    right: 0;
    left: 0;
  }
  body.home .visitors-list li:not(:last-of-type) a {
    border-right: none;
  }
  body.home .visitors-list li a:hover::after {
    -webkit-transform: translateX(5px) rotate(-90deg);
    transform: translateX(5px) rotate(-90deg);
  }
  body.page main .container {
    max-width: 1020px;
  }
  body.school-home .hero__elm.hero__elm--01 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  body.school-home .hero__elm .pickup .pickup__slide__wrap .pickup__slide .article {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 80vw;
  }
  body.child-page.day .day__slide {
    padding: 3.4375rem 0;
  }
  body.child-page.day .day__flow {
    padding: 3.4375rem 0;
  }
  body.child-page.day.iryo .day__life__chs .life__chs__block:not(:last-of-type) {
    margin-right: 3rem;
  }
  body.child-page.gyouji .gyouji__content .gyouji__content__block:nth-of-type(odd) {
    text-align: left;
  }
  body.child-page.gyouji .gyouji__content .gyouji__content__block:nth-of-type(even) {
    text-align: right;
  }
  body.child-page.gyouji .colabo {
    padding: 0 0 0rem;
  }
  body.child-page.gyouji .colabo .colabo__content__block:nth-of-type(odd) {
    text-align: left;
  }
  body.child-page.gyouji .colabo .colabo__content__block:nth-of-type(even) {
    text-align: right;
  }
  body.child-page.gyouji .sdgs__bg {
    -webkit-clip-path: inset(109px 0 0 0);
    clip-path: inset(109px 0 0 0);
  }
  body.child-page.facility .p-article__img {
    max-width: 300px;
  }
  body.hsc .simple-link-nav ul, body.htc .simple-link-nav ul, body.iryo .simple-link-nav ul {
    display: flex;
    align-items: center;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    flex-wrap: wrap;
  }
  body.hsc .visitors-list li::before, body.htc .visitors-list li::before, body.iryo .visitors-list li::before {
    content: '';
    display: inline-block;
    background-color: black;
    position: absolute;
    top: 6px;
    left: 6px;
    right: -6px;
    bottom: -6px;
    z-index: -1;
    transition: bottom 0.2s ease,right 0.2s ease;
  }
  body.hsc .visitors-list li:hover, body.htc .visitors-list li:hover, body.iryo .visitors-list li:hover {
    -webkit-transform: translate(6px, 6px);
    transform: translate(6px, 6px);
  }
  body.hsc .visitors-list li:hover::before, body.htc .visitors-list li:hover::before, body.iryo .visitors-list li:hover::before {
    bottom: 0;
    right: 0;
    left: 0;
  }
  body.hsc .visitors-list li:not(:last-of-type) a, body.htc .visitors-list li:not(:last-of-type) a, body.iryo .visitors-list li:not(:last-of-type) a {
    border-right: none;
  }
  body.hsc .visitors-list li a:hover::after, body.htc .visitors-list li a:hover::after, body.iryo .visitors-list li a:hover::after {
    -webkit-transform: translateX(5px) rotate(-90deg);
    transform: translateX(5px) rotate(-90deg);
  }
  body.htc .section--06 .color-wrap {
    background-color: #FFF1DB;
    padding: 70px 0 60px;
  }
  body.htc .section--06 .course__lower {
    padding-top: 70px;
  }
  body.htc .section--06 .course__item__link {
    padding: .5rem;
  }
  body.htc .section--06 .course__item__link:after {
    right: 20px;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
  }
  body.htc .section--06 .course__item__link:before {
    right: 19px;
    width: 25px;
    height: 5px;
  }
  body.htc .p-campus .img__block {
    margin-top: -3rem;
  }
  body.iryo.school-home .section--06 .inner {
    display: flex;
  }
  body.iryo .p-campus .img__block {
    margin-top: -3rem;
  }
  body.common.dormitory .section__dormitory .head__wrap:before, body.common.dormitory .section__dormitory body.lp.opencampus.parent-group .section__04 .head__wrap:before, body.lp.opencampus.parent-group .section__04 body.common.dormitory .section__dormitory .head__wrap:before, body.common.dormitory .section__dormitory .head__wrap:after, body.common.dormitory .section__dormitory body.lp.opencampus.parent-group .section__04 .head__wrap:after, body.lp.opencampus.parent-group .section__04 body.common.dormitory .section__dormitory .head__wrap:after {
    content: '';
    flex-grow: 1;
    height: 15px;
    width: 100%;
    background: repeating-linear-gradient(-45deg, #52A7F0, #52A7F0 2px, #fff 0, #fff 7px);
  }
  body.common.dormitory .section__dormitory .head__wrap:before, body.common.dormitory .section__dormitory body.lp.opencampus.parent-group .section__04 .head__wrap:before, body.lp.opencampus.parent-group .section__04 body.common.dormitory .section__dormitory .head__wrap:before {
    margin-right: 1rem;
  }
  body.common.dormitory .section__dormitory .head__wrap:after, body.common.dormitory .section__dormitory body.lp.opencampus.parent-group .section__04 .head__wrap:after, body.lp.opencampus.parent-group .section__04 body.common.dormitory .section__dormitory .head__wrap:after {
    margin-left: 1rem;
  }
  body.common.dormitory .section__dormitory .head {
    flex: 1 0 auto;
  }
  .d-lg {
    display: none;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .mt-lg-4h {
    margin-top: 2rem !important;
  }
  .mt-lg-6 {
    margin-top: 4rem !important;
  }
  .mt-lg-7 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-8 {
    margin-top: 5rem !important;
  }
  .mt-lg-9 {
    margin-top: 5.5rem !important;
  }
  .mt-lg-10 {
    margin-top: 6rem !important;
  }
  .mt-lg-12 {
    margin-top: 8rem !important;
  }
  .mt-lg-14 {
    margin-top: 10rem !important;
  }
  .mb-lg-4h {
    margin-bottom: 2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 5.5rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 10rem !important;
  }
  .mt--25p {
    margin-top: -25px;
  }
  body.parent-group .p-definition__site dt:first-of-type,
  body.parent-group .p-definition__site dd:first-of-type {
    border-top: 1px solid #ccc;
  }
  body.parent-group .p-pagination li {
    margin-bottom: 0.5rem;
  }
  body.parent-group .p-pagination .pagination__move__wrap:first-of-type {
    margin-right: .5rem;
  }
  body.parent-group .p-pagination .pagination__move__wrap:last-of-type {
    margin-left: .5rem;
  }
  body.parent-group .p-pagination .move_btn__wrap.move--prev .move_btn {
    margin-right: 0.2rem;
  }
  body.parent-group .p-pagination .move_btn__wrap.move--next .move_btn {
    margin-left: 0.2rem;
  }
  body.parent-group.page h1:not(.not) {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.parent-group.page .h1 {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.parent-group.page h3:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  body.parent-group.page h4:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  body.hsc .p-definition__site dt:first-of-type,
  body.hsc .p-definition__site dd:first-of-type {
    border-top: 1px solid #ccc;
  }
  body.hsc .p-pagination li {
    margin-bottom: 0.5rem;
  }
  body.hsc .p-pagination .pagination__move__wrap:first-of-type {
    margin-right: .5rem;
  }
  body.hsc .p-pagination .pagination__move__wrap:last-of-type {
    margin-left: .5rem;
  }
  body.hsc .p-pagination .move_btn__wrap.move--prev .move_btn {
    margin-right: 0.2rem;
  }
  body.hsc .p-pagination .move_btn__wrap.move--next .move_btn {
    margin-left: 0.2rem;
  }
  body.hsc.page h1:not(.not) {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.hsc.page .h1 {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.hsc.page h3:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  body.hsc.page h4:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  body.htc .p-definition__site dt:first-of-type,
  body.htc .p-definition__site dd:first-of-type {
    border-top: 1px solid #ccc;
  }
  body.htc .p-pagination li {
    margin-bottom: 0.5rem;
  }
  body.htc .p-pagination .pagination__move__wrap:first-of-type {
    margin-right: .5rem;
  }
  body.htc .p-pagination .pagination__move__wrap:last-of-type {
    margin-left: .5rem;
  }
  body.htc .p-pagination .move_btn__wrap.move--prev .move_btn {
    margin-right: 0.2rem;
  }
  body.htc .p-pagination .move_btn__wrap.move--next .move_btn {
    margin-left: 0.2rem;
  }
  body.htc.page h1:not(.not) {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.htc.page .h1 {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.htc.page h3:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  body.htc.page h4:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  body.iryo .p-definition__site dt:first-of-type,
  body.iryo .p-definition__site dd:first-of-type {
    border-top: 1px solid #ccc;
  }
  body.iryo .p-pagination li {
    margin-bottom: 0.5rem;
  }
  body.iryo .p-pagination .pagination__move__wrap:first-of-type {
    margin-right: .5rem;
  }
  body.iryo .p-pagination .pagination__move__wrap:last-of-type {
    margin-left: .5rem;
  }
  body.iryo .p-pagination .move_btn__wrap.move--prev .move_btn {
    margin-right: 0.2rem;
  }
  body.iryo .p-pagination .move_btn__wrap.move--next .move_btn {
    margin-left: 0.2rem;
  }
  body.iryo.page h1:not(.not) {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.iryo.page .h1 {
    font-size: 1.5625rem;
    margin: 1.25rem 0 2.5rem;
  }
  body.iryo.page h3:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  body.iryo.page h4:not(.not) {
    font-size: 1.375rem;
    margin: 1.875rem 0 1.25rem;
  }
  .p-target-menu a {
    padding: .5rem .5rem .5rem 3rem;
    font-size: 1.25rem;
  }
  .p-aside01 .aside01__wrap {
    display: flex;
  }
  .p-aside01 .aside01__wrap .aside01__block {
    width: calc((100% - 2.5rem) / 2);
  }
  .p-aside01 .aside01__wrap .aside01__block:first-of-type {
    margin-right: 2.5rem;
  }
  .p-aside01 .title {
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
    overflow: hidden;
  }
  .p-oc .item-01 .pic__01 {
    position: absolute;
    right: -60px;
    top: -2%;
  }
  .p-oc .point-lists li:not(:last-of-type) {
    margin-right: 3%;
  }
  .p-employment .title {
    font-size: 1.3125rem;
  }
  .p-video-content .play-btn {
    display: none;
  }
  .p-video-content #video {
    pointer-events: none;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  body.now-future .nf-members-range:nth-of-type(3) {
    margin-top: 0px;
  }
  body.now-future .nf-members-range:nth-of-type(3)::before {
    display: block;
  }
  body.now-future .nf-member-future .nf-members-range:nth-of-type(3)::before {
    display: block;
  }
  body.now-future .nf-members_img {
    flex: 0 0 12%;
    text-align: right;
  }
  body.now-future .nf-member-personal {
    flex: 0 0 27%;
    padding-left: 20px;
  }
  body.hsc.course .kl-point .container {
    max-width: 1140px;
  }
  body.hsc.course .section__tech-materials .aside__text {
    margin-top: -3rem;
  }
  body.hsc.course .section__tech-materials .examination__header {
    font-size: 1.875rem;
  }
  body.hsc.course .section__tech-materials .examination__inner {
    display: flex;
    justify-content: center;
    margin: auto;
  }
  body.hsc.course .section__tech-materials .examination__inner .examination__item .examination__item__inner:after {
    width: 290px;
    height: 290px;
  }
  body.hsc.course .section__tech-materials .examination__inner .examination__item {
    width: calc(100% / 3);
  }
  body.hsc.course .section__tech-materials .examination__inner .examination__item .plus {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
  }
  body.hsc.course .section__tech-materials .examination__inner .examination__head {
    font-size: 1.75rem;
    min-width: 288px;
  }
  body.hsc.course .section__tech-materials .examination__inner .examination__per {
    font-size: 4.75rem;
  }
  body.hsc.course .section__tech-materials .examination__inner .examination__per span {
    font-size: 2.25rem;
  }
  body.lp.opencampus .section__01 h2 {
    top: 1rem;
    font-size: 2.1875rem;
    padding: .5rem 2rem;
  }
  body.lp.opencampus .section__01 h2:after {
    content: '';
    display: block;
    background-color: rgba(252, 242, 93, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: -1;
  }
  body.lp.opencampus .section__01 .section__01__block__inner {
    display: flex;
  }
  body.lp.opencampus .section__01 .first__slide__wrap {
    width: 50vw;
    position: absolute;
    left: 50%;
    top: 0;
  }
  body.lp.opencampus .section__01 .text__block {
    width: 50%;
  }
  body.lp.opencampus .section__01 .text__block {
    padding: 0 1rem 0 3rem;
    margin-top: 7rem;
    font-size: 1.125rem;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block li {
    width: calc(85% / 4);
    margin-top: 5%;
  }
  body.lp.opencampus.parent-group .section__00 .schedule__wrap .schedule__block li:not(:nth-of-type(4n)) {
    margin-right: 5%;
  }
  body.lp.opencampus.parent-group .section__04 .point__lists li:nth-of-type(even) {
    margin-top: 2rem;
  }
  body.lp.opencampus.parent-group .section__04 .point__lists li:nth-of-type(1) .click__content:after {
    left: 10%;
  }
  body.lp.opencampus.parent-group .section__04 .point__lists li:nth-of-type(2) .click__content:after {
    left: 36%;
  }
  body.lp.opencampus.parent-group .section__04 .point__lists li .click__content:after {
    left: 61%;
  }
  body.lp.opencampus.parent-group .section__04 .point__lists li:nth-of-type(4) .click__content:after {
    left: 86%;
  }
  body.lp.opencampus.parent-group .section__04 figure .click__content {
    position: absolute;
    left: 0;
    top: 110%;
  }
  body.lp.opencampus.child-page .section__01 {
    margin-top: 5rem;
  }
  body.lp.opencampus.child-page .section__01 h2 {
    top: -2rem;
  }
  body.lp.opencampus.child-page .section__01 .first__slide__wrap {
    margin-top: 7rem;
    max-width: 730px;
  }
  body.lp.opencampus.child-page .section__01 .text__block {
    line-height: 1.75;
    font-size: 1.25rem;
    margin-top: 7rem;
  }
  body.lp.opencampus.child-page .section__01 .text__block .banner__doc {
    margin-top: 3rem;
  }
  body.lp.opencampus.child-page .section__10 .section-heading {
    font-size: 2.1875rem;
    padding: .5rem 2rem;
    display: block;
    margin-bottom: 1.5625rem;
  }
  body.lp.opencampus.child-page .section__10 .section-heading:after {
    content: '';
    display: block;
    background-color: rgba(252, 242, 93, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: -1;
  }
  body.lp.opencampus.hsc .section__01 .first__slide__wrap {
    margin-top: 7rem;
  }
  body.lp.opencampus.hsc .section__01 .text__block {
    line-height: 1.75;
    font-size: 1.25rem;
  }
  body.lp.opencampus.hsc .section__01 .text__block .banner__doc {
    margin-top: 3rem;
  }
  body.lp.opencampus.hsc .section__06 li:nth-of-type(odd) {
    float: left;
  }
  body.lp.opencampus.hsc .section__06 li:nth-of-type(even) {
    float: right;
  }
  body.lp.opencampus.htc .section__01 .text__block {
    line-height: 1.75;
    font-size: 1.25rem;
  }
  body.lp.opencampus.htc .section__06 li:nth-of-type(odd) {
    float: left;
  }
  body.lp.opencampus.htc .section__06 li:nth-of-type(even) {
    float: right;
  }
  body.lp.opencampus.htc .section__07 .experience__item.experience__item--01 {
    width: auto;
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
    left: 0;
    top: -6rem;
  }
  body.lp.opencampus.htc .section__07 .experience__item.experience__item--02 {
    width: auto;
    left: 0;
    bottom: -5rem;
    -webkit-transform: translateX(-35%);
    transform: translateX(-35%);
  }
  body.lp.opencampus.htc .section__07 .experience__item.experience__item--03 {
    top: -1rem;
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
    width: auto;
  }
  body.lp.opencampus.iryo .section__01 .text__block {
    line-height: 1.75;
    font-size: 1.25rem;
  }
  body.lp.opencampus.iryo .section__06 li:nth-of-type(odd) {
    float: left;
  }
  body.lp.opencampus.iryo .section__06 li:nth-of-type(even) {
    float: right;
  }
  body.lp.opencampus.iryo .section__07 .experience__item.experience__item--01 {
    width: auto;
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
    top: -4rem;
    left: 0;
  }
  body.lp.opencampus.iryo .section__07 .experience__item.experience__item--02 {
    width: auto;
    left: 2rem;
    bottom: -5rem;
    -webkit-transform: translateX(-35%);
    transform: translateX(-35%);
  }
  body.lp.opencampus.iryo .section__07 .experience__item.experience__item--03 {
    top: -5rem;
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
    width: auto;
    display: inline-block;
  }
  body.lp.opencampus .section__06 li:nth-of-type(odd) {
    float: left;
  }
  body.lp.opencampus .section__06 li:nth-of-type(even) {
    float: right;
  }
  body.lp.opencampus .section__06 li:nth-of-type(1) .number:after {
    left: 100%;
    top: 55%;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
  }
  body.lp.opencampus .section__06 li:nth-of-type(2) {
    margin-top: 23%;
  }
  body.lp.opencampus .section__06 li:nth-of-type(2) .number:after {
    right: 90%;
    top: 70%;
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);
    z-index: -1;
  }
  body.lp.opencampus .section__06 li:nth-of-type(3) {
    margin-top: 11%;
  }
  body.lp.opencampus .section__06 li:nth-of-type(3) .number:after {
    left: 90%;
    top: 65%;
    z-index: -1;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
  }
  body.lp.opencampus .section__06 li:nth-of-type(4) {
    margin-top: 26%;
  }
  body.lp.opencampus .section__06 li:nth-of-type(4) .number:after {
    right: 95%;
    top: 80%;
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);
    z-index: -1;
  }
  body.lp.opencampus .section__06 li:nth-of-type(5) {
    margin-top: 14%;
  }
  body.lp.opencampus .section__06 .number {
    line-height: 2;
    width: 415px;
  }
  body.lp.opencampus .section__06 .number.number--03 {
    width: 480px;
  }
  body.lp.opencampus .section__09 .ch02 {
    position: absolute;
    right: 0;
    top: 3rem;
  }
  body.lp .section__07 .section__08 h2.s8_heading {
    background-image: url(../img/lp/oc/img-section8-bg-item_pc.svg);
    padding-left: 128px !important;
    text-align: left;
  }
  body.lp .section__07 .section__08 h2.s8_heading img.item01 {
    left: 0;
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading {
    background-image: url(../img/lp/oc/img-section8-bg-item_pc.svg);
    padding-left: 128px !important;
    text-align: left;
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading img.item01 {
    left: 0;
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading.hsc {
    background-image: url(../img/lp/oc/hsc/img-section8-bg-item-hsc_pc.svg);
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading.htc {
    background-image: url(../img/lp/oc/htc/img-section8-bg-item-htc_pc.svg);
  }
  body.lp .section__08:not(.parent-group) h2.s8_heading.iryo {
    background-image: url(../img/lp/oc/iryo/img-section8-bg-item-iryo_pc.svg);
  }
  body.lp .section__08:not(.parent-group) .s8_img {
    text-align: center !important;
  }
  body.interview .mv__slider .slide-arrow.prev-arrow, body.interview .mv__slider .slide-arrow.next-arrow {
    margin-inline: 0;
    top: 0;
    left: auto;
  }
  body.interview .mv__slider .slide-arrow.prev-arrow {
    translate: 0;
    right: 200px;
  }
  body.interview .mv__slider .slide-arrow.next-arrow {
    translate: 0;
    right: 100px;
  }
  body.interview .individual {
    padding: 120px 0 180px;
  }
  body.interview .individual__item {
    border-radius: 20px;
    padding: 55px 40px 50px;
  }
  body.interview .individual__item.hsc .category {
    max-width: 317px;
    top: 32px;
  }
  body.interview .individual__item.hsc .student .person {
    width: 43%;
    padding-right: 10px;
  }
  body.interview .individual__item.hsc .student .text {
    margin-left: -10px;
    width: 57%;
  }
  body.interview .individual__item.hsc .student .text .d-range-none {
    display: block;
  }
  body.interview .individual__item.htc .category {
    max-width: 529px;
    top: 21px;
  }
  body.interview .individual__item.iryo .category {
    max-width: 483px;
    top: 32px;
    aspect-ratio: 325/45;
  }
  body.interview .individual__item .top .student .person img {
    max-width: 188px;
  }
  body.interview .individual__item .top .student .department {
    font-size: 1rem;
  }
  body.interview .individual__item .top .student .name {
    font-size: 1.25rem;
    margin-top: 6px;
  }
  body.interview .individual__item .top .student .name .sml {
    font-size: 0.8125rem;
  }
  body.interview .individual__item .item-content .q-title {
    font-size: 1rem;
  }
  body.interview .individual__item .item-content .q-title .q-icon {
    font-size: 1rem;
    width: 34px;
    height: 34px;
  }
  body.interview .individual__item .item-content .a-title {
    font-size: 1.25rem;
  }
  body.interview .individual__item .item-content .state-img img {
    border-radius: 10px;
  }
  body.interview .individual__item .item-content .desc-text {
    font-size: 1rem;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1320px;
  }
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .header__button-container {
    margin: auto;
  }
  .header__menu__main {
    display: block;
  }
  body.home .banner-slider > .container {
    max-width: 1100px !important;
  }
  .d-xl {
    display: none;
  }
  .f-xl-16-up {
    font-size: 1rem !important;
  }
  .p-aside01 .title {
    -webkit-line-clamp: 7;
  }
  body.now-future .nf-hero-star img:nth-of-type(1) {
    top: 138.7px;
    left: 71.1px;
  }
  body.now-future .nf-hero-star img:nth-of-type(2) {
    top: 255.8px;
    left: 367.7px;
  }
  body.now-future .nf-hero-star img:nth-of-type(3) {
    bottom: 48.1px;
    left: 47.6px;
  }
  body.now-future .nf-hero-star img:nth-of-type(4) {
    top: 147.5px;
    left: 594.4px;
  }
  body.now-future .nf-hero-star img:nth-of-type(5) {
    top: 297.5px;
    right: 625.7px;
  }
  body.now-future .nf-hero-star img:nth-of-type(6) {
    top: 164.7px;
    right: 367.5px;
  }
  body.now-future .nf-hero-star img:nth-of-type(7) {
    top: 221.4px;
    right: 84.7px;
  }
  body.now-future .nf-members-range-row {
    max-width: 365px;
  }
  body.now-future .nf-members_img {
    flex: 0 0 30%;
    text-align: left;
  }
  body.now-future .nf-member-personal {
    flex: 0 0 70% !important;
  }
  body.now-future .nf-now-heading2 {
    font-size: 4.375rem;
    line-height: 1.05714;
  }
  body.now-future .nf-now-vertical_text {
    top: 373px;
    font-size: 1.875rem;
    letter-spacing: 0.032em;
    width: 115px;
    top: 333px;
    left: 115px;
    right: auto;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .nf-now-bg:nth-of-type(even) .nf-now-heading2 {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
  }
  body.now-future .nf-now-bg:nth-of-type(even) .nf-now-vertical_text {
    right: 115px;
    left: auto;
  }
  body.now-future .nf-future-bg02 .nf-now-heading2, body.now-future .nf-future-bg04 .nf-now-heading2 {
    width: 100vw;
    max-width: 1280px;
    margin-left: auto;
    text-align: left;
    padding-left: 0;
  }
  body.now-future .nf-future-bg02 .nf-now-vertical .nf-now-vertical_text, body.now-future .nf-future-bg04 .nf-now-vertical .nf-now-vertical_text {
    right: 115px;
    left: auto;
  }
  body.now-future .nf-future-bg02 .nf-now-members-range, body.now-future .nf-future-bg04 .nf-now-members-range {
    margin-left: 50px;
  }
  body.now-future .nf-future-bg01 .nf-now-heading2, body.now-future .nf-future-bg03 .nf-now-heading2, body.now-future .nf-future-bg05 .nf-now-heading2 {
    width: 100vw;
    max-width: 1280px;
    margin-right: auto;
    text-align: right;
  }
  body.now-future .nf-now-members-range {
    margin-top: 100px;
  }
  body.now-future .nf-now-members_img_text {
    font-size: 1.0625rem;
    line-height: 1.17647;
    letter-spacing: 0.1em;
  }
  body.now-future .nf-now-members_img_text span {
    font-size: 0.875rem;
  }
  body.now-future .nf-now-members_text {
    font-size: 0.75rem;
    line-height: 1.16667;
  }
  body.now-future .nf-now-members_img img {
    max-width: 143px;
  }
  body.now-future .nf-now-members-items {
    margin-top: 258px;
    flex: 0 0 61% !important;
    margin-left: 0;
    margin-right: 0;
  }
  body.now-future .vertical-item_text {
    font-size: 1.25rem;
    top: 0px;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) {
    flex: 0 0 50%;
    max-width: 325px;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) .vertical-item_text {
    left: 20px;
    right: auto;
  }
  body.now-future .nf-now-members-col:nth-of-type(odd) .nf-now_text {
    margin-left: 120px;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) {
    flex: 0 0 50%;
    max-width: 325px;
    margin-left: 45px;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) .vertical-item_text {
    right: 20px;
  }
  body.now-future .nf-now-members-col:nth-of-type(even) .nf-now_text {
    margin-right: 120px;
  }
  body.now-future .bg_now_content {
    flex: 0 0 39%;
    max-width: 39%;
    padding-left: 0;
    padding-right: 0;
  }
  body.now-future .bg_now01 {
    background-position: top 0px left -55px;
  }
  body.now-future .bg_now01 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_now01 .nf-now-members_text {
    margin-top: 25px;
  }
  body.now-future .bg_now01 .nf-now-vertical .nf-now-vertical_text {
    left: 185px;
  }
  body.now-future .bg_now01 .nf-now-members-range {
    margin-top: 100px;
  }
  body.now-future .bg_now02 {
    background-position: top 0px right -55px;
  }
  body.now-future .bg_now02 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_now02 .nf-now-members_text {
    margin-top: 19px;
  }
  body.now-future .bg_now02 .nf-now-vertical_text span.now-vertical_text02 {
    margin-top: 35px;
  }
  body.now-future .bg_now02 .nf-now-vertical .nf-now-vertical_text {
    top: 290px;
    right: 145px;
  }
  body.now-future .bg_now02 .nf-now-members-range {
    margin-top: 95px;
    margin-left: 30px;
  }
  body.now-future .bg_now03 {
    background-position: top 0px left -55px;
  }
  body.now-future .bg_now03 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_now03 .nf-now-members_text {
    margin-top: 30px;
  }
  body.now-future .bg_now03 .nf-now-vertical_text {
    top: 330px;
  }
  body.now-future .bg_now03 .nf-now-vertical .nf-now-vertical_text {
    left: 185px;
  }
  body.now-future .bg_now03 .nf-now-members-range {
    margin-top: 95px;
  }
  body.now-future .bg_now04 {
    background-position: top 0px right -55px;
  }
  body.now-future .bg_now04 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_now04 .nf-now-members_text {
    margin-top: 30px;
  }
  body.now-future .bg_now04 .nf-now-vertical .nf-now-vertical_text {
    top: 290px;
    right: 145px;
  }
  body.now-future .bg_now04 .nf-now-members-range {
    margin-top: 80px;
    margin-left: 30px;
  }
  body.now-future .bg_now05 {
    background-position: top 0px left -55px;
  }
  body.now-future .bg_now05 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_now05 .nf-now-vertical .nf-now-vertical_text {
    left: 185px;
  }
  body.now-future .bg_now05 .nf-now-members-range {
    margin-top: 100px;
  }
  body.now-future .bg_future01 {
    background-position: top 0px left -55px;
  }
  body.now-future .bg_future01 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_future01 .nf-now-members_text {
    margin-top: 12px;
  }
  body.now-future .bg_future01 .nf-now-vertical .nf-now-vertical_text {
    left: 185px;
    right: auto;
  }
  body.now-future .bg_future01 .nf-now-members-range {
    margin-top: 150px;
  }
  body.now-future .bg_future02 {
    background-position: top 0px right -55px;
  }
  body.now-future .bg_future02 .nf-now-members-name_text {
    margin-top: 16.7px;
  }
  body.now-future .bg_future02 .nf-now-members_text {
    margin-top: 12px;
  }
  body.now-future .bg_future02 .nf-now-vertical_text {
    top: 273px;
    right: 185px;
    margin-left: auto;
  }
  body.now-future .bg_future02 .nf-now-vertical .nf-now-vertical_text {
    margin-left: auto;
  }
  body.now-future .bg_future02 .nf-now-members-range {
    margin-top: 100px;
    margin-left: 30px;
  }
  body.now-future .bg_future03 {
    background-position: top 0px left -55px;
  }
  body.now-future .bg_future03 .nf-now-members-name_text {
    margin-top: 17px;
  }
  body.now-future .bg_future03 .nf-now-members_text {
    margin-top: 11px;
  }
  body.now-future .bg_future03 .nf-now-vertical_text span.now-vertical_text02 {
    margin-top: 30px;
  }
  body.now-future .bg_future03 .nf-now-vertical .nf-now-vertical_text {
    top: 363px;
    left: 185px;
    right: auto;
  }
  body.now-future .bg_future03 .nf-now-members-range {
    margin-top: 120px;
  }
  body.now-future .bg_future04 {
    background-position: top 0px right -55px;
  }
  body.now-future .bg_future04 .nf-now-vertical_text {
    top: 273px;
    right: 185px;
    margin-left: auto;
  }
  body.now-future .bg_future04 .nf-now-vertical .nf-now-vertical_text {
    margin-left: auto;
  }
  body.now-future .bg_future04 .nf-now-members-range {
    margin-top: 130px;
    margin-left: 30px;
  }
  body.now-future .bg_future05 {
    padding-bottom: 270px;
    background-position: top 0px left -55px;
  }
  body.now-future .bg_future05 .nf-now-members-name_text {
    margin-top: 16px;
  }
  body.now-future .bg_future05 .nf-now-members_text {
    margin-top: 13px;
  }
  body.now-future .bg_future05 .nf-now-vertical .nf-now-vertical_text {
    top: 253px;
    left: 185px;
    right: auto;
  }
  body.now-future .bg_future05 .nf-now-members-range {
    margin-top: 100px;
  }
  body.kita-t-b-w-lower main .breadcrumb-container {
    max-width: 1110px !important;
  }
  body.kita-t-b-w-lower .d-xl-flex {
    display: flex;
  }
  body.kita-t-b-w-lower .kl-hero {
    padding-top: 0;
    height: 57.6vw;
    background-position: top 0px left 0;
  }
  body.kita-t-b-w-lower .kl-hero-inner {
    width: 100%;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: inherit;
  }
  body.kita-t-b-w-lower .kl-hero_heddigng2_img {
    top: 223px;
    left: 0px;
  }
  body.kita-t-b-w-lower .kl-hero_heddigng2_img img {
    width: 100%;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero_heddigng2_img {
    width: 37.86667vw;
    max-width: 568px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_heddigng2_img {
    width: 35.46667vw;
    max-width: 632px;
    left: 50px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero_heddigng2_img {
    width: 35.6vw;
    max-width: 534px;
  }
  body.kita-t-b-w-lower .kl-hero_text_img {
    top: 551px;
    bottom: auto;
    left: 0px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-hero_text_img {
    width: 16.66667vw;
    max-width: 250px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_text_img {
    width: 20.53333vw;
    max-width: 408px;
    left: 50px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-hero_text_img {
    width: 26.33333vw;
    max-width: 395px;
  }
  body.kita-t-b-w-lower .kl-point_text {
    font-size: 1.5625rem;
  }
  body.kita-t-b-w-lower .kl-img-inner .parts img:nth-of-type(1) {
    left: -10%;
  }
  body.kita-t-b-w-lower .kl-img-inner .parts img:nth-of-type(2) {
    right: -10%;
  }
  body.kita-t-b-w-lower .kl-point_btn {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  body.kita-t-b-w-lower .kl-qualification-inner {
    max-width: 1220px;
    position: relative;
    padding-top: 30px;
    padding-bottom: 72.5px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-inner {
    max-width: 1220px;
  }
  body.kita-t-b-w-lower .kl-qualification_heading2 {
    -webkit-transform: translate(173px, 40px);
    transform: translate(173px, 40px);
  }
  body.kita-t-b-w-lower .kl-qualification-flex {
    margin-left: 183px;
  }
  body.kita-t-b-w-lower .kl-qualification_item_img:nth-of-type(1) {
    width: 87px;
    bottom: 0px;
    right: 394px;
  }
  body.kita-t-b-w-lower .kl-qualification_item_img:nth-of-type(2) {
    width: 109px;
    bottom: -20px;
    right: 239px;
  }
  body.kita-t-b-w-lower .kl-qualification_item_img:nth-of-type(3) {
    width: 58px;
    bottom: 40px;
    right: 156px;
  }
  body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img:nth-of-type(1) {
    bottom: 35%;
    right: -5%;
  }
  body.kita-t-b-w-lower .kl-qualification-parts .kl-qualification_item_img:nth-of-type(2) {
    right: 3%;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification__late {
    margin-left: 100px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-inner {
    padding-bottom: 61px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-flex {
    margin-left: 183px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification-list {
    margin-top: 0px;
    width: 32%;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_ul {
    padding-left: 30px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_img {
    width: 40%;
    margin-top: 0px;
    margin-left: 0;
    text-align: left;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_item_img:nth-of-type(1) {
    width: 53px;
    height: 103px;
    bottom: 54px;
    right: 16.53333vw;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_item_img:nth-of-type(2) {
    width: 93px;
    height: 94px;
    bottom: 20px;
    right: 10.93333vw;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification-flex {
    margin-left: 0;
    justify-content: center;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul {
    margin-left: 30px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_ul li {
    font-size: 1.125rem;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:nth-of-type(1) {
    width: 74px;
    height: 72px;
    bottom: 4px;
    right: 90px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:nth-of-type(2) {
    width: 54px;
    height: 75px;
    right: -120px;
    bottom: 30px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:first-of-type {
    right: -60px;
  }
  body.kita-t-b-w-lower .ki-study-project {
    width: 986px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study__img {
    width: calc(47% - 24px);
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-study__img {
    width: calc(45% - 24px);
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-study__text {
    width: 53%;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-study__text {
    width: 55%;
  }
  body.kita-t-b-w-lower .kl-schedule {
    margin-top: 149px;
  }
  body.kita-t-b-w-lower .kl-schedule-bg-inner img {
    width: 27.6vw;
    max-width: 414px;
    bottom: 46px;
  }
  body.kita-t-b-w-lower .kl-schedule-bg-inner::before {
    width: 274px;
    height: 274px;
    left: 0;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt_img:nth-of-type(1) {
    width: 74px;
    height: 62px;
    right: 84px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-schedule-txt_img:nth-of-type(2) {
    width: 83px;
    height: 85px;
    right: 0px;
    bottom: 50px;
  }
  body.kita-t-b-w-lower .kl-schedule-60_heading2_img {
    width: 35.46667vw;
  }
  body.kita-t-b-w-lower .kl-schedule-60_heading2_img::before {
    width: 54px;
    height: 69px;
    left: -105px;
  }
  body.kita-t-b-w-lower .kl-schedule-60_heading2_img::after {
    width: 87px;
    height: 104px;
    right: -110px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide {
    padding-top: 40px;
  }
  body.kita-t-b-w-lower .next-arrow {
    right: 0px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-slide {
    padding-top: 20px;
  }
  body.kita-t-b-w-lower .prev-arrow,
  body.kita-t-b-w-lower .next-arrow,
  body.kita-t-b-w-lower .start-arrow,
  body.kita-t-b-w-lower .end-arrow {
    width: 35px;
    height: 60px;
  }
  body.kita-t-b-w-lower .prev-arrow {
    left: -10px;
  }
  body.kita-t-b-w-lower .next-arrow {
    right: -50px;
  }
  body.kita-t-b-w-lower .start-arrow {
    left: -45px;
  }
  body.kita-t-b-w-lower .end-arrow {
    right: -45px;
  }
  body.kita-t-b-w-lower .start-arrow.slick-disabled,
  body.kita-t-b-w-lower .end-arrow.slick-disabled {
    width: 42px;
    height: 207px;
  }
  body.kita-t-b-w-lower .kl-schedule-slide-item-tag {
    width: 123px;
    height: 123px;
    top: -60px;
    left: -40px;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-start-slide {
    max-width: 1000px;
  }
  body.kita-t-b-w-lower .kls-oneday-slide-item {
    max-width: 300px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-60_heading2_img::before {
    width: 77px;
    height: 64px;
    left: -132px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-schedule-60_heading2_img::after {
    width: 91px;
    height: 79px;
    right: -156px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table {
    width: 100%;
    max-width: 335px;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum .table.kl-curriculum-tag.style {
    margin-top: 80px;
  }
  body.kita-t-b-w-lower .kl-curriculum_heading2 {
    padding-left: 25px;
  }
  body.kita-t-b-w-lower .kl-curriculum_tables {
    overflow-x: visible;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum_tables {
    flex-wrap: wrap;
  }
  body.kita-t-b-w-lower .kitaweru-kodomo-inner .kl-curriculum_tables.kl-curriculum_tables-hiddend .table:last-of-type {
    margin-top: 55px;
  }
  body.kita-t-b-w-lower .kl-curriculum_tables-hidden {
    overflow-x: visible;
    flex-wrap: wrap;
    padding-top: 0 !important;
  }
  body.kita-t-b-w-lower .kl-curriculum_tables-hidden .table:nth-of-type(3) {
    margin-right: 0;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::before,
  body.kita-t-b-w-lower .kl-curriculum_btn::after {
    width: 345px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::before {
    left: -405px;
  }
  body.kita-t-b-w-lower .kl-curriculum_btn::after {
    right: -405px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-curriculum {
    padding-bottom: 20px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-curriculum_tables {
    flex-direction: row;
    flex-wrap: wrap;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .table-wrapper {
    overflow-x: visible;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .table {
    margin-right: 20px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-calc {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    order: 2;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-text {
    order: 3;
  }
  body.kita-t-b-w-lower .kl-links {
    padding-top: 80px;
  }
  body.kita-t-b-w-lower .kl-links-inner {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kl-links_li:nth-of-type(even) {
    margin-left: 31px;
  }
  body.kita-t-b-w-lower .table tr th, body.kita-t-b-w-lower .table tr td {
    width: 75%;
    min-width: auto;
  }
  body.kita-t-b-w-lower .table tr th:nth-of-type(2),
  body.kita-t-b-w-lower .table tr td:nth-of-type(2) {
    width: 25%;
    min-width: auto;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-curriculum {
    padding-top: 80px !important;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01 {
    max-width: 1120px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01 img {
    max-width: 603px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2_img01::before {
    bottom: -60px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2-second-wrapeer {
    padding-left: 0;
    padding-right: 0;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-point2__track::after {
    left: -10px;
    bottom: -5px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .container {
    max-width: 1110px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_heddigng2_img {
    max-width: 440px;
    top: 223px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_text_img {
    max-width: 245px;
    top: 500px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_heading2 {
    max-width: 488px;
    padding-top: 54px;
    padding-bottom: 43px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-inner {
    width: 100%;
    max-width: 100%;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification-flex {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_c1_heading3 {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_heading3 {
    max-width: 60px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_heading3 img {
    width: 100%;
    max-width: 100%;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_ul {
    width: 38%;
    margin-left: 50px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_ul:nth-of-type(1) {
    margin-left: 70px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_ul:nth-of-type(2) {
    width: 44%;
    margin-left: 50px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-qualification_paragraph {
    margin-left: 50px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-study_heading4 {
    margin-top: 0px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-study_paragraph {
    max-width: 445px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-focus_paragraph {
    font-size: 1.125rem;
    line-height: 1.66667;
    margin-top: 29px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner.kt-course1 .kl-hero_text_img {
    max-width: 347px;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 tr th:nth-of-type(2),
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .table01 tr td:nth-of-type(2) {
    border-right: 1px solid #6f6f6f !important;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-item-table .kl-curriculum-comparison {
    width: 30%;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-qualification-flex {
    max-width: 1110px;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-qualification_ul:nth-of-type(2) {
    width: 45%;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .text {
    width: calc(50% - 20px);
    padding: 0 20px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-levelup .levelup-content.last-style .card:last-of-type .text {
    width: calc(50% - 30px);
    padding: 0 30px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_img {
    max-width: 445px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_c1_heading3 {
    font-size: 1.5rem;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification_ul {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-qualification-flex {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_img {
    max-width: 310px;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_ul {
    width: 43%;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-qualification_ul li span {
    font-size: 0.875rem;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-curriculum_table-wrapper {
    display: flex;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-curriculum_tables {
    width: 80%;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_heddigng2_img {
    max-width: 650px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_text_img {
    max-width: 371px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification-flex:last-of-type {
    margin-top: -20px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_heading3 {
    max-width: 220px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_ul {
    width: 80%;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-q_paragraph {
    margin-top: 54px;
    font-size: 1.125rem;
    line-height: 2.22222;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-calc_img {
    margin-left: 100px;
    width: 100%;
    max-width: 175px;
  }
  body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item-ta {
    font-size: 1.375rem;
  }
  body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item-tb {
    font-size: 1rem;
  }
  body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item_ul .course__slide-item_li {
    font-size: 0.875rem;
  }
  body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item_ul .course__slide-item_li:before {
    top: 4px;
  }
  body.kita-t-b-w-lower .hsc-course-inner .course__slide-item .course__slide-item-text {
    font-size: 0.875rem;
  }
  body.kita-t-b-w-lower .hsc-course-inner .kl-curriculum_tables {
    justify-content: center;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_text_02 {
    width: 75%;
    left: 23%;
  }
  body.kita-t-b-w-lower .hsc-course-inner .hsc-course-study_text_01 {
    margin-left: 45px;
    width: 75%;
  }
  body.hsc.course .section__tech-materials .materials__items .item {
    -webkit-transform: translate(50%, 25%);
    transform: translate(50%, 25%);
  }
  body.lp.opencampus.child-page .section__01 {
    margin-top: 9rem;
  }
  body.lp.opencampus.child-page .section__01 h2 {
    top: -5.5rem;
  }
  body.lp.opencampus.child-page .section__01 .first__slide__wrap {
    margin-top: 0;
  }
  body.lp.opencampus.child-page .section__01 .text__block {
    margin-top: 3rem;
  }
  body.lp.opencampus.child-page .section__01 .text__block .banner__doc {
    margin-top: 6rem;
  }
  body.lp.opencampus.hsc .section__01 .first__slide__wrap {
    margin-top: 0;
  }
  body.lp .section__08:not(.parent-group) div.content {
    padding-top: 10px;
  }
  body.interview .individual__container {
    max-width: 1140px !important;
  }
  body.interview .individual__content {
    gap: 36px 30px;
  }
  body.interview .individual__item.hsc .student .text {
    margin-left: -20px;
  }
  body.interview .individual__item.hsc .student .text .d-range-none {
    display: none;
  }
  body.interview .individual__item .top .student {
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
  body.now-future.nf-2024 .nf-hero-star img:nth-of-type(4) {
    top: 147.5px;
    left: 39.62667vw;
  }
  body.now-future.nf-2024 .nf-now-members-items {
    margin-top: 200px;
  }
  body.now-future.nf-2024 .bg_now01 .nf-now-vertical .nf-now-vertical_text {
    top: 245px;
  }
  body.now-future.nf-2024 .bg_now03 .nf-now-vertical .nf-now-vertical_text {
    top: 290px;
  }
  body.now-future.nf-2024 .bg_future01 .nf-now-members-range {
    margin-top: 110px;
  }
  body.now-future.nf-2024 .bg_future01 .nf-now-vertical .nf-now-vertical_text {
    top: 220px;
  }
  body.now-future.nf-2024 #future02.nf-now-heading2 {
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
  body.now-future.nf-2024 .bg_future02 .nf-now-vertical .nf-now-vertical_text {
    top: 190px;
  }
  body.now-future.nf-2024 .bg_future02 .nf-now-members-range {
    margin-top: 105px;
  }
  body.now-future.nf-2024 #future03.nf-now-heading2 {
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
  body.now-future.nf-2024 .bg_future03 .nf-now-members-range {
    margin-top: 100px;
  }
  body.now-future.nf-2024 .bg_future04 .nf-now-members-range {
    margin-top: 45px;
  }
  body.now-future.nf-2024 #future05.nf-now-heading2 {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical .nf-now-vertical_text {
    left: 90px;
  }
}

@media (min-width: 1330px) {
  body.kita-t-b-w-lower .kl-hero_text_img {
    top: 600px;
  }
}

@media (min-width: 1400px) {
  body.hsc .header__nav-ls .header__nav-item > a, body.htc .header__nav-ls .header__nav-item > a, body.iryo .header__nav-ls .header__nav-item > a {
    font-size: 1.125rem;
  }
}

@media (min-width: 1500px) {
  body.interview .mv__slider {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    -webkit-column-gap: 35px;
    -moz-column-gap: 35px;
    column-gap: 35px;
    width: calc(100% - 100px);
    margin-inline: auto;
  }
  body.interview .mv__slider .slider-item {
    min-width: 170px;
  }
  body.interview .mv__slider .slider-item .top {
    width: 14.7vw;
  }
  body.interview .mv__slider .slider-item .top::before {
    width: 3.7vw;
    height: auto;
    aspect-ratio: 56/41;
  }
  body.interview .mv__slider .slider-item .top img {
    width: 100%;
  }
  body.interview .mv__slider .slider-item .bottom {
    width: 100%;
  }
}

@media (min-width: 1600px) {
  body.now-future .nf-hero-star img:nth-of-type(2) {
    top: 207.8px;
  }
  body.now-future #future01, body.now-future #future03, body.now-future #future05 {
    padding-right: 30px;
  }
  body.now-future #future02, body.now-future #future04 {
    padding-left: 0px;
  }
  body.now-future .nf-future-bg01 .nf-now-vertical .nf-now-vertical_text, body.now-future .nf-future-bg03 .nf-now-vertical .nf-now-vertical_text, body.now-future .nf-future-bg05 .nf-now-vertical .nf-now-vertical_text {
    left: 115px;
    right: auto;
  }
  body.now-future .bg_now01 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_now01 {
    background-position: top 0px left 0px;
  }
  body.now-future .bg_now01 .nf-now-vertical .nf-now-vertical_text {
    top: 310px;
    left: 115px;
  }
  body.now-future .bg_now01 .nf-now-members-range {
    margin-left: -35px;
  }
  body.now-future .bg_now02 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_now02 {
    background-position: top 0px right 0px;
  }
  body.now-future .bg_now02 .nf-now-vertical .nf-now-vertical_text {
    top: 260px;
    right: 115px;
  }
  body.now-future .bg_now02 .nf-now-members-range {
    margin-left: 55px;
  }
  body.now-future .bg_now03 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_now03 {
    background-position: top 0px left 0px;
  }
  body.now-future .bg_now03 .nf-now-vertical_text {
    top: 300px;
  }
  body.now-future .bg_now03 .nf-now-vertical .nf-now-vertical_text {
    left: 115px;
  }
  body.now-future .bg_now03 .nf-now-members-range {
    margin-left: -35px;
  }
  body.now-future .bg_now04 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_now04 {
    background-position: top 0px right 0px;
  }
  body.now-future .bg_now04 .nf-now-vertical .nf-now-vertical_text {
    top: 260px;
    right: 115px;
  }
  body.now-future .bg_now04 .nf-now-members-range {
    margin-left: 55px;
  }
  body.now-future .bg_now05 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_now05 {
    background-position: top 0px left 0px;
  }
  body.now-future .bg_now05 .nf-now-vertical .nf-now-vertical_text {
    left: 115px;
  }
  body.now-future .bg_now05 .nf-now-members-range {
    margin-left: -35px;
  }
  body.now-future .bg_future01 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_future01 {
    background-position: top 0px left 0px;
  }
  body.now-future .bg_future01 .nf-now-vertical .nf-now-vertical_text {
    left: 115px;
  }
  body.now-future .bg_future01 .nf-now-members-range {
    margin-left: -35px;
  }
  body.now-future .bg_future02 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_future02 {
    background-position: top 0px right 0px;
  }
  body.now-future .bg_future02 .nf-now-vertical_text {
    right: 115px;
  }
  body.now-future .bg_future02 .nf-now-members-range {
    margin-left: 55px;
  }
  body.now-future .bg_future03 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_future03 {
    background-position: top 0px left 0px;
  }
  body.now-future .bg_future03 .nf-now-vertical .nf-now-vertical_text {
    left: 115px;
  }
  body.now-future .bg_future03 .nf-now-members-range {
    margin-left: -35px;
  }
  body.now-future .bg_future04 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_future04 {
    background-position: top 0px right -0px;
  }
  body.now-future .bg_future04 .nf-now-vertical_text {
    top: 243px;
    right: 115px;
  }
  body.now-future .bg_future04 .nf-now-members-range {
    margin-left: 55px;
  }
  body.now-future .bg_future05 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-size: 784px;
  }
  body.now-future .bg_future05 {
    background-position: top 0px left 0;
  }
  body.now-future .bg_future05 .nf-now-vertical .nf-now-vertical_text {
    left: 115px;
  }
  body.now-future .bg_future05 .nf-now-members-range {
    margin-left: -35px;
  }
  body.kita-t-b-w-lower .kl-hero-inner {
    max-width: 1220px;
  }
  body.kita-t-b-w-lower .kl-hero_heddigng2_img {
    top: 263px;
    left: -100px;
  }
  body.kita-t-b-w-lower .kl-hero_heddigng2_img img {
    width: 100%;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_heddigng2_img {
    left: -100px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_heddigng2_img img {
    width: 100%;
  }
  body.kita-t-b-w-lower .kl-hero_text_img {
    top: 631px;
    bottom: auto;
    left: -100px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-hero_text_img {
    left: -100px;
  }
  body.kita-t-b-w-lower .kitaweru-kaigo-inner .kl-qualification_item_img:nth-of-type(1) {
    right: 15.2vw;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:nth-of-type(2) {
    right: -140px;
    bottom: 40px;
  }
  body.kita-t-b-w-lower .kitaweru-yakugyo-inner .kl-qualification_item_img:first-of-type {
    right: -80px;
  }
  body.kita-t-b-w-lower .kl-schedule-bg-inner img {
    bottom: 106px;
  }
  body.kita-t-b-w-lower .prev-arrow {
    left: -10px;
  }
  body.kita-t-b-w-lower .next-arrow {
    right: -70px;
  }
  body.kita-t-b-w-lower .start-arrow {
    left: -60px;
  }
  body.kita-t-b-w-lower .end-arrow {
    right: -60px;
  }
  body.kita-t-b-w-lower .start-arrow.slick-disabled,
  body.kita-t-b-w-lower .end-arrow.slick-disabled {
    width: 49px;
    height: 236px;
  }
  body.kita-t-b-w-lower .kl-schedule-oneday-start-slide {
    max-width: 1200px;
  }
  body.kita-t-b-w-lower .kls-oneday-slide-item {
    width: 339px !important;
    max-width: 339px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero-inner {
    position: static;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_heddigng2_img {
    max-width: 540px;
    left: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-lower-inner .kl-hero_text_img {
    max-width: 295px;
    top: 550px;
    left: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-hero-inner {
    position: static;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-hero_heddigng2_img {
    top: 323px;
    left: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-course2-inner .kl-hero_text_img {
    top: 650px;
    left: 100px;
  }
  body.kita-t-b-w-lower .kita-htc-course3-inner .kl-hero_heddigng2_img {
    max-width: 635px;
  }
  body.kita-t-b-w-lower .kita-htc-course4-inner .kl-hero_heddigng2_img {
    max-width: 540px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_heddigng2_img {
    max-width: 750px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-hero_text_img {
    max-width: 471px;
  }
  body.kita-t-b-w-lower .kita-htc-course5-inner .kl-qualification_ul {
    width: 90%;
  }
  body.now-future.nf-2024 .bg_now03 .nf-now-members-range {
    margin-top: 70px;
  }
  body.now-future.nf-2024 .nf-now-bg.nf-future-bg .bg_future05 .nf-now-vertical .nf-now-vertical_text {
    left: 10px;
  }
}

@media (any-hover: hover) {
  body.interview .mv__slider .slider-item:hover img.before {
    opacity: 0 !important;
    -webkit-animation: b 1.4s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards;
    animation: b 1.4s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards;
  }
  body.interview .mv__slider .slider-item:hover img.after {
    opacity: 1 !important;
    -webkit-animation: a 1.6s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards 0.1s;
    animation: a 1.6s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards 0.1s;
  }
  body.interview .mv__slider .slider-item:hover .top::before {
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    opacity: 0;
    -webkit-animation: s 0.3s cubic-bezier(0.2, 0.7, 0.72, 1.51) 0.6s forwards;
    animation: s 0.3s cubic-bezier(0.2, 0.7, 0.72, 1.51) 0.6s forwards;
  }
  body.interview .mv__slider .slider-item.interview--01:hover .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--02:hover .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--03:hover .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--04:hover .top::before {
    top: 34%;
  }
  body.interview .mv__slider .slider-item.interview--05:hover .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--06:hover .top::before {
    top: 34%;
  }
  body.interview .mv__slider .slider-item:hover .bottom {
    -webkit-animation: rr 0.8s ease-in-out forwards 0.3s;
    animation: rr 0.8s ease-in-out forwards 0.3s;
  }
}

@media (any-hover: none) {
  body.interview .mv__slider .slider-item.current img.before {
    opacity: 0 !important;
    -webkit-animation: b 1.4s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards;
    animation: b 1.4s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards;
  }
  body.interview .mv__slider .slider-item.current img.after {
    opacity: 1 !important;
    -webkit-animation: a 1.6s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards 0.1s;
    animation: a 1.6s cubic-bezier(0.42, 0.23, 0.27, 1.02) forwards 0.1s;
  }
  body.interview .mv__slider .slider-item.current .top::before {
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    opacity: 0;
    -webkit-animation: s 0.3s cubic-bezier(0.2, 0.7, 0.72, 1.51) 0.6s forwards;
    animation: s 0.3s cubic-bezier(0.2, 0.7, 0.72, 1.51) 0.6s forwards;
  }
  body.interview .mv__slider .slider-item.current img.before, body.interview .mv__slider .slider-item.current img.after {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    transition-delay: 1.5s;
  }
  body.interview .mv__slider .slider-item.current .top::before {
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
    transition-delay: 1.6s;
  }
  body.interview .mv__slider .slider-item.interview--01.current .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--02.current .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--03.current .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--04.current .top::before {
    top: 34%;
  }
  body.interview .mv__slider .slider-item.interview--05.current .top::before {
    top: 29%;
  }
  body.interview .mv__slider .slider-item.interview--06.current .top::before {
    top: 34%;
  }
  body.interview .mv__slider .slider-item.current .bottom {
    -webkit-animation: rr 0.8s ease-in-out forwards 0.3s;
    animation: rr 0.8s ease-in-out forwards 0.3s;
  }
  body.interview .mv__slider .slider-item.current .bottom {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
  }
}

@media (min-width) {
  body.now-future .bg_future03 .nf-now-members-name_text {
    margin-left: 20px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
  .btn {
    transition: none;
  }
  .fade {
    transition: none;
  }
  .collapsing {
    transition: none;
  }
  .badge {
    transition: none;
  }
  .carousel-item {
    transition: none;
  }
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
  .carousel-indicators li {
    transition: none;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #212224;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #F4CB35;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #F4CB35;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
}
