﻿
body.lp{
  font-family: $font-noto;
  .text-orange{
    color:$p-orange;
  }
  .text-green{
    color:$c-hukusi;
  }
  .text-pink{
    color:$p-pink;
  }
  .section{

  }
  .p-form__btn__wrap{
    .p-next-btn__wrap{
      .submit-btn{
        &.is_agree{
          background-color: $teal;
          &:hover{
            background-color: darken($teal,10%);
          }
        }
      }
      button{
        // background: $teal;
        &:hover{
          background: darken($teal,5%);
        }
      }
    }
  }
  .container{
    .inner__container{
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .hero{
    margin-bottom: 8rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 5rem;
    }
  }
  &.opencampus{
    .section{
      padding-top:rem(105);
      padding-bottom:rem(105);
    }
    .head__wrap{
      &:not(.not){
        text-align: center;
        transform: rotate(-6deg);
        position: relative;
        margin-bottom:2.5rem;
        opacity: 0;
        // &:after{
        //   content: '';
        //   display: block;
        //   position: absolute;
        //   background: url(../img/lp/oc/img_section2_marker.png)no-repeat;
        //   background-position: center center;
        //   width: 338px;
        //   height: 104px;
        //   top: 0;
        //   right: 0;
        //   bottom: 0;
        //   left: 0;
        //   margin: auto;
        //   transform: rotate(6deg);
        //   z-index: -1;
        // }
        h2{
          font-size: rem-calc(40);
          font-weight: 800;
          margin-bottom: 0;
          letter-spacing: ls(100);
          color: #333333;
          @include media-breakpoint-down(sm) {
            font-size: rem-calc(23);
          }
        }
        .f-century{
          color: $p-pink;
          font-size: rem-calc(25);
          letter-spacing: ls(50);
          @include media-breakpoint-down(sm) {
            font-size: rem-calc(14);
          }
        }
      }
      &.is_scrolled{
        &:not(.not){
          animation: buruburu .5s ease-in-out forwards;
          @keyframes buruburu {
            0%{
              opacity: 0;
            }
            20%{
              opacity: 1;
              transform: rotate(8deg);
            }
            40%{
              opacity: 1;
              transform: rotate(8deg);
            }
            60%{
              transform: rotate(-3deg);
              animation-direction: alternate;
            }
            80%{
              transform: rotate(3deg);
              animation-direction: alternate;
            }
            100%{
              opacity: 1;
              transform: rotate(0deg);
              animation-direction: alternate;
            }
          }
        }
      }
    }
    //================================
    .oc-main-under{
      display: block;
      padding-top: 3.2rem;
      padding-bottom: 5rem;
      @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 4.5rem;
      }
      &__block{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        &-item{
          padding: 0 3px;
          width: calc(100% / 4);
          @include media-breakpoint-down(md) {
            padding: 1px;
            width: calc(100%/ 2);
          }
        }
      }
    }
    //================================
    .oc-schedule{
      background-color: $yellow;
      &-heading{
        position: relative;
        padding-bottom: 30px;
        text-align: center;
        &::after{
          content: '';
          position: absolute;
          bottom: 1px;
          right: 50%;
          display: block;
          width: 38px;
          height: 30px;
          transform: translateX(50%);
        }
        &-text{
          display: block;
          width: 100%;
          padding: 1em 10px;
          color: #fff;
          letter-spacing: .1em;
          font-size: 1.5rem;
          font-weight: bold;
          @include media-breakpoint-down(md) {
            font-size: 1.1rem;
          }
        }
      }
      &__block{
        padding: 4.5rem .5rem;
        position: relative;
        z-index: 4;
        @include media-breakpoint-down(md) {
          padding: 3.5rem .5rem;
        }
        @include media-breakpoint-down(sm) {
          margin: 0 -10px;
        }
        &__container{
          max-width: 1020px;
          margin: auto;
          padding-left: 10px;
          padding-right: 10px;
          @include media-breakpoint-down(sm) {
            max-width: 540px;
          }
        }
      }
      &__list{
        display: flex;
        margin-top: -5%;
        padding: 0;
        flex-wrap: wrap;
        list-style: none;
        &-item{
          width: 21.25%;
          margin-top: 5%;
          margin-right: 5%;
          &:last-of-type{
            margin-right: 0;
          }
          &:nth-of-type(even){
            @include media-breakpoint-down(md) {
              margin-right: 0;
            }
          }
          @include media-breakpoint-down(md) {
            width: 47.5%;
          }
          &-wrap{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: auto;
            width: 200px;
            height: 200px;
            border: 4px solid transparent;
            border-radius: 50%;
            background-color: #fff;
            @include media-breakpoint-down(sm) {
              width: 150px;
              height: 150px;
            }
            &::before{
              content: '';
              position: absolute;
              bottom: -10px;
              right: -10px;
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              z-index: -1;
            }
            p{
              color: #696969;
              font-weight: bold;
              &.year{
                font-size: 1.5625rem;
                @include media-breakpoint-down(sm) {
                  font-size: 1.25rem;
                }
              }
              &.date{
                // padding: .5em 0;
                font-size: 2.625rem;
                @include media-breakpoint-down(sm) {
                  font-size: 1.5625rem;
                }
              }
              &.week{
                font-size: 1.5625rem;
                @include media-breakpoint-down(sm) {
                  font-size: 1.25rem;
                }
              }
            }
          }
        }
      }
      &.oc-schedule-hsc{
        .oc-schedule-heading{
          &::after{
            background-image:url(../img/lp/oc/hsc/oc_schedule-arrow.svg);
            background-size: contain;
          }
        }
        .oc-schedule-heading-text{
          background-color: $pink;
        }
        .oc-schedule__list-item{
          &-wrap{
            border-color: $pink;
            &::before{
              background-color: $pink;
            }
          }
        }
      }
      &.oc-schedule-htc{
        .oc-schedule-heading{
          &::after{
            background-image:url(../img/lp/oc/htc/oc_schedule-arrow.svg);
            background-size: contain;
          }
        }
        .oc-schedule-heading-text{
          background-color: $c-cook;
        }
        .oc-schedule__list-item{
          &-wrap{
            border-color: $c-cook;
            &::before{
              background-color: $c-cook;
            }
          }
        }
      }
      &.oc-schedule-iryo{
        .oc-schedule-heading{
          &::after{
            background-image:url(../img/lp/oc/iryo/oc_schedule-arrow.svg);
            background-size: contain;
          }
        }
        .oc-schedule-heading-text{
          background-color: $c-hukusi;
        }
        .oc-schedule__list-item{
          &-wrap{
            border-color: $c-hukusi;
            &::before{
              background-color: $c-hukusi;
            }
          }
        }
      }
    }
    //================================
    .section__01{
        margin-bottom: 7rem;
        margin-top:4rem;
        @include media-breakpoint-down(sm) {
          margin-bottom: 4rem;
          margin-top:8rem;
          
        }
      h2{
        font-weight: bold;
        color:$teal;
        display: inline-block;
        letter-spacing: 2px;
        position: absolute;
        top: -4rem;
        left: 0;
        z-index: 10;
        font-size: rem-calc(20);
        @include media-breakpoint-up(md) {
          font-size: rem-calc(30);
        }
        @include media-breakpoint-up(lg) {
          top: 1rem;
          font-size: rem-calc(35);
          padding:.5rem 2rem;
          &:after{
            content: '';
            display: block;
            background-color: rgba(#FCF25D,.7);
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            z-index: -1;
          }
        }
        span{
          position: relative;
          @include media-breakpoint-down(md) {
            padding:.5rem;
            margin-bottom: 5px;
            &:after{
              content: '';
              display: block;
              background-color: rgba(#FCF25D,.7);
              position: absolute;
              left: 0;
              top: 0;
              width: 0;
              height: 100%;
              z-index: -1;
            }
          }
        }
      }
      .section-heading{
        &.is_scrolled{
          &:after{
            transition:.5s;
            width: 100%;
          }
          span{
            &:after{
              transition:.5s;
              width: 100%;
            }
          }
        }
      }
      .slick-dots{
        display: flex;
        button{
          background-color: #F0F0F0;
          width: 100%;
          height: 15px;
          position: relative;
          &:before{
            content: "";
            background-color: $p-pink;
            width: 0;
            height: 100%;
            opacity: 1;
          }
        }
        li{
          margin-left: 0;
          width:calc((100% - 16px) / 6);
          height: auto;
          &:not(:last-of-type){
            margin-right: 4px;
          }
          &.slick-active{
            button{
              &:before{
                transition:3.4s linear;
                width: 100%;
              }
            }
          }
        }
      }
      .section__01__block__inner{
        margin-bottom: 5rem;
        position: relative;
        @include media-breakpoint-up(lg) {
          display: flex;
        }
        // flex-direction: row-reverse;
      }
      .section__01__block{
        position: relative;
      }
      .first__slide__wrap{
        @include media-breakpoint-up(lg) {
          width:50vw;
          position: absolute;
          left:50%;
          top: 0;
        }
      }
      .text__block{
        margin-top: 3rem;
        line-height: 2;
        font-size: rem-calc(16);
        padding:0;
        @include media-breakpoint-up(lg) {
          width: 50%;
        }
        @include media-breakpoint-up(lg) {
          padding: 0 1rem 0 3rem;
          margin-top: 7rem;
          font-size: rem-calc(18);
        }
      }
      .banner__doc{
        @include media-breakpoint-down(md) {
          margin-top: 2rem;
        }
      }
      .banner__reserve{
        @include media-breakpoint-down(md) {
          margin-top: 4rem;
        }
      }
    }
      //================================
    &.parent-group{
      .hero{
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
      h1{
        margin-bottom:0;
        img{
          width:100%;
        }
      }
      .head__wrap{
        &:not(.not){
          color:$teal;
        }
      }
      .section__imgs{
        padding: 55px 0 80px;
        @include media-breakpoint-down(sm){
          padding: 40px 0 70px;
        }
        .inner{
            width: 97%;
            margin: 0 auto;
            max-width: 1300px;
        }
        .imgs-wrapper{
          display: grid;
          grid-template-columns: repeat(4,1fr);
          gap: 6px;
          @include media-breakpoint-down(sm){
            grid-template-columns: repeat(2,1fr);
            gap: 3px;
          }
        }
      }
      .section__00{
        h2{
          text-align: center;
          color:white;
          background-color: $p-pink;
          font-weight: bold;
          font-size: rem-calc(25);
          padding: 1rem 0.5rem;
          position: relative;
          margin-bottom: 0;
          @include media-breakpoint-down(sm) {
            margin:0 -10px;
            font-size: rem-calc(20);
          }
          &:after{
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            margin: auto;
            border-width:20px 10px 0 10px;
            border-color:$p-pink transparent transparent transparent;
            border-style: solid;
            width: 0;
            height: 0;
          }
        }
        .schedule__wrap{
          background-color:$p-yellow;
          // background: url(../img/lp/oc/bg_kikagaku.png)no-repeat;
          // background-size: cover;
          // background-position: 0 0;
          padding:4.5rem .5rem;
          position: relative;
          z-index: -1;
          @include media-breakpoint-down(md) {
            padding:3.5rem .5rem;
          }
          @include media-breakpoint-down(sm) {
            margin:0 -10px;
            // background: url(../img/lp/oc/bg_kikagaku_sp.png)no-repeat;
            // background-size: cover;
            // background-position: 0 0;
          }
          .schedule__block{
            font-weight: bold;
            color:#696969;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            margin-top: -5%;
            li{
              @include media-breakpoint-up(lg) {
                width:calc(85% / 4);
                margin-top: 5%;
                &:not(:nth-of-type(4n)){
                  margin-right: 5%;
                }
              }
              @include media-breakpoint-down(md) {
                width: 47.5%;
                margin-top: 5%;
                &:not(:nth-of-type(even)){
                  margin-right: 5%;
                }
              }
              .item{
                border-radius: 50%;
                border:4px solid $p-pink;
                background-color: white;
                width: 200px;
                height: 200px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                position: relative;
                margin: auto;
                @include media-breakpoint-down(sm) {
                  width:150px;
                  height: 150px;
                }
                &:after{
                  content: '';
                  display: block;
                  position: absolute;
                  right: -10px;
                  bottom:-10px;
                  width:100%;
                  height: 100%;
                  border-radius: 50%;
                  background-color: $p-pink;
                  z-index: -1;
                }
              }
            }
            .year{
              font-size: rem-calc(25);
              @include media-breakpoint-down(sm) {
                font-size: rem-calc(20);
              }
            }
            .date{
              font-size: rem-calc(42);
              @include media-breakpoint-down(sm) {
                font-size: rem-calc(25);
              }
            }
            .week{
              font-size: rem-calc(25);
              @include media-breakpoint-down(sm) {
                font-size: rem-calc(20);
              }
            }
          }
          &:after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $teal;
            z-index: 10;
          }
          &.is_scrolled{
            &:after{
              transition:.6s cubic-bezier(0.72, 0.02, 0.26, 1);
              width: 0;
            }
          }
        }
      }
      .section__01{
        // margin-bottom: 7rem;
        // margin-top:4rem;
        // @include media-breakpoint-down(sm) {
        //   margin-bottom: 4rem;
        //   margin-top:8rem;
          
        // }
        // .slick-dots{
        //   display: flex;
        //   button{
        //     background-color: #F0F0F0;
        //     width: 100%;
        //     height: 15px;
        //     position: relative;
        //     &:before{
        //       content: "";
        //       background-color: $p-pink;
        //       width: 0;
        //       height: 100%;
        //       opacity: 1;
        //     }
        //   }
        //   li{
        //     margin-left: 0;
        //     width:calc((100% - 16px) / 6);
        //     height: auto;
        //     &:not(:last-of-type){
        //       margin-right: 4px;
        //     }
        //     &.slick-active{
        //       button{
        //         &:before{
        //           transition:3.4s linear;
        //           width: 100%;
        //         }
        //       }
        //     }
        //   }
        // }
        // h2{
        //   font-weight: bold;
        //   color:$teal;
        //   display: inline-block;
        //   letter-spacing: 2px;
        //   position: absolute;
        //   top: -4rem;
        //   left: 0;
        //   z-index: 10;
        //   font-size: rem-calc(20);
        //   @include media-breakpoint-up(md) {
        //     font-size: rem-calc(30);
        //   }
        //   @include media-breakpoint-up(lg) {
        //     top: 1rem;
        //     font-size: rem-calc(35);
        //     padding:.5rem 2rem;
        //     &:after{
        //       content: '';
        //       display: block;
        //       background-color: rgba(#FCF25D,.7);
        //       position: absolute;
        //       left: 0;
        //       top: 0;
        //       width: 0;
        //       height: 100%;
        //       z-index: -1;
        //     }
        //   }
        //   span{
        //     position: relative;
        //     @include media-breakpoint-down(md) {
        //       padding:.5rem;
        //       margin-bottom: 5px;
        //       &:after{
        //         content: '';
        //         display: block;
        //         background-color: rgba(#FCF25D,.7);
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         width: 0;
        //         height: 100%;
        //         z-index: -1;
        //       }
        //     }
        //   }
        // }
        // .section-heading{
        //   &.is_scrolled{
        //     &:after{
        //       transition:.5s;
        //       width: 100%;
        //     }
        //     span{
        //       &:after{
        //         transition:.5s;
        //         width: 100%;
        //       }
        //     }
        //   }
        // }
        //==================================
        // .first__slide__wrap{
        //   @include media-breakpoint-up(lg) {
        //     width:50vw;
        //     position: absolute;
        //     left:50%;
        //     top: 0;
        //   }
        // }
        // .text__block{
        //   margin-top: 3rem;
        //   line-height: 2;
        //   font-size: rem-calc(16);
        //   padding:0;
        //   @include media-breakpoint-up(lg) {
        //     width: 50%;
        //   }
        //   @include media-breakpoint-up(lg) {
        //     padding: 0 1rem 0 4rem;
        //     margin-top: 7rem;
        //     font-size: rem-calc(18);
        //   }
        // }
        // .banner__doc{
        //   @include media-breakpoint-down(md) {
        //     margin-top: 2rem;
        //   }
        // }
        // .banner__reserve{
        //   @include media-breakpoint-down(md) {
        //     margin-top: 4rem;
        //   }
        // }
        //========================================
        // h3{
        //   text-align: center;
        //   color:white;
        //   background-color: $teal;
        //   font-weight: bold;
        //   font-size: rem-calc(25);
        //   padding: 1rem 0.5rem;
        //   position: relative;
        //   margin-bottom: 0;
        //   @include media-breakpoint-down(sm) {
        //     margin:0 -10px;
        //     font-size: rem-calc(20);
        //   }
        //   &:after{
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     top: 100%;
        //     right: 0;
        //     left: 0;
        //     margin: auto;
        //     border-width:20px 10px 0 10px;
        //     border-color:$teal transparent transparent transparent;
        //     border-style: solid;
        //     width: 0;
        //     height: 0;
        //   }
        // }
        // .schedule__wrap{
        //   background: url(../img/lp/oc/bg_kikagaku.png)no-repeat;
        //   background-size: cover;
        //   background-position: 0 0;
        //   padding:5rem .5rem;
        //   position: relative;
        //   z-index: -1;
        //   @include media-breakpoint-down(sm) {
        //     margin:0 -10px;
        //     background: url(../img/lp/oc/bg_kikagaku_sp.png)no-repeat;
        //     background-size: cover;
        //     background-position: 0 0;
        //   }
        //   .schedule__block{
        //     font-weight: bold;
        //     color:$teal;
        //     text-align: center;
        //     display: flex;
        //     flex-wrap: wrap;
        //     margin-top: -5%;
        //     li{
        //       @include media-breakpoint-up(lg) {
        //         width:calc(85% / 4);
        //         margin-top: 5%;
        //         &:not(:nth-of-type(4n)){
        //           margin-right: 5%;
        //         }
        //       }
        //       @include media-breakpoint-down(md) {
        //         width: 47.5%;
        //         margin-top: 5%;
        //         &:not(:nth-of-type(even)){
        //           margin-right: 5%;
        //         }
        //       }
        //       .item{
        //         border-radius: 50%;
        //         border:4px solid $teal;
        //         background-color: white;
        //         width: 200px;
        //         height: 200px;
        //         display: flex;
        //         align-items: center;
        //         flex-direction: column;
        //         justify-content: center;
        //         position: relative;
        //         margin: auto;
        //         @include media-breakpoint-down(sm) {
        //           width:150px;
        //           height: 150px;
        //         }
        //         &:after{
        //           content: '';
        //           display: block;
        //           position: absolute;
        //           right: -10px;
        //           bottom:-10px;
        //           width:100%;
        //           height: 100%;
        //           border-radius: 50%;
        //           background-color: $teal;
        //           z-index: -1;
        //         }
        //       }
        //     }
        //     .year{
        //       font-size: rem-calc(25);
        //       @include media-breakpoint-down(sm) {
        //         font-size: rem-calc(20);
        //       }
        //     }
        //     .date{
        //       font-size: rem-calc(42);
        //       @include media-breakpoint-down(sm) {
        //         font-size: rem-calc(25);
        //       }
        //     }
        //     .week{
        //       font-size: rem-calc(25);
        //       @include media-breakpoint-down(sm) {
        //         font-size: rem-calc(20);
        //       }
        //     }
        //   }
        //   &:after{
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: $teal;
        //     z-index: 10;
        //   }
        //   &.is_scrolled{
        //     &:after{
        //       transition:.6s cubic-bezier(0.72, 0.02, 0.26, 1);
        //       width: 0;
        //     }
        //   }
        // }
      }
      // .section__01__block__inner{
      //   margin-bottom: 5rem;
      //   position: relative;
      //   @include media-breakpoint-up(lg) {
      //     display: flex;
      //   }
      //   // flex-direction: row-reverse;
      // }
      // .section__01__block{
      //   position: relative;
      // }
      .main__inner{
        background: repeating-linear-gradient(-45deg, #DEF2FB 0, #DEF2FB 7px, #EEF8FD 0, #EEF8FD 14px);
      }
      .main__inner--01{
        padding: 6.2rem 0 0;
        @include media-breakpoint-down(sm){
          padding: 3.8rem 0 0;
        }
      }
      .main__inner--02{
        padding-top:10rem;
      }
      .section__02{
        padding:4.2rem 10px;
        position: relative;
        // margin-bottom: 15rem;
        @include media-breakpoint-down(sm) {
          padding:2.5rem 10px;
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 5rem;
          left: 0;
          width: 100%;
          z-index: 4;
          height: calc(100% - 13%);
          background: url(../img/lp/oc/img_section2_cracker.png)repeat-x;
        }
        .inner__container{
          position: relative;
          z-index: 10;
        }
        .item{
          position: absolute;
          z-index: 5;
          filter: brightness(0);
          &.is_scrolled{
            filter: brightness(1);
            transition:1s ease-out;
          }
        }
        .item--01{
          left: -50px;
          top: 0;
          width: 28%;
          max-width:420px;
          @include media-breakpoint-down(sm) {
            left: -5%;
            width: 43%;
            max-width:190px;
          }
        }
        .item--02{
          right:0;
          top: 0;
          width: 24%;
          max-width:367px;
          @include media-breakpoint-down(sm) {
            width: 43%;
            max-width:200px;
          }
        }
        .item--03{
          left: -40px;
          bottom: -100px;
          width: 21%;
          max-width:294px;
          @include media-breakpoint-down(sm) {
            bottom: -1%;
            left:-10px;
            width: 38%;
            max-width: 190px;
          }
        }
        .item--04{
          right: -10px;
          bottom: -120px;
          width: 21%;
          max-width:293px;
          @include media-breakpoint-down(sm) {
            bottom: -1%;
            width: 38%;
            max-width: 190px;
          }
        }
        .lg__container{
          max-width:1400px;
          margin: auto;
          background-color: #fff;
          border-radius: 40px;
          padding:4.5rem 0 4rem;
          position: relative;
          @include media-breakpoint-down(md) {
            padding:4.5rem 0 4rem;
            border-radius: 20px;
          }
        }
        .future__lists{
          .thumb{
            position: relative;
          }
          .future__name{
            // position: absolute;
            // left:0;
            // top: 0;
            color:white;
            font-weight: bold;
            // min-width:210px;
            width: 100%;
            display: block;
            padding:.5rem;
            // border-bottom-right-radius: 10px;
            // text-align-last: left;
            font-size: rem-calc(20);
            &.label--pink{
              background-color: $p-pink;
            }
            &.label--orange{
              background-color: $p-orange;
            }
            &.label--green{
              background-color: $c-hukusi;
            }
          }
        }
      }
      .section__04{
        margin-bottom: 10rem;
        padding-left: 10px;
        padding-right: 10px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 5rem;
        }
        .container{
          padding-top:5rem;
          padding-bottom: 5rem;
          background: url(../img/lp/oc/img_section4_bg.jpg)no-repeat;
          background-size: cover;
          @include media-breakpoint-down(md) {
            background: url(../img/lp/oc/img_section4_bg_sp.jpg)no-repeat;
            background-size: cover;
          }
        }
        &.is_active{
          .container{
            padding-bottom: 14rem;
            @include media-breakpoint-down(md) {
              padding-bottom: 5rem;
            }
          }
        }
        .head__wrap{
          @extend .head__wrap;
          color:$p-pink;
          @include media-breakpoint-down(md) {
            margin-bottom: 5rem;
          }
        }
        .point__lists{
          position: relative;
          li{
            position:static;
            @include media-breakpoint-down(md) {
              margin-bottom: 3rem;
              // padding-left: 0;
              // padding-right: 0;
            }
            @include media-breakpoint-up(lg) {
              &:nth-of-type(even){
                margin-top: 2rem;
              }
              &:nth-of-type(1){
                .click__content{
                  &:after{
                    left:10%;
                  }
                }
              }
              &:nth-of-type(2){
                .click__content{
                  &:after{
                    left:36%;
                  }
                }
              }
              &:nth-of-type(3){}
              .click__content{
                &:after{
                  left:61%;
                }
              }
              &:nth-of-type(4){
                .click__content{
                  &:after{
                    left:86%;
                  }
                }
              }
            }
            &.point__content--01{
              .click__content{
                background-color: #FAD0D5;
                &:after{
                  border-color:transparent transparent #FAD0D5 transparent;
                }
              }
              figcaption{
                background:linear-gradient(transparent 60%, #FAD0D5 60%);
              }
              button{
                &.is_active{
                  img{
                    border: 10px solid #FAD0D5;
                  }
                }
              }
              .label{
                background-color: #FAD0D5;
              }
            }
            &.point__content--02{
              .click__content{
                background-color: #B6E3E2;
                &:after{
                  border-color:transparent transparent #B6E3E2 transparent;
                }
              }
              figcaption{
                background:linear-gradient(transparent 60%, #B6E3E2 60%);
              }
              button{
                &.is_active{
                  img{
                    border: 10px solid #B6E3E2;
                  }
                }
              }
              .label{
                background-color: #B6E3E2;
              }
            }
            &.point__content--03{
              .click__content{
                background-color: #FDDE88;
                &:after{
                  border-color:transparent transparent #FDDE88 transparent;
                }
              }
              figcaption{
                background:linear-gradient(transparent 60%, #FDDE88 60%);
              }
              button{
                &.is_active{
                  img{
                    border: 10px solid #FDDE88;
                  }
                }
              }
              .label{
                background-color: #FDDE88;
              }
            }
            &.point__content--04{
              .click__content{
                background-color: #C3E6A4;
                &:after{
                  border-color:transparent transparent #C3E6A4 transparent;
                }
              }
              figcaption{
                background:linear-gradient(transparent 60%, #C3E6A4 60%);
              }
              button{
                &.is_active{
                  img{
                    border: 10px solid #C3E6A4;
                  }
                }
              }
              .label{
                background-color: #C3E6A4;
              }
            }
          }
        }
        figure{
          text-align: center;
          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
          button{
            background-color: transparent;
            border:none;
            outline:none;
            position: relative;
            @include opacity();
            @include media-breakpoint-down(md) {
              margin: auto;
            }
            img{
              border-radius: 50%;
            }
            .label{
              position: absolute;
              left:0;
              top: 0;
              // background-color: $p-yellow;
              // display: inline-block;
              width: 66px;
              height: 66px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transform: rotate(-5deg);
            }
            &.is_active{
              img{
                // border: 10px solid $p-yellow;
              }
              .label{
                color:$red;
              }
            }
          }
          figcaption{
            // font-size: rem-calc(18);
            // font-size: rem-calc(15);
            
          }
          .click__content{
            opacity: 0;
            visibility: hidden;
            transform:translateY(-30px);
            // background-color: $p-yellow;
            width: 100%;
            font-weight: bold;
            font-size: rem-calc(18);
            text-align: left;
            border-radius: 30px;
            padding:2rem;
            @include media-breakpoint-up(lg) {
              position: absolute;
              left: 0;
              top: 110%;
            }
            @include media-breakpoint-down(md) {
              height: 0;
              margin-top: 2rem;
              padding:0 2rem;
              font-size: rem-calc(16);
            }
            &:after{
              content: '';
              display: block;
              position: absolute;
              bottom: 100%;
              border-width:0 15px 40px;
              border-style:solid;
              @include media-breakpoint-down(md) {
                left: calc(50% - 15px);
                border-width:0 10px 20px;
              }
            }
            &.is_active{
              visibility: visible;
              opacity: 1;
              transform:translateY(0);
              transition:.2s;
              @include media-breakpoint-down(md) {
                padding:2rem;
                height: 100%;
              }
            }
          }
        }
      }
      .section__03{
        
        .head__wrap{
          @extend .head__wrap;
          color:$p-pink;
          @include media-breakpoint-down(sm) {
            margin-bottom: 5rem;
          }
          h2{
            &:before{
              background: url(../img/lp/oc/img_section3_pink-star.svg)no-repeat;
              background-size: cover;
            }
            &:after{
              background: url(../img/lp/oc/img_section3_pink-star.svg)no-repeat;
              background-size: cover;
            }
          }
        }
        &+ .inner__container{
          margin-bottom: 6.2rem;
          @include media-breakpoint-down(md){
            margin-bottom: 4.2rem;
          }
        }
        .inner__container{
          background-color: #fff;
          border-radius: 20px;
          padding-top:4rem;
          padding-bottom:8rem;
          margin-bottom: 10rem;
          position: relative;
          @media(max-width:767.98px) {
            margin-bottom: 20px;
          }
          @include media-breakpoint-down(sm) {
            padding-bottom: 2rem;
          }
          @include media-breakpoint-up(md) {
            &:after{
              content: '';
              display: block;
              position: absolute;
              right: -30px;
              top: -30px;
              background: url(../img/lp/oc/img_section3_triangle.svg)no-repeat;
              background-size: cover;
              width:127px;
              height: 127px;
            }
            &:before{
              left: -170px;
              bottom: -150px;
            }
          }
          &.is_scrolled{
            .ch--01,
            .ch--03{
              transition:.5s;
              transform: translate(0);
            }
          }
        }
        .ch--01{
          position: absolute;
          left:14%;
          bottom: 100%;
          transform: translate(210px, 0px);
          @include media-breakpoint-down(sm) {
            width: 20%;
            max-width:100px
          }
        }
        .ch--02{
          position: absolute;
          left:0;
          right: 0;
          bottom: 100%;
          margin: auto;
          z-index: 10;
          @include media-breakpoint-down(sm) {
            width: 20%;
            max-width:100px
          }
        }
        .ch--03{
          position: absolute;
          right:14%;
          bottom: 100%;
          transform: translate(-190px, 0px);
          @include media-breakpoint-down(sm) {
            width: 20%;
            max-width:100px
          }
        }
        .merit-item{
          &.is_scrolled{
            .merit_ch{
              animation:ch_jump .4s forwards;
              @keyframes ch_jump {
                0%{
                  transform: translateY(0);
                }
                50%{
                  transform: translateY(-40px);
                }
                100%{
                  transform: translateY(0);
                }
              }
            }
          }
        }
        .merit_ch{
          margin: auto;
          @include media-breakpoint-up(md) {
            position: absolute;
            top: 110%;
            right: 0;
            left: 0;
          }
          @include media-breakpoint-down(sm) {
            max-width:90px;
            width: 24%;
            transform: translateY(-20%);
            margin-bottom: 1rem;
          }
        }
      }
      .section__05{
        margin-bottom: 5rem;
        // margin-top:13rem;
        margin-top:6rem;
        @media(max-width:767.98px){
          margin-top:60px;
        }
        .head__wrap{

          &-text{
            p{
              font-size: 18px;
              letter-spacing: ls(100);
              line-height: lh(35,18);
              margin: 3.2rem 0 3.8rem;
              color: #000;
              @include media-breakpoint-down(md){
                font-size: 14px;
                margin: 2.2rem 0 3.2rem;
              }
            }

          }
        }
      }
      .section__06{
        margin-bottom: 5rem;
        .number{
          .head{
            background-color: $teal;
          }
          .contents{
            color:$teal;
            background-color: #E2F9F9;
          }
          .current-num{
            color:$teal;
            border:3px solid $teal;
          }
        }
        &:not(:last-of-type){
          .number{
            &:after{
              background: url(../img/lp/oc/img_section6_step01.svg)no-repeat;
              // background-size: 220px 35px;
              background-position: 0 0;
            }
          }
        }
      }
      .section__07{
        // margin-bottom: 8rem;
        .container{
          padding-top: 4rem;
          // padding-top: 4rem;
          // position: relative;
          // background-color: #fff;
          background: #fff url(../img/lp/oc/img_section7_bg01.png)no-repeat;
          background-position: center bottom;
          background-size: 100%;
          // padding-left: 0;
          // padding-right: 0;
          @include media-breakpoint-down(md) {
            background: #fff url(../img/lp/oc/img_section7_bg01_sp.png)no-repeat;
            background-size: 100%;
            background-position: center bottom;
            // background-size: cover;
          }
        }
        
        .content{
          // padding-bottom: 80px;
        }
        .item01{
          position: absolute;
          left: 0;
          top: 20px;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        li{
          .tab-btn2{
            &.tab-btn--01{
              border-color:$p-pink;
              color:$white;
              background-color: $p-pink;
            }
            &.tab-btn--02,
            &.tab-btn--03{
              border-color:$p-orange;
              color:$white;
              background-color: $p-orange;
            }
            &.tab-btn--04,
            &.tab-btn--05,
            &.tab-btn--06{
              border-color:$c-hukusi;
              color:$white;
              background-color: $c-hukusi;
            }
          }
          &.is_active{
            .tab-btn2{
              &.tab-btn--01{
                color:$p-pink;
                background-color: $white;
              }
              &.tab-btn--02,
              &.tab-btn--03{
                color:$p-orange;
                background-color: $white;
              }
              &.tab-btn--04,
              &.tab-btn--05,
              &.tab-btn--06{
                color:$c-hukusi;
                background-color: $white;
              }
            }
          }
        }
      }
      .section__08{
        h2{
          // background-color: $teal;
          // &:after{
          //   border-color:$teal transparent transparent;
          // }
        }
        .inner__container{
          padding-bottom:80px;
          @media(max-width:1024.98px){
            padding-bottom:0;
          }
        }
      }
      .section__10{
        .container{
          // background: rgba($teal,.2);
        }
        .text{
          color:$teal;
        }
      }
      .section__13{
        h2{
          background:$teal;
        }
      }
    }
    &.child-page{
      .section__01{
        margin-top:12rem;
        @include media-breakpoint-up(lg) {
          margin-top: 5rem;
        }
        @include media-breakpoint-up(xl) {
          margin-top: 9rem;
        }
       
        h2{
          top:-7.5rem;
          @include media-breakpoint-up(md) {
            top:-9rem;
          }
          @include media-breakpoint-up(lg) {
            top:-2rem;
          }
          @include media-breakpoint-up(xl) {
            top:-5.5rem;
          }
        }
        .first__slide__wrap{
          @include media-breakpoint-up(lg) {
            margin-top:7rem;
            max-width:730px;
          }
          @include media-breakpoint-up(xl) {
            margin-top:0;
          }
        }
        .text__block{
          line-height: lh(30,14);
          @include media-breakpoint-up(lg) {
            line-height: lh(35,20);
            font-size: rem-calc(20);
            margin-top:7rem;
          }
          @include media-breakpoint-up(xl) {
            margin-top:3rem;
          }

          .banner__doc{
            @include media-breakpoint-up(lg) {
              margin-top:3rem;
            }
            @include media-breakpoint-up(xl) {
              margin-top:6rem;
            }
          }
        }
      }
      .section__10{
         .section-heading{
          font-weight: bold;
          display: inline-block;
          letter-spacing: 2px;
          font-size: rem-calc(20);
          position: relative;
          @include media-breakpoint-up(md) {
            font-size: rem-calc(30);
          }
          @include media-breakpoint-up(lg) {
            font-size: rem-calc(35);
            padding:.5rem 2rem;
            display: block;
            margin-bottom: rem(25);
            &:after{
              content: '';
              display: block;
              background-color: rgba(#FCF25D,.7);
              position: absolute;
              left: 0;
              top: 0;
              width: 0;
              height: 100%;
              z-index: -1;
            }
          }
          span{
            position: relative;
            @include media-breakpoint-down(md) {
              padding:.5rem;
              margin-bottom: 5px;
              &:after{
                content: '';
                display: block;
                background-color: rgba(#FCF25D,.7);
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                z-index: -1;
              }
            }
          }
          &.is_scrolled{
            &:after{
              transition:.5s;
              width: 100%;
            }
            span{
              &:after{
                transition:.5s;
                width: 100%;
              }
            }
          }
        }
      }
    }
    &.hsc{
      $school-color:$c-hair;
      .p-form__btn__wrap{
        .p-next-btn__wrap{
          .submit-btn{
            &.is_agree{
              background-color: $school-color;
              &:hover{
                background-color: darken($school-color,10%);
              }
            }
          }
          button{
            &:hover{
              background: darken($school-color,5%);
            }
          }
        }
      }
      .head__wrap{
        &:not(.not){
          color:$school-color;
        }
      }
      .ex__wrap{
        margin-bottom: 3rem;
        .text-cyaan{
          color: #499cac;
        }
        P{
          text-align: center;
          font-size: 1.1rem;
        }
        @include media-breakpoint-down(md) {
          p{
            text-align: start;
            font-size: 1rem;
          }
          br{
            display: none;
          }
        }
      }
      .hero{
        @include media-breakpoint-down(sm) {
          margin-bottom: 3rem;
        }
        img{
          width:100%;
        }
      }
      .section__01{
        // @include media-breakpoint-up(md) {
        //   margin-top: 9rem;
        // }
        // @include media-breakpoint-up(lg) {
        //   margin-top: 4rem;
        // }
        // margin-bottom: rem(105);
        // .section-heading{
          // font-weight: bold;
          // color:$school-color;
          // display: inline-block;
          // letter-spacing: 2px;
          // font-size: rem-calc(20);
          // position: relative;
          // @include media-breakpoint-up(md) {
          //   font-size: rem-calc(30);
          // }
          // @include media-breakpoint-up(lg) {
          //   font-size: rem-calc(35);
          //   padding:.5rem 2rem;
          //   display: block;
          //   margin-bottom: rem(25);
          //   &:after{
          //     content: '';
          //     display: block;
          //     background-color: rgba(#FCF25D,.7);
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     width: 0;
          //     height: 100%;
          //     z-index: -1;
          //   }
          // }
          // span{
          //   position: relative;
          //   @include media-breakpoint-down(md) {
          //     padding:.5rem;
          //     margin-bottom: 5px;
          //     &:after{
          //       content: '';
          //       display: block;
          //       background-color: rgba(#FCF25D,.7);
          //       position: absolute;
          //       left: 0;
          //       top: 0;
          //       width: 0;
          //       height: 100%;
          //       z-index: -1;
          //     }
          //   }
          // }
          // &.is_scrolled{
          //   &:after{
          //     transition:.5s;
          //     width: 100%;
          //   }
          //   span{
          //     &:after{
          //       transition:.5s;
          //       width: 100%;
          //     }
          //   }
          // }
        // }
        .first__slide__wrap{
          @include media-breakpoint-up(lg) {
            margin-top:7rem;
          }
          @include media-breakpoint-up(xl) {
            margin-top:0;
          }
        }
        .text__block{
          line-height: lh(30,14);
          @include media-breakpoint-up(lg) {
            line-height: lh(35,20);
            font-size: rem-calc(20);
          }

          .banner__doc{
            @include media-breakpoint-up(lg) {
              margin-top:3rem;
            }
          }
        }
      }
      .section__03{
        padding-top:rem(95);
        padding-bottom:rem(95);
        background: repeating-linear-gradient(-45deg,#EE7EB2,#EE7EB2 10px,#EE86B6 0,#EE86B6 20px);
        .head__wrap{
          @extend .head__wrap;
          // color:$school-color;
          h2{
            &:before,
            &:after{
              background: url(../img/lp/oc/hsc/img_section3_star.svg)no-repeat;
              background-size: cover;
            }
          }
        }
        .outer__container{
          position: relative;
          margin: auto;
          max-width: 1020px;
          &::before{
            content: "";
            display: block;
            position: absolute;
            left: -160px;
            bottom: -120px;
            background: url(../img/lp/oc/img_section3_dots.svg) no-repeat;
            background-size: cover;
            width: 197px;
            height: 200px;
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            right: -30px;
            top: 30px;
            width: 127px;
            height: 127px;
            background: url(../img/lp/oc/hsc/img_section3_triangle.svg) no-repeat;
            background-size: cover;
            z-index: 0;
          }
          @include media-breakpoint-down(md) {
            &::before,
            &::after{
              display: none;
            }
          }
          .inner__container{
            margin-bottom: 0;
            padding-bottom: 3rem;
            z-index: 1;
            &::before,
            &::after{
              display: none;
            }
          }
          .merit_ch{
            &.merit_ch_top{
              bottom: 80%;
              @include media-breakpoint-down(md) {
                top: -50px;
                bottom: auto;
                display: block;
                width: 74px;
              }
            }
            &.merit_ch--04{
              right: 68%;
            }
            &.merit_ch--05{
              right: calc(50% - (127px / 2));
              @include media-breakpoint-down(md) {
                right: calc(50% - (74px / 2));
              }
            }
            &.merit_ch--06{
              right: 20%;
              bottom: 90%;
              @include media-breakpoint-down(md) {
                bottom: -60px;
              }
              @include media-breakpoint-down(xs) {
                right: 10%;
              }
            }
          }
          .merit-item{
            @include media-breakpoint-down(md) {
              padding-bottom: 6rem;
            }
            .merit_ch{
              bottom: auto;
              top: calc(100% + 20px);
              right: calc(50% - (127px / 2));
              @include media-breakpoint-down(md) {
                top: auto;
                bottom: 5%;
                right: calc(50% - (74px / 2));
                display: block;
                width: 74px;
              }
            }
          }
        }
        .inner__container{
          margin-bottom: 0;
          padding-bottom: 3rem;
          @include media-breakpoint-up(md) {
            &:after{
              background: url(../img/lp/oc/hsc/img_section3_triangle.svg)no-repeat;
              background-size: cover;
            }
            &:before{
              left: -160px;
              bottom: -60px;
            }
          }
        }
        .merit_ch{
          position: absolute;
          bottom: -19%;
          right: 0;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
      .section__05{
        @media(max-width:767.98px){
          padding-top:60px;
        }
      }
      .section__06{
        .number{
          .head{
            background-color: $school-color;
          }
          .contents{
            color:$school-color;
            background-color: #FFEFF6;
          }
          .current-num{
            color:$school-color;
            border:3px solid $school-color;
          }
        }
        li{
          position: relative;
          @include media-breakpoint-up(lg) {
            &:nth-of-type(odd){
              float:left;
            }
            &:nth-of-type(even){
              float:right;
            }
          }
          &:not(:last-of-type){
            .number{
              &:after{
                background: url(../img/lp/oc/hsc/img_section6_step01.svg)no-repeat;
                // background-size: 220px 35px;
                background-position: 0 0;
              }
            }
          }
        }
      }
      .section__07{
        background-color: $school-color;
        .head__wrap{
          color:white;
          &:after{
            background: transparent;
          }
        }
        .experience__item{
          position: absolute;
          &.experience__item--01{
            top: -2rem;
            left: 0;
            transform: translateX(-60%);
            // @include media-breakpoint-up(lg) {
            //   top: -2rem;
            // }
            @include media-breakpoint-down(sm) {
              width: 70%;
              top: -4rem;
            }
          }
          &.experience__item--02{
            width: 50%;
            left: -3%;
            bottom: -27%;
            transform: translateX(0%);
            @include media-breakpoint-up(md) {
              width: auto;
              left: 0;
              bottom: -2rem;
              transform: translateX(-50%);
            }
            @include media-breakpoint-down(sm) {
              max-width:37%;
            }
          }
          &.experience__item--03{
            top: -5rem;
            right: 0;
            transform: translateX(10%);
            width: 60%;
            @include media-breakpoint-up(md) {
              top: -11rem;
              transform: translateX(36%);
              width: auto;
            }
            @include media-breakpoint-down(sm) {
              max-width: 210px;
            }
          }
          &.experience__item--04{
            // right:0;
            // width: 90%;
            // bottom: 0;
            // transform: translateX(70%);
            // @include media-breakpoint-up(md) {
            //   transform: translateX(40%);
            //   width: auto;
            // }
            right: 0;
            width: 90%;
            bottom: -26%;
            transform: translateX(0%);
            width: 50%;
            @include media-breakpoint-up(md) {
              width: auto;
              bottom: 0;
              transform: translateX(40%);
            }
            @include media-breakpoint-down(sm) {
              max-width: 40%;
            }
          }
        }
      }
      .section__08{
        // h2{
        //   background-color: $school-color;
        //   &:after{
        //     border-color:$school-color transparent transparent;
        //   }
        // }
        .inner__container{
          padding-bottom:0;
        }
        .ch01{
          @media(max-width:591.98px){
            width:53%;
            top:15%;
          }
        }
        .ch02{
          @media(max-width:591.98px){
            width:42%;
          }
        }
        .ch03{
          
          @media(max-width:1024.98px){
            bottom:5%;
          }
          @media(max-width:767.98px){
            bottom:-3%;     
          }
          @media(max-width:591.98px){
            width:40%;
            // bottom: 17%;
          }
        }
    }
      .section__10{
        .container{
          // background: rgba($school-color,.2);
        }
        .text{
          color:$school-color;
        }
        .section-heading{
          color:$school-color;
        }
      }
      .section__13{
        h2{
          background:$school-color;
        }
      }
    }
    &.htc{
      $school-color:$c-cook;
      .p-form__btn__wrap{
        .p-next-btn__wrap{
          .submit-btn{
            &.is_agree{
              background-color: $school-color;
              &:hover{
                background-color: darken($school-color,10%);
              }
            }
          }
          button{
            &:hover{
              background: darken($school-color,5%);
            }
          }
        }
      }
      .head__wrap{
        &:not(.not){
          color:$school-color;
        }
      }
      .ex__wrap{
        margin-bottom: 3rem;
        .text-cyaan{
          color: #499cac;
        }
        P{
          text-align: center;
          font-size: 1.1rem;
        }
        @include media-breakpoint-down(md) {
          p{
            text-align: start;
            font-size: 1rem;
          }
          br{
            display: none;
          }
        }
      }
      .hero{
        @include media-breakpoint-down(sm) {
          margin-bottom: 3rem;
        }
        width:100%;
        img{
          width:100%;
        }
      }
      .section__01{
        margin-bottom: rem(105);
        // .section-heading{
        //   font-weight: bold;
        //   color:$school-color;
        //   display: inline-block;
        //   letter-spacing: 2px;
        //   font-size: rem-calc(20);
        //   position: relative;
        //   @include media-breakpoint-up(md) {
        //     font-size: rem-calc(30);
        //   }
        //   @include media-breakpoint-up(lg) {
        //     font-size: rem-calc(35);
        //     padding:.5rem 2rem;
        //     display: block;
        //     margin-bottom: rem(25);
        //     &:after{
        //       content: '';
        //       display: block;
        //       background-color: rgba(#FCF25D,.7);
        //       position: absolute;
        //       left: 0;
        //       top: 0;
        //       width: 0;
        //       height: 100%;
        //       z-index: -1;
        //     }
        //   }
        //   span{
        //     position: relative;
        //     @include media-breakpoint-down(md) {
        //       padding:.5rem;
        //       margin-bottom: 5px;
        //       &:after{
        //         content: '';
        //         display: block;
        //         background-color: rgba(#FCF25D,.7);
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         width: 0;
        //         height: 100%;
        //         z-index: -1;
        //       }
        //     }
        //   }
        //   &.is_scrolled{
        //     &:after{
        //       transition:.5s;
        //       width: 100%;
        //     }
        //     span{
        //       &:after{
        //         transition:.5s;
        //         width: 100%;
        //       }
        //     }
        //   }
        // }
        .text__block{
          line-height: lh(30,14);
          @include media-breakpoint-up(lg) {
            line-height: lh(35,20);
            font-size: rem-calc(20);
          }
        }
      }
      .section__03{
        padding-top:rem(95);
        padding-bottom:rem(95);
        background: repeating-linear-gradient(-45deg,#FE974A,#FE974A 10px,#FE9F4E 0,#FE9F4E 20px);
        .head__wrap{
          @extend .head__wrap;
          // color:$school-color;
          h2{
            &:before,
            &:after{
              background: url(../img/lp/oc/htc/img_section3_star.svg)no-repeat;
              background-size: cover;
            }
          }
        }
        .outer__container{
          position: relative;
          margin: auto;
          max-width: 1020px;
          &::before{
            content: "";
            display: block;
            position: absolute;
            left: -160px;
            bottom: -120px;
            background: url(../img/lp/oc/img_section3_dots.svg) no-repeat;
            background-size: cover;
            width: 197px;
            height: 200px;
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            right: -30px;
            top: 30px;
            width: 127px;
            height: 127px;
            background: url(../img/lp/oc/htc/img_section3_triangle.svg) no-repeat;
            background-size: cover;
            z-index: 0;
          }
          @include media-breakpoint-down(md) {
            &::before,
            &::after{
              display: none;
            }
          }
          .inner__container{
            margin-bottom: 0;
            padding-bottom: 3rem;
            z-index: 1;
            &::before,
            &::after{
              display: none;
            }
          }
          .merit_ch{
            position: absolute;
            bottom: -19%;
            z-index: 0;
            &.merit_ch_top{
              bottom: 80%;
              @include media-breakpoint-down(md) {
                top: -50px;
                bottom: auto;
                display: block;
                width: 74px;
              }
            }
            &.merit_ch--04{
              right: 68%;
            }
            &.merit_ch--05{
              right: calc(50% - (127px / 2));
              @include media-breakpoint-down(md) {
                right: calc(50% - (74px / 2));
              }
            }
            &.merit_ch--06{
              right: 20%;
              bottom: 90%;
              @include media-breakpoint-down(md) {
                bottom: -60px;
              }
              @include media-breakpoint-down(xs) {
                right: 10%;
              }
            }
          }
          .merit-item{
            @include media-breakpoint-down(md) {
              padding-bottom: 6rem;
            }
            .merit_ch{
              bottom: auto;
              top: calc(100% + 20px);
              right: calc(50% - (127px / 2));
              left: auto;
              @include media-breakpoint-down(md) {
                top: auto;
                bottom: 5%;
                right: calc(50% - (74px / 2));
                display: block;
                width: 74px;
              }
            }
          }
        }
        .inner__container{
          margin-bottom: 0;
          padding-bottom: 3rem;
          @include media-breakpoint-up(md) {
            &:after{
              background: url(../img/lp/oc/htc/img_section3_triangle.svg)no-repeat;
              background-size: cover;
            }
            &:before{
              left: -160px;
              bottom: -60px;
            }
          }
        }
        .merit_ch{
          position: absolute;
          bottom: -19%;
          z-index: 10;
          &.merit_ch--01{
            left: 31%;
          }
          &.merit_ch--02{
            right: 31%;
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
      .section__05{
        @media(max-width:767.98px){
          padding-top:60px;
        }
      }
      .section__06{
        .number{
          .head{
            background-color: $school-color;
          }
          .contents{
            color:$school-color;
            background-color: #FFF5EF;
          }
          .current-num{
            color:$school-color;
            border:3px solid $school-color;
          }
        }
        .piano{
          max-width:none;
          width:380px;
          @include media-breakpoint-down(md) {
            width:50%;
            position:static;
            display: block;
            margin-left: auto;
          }
        }
        li{
          position: relative;
          @include media-breakpoint-up(lg) {
            &:nth-of-type(odd){
              float:left;
            }
            &:nth-of-type(even){
              float:right;
            }
          }
          &:not(:last-of-type){
            .number{
              &:after{
                background: url(../img/lp/oc/htc/img_section6_step01.svg)no-repeat;
                // background-size: 220px 35px;
                background-position: 0 0;
              }
            }
          }
        }
      }
      .section__07{
        background-color: $school-color;
        .head__wrap{
          color:white;
          &:after{
            background: transparent;
          }
        }
        .experience__item{
          position: absolute;
          &.experience__item--01{
            top: -8.5rem;
            left: -0.5rem;
            transform: translateX(-10%);
            width: 40%;
            @include media-breakpoint-up(lg) {
              width: auto;
              transform: translateX(-20%);
              left: 0;
              top: -6rem;
            }
          }
          &.experience__item--02{
            width: 50%;
            left: -3%;
            bottom: -27%;
            transform: translateX(0%);
            @include media-breakpoint-up(lg) {
              width: auto;
              left: 0;
              bottom: -5rem;
              transform: translateX(-35%);
            }
          }
          &.experience__item--03{
            top: 4rem;
            right: 0;
            transform: translateX(10%);
            width: 40%;
            @include media-breakpoint-up(lg) {
              top: -1rem;
              transform: translateX(30%);
              width: auto;
            }
          }
          &.experience__item--04{
            // right:0;
            // width: 90%;
            // bottom: 0;
            // transform: translateX(70%);
            // @include media-breakpoint-up(md) {
            //   transform: translateX(40%);
            //   width: auto;
            // }
            right: 0;
            bottom: 9%;
            transform: translateX(0);
            width: 30%;
            @include media-breakpoint-up(md) {
              width: auto;
              bottom: 0;
              transform: translateX(30%);
            }
          }
        }
        li{
          .tab-btn2{
            // &.tab-btn--01{
            //   border-color:$p-pink;
            //   color:$white;
            //   background-color: $p-pink;
            //   }
            &.tab-btn--02,
            &.tab-btn--03{
              border-color:$white;
              color:$white;
              background-color: $school-color;
              }
            // &.tab-btn--04,
            // &.tab-btn--05,
            // &.tab-btn--06{
            //   border-color:$c-hukusi;
            //   color:$white;
            //   background-color: $c-hukusi;
            //   }
          }
          &.is_active{
            .tab-btn2{
              // .tab-btn--01{
              //   color:$p-pink;
              //   background-color: $white;
              // }
              &.tab-btn--02,
              &.tab-btn--03{
                color:$school-color;
                background-color: $white;
              }
              // &.tab-btn--04,
              // &.tab-btn--05,
              // &.tab-btn--06{
              //   color:$c-hukusi;
              //   background-color: $white;
              // }
            }
          }
        }
      }
      .section__08{
        // h2{
        //   background-color: $school-color;
        //   &:after{
        //     border-color:$school-color transparent transparent;
        //   }
        // }
        .inner__container{
          padding-bottom:0;
        }
        .ch01{
          @media(max-width:591.98px){
            width:53%;
            top:15%;
          }
        }
        .ch02{
          @media(max-width:591.98px){
            width:42%;
          }
        }
        .ch03{
          
          @media(max-width:1024.98px){
            bottom:5%;
          }
          @media(max-width:767.98px){
            bottom:-3%;     
          }
          @media(max-width:591.98px){
            width:40%;
            // bottom: 17%;
          }
        }
      }
      .section__10{
        .container{
          // background: rgba($school-color,.2);
        }
        .text{
          color:$school-color;
        }
        .section-heading{
          color:$school-color;
        }
      }
      .section__13{
        h2{
          background:$school-color;
        }
      }
    }
    &.iryo{
      $school-color:$c-hukusi;
      .p-form__btn__wrap{
        .p-next-btn__wrap{
          .submit-btn{
            &.is_agree{
              background-color: $school-color;
              &:hover{
                background-color: darken($school-color,10%);
              }
            }
          }
          button{
            &:hover{
              background: darken($school-color,5%);
            }
          }
        }
      }
      .head__wrap{
        &:not(.not){
          color:$school-color;
        }
      }
      .hero{
        @include media-breakpoint-down(sm) {
          margin-bottom: 3rem;
        }
        width:100%;
        img{
          width:100%;
        }
      }
      .section__01{
        margin-bottom: rem(105);
        // .section-heading{
        //   font-weight: bold;
        //   color:$school-color;
        //   display: inline-block;
        //   letter-spacing: 2px;
        //   font-size: rem-calc(20);
        //   position: relative;
        //   @include media-breakpoint-up(md) {
        //     font-size: rem-calc(30);
        //   }
        //   @include media-breakpoint-up(lg) {
        //     font-size: rem-calc(35);
        //     padding:.5rem 2rem;
        //     display: block;
        //     margin-bottom: rem(25);
        //     &:after{
        //       content: '';
        //       display: block;
        //       background-color: rgba(#FCF25D,.7);
        //       position: absolute;
        //       left: 0;
        //       top: 0;
        //       width: 0;
        //       height: 100%;
        //       z-index: -1;
        //     }
        //   }
        //   span{
        //     position: relative;
        //     @include media-breakpoint-down(md) {
        //       padding:.5rem;
        //       margin-bottom: 5px;
        //       &:after{
        //         content: '';
        //         display: block;
        //         background-color: rgba(#FCF25D,.7);
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         width: 0;
        //         height: 100%;
        //         z-index: -1;
        //       }
        //     }
        //   }
        //   &.is_scrolled{
        //     &:after{
        //       transition:.5s;
        //       width: 100%;
        //     }
        //     span{
        //       &:after{
        //         transition:.5s;
        //         width: 100%;
        //       }
        //     }
        //   }
        // }
        .text__block{
          line-height: lh(30,14);
          @include media-breakpoint-up(lg) {
            line-height: lh(35,20);
            font-size: rem-calc(20);
          }
        }
      }
      .section__03{
        padding-top:rem(95);
        padding-bottom:rem(95);
        background: repeating-linear-gradient(-45deg,#7BBCA7,#7BBCA7 10px,#7BC3AB 0,#7BC3AB 20px);
        .head__wrap{
          @extend .head__wrap;
          // color:$school-color;
          h2{
            &:before,
            &:after{
              background: url(../img/lp/oc/iryo/img_section3_star.svg)no-repeat;
              background-size: cover;
            }
          }
        }
        .outer__container{
          position: relative;
          margin: auto;
          max-width: 1020px;
          &::before{
            content: "";
            display: block;
            position: absolute;
            left: -160px;
            bottom: -120px;
            background: url(../img/lp/oc/img_section3_dots.svg) no-repeat;
            background-size: cover;
            width: 197px;
            height: 200px;
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            right: -30px;
            top: 30px;
            width: 127px;
            height: 127px;
            background: url(../img/lp/oc/iryo/img_section3_triangle.svg) no-repeat;
            background-size: cover;
            z-index: 0;
          }
          .f-century{
            color: $c-hukusi;
          }
          .inner__container{
            margin-bottom: 0;
            padding-bottom: 3rem;
            z-index: 1;
            &::before,
            &::after{
              display: none;
            }
          }
          .merit_ch{
            position: absolute;
            bottom: -19%;
            z-index: 0;
            &.merit_ch_top{
              bottom: 80%;
              @include media-breakpoint-down(md) {
                top: -50px;
                bottom: auto;
                display: block;
                width: 74px;
              }
            }
            &.merit_ch--04{
              right: 68%;
            }
            &.merit_ch--05{
              right: calc(50% - (127px / 2));
              @include media-breakpoint-down(md) {
                right: calc(50% - (74px / 2));
              }
            }
            &.merit_ch--06{
              right: 20%;
              bottom: 90%;
              @include media-breakpoint-down(md) {
                bottom: -60px;
              }
              @include media-breakpoint-down(xs) {
                right: 10%;
              }
            }
          }
          .merit-item{
            @include media-breakpoint-down(md) {
              padding-bottom: 6rem;
            }
            .merit_ch{
              bottom: auto;
              top: calc(100% + 20px);
              right: calc(50% - (127px / 2));
              left: auto;
              @include media-breakpoint-down(md) {
                top: auto;
                bottom: 5%;
                right: calc(50% - (74px / 2));
                display: block;
                width: 74px;
              }
            }
          }

        }
        .inner__container{
          margin-bottom: 0;
          padding-bottom: 3rem;
          @include media-breakpoint-up(md) {
            &:after{
              background: url(../img/lp/oc/iryo/img_section3_triangle.svg)no-repeat;
              background-size: cover;
            }
            &:before{
              left: -160px;
              bottom: -60px;
            }
          }
        }
        .merit_ch{
          position: absolute;
          bottom: -19%;
          z-index: 10;
          &.merit_ch--01{
            left: 20%;
          }
          &.merit_ch--02{
            left: 44%;
          }
          &.merit_ch--03{
            right: 18%;
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
      .section__05{
        @media(max-width:767.98px){
          padding-top:60px;
        }
      }
      .section__06{
        .number{
          .head{
            background-color: $school-color;
          }
          .contents{
            color:$school-color;
            background-color: #F3FFEF;
          }
          .current-num{
            color:$school-color;
            border:3px solid $school-color;
          }
        }
        li{
          position: relative;
          @include media-breakpoint-up(lg) {
            &:nth-of-type(odd){
              float:left;
            }
            &:nth-of-type(even){
              float:right;
            }
          }
          &:not(:last-of-type){
            .number{
              &:after{
                background: url(../img/lp/oc/iryo/img_section6_step01.svg)no-repeat;
                // background-size: 220px 35px;
                background-position: 0 0;
              }
            }
          }
        }
        .schedule__wrap{
          & > .item01{
            right: 0;
            @include media-breakpoint-down(md) {
              top: -170px;
              max-width:200px;
            }
          }
        }
      }
      .section__07{
        background-color: $school-color;
        .head__wrap{
          color:white;
          &:after{
            background: transparent;
          }
        }
        .experience__item{
          position: absolute;
          &.experience__item--01{
            top: -10rem;
            left: 2rem;
            transform: translateX(-10%);
            width: 35%;
            @include media-breakpoint-down(md) {
              max-width: 160px;
            }
            @include media-breakpoint-up(lg) {
              width: auto;
              transform: translateX(-20%);
              top: -4rem;
              left: 0;
            }
          }
          &.experience__item--02{
            width: 40%;
            left: 1%;
            bottom: -18%;
            transform: translateX(0);
            @include media-breakpoint-down(md) {
              max-width: 160px;
            }
            @include media-breakpoint-up(lg) {
              width: auto;
              left: 2rem;
              bottom: -5rem;
              transform: translateX(-35%);
            }
          }
          &.experience__item--03{
            top: 4rem;
            right: 0;
            transform: translateX(10%);
            width: 40%;
            display: none;
            @include media-breakpoint-up(lg) {
              top: -5rem;
              transform: translateX(20%);
              width: auto;
              display: inline-block;
            }
          }
        }
        li{
          .tab-btn2{
            // &.tab-btn--01{
            //   border-color:$p-pink;
            //   color:$white;
            //   background-color: $p-pink;
            //   }
            // &.tab-btn--02,
            // &.tab-btn--03{
            //   border-color:$white;
            //   color:$white;
            //   background-color: $school-color;
            // }
            &.tab-btn--04,
            &.tab-btn--05,
            &.tab-btn--06{
              border-color:$white;
              color:$white;
              background-color: $c-hukusi;
              }
          }
          &.is_active{
            .tab-btn2{
              // .tab-btn--01{
              //   color:$p-pink;
              //   background-color: $white;
              // }
              // &.tab-btn--02,
              // &.tab-btn--03{
              //   color:$school-color;
              //   background-color: $white;
              // }
              &.tab-btn--04,
              &.tab-btn--05,
              &.tab-btn--06{
                color:$c-hukusi;
                background-color: $white;
              }
            }
          }
        }
      }
      .section__08{
        // h2{
        //   background-color: $school-color;
        //   &:after{
        //     border-color:$school-color transparent transparent;
        //   }
        // }
        .inner__container{
          padding-bottom:0;
        }
        .ch01{
          @media(max-width:591.98px){
            width:53%;
            top:15%;
          }
        }
        .ch02{
          @media(max-width:591.98px){
            width:42%;
          }
        }
        .ch03{
          
          @media(max-width:1024.98px){
            bottom:5%;
          }
          @media(max-width:767.98px){
            bottom:-3%;     
          }
          @media(max-width:591.98px){
            width:40%;
            // bottom: 17%;
          }
        }
      }
      .section__10{
        .container{
          // background: rgba($school-color,.2);
        }
        .text{
          color:$school-color;
        }
        .section-heading{
          color:$school-color;
        }
      }
      .section__13{
        h2{
          background:$school-color;
        }
      }
    }

    // common
    .main__inner{
      &.oc-inner-hsc{
        padding-top: 3rem;
        padding-bottom: 8rem;
        background-image: url(../img/lp/oc/hsc/hsc_oc_inner-bg.png);
        background-size: contain;
        .section__03{
          background: none;
        }
      }
      &.oc-inner-htc{
        padding-top: 3rem;
        padding-bottom: 8rem;
        background-image: url(../img/lp/oc/htc/htc_oc_inner-bg.png);
        background-size: contain;
        .section__03{
          background: none;
        }
        .f-century{
          color: $c-cook;
        }
      }
      &.oc-inner-iryo{
        padding-top: 3rem;
        padding-bottom: 8rem;
        background-image: url(../img/lp/oc/iryo/iryo_oc_inner-bg.png);
        background-size: contain;
        .section__03{
          background: none;
        }
        .f-century{
          color: $c-hukusi;
        }
      }
    }
    .section__03{
      padding-left: 10px;
      padding-right: 10px;
      .head__wrap{
        @extend .head__wrap;
        @include media-breakpoint-down(sm) {
          margin-bottom: 5rem;
        }
        h2{
          position: relative;
          &:before{
            content: '';
            display: block;
            position: absolute;
            left: -70px;
            top: -50px;
            // background: url(../img/lp/oc/img_section3_pink-star.svg)no-repeat;
            // background-size: cover;
            width:51px;
            height: 61px;
            @include media-breakpoint-down(sm) {
              left: 1%;
              top: 160%;
            }
          }
          &:after{
            content: '';
            display: block;
            position: absolute;
            right: -60px;
            top: 30px;
            // background: url(../img/lp/oc/img_section3_pink-star.svg)no-repeat;
            // background-size: cover;
            width:51px;
            height: 61px;
            @include media-breakpoint-down(sm) {
              right: 5%;
              top: 160%;
            }
          }
        }
        &.is_scrolled{
          h2{
            &:before,
            &:after{
              @include kyupeen();
            }
          }
        }
      }
      .inner__container{
        background-color: #fff;
        border-radius: 20px;
        padding-top:4rem;
        padding-bottom:8rem;
        margin-bottom: 10rem;
        position: relative;
        @include media-breakpoint-down(sm) {
          padding-bottom: 2rem;
        }
        @include media-breakpoint-up(md) {
          &:after{
            content: '';
            display: block;
            position: absolute;
            right: -30px;
            top: -30px;
            // background: url(../img/lp/oc/img_section3_triangle.svg)no-repeat;
            // background-size: cover;
            width:127px;
            height: 127px;
          }
          &:before{
            content: '';
            display: block;
            position: absolute;
            left: -170px;
            bottom: -150px;
            background: url(../img/lp/oc/img_section3_dots.svg)no-repeat;
            background-size: cover;
            width:197px;
            height: 200px;
          }
        }
        // &.is_scrolled{
        //   .ch--01,
        //   .ch--03{
        //     transition:.5s;
        //     transform: translate(0);
        //   }
        // }
      }
      .merit-item{
        .merit_img{
          filter: grayscale(1);
          transform: rotate(-30deg);
        }
        &.is_scrolled{
          .merit_img{
            transition:.3s ease .2s;
            filter: grayscale(0);
            transform: rotate(0);
          }
        }
      }
      .merit_ch{
        &.is_scrolled{
          animation:ch_jump .4s forwards;
          @keyframes ch_jump {
            0%{
              transform: translateY(0);
            }
            50%{
              transform: translateY(-40px);
            }
            100%{
              transform: translateY(0);
            }
          }
        }
      }
      // .ch--01{
      //   position: absolute;
      //   left:14%;
      //   bottom: 100%;
      //   transform: translate(210px, 0px);
      //   @include media-breakpoint-down(sm) {
      //     width: 20%;
      //     max-width:100px
      //   }
      // }
      // .ch--02{
      //   position: absolute;
      //   left:0;
      //   right: 0;
      //   bottom: 100%;
      //   margin: auto;
      //   z-index: 10;
      //   @include media-breakpoint-down(sm) {
      //     width: 20%;
      //     max-width:100px
      //   }
      // }
      // .ch--03{
      //   position: absolute;
      //   right:14%;
      //   bottom: 100%;
      //   transform: translate(-190px, 0px);
      //   @include media-breakpoint-down(sm) {
      //     width: 20%;
      //     max-width:100px
      //   }
      // }
      .star{
        position: absolute;
      }
      .star--01{
        right: 6%;
        top: 30%;
        @include media-breakpoint-down(sm) {
          right: -16%;
          top: 18%;
        }
      }
      .star--02{
        right: 35%;
        bottom: 13%;
        @include media-breakpoint-down(sm) {
          right: 2%;
          bottom: 2%;
        }
      }
      .star--03{
        left: 35%;
        bottom: 13%;
        @include media-breakpoint-down(sm) {
          left: 2%;
          bottom: 2%;
        }
      }
      .star--04{
        left: 4%;
        bottom: 20%;
        @include media-breakpoint-down(sm) {
          left: 0%;
          bottom: 27%;
        }
      }
      .star--05{
        left: 6%;
        top: 30%;
        @include media-breakpoint-down(sm) {
          left: -2%;
          top: 24%;
        }
      }
    }
    .section__05{
      figure{
        margin-bottom: 0;
      }
      .more-btn{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;;
        border-radius: 100px;
        border:1px solid $teal;
        color:$teal;
        text-align: center;
        padding:.5rem 2rem;
        margin: auto;
        background-color: #fff;
        width:240px;
        font-size: rem-calc(20);
        display: block;
        // position: relative;
        @include opacity();
        &.is_hidden{
          opacity:0;
          visibility: hidden;
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 30px;
          bottom: 0;
          margin: auto;
          width: 1px;
          height:20px;
          background-color: $teal;
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          right: 26px;
          bottom: 16px;
          margin: auto;
          width: 10px;
          height:10px;
          transform: rotate(45deg);
          border-bottom: 1px solid $teal;
          border-right: 1px solid $teal;
        }
      }
      .head__wrap{
        @extend .head__wrap;
        @include media-breakpoint-down(sm) {
          margin-bottom: 3rem;
        }
      }
      .tab-btns{
        display: flex;
        margin-bottom: 8rem;
        @include media-breakpoint-down(sm) {
          flex-wrap:wrap;
        }
        button{
          @include media-breakpoint-down(sm) {
            width: 50%;
            margin-bottom: 1.5rem;
            img{
              width: 100%;
            }
          }
          border:none;
          background-color: transparent;
          padding:0;
          @include opacity-href();
          position: relative;
          &.is_active{
            &:after{
              content: '';
              display: block;
              position: absolute;
              top: 100%;
              left:0;
              right: 0;
              width: 0;
              height: 0;
              margin: auto;
              border-width:15px 10px 0;
              border-style: solid;
              @include media-breakpoint-down(sm) {
                border-width:12px 8px 0;
              }
            }
            &.tab-btn--01{
              &:after{
                border-color: $black transparent transparent transparent;
              }
            }
            &.tab-btn--02{
              &:after{
                border-color: $c-hair transparent transparent transparent;
              }
            }
            &.tab-btn--03{
              &:after{
                border-color: $c-cook transparent transparent transparent;
              }
            }
            &.tab-btn--04{
              &:after{
                border-color: $c-hukusi transparent transparent transparent;
              }
            }
          }
        }
      }

    }
    .section__06{
      margin-bottom: 10rem;
      padding-left: 10px;
      padding-right: 10px;
      .container{
        position: relative;
        padding-top:5rem;
        padding-bottom:1rem;
        background: url(../img/lp/oc/img_section6_bg.jpg)no-repeat;
        background-size: cover;
        @include media-breakpoint-down(md) {
          background: url(../img/lp/oc/img_section4_bg_sp.jpg)no-repeat;
          background-size: cover;
        }
        @include media-breakpoint-down(sm) {
          padding-bottom: 5rem;
        }
      }
      .piano{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 54%;
        max-width:705px;
        @include media-breakpoint-down(md) {
          width: 84%;
        }
      }
      .schedule__wrap{
        position: relative;
        z-index: 10;
        & > .item01{
          position: absolute;
          right: -10%;
          top: -12%;
          @include media-breakpoint-down(md) {
            max-width: 300px;
            width: 55%;
            right: -5%;
            top: -26vw;
          }
        }
      }
      .head__wrap{
        margin-bottom: 7rem;
        @include media-breakpoint-down(md) {
          margin-bottom: 10rem;
        }
      }
      ol{
        &:after{
          content: '';
          display: block;
          clear: both;
        }
      }
      li{
        position: relative;
        @include media-breakpoint-up(lg) {
          &:nth-of-type(odd){
            float:left;
          }
          &:nth-of-type(even){
            float:right;
          }
        }
        &:not(:last-of-type){
          .number{
            &:after{
              content: '';
              display: block;
              position: absolute;
              width:220px;
              height: 35px;
              // background: url(../img/lp/oc/img_section6_step01.svg)no-repeat;
              // // background-size: 220px 35px;
              // background-position: 0 0;
              z-index: -1;
            }
          }
        }
        &.is_scrolled{
          &:not(:last-of-type){
            .number{
              &:after{
                animation:asiato 3s steps(6) infinite;
                @keyframes asiato {
                  to{
                    background-position: 0 -210px;
                  }
                }
                @include media-breakpoint-down(md) {
                  top: 105%;
                }
              }
            }
          }
          .number{
            .s-flag,
            .e-flag{
              opacity: 1;
              transform: rotate(0);
              transition:1s ease .5s;
            }
            &.number--01{
            }
          }
        }
        @include media-breakpoint-down(md) {
          &:nth-of-type(odd){
            .number{
              &:after{
                transform: rotate(50deg);
                left:15%;
              }
            }
          }
          &:nth-of-type(even){
            .number{
              &:after{
                transform: rotate(130deg);
                right:15%;
              }
            }
          }
        }
        @include media-breakpoint-up(lg) {
          &:nth-of-type(1){
            .number{
              &:after{
                left:100%;
                top: 55%;
                transform: rotate(35deg);
              }
            }
          }
          &:nth-of-type(2){
            margin-top: 23%;
            .number{
              &:after{
                right: 90%;
                top: 70%;
                transform: rotate(140deg);
                z-index: -1;
              }
            }
          }
          &:nth-of-type(3){
            margin-top: 11%;
            .number{
              &:after{
                left: 90%;
                top: 65%;
                z-index: -1;
                transform: rotate(35deg);
              }
            }
          }
          &:nth-of-type(4){
            margin-top: 26%;
            .number{
              &:after{
                right: 95%;
                top: 80%;
                transform: rotate(140deg);
                z-index: -1;
              }
            }
          }
          &:nth-of-type(5){
            margin-top: 14%;
          }
        }
        @include media-breakpoint-down(md) {
          margin-top: 7rem;
        }
      }
      .number{
        position: relative;
        @include media-breakpoint-up(lg) {
          line-height: 2;
          width:415px;
        }
        @include media-breakpoint-down(md) {
          max-width:480px;
          margin: auto;
        }
        .s-flag,
        .e-flag{
          position: absolute;
          bottom: 100%;
          left: 0;
          transform-origin: 10% 100%;
          // transform: rotate(40deg);
          transform: rotate(90deg);
          z-index: -1;
          opacity: 0;
        }
        .head{
          // background-color: $teal;
          display: flex;
          align-items: center;
          font-size: rem-calc(25);
          color:white;
          font-weight: bold;
          @include media-breakpoint-down(md) {
            font-size: rem-calc(21);
            // align-items: flex-start;
          }
        }
        .contents{
          position: relative;
          // background-color: #E2F9F9;
          text-align: center;
          font-weight: bold;
          // color:$teal;
          font-size: rem-calc(16);
          padding:1rem 0;
          .pic{
            @include media-breakpoint-down(md) {
              max-width:90%;
              margin: auto;
            }
          }
        }
        .current-num{
          background-color: #fff;
          font-size: rem-calc(35);
          // color:$teal;
          // border:3px solid $teal;
          width: 60px;
          text-align: center;
          @include media-breakpoint-down(md) {
            padding: 0.6rem 5px;
            line-height: 1.4;
          }
          // padding: 0.5rem;
          // height: 100%;
          // align-self: center;
        }
        .head__title{
          // align-self: center;
          width:calc(100% - 60px);
          padding:.5rem;
          line-height: 1.3;
          .title{
            @include media-breakpoint-down(md) {
              font-size: rem-calc(18);
            }
          }
        }
        &.number--01{
          .ch01{
            position: absolute;
            right: 10px;
            bottom: 90px;
            @include media-breakpoint-down(md) {
              max-width:100px;
              width:30%;
              bottom: auto;
              top: -25px;
              right: 0;
            }
          }
          .item01{
            position: absolute;
            bottom: 30%;
            left: 130%;
            z-index: -1;
            @include media-breakpoint-down(md) {
              bottom:95%;
              left:30%;
              width: 29%;
            }
          }
        }
        &.number--02{
          .ch01{
            position: absolute;
            right: 10px;
            bottom: 100%;
            @include media-breakpoint-down(md) {
              width: 17%;
              max-width:70px;
            }
          }
          .ocs{
            font-size: rem-calc(21);
          }
        }
        &.number--03{
          @include media-breakpoint-up(lg) {
            width:480px;
          }
          .ch01{
            position: absolute;
            right: 10px;
            top: -8%;
            z-index: 10;
            @include media-breakpoint-down(md) {
              top: -5%;
              right: 0;
              z-index: 10;
              width: 35%;
            }
          }
          .item01{
            position: absolute;
            left: -10px;
            top:20%;
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
          .item02{
            position: absolute;
            right: 0px;
            top:24%;
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
        &.number--04{
          .ch01{
            position: absolute;
            left: 10px;
            bottom: 100%;
            z-index: 10;
            @include media-breakpoint-down(sm) {
              left: auto;
              right: 0;
            }
            @include media-breakpoint-down(md) {
              width: 40%;
              max-width: 170px;
            }
          }
          .ch02{
            position: absolute;
            right: 10px;
            top:40%;
            z-index: 10;
            @include media-breakpoint-down(md) {
              top: 90%;
              width: 17%;
            }
          }
          .item01{
            position: absolute;
            left: -10px;
            top:20%;
          }
        }
        &.number--05{
          .ch01{
            position: absolute;
            right: 10px;
            top: 20%;
            z-index: 10;
            @include media-breakpoint-down(md) {
              width: 19%;
              right: 0;
            }
          }
        }
      }
    }
    .section__07{
      margin-bottom: 8rem;
      .container{
        position: relative;
        // // background-color: #fff;
        // background: #fff url(../img/lp/oc/img_section7_bg01.png)no-repeat;
        // background-position: center bottom;
        // background-size: 100%;
        // // padding-left: 0;
        // // padding-right: 0;
        // @include media-breakpoint-down(md) {
        //   background: #fff url(../img/lp/oc/img_section7_bg01_sp.png)no-repeat;
        //   background-size: 100%;
        //   background-position: center bottom;
        //   // background-size: cover;
        // }
      }
      .scroll{
        position: absolute;
        right: 0;
        bottom: 10px;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
      .tab-btns2{
        display: flex;
        flex-wrap:wrap;
        margin-bottom: 6rem;
        @include media-breakpoint-down(md) {
          margin-bottom: 0;
        }
        button{
          border-width:2px;
          border-style:solid;
          padding:.5rem;
          font-weight: bold;
          display: block;
          width: 100%;
          transition:.2s;
          @include opacity-href();
          position: relative;
          font-size: rem-calc(16);
          @include media-breakpoint-down(md) {
            // width: 50%;
            margin-bottom: 1rem;
            padding:.5rem;
            font-size: rem-calc(14);
          }
        }
        // li{
        //   .tab-btn2{
        //     &.tab-btn--01{
        //       border-color:$p-pink;
        //       color:$white;
        //       background-color: $p-pink;
        //       }
        //     &.tab-btn--02,
        //     &.tab-btn--03{
        //       border-color:$p-orange;
        //       color:$white;
        //       background-color: $p-orange;
        //       }
        //     &.tab-btn--04,
        //     &.tab-btn--05,
        //     &.tab-btn--06{
        //       border-color:$c-hukusi;
        //       color:$white;
        //       background-color: $c-hukusi;
        //       }
        //     &.is_active{
        //       .tab-btn--01{
        //         color:$p-pink;
        //         background-color: $white;
        //       }
        //       &.tab-btn--02,
        //       &.tab-btn--03{
        //         color:$p-orange;
        //         background-color: $white;
        //       }
        //       &.tab-btn--04,
        //       &.tab-btn--05,
        //       &.tab-btn--06{
        //         color:$c-hukusi;
        //         background-color: $white;
        //       }
        //     }
        //   }
        // }
      }
      .content{
        // padding-bottom: 80px;
        transition: height .6s ease-in-out;
        position: relative;
        display: none;
        @include media-breakpoint-down(md) {
          padding: 2rem 0;
        }
        @include media-breakpoint-down(sm) {
          overflow-x: scroll;
        }
        &.is_show{
          display: block;
          animation: tab-show .5s linear 0s;
          @keyframes tab-show{
            from{
              opacity: 0;
              transform:translate(-20px,20px);
            }
            to{
              opacity: 1;
              transform:translateY(0);
            }
          }
        }
        img{
          @include media-breakpoint-down(sm) {
            max-width:none;
            // width: auto;
          }
        }
      }
    }
    .section__08{
      margin-bottom: 11rem;
      .head__wrap{
        // text-align: center;
        margin-bottom: 4rem;
        @include media-breakpoint-down(md) {
        }
        &.is_scrolled{
          h2{
            // &:after{
            //   border-width:40px 20px 0;
            //   transition:.8s cubic-bezier(1, 0.09, 0.21, 1);
            // }
            .item01{
              transition:.8s;
              transform: translateX(0);
              opacity: 1;
              display:inline-block;
            }
          }
         
        }
      }
      .item01{
        position: absolute;
        left: -5%;
        bottom: -80%;
        text-align: center;
        transform: translateX(-30px);
        opacity: 0;
        @media(max-width:767.98px){
          width: 14%;
        }
        @media(max-width:591.98px){
          left: 0;
          top: 80%;
          bottom: auto;
          // width: 20%;
          width: 17%;
          max-width:109px;
        }
      }
      h2{
        position: relative;
        // background-color: #47B2B0;
        // text-align: center;
        display: inline-block;
        max-width:760px;
        
        width: 100%;
        font-size: rem-calc(26);
        padding: 1.8rem 0.5rem;
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(20);
          padding: 1.5rem 0.5rem;
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          right: 0;
          left: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-width:40px 0 0;
          border-style:solid;
          // border-color:#47B2B0 transparent transparent;
        }
      }
      .content{
        position: relative;
        @include media-breakpoint-down(md) {
          padding-top: 4rem;
        }
      }
      .ch01,.ch02,.ch03{
        position: absolute;
        width:100%;
        @include media-breakpoint-down(xl) {
          width:88%;
        }
        @include media-breakpoint-down(xl) {
          width:66%;
        }
        // left: 2rem;
        // top: 2rem;
        // @include media-breakpoint-down(md) {
        //   left:0;
        //   top: 0;
        //   right: 0;
        //   margin: auto;
        //   max-width:507px;
        // }
       
        .fukidashi{
          transform: scale(0);
          width:100%;
          margin:0;
          // @include media-breakpoint-down(xl) {
          //   width:88%;
          // }
          // @include media-breakpoint-down(xl) {
          //   width:66%;
          // }
        }
        .ch{
          margin-top: -1rem;
          max-width:118px;
          transform:translateX(-30px);
          opacity: 0;
          @include media-breakpoint-only(md) {
            width:23%;
          }
          @include media-breakpoint-down(sm) {
            width:18%;
          }
        }
        &.is_scrolled{
          .ch{
            transform:translateX(0);
            opacity: 1;
            transition:1s cubic-bezier(0.08, 0.27, 0.17, 1);
          }
          .fukidashi{
            transition:.3s cubic-bezier(.42,0,.37,1.5) 1s;
            transform: scale(1);
          }
        }
      }
      .ch01{
        left: 144px;
        top: 26px;
        max-width:345px;
        
        @media(max-width:1199.98px){
          width:33%;
          top: 16%;
          left:13%;
        }
        @media(max-width:1024.98px){
          width:48%;
          left:1%;
        }
        @media(max-width:767.98px){
          width:53%;
          left:-5%;
          top:10%;
        }
        @media(max-width:591.98px){
          width:60%;
          top:14%;
        }
        
      }
      .ch02{
        right: 156px;
        top: 56px;
        max-width:272px;
        @media(max-width:1199.98px){
          width:26%;
          top: 19%;
          right:15%;
        }
        @media(max-width:1024.98px){
          width:36%;
          right:2%;
        }
        @media(max-width:767.98px){
          width:41%;
          top: 17%;
          right:-2%;
          // left:0;
          // top: 0;
          // right: 0;
          // margin: auto;
          // max-width:507px;
        }
        @media(max-width:591.98px){
          width:46%;
          top: 24%;
        }
      }
      .ch03{
        right: 137px;
        bottom: 34px;
        max-width:269px;
        @media(max-width:1199.98px){
          width:26%;
          
        }
        @media(max-width:1024.98px){
          width:35%;
          right:2%;
          bottom:10%;
        }
        @media(max-width:767.98px){
          // left:0;
          // top: 0;
          width:40%;
          right: -2%;
          bottom:6%;
          // margin: auto;
          // max-width:507px;
   
        }
        @media(max-width:591.98px){
          width:43%;
          // bottom: 17%;
        }
      }
      .room{
        @include media-breakpoint-up(lg) {
          // margin-right: 2rem;
        }
      }
    }
    .section__09{
      margin-bottom: 10rem;
      @include media-breakpoint-down(md){
        margin-bottom: 5rem;
      }
      .container{
        padding-top: 4rem;
        padding-bottom: 4rem;
        border:30px solid #F5B5A7;
        background: #fff url(../img/lp/oc/img_section9_bg01.png)no-repeat;
        background-size: cover;
        @include media-breakpoint-down(md) {
          background: #fff url(../img/lp/oc/img_section9_bg01_sp.png)no-repeat;
          background-size: cover;
          border:15px solid #F5B5A7;
        }
      }
      .content{
        position: relative;
        max-width:807px;
        margin: auto;
        @include media-breakpoint-down(md) {
          max-width:390px;
        }
      }
      .ch02{
        @include media-breakpoint-up(lg) {
          position: absolute;
          right:0;
          top: 3rem;
        }
        img{
          @include media-breakpoint-down(md) {
            margin-top: -50%;
          }
        }
      }
    }
    .section__10{
      position: relative;
      z-index: 5;
      margin-bottom: 10rem;
      .head__wrap{
        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
        }
      }
      .container{
        padding-top: 4rem;
        padding-bottom: 4rem;
        // background: rgba(#47B2B0,.2);
        position: relative;
        @include media-breakpoint-down(md) {
        }
      }
      .item01{
        position: absolute;
        left: -50px;
        top: 20px;
        z-index: -1;
        @include media-breakpoint-down(md) {
          width:26%;
          left: 0;
          top: -10%;
        }

      }
      .item02{
        position: absolute;
        right: -50px;
        top: -40px;
        z-index: -1;
        @include media-breakpoint-down(md) {
          width:21%;
          right: 0;
        }
      }
      .content{
        position: relative;
        // margin-top: 8rem;
        padding-top: 5rem;
        // max-width:807px;
        // margin: auto;
        // @include media-breakpoint-down(md) {
        //   max-width:390px;
        // }
        animation:tate 1.5s ease-in-out infinite alternate;
        @keyframes tate {
          0%{
            transform: translateY(-10px);
          }
          100%{
            transform: translateY(0);
          }
        }
        .inner{
          animation:tate 1.5s ease-in-out infinite alternate;
          @keyframes tate {
            0%{
              transform: translateY(-10px);
            }
            100%{
              transform: translateY(0);
            }
          }
        }
      }
      .pic01{
        margin-top: -1rem;
      }
      .text{
        font-weight: 800;
        font-size: rem-calc(27);
        position: relative;
        z-index: 5;
        margin-bottom: 0;
        // color:$teal;
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(14);
        }
        &.is_scrolled{
          .clowd__block{
            // animation: floating-tate 1s ease-in-out infinite alternate;
            // @keyframes floating-tate {
            //   0% {
            //     transform: translateY(-10px);
            //   }
            //   100% {
            //     transform: translateY(0);
            //   }
            // }
            animation:tate 1.5s ease-in-out infinite alternate;
            @keyframes tate {
              0%{
                transform: translateY(-5px);
              }
              100%{
                transform: translateY(0);
              }
            }
          }
          .clowd{
            // animation: floating-yoko 1.2s ease-in-out infinite alternate;
            // @keyframes floating-yoko {
            //   0% {
            //     transform: translateX(-3px);
            //   }
            //   100% {
            //     transform: translateX(0);
            //   }
            // }
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            margin: auto;
            bottom: 0;
            animation:yoko 1.8s ease-in-out infinite alternate;
            @keyframes yoko {
              0%{
                transform: translateX(-3px);
              }
              100%{
                transform: translateX(0);
              }
            }
          }
        }
        .clowd__block{
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          z-index: -1;
        }
      }
    }
    .section__11{
      margin-bottom: 8rem;
      .acc{
        font-size: rem-calc(16);
        margin-bottom: 5rem;
        & > li{
          border-bottom: 1px solid #009389;
          &:first-of-type{
            border-top: 1px solid #009389;
          }
        }
        .accBtn{
          // padding:.5rem;
          background-color: transparent;
          border:none;
          position: relative;
          display: block;
          width: 100%;
          text-align: left;
          padding: 1.4rem 2rem 1.4rem 20px;
          @include opacity();
          &:after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto;
            width:28px;
            height: 2px;
            background-color: #009389;
          }
          &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 22px;
            bottom: 0;
            margin: auto;
            width: 2px;
            height: 28px;
            background-color: #009389;
            transition:.2s;
          }
          &.is_active{
            background-color: #fff;
            &:before{
              transform: scale(0);
            }
          }
        }
        .accContent{
          // padding: 0 .5rem;
          padding: 2rem 20px;
          // margin:1rem 0;
          display: block;
          background: #fff;
          position: relative;
          animation: tab-show2 .5s linear 0s;
          @keyframes tab-show2{
            from{
              opacity: 0;
              transform:translateX(20px);
            }
            to{
              opacity: 1;
              transform:translateX(0);
            }
          }
          &:after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left:20px;
            width:0;
            height: 1px;
            background-color: #009389;
            transition:.2s;
          }
          &.is_hidden{
            // visibility: hidden;
            display: none;
            height:0!important;
          }
          &.is_show{
            &:after{
              width:90%;
            }
          }
          // display: none;
        }
      }
    }
    .section__12{
      position: relative;
      // z-index: 5;
      margin-bottom: 10rem;
      .head__wrap{
        margin-bottom: 6rem;
      }
      .container{
        padding-top: 4rem;
        padding-bottom: 4rem;
        background: #fff;
        position: relative;
        @include media-breakpoint-down(md) {
        }
      }
      .item01{
        position: absolute;
        left: -100px;
        top: 10px;
        @include media-breakpoint-down(md) {
          width:40%;
          left: 0;
          top: -2%;
        }

      }
      .item02{
        position: absolute;
        right: -50px;
        top: -20px;
        @include media-breakpoint-down(md) {
          width: 21%;
          right: 0;
          top: -30px;
        }
      }
      .item03{
        position: absolute;
        left: -8%;
        bottom: -6%;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .content{
        font-size: rem-calc(16);
        line-height: 1.9;
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(14);
        }
      }
      .title{
        font-weight: bold;
        font-size: rem-calc(20);
        color:$teal;
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(18);
          margin-top: 0.5rem;
        }
      }
      .line-link{
        font-size: rem-calc(18);
        font-weight: bold;
        text-align: center;
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(16);
        }
        a{
          color:white;
          @include opacity-href();
          background-color: #40BB0F;
          display: inline-block;
          padding:1rem 2rem;
          @include media-breakpoint-down(sm) {
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 60px;
            }
          }
        }
      }
    }
    .section__13{
      // margin-bottom: 5rem;
      // padding-bottom: 1rem;
      .container{
        background-color: #fff;
        padding-bottom: 3rem;
      }
      h2{
        font-weight: bold;
        font-size: rem-calc(25);
        text-align: center;
        // background:$teal;
        padding:1rem .5rem;
        color:white;
        margin: 0 -10px 2rem;
      }
    }
  }

  
//追加
.section__07{

  .section__08{
    div.s8-wrap_heading{
      @include media-breakpoint-up(md) {
        text-align: center;
      
      }
    }
    div.content{
      display:block !important;
      padding-top:0;
      @include media-breakpoint-down(sm) {
        padding-top:30px;
      }
      @include media-breakpoint-down(md) {
        overflow-x:visible;
      }
    }
  
    h2.s8_heading{
      background-image:url(../img/lp/oc/img-section8-bg-item_sp.svg);
      background-position: center right;
      background-repeat: no-repeat;
      background-size: cover;
      padding-left:20px !important;
      max-width:863px;
      @include media-breakpoint-up(sm) {
        padding-left:40px !important;
      
      }
      @include media-breakpoint-up(md) {
        padding-left:90px !important;
      
      }
      @include media-breakpoint-up(lg) {
        background-image:url(../img/lp/oc/img-section8-bg-item_pc.svg);
        padding-left:128px !important;
        text-align: left;
      
      }
      img.item01{
        top:120px;
        z-index:1;
        @include queries(xs){
          top:90px;
        }
        @include queries(md){
          top:20px;
        }
        @include media-breakpoint-up(lg) {
          left:0;
        }
      }
    }
    .s8_img{
      position:relative;

      img{
        width:100%;
        max-width:756px;
      }
  
    }
    // .ch01.scroll_target{
    //   text-align: center;
    //   .fukidashi{
    //     width:86%;
    //     max-width:473px;
    //     margin-top:40px;
    //     @include media-breakpoint-up(md) {
    //       margin-top:40px;
    //       margin-left:100px;
    //     }
    //     @include media-breakpoint-up(lg) {
    //       margin-top:20px;
    //       margin-left:280px;
    //     }
    //   }

    // }
    // .s8_img::before{
    //   content:'';
    //   display:inline-block;
    //   // width:310px;
    //   width:86%;
    //   height:0px;
    //   padding-top:28%;
    //   max-width:473px;
    //   background-image:url(../img/lp/oc/img_section8_pic01_op.svg);
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: center;
    //   position: absolute;
    //   top:15px;
    //   left:0;right:0;
    //   margin-left:auto;margin-right:auto;
    //   @include media-breakpoint-up(md) {
    //     padding-top:0;
    //     height:100%;
    //     max-height:153px;
    //     top:32px;
    //     right:60px;
    //     left:auto;
    //   }
    //   @include media-breakpoint-up(lg) {
    //     padding-top:0;
    //     height:100%;
    //     max-height:153px;
    //     top:52px;
    //     right:200px;
    //     left:auto;
    //   }
    // }
  
  }
}
.section__08:not(.parent-group){
  div.s8-wrap_heading{
    @include media-breakpoint-down(lg) {
      margin-bottom:0;
    
    }
    @include media-breakpoint-up(md) {
      text-align: center;
    
    }
  }
  div.content{
    // display:block !important;
    padding-top:45px;
    @include queries(xs){
      padding-top:55px;
    }
    @include media-breakpoint-up(sm) {
      padding-top:100px;
    }
    @include media-breakpoint-up(md) {
      padding-top:90px;
    }
    @include media-breakpoint-up(xl) {
      padding-top:10px;
    }
   
  }

  h2.s8_heading{
    background-image:url(../img/lp/oc/img-section8-bg-item_sp.svg);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left:20px !important;
    max-width:863px;
    @include media-breakpoint-up(sm) {
      padding-left:40px !important;
    
    }
    @include media-breakpoint-up(md) {
      padding-left:90px !important;
    
    }
    @include media-breakpoint-up(lg) {
      background-image:url(../img/lp/oc/img-section8-bg-item_pc.svg);
      padding-left:128px !important;
      text-align: left;
    
    }
    img.item01{
      top:115px;
      z-index:1;
      @include queries(xs){
        top:90px;
      }
      
      @include queries(md){
        top:20px;
        left:0;
      }
      @include media-breakpoint-up(lg) {
        left:0;
      }
    }
  }
  h2.s8_heading.hsc{
    background-image:url(../img/lp/oc/hsc/img-section8-bg-item-hsc_sp.svg);
    @include media-breakpoint-up(lg) {
      background-image:url(../img/lp/oc/hsc/img-section8-bg-item-hsc_pc.svg);
    
    }
  }
  h2.s8_heading.htc{
    background-image:url(../img/lp/oc/htc/img-section8-bg-item-htc_sp.svg);
    @include media-breakpoint-up(lg) {
      background-image:url(../img/lp/oc/htc/img-section8-bg-item-htc_pc.svg);
    
    }
  }
  h2.s8_heading.iryo{
    background-image:url(../img/lp/oc/iryo/img-section8-bg-item-iryo_sp.svg);
    @include media-breakpoint-up(lg) {
      background-image:url(../img/lp/oc/iryo/img-section8-bg-item-iryo_pc.svg);
    
    }
  }
  .s8_img{
    position:relative;
    
    @include media-breakpoint-up(lg) {
      text-align: center !important;
    
    }

    img{
      width:100%;
      max-width:756px;
    }

  }
  // .ch01.scroll_target{
  //   text-align: center;
  //   .fukidashi{
  //     width:86%;
  //     max-width:473px;
  //     margin-top:55px;
  //     @include queries(xs){
  //       margin-top:70px;
  //     }
  //     @include media-breakpoint-up(sm) {
  //       margin-top:115px;
  //     }
  //     @include media-breakpoint-up(md) {
  //       // margin-top:140px;
  //       // margin-left:100px;
  //     }
  //     @include media-breakpoint-up(lg) {
  //       margin-top:90px;
  //       // margin-left:280px;
  //     }
  //     @include media-breakpoint-up(xl) {
  //       // margin-top:30px;
  //     }
  //   }

  // }


}
}
