/* utilities
-------------------------------------------------- */

/* float */
.pull-left,
.float-left{
	float: left;
}
.pull-right,
.float-right{
	float: right;
}

/* clear */
.clear{
	clear: both;
}
.clear-left{
	clear: left;
}
.clear-right{
	clear: right;
}

/* clearfix */
.clearfix {
	@include clearfix();
}

/* white space */
.nowrap{
	white-space: nowrap;
}

.lg-only{
	@include media-breakpoint-down(md) {
		display: none;
	}
}
.md-down{
	@include media-breakpoint-up(lg) {
		display: none;
	}
	@include media-breakpoint-down(md) {
		display: block;
	}
}
.sm-only{
	@include media-breakpoint-up(md) {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		display: block;
	}
}
//
// Flex video
//
// .flex-video {
// 	@include intrinsic-ratio-container();

// 	> iframe {
// 		@include intrinsic-ratio-item();
// 	}

// 	&.widescreen {
// 		@include intrinsic-ratio-container( 'wide' );
// 	}
// }


//
// Typography
//

// .h1 {
// 	margin: 1.5em 0 0.5em;
// 	padding: 0;
// 	color: $color;
// 	font-size: rem(74);
// }
// .h2 {
// 	margin: 1.5em 0 0.5em;
// 	padding: 0;
// 	color: $color;
// 	font-size: rem(33);
// 	// border-bottom: 2px solid $color;
// }
// .h3 {
// 	margin: 1.5em 0 0.5em;
// 	padding: 0;
// 	color: $color;
// 	font-size: rem(30);
// 	// border-left: 3px solid #CC0000;
// }
// .h4 {
// 	margin: 1.5em 0 0.5em;
// 	padding: 0;
// 	color: $color;
// 	font-size: rem(20);
// }
// .h5 {
// 	margin: 1.5em 0 0.5em;
// 	padding: 0;
// 	color: $color;
// 	font-size: rem(18);
// }
// .h6 {
// 	margin: 1.5em 0 0.5em;
// 	padding: 0;
// 	color: $color;
// 	font-size: rem(16);
// }

// main h1 { @extend .h1; }
// main h2 { @extend .h2; }
// main h3 { @extend .h3; }
// main h4 { @extend .h4; }
// main h5 { @extend .h5; }
// main h6 { @extend .h6; }


// /* section text margin style */
// p,
// blockquote,
// table,
// dl,
// ul,
// ol,
// pre{
// 	margin: 0 0 1em;
// }

// /* text-align */
// .alignleft  { text-align: left; }
// .aligncenter{ text-align: center; }
// .alignright { text-align: right; }

// /* font style */
// span.important{
// 	color: #ff0000;
// }
// span.large{
// 	font-size: 120%;
// }
// span.small{
// 	font-size: 84%;
// }