$kodomo-pink            : #F48E8C;
$kodomo-light_pink      : #FFEDEA;
$kodomo-gray            : #E2E2E2;
$kodomo-green           : #43AA88;
$kodomo-black           : #1A1311;
$kaigo-blue             : #7FADD8;
$kaigo-srong-blue       : #007FC7;
$yakugyo-green          : #8CBD97;
$yakugyo-light_green    : #EDF8F5;
$yakugyo-green02        : #43966C;
$yakugyo-light-green2   : #EAF4EA;
$htc-c1-orange          : #F08300;
$htc-c1-light-orang     : #FCC48B;
$htc-c1-border          : #CBCBCB;
$htc-black              : #333;
$hsc-primary            : #EB6EA5;
$hsc-secondary          : #F5B6D2;
$hsc-third              : #E85298;
$hsc-9course01-1        : #E73273;
$hsc-9course01-2        : #F085A7;
$hsc-9course01-3        : #FAD9DE;
$hsc-9course02-1        : #915DA3;
$hsc-9course02-2        : #C59AC0;
$hsc-9course02-3        : #E3D9EB;
$hsc-9course03-1        : #1BB8CE;
$hsc-9course03-2        : #7AD3E0;
$hsc-9course03-3        : #DBEFF4;
$hsc-9course04-1        : #E95383;
$hsc-9course04-2        : #F297B3;
$hsc-9course04-3        : #FBDDE3;
$hsc-9course05-1        : #6FBA2C;
$hsc-9course05-2        : #B3C984;
$hsc-9course05-3        : #E3EFD1;
$hsc-9course06-1        : #ABC808;
$hsc-9course06-2        : #CCDC6C;
$hsc-9course06-3        : #EDF2D1;
$hsc-9course07-1        : #76C5AB;
$hsc-9course07-2        : #ACDBCB;
$hsc-9course07-3        : #E4F2EC;
$hsc-9course08-1        : #F39800;
$hsc-9course08-2        : #F6B86E;
$hsc-9course08-3        : #FDE8C8;
$hsc-9course09-1        : #2990D0;
$hsc-9course09-2        : #90B4D6;
$hsc-9course09-3        : #DFE9F6;
$plane-black            : #000000;

body.kita-t-b-w-lower{
    font-family: $font-noto;

    $dir: '../img/lp/kitaweru/';
    $htc_c1_dir: '../img/lp/kitatech/course1/';
    $htc_c2_dir: '../img/lp/kitatech/course2/';
    $htc_c3_dir: '../img/lp/kitatech/course3/';
    $htc_c4_dir: '../img/lp/kitatech/course4/';
    $htc_c5_dir: '../img/lp/kitatech/course5/';
    $htc_c6_dir: '../img/lp/kitatech/course6/';
    $htc_dir: '../img/lp/kitatech/';
    $hsc_course: '../img/lp/hsc/course/';
    
    
 


    // @mixin separate($key){
    //     @if $key == kodomo {
    //         // .kitaweru-kodomo-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == kaigo {
    //         // .kitaweru-kaigo-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == yakugyo {
    //         // .kitaweru-yakugyo-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == course1a {
    //         // .kita-htc-lower-inner.kt-course1{
    //         //     @content;
    //         // }
    //     }@else if $key == course1 {
    //         // .kita-htc-lower-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == course2{
    //         // .kita-htc-course2-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == course3{
    //         // .kita-htc-course3-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == course4{
    //         // .kita-htc-course4-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == course5{
    //         // .kita-htc-course5-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == course6{
    //         // .kita-htc-course6-inner{
    //         //     @content;
    //         // }
    //     }@else if $key == course{
    //         // .hsc-course-inner{
    //         //     @content;
    //         // }
    //     }
    // }

    @function percent($inner, $outer){
        @return ($inner / $outer) * 100%;
    }
    @mixin bg-init{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top 0 left 0;
    }
    @mixin none-auto{
        margin-left:0;
        margin-right:0;
    }
    main{
        p{
            
            margin-bottom:0;
        }
        .breadcrumb-container{
            @include queries(xl){
                max-width: 1110px !important;
            }
        }
        nav{
            .breadcrumb{
                margin-bottom: 0;
                background-color: transparent;
                li,a,span{
                    font-size: 12px;
                    color: #000;
                    @include media-breakpoint-down(sm){
                        font-size: 11px;
                    }
                }
            }
        }
    }
    .section{
        padding-top: rem(60);
        padding-bottom: rem(60);
        @include media-breakpoint-up(md) {
            padding-top: rem(100);
            padding-bottom: rem(100);
        }
    }
    .d-sp-ss{
        display:none;
        @include queries( xs ){
            display:block;
        } 
    }
    .d-xl-flex{
        display:none;
        @include queries( xl ){
            display:flex;
        }
    }
    .kl-hero{
        width:100%;
        height:0;
        padding-top:vw(1214,750);
        background-repeat:no-repeat;
        background-position:top 0 left 0;
        background-size:cover;
        position:relative;

        @include queries( md ){
            padding-top:vw(1728,3000);
          }
        @include queries( xl ){
            padding-top:0;
            height:vw(864,1500);
            // height:vw(764,1500);
            background-position:top 0px left 0;
            // background-position:center;
          }
    }
    .kl-hero-inner{
        @include queries( xl ){
            width:100%;
            max-width:1160px;
            @include center;
            position:relative;
            height:inherit;
          }
        @include queries( xxl ){
            max-width:1220px;
          }
    }

    // ページ分け
    .kitaweru-kodomo-inner{  //@kodomo
        .kl-hero{
          background-image: url($dir + 'kodomo-bg-item-sp_01.jpg');

          @include queries( md ){
            background-image: url($dir + 'kodomo-bg-item-pc_01.jpg');  
          }
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-hero{
          background-image: url($dir + 'kaigo-bg-item-sp_01.jpg');

          @include queries( md ){
            background-image: url($dir + 'kaigo-bg-item-pc_01.jpg');  
          }
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-hero{
          background-image: url($dir + 'yakugyo-bg-item-sp_01.jpg');

          @include queries( md ){
            background-image: url($dir + 'yakugyo-bg-item-pc_01.jpg');  
          }
        }
    }
    
    .hsc-course-inner{  //@course
        .kl-hero{
            background-image: url($hsc_course + 'hsc-bg-item01_sp.jpg');
            @include queries( md ){
              background-image: url($hsc_course + 'hsc-bg-item01-pc_01.jpg');
          }
        }
    }
    

    .plean-btn{
        width:187px;
        text-align: center;
        border-radius: 30px;
        padding: 10px 0;
        cursor: pointer;
        p{
            font-size:rem(15);
            margin-bottom:0;
        }
    }

    
    .bg-white{
        background-color:$white;

    }
    .kl-arrow{
        
        span{
            display:block;
            position:relative;
        }
        span::after{
            content:'';
            display:inline-block;
            width:12px;
            height:13px;
            @include bg-init;
            position:absolute;
            top:0;
            bottom:0;
            margin-top:auto;
            margin-bottom:auto;
            right:15px;

        }
        span.up-a::after{
            transform:rotate(180deg);
        }
    }
    .kitaweru-kodomo-inner{  //@kodomo
        .kl-arrow{
            span::after{
                background-image:url($dir + 'kodomo-item_08.svg');
    
            }
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-arrow{
            span::after{
                background-image:url($dir + 'yakugyo-item_29.svg');
    
            }
        }
    }

    .kl-hero_heddigng2_img{
        position:absolute;
        top:36px;
        left:vw(10,375);
        // right:0;
        // @include center;
        @include queries(md){
            top:63px;
            left:50px;
            right:auto;
        }

        @include queries(xl){
            top:223px;
            left:0px;

            img{
                width:100%;
            }
        }
        @include queries(xxl){
            top:263px;
            left:-100px;

            img{
                width:100%;
            }
        }
    }
    .hsc-course-inner{  //@course
        .kl-hero-inner__block{
            @include queries(md){
                position: absolute;
                top: 50%;
                left: 10px;
                margin: auto;
                transform: translateY(-50%);
            }
        }
        .kl-hero_heddigng2_img{
            @include media-breakpoint-up(md) {
                top: auto;
                position: static;
            }
        }
        .kl-hero_text_img{
            @include media-breakpoint-up(md) {
                position: static;
                top: auto;
            }
        }
    }
    .border-weru-kodomo{
        border:1px solid $kodomo-pink;
        color:$kodomo-pink;
    }
    .border-weru-yakugyo{
        border:1px solid $yakugyo-green02;
        color:$yakugyo-green02;
    }

    .kitaweru-kodomo-inner{  //@kodomo
        .kl-hero_heddigng2_img{
            width:vw(355,375);
            @include queries(md){
                width:vw(368,768);
            }
            @include queries(xl){
                width:vw(568,1500);
                max-width:568px;
            }
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-hero_heddigng2_img{
            width:vw(343,375);
            @include queries(md){
                width:vw(368,768);
            }
            @include queries(xl){
                width:vw(532,1500);
                max-width:632px;
                left:50px;
            }

            @include queries(xxl){
                left:-100px;
    
                img{
                    width:100%;
                }
            }
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-hero_heddigng2_img{
            width:vw(327,375); 
            @include queries(md){
                width:vw(368,768);
            }
            @include queries(xl){
                width:vw(534,1500);
                max-width:534px;
            } 
        }   
    }
    .hsc-course-inner{  //@course
        .kl-hero_heddigng2_img{
            width:vw(183,375); 
            // @include queries(md){
            //     width:vw(368,768);
            // }
            @include queries(md){
                width:vw(285,1500);
                max-width:285px;
            } 
        }   
    }
    .kl-hero_text_img{
        position:absolute;
        top:vw(388,375);
        left:vw(10,375);

        @include queries(md){
            top:vw(268,678);
            left:50px;
        }
        @include queries(xl){
            top:551px;
            bottom:auto;
            left:0px;
        }
        @media(min-width:1330px){
            top:600px;
        }
        @include queries(xxl){
            top:631px;
            bottom:auto;
            left:-100px;
        }
    }
    .kitaweru-kodomo-inner{  //@kodomo
        .kl-hero_text_img{
            width:vw(193,375);
            @include queries(md){
                width:vw(170,768);
            }
            @include queries(xl){
                width:vw(250,1500);
                max-width:250px;
            }
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-hero_text_img{
            width:vw(249,375);
            @include queries(md){
                width:vw(170,768);
            }
            @include queries(xl){
                width:vw(308,1500);
                max-width:408px;
                left:50px;
            }

         
            @include queries(xxl){
               
                left:-100px;
            }
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-hero_text_img{
            width:vw(306,375);
            @include queries(md){
                width:vw(170,768);
            }
            @include queries(xl){
                width:vw(395,1500);
                max-width:395px;
            }
        }
    }
    .hsc-course-inner{  //@course
        .kl-hero_text_img{
            width:vw(222,375);
            // @include queries(md){
            //     width:vw(170,768);
            // }
            @include queries(md){
                width:vw(298,1500);
                max-width:298px;
            }
            @include media-breakpoint-down(sm) {
                top:auto;
                bottom: 16%;
            }
        }
    }

    .kl-point{
        width:100%;
        position:relative
    }
    .kl-point-inner{
        width:100%;
        max-width:100%;
        @include center;
        @include queries(md){
            max-width:1220px;
            @include center;
        }
    }
    .kl-point:before{
        content:'';
        display:block;
        background-image:url($dir + 'common-item_22.svg');
        background-repeat: repeat;
        background-size:  192px 114px;
        background-position: top 0 left 0;
        position:absolute;
        top:0;left:0;right:0;bottom:0;
    }

    .kitaweru-kodomo-inner{ //@kodomo
        .kl-point{
            background-color:$kodomo-pink;
        } 
    }
    .kitaweru-kaigo-inner{  //@kaigo
         .kl-point{
            background-color:$kaigo-blue;
        } 
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
         .kl-point{
            background-color:$yakugyo-green;
        } 
    }
    .hsc-course-inner{  //@course
         .kl-point{
            background-color:$hsc-primary;
        } 
    }
   


    .p-btn--dark{
        margin-top:25px;
        a{
            span:before{
                background-color: transparent;
            }
        }
    }  

    .kl-point_text{
        font-size:rem(20);
        font-weight:$font-weight-bold;
        color:$white;
        text-align: center;
        position:relative;
        @include queries(md){
            font-size:rem(16);
            transform:scale(0.97);
            // margin-bottom:18px;
        }
        @include queries(lg){
            font-size:rem(20);
        }
        @include queries(xl){
            font-size:rem(25);
        }

    }
    .kl-point_text::before,
    .kl-point_text::after{
        content:'';
        display:inline-block;
        width:21px;
        height:24px;
        background-image:url($dir + 'common-item_05.png');
        @include bg-init;
        position:absolute;
        top:0;bottom:0;@include center;   
    }
    .kl-point_text::before{
        left:30px;
        transform: translate(-3px,10px);
      
    }
    .kl-point_text::after{
        right:30px;
        transform: translate(0,10px) rotate(65deg);
        
    }

    .kl-point_col{
        padding-left:0;
        padding-right:0;
        text-align: center;
        @include queries(md){
            @include p-init; 
        }
        .kl-point_text{
            display:inline-block;
            margin-top:30px;
            @include queries(md){
                margin-top:63px; 
            }
        }
    }
    .kl-point_col:nth-of-type(1){
        

        .kl-point_text{
            margin-top:70px;

            @include queries(md){
                margin-top:63px; 
            }
        }
        .kl-point_text::before{
           left:-20px;
        //    @include queries(md){
        //     left:-5px;
        //     }
        //    @include queries(xl){
        //     left:0px;
        //     }
        }
        .kl-point_text::after{
           right:-20px;
        //    @include queries(md){
        //     right:-5px;
        //     }
        //    @include queries(xl){
        //     right:0px;
        //     }
        }
    }
    .kl-point_col:nth-of-type(2){
        .kl-point_text::before{
           left:-20px;
        //    @include queries(md){
        //         left:12px;
        //     }
        //     @include queries(xl){
        //         left:30px;
        //     }
        }
        .kl-point_text::after{
           right:-20px;
        //    @include queries(md){
        //         right:15px;
        //     }
        //     @include queries(xl){
        //         right:40px;
        //     }
        }
    }
    .hsc-course-inner{  //@course
        .kl-point_col:nth-of-type(2){
            .kl-point_text::before{
                top:auto;
                bottom:15px;
                left: -25px;
                @include media-breakpoint-up(md) {
                }
            //    @include queries(md){
            //         left:12px;
            //     }
            //     @include queries(xl){
            //         left:30px;
            //     }
            }
            .kl-point_text::after{
                top:auto;
                bottom:15px;
                right: -25px;
                @include media-breakpoint-up(md) {
                }
            //    @include queries(md){
            //         right:15px;
            //     }
            //     @include queries(xl){
            //         right:40px;
            //     }
            }
        }
    }
    .kl-point_col:nth-of-type(3){
        .kl-point_text::before{
           left:-20px;
        }
        .kl-point_text::after{
           right:-20px;
        }
    }
    .kl-point_row {
        padding-bottom:80px;
    }

    .kl-image{
        width:100%;
        background: repeating-linear-gradient(-45deg,#fef7fa,#fef7fa 10px,#fff 0,#fff 20px);
    }

    .kitaweru-kaigo-inner{  //@kaigo
        .kl-image{
            background: repeating-linear-gradient(-45deg,$yakugyo-light_green,$yakugyo-light_green 10px,#fff 0,#fff 20px);
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-image{
            background: repeating-linear-gradient(-45deg,$yakugyo-light_green,$yakugyo-light_green 10px,#fff 0,#fff 20px);
        }
    }
    

    // .kl-image-inner{
    //     width:100%;
    //     @include center;
    //     position:relative;

    //     @include queries(md){
    //         max-width:1220px;
            
    //     }
        

    //     img:nth-of-type(1){
    //         width:113.7px;
    //         position:absolute;
    //         top:160.5px;
    //         right:0;
          
    //         @include queries(md){
    //             width:vw(91,768);
    //             top:27px;
    //             left:19.5px;
    //             right:auto;
                
    //         }
    //         @include queries(xl){
    //             width:184px;
    //             top:54px;
    //             left:0px;
    //             right:auto;
                
    //         }

    //     }
    //     img:nth-of-type(2){
    //         width:vw(363.97,375);
    //         max-width:363.97px;
    //         margin-top:12.3px;
    //         @include center;
    //         transform:translate(5px,0);
    //         @include queries(md){
    //             width:vw(380,768);
    //             margin-top:0px;
    //             margin-left:112px;
    //         }
    //         @include queries(xl){
    //             width:723px;
    //             max-width:772px;
    //             margin-top:34.6px;
    //             margin-left:85px;
    //         }

    //     }
    //     img:nth-of-type(3){
    //         width:132.24px;
    //         position:absolute;
    //         top:160.5px;
    //         left:0;

    //         @include queries(md){
    //             width:vw(105,768);
    //             right:61px;
    //             top:15px;
    //             left:auto;

                
    //         }
    //         @include queries(xl){
    //             width:214px;
    //             max-width:214px;
    //             right:0px;
    //             top:35px;
    //             left:auto;

                
    //         }
          


    //     }
    //     img:nth-of-type(4){
    //         width:vw(246,375);
    //         max-width:246px;
    //         display:block;
    //         @include center;

    //         @include queries(md){
    //             width:vw(195,768);
    //             margin-right:50px;
    //             margin-top:10px;
    //             display:inline-block;
                
    //         }
    //         @include queries(xl){
    //             width:391px;
    //             max-width:391px;
    //             margin-right:0px;
    //             margin-top:10px;
                
    //         }

    //     }

        
    // }
//-----2024------

    .kl-img-inner{
        width: 100%;
        max-width: 1220px;
        margin-inline: auto;
        padding-top: 20px;
        position: relative;
        .parts{
            img{
                display: inline-block;
                position: absolute;
                &:nth-of-type(1){
                    width: 30vw;
                    top: 40%;
                    left: 4%;
                    @include queries(md){
                        width: f-min(184px,12vw);
                        top:54px;
                        left:0%; 
                    }
                    @include queries(xl){
                        left: -10%; 
                    }
                }
                &:nth-of-type(2){
                    width: 35vw;
                    top: 40%;
                    right: 4%;
                    @include queries(md){
                        width: f-min(214px,14vw);
                        top:54px;
                        right:0%; 
                    }
                    @include queries(xl){
                        right: -10%; 
                    }
                }
            }
        }
        .image-item{
            
                width:92%;
                margin-inline: auto;
                margin-bottom: 0;
                position: relative;
                @include queries(md){
                    width: f-min(920px,71vw);
                    margin-bottom: -10px;
                }
                
            
            img{
                width: 100%;
            }
        }
    }
//----/2024------

    .kl-point_btn{
        @include queries(md){
            transform: scale(0.9);
        }
        @include queries(xl){
            transform: scale(1);
        }
       
        a{
            @include queries(md){
                // padding: 0.7rem 2rem 0.7rem 0rem;
            }
        }
    }

    .kl-qualification{
        width:100%;
    }
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification{
            background-color:$kodomo-pink;
            position: relative;
        }
    }

    .kl-qualification-inner{
        width:vw(355,375);
        @include center;
        padding-top:50px;
        padding-bottom:40px;

        @include queries(md){
            position:relative;
            padding-top:15px;
            padding-bottom:35px;
        }
        @include queries(xl){
            max-width:1220px;
            position:relative;
            padding-top:30px;
            padding-bottom:72.5px;
        }
    }
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification-inner{
            padding-top: 40px;
            padding-bottom: 180px;
            @include queries(md){
                padding-top: 50px;
                padding-bottom:50px;
            }
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-qualification-inner{
            padding-top: 10px;
        }
    }

//-------------------------------------------------
//      ページ別スタイル
//-------------------------------------------------
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-qualification{
            background-color:$kaigo-blue;
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-qualification{
            background-color:$yakugyo-green;
        }
        
    }
    .hsc-course-inner{  //@course
        .kl-qualification{
            background-color:$hsc_primary;
        }
    }

  

    .kitaweru-kaigo-inner{  //@kaigo
        .kl-qualification-inner{
  
    
            @include queries(lg){
                max-width:845px;
            }
            @include queries(xl){
                max-width:1220px;
            }

        }
    }
    // .kl-yakugyo-image-inner{
    //     position:relative;
    //     width:94%;
    //     max-width:1000px;
    //     height:inherit;
    //     @include center;
    //     text-align: center;
        
    // }

    //-------------------------------------------------------------------
    // .kl-image_img{

    //     @include queries(md){
    //         display:flex;
    //         justify-content:center;

    //     }
    //     img:nth-of-type(1){
    //         width:71%;
    //         max-width:331px;
    //         @include queries(md){
    //             width:32%;
    //             position:static;
    //             max-width:331px;
    //         }
    //         @include queries(xl){
    //             width:32%;
    //             position:static;
    //             max-width:331px;
    //         }
    //     }
    //     img:nth-of-type(2){
    //         width:90%;
    //         max-width:442px;
    //         @include queries(md){
    //             width:38%;
    //             position:static;
    //             margin-left:15px;
    //             max-width:442px;
    //         }
    //         @include queries(xl){
    //             width:38%;
    //             position:static;
    //             margin-left:15px;
    //             max-width:442px;
    //         }

    //     }
    // }
 

    .kl-qualification_heading2{
        width:vw(355,375);
        @include center;
        font-size:rem(18);
        font-weight: $font-weight-bold;
        line-height: lh-calc(24,18);
        background-color: $yellow;
        padding:4px 15.5px;

        @include queries(md){
            width:355px;
            margin-left:90px;
            margin-right:0;
            // margin-top:74px;
            transform:translateY(40px);
        }
        @include queries(lg){
            margin-left:100px;
        
        }
        @include queries(xl){
            // margin-left:183px;
            // margin-top:74px;
            transform:translate(173px,40px);
        }
    }
    .kl-qualification-flex {
        width:100%;
        display:flex;
        margin-left:10px;
        position:relative;

        @include queries(xl){
            // justify-content: center;
            margin-left:183px;
        }
    }
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification-flex{
            position: static;
            @include queries(md){
                // flex-wrap: wrap;
            }
        }
    }
    .kitaweru-kaigo-inner{
        .kl-qualification-flex{
            justify-content: center;
            
        }
    }


   

    


    .kl-qualification_heading3{
        width:35px;
        @include queries(xs){
            width:45px;
        }  
    }

    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification_heading3{
                margin-top:5px;
               
            }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-qualification_heading3{
            margin-left:-20px;
            @include queries(sm){
                margin-left:-50px;
            }
            @include queries(md){
                margin-left:0;
            }
        }
    }


    .kl-qualification-list{
        width:83%;
        margin-top:5px;

        @include queries(xs){
            width:auto;
            margin-top:24px;
            margin-left:15px;
        }
        @include queries(md){
            display:flex;
        }

    }

    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification-list{
            margin-top:0;
            margin-left:10px;
            @include queries(md){
                margin-left:0;
            }
            // @include queries(xl){
            //     padding-top:0;
            // }
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-qualification-list{
            order:2;
            margin-left:10px;
            @include queries(xs){
                margin-left:15px;
            }
            @include queries(md){
                // order:2;
                flex-direction: column;
                margin-top:0;
            }
            
        }
    }

    .kl-qualification_ul{
        list-style-type: none;
        padding-left:0;
        margin-top:15px;

        @include queries(md){
            margin-top:30px;
           
        }
        li{
            font-size:rem(14);
            font-weight:$font-weight-bold;
            line-height:lh-calc(21,14);
            letter-spacing:lp-calc(50);
            margin-bottom:8px;

            @include queries(md){
                font-size:rem(18);
                line-height:lh-calc(22,18);
            }

        }
        li::before{
            content:'';
            display:inline-block;
            width:16px; height:16px;
            background-color:$white;
            transform: translateY(3px);
            margin-right:10px;
        }
    }
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification_ul{
            margin-top: 15px;
            position: relative;
        }
    }
    
    .kl-qualification_item_text{
        position: absolute;
        bottom: 50px;
        left:0;
        right: 0;
        z-index: 1;
        @include queries(md){
            position: static;
            margin: 15px 0 0 20px;
            width: f-min(270px, 20%);
            // position: relative;
        }
        img{
            width: f-min(400px,92%);
            margin-inline: auto;
            display: block;
            @include queries(md){
                width: 100%;
            }
        }
    }

    .kl-qualification_item_img{
        @include center;
        text-align: center;
        @include queries(md){
            position:absolute;
            display:inline-block;
        }
    }
    .kl-qualification_item_img:nth-of-type(1){
        @include queries(md){
            width:vw(47,768);
            bottom:30px;
            right:134px;
        }
        @include queries(xl){
            width:87px;
            bottom:0px;
            right:394px;
        }
    }
    .kl-qualification_item_img:nth-of-type(2){
        @include queries(md){
            width:vw(54,768);
            bottom:25px;
            right:74px;
        }
        @include queries(xl){
            width:109px;
            bottom:-20px;
            right:239px;
        }
    }
    .kl-qualification_item_img:nth-of-type(3){
        @include queries(md){
            width:vw(30,768);
            bottom:50px;
            right:44px;
        }
        @include queries(xl){
            width:58px;
            bottom:40px;
            right:156px;
        }
    }
    .kl-qualification-parts{
        .kl-qualification_item_img{
            top: auto;
            .kl-qualification_item_text{
                position: absolute;
                bottom: 50px;
                left:0;
                right: 0;
                @include queries(md){
                    position: static;
                    margin: 15px 0 0 20px;
                    width: f-min(270px, 20%);
                }
                img{
                    width: f-min(400px,92%);
                    margin-inline: auto;
                    display: block;
                    @include queries(md){
                        width: 100%;
                    }
                }
            }
            &:nth-of-type(1){
                width: f-min(108px,7vw);
                bottom: 25%;
                right: 0;
                @include queries(xl){
                    bottom: 35%;
                    right: -5%;
                }
            }
            &:nth-of-type(2){
                width: f-min(57px,4vw);
                bottom: 12%;
                right: 10%;
                @include queries(xl){
                    
                    right: 3%;
                }
            }
        }
    }
    .kitaweru-yakugyo-inner{  //@yakugyo
        .kl-qualification__late{
            order:3;
            width:100%;
            text-align: center;
            margin-top: 30px;
            @include queries(sm){
            }
            @include queries(md){
                // order:3;
                margin-top: 0;
                width:auto;
                max-width:288px;
            }
            @include queries(lg){
                max-width:388px;
                margin-left:50px;
            }
            @include queries(xl){
                margin-left:100px;
            }
        }
    }
    .kl-qualification-btn{
        
        position:absolute;
        top:186px;
        left:0; right:0; 
        @include center;
        text-align: center;
    }
    .kitaweru-yakugyo-inner{
        .kl-qualification-btn{
            top:206px;
        }
    }
    .kl-qualification-item_btn{
        position:relative;
        transform: translateX(-10px);
      p{
          position:relative;
      }
      p::after{
          content:'';
          display:inline-block;
          width:12px;
          height:13.42px;
          background-image:url($dir + 'kodomo-item_08.svg');
          @include bg-init;
          position:absolute;
          top:0;bottom:0;
          margin-top:auto;
          margin-bottom:auto;
          right:16.2px;

      }

    }
    .kitaweru-yakugyo-inner{
        .kl-qualification-item_btn{
            transform:translateX(0px);
        }
    }

    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification-btn{
            top:200px;
            @media(min-width:375px){
                top:220px;
            }
            
            .kl-qualification-item_btn{
                transform: translateX(0);
            }
        }
    }

    .kl-qualification-item_btn::before,
    .kl-qualification-item_btn::after{
        content:'';
        display:inline-block;
        width:71px;
        height:1px;
        background-color:$white;
        position:absolute;
        top:0;bottom:0;
        margin-top:auto;margin-bottom:auto;
    }
    .kl-qualification-item_btn::before{
        left:-83px;
    }
    .kl-qualification-item_btn::after{
        right:-83px;
    }
    .kl-qualification_ul01 {
        margin-bottom:130px;
        @include queries(md){
            margin-bottom:0;
            margin-left:20px;
        }
        @include queries(lg){
            margin-left:30px;
        }
    }
    .kl-qualification_img {
        // @include queries(sm){
            text-align: center;
            margin-left:auto;
        // }
    }

    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification_ul01{
                margin-bottom:0;
                @include queries(md){
                    margin-top:35px;
                }
            }
        }
    .kl-qualification_ul02 {
        // margin-top:130px;

        @include queries(md){
            margin-top:27px; 
            margin-left:45px;
        }
        @include queries(lg){
            margin-left:70px;
        }
    }
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-qualification_ul02{
            margin-top:0;
            @include queries(md){
                margin-top:35px;
            }
        }
    }

//---------------  kaigo ----------------------
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-qualification-inner{
            padding-bottom:55px;

            @include queries(xl){
                padding-bottom:61px;
            }
        }
        .kl-qualification-flex {
            // width:100%;
            // display:flex;
            // margin-left:10px;
            // position:relative;
            flex-wrap:wrap;

            @include queries(md){
                margin-top:53px;
                align-items: center;
            }
    
            @include queries(xl){
                // justify-content: center;
                margin-left:183px;
            }
        }

        .kl-qualification-list{
            margin-top:0;
            margin-left:10px;
            @include queries(md){
                margin-top:0px;
                margin-top:10px;
                margin-left:20px;
                width:48%;
            }
            @include queries(xl){
                margin-top:0px;
                width:32%;
            }
            // margin-left:15px;
    
            // @include queries(md){
            //     display:flex;
            // }
    
        }
     

        .kl-qualification_ul{
            // list-style-type: none;
    
            @include queries(md){
                margin-top:10px;
                padding-left:0;
            }
            @include queries(xl){
                padding-left:30px;
         
            }
            li{
                // font-size:rem(14);
                // line-height:lh-calc(21,14);
                // letter-spacing:lp-calc(50);
                // margin-bottom:8px;
    
                // @include queries(md){
                //     font-size:rem(18);
                //     line-height:lh-calc(22,18);
                // }
    
            }
            li::before{
            //     content:'';
            //     display:inline-block;
            //     width:16px; height:16px;
            //     background-color:$white;
            //     transform: translateY(3px);
            //     margin-right:10px;
            }
        }
       

        .kl-qualification_img {
            width:90%;
            @include center;
            margin-top:29px;
            margin-left:0px;
            img{
                width:100%;
            }
            @include queries(xs){
                width:vw(315,375);
                // text-align: center;
           
            }
            @include queries(md){
                width:40%;
                margin-top:0px;
                margin-left:0;
                text-align: left;
            }
            @include queries(xl){
                width:40%;
                margin-top:0px;
                margin-left:0;
                text-align: left;
            }
            img{
                max-width:315px;

                @include queries(md){
                    width:80%;
                    max-width:388px;

                }
            }
        }

        .kl-qualification_item_img:nth-of-type(1){
            @include queries(md){
                width:25px;
                height:50px;
                bottom:30px;
                right:40px;
            }
            @include queries(xl){
                width:53px;
                height:103px;
                bottom:54px;
                right:vw(248,1500);
            }
            @include queries(xxl){
                right:vw(228,1500);
            }
        }
        .kl-qualification_item_img:nth-of-type(2){
            @include queries(md){
                width:43px;
                height:44px;
                bottom:15px;
                right:0px
            }
            @include queries(xl){
                width:93px;
                height:94px;
                bottom:20px;
                right:vw(164,1500);
            }
        }
    }

//------------------------------------------

//-----  yakugyo  ---------------------------
        .kitaweru-yakugyo-inner{ //@yakugyo
            .kl-qualification-inner{
                max-width:1000px;
                @include center;
                padding-bottom:60px;
                @include queries(sm){
                    max-width:540px;
                    @include center;
                }
                @include queries(md){
                    max-width:1000px;
                    @include center;
                    padding-bottom:40px;
                    
                }
                @include queries(lg){
                    padding-top:35px;
                    
                }
            }

            .kl-qualification-flex {
                flex-wrap:wrap;
                margin-left:0;
                justify-content: center;
                @include queries(md){
                    // justify-content: flex-start;
                    align-items:center;
                }
                @include queries(xl){
                    margin-left:0;
                    // flex-wrap:wrap;
                    justify-content: center;
                }
            }
    
            .kl-qualification_heading3{
                order:1;
                margin-top:30px;
                @include queries(md){
                    width:35px;
                    margin-top:35px;
                    margin-top:0;
                }
                @include queries(lg){
                    width:47px;
                }
                
            }
    
            .kl-qualification_ul_01 {
                // margin-bottom:150px;
                margin-bottom:0px;
                @include queries(md){
                    margin-bottom:0px; 
                }
            }
            .kl-qualification_ul02{
                margin-top:-30px;
                @include queries(md){
                    margin-top:0;
                }
                li:nth-of-type(1){
                    padding-top:30px;
                    @include queries(md){
                        padding-top:0px;
                    }

                }
            }
            .kl-qualification_ul{
                padding-left:0;
                @include queries(md){
                    margin-left:10px;
                }
                @include queries(xl){
                    margin-left:30px;
                }
    
                li{
                    @include queries(md){
                        font-size:rem(16);
                    }
                    @include queries(lg){
                        margin-bottom:13px;
                    } 
                    @include queries(xl){
                        font-size:rem(18);
                    }
    
                }
            }
            .kl-qualification_item_img:nth-of-type(1){
                @include queries(md){
                    width:40px;
                    height:40px;
                    bottom:0px;
                    right:50px;
                }
                @include queries(xl){
                    width:74px;
                    height:72px;
                    bottom:4px;
                    right:90px;
                }
            }
            .kl-qualification_item_img:nth-of-type(2){
                @include queries(md){
                    width:34px;
                    height:55px;
                    bottom:-15px;
                    right:5px
                }
                @include queries(lg){
                    right:-15px;
                }
                @include queries(xl){
                    width:54px;
                    height:75px;
                    right:-120px;
                    bottom:30px;
                }
                @include queries(xxl){
                    right:-140px;
                    bottom:40px;
                }
            }

            .kl-qualification_item_img:first-of-type{
                @include queries(md){
                    bottom:-25px;
                }
                @include queries(lg){
                    right:25px;
                }
                @include queries(xl){
                    right:-60px;
                }
                @include queries(xxl){
                    right:-80px;
                }
            }
            
          
           
    
        }
    
        //----------------------


    .kl-study{
        width:100%;
        
    }
    .kl-study-inner{
        // width:vw(355,375);
        @include center;
        //--- 2024 -----
        width: f-min(1220px, 92%);


    }
    //--- 2024 -----
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-study-inner{
        width: f-min(1220px, 92%);
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-study-inner{
        width: f-min(1220px, 92%);
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-study-inner{
        width: f-min(1220px, 92%);
        }
    }
    //-- /2024 -----
    

    .ki-study-project{
        width:100%;
        margin-top:45px;

        @include queries(md){
            width:vw(486,678);
            @include center;
        }
        @include queries(xl){
            width:986px;
        }
    }

    // .kl-studies{
    //     width:100%;
    //     display:flex;
    //     flex-direction:row;
    //     flex-wrap: wrap;
    //     margin-top:60px;

    //     @include queries(md){
    //         position:relative;
    //         justify-content: center;
    //         max-width:768px;
    //         @include center;
    //         margin-top:70px;
    //     }
    //     @include queries(xl){
    //         max-width:1100px;
    //         justify-content: flex-start;
    //         max-width:1100px;
    //         margin-top:100px;
    //     }

    // }
    // .kl-studies:nth-of-type(1){
    //     margin-top:51px;
    //     @include queries(xl){
    //         margin-top:100px;
    //     }
    // }
    // .kl-studies::after{
    //     @include queries(xl){
    //         content:'';
    //         display:inline-block;
    //         position:absolute;
    //         top:0; bottom:0;
    //         margin-top:auto;
    //         margin-bottom:auto;
    //         transform: translate(-60px, -70px);
    //     }
    //     @include queries(xxl){
    //         transform: translate(0px,0px);
    //     }
    // }

    //--2024----
    .kl-study__upper{
        // width: f-min(704px,46vw);
        width: 100%;
        
        @include queries(md){
            width: f-min(704px,56vw);
        }
    }
    .kl-study__title--sub{
        width: 100%;
        max-width: 423px;
        margin-inline:auto;
        img{
            width:100%;
        }
    }
    .kl-study__wrap{
        @include queries(md){
            gap:24px;
        }
    }
    .kl-study__img{
        @include queries(md){
            width: calc(55% - 24px);
        }
        img{
            width: 100%;
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-study__img{
            @include queries(md){
                width: calc(43% - 24px);
            }
            @include queries(xl){
                width: calc(47% - 24px);
            }
        }

    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-study__img{
            @include queries(md){
                width: calc(41% - 24px);
            }
            @include queries(xl){
                width: calc(45% - 24px);
            }
        }

    }
    .kl-study__text{
        font-size: f-min(18px,3.7vw);
        @include queries(md){
            width: 48%;
            font-size: f-min(18px,1.6vw);
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-study__text{
            @include queries(md){
                width: 57%;
            }
            @include queries(xl){
                width: 53%;
            }
        }

    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-study__text{
            @include queries(md){
                width: 59%;
            }
            @include queries(xl){
                width: 55%;
            }
        }

    }

    .kl-study__lower--title{
        font-size: 21px;
        color: #fff;
        font-weight: bold;
        background-color: #E96E69;
        
        @include queries(md){
            font-size: 34px;
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-study__lower--title{
            background-color: #007FC7;
        }

    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-study__lower--title{
            background-color: #43966C;
        }

    }
    .kl-study__content{
        width: 100%;
        margin-inline: auto;
        @include queries(sm){
            width: f-min(1000px,78%);

        }
        @include queries(md){
            width: f-min(1000px,92%);
        }
    }

    //--/2024-----

   
    // .kl-studies01::after{
    //     @include queries(xl){
    //         background-image:url($dir + 'kodomo-item_19.svg');
    //         @include bg-init;
    //         width:97px;
    //         height:97px;
    //         right:-63px;
    //         // transform:translate(-30px,20px);
    //     }
    // }
  

    // .kitaweru-kodomo-inner{ //@kodomo
    //     .kl-studies02::after{
    //         content:'';
    //         display:inline-block;
    //         position:absolute;
    //         top:100px; bottom:0;
    //         margin-top:auto;
    //         margin-bottom:auto;
    //         background-image:url($dir + 'kodomo-item_20.svg');
    //         width:93px;
    //         height:93px;
    //         background-size: cover;
    //         bottom:auto;
    //         right:-5px;
    //         @include queries(sm){
    //             width:123px;
    //             height:123px;
    //         }
    //         @include queries(md){
    //             width:93px;
    //             height:93px;
    //             top:-10px;
    //             right:20px;
    //         }
    //         @include queries(xl){
    //             width:123px;
    //             height:123px;
    //             right:-75px;
    //             top:0; bottom:0;
    //         }
    //     }
    // }
    // .kl-studies03::after{
    //     @include queries(xl){
    //         background-image:url($dir + 'kodomo-item_21.svg');
    //         width:87px;
    //         height:66px;
    //         right:-63px;
    //     }
    // }
    // .kl-study_heading2_img{
    //     order:1;
    //     width:percent(95,355);

    //     @include queries(sm){
    //         width:15%;
    //         // align-self: center;
    //     }
    //     @include queries(md){
    //         width:percent(55,768); 
    //         width:10%;
    //         // align-self: center;
    //     }
    //     @include queries(xl){
    //         width:10%;
            
    //     }

    //     img{
    //         width:68px;
    //         @include queries(md){
    //             width:100%;
    //         }
    //         @include queries(xl){
    //             max-width:111px;
    //         }
    //     }
    // }
    // .kl-study_img{
    //     order:3;
    //     text-align: center;
    //     @include center;
    //     margin-top:30px;

    //     @include queries(md){
    //         order:2;
    //         width:40%;
    //         @include none-auto;
    //         margin-left:15px;
    //         margin-top:0px;

    //     }
    //     @include queries(xl){
    //         max-width:450px;
    //         margin-left:51px;
    //     }
    // }
    // .kl-study_text_01{
    //     order:2;
    //     width:percent(255,355);

    //     @include queries(md){
    //         width:40%;
    //         order:3;
    //         margin-left:15px;
    //     }
    //     @include queries(xl){
        
    //         margin-left:45px;
    //     }
    //     .kl-study_heading3{
    //         font-size:rem(21);
    //         font-weight: 700;

    //         @include queries(md){
    //             font-size:rem(19);
    //         }
    //         @include queries(xl){
    //             font-size:rem(34);
    //         }
    //         span{
    //             display:inline-block;
    //             color:$white;
    //             background-color:$kodomo-pink;
    //             padding:5px 8px;
    //             margin-top:10px;
    //             @include queries(md){
    //                 margin-top:0px;
    //                 padding:2px 8px;
    //             }
    
    //         }
    //         span:nth-of-type(2){
    //             padding-right:0;
    //             margin-top:11px;
    //             @include queries(md){
    //                 margin-top:7px;
    //             }
    
    //         }

    //     }
    // }


    // .kl-study_text_02{
    //     order:4;

    //     @include queries(md){
    //         width:40%;
    //         margin-left:auto;
    //         position:absolute;
    //         right:25px;
    //         top:80px;
            
    //     }
    //     @include queries(xl){
    //         width:40%;
    //         margin-left:auto;
    //         position:absolute;
    //         right:15px;
    //         top:140px;
            
    //     }
        
    //     .kl-study_heading4{
    //         font-size:rem(20);
    //         font-weight:700;
    //         color:$kodomo-pink;
    //         margin-top:20px;
            
    //         @include queries(md){
    //             font-size:rem(17);
    //             margin-top:0px;
    //         }
    //         @include queries(xl){
    //             font-size:rem(23);
    //             margin-top:0px;
    //         }
    //     }

    //     .kl-study_paragraph{
    //         font-size:rem(14);
    //         line-height: lh-calc(26,14);
    //         margin-top:10px;
    //         @include queries(md){
    //             margin-top:0px;
    //         }
    //         @include queries(xl){
    //             font-size:rem(16);
    //             margin-top:10px;
    //         }
    //     }
    // }
// --------------kaigo ---------------------
    .kitaweru-kaigo-inner{  //@kaigo

        // .kl-study_heading3{
        //     span{
        //         background-color:$kaigo-srong-blue; 
        //     }

        // }

        .kl-study_heading4{
            color:$kaigo-srong-blue; 
         
        }
    

        // .kl-studies01::after{
        //     @include queries(xl){
        //         background-image:url($dir + 'kaigo-item_12.svg');
        //         @include bg-init;
        //         width:85px;
        //         height:87px;
        //         right:-50px;
        //         // transform:translate(-30px,20px);
        //     }
        // }
      

        // .kl-studies02::after{
        //     @include queries(xl){
        //         background-image:url($dir + 'kaigo-item_16.svg');
        //         width:90px;
        //         height:75px;
        //         right:-50px;
        //     }
        // }
        // .kl-studies03::after{
        //     @include queries(xl){
        //         background-image:url($dir + 'kaigo-item_17.svg');
        //         width:71px;
        //         height:41px;
        //         right:-53px;
        //     }
        // }

    }

//--------------------------------------
//----- yakugyo --------------
    .kitaweru-yakugyo-inner{ //@yakugyo
        // .kl-study_heading3{
        //     span{
                
        //         color:$white;
        //         background-color:$yakugyo-green02;
        //     }
        // }

        .kl-study_heading4{
            color:$yakugyo-green02; 
         
        }

        // .kl-studies01::after{
        //     @include queries(xl){
        //         background-image:url($dir + 'yakugyo-item_21.svg');
        //         @include bg-init;
        //         width:65px;
        //         height:65px;
        //         right:-45px;
        //         transform:translate(0px,-30px);
        //     }
        // }
        // .kl-studies02::after{
        //     @include queries(xl){
        //         background-image:url($dir + 'yakugyo-item_22.svg');
        //         width:70px;
        //         height:75px;
        //         right:-47px;
        //         transform:translate(0px,-30px);
        //     }
        // }
        // .kl-studies03::after{
        //     @include queries(xl){
        //         background-image:url($dir + 'yakugyo-item_23.svg');
        //         width:79px;
        //         height:80px;
        //         right:-52px;
        //         transform:translate(0px,-30px);
        //     }
        // }

        // .kl-yakugyo-license{
        //     width:100%;
        //     padding-top:26.5px;
            

        // }
        // .kl-yakugyo-license-inner{
        //     text-align: center;
        //     img:nth-of-type(1){
        //         width:96%;

        //         @include queries(md){
        //             max-width:365px;
        //             // max-width:531px;
        //         }
        //         @include queries(xl){
        //             max-width:531px;
        //         }
        //     }
        //     img:nth-of-type(2){
        //         width:90%;
        //         margin-top:15px;
        //         @include queries(md){
        //             // max-width:534px;
        //             max-width:304px;
        //             margin-left:45px;
        //         }
        //         @include queries(xl){
        //             width:90%;
        //             max-width:364px;
        //             margin-left:45px;
        //         }
        //     }
        // }

        .ki-study-project{
            margin-top:0px;
        }
    }

    .kl-schedule{
        margin-top:83px;
        width:100%;
        background-color:$kodomo-pink;

        @include queries(md){
            margin-top:120px; 
            
            
        }
        @include queries(xl){
            margin-top:149px; 
        }

    }
     //-----2024------
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-schedule{
            margin-top:0 !important;
        }

    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule{
            margin-top:0 !important;
            background-color: #007FC7;;
        }

    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule{
            margin-top:0 !important;
            background-color:#43966C;
        }

    }
    .kl-schedule-border{
        height: 10px;
        width: 100%;
        background-color: #F48E8C;
        @include queries(md){
            height: 15px;
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-border{
            background-color: #007FC7;
        }

        }
        .kitaweru-yakugyo-inner{ //@yakugyo
            .kl-schedule-border{
                background-color: #8CBD97;
            }
    
    }
    //----/2024------

    .kl-schedule-bg {
        width:100%;
        height:0;
        padding-top:percent(566,750);
        background-image:url($dir + 'kodomo-bg-item-sp_02.jpg');
        @include bg-init;
        position:relative;

        @include queries(md){
            padding-top:percent(920,3000);
            background-image:url($dir + 'kodomo-bg-item-pc_02.jpg');
        }
        @include queries(xl){

        }
    }
    //-----2024------ marge
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-schedule-bg {
            background-size: cover;
        }

    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-bg {
            background-size: cover;
        }

    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-bg {
            background-size: cover;
        }

    }
    //----/2024------
    .kl-schedule-bg-inner {
        width:vw(355,375);
        position:absolute;
        bottom:20px;
        left:0;
        right:0;
        top:0;
        bottom:0;
        @include center;

        @include queries(md){
            max-width:1220px;
        }

        img{
            width:vw(280,375);
            position:absolute;
            bottom:20px;
            left:10px;
            @include queries(md){
                width:vw(314,678);
            }
            @include queries(xl){
                width:vw(414,1500);
                max-width:414px;
                bottom:46px;
            }
            @include queries(xxl){
                  
                bottom:106px;
            }
        }

      
    }



    .kl-schedule-bg-inner::before{
        content:'';
        display:inline-block;
        width:143px; height:143px;
        background-image:url($dir + 'kodomo-item_22.svg');
        @include bg-init;
        position:absolute;
        top:-40px;left:0;

        @include queries(md){
            width:158px; height:158px;  
            left:vw(50,678);
            top:-50px;left:0;
        }
        @include queries(xl){
            width:274px; height:274px;  
            left:0;
        }
        
    }
    //---------2024------------- marge
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-schedule-bg-inner::before {
            top:20px;
            @include queries(md){
                top:-50px;
            }
        }

    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-bg-inner::before {
            top:20px;
            @include queries(md){
                top:-50px;
            }
        }

    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-bg-inner::before {
            top:20px;
            @include queries(md){
                top:-50px;
            }
        }

    }
    //------------------------------------

    .kl-schedule__three-power{
        background-color: #fff;
        padding: 20px 0 51px 0;
        @include queries(md){
            padding: 30px 0 51px 0;
        }
    }
    .kl-schedule__three-power--inner{
        width: f-min(1220px, 92%);
        margin-inline:auto;
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule__three-power--inner{
            width: f-min(1000px, 92%);
        }
    }
    .kl-schedule__three-power--list{
        display: grid;
        grid-template-columns: repeat(3,1fr);
         gap:  15px 7px;
         @include queries(md){
            gap:16px;
            grid-template-columns: repeat(6,1fr);
            }

        }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule__three-power--list{
            @include queries(md){
                gap:16px;
                grid-template-columns: repeat(5,1fr);
                }
        }
    }
    .kl-schedule__three-power--lower{
        width: f-min(1000px, 78%);
        @include queries(md){
            width: f-min(1000px, 88%);
        }
    }
    //-------/2024----------------------
    //----------  yakugyo  -------------------
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-bg-inner::before{
            background-image:url($dir + 'yakugyo-item_24.svg');
        }

        .kl-schedule-bg {
            background-image:url($dir + 'yakugyo-bg-item-sp_02.jpg');
    
            @include queries(md){
                background-image:url($dir + 'yakugyo-bg-item-pc_02.jpg');
            }
        }

        .kl-schedule-txt{
            width:100%;
            background-color:$yakugyo-green02;
        }
        .kl-schedule-txt-inner{
            width:94%;
            @include center;
            padding-top:50px;
            margin-bottom:40px;
            color:$white;
            max-width:1000px;
            @include center;
            position:relative;
            
        }
        .kl-schedule_paragraph{
            font-size:rem(14);
            line-height:lh-calc(28,14);
            margin-bottom:15px;
            max-width:750px;
        

        }
        .kl-schedule_paragraph-samll{
            display:block;
            width:100%;
            font-size:rem(12);
            line-height:lh-calc(22,12);
            display:inline-flex;
            max-width:823px;
        
            span:nth-of-type(2){
                margin-left:5px;
            }
        }
        .kl-schedule-txt_img:nth-of-type(1){
            display:none;
            position:absolute;
            
            bottom:50px;
            @include queries(lg){
                display:inline-block;
                width:40px;
                height:38px;
                right:100px;
                bottom:20px;
            }
            @include queries(xl){
                width:74px;
                height:62px;
                right:84px;
                
            }
        }
        .kl-schedule-txt_img:nth-of-type(2){
            display:none;
            
            position:absolute;
            right:40px;
            bottom:50px;
            @include queries(lg){
                display:inline-block;
                width:46px;
                height:46px;
            }
            @include queries(xl){

                width:83px;
                height:85px;
                right:0px;
                bottom:50px;
            }
        }
        // .kl-schedule-discription{
        //     width:100%;
            
        //     @include queries(md){
        //         background-color:$yakugyo-green02;
        //     }
        // }
    }
    //--------------------------------------

    .kl-schedule-60-inner{
        width:vw(355,375);
        @include center;

        @include queries(md){
            max-width:1220px;
        }
       
    }

    .kl-schedule-60_heading2_img {
        text-align: center;
        width:vw(302,355);
        @include center;
        margin-top:50px;
        position:relative;

        @include queries(md){
            width:vw(332,768);
        //---------2024-------------
            margin-top:81px;
        //---------/2024-------------
        }
        @include queries(xl){
            width:vw(532,1500);
        }
    }
    .kl-schedule-60_heading2_img::before,
    .kl-schedule-60_heading2_img::after{
        content:'';
        display:inline-block;
        @include bg-init;
        position:absolute;
    }
    .kl-schedule-60_heading2_img::before{
        width:34.87px;
        height:44.68px;
        background-image:url($dir + 'kodomo-item_24.svg');
        bottom:-60.68px;
        left:-20px;

        @include queries(md){
            width:34px;
            height:49px;
            bottom:-20.68px;
            left:-55px;
        }
        @include queries(xl){
            width:54px;
            height:69px;
            left:-105px;
        }

    }
    //---------2024-------------
     .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-60_heading2_img::before{
            background-image:url($dir + 'yakugyo-item_38.svg');
        }
    }
    //---------/2024-------------
    .kl-schedule-60_heading2_img::after{
        width:47.3px;
        height:56.64px;
        background-image:url($dir + 'kodomo-item_25.svg');
        bottom:-67.3px;
        right:-20px;

        @include queries(md){
            width:47px;
            height:54px;
            bottom:-22.3px;
            right:-60px;
        }
        @include queries(xl){
            width:87px;
            height:104px;
            right:-110px;
        }
    }
    //---------2024-------------
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-60_heading2_img::after{
            background-image:url($dir + 'yakugyo-item_39.svg');
        }
    }
    //---------/2024-------------
    .kl-schedule-60_heading3_img {
        width:vw(176,375);
        @include center;
        margin-top:37px;
        text-align: center;
        margin-bottom:20px;

        @include queries(md){
            text-align: center;
            margin-top:50px;
            margin-bottom:30px;
        }
    }
    //---------2024-------------
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-60_heading3_img{
            @include queries(md){
                margin-bottom:10px;

            }
        } 
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-60_heading3_img{
            @include queries(md){
                margin-bottom:10px;

            }
        } 
    }
    //---------/2024-------------

    .kl-schedule-slide{
        width:100%;
        // padding-top:40px;

    }

    .kitaweru-kaigo-inner{  //@kaigo
        @include queries(xl){
            .kl-schedule-slide{
                padding-top:40px;
            }
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-slide{
            
            max-width:1000px;
            @include center;
            margin-top: -37px;
            padding-top:20px;
            @include queries(md){
                background-color: #8CBD97;
                padding-top:40px;
            }
        }
    }
//----- slick------------
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto !important;
    }
    .slick-arrow:before{
        content:""!important;
    }
    .prev-arrow{
        position:absolute;
        top: 0;
        bottom:0;
        left:0;
        margin-top:auto;
        margin-bottom:auto;
        transform: rotate(180deg);
        z-index:1000;
        opacity:1;
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    .prev-arrow.slick-disabled,
    .next-arrow.slick-disabled{
        cursor:default;
       opacity:0;
       transition: 0.3s;
       transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    .next-arrow{
        position:absolute;
        width:26px;
        top: 0;
        right:0;
        bottom:0;
        margin-top:auto;
        margin-bottom:auto;
        opacity:1;
        transition-property: opacity;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
        @include queries(md){
            width:34px;
            right:50px;
        }
        @include queries(lg){
            right:100px;
        }
        @include queries(xl){
            right:0px;
        }
    }
//---------------------------
 

    .kitaweru-kaigo-inner{  //@kaigo
        @include queries(xl){
            .kl-schedule-slide{
                padding-top:20px;
            }
        }
    }
//----- slick------------
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto !important;
    }
    .slick-arrow:before{
        content:""!important;
    }
    .prev-arrow,
    .next-arrow,
    .start-arrow,
    .end-arrow{
        position:absolute;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width:26px;
        height:48px;
        top: 0;
        bottom:0;
        margin-top:auto;
        margin-bottom:auto;
        // transition-property: opacity;
        // transition-duration: 0.2s;
        // transition-timing-function: cubic-bezier(.23,-0.1,.83,.26);
        opacity:1;
        // animation-name: a;
        // animation-fill-mode: forwards;
        // animation-duration: 1s;
        cursor: pointer;
        @include queries(sm){
            width:28px;
            height:50px;
        }
        @include queries(md){
            width:28px;
            height:50px;
        }
      
        @include queries(lg){
            // width:35;
            // height:60px;

        }
        @include queries(xl){
            width:35px;
            height:60px;

        }
    }

    
   
    .prev-arrow{
        left:0;
        transform: rotate(180deg);

        @include queries(sm){
            left:25px;
           
        }
        @include queries(md){
            left:30px;
           
        }
        @include queries(lg){
            left:-15px;
        }
        @include queries(xl){
            
            left:-10px;
        }
        @include queries(xxl){
            
            left:-10px;
        }
    }
    .next-arrow{
        right:0;
        @include queries(sm){
            right:25px;
        }
        @include queries(md){
            right:-5px;
        }
        @include queries(lg){
            right:-30px;
        }
        @include queries(xl){
            
            right:-50px;
        }
        @include queries(xxl){
            
            right:-70px;
        }
    }

    .start-arrow{
        left:-33px;
        @include queries(xs){
            
            left:-25px;
        }
        @include queries(md){
            
            left:-35px;
        }
        @include queries(lg){
            
            left:-50px;
        }
        @include queries(xl){
            
            left:-45px;
        }
        @include queries(xxl){
            
            left:-60px;
        }
    }
    .end-arrow{
        right: -33px;
        @include queries(xs){
            
            right:-25px;
        }
        @include queries(md){
            
            right:-35px;
        }
        @include queries(lg){
            
            right:-50px;
        }
        @include queries(xl){
            
            right:-45px;
        }
        @include queries(xxl){
            
            right:-60px;
        }
    }

    .prev-arrow{
        background-image:url($dir + 'common-item_13.svg');
    }
    .next-arrow{
        background-image:url($dir + 'common-item_13.svg');
    }
   
    .start-arrow.slick-disabled{
        width:30px;
        height:172px;
        border-radius: 5px;
        
        // transition: 0.2s;
        // transition-delay: 0.5s;
        // transition-timing-function: cubic-bezier(.23,-0.1,.83,.26);
        // transition-property: opacity;
        // opacity:1;
        z-index:100;
        animation-name: a;
        animation-fill-mode: forwards;
        animation-duration: 1s;
    
    }

    .end-arrow.slick-disabled{
        width:30px;
        height:172px;
        // transition: 0.2s;
        // transition-timing-function: cubic-bezier(.23,-0.1,.83,.26);
        // opacity:1;
        // transition-delay: 0.5s;
        // transition-property: opacity;
        animation-name: a;
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }


    
     
   

    @keyframes a {
        0%{
            opacity:0;
        }
        100%{
            opacity:1;
        }
        
    }
    @keyframes b {
        0%{
            opacity:1;
        }
        100%{
            opacity:0;
        }
        
    }

    .start-arrow.slick-disabled,
        .end-arrow.slick-disabled{
            cursor:default;
            width:30px;
            height:172px;
            @include queries(xs){
                width:35px;
                 height:190px;
            }
            @include queries(sm){
                width:49px;
                height:236px;
            }
            @include queries(md){
                width:40px;
                height:194px;
            }
            @include queries(lg){
                width:35px;
                height:173px;
            }
            @include queries(xl){
                width:42px;
                height:207px;
            }
            @include queries(xxl){
                width:49px;
                height:236px;
            }
        }
    // ---- kodomo ------
    .kitaweru-kodomo-inner{ //@kodomo
       

        .start-arrow{
            background-image:url($dir + 'common-item_30.svg');
        }
        .end-arrow{
            background-image:url($dir + 'common-item_14.svg');
        }

        .start-arrow.slick-disabled{
            background-image:url($dir + 'common-item_17.svg') !important;
        }

        .end-arrow.slick-disabled{
            background-image:url($dir + 'common-item_29.svg') !important;
        }  
    }
   //---------------------------


   // --- yakugyou ---
   .kitaweru-yakugyo-inner{ //@yakugyo
    
    .start-arrow{
        background-image:url($dir + 'common-item_31.svg');
    }
    .end-arrow{
        background-image:url($dir + 'common-item_15.svg');
    }

    .start-arrow.slick-disabled{
        background-image:url($dir + 'common-item_16.svg') !important;
    }

    .end-arrow.slick-disabled{
        background-image:url($dir + 'common-item_28.svg') !important;
    }  
}
//---------------------------
// course
   .hsc-course-inner{  //@course
    
       
    .start-arrow{
        background-image:url($hsc_course + 'common-item04.svg');
    }
    .end-arrow{
        background-image:url($hsc_course + 'common-item03.svg');
    }
    
    .start-arrow.slick-disabled{
        background-image:url($hsc_course + 'common-item02.svg') !important;
    }
    
    .end-arrow.slick-disabled{
        background-image:url($hsc_course + 'common-item01.svg') !important;
    }  
   }

    .kl-schedule-slide-inner {
        padding-bottom:22px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        @include queries(md){
            padding-bottom:70px;
            @include center;
        }
        @include queries(md){
            max-width: 1120px;
          
        }
      
    }
    .kl-schedule-slide-item {
        width:89%;
        max-width:290px;
        display:flex;
        //-----2024------ commentout
        border:1px solid $white;
        border-radius:13px;
        //-----/2024------
        // overflow: hidden;
        @include center;
        position:relative;
        margin-top:43px;
        padding:1px;

        @include queries(xs){
            width:100%;
            max-width:331px;
        }
        @include queries(sm){
            width:100%;
            max-width:381px;
        }
        @include queries(md){
            margin-top:85px;
            max-width:583px;
        }

    }
    //-----2024------
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-schedule-slide-item {
            border-radius: 0;
            border:none;
            @include queries(md){
                max-width:555px;
            }

        }

     }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-slide-item {
            border-radius: 0;
            border:none;
            @include queries(md){
                max-width:555px;
            }

        }

     }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-slide-item {
            border-radius: 0;
            border:none;
            @include queries(md){
                max-width:555px;
            }

        }

     }
    .kl-schedule-teacher{
        width: 100%;
        background-color: #fff;
        @include queries(md){
            background-color:#F48E8C;
            padding-bottom: 70px;
        }
    }
    .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-teacher{
            @include queries(md){
                background-color:#007FC7;
            }
        }

    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-teacher{
            @include queries(md){
                background-color:#43966C;
            }
        }

     }
    .kl-schedule-teacher-item{
        max-width:340px;
        transform: translate(-5px);
        @include queries(xs){
        
            max-width:340px;
        }
        @include queries(sm){
        
            max-width:395px;
        }
        @include queries(md){
            max-width:605px;
            transform: translate(-10px);
            

        }
    }
     //----/2024------


    .kl-schedule-slide-item-tag{
        width:70px;
        height:70px;
        position:absolute;
        top:-25px;
        left:-10px;
        z-index:10;

        
        @include queries(md){
            width:123px; height:123px;
            top:-45px;
            left:-21px;
        }
        @include queries(lg){
            width:98px; height:98px;
            top:-45px;
            left:-21px;
        }
        @include queries(xl){
            width:123px; height:123px;
            top:-60px;
            left:-40px;
        }
    }

 
    .kl-schedule-slide_img {
        width:43%;
       
        overflow: hidden;
         //-----2024------ 
        // border-radius:13px 0 0 13px;
        // transform:scale(1.012) translate(-0.8px, 0.4px);
        //-----/2024------
        border-radius: 0;
        transform:scale(1.0) translate(0);

        @include queries(sm){
            width:48%;
            // transform:scale(1.012) translate(-0.15px, -0.05px);
        }
        @include queries(md){
            width:100%;
            max-width:290px;
            // transform:scale(1.011) translate(0.85px, 0px);
        }
        @include queries(xl){
            // transform:scale(1.011) translate(0.45px, 0px);
        }
        img{
            display:block;
            width:100%;
            object-fit: cover;
            height:100%;
            // transform:scale(1.05);
        }

        

    }

    //-----2024------ marge
    //  .kitaweru-kodomo-inner{ //@kodomo
    //     .kl-schedule-slide_img {
    //         border-radius: 0;
    //         transform:scale(1.0) translate(0);
    //     }

    // }
    // .kitaweru-kaigo-inner{  //@kaigo
    //     .kl-schedule-slide_img {
    //         border-radius: 0;
    //         transform:scale(1.0) translate(0);
    //     }

    // }
    // .kitaweru-yakugyo-inner{ //@yakugyo
    //     .kl-schedule-slide_img {
    //         border-radius: 0;
    //         transform:scale(1.0) translate(0);
    //     }
    // }

    //----/2024------
    
    .kl-schedule-slide_content {
        width:57%;
        padding-left:10px;
        padding-right:10px;
        padding-bottom:10px;
        //---------2024------------- commentout remove
        // background-color: #ECE2D4;
        //---------/2024------------
        @include queries(md){
            width:50%;
            padding-left:17px;
            padding-right:15px;
            padding-bottom:15px;
        }
       
    }
     //-----2024------ marge
       .kitaweru-kodomo-inner{ //@kodomo
        .kl-schedule-slide_content {
            background-color: #ECE2D4;
        }

    }
     .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-slide_content {
            background-color: #ECE2D4;
        }

    }
     .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-slide_content {
            background-color: #ECE2D4;
        }
    }
    //----/2024------

    .kl-schedule-slide_heading4{
        font-size:rem(14);
        font-weight:$font-weight-bold;
        letter-spacing: lp-calc(100);
        line-height:lh-calc(23,15);
        color:$white;
        margin-top:15px;
        //----2024------ commentout remove
        // color:#1A1311;
        //----/2024------

        @include queries(xs){
            font-size:rem(15);
        }
        @include queries(md){
            font-size:rem(20); 
            margin-top:26px;
            line-height:lh-calc(28,20);
        }
       
    }
    //-----2024------ marge
    .kitaweru-kodomo-inner{ //@kodomo
        .kl-schedule-slide_heading4{
            color:#1A1311;
        }

    }
     .kitaweru-kaigo-inner{  //@kaigo
        .kl-schedule-slide_heading4{
            color:#1A1311;
        }

    }
     .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-slide_heading4{
            color:#1A1311;
        }
    }
    //----/2024------
    .kl-schedule-slide_paragraph{
        font-size:rem(12);
        line-height:lh-calc(22,13);
        margin-top:10px;
        margin-bottom:0;

        @include queries(md){
            font-size:rem(14);
            line-height:lh-calc(25,14);
            margin-top:20px;
        }
    }


    .kl-schedule-oneday{
        width:100%;
        background-color: $kodomo-light_pink;
        padding-bottom:40px;
    }

    .kl-schedule-oneday-inner{
        width:vw(355,375);
        @include center;
        @include queries(md){
            max-width:1190px;
        }
    }
    .kl-schedule-oneday-heading{
        padding-top:24px;
        strong{
            font-size:rem(18);

            @include queries(md){
                font-size:rem(20);
            }
        }
        font-size:rem(14);
        @include queries(md){
            font-size:rem(16);
            padding-top:31px;
        }


    }
    .kl-schedule-oneday-start{
        display:flex;
        margin-top:27.7px;
    }

    .kl-schedule-oneday-start-slide{
        width:82%;
        @include center;
        @include queries(xs){
            width:89%;
            transform:translateX(0px);
        }
       
        @include queries(md){
            width:82%;
            max-width:596px;
            @include center;
            text-align: center;
        }
        @include queries(lg){
            width:100%;
            max-width:790px;
        }
        @include queries(xl){
            
            max-width:1000px;
        }
        @include queries(xxl){
          max-width:1200px;
        }
    }
    .kls-oneday-slide-item{
        width:100% !important;
        margin-left:auto;
        margin-right:auto;
        max-width:250px;
        border-radius: 10px;
        position:relative;
        overflow: hidden;

        @include queries(xs){
            max-width:274px;
        }
        @include queries(sm){
            max-width:340px;
        }
        @include queries(md){
            max-width:280px;
        }
        @include queries(lg){
            max-width:250px;
            // margin-left:15px;
            // margin-right:15px;
        }
        @include queries(xl){
            max-width:300px;
        }
        @include queries(xxl){
            width:339px !important;
            max-width:339px;
        }
    }
  
    
    .kls-oneday-slide-item_paragraph{
        width:100%;
        position:absolute;
        bottom:0;
        left:0;right:0;
        background-color:$kodomo-pink;
        color:$white;
        font-size:rem(14);
        padding-left:10px;
        padding-top:4px;
        padding-bottom:4px;

    }

    //------------ kaigo ------------------------------------

    .kitaweru-kaigo-inner{  //@kaigo

        .kl-schedule{
            background-color:$kaigo-srong-blue;
           
    
        }

        .kl-schedule-bg {
     
            background-image:url($dir + 'kaigo-bg-item-sp_02.jpg');
    
            @include queries(md){
                background-image:url($dir + 'kaigo-bg-item-pc_02.jpg');
            }
        }

        .kl-schedule-bg-inner::before{
        
            background-image:url($dir + 'kaigo-item_18.svg');
            
        }

        .kl-schedule-60_heading2_img::before{
            width:45px;
            height:37px;
            background-image:url($dir + 'kaigo-item_19.svg');
            bottom:-60.68px;
            left:-20px;
    
            @include queries(md){
                width:27px;
                height:39px;
                bottom:-20.68px;
                left:-55px;
            }
            @include queries(xl){
                width:77px;
                height:64px;
                left:-132px;
            }
    
        }
        .kl-schedule-60_heading2_img::after{
            width:53px;
            height:46px;
            background-image:url($dir + 'kaigo-item_20.svg');
            bottom:-67.3px;
            right:-20px;
    
            @include queries(md){
                width:37px;
                height:50px;
                bottom:-22.3px;
                right:-60px;
            }
            @include queries(xl){
                width:91px;
                height:79px;
                right:-156px;
            }
        }

        .kl-schedule-slide{
            max-width:1000px;
            @include center;
            margin-top: -37px;
            padding-top:20px;
            @include queries(md){
                padding-top:40px;
                background-color: $kaigo-blue;
            }
        }
        
      

        .kl-schedule-slide-item::before{
            background-image:url($dir + 'common-item_02.svg');
        }
        .kl-schedule-slide-item.kl-schedule-slide-item_02::before{
            background-image:url($dir + 'common-item_12.svg');
        }
    }

    //-----------  yakugyo  ------------------------
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-schedule-oneday{
            background-color: $yakugyo-light-green2;
        }
  
        // .kls-oneday-slide-item_paragraph{
        //     background-color:$yakugyo-green02;
        //     color:$white;
    
        // }
    }

  

//------------------------------------------------------
    // .kl-schedule-discription {
    //     width:100%;
    //     background: repeating-linear-gradient(-45deg,#fef7fa,#fef7fa 10px,#fff 0,#fff 20px);
    // }

    // .kitaweru-yakugyo-inner{ //@yakugyo
    //     .kl-schedule-discription {
    //         width:100%;
    //         background:none;
    //         @include queries(md){
    //             background-color: $yakugyo-green02;
    //         }
    //     } 
    // }

    // .kls-discription_paragraph{
    //     font-size:rem(16);
    //     font-weight:$font-weight-bold;
    //     line-height:lh-calc(32,16);
    //     padding-top:45px;
    //     padding-bottom:45px;

    //     @include queries(md){
    //         font-size:rem(16); 
    //     }
    //     @include queries(xl){
    //         max-width:828px;
    //         @include center;
    //     }
    // }
    // .kl-schedule-discription-inner{
    //     width:vw(355,375);
    //     @include center;
    // }

    .kl-curriculum-inner {
        width:vw(355,375);
        @include center;

        @include queries(md){
            max-width:1110px;
            @include center;
        }
    }
    // .kl-schedule-thoughts-inner{
    //     .kl-curriculum-inner {
    //         width:vw(355,375);
    //         @include center;
    //         max-width:1110px;
    //     }
    // }

    // .kl-schedule-thoughts-flex{
    //     display:flex;
    //     flex-direction:column;

    //     @include queries(md){
    //         flex-direction:row;
    //     }
    //     @include queries(xl){
    //         max-width:947px;
    //         @include center;
    //     }
    // }
    // .kl-schedule-thoughts-left {
    //     // order:2;

    //     @include queries(md){
    //         order:1;
    //         width:35%;
    //     }
    // }
    // .kl-schedule-thoughts-right {
    //     // order:1;

    //     @include queries(md){
    //         order:2;
    //         width:65%;
    //     }
    // }

    // .kl-schedule-thoughts{
    //     width:100%;
    //     // padding-top:42px;
    //     background-color:$white;
    //     @include queries(md){
    //         padding-top:60px;
    //     }

    // }

    // .kitaweru-kaigo-inner{  //@kaigo
    //     @include queries(xl){
    //         .kl-schedule-thoughts{
    //             padding: 62px ;
    //             padding-bottom:0;
    //      }
    //     }
    // }
    // .kl-schedule-thoughts-inner{
    //     width:vw(355,375);
    //     @include center;
    // }
    // .kl-schedule-thoughts_heading4{
    //     font-size:rem(20);
    //     font-weight:$font-weight-bold;
    //     letter-spacing:lp-calc(50);
    //     line-height:lh-calc(27,20);
    //     color:$htc-black;
    //     display: table;
    //     margin:15px auto 0;
    //     @include queries(md){
    //         color:$white; 
    //         display:block;
    //         margin:0;
    //     }

    //     @include queries(xl){
    //         font-size:rem(22);  
    //         line-height:lh-calc(27,22);
         
    //     }

    //     span{
    //         font-size:rem(14);
    //         @include queries(xl){
    //             font-size:rem(16);  
    //         }

    //     }
    // }

    // .kl-schedule-thoughts-box_paragraph{
    //     width:100%;
    //     font-size:rem(14);
    //     line-height:lh-calc(26,14);
    //     border:1px solid $htc-black;
    //     border-radius:6px;
    //     // color:$white;
    //     color:$htc-black;
    //     padding:15px 23px;
    //     margin-top:13px;
    //     position:relative;
    //     @include queries(md){
    //         width:88%;
    //         color:$white;
    //         border:1px solid $white;
    //     }
    //     @include queries(xl){
    //         width:100%;
    //         font-size:rem(16); 
    //         padding:25px 32px; 
    //         position:relative;
    //         max-width:576px;
    //     }
    // }




    // .kl-schedule-thoughts-box_paragraph::after{
    //     content:'';
    //     display:inline-block;
    //     width:50px;
    //     height:64px;
    //     background-image:url($dir + 'common-item_23.png');
    //     @include bg-init;
    //     position:absolute;
    //     bottom:-34px;
    //     right:0;

    // }

    // .kl-schedule-thoughts-left {
    //     // margin-top:40px;
    //     margin-top:0px;

    //     @include queries(md){
    //         // margin-top:0px;
    //     }

    //     .kl-schedule-thoughts_heading3{
    //         // width:vw(248,375);
    //         @include center;
    //         text-align: center;
    //         width: 100vw;
    //         margin-left: calc(-50vw + 50%);
    //         // background-color:$kodomo-pink;
    //         padding:25px 0;
    //         @include queries(md){
    //             text-align: left; 
    //             width:vw(208,678);
    //             background-color:transparent;
    //             margin-left: 0;
    //         }
    //         @include queries(xl){
    //             max-width:248px;
    //         }
    //     }

    //     .kl-schedule-thoughts_img{
           
    //         width:vw(262,375);
    //         @include center;

    //         @include queries(md){
    //             width:vw(222,768);
    //             margin-left:0;
    //             margin-right:0;
    //         }
    //         @include queries(md){
    //             max-width:262px;
    //         }

    //     }
    // }

    .kl-curriculum{
        width:100%;
        padding-top:116px;

    }
    .kitaweru-kodomo-inner{  //@kodomo
        .kl-curriculum{
            .table{
                @include queries(md){
                    max-width: 31%;
                }
                @include queries(lg){
                    width:100%;
                    max-width:310px;
                }
                @include queries(xl){
                    width:100%;
                    max-width:335px;
                }
                &.kl-curriculum-tag {
                    position: relative;
    
                    &.style{
                        margin-top:100px;
                        @include queries(xl){
                            margin-top:80px;
                        }
                    }
        
                    caption{
                        position:absolute;
                        top:-60px;
                        left:0;
    
                        span{
                            font-size:18px;
                            color:$white;
                            font-weight:500;
                            background-color:$kodomo-pink;
                            padding:4px 16px;
                            border-radius:15px;
                        }
                    }
                }
                &.last-item{
                    @include queries(md){
                        margin-top:85px;
                        max-width:310px;
                    }
                }
            }
        }
    }
    .kl-curriculu-inner{

    }
    .kitaweru-kodomo-inner{ //@kodomo

        .kl-curriculum-inner{
            position:relative;
        }
    }
    .kl-curriculum_heading2{
        font-size:rem(23);
        font-weight:$headings-font-weight;
        // width:vw(275,375);
        width:92%;
        max-width:1110px;
        position:relative;
        @include center;
        padding-left:20px;

        @include queries(xs){
            padding-left:25px; 
        }
        @include queries(xl){
            padding-left:25px; 
        }

    }

    .kl-curriculum_heading2::before{
        content:'';
        display:inline-block;
        width:44.8px;
        height:50px;
        background-image:url($dir + 'common-item_03.png');
        @include bg-init;
        position:absolute;
        top:-10px;
        left:-25px;
        z-index:10;
        @include queries(lg){
            left:-34.8px;
        }
        // @include queries(xl){
        //     left:-34.8px;
        // }
    }
    .kl-curriculum-title_border{
        display:block;
        width:100%;
        height:6px;
        background-color:$kodomo-gray;
        // border:6px solid $kodomo-gray;
        border-radius: 4px;
        position:relative;
        margin-top:-5px;

    }
    .kl-curriculum-title_border::before{
        content:'';
        width:inline-block;
        width:95px;
        height: 6px;
        background-color:$kodomo-green;
        position:absolute;
        top:0;
        left:0px;
        border-radius:6px;
    }

    .kl-curriculum_tables{
        margin-top:25px;
        display:flex;
        overflow-x: scroll;

        @include queries(xl){
            overflow-x: visible;
        }
    }
    .kitaweru-kodomo-inner{
        .kl-curriculum_tables{
            margin-top:0;
            padding-top:65px;
           @include queries(xl){
            flex-wrap: wrap;
           }
    
           &.kl-curriculum_tables-hiddend{
            .table{
                &:last-of-type{
                    @include queries(xl){
                        margin-top:55px;
                    }
                }
    
            }
           }
            
            
        }
    }
    .kl-curriculum_tables-hidden{
        margin-top:0;
        display:flex;
        overflow-x: scroll;
        // padding-top:65px !important;

        @include queries(xl){
            overflow-x: visible;
            flex-wrap:wrap;
            padding-top:0 !important;
        }
        .table{
            width:auto;
            min-width:310px;
            margin-top:50px;
        }
        .table:nth-of-type(3){
            @include queries(xl){
                margin-right:0;
            }
        }
    }
    .accordion-item{
        display:none;
    }



    .table{
        min-width:310px;
        margin-right:35px;
        border:1px solid $kodomo-black;
        tr{
            th{
                background-color: $kodomo-black;
                color:$white;
                border:1px solid #6F6F6F;
            }
            td{
                border:1px solid #6F6F6F;
                border-right:1px solid transparent;
                border-left:1px solid transparent;
            }
            td:nth-of-type(1){
                border-right:1px solid #6F6F6F !important;
            }

        }
    }
    .kitaweru-kodomo-inner{ //@kodomo
        .table{
                min-width: 260px;
                margin-right:15px;
                @include queries(xs){
                    min-width: 310px;
                    margin-right:20px;
                }
                @include queries(sm){
                    margin-right:35px;
                }
               
                
            }
    }


    .kitaweru-kaigo-inner{  //@kaigo
        .table-wrapper{
            width:32%;
            margin-left:10px;
            margin-right:10px;
            min-width:310px;
    
        }
    
    }

    .kl-curriculum_btn::before,
    .kl-curriculum_btn::after{
        background-color:$kodomo-pink;

        @include queries(md){
            width:260px;
        }
        @include queries(lg){
            width:350px;
        }
        @include queries(xl){
            width:345px;
        }
    }
    .kl-curriculum_btn::before{
        left:-78px;

        @include queries(md){
            left:-270px;
        }
        @include queries(lg){
            left:-380px;
        }
        @include queries(xl){
            left:-405px;
        }
    }
    .kl-curriculum_btn::after{
        right:-78px;

        @include queries(md){
            right:-270px;
        }
        @include queries(lg){
            right:-380px;
        }
        @include queries(xl){
            right:-405px;
        }
    }

    .kl-curriculum_btn{
        @include center;
        transform: translateX(5px);
        margin-top:20px;
    }

    .kitaweru-kodomo-inner{  //@kodomo
        .kl-curriculum_btn{
            @include queries(md){
                position:absolute;
                bottom:-55px;
                left:0;right:0;margin-left:auto;margin-right:auto;
                margin-top:0;
             
            }
        }
    }

    .kl-calc{
        width:100%;
        padding-bottom:71.5px;

        @include queries(md){
            padding-bottom:81.5px;
        }

    }
    .kl-calc-inner{
        width:vw(355,375);
        @include center;
    }
    .kl-calc_img{
        margin-top:50px;
        display:flex;
        flex-direction:row;
        justify-content: center;
        padding-bottom:81.5px;
        flex-wrap: wrap;
        img{
            width:98px;
        }
        @include queries(xs){
            img{
                width:123px;
            }
        }
        img:nth-of-type(2){
            margin-left:17px;
            margin-right:17px;
            width:27px;
            @include queries(xs){
                width:27px;
            }
            
        }
    }
    .kitaweru-kodomo-inner{
        .kl-calc_img{
            @include queries(md){
                margin-top:70px;
            }
        }
    }
     //-----2024------
     .kitaweru-kaigo-inner{  //@kaigo
        .kl-calc_img{
            padding-bottom:0px;
        }
    }
     //----/2024------
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-calc_img{
            display:block;
            }
        // .kl-schedule-thoughts-box_paragraph:after{
        //     background-image: url($dir + 'yakugyo-item_08.png');
        // }
    }


    .kl-calc_paragraph{
        width:100%;
        font-size:rem(21);
        font-weight:$font-weight-bold;
        line-height: lh-calc(28,21);
        text-align: center;
        margin-top:25px;
    }

    //----- yakugyo-------
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-curriculum{
            @include queries(xl){
                padding-bottom:20px;
            } 
        }
        .kl-curriculum_tables{
            display:flex;
            flex-direction: column;
            overflow-x: visible;
            @include queries(xl){
                flex-direction: row;
                flex-wrap:wrap;
                // overflow-x: scroll;
            }
        }
        .table-wrapper{
            display:flex;
            overflow-x: scroll;
            order:1;

            @include queries(xl){
                overflow-x: visible;
                
            }
        }
        .table{
            min-width:260px;
            margin-right:20px;
            @include queries(xs){
                min-width:310px;
            }
            @include queries(md){
                margin-right:35px;
            }
            @include queries(xl){
                margin-right:20px;
            }
        }
        .kl-calc-inner{
            width:100%;
        }
        .kl-calc{
            width:100%;
            order:3;
            @include queries(xl){
                width:auto;
                @include center;
                order:2;
                
            }
        }
        .kl-text{
            width:100%;
            order:2;
            @include queries(xl){
                order:3;
                
            }
        }
        .kl-calc_img{
            display:flex;
            justify-content: center;
           img{
               max-width:90px;
               @include queries(xs){
                    max-width:129px;
               }
           }
        }

        .kl-calc_paragraph-small{
            font-size:rem(16);
            line-height:lh-calc(24,16);
            text-align: center;
            margin-top:5px;
            span{
                font-size:rem(12);
                line-height:lh-calc(28,12);
            }
        }
        .kl-calc__pwrap{
            border:2px solid $kodomo-green;
            border-radius:10px;
            max-width:331px;
            @include center;
            margin-top:15px;
        }
        .kl-calc_img{
            padding-bottom:0;
        }
        .kl-calc_paragraph{
            padding-top:20px;
            margin-top:0;
    
            &-small{
                padding-bottom:20px;
            }
        }

        

    }
    .kl-interview{
        .movie-index{
            max-width: 678px;
        }
    }

    .kl-links{
        width:100%;
        background: repeating-linear-gradient(-45deg,#fef7fa,#fef7fa 10px,#fff 0,#fff 20px);
        padding-top:60px;
        padding-bottom:100px;
        @include queries(xl){
            padding-top:80px;
        }
    }

    .kitaweru-kaigo-inner{  //@kaigo
        .kl-links{
            background: repeating-linear-gradient(-45deg,$yakugyo-light_green,$yakugyo-light_green 10px,#fff 0,#fff 20px);
        }
    }
    .kitaweru-yakugyo-inner{ //@yakugyo
        .kl-links{
            background: repeating-linear-gradient(-45deg,$yakugyo-light_green,$yakugyo-light_green 10px,#fff 0,#fff 20px);
        }
    }

    .kl-links-inner{
        width:vw(355,375);
        @include center;
        @include queries(xl){
            max-width:1220px;
            @include center;
        }
    }
    .kl-links_ul{
        list-style-type: none;
        padding-left:0;

        @include queries(md){
            display:flex;
            flex-direction: row;
            justify-content:center;
            flex-wrap:wrap;
        }
    }
    .kl-links_li{
        margin-bottom:15px;
        @include queries(md){
            width:48%;
            max-width:481px;
        }
    }
    .kl-links_li:nth-of-type(even){
        @include queries(md){
            margin-left:18px;
        }
        @include queries(xl){
            margin-left:31px;
        }
    }

    .table{
        tr{
            th,td{
                @include queries(xl){
                    width:75%;
                    min-width:auto;

                }
                
            }
            th:nth-of-type(2),
            td:nth-of-type(2){
                @include queries(xl){
                    width:25%;
                    min-width:auto;

                }
                text-align: center;
                
            }
          

        }
    }


    .kitaweru-kaigo-inner{  //@kaigo
        // .kl-schedule-thoughts-box_paragraph::after{
        //     background-image:url($dir + 'kaigo-item_08.svg');
    
        // }
        // .kl-schedule-thoughts_img{
        //     max-width:228px !important;
        // }

    


        .kl-curriculum{
            max-width: 1110px;
            @include center;
            padding-top:0px;
            @include queries(xl){       
                    padding-top:80px !important;
                
            }
        }
 

        .kl-point2 {
            width:100%;
            background-color:#7FADD8;
            padding-top:35px;
            padding-bottom:60px;
            @include queries(md){
                padding-top:75px;
            }
        }
        .kl-point2-second-wrapeer{
            width: 92%;
            margin-inline: auto;
        }
        .kl-point2_img01{
            width:f-min(480px,75%);
            margin-left: 3vw;
            position:relative;
            @include queries(md){
                margin-inline: auto;
                text-align: center;
                margin-bottom: 15px;
                width:f-min(600px,75%);
                img{
                    max-width:603px;
                    width: 100%;

                }
            }
            @include queries(xl){
                max-width:1120px;
                
                img{
                    max-width:603px;

                }
            }
        }
        .kl-point2_img01::before{
            content: '';
            display:inline-block;
            @include bg-init;
            position:absolute;
            width:f-min(100px,18vw);
            aspect-ratio: 71/184;
            // height:153px;
            background-image: url($dir + 'kaigo-item_04_sp.png');
            
            right:-25%;
            bottom: -15%;
            z-index: 1;
            @include queries(md){
            background-image: url($dir + 'kaigo-item_04.png');
                right: auto;
                left:-9vw;
                bottom: -5.8vw;
                aspect-ratio: 145/235;
                width: f-min(145px,11vw);
            }
            @include queries(lg){
                left:-165px;
                bottom: -5.2vw;
            }
            @include queries(xl){
                bottom: -60px;
            }
        }
        .kl-point2__track{
            position: relative;
            z-index: 1;
        }
       


    }

    .d-pc{
        display:none;
        @include queries(md){
            display:block;
        }
    }
    .d-sp-md{
        @include queries(md){
            display:none;
        }
    }
    .d-sp{
        @include queries(lg){
            display:none;
        }
    }


    

//=========================================================================
//                              kodomo
//=========================================================================
// @mixin well-style-change{
    // .kl-study_heading2_img{
    //     width: 100%;
    //     text-align: center;
    //     @include queries(md){
    //         margin-bottom:30px;
    //      }
    //     @include queries(xl){
    //         margin-bottom:50px;
    //      }
    //     img{
    //         @include queries(sm){
    //             width:100%;
    //             max-width:111px
    //         }
    //     }
    // }
    // .kl-study_text_01{
    //     order:3;
    //     margin-top:20px;
    // }
    // .kl-study_img{
    //     order:2;
    // }
    // .kl-study_text_01{
    //     @include queries(md){
    //        margin-top:0;
    //     }
    // }
    // .kl-study_text_02{
    //     @include queries(md){
    //         width: 45%;
    //         right: 20px;
    //         top: 295px;
           
    //     }
    //     @include queries(xl){
    //         width: 50%;
    //         right: 10px;
    //         top: 370px;
    //     }
    // }
    // .kl-studies:after{
    //     @include queries(xl){
    //         bottom: 80px;
    //         top: auto;  
    //     }
    //     @include queries(xxl){
    //         bottom: 140px;
    //     }
    // }
// }

.kitaweru-kodomo-inner{    //@kodomo
    // @include well-style-change;
    
    // .kl-studies02:after{
    //     @include queries(md){
    //         top: auto;
    //         bottom: 100px;    
    //     }
    //     @include queries(xl){
    //         bottom: 80px;
    //         top: auto;  
    //     }
    //     @include queries(xxl){
    //         bottom: 140px;
    //     }
    // }
    
    // .kl-image-inner{
    //     text-align: center;
    // }
   
    // .table{
    //     min-width: 260px;
    //     margin-right:15px;
    //     @include queries(xs){
    //         min-width: 310px;
    //         margin-right:20px;
    //     }
    //     @include queries(sm){
    //         margin-right:35px;
    //     }
       
        
    // }
    .accordion-item{
        display:none;
        .kl-curriculum_tables{
            @include queries(md){
                margin-top:0;
            }
            // .table{
            //     @include queries(lg){
            //         margin-top:15px;
            //     }
            // }
        } 
    }
    // .kl-curriculum{
    //     .table{
    //         @include queries(md){
    //             max-width: 31%;
    //         }
    //         @include queries(lg){
    //             width:100%;
    //             max-width:310px;
    //         }
    //         @include queries(xl){
    //             width:100%;
    //             max-width:335px;
    //         }
    //         &.kl-curriculum-tag {
    //             position: relative;

    //             &.style{
    //                 @include queries(xl){
    //                     margin-top:80px;
    //                 }
    //             }
    
    //             caption{
    //                 position:absolute;
    //                 top:-60px;
    //                 left:0;

    //                 span{
    //                     font-size:18px;
    //                     color:$white;
    //                     font-weight:500;
    //                     background-color:$kodomo-pink;
    //                     padding:4px 16px;
    //                     border-radius:15px;
    //                 }
    //             }
    //         }
    //         &.last-item{
    //             @include queries(md){
    //                 margin-top:85px;
    //                 max-width:310px;
    //             }
    //         }
    //     }
    // }
    // .kl-curriculum_tables{
    //     margin-top:0;
    //     padding-top:65px;
    //    @include queries(xl){
    //     flex-wrap: wrap;
    //    }

    //    &.kl-curriculum_tables-hiddend{
    //     .table{
    //         &:last-of-type{
    //             @include queries(xl){
    //                 margin-top:55px;
    //             }
    //         }

    //     }
    //    }
        
        
    // }
    
    // .kl-calc_img{
    //     @include queries(md){
    //         margin-top:70px;
    //     }
    // }
    // .kl-curriculum_btn{
    //     @include queries(md){
    //         position:absolute;
    //         bottom:-55px;
    //         left:0;right:0;margin-left:auto;margin-right:auto;
    //         margin-top:0;
         
    //     }
    // }
    // .kl-schedule-thoughts{
    //     @include queries(md){
    //         background-color:$kodomo-pink;
    //     }
    // }
    // .kl-schedule-thoughts_heading3{
    //     background-color:$kodomo-pink;
    // }
    

}

//========================================================================
//                              kaigo
//========================================================================
.kitaweru-kaigo-inner{  //@kaigo
    // @include well-style-change;
    
    // .kl-image__text{
    //     width:100%;
    //     max-width:341px;
    //     @include center;
    //     padding-top:20px;
        
    //     @include queries(md){
    //         max-width:871px;
    //         width:65%;
    //     }
    // }
    // .kl-image__persons{
    //     width:100%;
    //     max-width:371px;
    //     @include center;
    //     margin-top:-25px;
    //     @include queries(md){
    //         margin-top:20px; 
    //         max-width:570px;  
    //         width:35%;
    //     }
    // }
    // .kl-100-image-inner{
    //     @include queries(md){
    //         display:flex;
    //         align-items: center;
    //         max-width:1220px;
    //         @include center;
    //     }
       
    // }
    // .kl-qualification-flex{
    //     // @include queries(sm){
    //         justify-content: center;
    //     // }
        
    // }
    // .kl-qualification_heading3{
    //     margin-left:-20px;
    //     @include queries(sm){
    //         margin-left:-50px;
    //     }
    //     @include queries(md){
    //         margin-left:0;
    //     }
    // }
    // .kl-qualification_img {
    //     // @include queries(sm){
    //         text-align: center;
    //         margin-left:auto;
    //     // }
    // }
    // .kl-qualification-list{
    //     margin-top:0;
    //     margin-left:10px;
    //     @include queries(md){
    //         margin-top:10px;
    //         margin-left:20px;
    //     }
    // }

    // .kl-schedule-slide{
    //     max-width:1000px;
    //     @include center;
    //     margin-top: -37px;
    //     padding-top:20px;
    //     @include queries(md){
    //         padding-top:40px;
    //     }
    // }
    // .kl-schedule-thoughts{
    //     @include queries(md){
    //         background-color:$kaigo-srong-blue;
    //     }
    // }
    // .kl-schedule-thoughts_heading3{
    //     background-color:$kaigo-srong-blue;
    // }
    .kl-point2{
        &-second-wrapeer{
            @include queries(md){
                display:flex;
                gap:20px;
                max-width:1000px;
                @include center;
                padding-left:20px;
                padding-right:20px;
            }
            @include queries(xl){
                padding-left:0;
                padding-right:0;
            }
        }

        &__track{
            max-width:355px;
            @include center;
            margin-top:25px;
            position:relative;
            @include queries(sm){
                max-width:455px;
            }
            @include queries(md){
                align-self:flex-end;
                max-width:415px;
                width:42.5%;
               
            }

            img{
                width:100%;
            }
        }
        &__track::after{
            content:'';
            display:block;
            width:60px;
            height:87px;
            background:url($dir + 'kaigo-item_23.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position:absolute;
            bottom:-23px;
            left:0;
            @include queries(xl){
                left:-10px;
                bottom:-5px;
               
            }
        }

        &__point{
            max-width:355px;
            @include center;
            margin-top:20px;
            @include queries(sm){
                max-width:455px;
            }
            @include queries(md){
                max-width:564px;
                width:57.5%;
            }

            img{
                width:100%;
            }
        }
    }

}
//========================================================================
//                              yakugyo
//========================================================================
// .kitaweru-yakugyo-inner{ //@yakugyo
    // @include well-style-change;

    // .kl-image__text{
    //     width:100%;
    //     max-width:349px;
    //     @include center;
    //     padding-top:20px;
        
    //     @include queries(md){
    //         max-width:994px;
    //         width:65%;
    //     }
    // }
    // .kl-image__persons{
    //     width:100%;
    //     max-width:335px;
    //     @include center;
    //     margin-top:-25px;
    //     @include queries(md){
    //         margin-top:20px; 
    //         max-width:412px;  
    //         width:35%;
    //     }
    // }
    // .kl-yakugyo-image-inner{
    //     @include queries(md){
    //         display:flex;
    //         align-items: center;
    //         max-width:1220px;
    //         @include center;
    //     }
    //     @include queries(xxl){
    //         max-width:1390px;
    //     }
    // }
    // .kl-qualification-inner{
    //     padding-bottom:60px;
    //     @include queries(sm){
    //         max-width:540px;
    //         @include center;
    //     }
    //     @include queries(md){
    //         max-width:1000px;
    //         @include center;
    //         padding-bottom:40px;
            
    //     }
    //     @include queries(lg){
    //         padding-top:35px;
            
    //     }
    // }
    // .kl-qualification-flex{
    //     flex-wrap:wrap;
    //     margin-left:0;
    //     justify-content: center;
    //     @include queries(sm){
    //     }
    //     @include queries(md){
    //         // justify-content: flex-start;
    //         align-items:center;
    //     }
    // }
    // .kl-schedule-thoughts{
    //     @include queries(md){
    //         background-color:$yakugyo-green02;
    //     }
    // }
    // .kl-schedule-thoughts_heading3{
    //     background-color:$yakugyo-green02;
    // }
    // .kl-qualification_heading3{
    //     order:1;
    //     margin-top:30px;
    //     @include queries(md){
    //         // order:1;
    //         margin-top:0;
    //     }
    //     @include queries(lg){
    //         width:47px;
    //     }
    // }
    // .kl-qualification-list{
    //     order:2;
    //     margin-left:10px;
    //     @include queries(xs){
    //         margin-left:15px;
    //     }
    //     @include queries(md){
    //         // order:2;
    //         flex-direction: column;
    //         margin-top:0;
    //     }
        
    // }
    // .kl-qualification__late{
    //     order:3;
    //     width:100%;
    //     text-align: center;
    //     margin-top: 30px;
    //     @include queries(sm){
    //     }
    //     @include queries(md){
    //         // order:3;
    //         margin-top: 0;
    //         width:auto;
    //         max-width:288px;
    //     }
    //     @include queries(lg){
    //         max-width:388px;
    //         margin-left:50px;
    //     }
    //     @include queries(xl){
    //         margin-left:100px;
    //     }
    // }
    // .kl-qualification-btn{
    //     top:206px;
    // }
    // .kl-qualification-item_btn{
    //     transform:translateX(0px);
    // }
    // .kl-qualification_ul_01{
    //     margin-bottom:0px;
    // }
    // .kl-qualification_ul02{
    //     margin-top:-30px;
    //     @include queries(md){
    //         margin-top:0;
    //     }
    // }
    // .kl-qualification_ul{
    //     li{
    //         @include queries(lg){
    //             margin-bottom:13px;
    //         } 
    //     }
    // }
    // .kl-qualification_item_img:first-of-type{
    //     @include queries(md){
    //         bottom:-25px;
    //     }
    //     @include queries(lg){
    //         right:25px;
    //     }
    //     @include queries(xl){
    //         right:-60px;
    //     }
    //     @include queries(xxl){
    //         right:-80px;
    //     }
    // }
    // .kl-qualification_item_img:nth-of-type(2){
    //     @include queries(md){
    //         bottom:-15px;
    //     }
    //     @include queries(lg){
    //         right:-15px;
    //     }
    //     @include queries(xl){
    //         right:-120px;
    //         bottom:30px;
    //     }
    //     @include queries(xxl){
    //         right:-140px;
    //         bottom:40px;
    //     }
    // }
    // .kl-yakugyo-license{
    //     @include queries(md){
    //         padding-top:0;
    //     }
        
    // }
    
    
    // .table{
    //     min-width:260px;
    //     margin-right:20px;
    //     @include queries(xs){
    //         min-width:310px;
    //     }
    //     @include queries(md){
    //         margin-right:35px;
    //     }
    //     @include queries(xl){
    //         margin-right:20px;
    //     }
    // }
    // .kl-calc__pwrap{
    //     border:2px solid $kodomo-green;
    //     border-radius:10px;
    //     max-width:331px;
    //     @include center;
    //     margin-top:15px;
    // }
    // .kl-calc_img{
    //     padding-bottom:0;
    // }
    // .kl-calc_paragraph{
    //     padding-top:20px;
    //     margin-top:0;

    //     &-small{
    //         padding-bottom:20px;
    //     }
    // }

// }


//=========================================================================
//                              course1
//=========================================================================
.kita-htc-lower-inner{
    .container{
        @include queries(xl){
            max-width: 1110px;
        }
    }
    .kl{
        &-field{
           .field02{
            column-gap: 20px;
           }
           .field04{
                li{
                    min-height: 72px;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: lh(22,16);
                    padding: 13px 20px 13px 55px;
                    border: 3px dotted $htc-c1-orange ;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    
                    position: relative;

                    @include queries(md){
                        font-size: 25px;
                        min-height: 90px;
                        flex-wrap: wrap;
                    }
                    &::before{
                        content: '';
                        width: 45px;
                        height: 45px;
                        background: url($htc_dir + 'htc-common-cook.svg') center/cover no-repeat;
                        position: absolute;
                        top: -3px;
                        left: -3px;
                    }
                }
           }
        }
        &-levelup{
            h2{
                margin-bottom: 34px;
                @include queries(md){
                    margin-bottom: 54px;
                }
            }
            .levelup-text{
                font-size: 14px;
                padding-bottom: 10px;
                border-bottom: double 4px #333333;
                margin-bottom: 34px;
                @include queries(md){
                    font-size: 16px;
                    margin-bottom: 45px;
                }
            }
            h3.bg-htc-spline{
                font-size: 22px;
                padding: 10px 0;
                background: repeating-linear-gradient(-45deg,#FFF1E6,#FFF1E6 10px,#fff 0,#fff 20px);
                margin-bottom: 24px;
                @include queries(md){
                    font-size: 25px;
                    margin-bottom: 30px;
                }
            }
            .levelup-content{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap:14px;
                @include queries(md){
                    grid-template-columns: repeat(3,1fr);
                    gap: 20px 25px;
                }
                .card{
                    border: none;
                    background-color: #FFF3C9;
                    padding: 10px 0 5px;

                    .title{
                        font-size: 15px;
                        padding: 0 10px;
                        margin-bottom: 12px;
                        font-weight: 700;
                        @include queries(md){
                            font-size: 18px;

                        }
                    }
                    .img{
                        width: 100%;
                        position: relative;
                        margin-bottom: 10px;
                        @include queries(md){
                            padding: 0 10px;
                            margin-bottom: 15px;
                        }
                        .comment{
                            position: absolute;
                            top: -5px;
                            right: 0;
                            text-align: right;
                            @include queries(md){
                                right: 10px;
                            }
                            img{
                                width: 63%;
                                @include queries(lg){
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .text{
                        font-size: 14px;
                        line-height: lh(22,14);
                        margin-bottom: 10px;
                        padding: 0 10px;
                    }
                }
            }
            .y2{
                .y2-img{
                    img{
                        @include media-breakpoint-down(md){
                            width: 100%;
                        }

                    }
                }
                .levelup-content{
                    @include queries(md){
                        grid-template-columns: repeat(2,1fr);
                        gap: 20px 34px;
                    }
                    .card{
                        .title,.img,.text{
                            @include queries(md){
                                padding: 0 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .kl-hero{
        background-image: url($htc_c1_dir + 'htc-c1-bg-item-sp_01.jpg');

        @include queries( md ){
            background-image: url($htc_c1_dir + 'htc-c1-bg-item-pc_01.jpg');  
        }
    }
    .kl-hero-inner {
        @include queries(xxl){
            position: static;
        }
    }
    .kl-hero_heddigng2_img{
        width:100%;
        max-width:255px;
        top:16px;
        @include queries(xs){
            top:36px;
        }
        @include queries(md){
            max-width:350px;
        }

        @include queries(xl){
            max-width:440px;
            top:223px;
        }
        @include queries(xxl){
            max-width:540px;
            left: 100px;
        }
    }
    .kl-hero_text_img{
        width:100%;
        max-width:198px;
        @include queries(md){
            max-width:225px;
            top:34.528vw;
        }
        @include queries(xl){
            max-width:245px;
            top:500px;
        }
     
        @include queries(xxl){
            max-width:295px;
            top:550px;
            left: 100px;
        }
    }

    .kl-point{
        background-color:$htc-c1-orange; 
    } 

    .kl-point_row{
        justify-content: center;

    }
    // .kl-point_col:nth-of-type(1){
    //     @include queries(md){
    //         margin-right:25px;   
    //     }
    // }
    // .kl-point_col:nth-of-type(2){
    //     @include queries(md){
    //         margin-left:25px;   
    //     }
    // }
    .kl-levelup{
        h2{
            max-width: 920px;
        }
    }
    .kl-qualification{
        background-color:$white;
    } 

    .kl-qualification_heading2{
        width:100%;
        background-color:$white;
        padding-top:70px;
        padding-bottom:60px;
        @include queries(md){
            max-width:488px;
            text-align: center;
            padding-top:10px;
            padding-bottom:63px;
            margin-left:auto;
            margin-right:auto;
        }
        @include queries(xl){
            max-width:488px;
            padding-top:54px;
            padding-bottom:43px;
            transform: translateX(0);
        }
        
    }
    .kl-qualification-inner{
        width:100%;
        padding-top:0;
        @include queries(xl){
            width:100%;
            max-width: 100%;
        }
    }
    .kl-qualification-flex{
        flex-direction:column;
        @include queries(md){
            flex-direction:row;
        }
        @include queries(xl){
            width:100%;
            max-width:1200px;
            @include center;
        }
    }

    .kl-qualification_c1_heading3{
        width:93%;
        background-color:$htc-c1-orange;
        font-weight:$font-weight-bold;
        font-size:rem(20);
        letter-spacing:lp-calc(100);
        line-height:lh-calc(30,20);
        padding-top:15px;
        text-align: center;
        @include center;
        color:$white;
        margin-top:100px;
        margin-bottom:0;
        border-radius: 20px 20px 0 0;
        transform: translateY(5px);

        @include queries(md){
            font-size:rem(24); 
            line-height:lh-calc(30,24);
        }
        @include queries(xl){
            max-width:920px;
            @include center;
            padding-top:25px;
            padding-bottom:25px;
            margin-top:100px;
            
        }

    }
    .kl-qualification_heading3 {
        width:100%;
        max-width:350px;
        margin-top:34px;
        // @include center;
        @include queries(md){
            max-width:47px;
        }
        @include queries(xl){
            max-width:60px;
            img{
                width:100%;
                max-width:100%;
            }
        }
    }

    .kl-qualification-list{
        margin-left:0;
        margin-top:0px;
        @include queries(md){
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            margin-top:15px;
           
        }
    }
    .kl-qualification-bg{
        width:100%;
        background-color:$htc-c1-orange;
        padding-bottom:77.9px;
    }

    .kl-qualification_ul{
        @include queries(md){
            width:41%;
            margin-left:35px;
        }
        @include queries(xl){
            width:38%;
            margin-left:50px;
        }
            li{
                @include queries(md){
                    text-indent: -25px;
                    font-size:rem(16);
                    
                }
                @include queries(lg){
                    font-size:rem(18);
                    padding:5px 0px;
                }
            }
       
    }
    .kl-qualification_ul:nth-of-type(1){
        @include queries(xl){
            margin-left:70px;
        }
       
    }
    .kl-qualification_ul:nth-of-type(2){
        @include queries(xl){
            width:44%;
            margin-left:50px;
        }
       
    }
    .kl-qualification_paragraph{
        margin-top:-10px;
        margin-left:15px;
        @include queries(md){
            width:84%;
        }
        @include queries(xl){
            margin-left:50px;
        }
    }

    .md-only{
        display:none;
        @include queries(md){
            display:block;
        }
        @include queries(lg){
            display:none;
        }
    }
    


    // .kl-study_heading3{
    //     span{
    //         background-color:$htc-c1-orange ;
          
    //     }
      
    // }
    // .kl-study_text_01{
    //     @include queries(md){
    //         margin-left:0px; 
    //         width:45%;
    //     }
    //     @include queries(xl){
    //         width:43%;
    //     }
    // }
 
    // .kl-study_heading3{
    //     // height:300px !important;
    //     span{
    //         background-color:$htc-c1-orange ;
    //         color:$white;
    //         font-size:rem(18);
    //         padding:5px;
    //         display:inline-block;
            
    //         @include queries(md){
    //             font-size:rem(22);
    //             // margin-left:-20px; 
    //         }
    //         @include queries(xl){
    //             font-size:rem(28); 
    //         }
    //     }
    //     span:nth-of-type(2){
    //         // transform:translateY(10px);
    //         margin-top:10px;
    //     }
    // }
    
    .kl-study_heading4{
        color:$htc-c1-orange; 
        @include queries(md){
            margin-top:25px;
        }
        @include queries(xl){
            margin-top:0px;
        }
    }
    .kl-study-inner{
        width:100%;
    }
    // .kl-study_heading2_img{
    //     text-align: center;
    // }
 

    // .kl-study_img,
    // .kl-study_text_02{
    //     width:93%;
    //     @include center;
    //     @include queries(md){
    //         max-width:320px;
    //     }
    //     @include queries(xl){
    //         max-width:460px;
    //     }
    // }
 
    .kl-study_paragraph{
        @include queries(xl){
            max-width:445px;
        }
    }



    // .kl-studies.kl-studies02,
    // .kl-studies.kl-studies03{
    //     position:relative;
    //     display:block;

    //     @include queries(md){
    //         display:flex;

    //     }
    //     .kl-study_heading2_img{
    //         position:absolute;
    //         @include queries(md){
    //             position:static;
    //             order:1;
                
                
    //         }
          
    //     }
    //     .kl-studies-right{
    //         order:2;
    //         display:flex;
    //         flex-direction:column;
    //         @include queries(md){
    //             flex-direction:row;
    //             flex-wrap:wrap;
    //             width:85%;
    //             position:relative;
    //             margin-left:30px;
    //         }
            
    //     }
      
    //     .kl-studies_heading2{
    //         order:1;
    //         font-size:rem(20);
    //         letter-spacing: lp-calc(100);
    //         line-height: lh-calc(58,20);
    //         color:$htc-c1-orange;
    //         background-image:url($htc_dir + 'htc-c1-bg-heading.png');
    //         background-repeat: repeat;
    //         background-size: contain;
    //         padding-left:17px;
    //         margin-left:107px;
    //         @include queries(md){
    //             width:100%;
    //             margin-left:0;
    //             margin-bottom:15px;
    //             text-align: center;
    //         }
    //         @include queries(xl){
    //             margin-bottom:30px;
    //             font-size:rem(24);
    //             line-height: lh-calc(48,20);
                
    //         }
    //     }
    //     .kl-study_img{
    //         order:3;
    //         @include queries(md){
    //             order:2;
    //             text-align: left;
    //             margin-left:0;
    //         }
    //     }
        
    //     .kl-study_heading3{
    //         order:2;
    //         margin-left:107px;
    //         @include queries(md){
    //             order:3;
    //             margin-left:0px;
    //             position:absolute;
    //             top:85px;
    //             left:52%;
                
    //         }
    //         @include queries(xl){
    //             top:88px;
    //             left:52.5%;
                
    //         }
    //     }

      
    //     .kl-study_heading4{
    //         order:4;
    //         width:93%;
    //         @include center;
    //         @include queries(md){
    //             width:47%;
    //             margin-left:0px;
    //             position:absolute;
    //             top:175px;
    //             left:52%;
    //             text-align: left;
                
    //         }
    //         @include queries(xl){
    //             font-size:rem(23);
    //             line-height: lh-calc(35,23);
    //             top:230px;
    //             left:52.5%;
    //             max-width:445px;
                
    //         }
    //     }
  
   

    //     .kl-study_paragraph,
    //     .kl-study_paragraph-sole{
    //         order:5;
    //         width:93%;
    //         @include center;
    //         @include queries(md){
        
    //             margin-left:0px;
    //             position:absolute;
    //             top:270px;
    //             left:52%;
    //             text-align: left;
    //             width:47%;
    //         }
    //         @include queries(xl){
    //             font-size:rem(16);
    //             line-height: lh-calc(28,16);
    //             top:310px;
    //             left:52.5%;
    //             max-width:445px;
                
    //         }
    //     }

       
        
    //     .kl-study_paragraph-sole{
       
    //         @include queries(md){
        
    //             width:47%;
    //             top:73px;
    //             left:52%;
               
    //         }
    //         @include queries(xl){
        
        
    //             top:90px !important;
    //             left:52.5%;
               
    //         }
    //     }

       

     
    // }
 
   
    // .kl-studies.kl-studies04{
    //     // margin-bottom:30px;
    // }
    .kl-focus {
        margin-top:60px;
        @include queries(md){
            margin-top: 120px;
        }
    }

    .kl-focus-inner{
        width:94%;
        @include center;
    }
    .kl-focus_img01{
        width:100%;
        max-width:391px;
        @include center;
        text-align: center;
    }
    .kl-focus_img02{
        width:100%;
        max-width:860px;
        @include center;
        margin-top:20px;
        text-align: center;
    }
    .kl-focus_paragraph{
        width:94%;
        @include center;
        text-align: center;
        font-size:rem(14);
        line-height:lh-calc(30,14);
        font-weight:$font-weight-bold;
        margin-top:25px;
        @include queries(md){
            font-size:rem(16);
            line-height:lh-calc(30,16);  
            margin-top:18px;
        }
        @include queries(xl){
            font-size:rem(18);
            line-height:lh-calc(30,18); 
            margin-top:29px;
        }
    }
    .kl-focus-grid{
        width:94%;
        @include center;
        @include queries(md){
            max-width:766px;
        }
    }
    .kl-focus-grid_row{
        .kl-focus-grid_col{
            padding:0;
            @include queries(md){
                padding:15px !important;

            }

        }
    }
    .kl-focus-grid_row{
        max-width:920px;
        @include center;
    }

    // .kl-program{
    //     width:100%;
    //     background: repeating-linear-gradient(-45deg,rgba(255,118,3,.05),rgba(255,118,3,.05) 8px,#fff 0,#fff 15px);
    //     padding-bottom:69px;
    // }
    // .kl-program-inner{
    //     width:94%;
    //     @include center;
    //     @include queries(xl){
    //         max-width:1110px;
    //     }
    // }
    // .kl-program_heading2{
    //     padding-top:70px;
    //     @include queries(md){
    //         max-width:530px;
    //         @include center;
    //     }
    //     @include queries(xl){
    //         max-width:722px;
    //     }
    // }
    // .kl-program_heading3{
    //     margin-top:40px;
    //     text-align: center;
    //     max-width:120px;
    //     @include center;
    //     @include queries(md){
    //         max-width:150px; 
    //     }
    //     @include queries(xl){
    //         max-width:200px; 
    //     }
    // }
    // .kl-program_paragraph{
    //     @include queries(xl){
    //         font-size:rem(16); 
    //         line-height:lh-calc(28,16); 
    //     }
    // }

    // .kl-studies01::after{
    //     @include queries(xl){
    //         background-image:url($htc_c1_dir + 'htc-c1-item_01.svg');
    //         @include bg-init;
    //         width:190px;
    //         height:130px;
    //         top:auto;
    //         bottom:-130px;
    //         right:-30px;
    //     }
    //     @include queries(xxl){
    //         bottom:-60px;
    //         right:30px;
    //     }
    // }
    // .kl-studies02::after{
    //     @include queries(xl){
    //         display:none;
    //     }
    // }
    // .kl-studies03::after{
    //     @include queries(xl){
    //         background-image:url($htc_c1_dir + 'htc-c1-item_02.svg');
    //         width:102px;
    //         height:136px;
    //         right:0px;
    //         top:auto;
    //         bottom:0;
    //     }
    // }
    .p-btn.p-btn--lower.p-btn--school{
        a{
            background-color: $htc-c1-orange;
        }
    }

    // .kl-schedule-thought-flex{
    //     display:flex;
    //     margin-top:37px;
    //     max-width:580px;
    //     @include center;
    
    // }
    // .kl-schedule-thoughts-left{
    //     width:39%;
    //     order:1;
    //     text-align: left;
    //     .kl-schedule-thoughts_img{
    //         max-width:180px;
    //         width: 45.86667vw;
    //         transform:translateY(20px);
            
    //         @include queries(xs){
    //             transform:translateY(0px);
    //             width:69.86667vw;
    //         }
    //         @include queries(xl){
    //             transform:translateY(-15px);
    //         }
    //     }
    // }
    // .kl-schedule-thoughts-right{
    //     width:65%;
    //     order:2;
    //     @include queries(md){
    //         width:70%;
    //         margin-left:-20px;
    //         align-self: center;
    //     }

    // }
    
    // .kl-schedule-thoughts_heading4{
    //     font-size:rem(22);
    //     color:$htc-black;

    //     @include queries(xl){
    //         font-size:rem(22);  
    //         line-height:lh-calc(27,22);
    //     }

    //     span{
    //         font-size:rem(11);
    //         @include queries(xl){
    //             font-size:rem(14);  
    //         }

    //     }
    // }
    // .kl-schedule-thoughts-box_paragraph{
    //     border:1px solid $htc-black;
    //     color:$htc-black;
    //     padding:10px 15px;
    // }
    // .kl-schedule-thoughts-box_paragraph::after{
    //     content:'';
    //     display:inline-block;
    //     width:30px;
    //     height:1px;
    //     background-color: $htc-black;
    //     position:absolute;
    //     top:40px;
    //     left:-30px;
    //     transform:rotate(-30deg);
    // }
    // .kl-curriculum{
    //     padding-top: 0;
    // }
    .kl-curriculum_heading2::before{
       
        width:34px;
        height:51px;
        background-image:url($htc_dir + 'common-item_05.png');
     
       
        
    }
    
    .kl-curriculum-title_border{
        max-width:1100px;
        @include center;
    }
    .kl-curriculum-title_border::before{
        background-color:$htc-c1-orange;
    }
    // .kl-schedule-thoughts{
    //     width:100%;
    //     background-image:url($htc_c1_dir + 'htc-c1-bg-item_01.jpg');
    //     background-repeat: repeat;
    //     background-size:contain;
    //     background-position: top 0 left 0;
    //     padding-bottom:65px;
    //     @include queries(xl){
    //         background-image:url($htc_c1_dir + 'htc-c1-bg-item_02.jpg');
    //         background-repeat: repeat;
    //         background-size:cover;
    //     }
    //     .kl-curriculum_heading2{
    //         padding-left:65px;
    //     }
    //     .kl-curriculum_heading2::before{
    //         @include queries(xl){
    //             // left:15px;
    //             left:15px;
    //         }
           
            
    //     }
    // }

    .table{
        border:0;
    }
    .kl-item-table{
        display:flex;
        width:100%;
        align-items: baseline;
      
        th,td{
            vertical-align: middle;
        }
        td{
            border-right: 1px solid #6f6f6f;
        }
        th{
            border-right:1px solid $htc-c1-orange;
        }
        th:last-of-type{
            border-right:0 !important;
        }
        td:last-of-type{
            border-right: 0 !important  ;
        }
    
       .kl-item_th01,
       .kl-item_th02,
       .kl-item_th03,
       .kl-item_th04{
            // padding:10px;
            min-width:110px;
        }
        .table{
            min-width:100%;
            @include queries(xs){
                min-width:310px;
            }
        }
        .table01{
            td{
                padding:10px !important;
            }
            th{
                padding:12px;
            }
          
        }
        .table02{
            td{
                line-height: 1;
            }
        }

        td:first-of-type{
            border-right: 1px solid #6f6f6f!important;
        }
        th:nth-of-type(1),
        td:nth-of-type(1){
            width:50% !important; 
        }
        th:nth-of-type(2),
        td:nth-of-type(2){
            width:17% !important; 
        }
        th:nth-of-type(3),
        td:nth-of-type(3){
            width:17% !important; 
        }
        th:nth-of-type(4),
        td:nth-of-type(4){
            width:17% !important; 
        }
    }
    .bg-orange{
        background-color: $htc-c1-light-orang;
    }
    .kl-item-table02{
        margin-top:50px;
        margin-left:230px;
        @include queries(xs){
            margin-left:170px;
        }
        @include queries(sm){
            margin-left:20px;
        }
        @include queries(md){
            margin-left:20px;
        }
        caption{
            caption-side: bottom;
        }

        th,td{
            line-height:1 !important;
        }
    }
    .kl-item_text{
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        align-self: center;
        font-size:rem(14);
        width:5%;

    }
    .kl-item__caption{
        font-size:rem(14);
        margin-left:0px;
    }
    .kl-item__caption::before{
        content:'';
        display:inline-block;
        width:26px;
        height:13px;
        background-color:$htc-c1-light-orang;
        margin-right:5px;

    }
    .kl-table-text{
    
    }
    // .kl-schedule-thoughts-box_paragraph{
    //     color:$htc-black !important;
    // }

  
    
    .txt.bg-or{
        background-color:$htc-c1-orange !important;
        border-color:transparent !important;
    }



}
@mixin space-0{
    .kl-curriculum{
        padding-top: 0;
    }
    .kl-calc{
        padding-bottom: 0;
        .kl-calc_img{
            padding-bottom: 0;
        }
    }
}

//----------------------------------------------------------------------
//                            course1a 追加
//-------------------------------------------------------------------------
.kita-htc-lower-inner.kt-course1{  //@course1
    .kl-hero_text_img{
        @include queries(md){
            width:23vw;
        }
        @include queries(xl){
            
            max-width:347px;
        }
    }
    @include space-0;
   
}




//-----------------------------------------------------------------------
//                追加スタイル用 mixin
//-------------------------------------------------------------------------
// @mixin add-style-01{

        //   .kl-studies.kl-studies02{
        //     .kl-studies-right{
        //         @include queries(xl){
        //             margin-left:25px;

        //         }
        //         .kl-study_heading4{
        //             margin-top:20px;
        //             @include queries(md){
        //                 top: 90px;
        //                 margin-top:0;
        //                 left:51%;

        //             }
        //             @include queries(xl){
        //                 top: 130px;

        //             }
        //         }
        //         .kl-study_paragraph{
        //             @include queries(md){
        //                 top: 170px;
        //                 left:51%;

        //             }
        //             @include queries(xl){
        //                 top: 210px;

        //             }
        //         }

        //     }
        // }
        // .kl-studies{
        //     width:93%;
        //     @include center;
        //     @include queries(sm){
        //         width:100%;
        //         max-width:520px;

        //     }
        //     @include queries(md){
        //         width:100%;
        //         max-width:768px;
        //         margin-top:100px;

        //     }
        //     @include queries(xl){
        //         width:100%;
        //         max-width:1100px;

        //     }
        // }
        // .kl-study_heading2_img {
        //     width:100%;
        //     text-align: center;
        //     @include queries(md){
        //         width:10%;

        //     }

        //     img{
               
        //         // @include queries(sm){
        //         //     width:100%;
        //         //     max-width:110px;
    
        //         // }
    

        //     }
        // }
        // .kl-study_img{
        //     order:2;
        //     width:100%;
        //     @include queries(xl){
        //         max-width:500px;

        //     }
        // }
        // .kl-study_text_01{
        //     order:3;
        //     width:73vw;
        
        // }

        // .kl-study_text_02{
        //     width:100%;
         
        // }

        // .kl-study_heading3{
        //     span{
        //         margin-top:20px;
        //     }
        // }
        // .kl-studies.kl-studies02{
            
        //     .kl-studies-right{
        //         @include queries(md){
        //             margin-left:0px;
    
        //         }
        //     }
        // }

        // .kl-studies-btn{
        //     @include queries(md){
        //         width:50%;
        //         margin-left:50%;
        //         transform:translateY(-65px);

        //     }
        //     @include queries(lg){
        //         width:50%;
        //         margin-left:45%;

        //     }
        //     @include queries(xl){
        //         width:20%;
        //         margin-left:50%;
        //         transform:translateY(-105px);

        //     }
        // }
        // .kl-studies-btn-wrapper{
        //     width:100%;
        //     max-width:1110px;
        //     @include center;
        // }
        // .kl-studies01:after{
        //     @include queries(xl){
        //         top: 130px;
        //         right: -50px;

        //     }
        // }
        // .k-s-b-w-01{

        //     .kl-studies-btn{
        //         @include queries(md){
        //             width:50%;
        //             margin-left:50%;
        //             transform:translateY(50px);
    
        //         }
        //         @include queries(lg){
        //             width:50%;
        //             margin-left:45%;
    
        //         }
        //         @include queries(xl){
        //             width:20%;
        //             margin-left:50%;
        //             transform:translateY(-15px);
    
        //         }
        //     }
            
        // }
        // .kl-program-inner{
        //     @include queries(sm){
        //         width:100%;
        //         max-width:520px;

        //     }
        //     @include queries(md){
        //         width:94%;
        //         max-width:1110px;

        //     }
        // }
        // .kl-study-h-wrapper{
        //     order:3;
        // }

        // @media(min-width:1600px){
        //     .kl-studies01:after{
        //         top:-130px;
        //     }
        // }

        
       
// }

// @mixin add-style-02{

   

    // h2.kl-study_heading2_img{
    //     @include queries(md){
    //         width:100%;
    //         text-align: center;
    //         margin-bottom:40px;
    //         img{
    //             max-width:111px;
    //         }
    //     }
    //     @include queries(xl){
    //         margin-bottom:48px;
          
    //     }
    // }
    // .kl-study_text_01{
    //     @include queries(md){
    //         width:49%;
    //     }
    //     @include queries(xl){
    //         width:51%;
    //     }
    //     .kl-study_heading3{
    //         @include queries(xl){
    //             margin-left:15px;
    //         }
    //     }
    // }
    // .kl-study_text_02{
    //     right:25px;
    //     top:260px;
    //     @include queries(md){
    //         top:295px;
    //         right:55px;
    //     }
    //     @include queries(xl){
    //         right:45px;
    //         top:360px;
    //         max-width:500px;
    //     }

    //     h4.kl-study_heading4{
    //         @include queries(xl){
    //             max-width:605px;
    //         }
    //     }
    //     p.kl-study_paragraph{
    //         @include queries(xl){
    //             max-width:605px;
    //         } 
    //     }
    // }

    // .kl-studies_03{
    //     @include queries(md){
    //         margin-top:60px;
    //     }
    // }
    
    
    
// }









//=========================================================================
//                              course2
//=========================================================================
    .kita-htc-course2-inner{ //@course2
        .kl-hero-inner{
            @include queries(xxl){
                position: static;
            }
        }
        .kl-hero {
            background-image: url($htc_c2_dir + 'htc-c2-bg-item-sp_01.jpg');

            @include queries(md){
                background-image: url($htc_c2_dir + 'htc-c2-bg-item-mid_01.jpg');
            }
            @include queries(lg){
                background-image: url($htc_c2_dir + 'htc-c2-bg-item-pc_01.jpg');
            }
        }
        .kl-hero_heddigng2_img{
            @include queries(xxl){
                top:323px;;
                left: 100px;
            }
        }
        .kl-hero_text_img{
            @include queries(xxl){
                top:650px;
                left: 100px;
            }
        }

        .kl-qualification{
            // padding-top: 75px;
        }
        

        .kl-item-table{
            .table01{
                max-width: 420px;
                tr{
                    &:last-of-type{
                        th:nth-of-type(2),
                        td:nth-of-type(2){
                            border-right:none;
    
                        }
                    }
                    th:nth-of-type(2),
                    td:nth-of-type(2){
                        border-right: 1px solid #6f6f6f !important;
                        @include queries(xl){
                            border-right: 1px solid #6f6f6f !important;
                        }

        
                    }
                }
                
              
            }
            
            
           
        }


        // .kl-program_paragraph{
        //     font-size:rem(14);
        //     @include queries(md){
        //         font-size:rem(16);
        //     }
        // }
        .kl-curriculum{
            padding-top: 0;
            // padding-bottom:70px;
        }
        .kl-item_text{
            width:2%;
            @include queries(sm){
                width:5%;
            }
        }
        .kl-curriculum-inner{
            .kl-curriculum_tables{
                overflow: visible;
            }

        }
        .kl-item-table{
            flex-wrap:wrap;
            justify-content:center;
            min-width: 300px;
            @media(max-width: 360px){
                min-width: 0px;
            }
            th:first-of-type,
            td:first-of-type{
                width:20%!important;
            }

            .table01{
                width:58%;
                margin-right:0;
                min-width:auto;
                @include queries(sm){
                    margin-right:35px; 
                }
                .kl-curriculum_td{
                    border-right:0 !important;
                }
            }

            .kl-curriculum-comparison {
                width:100%;
                margin-left: auto;
                margin-right:auto;
                @include queries(xl){
                    width:30%;
                }
            }
        } 

        // .kl-schedule-thoughts-right{
        //     @include queries(xl){
        //         margin-left:10px;
        //     }
        // }

        // .kl-schedule-thoughts-inner{
        //     max-width:1110px;
        // }
        .kl-qualification-flex{
            @include queries(xl){
                max-width:1110px;

            }
        }
        .kl-qualification_ul:nth-of-type(2){
            @include queries(xl){
                width:45%;

            }
        }

        // .kl-schedule-thoughts-left{

        //     .kl-schedule-thoughts_img{
                
        //         @include queries(xs){
        //             width:35.8vw;
        //             transform: translateY(20px);
        //         }
        //         @include queries(sm){
        //             transform: translateY(0px);
        //         }
        //     }
        // }
        // .kl-schedule-thoughts{
        //     .kl-curriculum_heading2{
        //         padding-left:25px;
        //     }
        //     .kl-curriculum_heading2::before{
        //         @include queries(xl){
        //             left:-34.8px;
        //         }    
        //     }
        // }
        // .kl-studies.kl-studies02{
        //     .kl-studies_heading2{
        //         @include queries(md){
        //             margin-bottom:40px;
        //         }  
        //         @include queries(xl){
        //             margin-bottom:48px;
        //         }  
        //     }

        // }
       
       


        
    }

//=========================================================================
//                              course3
//=========================================================================
@mixin color-A84F25{
    .kl-point{
        background-color: #A84F25;
    }

    .kl-qualification-bg{
        background-color: #A84F25;
    }
    .kl-qualification_c1_heading3{
        background-color: #A84F25;
    }
    .kl-qualification_ul{
        li{
            color: #fff;
        }
    }
}
    .kita-htc-course3-inner{ //@course3
        .kl-hero{
            background-image: url($htc_c3_dir + 'htc-c3-bg-item-sp_01.jpg');
            @include queries(md){
                background-image: url($htc_c3_dir + 'htc-c3-bg-item-pc_01.jpg');
            }
        }
        .kl-hero_heddigng2_img{
            width: 91vw;
            @include queries(xs){
                max-width:341px;

            }
            @include queries(sm){
               
                top:100px;

            }
            @include queries(md){
                width: 42vw;
            }
            img{
                width: 100%;
            }
            @media(min-width:1600px){
                max-width: 635px;
            }

        }
        .kl-hero_text_img{
            width:49vw;
            max-width:183px;
            @include queries(sm){
                max-width: 285px;
                top:450px;
            }
            @include queries(md){
                width: 19vw;
                
            }
        }
        
        .kl{
            &-field{
                .field04{
                    li{
                        border-color: #A84F25;
                        &::before{
                            background: url($htc_dir + 'htc-common-cook2.svg') center/cover no-repeat;
                        }
                    }
               }
            }
            &-levelup{
                .levelup-content{
                    .card{
                        .text{
                            font-size: 14px;
                            @include queries(md){
                                font-size: 16px;
                                margin-bottom: 15px;
                            }
                        }
                    }
                    &.last-style{
                        .card{
                           
                            &:last-of-type{
                                grid-column: auto / span 2;
                                .wide{
                                    width: 50%;
                                    max-width: 346px;
                                    padding-right: 0;
    
                                    @include queries(md){
                                        position: relative;
                                        
                                    }
                                    &::after{
                                        @include queries(md){
                                            content: '';
                                            display: inline-block;
                                            width: 100%;
                                            max-width: 325px;
                                            aspect-ratio: 315/230;
                                            background: url($htc_c3_dir + 'htc-c3-levelup-m12-img_pc.png') center/cover no-repeat;
                                            position: absolute;
                                            right: -20px;
                                            bottom: -30px;
                                            
                                        }
                                        @include queries(lg){
                                            
                                            right: -40px;
                                            bottom: 10px;
                                        }
                                    }
                                    img{
                                        width: 100%;
                                    }
                                }
                                .text{
                                    width: calc(50% - 10px);
                                    padding: 0 10px;
                                    @include queries(xl){
                                        width: calc(50% - 20px);
                                        padding: 0 20px;
                                    }
                                    @include queries(xl){
                                        width: calc(50% - 30px);
                                        padding: 0 30px;
                                    }
                                }
                            }
                        }

                    }
                   
                }
            }
        }
        
        
        
        .kl-qualification{
            margin-top:80px;
        }
       .kl-qualification-bg{
            padding-bottom: 40px;

       }
        .kl-qualification-list{
            width: 100%;
            column-gap: 20px;
        }
        .kl-qualification_img{
            width:100%;
            max-width:350px;
            @include queries(xl){
                max-width:445px; 
            }
        }

        .kl-qualification_c1_heading3{
            margin-top: 0 !important;
            @include queries(md){
                font-size:rem(20);
               

            }
            @include queries(xl){
                font-size:rem(24);
                

            }
        }
        .kl-qualification_img{
            margin-left:auto;
            margin-right:auto;
            margin-top:20px;
            @include queries(md){
                margin-top:20px;
                margin-left:10px;

            }
        }
        .kl-qualification_ul{
            margin-left:30px;
            @include queries(xl){
                width:fit-content;
            }
            &:last-of-type{
                width: fit-content;
            }
            li{
            text-indent: -20px;;
                span{
                    font-size:rem(12);
                }
            }
        }
        .kl-item-table{
            .table01{
                th,td{
                    padding:6px !important;
                }
            }
        }
        .kl-qualification-flex{
            align-items: center;
            margin-left:0;
            @include queries(md){
                align-items: flex-start;
            }
            @include queries(lg){
                width:84%;
                @include center;
            }
            @include queries(xl){
                max-width:1110px;
                @include center;
            }
        }

        
        @include color-A84F25;
        @include space-0;
    }

//=========================================================================
//                              course4
//=========================================================================
    .kita-htc-course4-inner{ //@course4
        @include color-A84F25;
        .kl-hero{
            background-image: url($htc_c4_dir + 'htc-c4-bg-item-sp_01.jpg');
            @include queries(md){
                background-image: url($htc_c4_dir + 'htc-c4-bg-item-pc_01.jpg');
            }
        }
        
        .kl-hero_heddigng2_img{
            @include queries(sm){
                top:100px;
            }
            @media(min-width:1600px){
                max-width: 540px;
            }
        }

        .kl-qualification-list{
            @include queries(md){
                width:90%;
            }
        }

        .kl-qualification_img{

            @include queries(md){
                margin-top:-20px;
                width:41%;
            }
            @include queries(xl){
                max-width:310px;
            }
            .kl-qualification_img_01{
                text-align: right;

                img{
                    transform: translateY(30px);
                    max-width:132px;
                    @include queries(md){
                        transform: translate(30px,30px); 
                    }
                
                }
            }
            .kl-qualification_img_02{
                text-align: center;
                img{
                    max-width:229px;
                    
                }
            }
        }

        .kl-qualification-bg{
            padding-bottom:0;
        }
        .kl-qualification_ul{
            @include queries(md){
                width:43%;
            }
            @include queries(xl){
                width:43%;
            }

            li{
                span{
                    font-size:rem(10);
                    @include queries(md){
                        font-size:rem(12);
                    }
                    @include queries(xl){
                        font-size:rem(14);
                    }
                }
            }
        }
        .kl-curriculum_table-wrapper{
            @include queries(xl){
                display:flex;
            }
        }
        .kl-curriculum_tables{
            @include queries(xl){
                width:80%;
            }
        }
        .kl-item-table{
            align-items: flex-start;
            .table{
                th:nth-of-type(2),
                td:nth-of-type(2){
                    width:20% !important;
                }
                th:nth-of-type(1),
                td:nth-of-type(1){
                    width:20% !important;
                }
            }
            .table01{
                th,td{
                    padding:10px !important;
                }
            }
            .table02{
               margin-left:30px;
               @include queries(md){
                   margin-left:0;
               }
            }
        }
        .kl-curriculum-inner{
            @include queries(md){
                max-width:1110px;
            }
        }

        @include color-A84F25;
        @include space-0;

    }

//=========================================================================
//                              course5
//=========================================================================
    .kita-htc-course5-inner{  //@course5
        .kl-hero{
            background-image: url($htc_c5_dir + 'htc-c5-bg-item-sp_01.jpg');
            @include queries(md){
                background-image: url($htc_c5_dir + 'htc-c5-bg-item-pc_01.jpg');
            }
        }
        .kl-hero_heddigng2_img{
            max-width:341px;
            @include queries(sm){
                max-width:450px;
                top:150px;
            }
            @include queries(md){
                max-width:450px;
                top:100px;
            }
            @include queries(xl){
                max-width:650px;
            }
            @include queries(xxl){
                max-width:750px;
            }
        }
        .kl-point{
            background-color: #FA7402;
        }
    
        .kl-qualification-bg{
            background-color: #FA7402;
        }
        .kl-qualification_c1_heading3{
            background-color: #FA7402;
        }
        // .kl-qualification_ul{
        //     li{
        //         color: #fff;
        //     }
        // }
        .kl-hero_text_img{
            max-width:306px;
            @include queries(md){
                max-width:271px;
            }
            @include queries(xl){
                max-width:371px;
            }
            @include queries(xxl){
                max-width:471px;
            }
        }
        .kl-qualification-flex{
            margin-left:0;
        }
        .kl-qualification-flex:last-of-type{
            @include queries(xl){
                margin-top:-20px;
            }
        }
        .kl-qualification_heading3{
            max-width:auto;
            @include queries(md){
                max-width:220px;
            }
            @include queries(xl){
                max-width:220px;
            }
        }
        .kl-qualification-list{
            width:84%;
        }
        .kl-qualification_ul{
            margin-left:10px;
            @include queries(md){
                width:80%
            }
            @include queries(xl){
                width:80%
            }
            @include queries(xxl){
                width:90%
            }
            li{
                text-indent: -30px;

            }
        }
        .kl-q_paragraph{
            font-size:rem(16);
            letter-spacing: lp-calc(100);
            line-height:lh-calc(30,16);
            font-weight:$font-weight-bold;
            color:$white;
            text-decoration: underline;
            margin-top:40px;
            text-align: center;
            @include queries(md){
                margin-top:20px;
            }
            @include queries(xl){
                margin-top:54px;
                font-size:rem(18);
                line-height:lh-calc(40,18);
            }
        }
        .kl-links_text{
            font-size:rem(19);
            font-weight:$font-weight-bold;
            line-height:lh-calc(30,19);
            text-align: center;
            color:$htc-c1-orange;
            position:relative;
            display:inline-block;
        }
        .kl-links_text::before,
        .kl-links_text::after{
            content:'';
            display:inline-block;
            width:70px;
            height:1px;
            background-color:$htc-c1-orange;
            position:absolute;
        }
        .kl-links_text::before{
            top:35px;
            left:-50px;
            transform:rotate(60deg);
        }
        .kl-links_text::after{
            top:35px;
            right:-50px;
            transform:rotate(-60deg);
        }
        .kl-links-inner{
            text-align: center;
            img{
                display:block;
                width:100%;
                text-align: center;
               
            }
            img:nth-of-type(1){
                max-width:350px;
                margin-top:25px;
                @include center;
                @include queries(md){
                    margin-top:40px;
                }
            }
            img:nth-of-type(2){
                max-width:268px;
                margin-top:10px;
                @include center;
            }
        }
        .kl-curriculum_table-wrapper{
            display:flex;
            flex-wrap:wrap;
            justify-content: center;;
        }
        .table{
            max-width:510px;
            th,td{
                width:auto !important;
            }
            th:nth-of-type(1),
            td:nth-of-type(1){
                width:35% !important;

            }
            th:nth-of-type(2),
            td:nth-of-type(2){
                width:15% !important;

            }
            th:nth-of-type(3),
            td:nth-of-type(3){
                width:50% !important;

            }
        }
        
        .kl-calc_img{
            display:block;
            align-self:center;
            margin-left:auto;
            margin-right:auto;

            @include queries(xl){
                margin-left:100px;
                width:100%;
                max-width:175px;
            }
        }
        .kl-item-table{
            td.kl-curriculum_text{
                border-bottom:0 !important;
                border-right:0 !important;
                border-left:0 !important;
                text-align: right;
            }

        }
        .kl-item-table{
            // td:first-of-type{
            //     border-right:0 !important;
            // }
            .table01{
                min-width:550px;
            }
        }
        .kl-curriculum_tables{
            overflow-x: scroll;
            // .kl-item-table{
          
            // }

        }
    }


//=========================================================================
//                              course6
//=========================================================================
//     .kita-htc-course6-inner{   //@course6
//         .kl-hero{
//             background-image: url($htc_c6_dir + 'htc-c6-bg-item-sp_01.jpg');
//             @include queries(md){
//                 background-image: url($htc_c6_dir + 'htc-c6-bg-item-pc_01.jpg');
//             }
//         }

//         .kl-hero_heddigng2_img{
//             max-width:375px;
//             @include queries(sm){
//                 max-width:493px;
//             }
//             @include queries(xl){
//                 max-width:593px;
//             }
//             @include queries(xxl){
//                 max-width:693px;
//                 top:153px;
//             }
//         }
//         .kl-hero_text_img{
//             max-width:306px;
//             @include queries(sm){
//                 max-width:340px;
//             }
//             @include queries(md){
//                 top:350px;
//             }
//             @include queries(lg){
//                 max-width:380px;
//             }
//             @include queries(xl){
//                 max-width:380px;
//                 top:550px;
//             }
//             @include queries(xxl){
//                 max-width:480px;
//                 top:650px;
//             }
//         }
//         .kl-qualification{
//             margin-top:0;
//             padding-top:0;
//         }
//         .kl-qualification-bg{
//             @include queries(md){
//                 padding-bottom:30.9px;

//             }
//         }
//         .kl-study-inner{
//             width:100%;
//             @include center;
//             max-width:1110px;
//         }
//         // .kl-studies.kl-studies{
//         //     @include queries(md){
//         //         width:93%;

//         //     }
//         // }
//         // .kl-studies.kl-studies02,
//         // .kl-studies.kl-studies03{
//         //     .kl-studies_heading2{
//         //         margin-left:0;
//         //         text-align: center;
//         //     } 

//         //     .kl-studies-right{
//         //         @include queries(md){
//         //             margin-left:0;
//         //             width:100%;
//         //         }
//         //         @include queries(xl){
//         //             margin-left:0;
//         //         }
                

//         //        .kl-study_heading4{
//         //             @include queries(md){
//         //                 font-size:rem(18);
//         //                 top:145px;
//         //                 left:47%;
//         //                 width:50%;
//         //             }
//         //             @include queries(xl){
//         //                 font-size:rem(23);
//         //                 top:200px;
//         //                 left: 55%;
//         //                 width:100%;
//         //                 max-width:460px;
//         //            }
//         //         }

//         //         p.kl-study_paragraph.paragraph-add{
//         //             @include queries(md){
//         //                 left:47%;
//         //                 top: 220px;
//         //             }
//         //             @include queries(xl){
//         //                 left:55% !important;
//         //                 top: 280px !important;
//         //                 max-width:460px;
//         //             }
                  
//         //         }
//         //         .kl-study_paragraph-sole{
//         //             @include queries(md){
//         //                 left:48%;
//         //                 top:130px !important;
//         //             }
//         //             @include queries(xl){
//         //                 left:56%;
//         //                 top:165px !important;
//         //             }
//         //         }
//         //     }
            
//         // }
//         // .kl-studies.kl-studies02{
//         //     div.kl-studies-right{
//         //         @include queries(md){
//         //             width:100%;
//         //         }
//         //     }
//         // }
//         // .kl-studies.kl-studies03{
//         //     .kl-study_img{
//         //         @include queries(xl){
//         //             margin-left:25px;
//         //         }
//         //     }
//         // }
//         .kl-study_btn01{
//             @include queries(md){
//                 position:absolute;
//                 top:200px;
//                 right:60px;
//             }
//             @include queries(xl){
//                 position:absolute;
//                 top:350px;
//                 right:190px;
//             }
//         }
//         // .kl-study_img{
//         //     width:100%;
//         //     @include queries(md){
//         //         margin-top:20px;
//         //     }
//         // }
//         .kl-qualification-flex{
//             @include queries(md){
//                 justify-content:center;
//             }
//         }
//         .kl-qualification-list{
//             @include queries(xl){
//                 width:45%;
//             }
//         }
//         ul.kl-qualification_ul{
//             @include queries(md){
//                 width:90%;
//                 margin-left:50px;
//                 li{
//                     padding:11px 0;
//                 }
//         }
//             @include queries(xl){
//                 width:90%;
//                 margin-left:50px;
//                 li{
//                     padding:17px 0;
//                 }
//         }
//     }

//     .kl-curriculum_td{
//         border-bottom:0 !important;
//     }
//     .kl-calc_img img:nth-of-type(2){
//         width:98px;
//         @include queries(xs){
//             width:123px;

//         }
//     }
//     .kl-calc_img {
//         margin-top:0px;
//         align-self:center;
//         transform: translateY(30px);
//     }
//     .kl-item-table{
//         flex-wrap:wrap;
//         justify-content:center;

//         .table01{
//             width:45%;
//         }
//     }
//     .kl-curriculum_heading2{
//         max-width:768px;
//         @include queries(xl){
//             width:100%;
//             max-width:1110px;
           
//         }
//     }
//     .kl-curriculum{
//         padding-bottom:71px;
//     }
//     .kl-curriculum-title_border{
//         max-width:768px;
//         @include queries(md){
//             width:100%;
//             max-width:768px;
           
//         }
//         @include queries(xl){
//             max-width:1110px;
           
//         }
//     }

//     .kl-curriculum_heading2{
//         padding-left:25px;
//         @include queries(md){
//             padding-left:50px;
//         }  
//         @include queries(xl){
//             padding-left:50px;
//         }  
//     }
//     .kl-curriculum_heading2::before{
//         @include queries(md){
//             left:0px;
//         }    
//         @include queries(xl){
//             left:0px;
//         }    
//     }


//     // .kl-studies-btn{
//     //     @include queries(md){

//     //         margin-left: 0;
//     //         margin-right:auto;
//     //     }   
//     //     @include queries(lg){

//     //         margin-left: 0;
//     //         margin-right:auto;
//     //     }   
//     //     @include queries(xl){

//     //         margin-left: 0;
//     //         margin-right:auto;
//     //     }   
//     // }
    

// }

//==============================================================================
//                               hsc course
//==============================================================================     
    .hsc-course-inner{  //@course
     
        .course__slide-item{
            // max-width:450px;
            .course__slide-item--inner{
                width:100%;
                max-width:450px;
                @include center;
            }
            align-self: baseline;
            .course__slide-item-ta{
              font-size:rem(16);
              margin-top:0;
              @include queries(xl){
                font-size:rem(22);
            } 
          }
          .course__slide-item-tb{
              font-size:rem(14);
              color:$black;
              border-bottom:none;
              margin-top:0;margin-bottom:0;
              padding:4px 10px;
              @include queries(xl){
                font-size:rem(16);
            } 
          }
          .course__slide-item_ul{
              list-style-type:none;
              margin-top:10px;margin-bottom:10px;
              
              .course__slide-item_li{
                  position:relative;
                  font-size:rem(12);
                  padding-left:10px;
                  @include queries(xl){
                    font-size:rem(14);
                } 
              }
              .course__slide-item_li:before{
                  content:'';
                  display:inline-block;
                  width:16px;
                  height:16px;
                  
                  position:absolute;
                  top:3px;
                  left:-13px;
                  @include queries(xl){
                    top:4px;
                } 
              }
          }
          .course__slide-item-text{
              font-size:rem(13);
              padding:10px;
              @include queries(xl){
                font-size:rem(14);
            } 
          }
          .wrap-2col{
            // column-gap: 2px;
            .col-wrap{
                width: calc(50% - 1px);
                &:not(:last-of-type){
                    border-right: 2px solid #E4E4E4;
                }
            }
          }
        }


        .course__slide-item--01{
            .course__slide-item--inner{
                background-color:$hsc-9course01-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course01-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course01-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course01-1;
            }
        }
        .course__slide-item--02{
            .course__slide-item--inner{
                background-color:$hsc-9course02-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course02-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course02-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course02-1;
            }
        }
        .course__slide-item--03{
            .course__slide-item--inner{
                background-color:$hsc-9course05-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course05-1 ;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course05-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course05-1;
            }
        }
        .course__slide-item--04{
            .course__slide-item--inner{
                background-color:$hsc-9course06-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course06-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course06-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course06-1;
            }
        }
        .course__slide-item--05{
            .course__slide-item--inner{
                background-color:$hsc-9course04-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course04-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course04-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course04-1;
            }
        }
        .course__slide-item--06{
            .course__slide-item--inner{
                background-color:$hsc-9course03-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course03-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course03-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course03-1;
            }
        }
        .course__slide-item--07{
            .course__slide-item--inner{
                background-color:$hsc-9course07-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course07-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course07-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course07-1;
            }
        }
        .course__slide-item--08{
            .course__slide-item--inner{
                background-color:$hsc-9course08-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course08-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course08-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course08-1;
            }
        }
        .course__slide-item--09{
            .course__slide-item--inner{
                background-color:$hsc-9course09-3;
            }
            .course__slide-item-ta{
                background-color:$hsc-9course09-1;
            }
            .course__slide-item-tb{
                background-color:$hsc-9course09-2;
            }
            .course__slide-item_li:before{
                background-color:$hsc-9course09-1;
            }
        }
        // .kls-oneday-slide-item-cast{
        //     width:100%;
        //     margin-left:auto;
        //     margin-right:auto;
        //     max-width:250px;
        //     @include center;
        //     @include queries(xs){ max-width:274px; }
        //     @include queries(sm){ max-width:340px; }
        //     @include queries(md){ max-width:280px; }
        //     @include queries(lg){ max-width:250px; }
        //     @include queries(xl){ max-width:300px; }
        //     @include queries(xxl){
        //         width:339px !important;
        //         max-width:339px;
        //     }

        //     img{
        //         width:99%;
        //         @include center;
        //     }
        //     .kls-oneday-slide-item_paragraph-desc{
        //         font-size:rem(14);
        //         text-align: left;
        //         @include queries(xl){ 
        //             font-size:rem(16);
        //         }
        //     }
        // }
        // .kls-oneday-slide-item_paragraph{
        //     background-color:$hsc-third;
        //     font-weight:$font-weight-bold;
        //     @include queries(xl){ 
        //         font-size:rem(18);
        //     }
        // }

        .kl-curriculum_tables{
            @include queries(xl){ 
                justify-content: center;
            }
            .table1{
                width:100%;
                max-width:310px;
            }
        }
        .kl-curriculum_table-item--01{
            width:357px;
            min-width:357px;
            p{
                font-size:rem(16);
                font-weight:$font-weight-bold;
            }
            .table1{
                tr{
                    th{
        
                        width:55%;
                        padding:5px;
                           
                   
                    }
                    td{
                        width:55%;
                        padding:5px;
                           
                    }
                }
            }
        }
        .kl-curriculum_table-item--02{
            width:588px;
            min-width:588px;
            margin-left:30px;
            p{
                font-size:rem(16);
                font-weight:$font-weight-bold;
            }

            .table2{
                tr{
                    th:nth-of-type(1){
                  
                        width:30%;
                        padding:5px;
                           
                        
                    }
                    th:nth-of-type(2){
                
                        width:50%;
                        padding:5px;
                           
                     
                    }
                    th:nth-of-type(3){
                   
                        width:20%;
                        padding:5px;
                           
                   
                    }
                    td:nth-of-type(1){
               
                        width:30%;
                        padding:5px;
                           
                     
                    }
                    td.kl-curriculum_table-border{
                        border-right:1px solid #6F6F6F;
                        width:50%;
                        padding:5px;
                        
                           
                     
                    }
                    td:nth-of-type(3){
                        
                        width:20%;
                        padding:5px;
                        
                           
                     
                    }
                }
            }
        }
        // .kl-s2-late__heading{
        //     font-size:rem(23);
        //     font-weight:$font-weight-bold;
        //     text-align: center;
        //     width:100%;
        //     max-width:220px;
        //     background-color:$plane-black;
        //     color:$white;
        //     margin-bottom:30px;
        //     @include queries(md){ 
        //         margin-bottom:15px;
                
        //     }
        //     @include queries(lg){ 
        //         font-size:rem(26);
        //         max-width:268px;
        //         margin-bottom:30px;
                
        //     }
        //     @include queries(xl){ 
        //         font-size:rem(30);
        //         max-width:288px;
        //     }
        // }
        // .kl-s2-late__images{
        //     @include queries(md){ 
        //         display:flex;
        //         flex-direction: row;
        //         justify-content: center;
        //         gap:15px;
                
        //     }
        //     img.p-late__img{
        //         width:100%;
        //         max-width:288px;
        //         @include center;
        //         margin-bottom:25px;
        //         @include queries(md){ 
        //             max-width:188px; 
        //         }
        //         @include queries(lg){ 
        //             max-width:238px; 
        //         }
        //         @include queries(xl){ 
        //             max-width:288px; 
        //         }
        //     }
        //     .p-late__img--plus{
        //         max-width:35px;
        //         @include center;
        //         margin-bottom:25px;
        //         @include queries(md){ 
        //             max-width:25px; 
        //         }
        //         @include queries(lg){ 
        //             max-width:35px; 
        //         }
        //     }
        // }
        // .kl-s2-late-capture{
        //     font-size:rem(10);
        //     // position:relative;
        //     margin-top:100px;
        //     text-align: center;
        //     display:table;
        //     @include center;
        //     @include queries(md){ 
        //         margin-top:10px; 
        //         font-size:rem(12);
        //     }
        // }
        // .kl-s2-late-capture::before{
        //     content:'';
        //     display:block;
        //     width:83px;
        //     height:97px;
        //     background-image:url($hsc_course + 'img_course_item41.svg');
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     background-position:center;
        //     position:absolute;
        //     top:-100px;
        //     left:-45px;
        //     @include queries(md){ 
       
                
        //         left:40px;
        //     }
        //     @include queries(lg){ 
             
        //         top:-115px;
        //         left:0px;
        //     }
        //     @include queries(xl){ 
             
        //         top:-130px;
        //         left:-15px;
        //     }
        // }
        .hsc-course-study_headding{
            @include queries(md){ 
             
                width:100%;
                max-width:89px;
            }
            @include queries(lg){ 
             
                max-width:111px;
            }
        }
        .hsc-course-study_text_02{
            @include queries(md){ 
                width:45%;
                right:auto;
                left:37%;
            }
            @include queries(lg){ 
                width:50%;
                left:35%;
            }
            @include queries(xl){ 
                width:75%;
                left:23%;
            }
            
        }
        .hsc-course-study_text_01{
            @include queries(md){ 
                width:45%;
            }
            @include queries(lg){ 
                margin-left:38px;
                width:50%;
            }
            @include queries(xl){ 
                margin-left:45px;
                width:75%;
            }
        }
        .hsc-course-study{
            @include queries(md){ 
                margin-top:200px;
                max-width:660px;
                @include center;
            }
        }
        .kl-study{
            
            .hsc-course-study:first-of-type{
                margin-top:0;
            }
            .hsc-course-study:last-of-type{
                padding-bottom:60px;
                @include queries(md){ 
                    padding-bottom:100px;
                }
            }
        }

        .kl-curriculum__scroll{
            @include queries(lg){ 
                display:none;
            }
        }
        

     
   
    // .kl-s2-late__heading{
    //     font-size:rem(23);
    //     font-weight:$font-weight-bold;
    //     text-align: center;
    //     width:100%;
    //     max-width:220px;
    //     background-color:$plane-black;
    //     color:$white;
    //     margin-bottom:30px;
    //     @include queries(md){ 
    //         margin-bottom:15px;
            
    //     }
    //     @include queries(lg){ 
    //         font-size:rem(26);
    //         max-width:268px;
            
    //     }
    //     @include queries(xl){ 
    //         font-size:rem(30);
    //         max-width:288px;
    //     }
    // }
    // .kl-s2-late__images{
    //     @include queries(md){ 
    //         display:flex;
    //         flex-direction: row;
    //         justify-content: center;
    //         gap:15px;
            
    //     }
    //    img.p-late__img{
    //         max-width:288px;
    //         @include center;
    //         margin-bottom:25px;
    //         @include queries(md){ 
    //             max-width:188px; 
    //         }
    //         @include queries(lg){ 
    //             max-width:238px; 
    //         }
    //         @include queries(xl){ 
    //             max-width:288px; 
    //         }
    //     }
    //     .p-late__img--plus{
    //         max-width:35px;
    //         @include center;
    //         margin-bottom:25px;
    //         @include queries(md){ 
    //             max-width:25px; 
    //         }
    //         @include queries(lg){ 
    //             max-width:35px; 
    //         }
    //     }
    // }
    // .kl-s2-late-capture{
    //     font-size:rem(10);
    //     position:relative;
    //     margin-top:100px;
    //     text-align: center;
    //     display:table;
    //     @include center;
    //     @include queries(md){ 
    //         margin-top:0px; 
    //     }
    // }
    // .kl-s2-late-capture::before{
    //     content:'';
    //     display:block;
    //     width:83px;
    //     height:97px;
    //     background-image:url($hsc_course + 'img_course_item41.svg');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-position:center;
    //     position:absolute;
    //     top:-100px;
    //     left:-45px;
    //     @include queries(lg){ 
    //         // left:-50px;
    //     }
    // }
}



    // .p-btn.p-btn--lower.p-btn--lg{
    //     a{
    //         .txt{
    //             max-width:230px !important;
    //             min-width:220px;
    //         }
    //     }
    // }

}  //  body.kita-t-b-w-lower -----   end   --------



//==========================================================================
//                              shokujitsu
//==========================================================================
body.kita-lower-shokujitsu{

    $dir2: '../img/pages/iryo/';

    .link-page-btm{
        position:relative;
    }
    .link-page-btm::before{
        
        background-color:$yakugyo-green02;
    }
    .link-page-btm::after{
        content:'';
        display:block;
        background-image: url($dir2 + 'img-shokujitsu07.png');
        position:absolute;
        width:100vw;
        background-repeat: repeat;
        background-size:312px ;
        background-position: top 0 left 0;
        position:absolute;
        top:0;left:50%;bottom:0;
        margin-left:-50vw;
        // z-index:100;
        
        
    }

    div.list-merit--wrap::before{
        background-image:url($dir2 + 'img-shokujitsu-item_01.svg');
    }
    div.list-merit--wrap::after{
        background-image:url($dir2 + 'img-shokujitsu-item_02.svg');
    }
 
}

//==========================================================================
//                              hsc course
//==========================================================================
body.hsc{
    &.course{
        $hsc_course: '../img/lp/hsc/course/';
        .kl__inner{
            max-width:1130px;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }
        .kl-hero-inner__block{
            .kl-hero_text_img{
                @include queries(md){
                    margin-top: 61px;
                    max-width: 348px;
                }
            }
        }
        .kl-point{
            .container{
                @include media-breakpoint-up(lg) {
                    max-width: 1140px;
                }
            }
        }
        .color-505050{
            color: #505050;
        }
        .kl__title{
            width: 100%;
            background-color: $hsc-secondary;
            margin-bottom: 40px;
            @include media-breakpoint-down(sm){
                margin-bottom: 20px;
            }
            .wrap{
                display: flex;
                column-gap: 50px;
                align-items: center;
                padding: 60px 0;
                @include media-breakpoint-down(md){
                    padding: 20px 0 30px;
                    flex-direction: column;
                    row-gap: 15px;
                }
            }
            h2{
                background-color: $hsc-third;
                padding: 6px 50px;
                color: #fff;
                max-width: 310px;
                font-size: 30px;
                width: fit-content;
                @include media-breakpoint-down(sm){
                    font-size: 21px;
                    padding: 6px 30px;
                }
            }
            span.text{
                font-size: 20px;
                @include media-breakpoint-down(sm){
                    font-size: 14px;
                }
            }
            .parts{
                position: absolute;
                right: 5%;
                top: 19px;
                @include media-breakpoint-down(md){
                    display: none;
                }
                &._02{
                    top: 56px;
                   
                }
            }
            
        }
        .kl-parts1{
            background:repeating-linear-gradient(-45deg,#fef7fa,#fef7fa 10px,#fff 0,#fff 20px);
        
            .bg-parts{
            
            }
            .ksp-inner{
                width: 100%;
                max-width: 1220px;
                margin-inline:auto;
                position: relative;
                @include media-breakpoint-down(xl) {
                    width: 92%;
                }
            }
            .ksp-wrap{
                width: 100%;
                margin-left: -80px;
                @include media-breakpoint-down(xxl) {
                    margin-left: 0px;
                }
                .text{
                    width: 76%;
                    max-width: 914px;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
                .img{
                    width: 24%;
                    max-width: 294px;
                    @include media-breakpoint-down(sm) {
                        max-width: 100%;
                        width: 100%;
                        text-align: center;
                    }
                
                }
                img{
                    width: 100%;
                }
            }
            .kl-parts1{
                background:repeating-linear-gradient(-45deg,#fef7fa,#fef7fa 10px,#fff 0,#fff 20px);
            }
            .bg-parts{
               
            }
            .ksp-inner{
                width: 100%;
                max-width: 1220px;
                margin-inline:auto;
                position: relative;
                @include media-breakpoint-down(xl) {
                    width: 92%;
                }
            }
            .ksp-wrap{
                width: 100%;
                margin-left: -80px;
                position: relative;
                @include media-breakpoint-down(xxl) {
                    margin-left: 0px;
                }
                .text{
                    width: 76%;
                    max-width: 914px;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
                .img{
                    width: 24%;
                    max-width: 294px;
                    @include media-breakpoint-down(sm) {
                        max-width: 400px;
                        width: 100%;
                        text-align: center;
                    }
                  
                }
                img{
                    width: 100%;
                }
            }
            .point__item{
                position: absolute;
                width: 100%;
                &--01{
                    max-width: 186px;
                    width: 12vw;
                    top:34px;
                    left: -8vw;
                    @include media-breakpoint-down(sm) {
                        width: 30vw;
                        max-width: 113px;
                        top:40vw;
                        left: 0;
                    }
                }
                &--02{
                    max-width: 214px;
                    width: 14vw;
                    top:54px;
                    right: -2vw;
                    @include media-breakpoint-down(sm) {
                        max-width: 132px;
                        width: 35vw;
                        top:32vw;
                        right: 0;
                    }
                }

            }
        }
        .kl01{
            position: relative;
            width: 100%;
           
            .kl-lower{
                .text{
                    font-size: 20px;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }
            }
            
            
        }
        .kl02{
            &__title{
                margin-bottom: 40px;
                @include media-breakpoint-up(sm) {
                    margin-bottom: 30px;
                }
            }
            &__images{
                max-width: 852px;
                width: 88%;
                margin-inline:auto;
                
            }
            &__list{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
                max-width: 960px;
                margin-inline: auto;
                margin-top: 40px;
                @include media-breakpoint-down(md) {
                    grid-template-columns: repeat(1, 1fr);
                    margin-left: 4vw;
                }
                ul{
                    li{
                        margin-bottom: 10px;
                        font-size: 18px;
                        @include media-breakpoint-down(sm) {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                        span.quali__label{
                            color:white;
                            background-color: black;
                            text-align: center;
                            display: inline-grid;
                            place-items: center;
                            width:83px;
                            font-size: rem(14);
                            font-weight: bold;
                            margin-right: 0.7rem;
                            padding:2px 5px;
                            
                          
                            @include media-breakpoint-down(sm) {
                                font-size: rem(13);
                                width:75px;
                            }
                        } 
                    }
                }
            }
            h4.nk02__title{
                font-size: 30px;
                color:$hsc-third;
                @include media-breakpoint-down(sm) {
                    font-size: 5vw;
                }
                
            }
            &__lower{
                max-width: 960px;
                margin-inline:auto;
                
                img{
                    min-width:382px;
                }             
            }
        }
        .kl03{
            &__inner{
                max-width:1000px;
                margin-inline:auto;
            }
            &__upper{
                width: 100%;

                img{
                    width: 100%;
                }
            }
            &__mit{
                display: grid;
                width: 94%;
                max-width: 860px;
                margin-inline:auto;
                grid-template-columns: repeat(2,1fr);
                gap:30px;
                @include media-breakpoint-down(sm){
                    grid-template-columns: repeat(1,1fr);
                    gap: 15px;
                }
                .item{
                    background-color: #FDEEF4;
                    padding: 20px 25px;
                    color:#333333;
                    .img-wrap{
                        width: 100%;
                        &.check{
                            position: relative;
                            &::after{
                                content: '';
                                display: inline-block;
                                width: 56px;
                                height: 28px;
                                background: url($hsc_course + '/img_course_item19.svg') center/cover no-repeat;
                                position: absolute;
                                top: -4px;
                                right: -15px;
                            }
                        }
                        img{
                            width: 100%;
                        }
                    }
                }
            }
            &__lower{
                width: 100%;
                position: relative;
            }
        }
        .kl04{
            &__top{
                width: 90%;
                margin-inline: auto;
                @include media-breakpoint-down(sm) {
                    width: 75vw;
                    margin-inline: auto;
                }
                img{
                    width: 100%;
                }
            }
            &__text{
                color:#333333;
            }
            &__lower{
                width: 92%;
                max-width: 876px;
                margin-inline: auto;
                @include media-breakpoint-down(sm) {
                    width: 100%
                }
            }
        }
        .kl05{
            &__upper{
                max-width:856px;
                margin-inline:auto;
                .img-wrap{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 36px;
                    
                    @include media-breakpoint-down(sm) {
                        grid-template-columns: repeat(1,1fr);
                        gap: 26px;
                    }
                    .item{
                        position: relative;
                        &::before{
                            content: '';
                            display: inline-block;
                            width:100px;
                            height: 100px;
                            position: absolute;
                            top: -20px;
                            right: -20px;
                            @include media-breakpoint-down(sm) {
                                right: -5px;
                            }
                        }
                        &:nth-of-type(1){
                            &::before{
                                background: url($hsc_course + 'img_course_item25a.svg') center/cover no-repeat;
                            }
                        }
                        &:nth-of-type(2){
                            &::before{
                                background: url($hsc_course + 'img_course_item26a.svg') center/cover no-repeat;
                            }
                        }
                        &:nth-of-type(3){
                            &::before{
                                background: url($hsc_course + 'img_course_item27a.svg') center/cover no-repeat;
                            }
                        }
                        &:nth-of-type(4){
                            &::before{
                                background: url($hsc_course + 'img_course_item28a.svg') center/cover no-repeat;
                            }
                        }
                    }
                }
            }
            &__mit{
                width: 100%;
              
                .img-wrap{
                    width: 100%;
                    margin-inline:auto;
                    img{
                        width: 100%;
                    }
                }
            }
        }

        .kl-movie{
            .video-indexwrap{
                margin-bottom: 100px !important;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 60px !important;
                }
            }
        }
        .section__tech-materials{
            .materials__items{
                max-width:1000px;
                margin: auto;
                background-color: #FDEEF4;
                position: relative;
                padding:1rem 10px;
                margin-bottom: rem(108);
                @include media-breakpoint-up(md) {
                    margin-bottom: rem(86);
                    padding:2rem;
                    display: flex;
                    flex-wrap:wrap;
                }
                .item{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    transform: translate(0,50%);
                    @include media-breakpoint-up(xl) {
                        transform: translate(50%,25%);
                    }
                }
            }
            // .materials__items__img{
            //     margin-bottom:rem(68);
            //     @include media-breakpoint-up(md) {
            //         margin-bottom:rem(104);
            //     }
            // }
            .materials__item{
                padding:20px 0;
                @include media-breakpoint-up(md) {
                    width: calc((100% - 1rem) / 2);
                    padding:0 20px;
                }
                &:first-of-type{
                    // margin-right: 1rem;
                    @include media-breakpoint-up(md) {
                        border-right:3px dashed $hsc_secondary;
                    }
                    @include media-breakpoint-down(sm) {
                        border-bottom:3px dashed $hsc_secondary;
                    }
                }
            }
            .aside__text{
                @include media-breakpoint-up(lg) {
                    margin-top: -3rem;
                }
            }
            .examination__header{
                font-size: rem(23);
                background-color: #000;
                color:white;
                font-weight: bold;
                padding:.1rem 2rem;
                margin-bottom: 5rem;
                @include media-breakpoint-up(lg) {
                    font-size: rem(30);
                }
            }
            .examination__inner{
                @include media-breakpoint-up(lg) {
                    display: flex;
                    justify-content: center;
                    // max-width:830px;
                    margin: auto;
                }
                .examination__item{
                    position: relative;
                    .examination__item__inner{
                        position: relative;
                        &:after{
                            content: '';
                            position: absolute;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                            display: block;
                            margin: auto;
                            width:280px;
                            height:280px;
                            border-radius: 50%;
                            border:5px solid #E85298;
                            z-index: -1;
                            @include media-breakpoint-up(lg) {
                                width:290px;
                                height:290px;
                            }
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        width: calc(100% / 3);
                        .plus{
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform:translate(50%,-50%)
                        }
                        // width: calc(100)%;
                    }
                }
                .examination__head{
                    font-size: rem(30);
                    font-weight: 900;
                    background-color: $hsc-third;
                    padding:.2rem 1rem;
                    color:white;
                    display: inline-block;
                    @include media-breakpoint-up(lg) {
                        font-size: rem(28);
                        min-width:288px;
                    }
                }
                .examination__per{
                    font-size: rem(70);
                    font-weight: 900;
                    color:#DB1966;
                    line-height: 1;
                    position: relative;
                    @include media-breakpoint-up(lg) {
                        font-size: rem(76);
                    }
                    span{
                        font-size: rem(34);
                        @include media-breakpoint-up(lg) {
                            font-size: rem(36);
                        }
                    }
                    .hukidashi{
                        position: absolute;
                        left: 0;
                        top: 100%;
                    }
                }
            }
        }
        // .section__strengths{
        //     @include media-breakpoint-down(sm) {
        //         padding-top: 0;
        //     }
        //     .strengths__head{
        //         display: inline-block;
        //         font-weight: bold;
        //         font-size: rem(18);
        //         background-color: $hsc_third;
        //         color:white;
        //         padding:.2rem 1rem;
        //         @include media-breakpoint-up(md) {
        //             font-size: rem(34);
        //         }
        //     }
        //     .strengths__intro{
        //         font-weight: bold;
        //         @include media-breakpoint-up(md) {
        //             font-size: rem(20);
        //         }
        //     }
        //     .strengths__lists{
        //         font-weight: bold;
        //         font-size: rem(16);
        //         list-style: none;
        //         padding-left: 0;
        //         li{
        //             padding-left: .5rem;
        //             margin-bottom: 0.5rem;
        //             &:before{
        //                 content:"";
        //                 display: inline-block;
        //                 width:1em;
        //                 height:1em;
        //                 vertical-align: middle;
        //                 background: $hsc_third;
        //                 margin-right: .5rem;
        //                 margin-bottom: 2px;
        //             }
        //         }
        //     }
        //     .strengths__point{
        //         display: flex;
        //         border:1px solid $hsc_third;
        //     }
        //     .strengths__point__head{
        //         padding:.5rem;
        //         color:white;
        //         width:30%;
        //         background-color: $hsc_third;
        //         font-size: rem(16);
        //         display: flex;
        //         align-items: center;
        //         @include media-breakpoint-up(md) {
        //             font-size: rem(20);
        //             padding:1rem;
        //         }
        //     }
        //     .strengths__point__body{
        //         width:70%;
        //         font-size: rem(15);
        //         padding:.5rem;
        //         @include media-breakpoint-up(md) {
        //             padding:1rem;
        //         }
        //     }
        // }
        // .kl-qualification{
        //     margin-top: 60px;
        //     @include queries(lg){
        //         margin-top: 105px;
        //     }
        // }
        
        // .kl-qualification-list{
        //     align-items: center;
        // }
        // .kl-qualification-inner{
        //     padding-top: rem(75);
        //     padding-bottom: rem(75);
        //     .kl-qualification-flex{
        //         @include media-breakpoint-down(sm) {
        //             margin-left: 0;
        //         }
        //     }
        //     .kl-qualification_heading3{
        //         @include media-breakpoint-down(sm) {
        //             margin-right: 0.5rem;
        //         }
        //     }
        //     .kl-qualification_ul01{
        //         margin-bottom: 0;
        //     }
        //     .qualification__item{
        //         position: absolute;
        //         right: 0;
        //         bottom: 2rem;
        //         @include media-breakpoint-down(sm) {
        //             display: none;
        //         }
        //     }
        //     .kl-qualification_ul li:before{
        //         content: none;
        //     }
        //     .kl-qualification_ul li{
        //         padding-left: 90px;
        //         position: relative;
        //     }
        //     .quali__label{
        //         color:white;
        //         background-color: black;
        //         text-align: center;
        //         display: inline-block;
        //         min-width:83px;
        //         font-size: rem(14);
        //         font-weight: bold;
        //         margin-right: 0.5rem;
        //         padding:.2rem 0;
        //         font-size: rem(13);
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         @include media-breakpoint-up(md) {
        //             font-size: rem(14);
        //         }
        //     }
        // }
        // .section-sand-text{
        //     margin-top: 30px;

        //     @include queries(lg){
        //         margin-top: 70px;
        //     }

        //     .sand-text01{
        //         font-size: 14px;
        //         @include queries(lg){
        //             font-size:16px;
        //             max-width: 450px;
        //             margin: 0 auto;
        //         }
        //     }
        //     .sand-text02-wrap{
        //         display: flex;
        //         flex-direction: column;
        //         row-gap: 5px;
        //         max-width: 900px;
        //         margin:22px auto 0;
        //         @include queries(md){
        //             flex-direction: row;
        //             column-gap: 0;
        //             align-items: center;
        //             background-color: #E85298;
        //             justify-content: center;
        //             margin:33px auto 0;
        //         }
        //     }
        //     .sand-text02{
        //         font-size: 15px;
        //         color:#fff;
        //         font-weight: 700;
        //         padding: 8px 15px;
        //         background-color:#E85298;
        //         text-align: center;
        //         @include queries(md){
        //             background-color:transparent; 
        //         }
        //         @include queries(lg){
        //             font-size: 21px;
        //         }                
        //         &:first-of-type{
        //             @include queries(md){
        //                 padding: 8px 0 8px 15px;
        //             }
        //         }
        //         &:last-of-type{
        //             @include queries(md){
        //                 padding: 8px 15px 8px 0;
        //                 // align-self: self-end;
        //                 transform: translateY(3px);
        //             }
        //         }
        //         .big{
        //             font-size: 19px;
        //             @include queries(lg){
        //                 font-size: 30px;
        //             }
        //         }
        //     }
        // }

        .course__slide{
            .slick-arrow{
                @include opacity();
                transition:.2s;
                filter: grayscale(0);
                pointer-events: all;
                &.slick-disabled{
                    pointer-events: none;
                    filter: grayscale(1);
                }
            }
            .slick-next{
                background-color: transparent;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 0;
                width: 0;
                border-width:22px 0 22px 22px;
                border-style:solid;
                border-color:transparent transparent transparent #E85298;
                z-index: 10;
                @include media-breakpoint-up(md) {
                    border-width:35px 0 35px 35px;
                }
            }
            .slick-prev{
                background-color: transparent;
                left: 0;
                top: 0;
                right: auto;
                bottom: 0;
                margin: auto;
                height: 0;
                width: 0;
                border-width:22px 22px 22px 0;
                border-style:solid;
                border-color:transparent #E85298 transparent transparent;
                z-index: 10;
                @include media-breakpoint-up(md) {
                    border-width:35px 35px 35px 0;
                }
            }
            .course__slide-item {
                margin-right: 2.5rem;
                @include media-breakpoint-up(md) {
                    margin-right: 2rem;
                }
                @include media-breakpoint-down(sm) {
                    margin-left: 2.5rem;
                }
            }
            .slick-slider{
                @include media-breakpoint-up(md) {
                    padding-right: 8%;
                }
            }
            .slick-list{
                @include media-breakpoint-up(md) {
                    margin-right: 3rem;
                    margin-left:3rem;
                    padding: 0 20% 0 0!important;
                }
            }
            .slick-slide img{
                margin: auto;
            }
        }
        // .kl-schedule{
        //     background-color: transparent;
        // }
        // .kl-schedule-top{
        //     column-gap: 5px;
        //     max-width: 610px;
        //     margin: 0 auto;
        //     .top01{
        //         width: calc(75% - 5px);
        //         @include queries(lg){
        //             font-size: 16px;
        //         }
        //         br{
        //             display: none;
        //             @include queries(lg){
        //                 display: inline;
        //             }
        //         }
        //     }
        //     .top02{
        //         width: 25%;
        //         img{
        //             width: 100%;
        //         }
        //     }
        // }
        // .table__text{
        //     overflow-x: scroll;
        //     img{
        //         min-width:986px;
        //     }
        // }
        // .kl-studies01{
        //     .kl-study_text_02{
        //         @include media-breakpoint-up(lg) {
        //             // top:180px;
        //         }
        //     }
        // }
        // .kl-study_text_01 .kl-study_heading3 span{
        //     background-color: $hsc_primary;
        // }
        // .kl-study_text_02 .kl-study_heading4{
        //     color:$hsc_primary;
        // }
        // .kl-studies{
        //     position: relative;
        // }
        // .kl-studies01:after,
        // .kl-studies02:after,
        // .kl-studies03:after{
        //     content: none;
        // }
        // .studies__item{
        //     display: none;
        //     @include media-breakpoint-up(md) {
        //         display:block;
        //         position: absolute;
        //         left:100%;
        //          bottom: -50px;
        //     }
        //     @include media-breakpoint-up(xl) {
        //         left:120%;
        //     }
          
          
        // }
    }
    // .hsc__study{
    //         // .kl-studies{
    //         //     max-width:700px;
    //         //     @include media-breakpoint-up(md) {
    //         //         margin: auto auto 7rem;
    //         //     }
    //         //     @include media-breakpoint-up(xl) {
    //         //         margin: auto auto 15rem;
    //         //     }

    //         // }
    //         // .kl-study_heading2_img{
    //         //     @include media-breakpoint-up(md) {
    //         //         width:16%;
    //         //     }
    //         // }
    //         // .kl-study_text_01{
    //         //     @include media-breakpoint-up(md) {
    //         //         width:calc(84% - 45px);
    //         //     }
    //         // }
    //         // .kl-study_text_02{
    //         //     right: auto;
    //         //     @include media-breakpoint-up(xl) {
    //         //         left: calc(16% + 45px );
    //         //         top: 130px;
    //         //     }
    //         //     @include media-breakpoint-up(md) {
    //         //         width:calc(84% - 45px);
    //         //     }
    //         //     @include media-breakpoint-down(lg) {
    //         //         left: calc(16% + 30px);
    //         //         top: 90px;
    //         //     }
    //         // }
    // }
    .section__course.section{
    
            padding-top:0;
    
    }
    .kl-s__stregths{
        max-width:620px;
        @include center;
        margin-top:60px;
    }

    .section__corabo__text{
        background-color:$hsc-primary ;
        padding-top:60px;
        padding-bottom:60px;
        @include media-breakpoint-down(xl) {
            padding-top:100px;
            padding-bottom:100px;
        }
    }

    
 
}