//
// Header
//
// Layout
.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 1000;
	.aside__links{
		> ul{
			@include media-breakpoint-down(md) {
				border-top:1px solid white;
			}
		}
	}
	&__inner {
		position: relative;
		background-color: white;
		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: space-between;
		}
		@include media-breakpoint-up(md) {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			nav {
				flex: 1 1 auto;
			}
		}
	}
	.schools{
		img{
			border:1px solid white;
		}
	}
}

// Logo
.header__logo {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 100%;
	display: flex;
	align-items: center;
	margin-left: 0.5rem;
	// width:295px;
	@include media-breakpoint-down(sm) {
		margin-right: 1rem;
	}
}

// Button
.header__button-container {
	// position: absolute;
	// top:0;
	// bottom:0;
	// right:10px;
	z-index: 100;
	display: flex;
	align-items: center;
	@media (min-width: 1200px){
		margin: auto;
	}

	button {
		background: $black;
		width:80px;
		height:80px;
		display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
		cursor: pointer;
		border:none;
		position: relative;
		transition:1s;
		// border-radius: 5px;
		position: relative;
		
		@include media-breakpoint-down(sm) {
			height:70px;
			width: 70px;
		}
		&:focus{
			outline: none;
		}
		&:before{
			content:"";
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin:auto;
			width: 100%;
			height: 100%;
			border:2px dashed $white;
			opacity: 0;
		}
		.menu{
			font-size: rem-calc(13);
		}
		.bars{
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			width: 100%;
			padding: 8px 0;
		}
		span{
			display: block;
			width:100%;
			height:2px;
			background-color: $white;
			&:nth-of-type(1){
				position: absolute;
				left:0;
				right:0;
				top:30%;
				margin:auto;
				width:60%;
				transition:.4s ease .05s;
			}
			&:nth-of-type(2){
				position: absolute;
				left:0;
				right:0;
				top:0;
				bottom:0;
				margin:auto;
				width:60%;
				transition:width .4s,transform .4s ease .2s;
				&:after{
					content:"";
					display: block;
					position: absolute;
					left:0;
					right:0;
					top:0;
					bottom:0;
					margin:auto;
					width:2px;
					height:0;
					transition:.4s;
					background-color: $white;
				}
			}
			&:nth-of-type(3){
				position: absolute;
				left:0;
				right:0;
				bottom:30%;
				margin:auto;
				width:60%;
				transition:.4s ease .05s;
			}
		}
		&.is_active{
			&:before{
			}
			span{
				&:nth-of-type(1){
					top:5px;
					opacity: 0;
				}
				&:nth-of-type(2){
					width:30px;
					transform:rotate(45deg);
					transition:width .4s,transform .6s ease-in-out .2s;
					&:after{
						height:30px;
					}
				}
				&:nth-of-type(3){
					bottom:5px;
					opacity: 0;
				}
			}
		}
	}
}
.visitor{
	position: relative;
	max-width:410px;
	margin:0 auto 1rem;
	margin-bottom: 4rem;
	.visitor-menu{
		background-color: #fff;
		display: flex;
		align-items: center;
		width: 100%;
		font-size: rem-calc(16);
		padding:.5rem 1rem;
		position: relative;
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 15px;
			bottom: 0;
			margin: auto;
			width: 10px;
			height: 10px;
			border-bottom: 3px solid $black;
			border-right: 3px solid $black;
			transform: rotate(45deg);
			transition:.2s;
		}
		&.is_active{
			&:after{
				transform: rotate(225deg);
			}
		}
	}
	.visitor-menu__child{

	}
	.visitor-menu{
		// font-size: rem-calc(12);
		border:none;
		// line-height: 1.3;
		// background-color: transparent;
		// height: 100%;
	}
	.visitor-menu__child{
		background:white;
		z-index: 1000;
		opacity: 0;
		overflow: hidden;
		position: relative;
		&.is-transition{
			transition:height .6s;
		}
		&.is_opa{
			opacity: 1;
		}
		&.close{
			// opacity:0;
			// z-index: -1;
			height: 0!important;
		}
		ul{
			// border: 1px solid white;
			padding:1rem;
			li{
				&:not(:last-of-type){
					margin-bottom: 0.5rem;
					border-bottom: 2px dashed $black;
					// border-bottom: 2px dashed white;
				}
			}
		}
		a{
			color:$black;
			// color:white;
			display: block;
			font-weight: bold;
			@include opacity-href();
			padding:.5rem 0;
		}
	}
	// &.visitor--pc{
	// 	.visitor-menu{
	// 		font-size: rem-calc(12);
	// 		width:80px;
	// 		line-height: 1.3;
	// 		background-color: transparent;
	// 		height: 100%;
	// 	}
	// 	.visitor-menu__child{
	// 		position: absolute;
	// 		right: 0;
	// 		top: 100%;
	// 		width:230px;
	// 	}
	// }
	// &.visitor--sp{
		// margin-bottom: 4rem;
		// .visitor-menu{
		// 	background-color: #fff;
		// 	display: flex;
		// 	align-items: center;
		// 	width: 100%;
		// 	font-size: rem-calc(16);
		// 	padding:.5rem 1rem;
		// 	position: relative;
		// 	&:after{
		// 		content: '';
		// 		display: block;
		// 		position: absolute;
		// 		top: 0;
		// 		right: 15px;
		// 		bottom: 0;
		// 		margin: auto;
		// 		width: 10px;
		// 		height: 10px;
		// 		border-bottom: 3px solid $black;
		// 		border-right: 3px solid $black;
		// 		transform: rotate(45deg);
		// 	}
		// }
		// .visitor-menu__child{
		// 	ul{
		// 		// border: 1px solid white;
		// 		padding:1rem;
		// 		li{
		// 			&:not(:last-of-type){
		// 				margin-bottom: 0.5rem;
		// 				// border-bottom: 2px dashed white;
		// 				border-bottom: 2px dashed $black;
		// 			}
		// 		}
		// 	}
		// 	a{
		// 		color:$black;
		// 		// color:white;
		// 		display: block;
		// 		font-weight: bold;
		// 		@include opacity-href();
		// 		padding:.5rem 0;
		// 	}
		// }
	// }
}
.search--pc{
	position: relative;
	.search-btn{
		font-size: rem-calc(12);
		border:none;
		width:80px;
		line-height: 1.3;
		@include media-breakpoint-only(md) {
			font-size: rem-calc(11);
		}
	}
	.cse-search-box-pc{
		position: absolute;
		top: 100%;
		right: 0;
		display: flex;
		opacity: 0;
		visibility: hidden;
		// z-index: -1;
		z-index: 1000;
		transform: translateX(10%);
		transition:.7s;
		&.is_active{
			transform: translateX(0);
			opacity: 1;
			visibility: visible;
			// z-index: 10;
		}
	}
	.seach_text{
		border-radius: 0;
		border: 2px solid $black;
		padding: 0.5rem;
		min-width: 210px;
	}
	.submit__btn{
		background-color: $black;
		width: 50px;
		border-radius: 0;
		border: 0;
	}
	.search-btn{
		// background-color: #377DD6;
		background-color: #515458;
		color:white;
		height: 100%;
	}
}
.search--sp{
	.cse-search-box-sp{
		display: flex;
		justify-content: center;
		max-width:410px;
		margin:0 auto 1rem;
	}
	.seach_text{
		width:calc(100% - 40px);
		height: 40px;
		border:none;
		padding-left: 0.5rem;
	}
	.submit__btn{
		width: 40px;
		height: 40px;
		border:none;
		background-color: $black;
	}
}

.menu__inner{
	@include media-breakpoint-up(md) {
		display: flex;
		width:calc(100% - 295px);
		justify-content: flex-end;
	}
	.header__nav-item{
		&.tel{
			padding:.5rem;
			@include media-breakpoint-only(md) {
				padding: 0rem 0.5rem;
			}
			img{
				margin-right: 0.3rem;
			}
			a{
				line-height: 1;
				@include opacity();
			}
			.f-century{
				font-weight: bold;
				font-size: rem-calc(27);
				@include media-breakpoint-only(md) {
					font-size: rem-calc(20);
				}
				@media (min-width: 1024.98px) and (max-width: 1200px){
					font-size: rem-calc(20);
				}
			}
			p{
				font-size: rem-calc(13);
				@media (min-width: 1024.98px) and (max-width: 1200px){
					font-size: rem-calc(10);
				}
			}
		}
		&.map{
			padding:.5rem;
			@-moz-document url-prefix() {
				white-space: nowrap;
      }
			@media (min-width: 1024.98px) and (max-width: 1365px){
				display: none;
			}
			// @include media-breakpoint-only(md) {
			// 	display: none;
			// }
			.inner{
				display: inline-block;
				border-radius: 6px;
				// background-color: #DF5656;
				background-color: #F4CB35;
				text-align: center;
				// color:white;
				color:#333333;
				font-weight: bold;
				font-size: rem-calc(15);
				margin-inline: 0.75rem;
				padding: .3rem .2rem .3rem 0.5rem;
				
				@include media-breakpoint-only(md) {
					font-size: rem-calc(14);
				}
			}
		}
	}
}

// Nav
.header__menu__main{
	margin: auto;
	// padding:1rem .5rem;
	// transition: transform .5s,opacity .5s;
	@include media-breakpoint-down(sm) {
		display: none;
	}
	@media (min-width: 1200px){
		display: block;
	}
	&.is-transition{
		transition: .5s;
	}
	// .inner{
	// 	&:after,
	// 	&:before{
	// 		content:"";
	// 		display: block;
	// 		width:10px;
	// 		height:0;
	// 		position: absolute;
	// 		transition: .3s cubic-bezier(0.76, 0.04, 0.51, 0.99);
	// 	}
	// 	&:after{
	// 		bottom:0;
	// 		left:0;
	// 	}
	// 	&:before{
	// 		top:0;
	// 		right:0;
	// 	}
	// }
	a{
		span{
			position: relative;
		}
	}
	.header__nav{
		height: 100%;
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-left: 1rem;
		}
		@include media-breakpoint-only(md) {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.header__nav-item{
			// .en{
			// 	font-size: rem-calc(22);
			// }
			.time{
				@include media-breakpoint-down(md) {
					font-size: rem-calc(10);
				}
			}
			// .jp{
			// 	font-size: rem-calc(14);
			// }
			@include media-breakpoint-only(md) {
				&:not(.tel):not(.map){
					display: none;
				}
			}
			@include media-breakpoint-up(lg) {
				position: relative;
				// margin-right: 1.4vw;
				padding: 0 0.5vw;
				&:not(:last-of-type){
				}
				> a{
					position: relative;
					display: block;
					&:before{
						content:"";
						display: block;
						position: absolute;
						left:0;
						top:0;
						width:0;
						height:2px;
						background: $black;
						transition:.2s;
					}
				}
				&:hover{
					& > a{
						// &:before{
						// 	width:100%;
						// }
					}
				}
			}
		}
		a{
			color:$black;
			text-decoration: none;
			padding:.5rem;
			@include media-breakpoint-down(md) {
				display: block;
				text-align: left;
				font-weight: bold;
				color:$white;
				border-bottom: 1px solid $white;
				padding: 1rem 0.5rem;
			}
		}
	}
}





.header__menu{
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	margin: auto;
	width:100%;
	// padding:2rem 30px;
	transform: scale(.9);
	// background: $primary;
	opacity: 0;
	z-index: -1;
	visibility: hidden;
	height: calc(100vh - 4rem);
	overflow-y: scroll;
	// transition: transform .5s,opacity .5s;
	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: center;
	}
	&.is-transition{
		transition: .5s;
	}
	.inner{
		width: 100%;
		// &:after,
		// &:before{
		// 	content:"";
		// 	display: block;
		// 	width:10px;
		// 	height:0;
		// 	position: absolute;
		// 	transition: .3s cubic-bezier(0.76, 0.04, 0.51, 0.99);
		// }
		// &:after{
		// 	bottom:0;
		// 	left:0;
		// }
		// &:before{
		// 	top:0;
		// 	right:0;
		// }
	}
	a{
		span{
			position: relative;
			&:after{
				content:"";
				display: block;
				position: absolute;
				bottom:-10px;
				left:0;
				width:100%;
				height:5px;
				background-size: 8px 8px;
			}
		}
	}
	&.is_showes{
		.header__nav{
			a{
				span{
					transform: translateX(0);
					opacity: 1;
					transition:.4s ease 1s;
				}
			}
		}
	}
	&.is_open{
		// @include media-breakpoint-down(md) {
			// transform: translateX(0);
			transform: scale(1);
			opacity: 1;
			visibility: visible;
			z-index:100;
			.inner{
				&:after,
				&:before{
					height:100%;
					transition: .4s cubic-bezier(0.76, 0.04, 0.51, 0.99) .7s;
				}
			}
		// }
	}
	.header__nav{
		height: 100%;
		a{
			color:white;
			text-decoration: none;
			padding:.5rem;
			display: block;
			@include media-breakpoint-down(md) {
				display: block;
				// text-align: left;
				font-weight: bold;
				color:$white;
				// border-bottom: 1px solid $white;
				padding: 1rem 0.5rem;
			}
		}
		aside > .sup{
			color: #00778D;
		}
	}
}

body.parent-group{
	.menu__inner{
		.header__nav-item{
			&.map{
				.inner{
					padding: .6rem 1.68rem .6rem 1.25rem !important;
				}
			}
		}
	}
	.header__menu{
		background: $primary;
		padding:2rem 0;
		@include media-breakpoint-up(lg) {
			padding:4rem 30px;
		}
		&.is_open{
			.header__nav{
				padding-top: 1rem;
				border-top: 1px solid $white;
				@include media-breakpoint-up(lg){
					padding-top: 2.6rem;
				}
				
			}
		}
		.header__nav{
			@include media-breakpoint-up(lg) {
				margin-bottom: 7rem;
			}
		}
		.header__nav-item{
			// overflow: hidden;
			margin-bottom: 2rem;
			@include media-breakpoint-up(lg) {
				margin-bottom: 3rem;
				position: relative;
			}
			a{
				position: relative;
				.i-text{
					transform: translateX(20px);
					opacity: 0;
				}
				&:after{
					content: '';
					display: block;
					position: absolute;
					// right: -20%;
					top: 0;
					right: 50%;
					bottom: -40px;
					margin: auto;
					opacity: 0;
					transform: translateX(50%);
				}
			}
			
			// &:nth-of-type(7n+1){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_01_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:70px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+2){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_02_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:67px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+3){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_03_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:67px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+4){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_04_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:68px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+5){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_05_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:69px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+6){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_06_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:70px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+7){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_07_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:69px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			.en{
				font-size: rem-calc(22);
				@include media-breakpoint-up(md) {
					font-size: rem-calc(30);
				}
			}
			.jp{
				font-size: rem-calc(14);
				@include media-breakpoint-up(md) {
					font-size: rem-calc(18);
				}
			}
			&.is_nav_show{
				a{
					position: relative;
					.i-text{
						transform: translateX(0);
						opacity: 1;
						transition:.4s ease .6s;
					}
				}
			}
			// &.is_ch_show{
			// 	a{
			// 		&:after{
			// 			right: calc(120% + 30px);
			// 			@include media-breakpoint-down(sm) {
			// 				right: calc(140% + 30px);
			// 			}
			// 			bottom:0;
			// 			opacity:1;
			// 			transform: rotate(-30deg);
			// 			transition:right 1s ease-in-out .5s,opacity .3s ease .1s,bottom .3s ease .1s,transform .4s ease .4s;
			// 		}
			// 	}
			// }
		}
		// @include media-breakpoint-down(sm) {
		// 	.header__nav-item{
		// 		margin-left: auto;
		// 		margin-right: auto;
		// 		&:nth-of-type(1){
		// 			width: 70%;
		// 		}
		// 		&:nth-of-type(2){
		// 			width: 80%;
		// 		}
		// 		&:nth-of-type(3){
		// 			width: 80%;
		// 		}
		// 	}
		// }
	}
	.header__menu{
		.header__nav-item{
			// overflow: hidden;
			@include media-breakpoint-up(lg) {
				margin-bottom: 3rem;
			}
			@include media-breakpoint-down(md) {
				// margin-bottom: 2rem;
				margin-bottom: 0;
			}
			.child__item{
				margin-bottom: 1rem;
				.child__item__link{
					@include opacity();
				}
			}
			a{
				position: relative;
				color:$black;
				font-weight:bold;
				.i-text{
					transform: translateX(20px);
					opacity: 0;
				}
				// &:after{
				// 	content: '';
				// 	display: block;
				// 	position: absolute;
				// 	// right: -20%;
				// 	top: 0;
				// 	right: 50%;
				// 	bottom: -40px;
				// 	margin: auto;
				// 	opacity: 0;
				// 	transform: translateX(50%);
				// }
			}
			// &:nth-of-type(7n+1){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_01_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:70px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+2){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_02_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:67px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+3){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_03_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:67px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+4){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_04_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:68px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+5){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_05_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:69px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+6){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_06_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:70px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(7n+7){
			// 	a{
			// 		&:after{
			// 			background: url(../img/common/head_ch_07_guruguru_group.png)no-repeat;
			// 			background-position: center center;
			// 			background-size: cover;
			// 			width:69px;
			// 			height: 97px;
			// 		}
			// 	}
			// }
			.en{
				font-size: rem-calc(22);
				@include media-breakpoint-up(md) {
					font-size: rem-calc(30);
				}
			}
			.jp{
				font-size: rem-calc(14);
				@include media-breakpoint-up(md) {
					font-size: rem-calc(18);
				}
			}
			
			&.is_nav_show{
				a{
					position: relative;
					.i-text{
						transform: translateX(0);
						opacity: 1;
						transition:.4s ease .6s;
					}
				}
			}
			&.is_ch_show{
				a{
					&:after{
						right: calc(120% + 30px);
						@include media-breakpoint-down(sm) {
							right: calc(140% + 30px);
						}
						bottom:0;
						opacity:1;
						transform: rotate(-30deg);
						transition:right 1s ease-in-out .5s,opacity .3s ease .1s,bottom .3s ease .1s,transform .4s ease .4s;
						// @include media-breakpoint-up(md) {
						// 	right: 150%;
						// 	left:auto;
						// }
					}
				}
			}
			@include media-breakpoint-up(lg) {
				position: relative;
			}
			&.parent__nav{
				position: relative;
				.sub__menu{
					@include media-breakpoint-up(lg) {
						position: absolute;
						top:100%;
						left: -200%;
						right: -200%;
						margin: auto;
						visibility: hidden;
						z-index:1000;
						opacity: 0;
						transform:translateY(-10px);
						width:350px;
						padding:1rem;
						transition:.5s;
						background: $black;
					}
					@include media-breakpoint-down(md) {
						display: none;
						// height: 0;
						// overflow: hidden;
						// &.is_slide{
						// 	// display: block;
						// 	animation:accordion 1s forwards;
						// 	@keyframes accordion {
						// 		from{
						// 			height: 0;
						// 		}
						// 		to{
						// 			height: 100%;
						// 		}
						// 	}
						// }
					}
					li{
						@include media-breakpoint-up(lg) {
							margin-bottom: 0.5rem;
						}
						@include media-breakpoint-down(md) {
							margin-left: 1.5rem;
							border-left: 1px solid $white;
						}
						a{
							color:$white;
							text-decoration: none;
							padding:.5rem;
							display: block;
							text-align: left;
							font-weight: bold;
							border-bottom: 1px solid $white;
							padding: 1rem 0.5rem;
							@include opacity();
							@include media-breakpoint-down(md) {
								padding: 1rem;
							}
						}
					}
				}
				@include media-breakpoint-up(lg) {
					&:hover{
						.sub__menu{
							transform:translateY(0);
							opacity: 1;
							visibility: visible;
						}
					}
				}
				& > button{
					display: block;
					text-align: left;
					font-weight: bold;
					color:$white;
					border:none;
					border-bottom: 1px solid $white;
					width: 100%;
					background: none;
					padding: 1rem 2rem 1rem .5rem;
					outline: none;
					position: relative;
					&:after{
						content:"";
						display: block;
						position: absolute;
						top: 0;
						right: 10px;
						bottom: 0;
						margin:auto;
						width:17px;
						height:1px;
						background-color: $white;
					}
					&:before{
						content:"";
						display: block;
						position: absolute;
						top: 0;
						right: 18px;
						bottom: 0;
						margin:auto;
						width:1px;
						height:17px;
						background-color: $white;
						transition:.2s;
					}
					&.is_active{
						&:before{
							opacity: 0;
							height:0;
						}
					}
				}
			}
		}
	}
	.header__inner{
		.menu__inner{
			
			.header__nav-item{
				&.tel{
					color:#00A0E8;
					a{
						color:#00A0E8;
					}
				}
			}
		}
	}
}
body.opencampus{
	.cv__btns{
		.entry{
			a{
				color:$black;
				background-color: #FCF25D;
				border:2px solid white;
			}
		}
	}
}

body.hsc{
	.header__menu{
		background: rgba($c-hair,.93);
	}
	.header__inner{
		background-color: $c-hair;
	}
	.header__menu__main{
		.sub__menu{
			border-width:0 2px 2px;
			border-color:transparent $c-hair $c-hair;
			border-style:solid;
			a{
				position: relative;
				color:$c-hair;
				&:after{
					content: '';
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					background-color: $c-hair;
				}
				&:hover{
					&:after{
						width: 100%;
						transition:.3s;
					}
				}
			}
		}
	}
	.divide-color{
		@media(max-width:1025px) {
			background-color: rgba(#DB76A4,0.63);
			width:100vw;
			margin-left:50%;
			transform:translateX(-50%);
			
		}
		@media(max-width:1025px) {
			background-color: rgba(#DB76A4,0.63);
			width:100vw;
			margin-left:50%;
			transform:translateX(-50%);
			
		}
		ul{
			@media(max-width:1025px) {
				padding:0 10px;
				
			}
			
		}
	}
}
body.htc{
	.header__menu{
		background: rgba($c-cook,.93);
	}
	.header__inner{
		background-color: $c-cook;
	}
	.header__menu__main{
		.sub__menu{
			border-width:0 2px 2px;
			border-color:transparent $c-cook $c-cook;
			border-style:solid;
			a{
				position: relative;
				color:$c-cook;
				&:after{
					content: '';
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					background-color: $c-cook;
				}
				&:hover{
					&:after{
						width: 100%;
						transition:.3s;
					}
				}
			}
		}
	}
	.divide-color{
		@media(max-width:1025px) {
			background-color: rgba(#F6B86E,0.63);
			width:100vw;
			margin-left:50%;
			transform:translateX(-50%);
			// padding:0 10px;
		}
		ul{
			@media(max-width:1025px) {
				padding:0 10px;
			}

		}
	}
	
}
body.iryo{
	.header__menu{
		background: rgba($c-hukusi,.93);
	}
	.header__inner{
		background-color: $c-hukusi;
	}
	.header__menu__main{
		.sub__menu{
			border-width:0 2px 2px;
			border-color:transparent $c-hukusi $c-hukusi;
			border-style:solid;
			a{
				position: relative;
				color:$c-hukusi;
				&:after{
					content: '';
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					background-color: $c-hukusi;
				}
				&:hover{
					&:after{
						width: 100%;
						transition:.3s;
					}
				}
			}
		}
	}
	.divide-color{
		@media(max-width:1025px) {
			background-color: rgba(#B3C984,0.63);
			width:100vw;
			margin-left:50%;
			transform:translateX(-50%);
			// padding:0 10px;
		}
		ul{
			@media(max-width:1025px) {
				padding:0 10px;
			}
		}
		
	}
}
body{
	&.hsc,
	&.htc,
	&.iryo{
		.header__menu{
			padding:4rem 0;
			@include media-breakpoint-down(md) {
				padding: 1rem 0;
			}
		}
		.header__menu__main{
			height: 100%;
		}
		.menu__inner{
			.header__nav-item{
				&.tel{
					color:white;
					a{
						color:white;
					}
				}
				&.map{
					.inner{
						background-color: #fff;
						text-align: center;
						color:#DF5656;
						font-size: rem(15);
					}
				}
				.single-link{
					a{
						@include media-breakpoint-up(lg) {
							font-size: rem-calc(16);
						}
					}
				}
			}
		}
	
		.header__nav-ls{
			height: 100%;
			@include media-breakpoint-up(lg) {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
			.header__nav-item{
				&:not(:last-of-type){
					margin-right: 1rem;
					@media (min-width: 1024.98px) and (max-width: 1200px){
						margin-right: 0.5rem;
					}
				}
				@include media-breakpoint-up(lg) {
					position: relative;
					> a{
						display: block;
						position: relative;
						font-weight: bold;
						font-size: rem-calc(16);
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						&:before{
							content:"";
							display: block;
							position: absolute;
							left:0;
							bottom:0;
							right: 0;
							width:0;
							height:0;
							margin: auto;
							// background: $black;
							transition:.2s;
							border-width:0 10px 10px;
							border-style:solid;
							border-color:transparent transparent white;
							transform: translateY(100%);
							opacity: 0;
							visibility: hidden;
						}
						.f-century{
							font-size: rem-calc(14);
						}
					}
					&:hover{
						& > a{
							@include opacity();
					// 		&:before{
					// 			transform: translateY(0);
					// 			opacity: 1;
					// 			visibility: visible;
					// 		}
						}
					}
				}
				@media(min-width:1400px){
					> a{
						font-size: rem-calc(18);

					}
				}
				&.parent__nav{
					position: relative;
					height: 100%;
					display: flex;
					align-items: center;
					.sub__menu{
						@include media-breakpoint-up(lg) {
							position: absolute;
							top:100%;
							// left: -200%;
							// right: -200%;
							left: -50px;
							margin: auto;
							visibility: hidden;
							z-index:1000;
							opacity: 0;
							transform:translateY(-10px);
							width:350px;
							padding:15px 15px 15px 50px;
							transition:.5s;
							background: rgba(white,.93);
						}
						@include media-breakpoint-down(md) {
							display: none;
						}
						li{
							@include media-breakpoint-up(lg) {
								margin-bottom: 0.5rem;
							}
							@include media-breakpoint-down(md) {
								margin-left: 1.5rem;
								border-left: 1px solid $white;
							}
							a{
								text-decoration: none;
								padding:.5rem;
								display: block;
								text-align: left;
								font-weight: bold;
								// border-bottom: 1px solid $c-hair;
								padding: .7rem 0.5rem;
								font-size: rem-calc(16);
								@include opacity();
								position: relative;
								@include media-breakpoint-down(md) {
									padding: 1rem;
								}
							}
						}
					}
					@include media-breakpoint-up(lg) {
						&:hover{
							.sub__menu{
								transform:translateY(0);
								opacity: 1;
								visibility: visible;
							}
						}
					}
					& > button{
						display: block;
						text-align: left;
						font-weight: bold;
						color:$white;
						border:none;
						border-bottom: 1px solid $white;
						width: 100%;
						background: none;
						padding: 1rem 2rem 1rem .5rem;
						outline: none;
						position: relative;
						&:after{
							content:"";
							display: block;
							position: absolute;
							top: 0;
							right: 10px;
							bottom: 0;
							margin:auto;
							width:17px;
							height:1px;
							background-color: $white;
						}
						&:before{
							content:"";
							display: block;
							position: absolute;
							top: 0;
							right: 18px;
							bottom: 0;
							margin:auto;
							width:1px;
							height:17px;
							background-color: $white;
							transition:.2s;
						}
						&.is_active{
							&:before{
								opacity: 0;
								height:0;
							}
						}
					}
				}
			}
			a{
				color:$black;
				text-decoration: none;
				padding:.5rem;
				@media (min-width: 1024.98px) and (max-width: 1200px){
					padding:.5rem .3rem;
				}
				@include media-breakpoint-down(md) {
					display: block;
					text-align: left;
					font-weight: bold;
					color:$white;
					border-bottom: 1px solid $white;
					padding: 1rem 0.5rem;
				}
			}
		}
		.header__nav{
			margin-bottom: 5rem;
			@include media-breakpoint-down(md) {
				margin-bottom: 3rem;
			}
			.sub__menu{
				list-style: unset;
				li{
					a{
						position: relative;
						@include opacity();
						&:before{
							content: '';
							display: block;
							position: absolute;
							left:-0.5rem;
							top: 1.2rem;
							margin: auto;
							width:3px;
							height: 3px;
							border-radius: 50%;
							background-color: #fff;
							@include media-breakpoint-down(md) {
								top: 1.7rem;
							}
						}
					}
				}
			}
			aside > .sup{
				color: #FFEBA8;
			}
			.header__nav-item{
				&.parent__nav{
					.sub__menu{
						@include media-breakpoint-down(md) {
							display: none;
						}
						// @include media-breakpoint-up(lg) {
						// 	position: absolute;
						// 	top:100%;
						// 	// left: -200%;
						// 	// right: -200%;
						// 	left: -50px;
						// 	margin: auto;
						// 	visibility: hidden;
						// 	z-index:1000;
						// 	opacity: 0;
						// 	transform:translateY(-10px);
						// 	width:350px;
						// 	padding:25px 50px;
						// 	transition:.5s;
						// 	background: rgba(white,.93);
						// }
						// @include media-breakpoint-down(md) {
						// 	display: none;
						// }
						// li{
						// 	@include media-breakpoint-up(lg) {
						// 		margin-bottom: 0.5rem;
						// 	}
						// 	@include media-breakpoint-down(md) {
						// 		margin-left: 1.5rem;
						// 		border-left: 1px solid $white;
						// 	}
						// 	a{
						// 		color:$c-hair;
						// 		text-decoration: none;
						// 		padding:.5rem;
						// 		display: block;
						// 		text-align: left;
						// 		font-weight: bold;
						// 		// border-bottom: 1px solid $c-hair;
						// 		padding: .7rem 0.5rem;
						// 		font-size: rem-calc(18);
						// 		@include opacity();
						// 		position: relative;
						// 		@include media-breakpoint-down(md) {
						// 			padding: 1rem;
						// 		}
						// 	}
						// }
					}
					// @include media-breakpoint-up(lg) {
					// 	&:hover{
					// 		.sub__menu{
					// 			transform:translateY(0);
					// 			opacity: 1;
					// 			visibility: visible;
					// 		}
					// 	}
					// }
					& > button{
						display: block;
						text-align: left;
						font-weight: bold;
						color:$white;
						border:none;
						border-bottom: 1px solid $white;
						width: 100%;
						background: none;
						padding: 1rem 2rem 1rem .5rem;
						outline: none;
						position: relative;
						&:before{
							content:"";
							display: block;
							position: absolute;
							top: 0;
							right: 18px;
							bottom: 0;
							margin:auto;
							width:0;
							height:0;
							border-width:10px 10px 0;
							border-color:white transparent transparent;
							border-style:solid;
							transition:.2s;
						}
						&.is_active{
							&:before{
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
		.header__menu{
			.parent__nav{
				& > a{
					font-weight: bold;
					font-size: rem-calc(20);
				}
			}
		}   
	}
}

@include overlay();