﻿/**
 * State
 */

// サイト共通の状態ルール（特定のモジュールやレイアウトに限定されない）

.is-hidden{
	display: none;
}
.is-show{
	display: block;
}
.is-block{
	display: block;
}