﻿// @import "../../../../node_modules/tailwindcss/base";
// @import "../../../../node_modules/tailwindcss/components";
// @import "../../../../node_modules/tailwindcss/utilities";
//
// common
//

// print css
@media print{
  body{
    -webkit-print-color-adjust: exact;
  }
}

// ie10-11 only font-family
// @media all and (-ms-high-contrast:none) {
//   body{
//     font-family:$font-family-ie-gothic;
//   }
// }

main{
  margin-top: 80px;
  @include media-breakpoint-down(sm) {
    margin-top: 70px;
  }
}
.no-event{
  pointer-events: none;
  text-decoration: none !important;
}
.hov{
  &:hover{
    opacity:0.8 !important;
  }
}
.none-deco{
  &:hover{
    text-decoration: none;
  }
}
.d-md{
  @include media-breakpoint-up(sm) {
    display:none;
  }
}
.d-md--none{
  @include media-breakpoint-down(sm) {
    display:none;
  }
}
.d-lg{
  @include media-breakpoint-up(lg) {
    display:none;
  }
}
.d-lg--none{
  @include media-breakpoint-down(lg) {
    display:none;
  }
}
.d-xl{
  @include media-breakpoint-up(xl) {
    display:none;
  }
}
.d-xl--none{
  @include media-breakpoint-down(xl) {
    display:none;
  }
}

.f-century{
  font-family: $font-century;
  font-style: italic;
  font-weight: 700;
}
.f-noto{
  font-family: $font-noto;
}
.f-notojp{
  font-family: 'Noto Sans JP';
}
.fs-normal{
  font-style: normal;
}
.lh-1{
  line-height: 1;
}
.text-teal{
  color:$teal;
}
.yellow-marker{
  background: linear-gradient(transparent 70%, #FFEC00 70%);
}
.w-md-100{
  @include media-breakpoint-up(md){
    width: 100% !important;
  }
}
.w-lg-100{
  @include media-breakpoint-up(lg){
    width: 100% !important;
  }
}

.f-10{
  font-size: rem-calc(10);
}
.f-12{
  font-size: rem-calc(12)!important;
}
.f-13{
  font-size: rem-calc(13)!important;
}
.f-14{
  font-size: rem-calc(14);
}
.f-16{
  font-size: rem-calc(16);
}
.f-18{
  font-size: rem-calc(18);
}
.f-20{
  font-size: rem-calc(20);
}
.f-23{
  font-size: rem-calc(23);
}
.f-24{
  font-size: rem-calc(24);
}
.f-m-16{
  @include media-breakpoint-down(md) {
    font-size: rem-calc(16);
  }
}
.f-s-17{
  @include media-breakpoint-down(sm) {
    font-size: rem-calc(17);
  }
}
.f-xl-16-up{
  @include media-breakpoint-up(xl) {
    font-size: rem-calc(16) !important;
  }
}
.f-s-10{
  @include media-breakpoint-down(sm) {
    font-size: rem-calc(10);
  }
}
.f-s-12{
  @include media-breakpoint-down(sm) {
    font-size: rem-calc(12);
  }
}
.f-s-13{
  @include media-breakpoint-down(sm) {
    font-size: rem-calc(13);
  }
}
.f-s-14{
  @include media-breakpoint-down(sm) {
    font-size: rem-calc(14);
  }
}
.f-s-16{
  @include media-breakpoint-down(sm) {
    font-size: rem-calc(16);
  }
}
.pb-6{
  padding-bottom: 4rem;
}
.pb-7{
  padding-bottom: 4.5rem;
}
.pb-8{
  padding-bottom: 5rem;
}
.mt-4h{
  margin-top:2rem!important;
}
.mt-6{
  margin-top:4rem!important;
}
.mt-7{
  margin-top:4.5rem!important;
}
.mt-8{
  margin-top:5rem!important;
}
.mt-9{
  margin-top:5.5rem!important;
}
.mt-10{
  margin-top:6rem!important;
}
.mb-6{
  margin-bottom:4rem!important;
}
.mb-4h{
  margin-bottom:2rem!important;
}
.mb-7{
  margin-bottom:4.5rem!important;
}
.mb-8{
  margin-bottom:5rem!important;
}
.mb-9{
  margin-bottom:5.5rem!important;
}
.mb-10{
  margin-bottom:6rem!important;
}
.mt-md-4h{
  @include media-breakpoint-up(md) {
    margin-top:2rem!important;
  }
}
.mt-md-6{
  @include media-breakpoint-up(md) {
    margin-top:4rem!important;
  }
}
.mt-md-7{
  @include media-breakpoint-up(md) {
    margin-top:4.5rem!important;
  }
}
.mt-md-8{
  @include media-breakpoint-up(md) {
    margin-top:5rem!important;
  }
}
.mt-md-9{
  @include media-breakpoint-up(md) {
    margin-top:5.5rem!important;
  }
}
.mt-md-10{
  @include media-breakpoint-up(md) {
    margin-top:6rem!important;
  }
}

.mb-md-4h{
  @include media-breakpoint-up(md) {
    margin-bottom:2rem!important;
  }
}
.mb-md-6{
  @include media-breakpoint-up(md) {
    margin-bottom:4rem!important;
  }
}
.mb-md-7{
  @include media-breakpoint-up(md) {
    margin-bottom:4.5rem!important;
  }
}
.mb-md-8{
  @include media-breakpoint-up(md) {
    margin-bottom:5rem!important;
  }
}
.mb-md-9{
  @include media-breakpoint-up(md) {
    margin-bottom:5.5rem!important;
  }
}
.mb-md-10{
  @include media-breakpoint-up(md) {
    margin-bottom:6rem!important;
  }
}
.mb-md-12{
  @include media-breakpoint-up(md) {
    margin-bottom:8rem!important;
  }
}
.mt-lg-4h{
  @include media-breakpoint-up(lg) {
    margin-top:2rem!important;
  }
}
.mt-lg-6{
  @include media-breakpoint-up(lg) {
    margin-top:4rem!important;
  }
}
.mt-lg-7{
  @include media-breakpoint-up(lg) {
    margin-top:4.5rem!important;
  }
}
.mt-lg-8{
  @include media-breakpoint-up(lg) {
    margin-top:5rem!important;
  }
}
.mt-lg-9{
  @include media-breakpoint-up(lg) {
    margin-top:5.5rem!important;
  }
}
.mt-lg-10{
  @include media-breakpoint-up(lg) {
    margin-top:6rem!important;
  }
}
.mt-lg-12{
  @include media-breakpoint-up(lg) {
    margin-top:8rem!important;
  }
}
.mt-lg-14{
  @include media-breakpoint-up(lg) {
    margin-top:10rem!important;
  }
}
.mb-lg-4h{
  @include media-breakpoint-up(lg) {
    margin-bottom:2rem!important;
  }
}
.mb-lg-6{
  @include media-breakpoint-up(lg) {
    margin-bottom:4rem!important;
  }
}
.mb-lg-7{
  @include media-breakpoint-up(lg) {
    margin-bottom:4.5rem!important;
  }
}
.mb-lg-8{
  @include media-breakpoint-up(lg) {
    margin-bottom:5rem!important;
  }
}
.mb-lg-9{
  @include media-breakpoint-up(lg) {
    margin-bottom:5.5rem!important;
  }
}
.mb-lg-10{
  @include media-breakpoint-up(lg) {
    margin-bottom:6rem!important;
  }
}
.mb-lg-12{
  @include media-breakpoint-up(lg) {
    margin-bottom:8rem!important;
  }
}
.mb-lg-14{
  @include media-breakpoint-up(lg) {
    margin-bottom:10rem!important;
  }
}
.mt--25p{
  @include media-breakpoint-up(lg) {
    margin-top: -25px;
  }
}
.text-hsc{
  color:$c-hair!important;
}
.text-htc{
  color:$c-cook!important;
}
.text-iryo{
  color:$c-hukusi!important;
}
.text-teal{
  color:$teal!important;
}
.d-shadow{
  filter: drop-shadow(0px 3px 6px rgba(#000,.15));
}
.font-weibhg-bolder{
  font-weight: 800!important;
}
.fit-content{
  width:fit-content;
}
.wrapper {
  overflow-x: hidden;
}

ul,
ol{
  padding-left: 1.5rem;
}

.container{
  .container{
    padding-right:0;
    padding-left:0;
  }
}
.slick-slider{
  display: none;
  opacity: 0;
  &.slick-initialized{
    display: block;
    opacity: 1;
    transition:.2s;
  }
}
.y-maker{
  background:linear-gradient(transparent 60%, #ff6 60%);
  // padding-bottom: 5px;
}

body{
  .inner__container{
    max-width:1020px;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    @include media-breakpoint-down(sm) {
      max-width:540px;
      margin-left:auto;
      margin-right:auto;
    }
  }
  &.noscroll{
    overflow: hidden;
  }
  // @include p-a__primary();
  // @include p-btn__primary();
  // @include p-btn__white();
  // @include p-a__white();
  // @include p-line-through();
  // @include p-large__fz();
  @include p-breadcrumb();
  @include p-youtube__wrap();
  @include p-btn();
  &.opencampus{
    @include p-form-second();
    @include oc-card();
    @include p-arrow();
  }
  &.page{
    @include p-article();
  }
  &.parent-group{
    @include primary_btn();
    @include p-important__txt();
    @include blockquote();
    @include p-ul-list($black);
    // @include p-custom__list();
    // @include p-custom__list__second();
    @include p-definition__lists__dotted();
    // @include p-definition__lists__dashed();
    @include p-definition__site();
    @include table();
    @include p-table-wrap();
    // @include p-table-wrap__second();
    // @include p-tab();
    // @include p-box__link();
    // @include p-box__link__second();
    @include p-form();
    @include p-form__btn__wrap();
    @include p-accordion($theme_color:$primary,$btn_color:$black);
    @include p-pagination($fontColor:$black,$numberLinkColor:$black);
    @include p-link-block($fontColor:$black);
    @include p-more($fontColor:$black);
    &.page{
      @include head();
      @include p-article();
      @include p-form-second($primary);
      @include p-btn($orange);
      .p-btn{
        &.p-btn--lower{
          &.p-btn--school{
            a{
              .txt{
                color:$black;
              }
              &:after{
                background-color: $black;
              }
              &:after,
              &:before{
                border-color:$black;
              }
            }
          }
        }
      }
      .p-form__btn__wrap .p-next-btn__wrap button{
        &.is_agree{
          color:$black;
        }
      }
      .p-form-second form dl dd.radio label input:checked+span{
        color:$black;
      }
    }
  }
  &.hsc{
    @include primary_btn($c-hair);
    @include p-important__txt($c-hair);
    @include blockquote($c-hair);
    @include p-ul-list($c-hair);
    // @include p-custom__list($c-hair);
    // @include p-custom__list__second($c-hair);
    @include p-definition__lists__dotted($c-hair);
    // @include p-definition__lists__dashed($c-hair);
    @include p-definition__site($c-hair);
    @include table($c-hair);
    @include p-table-wrap($c-hair);
    // @include p-table-wrap__second($c-hair);
    // @include p-tab($c-hair);
    // @include p-box__link($c-hair);
    // @include p-box__link__second($c-hair);
    @include p-form($c-hair);
    @include p-form__btn__wrap($c-hair);
    @include p-pagination($c-hair,$numberLinkColor:$c-hair);
    @include p-accordion($c-hair);
    // @include p-arrow($c-hair);
    @include p-form-second($c-hair);
    &.page{
      @include head($c-hair,$fontColor:$white);
      @include p-link-block($c-hair,$c-hair);
      @include p-more($c-hair,$c-hair);
      @include p-btn($c-hair);
      @include p-form-second($c-hair);
    }
  }
  &.htc{
    @include primary_btn($c-cook);
    @include p-important__txt($c-cook);
    @include blockquote($c-cook);
    @include p-ul-list($c-cook);
    // @include p-custom__list($c-cook);
    // @include p-custom__list__second($c-cook);
    @include p-definition__lists__dotted($c-cook);
    // @include p-definition__lists__dashed($c-cook);
    @include p-definition__site($c-cook);
    @include table($c-cook);
    @include p-table-wrap($c-cook);
    // @include p-table-wrap__second($c-cook);
    // @include p-tab($c-cook);
    // @include p-box__link($c-cook);
    // @include p-box__link__second($c-cook);
    @include p-form($c-cook);
    @include p-form__btn__wrap($c-cook);
    @include p-pagination($c-cook,$numberLinkColor:$c-cook);
    @include p-accordion($c-cook);
    // @include p-arrow($c-cook);
    @include p-form-second($c-cook);
    &.page{
      @include head($c-cook,$fontColor:$white);
      @include p-link-block($c-cook,$c-cook);
      @include p-more($c-cook,$c-cook);
      @include p-btn($c-cook);
      @include p-form-second($c-cook);
    }
  }
  &.iryo{
    @include primary_btn($c-hukusi);
    @include p-important__txt($c-hukusi);
    @include blockquote($c-hukusi);
    @include p-ul-list($c-hukusi);
    // @include p-custom__list($c-hukusi);
    // @include p-custom__list__second($c-hukusi);
    @include p-definition__lists__dotted($c-hukusi);
    // @include p-definition__lists__dashed($c-hukusi);
    @include p-definition__site($c-hukusi);
    @include table($c-hukusi);
    @include p-table-wrap($c-hukusi);
    // @include p-table-wrap__second($c-hukusi);
    // @include p-tab($c-hukusi);
    // @include p-box__link($c-hukusi);
    // @include p-box__link__second($c-hukusi);
    @include p-form($c-hukusi);
    @include p-form__btn__wrap($c-hukusi);
    @include p-pagination($c-hukusi,$numberLinkColor:$c-hukusi);
    @include p-accordion($c-hukusi);
    // @include p-arrow($c-hukusi);
    @include p-form-second($c-hukusi);
    &.page{
      @include head($c-hukusi,$fontColor:$white);
      @include p-link-block($c-hukusi,$c-hukusi);
      @include p-more($c-hukusi,$c-hukusi);
      @include p-btn($c-hukusi);
      @include p-form-second($c-hukusi);
    }
  }
  &.single{
    .news-cat{
      min-width: 115px;
      color: #fff;
      font-weight: bold;
      font-size: .875rem;
      background-color: #333;
      padding: 0.2rem 0.5rem;
      margin-left: 1rem;
    }
  }
}
.p-attention{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: rem-calc(16);
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
    font-size: rem-calc(28);
  }
  .p-attention__inner{
    position: relative;
    &:after,
    &:before{
      content: '';
      display: block;
      width:10px;
      height:1.5em;
      border-width: 0 3px;
      border-style:solid;
      border-color:$black;
      position: absolute;
      bottom: 0;
    }
    &:after{
      right:-1.5em;
      transform: skewX(-35deg);
    }
    &:before{
      left:-1.5em;
      transform: skewX(35deg);
    }
  }
  &.p-attention--01{
    color:#DB1966;
    .p-attention__inner{
      &:after,
      &:before{
        border-color:#DB1966;
      }
    }
  }
  &.p-attention--02{
    color:$c-cook;
    .p-attention__inner{
      &:after,
      &:before{
        border-color:$c-cook;
      }
    }
  }
  &.p-attention--03{
    color:$c-hukusi;
    .p-attention__inner{
      &:after,
      &:before{
        border-color:$c-hukusi;
      }
    }
  }
}
.p-order{
  .order__elm{
    transform: translateY(20%);
    opacity:0;
  }
  &.is_scrolled{
    .order__elm{
      transform: translateY(0);
      opacity:1;
      @for $index from 1 through 10 {
        &:nth-of-type(#{$index}){
          transition: .7s cubic-bezier(0.72,-0.01, 0.02, 1.5) ($index / 10) + s;
        }
      }
    }
  }
}
.p-open-img{
  position: relative;
  overflow: hidden;
  &:after,
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 49%;
    height: 150%;
    // background-color: #eb6ea5;
  }
  &:after{
    left:0;
    transform-origin: right top;
  }
  &:before{
    transform-origin: left top;
    right:0;
  }
  .line{
    position: absolute;
    left:0;
    top: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width:2%;
    // background-color: #eb6ea5;
    transform: scaleY(1);
    transform-origin: bottom center;
  }
  &.is_scrolled{
    &:after,
    &:before{
      transition:transform .4s ease .5s;
    }
    &:after{
      transform:rotate(90deg);
    }
    &:before{
      transform:rotate(-90deg);
    }
    .line{
      transform: scaleY(0);
      transition:transform .4s;
    }
  }
}
.p-show{
  transform:translateY(30px);
  opacity: 0;
  &.is_scrolled{
    transform:translateY(0);
    opacity: 1;
    transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
  }
}
.p-showY{
  transform:translateY(30px);
  opacity: 0;
  &.is_scrolled{
    transform:translateY(0);
    opacity: 1;
    transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
  }
}
.p-showY-reverse{
  transform:translateY(-30px);
  opacity: 0;
  &.is_scrolled{
    transform:translateY(0);
    opacity: 1;
    transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
  }
}
.p-showX{
  transform:translateX(30px);
  opacity: 0;
  &.is_scrolled{
    transform:translateX(0);
    opacity: 1;
    transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
  }
}
.p-showX-reverse{
  transform:translateX(-30px);
  opacity: 0;
  &.is_scrolled{
    transform:translateX(0);
    opacity: 1;
    transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
  }
}



.p-target-menu{
  color:white;
  ul{
    max-width:880px;
    margin: auto;
    display: flex;
    flex-wrap:wrap;
    border:1px solid white;
  }
  h2,
  h3{
    font-size: rem-calc(25);
    font-weight: bold;
    margin-bottom: 2rem;
  }
  li{
    width:calc(100% / 3);
    @include media-breakpoint-down(sm) {
      width:50%;
    }
  }
  a{
    border:1px solid white;
    color:white;
    text-decoration: none;
    font-weight: bold;
    display: block;
    position: relative;
    padding:.5rem;
    transition:.2s;
    @include media-breakpoint-up(lg) {
      padding:.5rem .5rem .5rem 3rem;
      font-size: rem-calc(20);
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      top: -1px;
      bottom: 0;
      margin: auto;
      width:17px;
      height: 1px;
      background-color: #fff;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      right: 11px;
      top: -1px;
      bottom: 0;
      margin: auto;
      width:10px;
      height: 10px;
      transform: rotate(45deg);
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    &:hover{
      background-color: #fff;
      color:$teal;
      &:after{
        background-color: $teal;
      }
      &:before{
        border-color:$teal;
      }
    }
  }
}
.p-now-future{
  background-color: #fff;
  a{
    position: relative;
    &:after,
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      background-color: white;
    }
    &:after{
      left:50%;
      transform-origin: right center;
    }
    &:before{
      right:50%;
      transform-origin: left center;
    }
    &.is_scrolled{
      &:after,
      &:before{
        transform: scaleX(0);
        transition:.4s cubic-bezier(0.72, -0.01, 0.11, 1);
      }
    }
  }
}
.p-instagram{
  .intro{
    font-size: rem-calc(24);
  }
  .head__wrap{
    color:white;
  }
  .insta-list{
    display: flex;
    flex-wrap:wrap;
    max-width: 900px;
    margin: auto auto 5rem;
    li{
      transform: scale(0);
      opacity: 0;
      // width: 50%;
      width: calc(100% / 3);
      // @include media-breakpoint-up(lg) {
      // }
      // @include media-breakpoint-only(md) {
      //   width: 25%;
      // }
      a{
        padding-top: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        img{
          position: absolute;
          left: 0;
          top: 0;
          object-fit:cover;
          width: 100%;
          height: 100%;
          font-family: 'object-fit: cover;';
        }
      }
      &.instagram__album{
        a{
          &:after{
            content: '';
            display: block;
            position: absolute;
            right: 10px;
            top: 10px;
            background: url(#{$dir}/common/icon_insta-album.svg) no-repeat;
            background-size: cover;
            width: 15px;
            height: 15px;
            opacity: .8;
            @include media-breakpoint-up(md) {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
    &.is_scrolled{
      li{
        @for $index from 1 through 10 {
          &:nth-of-type(#{$index}){
            transform: scale(1);
            opacity: 1;
            transition: .7s cubic-bezier(0.72,-0.01, 0.02, 1.41) ($index / 9) + s;
          }
        }
      }
    }
  }
}
.p-access{
  // background: url(#{$dir}/home/bg_circle01.png)no-repeat;
  // background-size: 1105px 1077px;
  // background-position: top center;
  // .head__wrap{
  //   color:#49B7DE;
  // }
  padding:5rem 0 8rem;
  .gmap{
    max-width:900px;
    margin: auto;
  }
}
.p-info{
  background-color: $teal;
  .container{
    .head__wrap{
      color:#FCF25D;
    }
    .head__wrap{
      @include media-breakpoint-down(sm) {
        margin-bottom: 6rem;
      }
    }
  }
  .bg-elm{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: -4rem auto 0;
    @include media-breakpoint-down(sm) {
      width:50%;
      max-width:200px;
    }
  }
  .inner{
    max-width:440px;
    margin: auto;
    color:white;
    text-align: center;
    // display: flex;
  }
  .list-elm{
    position: relative;
  }
  .text-elm{
    font-weight: bold;
    position: relative;
    z-index:5;
    &.text-elm--01{
      font-size: rem-calc(35);
      line-height: 1.4;
      margin-bottom: rem-calc(53);
      @include media-breakpoint-down(sm) {
        font-size: rem-calc(23);
        margin-bottom: rem-calc(20);
      }
    }
    &.text-elm--02{
      font-size: rem-calc(16);
      margin-bottom: rem-calc(70);
      @include media-breakpoint-down(sm) {
        font-size: rem-calc(14);
        margin-bottom: rem-calc(40);
      }
    }
    &.text-elm--03{
      font-size: rem-calc(35);
      line-height: 1.4;
      margin-bottom: rem-calc(53);
      @include media-breakpoint-down(sm) {
        font-size: rem-calc(23);
        margin-bottom: rem-calc(20);
      }
    }
  }
  .img-elm{
    &.img-elm--02{
      margin-bottom: rem-calc(60);
    }
  }
}
.p-news__lower{
  .head{
    font-size: rem-calc(20);
    font-weight: bold;
    text-align: center;
    background-color: #FCF25D;
    padding:.7rem;
    margin-bottom: 3rem;
    @include media-breakpoint-down(sm) {
      font-size: rem-calc(18);
    }
    &.icon-news,
    &.icon-entrance{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      &::before{
        content: '';
        display: inline-block;
      }
    }
    &.icon-news{
      &::before{
        width: 22px;
        height: 20px;
        background: url(#{$dir}/common/icon-news.svg) center/cover no-repeat;
      }
    }
    &.icon-entrance{
      &::before{
        width: 37px;
        height: 22px;
        background: url(#{$dir}/common/icon-entrance.svg) center/cover no-repeat;
      }
    }
  }
  .new{
    position: absolute;
    left:0;
    top: 0;
    transform: translate(-20%,-50%);
    color:white;
    background:linear-gradient(to bottom ,#5E7FDC 0%,#ED87DE 100%);
    border-radius: 50%;
    display: inline-block;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    font-size: rem-calc(16);
    @include media-breakpoint-down(md) {
      width: 30px;
      height: 30px;
      font-size: rem-calc(10);
    }
  }
  .news__wrap{
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
    }
    .news__block{
      background-color: #fff;
      box-shadow: 0px 4px 4px 0px rgba($black,.15);
      @include media-breakpoint-up(md) {
        width:calc((100% - 2.5rem) / 2);
        &:first-of-type{
          margin-right: 2.5rem;
        }
      }
    }
  }
  .news__block--entrance{
    .head{
      background-color: $teal;
      color:white;
    }
  }
  .news__block--gray{
    .head{
      background-color: #5A5A5A;
      color:white;
    }
  }
  .news__lists{
    a{
      display: flex;
      align-items: flex-start;
      color:$black;
      @include opacity-href();
    }
    li{
      padding:0 2.5rem 2rem;
      margin: 0 2rem 2rem;
      &:not(:last-of-type){
        border-bottom: 1px dashed #333;
      }
      @include media-breakpoint-down(md) {
        padding:0 10px 1rem;
        margin:0 0 1rem;
      }
    }
  }
  .thumb{
    // margin-right: 2rem;
    position: relative;
    padding-top: 21%;
    width: 35%;
    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      position: absolute;
      left: 0;
      top: 0;
      font-family: 'object-fit: cover;';
    }
  }
  time{
    margin-bottom: 1rem;
  }
  .title{
    margin-bottom: 0;
    font-size: rem-calc(16);
    @include media-breakpoint-down(md) {
      font-size: rem-calc(14);
    }
  }
  .content{
    width: 65%;
    padding-left: 2rem;
    @include media-breakpoint-down(md) {
      padding-left: 10px;
    }
  }
}
.p-news{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  .new{
    position: absolute;
    left:0;
    top: 0;
    transform: translate(0,-50%);
    color:white;
    background:linear-gradient(to bottom ,#5E7FDC 0%,#ED87DE 100%);
    border-radius: 50%;
    display: inline-block;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    font-size: rem-calc(18);
  }
  a{
    text-decoration: none;
    color:$black;
    display: block;
    &:hover{
      opacity: .8;
    }
  }
  li{
    position: relative;
    margin-top: 40px;
  }
  time{
    font-weight: bold;
    .date{
      font-size: rem-calc(16);
    }
  }
  .thumb{
    position: relative;
    padding-top: 62.5%;
    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      position: absolute;
      left: 0;
      top: 0;
      font-family: 'object-fit: cover;';
    }
  }
  .study-cat{
    position: absolute;
    right:0;
    bottom: 0;
    min-width:115px;
    color:white;
    font-weight: bold;
    font-size: rem-calc(14);
    background-color: #333;
    padding:.2rem .5rem;
  }
  figcaption{
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,.3);
    padding:1rem;
    min-height:180px;
    @include media-breakpoint-down(sm) {
      min-height: 140px;
    }
  }
  .school-cat--01{
    figure{
      border-bottom: 5px solid $p-pink;
    }
  }
  .school-cat--02{
    figure{
      border-bottom: 5px solid $p-orange;
    }
  }
  .school-cat--03{
    figure{
      border-bottom: 5px solid $p-green;
    }
  }
  
}
.p-news__archive{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  .news__lists{
    display: flex;
    flex-wrap:wrap;
  }
  .tab-contents{
    width: 100%;
  }
  .new{
    position: absolute;
    left:0;
    top: 0;
    transform: translate(0,-50%);
    color:white;
    background:linear-gradient(to bottom ,#5E7FDC 0%,#ED87DE 100%);
    border-radius: 50%;
    display: inline-block;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    font-size: rem-calc(18);
  }
  a{
    text-decoration: none;
    color:$black;
    display: block;
    &:hover{
      opacity: .8;
    }
  }
  li{
    position: relative;
    margin-top: 40px;
  }
  time{
    font-weight: bold;
    .date{
      font-size: rem-calc(16);
    }
  }
  .thumb{
    position: relative;
    padding-top: 62.5%;
    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      position: absolute;
      left: 0;
      top: 0;
      font-family: 'object-fit: cover;';
    }
  }
  .study-cat{
    position: absolute;
    right:0;
    bottom: 0;
    min-width:115px;
    color:white;
    font-weight: bold;
    font-size: rem-calc(14);
    background-color: #333;
    padding:.2rem .5rem;
  }
  figcaption{
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,.3);
    padding:1rem;
    min-height:180px;
    @include media-breakpoint-down(sm) {
      min-height: 140px;
    }
  }
  .tab-btns{
    display: flex;
    margin-bottom: 6rem;
    @include media-breakpoint-down(sm) {
      flex-wrap:wrap;
    }
    button{
      width: 25%;
      @include media-breakpoint-down(sm) {
        width: 50%;
        margin-bottom: 1.5rem;
        img{
          width: 100%;
        }
      }
      border:none;
      background-color: transparent;
      padding:0;
      @include opacity-href();
      position: relative;
      &.is_active{
        &:after{
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left:0;
          right: 0;
          width: 0;
          height: 0;
          margin: auto;
          border-width:15px 10px 0;
          border-style: solid;
          @include media-breakpoint-down(sm) {
            border-width:12px 8px 0;
          }
        }
        &.tab-btn--01{
          &:after{
            border-color: $black transparent transparent transparent;
          }
        }
        &.tab-btn--02{
          &:after{
            border-color: $c-hair transparent transparent transparent;
          }
        }
        &.tab-btn--03{
          &:after{
            border-color: $c-cook transparent transparent transparent;
          }
        }
        &.tab-btn--04{
          &:after{
            border-color: $c-hukusi transparent transparent transparent;
          }
        }
      }
    }
  }
  &.p-news__archive--02{
    .tab-btns{
      button{
        &.is_active{
          // &.tab-btn--01{
          //   &:after{
          //     border-color: $black transparent transparent transparent;
          //   }
          // }
          &.tab-btn--02{
            &:after{
              border-color: #fcf25d transparent transparent transparent;
            }
          }
          &.tab-btn--03{
            &:after{
              border-color: #12a2b3 transparent transparent transparent;
            }
          }
          // &.tab-btn--04{
          //   &:after{
          //     border-color: $c-hukusi transparent transparent transparent;
          //   }
          // }
        }
      }
    }
  }
  .content{
    margin-top: -5rem;
    margin-bottom: 5rem;
    transition: height .6s ease-in-out;
    position: relative;
    display: none;
    &.is_show{
      display: block!important;
      animation: tab-show .5s linear 0s;
      @keyframes tab-show{ 
        from{
          opacity: 0;
          transform:translate(-20px,20px);
        }
        to{
          opacity: 1;
          transform:translateY(0);
        }
      }
    }
  }
  .school-cat--01{
    figure{
      border-bottom: 5px solid $p-pink;
    }
  }
  .school-cat--02{
    figure{
      border-bottom: 5px solid $p-orange;
    }
  }
  .school-cat--03{
    figure{
      border-bottom: 5px solid $p-green;
    }
  }
  
}
.p-aside01{
  .head{
    font-size: rem-calc(26);
    font-weight: bold;
    text-align: center;
    background: linear-gradient(45deg, #03B2FF 0%,#4AEAFF 100%);
    padding:.7rem;
    margin-bottom: 1rem;
    color:white;
    align-items: center;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      font-size: rem-calc(16);
    }
  }
  .aside01__content{
    .twitter__link{
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        // flex-wrap:wrap;
        // flex:1 0 auto;
      }
      @include opacity-href();
      color:$black;
    }
    .thumb,
    .content{
      width: 100%;
    }
  }
  .aside01__block__inner{
    padding:0 rem(30) rem(35);
    @include media-breakpoint-only(lg) {
      padding:0 rem(16) rem(27);
    }
  }
  .aside01__wrap{
    @include media-breakpoint-up(lg) {
      display: flex;
    }
    .aside01__block{
      @include media-breakpoint-up(lg) {
        width:calc((100% - 2.5rem) / 2);
        &:first-of-type{
          margin-right: 2.5rem;
        }
      }
      &.aside01__block--twitter{
        @include media-breakpoint-down(md) {
          margin-bottom: 5rem;
        }
        .inner{
          background-color: #fff;
          box-shadow: 0px 4px 4px 0px rgba($black,.15);
        }
      }
    }
  }
  .thumb{
    // margin-right: 2rem;
    position: relative;
    padding-top: 38%;
    height: 0;
    // width: 50%;
    @include media-breakpoint-only(lg) {
      padding-top: 27%;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 68%;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      position: absolute;
      left: 0;
      top: 0;
      font-family: 'object-fit: cover;';
    }
  }
  time{
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      font-size: rem-calc(19);
    }
  }
  .title{
    margin-bottom: 0;
    font-size: rem-calc(16);
    @include media-breakpoint-up(lg) {
      display: -webkit-box;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      /*! autoprefixer: on */
      overflow: hidden;
    }
    @include media-breakpoint-up(xl) {
      -webkit-line-clamp: 7;
    }
    @include media-breakpoint-only(lg) {
      -webkit-line-clamp: 5;
    }
    @include media-breakpoint-down(lg) {
      font-size: rem-calc(14);
      margin-top: .5rem;
    }
  }
  .content{
    width: 65%;
    padding-left: 1rem;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }
  }
}
.p-aside02{
  .aside02__wrap{
    @include media-breakpoint-up(md) {
      display: flex;
    }
    .aside02__block{
      position: relative;
      width: calc(100% - 10px);
      @include media-breakpoint-up(md) {
        width:calc((100% - 2.5rem) / 2);
        &:first-of-type{
          margin-right: 2.5rem;
        }
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translate(20px,20px);
        background: linear-gradient(to bottom, #82e8f5 0%,#ed87de 100%);
        z-index: -1;
        @include media-breakpoint-down(sm) {
          transform: translate(10px,10px);
        }
      }
    }
  }
  .flag{
    @include media-breakpoint-down(md) {
      width: 40%;
    }
    &.flag--01{
      position: absolute;
      left:0;
      top: 0;
      transform: translate(-50%,-50%);
    }
    &.flag--02{
      position: absolute;
      right:0;
      top: 0;
      transform: translate(50%,-50%);
    }
  }
}
body.htc{
  .p-aside02{
    .aside02__wrap{
      .aside02__block{
        &:after{
          background: linear-gradient(to bottom, #92D514 0%,#00BAB4 100%);
        }
      }
    }
  }
}
body.iryo{
  .p-aside02{
    .aside02__wrap{
      .aside02__block{
        &:after{
          background: linear-gradient(180deg, #62B7FF 0%,#BBACFA 50%,#FFA3A7 100%);
        }
      }
    }
  }
}
.p-campus{
  position: relative;
  @include font-gradient($sp-font:20);
  @include media-breakpoint-down(sm) {}
  .p-gradient{
    @include media-breakpoint-up(md) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }
    span{
      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: center;
      }
    }
  }
  .content__block{
    @include media-breakpoint-up(md) {
      margin-top: 12rem;
    }
  }
  .img__block{
    margin-top: 5rem;
  }
  .first{
    @include media-breakpoint-up(md) {
      min-width:765px;
    }
  }
  .second{
    @include media-breakpoint-up(md) {
      min-width:630px;
    }
  }
}
.p-oc{
  background: url(#{$dir}/pages/bg_oc01.jpg)no-repeat;
  background-position: center bottom;
  background-size: cover;
  .oc__btn{
    z-index: 10;
    display: inline-block;
    position: relative;
    .chs{
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      bottom: 100%;
      right: 0;
      left: 0;
      margin: auto;
      z-index: -1;
      visibility: hidden;
    }
    .ch-head{
      display: block;
      opacity: 0;
      transition:.2s;
      &.ch-head01{
        // transform: translate(-50px,0);
        transform: translate(40px, 80%);
      }
      &.ch-head02{
        position: relative;
        z-index: 10;
        transform: translate(0,80%);
      }
      &.ch-head03{
        transform: translate(-40px, 80%);
        // transform: translate(50px,0);
      }
    }
    &:hover{
      .chs{
        visibility: visible;
      }
      &:after{
        right: 2px;
      }
      &:before{
        right: -1px;
      }
      .ch-head{
        opacity: 1;
        &.ch-head01{
          transform: translate(15px, 0);
        }
        &.ch-head02{
          transform: translate(0,0);
        }
        &.ch-head03{
          transform: translate(-15px, 0);
        }
      }
    }
  }
  .head__wrap{
    color:white;
  }
  .point__elm01{
    position: absolute;
    left: 28%;
    top: -1%;
    @include media-breakpoint-only(lg) {
      left: 25%;
    }
    @include media-breakpoint-down(md) {
      left: -8%;
      bottom: 38%;
      top:auto;
    }
  }
  .point__elm02{
    position: absolute;
    right: 28%;
    top: -3%;
    @include media-breakpoint-only(lg) {
      right: 25%;
    }
    @include media-breakpoint-down(md) {
      right: -11%;
      top: -14%;
    }
  }
  .point__elm03{
    position: absolute;
    left: -2%;
    top: -5%;
    @include media-breakpoint-down(sm) {
      left: 1%;
      top: 10%;
      width: 30%
    }
  }
  .ch-elm01{
    position: absolute;
    bottom: -10px;
    z-index: 10;
    left: -30px;
    @include media-breakpoint-down(md) {
      left: 0;
    }
    @include media-breakpoint-only(md) {
      bottom: -40px;
    }
    @include media-breakpoint-down(sm) {
      width: 10%;
      bottom: -30%;
    }
  }
  .ch-elm02{
    position: absolute;
    bottom: -10px;
    z-index: 10;
    right: -30px;
    @include media-breakpoint-down(md) {
      right: 0;
    }
    @include media-breakpoint-only(md) {
      bottom: -40px;
    }
    @include media-breakpoint-down(sm) {
      width: 10%;
      bottom: -30%;
    }
  }
  .item-01{
    @include media-breakpoint-down(sm) {
      width: 90%;
      margin: auto;
    }
    .inner{
      @include media-breakpoint-down(md) {
        position: relative;
        display: inline-block;
      }
    }
    .pic__01{
      @include media-breakpoint-up(lg) {
        position: absolute;
        right: -60px;
        top: -2%;
      }
      transform: scale(0);
      &.is_scrolled{
        transform: scale(1);
        transition:transform .4s cubic-bezier(.42,0,.37,2);
      }
    }
    @include media-breakpoint-down(sm) {
      // width: 29%;
    }
  }
  .item-02{
    margin-bottom: 2.5rem;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      margin-top: -25%;
    }
    strong{
      transform: translateY(100%);
      font-size: rem-calc(35);
      font-weight: bold;
      text-align: center;
      border-top:1px solid white;
      border-bottom:1px solid white;
      color:white;
      display: inline-block;
      margin: 1px auto;
      span{
        transform: rotate(360deg);
        display: inline-block;
      }
      @include media-breakpoint-down(sm) {
        font-size: rem-calc(25);
        display: block;
        padding:.3rem 0;
        text-shadow: 0 0 3px #838383;
      }
    }
    &.is_scrolled{
      strong{
        transition:transform .4s;
        transform: translateY(0);
      }
    }
  }
  .item-03{
    color:white;
    margin-bottom: 2.5rem;
    font-size: rem-calc(14);
    @include media-breakpoint-up(md) {
      font-size: rem-calc(18);
    }
  }
  .item-04{
    margin-bottom: 2.5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 4rem;
    }
    @keyframes jump-ch {
      0%{
        transform:translateY(0);
      }
      30%{
        transform:translateY(-30px);
      }
      40%{
        transform:translateY(-30px);
      }
      70%{
        transform:translateY(0);
      }
      100%{
        transform:translateY(0);
      }
    }
    @keyframes seesaw {
      from{
        transform:rotate(-.5deg);
      }to{
        transform:rotate(.5deg);
      }
    }
    &.is_scrolled{
      .item-04__main{
        animation:seesaw 1.5s linear infinite alternate;
      }
      .ch-elm01{
        display: inline-block;
        animation:jump-ch 3s ease-in-out infinite;
      }
      .ch-elm02{
        display: inline-block;
        animation:jump-ch 3s ease-in-out infinite 1.5s;
      }
    }
  }
  .point-lists{
    display: flex;
    flex-wrap:wrap;
    font-weight: bold;
    max-width:1155px;
    margin: auto;
    margin-bottom: rem-calc(60);
    li{
      background-color: #FCF25D;
      border-radius: 13px;
      text-align: center;
      font-size: rem-calc(18);
      padding:.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      width:calc( (100% - 9%) / 4);
      @include media-breakpoint-up(lg) {
        &:not(:last-of-type){
          margin-right: 3%;
        }
      }
      @include media-breakpoint-down(md) {
        width:calc( (100% - 2%) / 2);
        margin-bottom: 2%;
        padding:1rem .5rem;
        &:nth-of-type(odd){
          margin-right: 2%;
        }
      }
      @include media-breakpoint-down(sm) {
        font-size: rem-calc(12);
        min-height: 43px;
        &:last-of-type{
          line-height: 1.1;
        }
      }
      span{
        width: 100%;
        font-size: rem-calc(13);
      }
    }
  }
}
.p-employment{
  li{
    border-radius: 13px;
    margin-bottom: 1.5rem;
  }
  a{
    position: relative;
    color:white;
    display: block;
    border-radius: 13px;
    overflow: hidden;
    transition:transform .2s;
    img{
      transition:filter .2s;
    }
    &:hover{
      transform:scale(1.05);
      img{
        opacity:1;
        filter: brightness(1.1);
        // animation:.4s bright forwards;
        // @keyframes bright {
        //   0%{
        //     filter: brightness(1);
        //   }
        //   50%{
        //   }
        //   100%{
        //     filter: brightness(1.0);
        //   }
        // }
      }
    }
  }
  .title{
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: red;
    padding:1rem .5rem;
    text-align: center;
    font-weight: bold;
    width: 100%;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(21);
    }
  }

}
.p-video-content{
  @include media-breakpoint-down(md) {
    margin-bottom: 3rem;
  }
  &#p-video-content{
    background-color: transparent;
    padding: 56.25% 0 0;
    position: relative;
  }
  .play-btn{
    padding: 0;
    @include media-breakpoint-up(lg) {
      display: none;
    }
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(black,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border:none;
    &.is_hidden{
      opacity:0;
      visibility: hidden;
      transition:.2s;
    }
  }
  #video{
    position: absolute;
    left:0;
    top: 0;
    @include media-breakpoint-up(lg) {
      pointer-events: none;
      position:fixed;
      top: auto;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

}
// .p-gradient{
//   font-weight: bold;
//   span{
//     color:white;
//     padding:0.3rem 1rem;
//     background: linear-gradient(45deg, #82e8f5 0%,#ed87de 100%);
//   }
// }
.banner__intext{
  padding-top: 50px;
  span{
    position: absolute;
    top: -50px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
  a{
    position: relative;
  }
}
.p-img-content {
 
}
#p-img-content{
  background-color: transparent;
    // padding: 26.25% 0 0;
    height: 62vh;
    position: relative;
    @include media-breakpoint-down(sm){
      height: 41vh;
    }

    #image{
      pointer-events: none;
      position:fixed;
      inset: 0;
      
      z-index: -1;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position:center top;
      }
    }
}
.banner__reserve{
  position: relative;
  z-index: 10;
  a{
    position: relative;
    display: inline-block;
    z-index: 10;
    transition:.2s;
    &:hover{
      opacity: 1;
      transform: scale(1)rotate(3deg);
      img{
        opacity: 1;
      }
    }
  }
  .human{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: scale(0);
    @include media-breakpoint-down(sm) {
      max-width: 302px;
      width: 30%;
    }
  }
  .text{
    width: 100% ;
    max-width: 285px;
    position: relative; 
    z-index: -1;
    opacity: 0; 
    margin-bottom: 10px;
    transform: translateY(200%); 
    @include media-breakpoint-down(sm){
      margin-bottom: 5px;
      transform: translateY(170%); 
      top: calc(-35% - 10px);
    }
  }
  ul{
    position: absolute;
    left:0;
    top: -30px;
    display: flex;
    z-index: -1;
    width: 100%;
    li{
      margin-left: 0.5rem;
      transform: translateY(30px);
      
      &:not(:nth-of-type(4)){
        width: 13%;
        max-width: 47px;
        img{
        }
      }
      
      &:nth-of-type(4){
        width: 13%;
        max-width: 56px;
      }
    }
  }
  &.is_scrolled{
    .human{
      transform: scale(1);
      transition:0.4s cubic-bezier(0.42, 0, 0.37, 2) 1.05s;
    }
    .text{
      transform: translateY(0);
      opacity: 1;
      transition:0.4s cubic-bezier(0.42, 0, 0.37, 2);
    }
    ul{
      li{
        // $index : 1;
        transform: translateY(0);
        @for $index from 1 through 5 {
          &:nth-of-type(#{$index}){
            transition: .2s ease ($index / 6) + s;
          }
        }
      }
    }
  }
}

.banner__doc{
  a{
    transition:.2s;
    display: inline-block;
    &:hover{
      opacity: 1;
      transform: scale(1)rotate(3deg);
      img{
        opacity: 1;
      }
    }
    .text{
      width: 100% ;
      max-width: 354px;
      position: relative; 
      z-index: -1;
      opacity: 0; 
      margin-bottom: 10px;
      transform: translateY(200%); 
      @include media-breakpoint-down(sm){
        margin-bottom: 5px;
        transform: translateY(170%); 
        top: calc(-35% - 10px);
      }
    }
  }
  &.is_scrolled{
    .text{
      transform: translateY(0);
      opacity: 1;
      z-index: 0;
      transition:0.4s cubic-bezier(0.42, 0, 0.37, 2);
     
    }
  }
}

.cv__btns{
	position: fixed;
	display: flex;
	text-align: center;
	margin: 0;
	z-index:99;
  writing-mode: vertical-rl;
  top: 100px;
  right: 0;
  @media (max-width: 1400px) {
    // width: 100%;
    // bottom: 0;
  }
  // @media (max-width: 1399.98px) {
  //   // width: 100%;
  //   display: none;
  //   // bottom: 0;
  // }
  @include media-breakpoint-down(sm) {
    display: none;
  }

	li{
		@include media-breakpoint-down(sm) {
			width:50%;
		}
	}
	a{
		@include opacity-href();
		font-size: rem-calc(17);
		justify-content: center;
		display: flex;
		align-items: center;
		line-height: 1;
		img{
			@include media-breakpoint-up(md) {
				margin-bottom: 0.5rem;
			}
			@include media-breakpoint-down(sm) {
				margin-right: 0.5rem;
			}
		}
	}
	.document{
		@include media-breakpoint-up(md) {
			margin-bottom: 0.5rem;
		}
		span{
			word-break: keep-all;
		}
		a{
			color:$teal;
			border:2px solid $teal;
			background-color: white;
			padding:1rem .5rem;
			@include media-breakpoint-up(md) {
				padding:2rem .8rem;
				border-right:none;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
		}
	}
	.entry{
		a{
			color:$black;
			background-color: $p-pink;
			border:2px solid white;
			padding:1rem .5rem;
			@include media-breakpoint-up(md) {
				padding:2rem .8rem;
				border-right:none;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
		}
	}
}
/* ↓ オープンキャンパス各校用 ↓ */
.opencampus.hsc{
  .cv__btns{
    .document{
      a{
        color: $c-hair;
        border-color: $c-hair;
      }
    }
  }
}
// .opencampus.htc{
//   .cv__btns{
//     .document{
//       a{
//         color: $c-cook;
//         border-color: $c-cook;
//       }
//     }
//   }
// }
// .opencampus.iryo{
//   .cv__btns{
//     .document{
//       a{
//         color: $c-hukusi;
//         border-color: $c-hukusi;
//       }
//     }
//   }
// }
/* ↑ オープンキャンパス各校用 ↑ */

.cv__btns--sp{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  margin-bottom: 0;
  text-align: center;
  transition:.4s;
  @include media-breakpoint-up(md) {
    display: none;

  }
  // @media (min-width: 1400px) {
  //   display:none;
  // }
  &.is_show{
    transform: translateY(100%);
  }
  a{
    color:white;
    font-size: rem-calc(10);
    font-weight: bold;
    display: block;
    letter-spacing: 4px;
    padding: 0.5rem 0.2rem;
    @media (max-width: 340px) {
      font-size: 1vw;
    }
  }
  li{
    width:calc(60% / 3);
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
    &.document{
      a{
        background-color: $teal;
      }
    }
    &.inquiry{
      a{
        background-color: #1867D3;
      }
    }
    &.qa{
      a{
        background-color: #06C755;
      }
    }
    &.entry{
      width:40%;
      a{
        background-color: $p-pink;
      }
    }
  }
}
.wrap-open{
  
  &.scroll_target{
    position: relative;
    &::after{
      content: '';
      display: inline-block;
      background-color:#fff;
      position: absolute;
      inset: 0;
      transition: right 1s cubic-bezier(.72,.02,.26,1);
     
    }
    &.is_scrolled{
      &::after{
        right: 100%;
      }
    }
  }
 
}

.order-show{
  &.scroll_target{
    &> *{
      opacity: 0;
      transform: scale(1.2);
      transition: transform 1s ease, opacity 0.3s ease;
    }
    @for $i from 1 through 5 {
      &> .show#{$i} {
        transition-delay: calc(#{$i} * 0.2s);
      }
    }
    &.is_scrolled{
      &> *{
        transform: scale(1.0);
        opacity: 1;

      }
    }
  }
}
// .btn-space-top{
//   margin-top: 4.5rem;
//   @include media-breakpoint-down(sm){
//     margin-top: 2.5rem;
//   }
// }

