﻿//
// Pages
//
body.page{
  main{
    .container{
      @include media-breakpoint-up(lg) {
        max-width:1020px;
      }
    }
  }
  .breadcrumb{
    background: transparent;
    padding:1rem 0;
    .breadcrumb-item{
      font-size: rem-calc(12);
      color:$black;
    }
    .breadcrumb-link{
      color:$black;
      text-decoration: underline;
      @include opacity();
    }
  }
  .page-contents{
    margin-bottom: 10rem;
    .head-ch{
      position: relative;
      padding:0 0 .7rem 2.8rem;
      &:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width:34px;
        height: 50px;
      }
      &.head-ch--01{
        &:before{
          background: url(#{$dir}/pages/item_head-ch01.png)no-repeat;
          background-size: cover;
        }
      }
      &.head-ch--02{
        &:before{
          background: url(#{$dir}/pages/item_head-ch02.png)no-repeat;
          background-size: cover;
        }
      }
      &.head-ch--03{
        &:before{
          background: url(#{$dir}/pages/item_head-ch03.png)no-repeat;
          background-size: cover;
        }
      }
      &.head-ch--04{
        &:before{
          background: url(#{$dir}/pages/item_head-ch04.png)no-repeat;
          background-size: cover;
        }
      }
      &.head-ch--05{
        &:before{
          background: url(#{$dir}/pages/item_head-ch05.png)no-repeat;
          background-size: cover;
        }
      }
      &.head-ch--06{
        &:before{
          background: url(#{$dir}/pages/item_head-ch06.png)no-repeat;
          background-size: cover;
        }
      }
    }
  }

  @include tabBtn;
  .tab-btns2{
      button,a{
        color:$black;
        background-color: $orange;
        border-color: $orange;
      }
      li.is_active{
        button,a{
          color:$black;
          background-color: $white;
          border-color: $orange;
        }
      }
    }

  &.hsc{
    .tab-btns2{
      button,a{
        color:$white;
        background-color: $pink;
        border-color: $pink;
      }
      li.is_active{
        button,a{
          color:$pink;
          background-color: $white;
          border-color: $pink;
        }
      }
    }

  } 
   
 &.htc{
  .tab-btns2{
    button,a{
      color:$white;
      background-color: $p-orange;
      border-color: $p-orange;
    }
    li.is_active{
      button,a{
        color:$p-orange;
        background-color: $white;
        border-color: $p-orange;
      }
    }
  }
 }
 
 &.iryo{
  .tab-btns2{
    button,a{
      color:$white;
      background-color: $c-hukusi;
      border-color: $c-hukusi;
    }
    li.is_active{
      button,a{
        color:$c-hukusi;
        background-color: $white;
        border-color: $c-hukusi;
      }
    }
  }
 }
 
}


body.school-home,
body.home{
  main{
    h2{
      font-size: rem-calc(20);
      font-weight: bold;
    }
    .en{
      line-height: 1.2;
      // height: 60px;
    }
    
  }
  .head__wrap{
    text-align: center;
    margin-bottom: 3rem;
    font-size: rem-calc(50);
    line-height: 0;
    @include media-breakpoint-down(sm) {
      font-size: rem-calc(40);
    }
    .en{
      span{
        font-size: rem-calc(0);
        line-height: 1.2;
      }
    }
    h2{
      overflow: hidden;
      position: relative;
      &:after{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
        height:1px;
        background-color: $black;
        transform: translateX(-100%);
      }
      span{
        display: inline-block;
        transform: translateY(100%);
      }
    }
    &.is_scrolled{
      .en{
        span{
          font-size: rem-calc(50);
          @include media-breakpoint-down(sm) {
            font-size: rem-calc(40);
          }
          @for $index from 1 through 11 {
            &:nth-of-type(#{$index}){
              transition: .001s cubic-bezier(0.72,-0.01, 0.02, 1.41) ($index / 8) + s;
            }
          }
        }
      }
      h2{
        &:after{
          transition:.4s;
          transform: translateX(100%);
        }
        span{
          transition:.5s ease-in-out .4s;
          transform: translateY(0);
        }
      }
    }
  }
}

body.school-home main,
body.lp main{
  font-size: rem-calc(14);
}

body{
  @include media-breakpoint-down(sm) {
    font-size: rem-calc(14);
  }
  &.school-home{
    .section{
      padding-top:5rem;
      padding-bottom:5rem;
    }
    .section--01{
      position: relative;
      overflow: hidden;
    }
    .hero {
      //各校共通スライダー1番目
      .hero-slider__item01{
        background-color: #DCE2F2 !important;
        position: relative;
        padding-top: 0;
        aspect-ratio: 1500/760;
        @media(max-width:1024.98px){
          padding-top: 0;
          aspect-ratio: 375/552;
        }
        .hero-item{
          position: absolute;
          z-index: 10;
          overflow: hidden;
          animation:stepImg01 2s steps(2) infinite;
          transform:scale(.5);
          opacity:0;
        }
        .hero-item--01{
          left: 3%;
          top: 2%;
          background: url(#{$dir}/pages/slider/img_hero-item01@2x.png)no-repeat;
          background-size: 200%;
          width: 19.6%;
            height: 41%;
          aspect-ratio: 294/344;
          // @include media-breakpoint-down(md) {
          // 	width: 50%;
          // 	height: 28%;
          // 	top: 23%;
          // 	left: 0;
          // }
          @media (max-width: 1024.98px){
            width: 32%;
            height: 34%;
            top: 21%;
            right: 8%;
            left: auto;
            z-index: 4 !important;
          }
        }
        .hero-item--02{
          top: 30%;
          left: 16%;
          background: url(#{$dir}/pages/slider/img_hero-item02@2x.png)no-repeat;
          background-size: 200%;
          width: 18.8%;
          height: 32%;
          aspect-ratio: 282/245;
          // @include media-breakpoint-down(md) {
          // 	width: 17%;
          // 	height: 23%;
          // 	top: 54%;
          // 	left: 3%;
          // }
          @media (max-width: 1024.98px){
            width: 29%;
                left: auto;
                height: 23%;
                top: 50%;
            right: 3%;
          }
        }
        .hero-item--03{
          top: 22%;
          right: 21%;
          background: url(#{$dir}/pages/slider/img_hero-item03@2x.png)no-repeat;
          background-size: 200%;
          width: 17.5%;
          height: 41.9%;
          aspect-ratio: 263/319;
          @media (max-width: 1024.98px){
            width: 29.3%;
            height: 32%;
            top: 43%;
            right: auto;
            left: 3%;
          }
        }
        .hero-item--04{
          top: 6%;
          right: 6%;
          background: url(#{$dir}/pages/slider/img_hero-item04@2x.png)no-repeat;
          background-size: 200%;
          width: 18.8%;
          height: 35%;
          aspect-ratio: 283/270;
          // @include media-breakpoint-down(md) {
          // 	width: 22%;
          // 	height: 21%;
          // 	top: 25%;
          //   right: 0;
          // }
          @media (max-width: 1024.98px){
            width: 29%;
            height: 25%;
            top: 22%;
            left: 8%;
            right: auto;
          }
        }
        .hero-item--05{
          bottom: 8%;
          left: 1%;
          background: url(#{$dir}/pages/slider/img_hero-item05@2x.png)no-repeat;
          background-size: 200%;
          width: 15.8%;
          height: 35%;
          aspect-ratio: 237/266;
          // @include media-breakpoint-down(md) {
          // 	width: 24.8%;
          // 	height: 23.1%;
          // 	bottom: 26%;
          //   right: 2%;
          // }
          @media (max-width: 1024.98px){
            width: 25.6%;
                height: 25.7%;
                bottom: 1%;
                right: 2%;
          }
        }
        .hero-item--06{
          bottom: 8%;
          right: 6%;
          background: url(#{$dir}/pages/slider/img_hero-item06@2x.png)no-repeat;
          background-size: 200%;
          width: 15.6%;
          height: 35.2%;
          aspect-ratio: 235/268;
          @include media-breakpoint-down(md) {
            width: 26.1%;
            height: 26.9%;
            bottom: 1%;
            right: 5%;
          }
        }
        // .hero-item--07{
        // 	display: none;
        // 	top: 41%;
        // 	left: 11%;
        // 	background: url(#{$dir}/home/img_hero-item07@2x.png)no-repeat;
        // 	// background-size: 200%;
        // 	background-size: contain;
        // 	width: 39%;
        // 	height: 19%;
        // 	@include media-breakpoint-down(md) {
        // 		top: 80%;
        // 		width:17%;
        // 	}
        // }
        .hero-img{
          position: absolute;
          right: 0;
          bottom: 0;
          left: -2%;
          margin: auto;
          width: 44%;
          transform: translateY(10%);
          opacity: 0;
          z-index: 5;
          @media (max-width: 1024.98px){
            width: 90%;
            left: 0%;
          }
        }
        .hero-text{
          position: absolute;
          top: 5%;
          // right: 50%;
          left: 50%;
          transform: translate(-50%, 0);
          margin: auto;
          text-align: center;
          display: inline-block;
          width: 35%;
          overflow: hidden;
          img{
            display: inline;
            opacity: 0;
            transform: translateX(-20px);
          }
          &:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            // opacity: 0;
            background-color: #fff;
            width:0;
          }
          @include media-breakpoint-down(md) {
            top: 2%;
            width: 100%;
            img{
              width: 90%;
            }
          }
        }
    
        &.is_show{
          .hero-text{
            img{
              transition:opacity .001s ease .3s,transform 1s ease .4s;
              opacity: 1;
              transform: translateX(0);
            }
            &:after{
              transition:width .3s,transform .3s ease .5s;
              width:100%;
              transform: translateX(100%);
            }
          }
          .hero-item{
            transition:1.5s cubic-bezier(0.82, 0.15, 0.21, 1.24) .7s;
            transform:scale(1);
            opacity:1;
          }
          .hero-img{
            transition:.6s ease .5s;
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
      position: relative;
    }
    .hero__elm{
      &.hero__elm--01{
        width: 100%;
        z-index: 10;
        @include media-breakpoint-up(lg) {
          position: absolute;
          left:0;
          bottom:0;
        }
      }
      .pickup{
        // position: absolute;
        // bottom: 0;
        // left: 0;
        background: rgba(#000,.6);
        color:white;
        margin-top: 1rem;
        padding-left: 3%;
        @include media-breakpoint-down(md) {
          padding:10px;
        }
        .pickup__slide__wrap{
          .pickup__slide{
            .article{
              @include media-breakpoint-up(lg) {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                max-width: 80vw;
              }
            }
          }
        }
        .title{
          font-size: rem-calc(27);
          white-space: nowrap;
          @include media-breakpoint-down(md) {
            font-size: rem-calc(18);
          }
        }
        a{
          color:white;
          text-decoration: underline;
          @include media-breakpoint-down(md) {
            font-size: rem-calc(14);
          }
        }
      }
      &.is_scrolled{
        .hero-slide{
          transform: translateX(0);
          opacity: 1;
          transition:1s ease 1.3s;
        }
      }
    }
  }
  &.child-page{
    &.day{
      &.page.page02{
        h1{
          display: none;
        }
      }
      &:not(.page2){
        main{
          margin-bottom: 0;
        }
      }
      .day__slide__inner{
        position: relative;
        height: 300px;
        @include media-breakpoint-only(md) {
          max-width:560px;
        }
        @include media-breakpoint-down(sm) {
          max-width: 440px;
        }
        margin: auto;
      }
      .day__slide{
        position: relative;
        padding:rem(40) 0;
        @include media-breakpoint-up(lg) {
          padding:rem(55) 0;
        }
        &:before{
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 0;
          width: 100vw;
          height: 100%;
          margin-left: -50vw;
          z-index: -1;
        }
        .slick-track{
          display: flex;
          align-items: flex-end;
        }
        .slick-next,.slick-prev{
          position: absolute;
          left: auto;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 10;
          width:0;
          height: 0;
          background-color: transparent;
          transform:none;
          transition:opacity 0.6s linear;
          @include opacity();
          border-style:solid;
          border-width:17px 0 17px 20px;
          @include media-breakpoint-up(md) {
            border-width:30px 0 30px 35px;
          }
          &.slick-disabled{
            opacity: 0.5;
          }
        }
        .slick-prev{
          position: absolute;
          left: 0;
          right: auto;
          top: 0;
          bottom: 0;
          margin: auto;
          transform:rotate(180deg);

        }
      }
      .day__slide__start,.day__slide__goal{
        width: auto !important;
      }
     
      .day__slide__item{
        margin-right: 0.5rem;
        @include media-breakpoint-up(md) {
          margin-right: 1.5rem;
        }
        img{
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
          -webkit-font-smoothing: antialiased;
        }
      }
      .day__slide__wrap{
        padding-left: 16%;
        padding-right: 8%;
        @include media-breakpoint-up(md) {
          padding-left: 80px;
          padding-right: 60px;

        }
        .slick-list{
          @include media-breakpoint-up(md) {
            padding: 0 20% 0 0!important
          }
        }
      }
      .day__flow{
        position: relative;
        padding:rem(40) 0;
        @include media-breakpoint-up(lg) {
          padding:rem(55) 0;
        }
        &:before{
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 0;
          width: 100vw;
          height: 100%;
          margin-left: -50vw;
          z-index: -1;
          background: repeating-linear-gradient(-45deg,#FEF7FA,#FEF7FA 13px,#fff 0,#fff 26px);
        }
      }
      .day__flow-content{
        gap:40px 64px;
      }
      .day__flow-item{
        width: calc(50% - 32px);
        max-width: 340px;
        @include media-breakpoint-down(sm){
          width: 100%;
          margin-inline: auto;
        }
      }
      .day__life__section{
        position: relative;
        &:before{
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 0;
          width: 100vw;
          height: 100%;
          margin-left: -50vw;
          z-index: -1;
        }
        .day__life__link{
          .life__link__lists{
            li{
              &::before{
                background: #fff !important;
              }
              .life__link{
                color: #fff;
              }
            }
          }
        }
        .day-insta{
          display: flex;
          max-width:600px;
          margin: auto;
          .day-insta__lists{
            display: flex;
            flex-wrap:wrap;
          }
        }
        .insta__item{
          position: absolute;
          display: none;
          @include media-breakpoint-up(md) {
            display: block;
          }
          &.insta__item--01{
            right: -50px;
            top: 12%;
          }
          &.insta__item--02{
            left: -50px;
            top: 30%;
          }
          &.insta__item--03{
            right: -50px;
            bottom: 30%;
          }
          &.insta__item--04{
            left: -50px;
            bottom: 0;
          }
        }
        .day__life__link{
          @include media-breakpoint-down(sm) {
            font-size: rem-calc(14);
          }
          @include media-breakpoint-up(md) {
            display: flex;
            justify-content: center;
          }
          li{
            margin-bottom: 1rem;
          }
          .life__link{
            color:$black;
            text-decoration: underline;
            @include opacity();
          }
        }
        .life__link__lists{
          max-width:660px;
          margin: auto;
        }
      }
      &.hsc{
        .day__slide{
          &:before{
            background: repeating-linear-gradient(-45deg,#FEF7FA,#FEF7FA 13px,#fff 0,#fff 26px);
          }
          .slick-next,.slick-prev{
            border-color:transparent transparent transparent #DB1966;
          }
        }
        .day__life__section{
          &.day__life__section--01{
            &:before{
              background: repeating-linear-gradient(90deg,#EA61A1,#EA61A1 65px,#E85298 0,#E85298 172px);
            }
          }
          &.day__life__section--02{
            &:before{
              background: repeating-linear-gradient(90deg,#EA61A1,#EA61A1 65px,#E85298 0,#E85298 172px);
            }
          }
          &.day__life__section--03{
            &:before{
              background: repeating-linear-gradient(90deg,#EA61A1,#EA61A1 65px,#E85298 0,#E85298 172px);
            }
          }
        }
      }
      &.htc{
        .day__slide{
          &:before{
            background: repeating-linear-gradient(-45deg,#FEF6EE,#FEF6EE 13px,#fff 0,#fff 26px);
          }
          .slick-next,.slick-prev{
            border-color:transparent transparent transparent $c-cook;
          }
        }
        .day__life__section{
          &.day__life__section--01{
            &:before{
              background: repeating-linear-gradient(90deg,#EF8E27,#EF8E27 65px,#F08325 0,#F08325 172px);
            }
          }
          &.day__life__section--02{
            &:before{
              background: repeating-linear-gradient(90deg,#EF8E27,#EF8E27 65px,#F08325 0,#F08325 172px);
            }
          }
          &.day__life__section--03{
            &:before{
              background: repeating-linear-gradient(90deg,#EF8E27,#EF8E27 65px,#F08325 0,#F08325 172px);
            }
          }
        }
        .day-acc{
          li{
            border:none;
            margin-bottom: 2rem;
          }
          .accBtn{
            background-color: $gray;
            color:white;
            border:none;
            font-weight: bold;
            font-size: rem-calc(21);
            @include media-breakpoint-down(sm) {
              padding: 1rem 10px;
            }
            @include media-breakpoint-up(md) {
              font-size: rem-calc(25);
            }
            &:after{
              border-color:transparent white white transparent;
              width:12px;
              height: 12px;
              border-width:2px;
              right: 11px;
              @include media-breakpoint-up(md) {
                width:20px;
                height: 20px;
                right: 39px;
                border-width: 4px;
              }
            }
            &:before{
              content: '';
              position: absolute;
              margin: auto;
              display: block;
              background-color: #fff;
              transition:transform .2s;
              top: 0;
              right: 16px;
              bottom: 0;
              width: 2px;
              height:15px;
              @include media-breakpoint-up(md) {
                bottom: 5px;
                right: 47px;
                width:4px;
                height: 25px;
              }
            }
            &.is_active{
              &:before{
                transform: rotate(-180deg)translateY(-3px);
              }
            }
            &.cooking{
              background-color: #FA7402;
            }
            &.confectionery{
              background-color: #A84F25;
            }
          }
          .accContent{
            padding:3rem 0;
            @include media-breakpoint-down(md){
              padding:1.5rem 0;
            }
            .upper{
              width: fit-content;
              max-width: 448px;
              margin-inline: auto;
            }
          }
        }

      }
      &.iryo{
        .day__slide__title{
          font-size: rem-calc(20);
          font-weight: bold;
          @include media-breakpoint-up(md) {
            font-size: rem-calc(25);
          }
        }
        .day__slide{
          .slick-prev:before,
          .slick-next:before{
            content: none;
          }
          &.day__slide--01{
            &:before{
              background: #E4EDF9;
            }
            .slick-next,.slick-prev{
              border-color:transparent transparent transparent #007FC7;
            }
          }
          &.day__slide--02{
            &:before{
              background: #FCE4DF;
            }
            .slick-next,.slick-prev{
              border-color:transparent transparent transparent #E96E69;
            }
          }
          &.day__slide--03{
            &:before{
              background: #E7F0E6;
            }
            .slick-next,.slick-prev{
              border-color:transparent transparent transparent #43966C;
            }
          }
        }
        .day__life{
          &--kaigo{
            .day__life__section{
              &.day__life__section--01,
              &.day__life__section--02,
              &.day__life__section--05{
                &:before{
                  background: repeating-linear-gradient(90deg,#8AB4DB,#8AB4DB 65px,#7FADD8 0,#7FADD8 172px);
                }
              }

              &.day__life__section--05{
                .life__link__lists{
                  li{
                    list-style-type: none;
                    &:before{
                      content:"";
                      display: inline-block;
                      width:1em;
                      height:1em;
                    
                      vertical-align: middle;
                      margin-right: .5rem;
                      margin-bottom: 2px;
                    }
                
                  }
                }
              }
            }
          }
          &--kodomo{
            .day__life__section{
              &.day__life__section--01,
              &.day__life__section--03,
              &.day__life__section--05{
                &:before{
                  background: repeating-linear-gradient(90deg,#F59896,#F59896 65px,#F48E8C 0,#F48E8C 172px);
                }
              }
            }
          }
          &--yakugyo{
            .day__life__section{
              &.day__life__section--01,
              &.day__life__section--04,
              &.day__life__section--05{
                &:before{
                  background: repeating-linear-gradient(90deg,#96C3A0,#96C3A0 65px,#8CBD97 0,#8CBD97 172px);
                }
              }
            }
          }
        }
        .day__life__section{
        //   &.day__life__section--01{
        //     &:before{
        //       background: repeating-linear-gradient(90deg,#54B193,#54B193 65px,#43AA88 0,#43AA88 172px);
        //     }
        //   }
          &.day__life__section--02{
            &:before{
              background: repeating-linear-gradient(90deg,#8AB4DB,#8AB4DB 65px,#7FADD8 0,#7FADD8 172px);
            }
          }
          &.day__life__section--03{
            &:before{
              background: repeating-linear-gradient(90deg,#F59896,#F59896 65px,#F48E8C 0,#F48E8C 172px);
            }
          }
          &.day__life__section--04{
            &:before{
              background: repeating-linear-gradient(90deg,#96C3A0,#96C3A0 65px,#8CBD97 0,#8CBD97 172px);
            }
          }
        //   &.day__life__section--05{
        //     &:before{
        //       background: white;
        //     }
        //   }
        }
        .day-acc{
          li{
            border:none;
            margin-bottom: 2rem;
          }
          .accBtn{
            background-color: $gray;
            color:white;
            border:none;
            font-weight: bold;
            font-size: rem-calc(21);
            @include media-breakpoint-down(sm) {
              padding: 1rem 10px;
            }
            @include media-breakpoint-up(md) {
              font-size: rem-calc(25);
            }
            &:after{
              border-color:transparent white white transparent;
              width:12px;
              height: 12px;
              border-width:2px;
              right: 11px;
              @include media-breakpoint-up(md) {
                width:20px;
                height: 20px;
                right: 39px;
                border-width: 4px;
              }
            }
            &:before{
              content: '';
              position: absolute;
              margin: auto;
              display: block;
              background-color: #fff;
              transition:transform .2s;
              top: 0;
              right: 16px;
              bottom: 0;
              width: 2px;
              height:15px;
              @include media-breakpoint-up(md) {
                bottom: 5px;
                right: 47px;
                width:4px;
                height: 25px;
              }
            }
            &.is_active{
              &:before{
                transform: rotate(-180deg)translateY(-3px);
              }
            }
          }
          .accContent{
            padding:3rem 0;
          }
        }
        .day__life__chs{
          @include media-breakpoint-up(md) {
            display: flex;
            justify-content: center;
          }
          .life__chs__block{
            margin-bottom: 3rem;
            @include media-breakpoint-up(lg) {
              &:not(:last-of-type){
                margin-right: 3rem;
              }
            }
            @include media-breakpoint-only(md) {
              &:not(:last-of-type){
                margin-right: 1rem;
              }
            }
          }
          .other-link01{
            // padding-top: 2.4rem;
          }
        }
        .life__head{
          font-weight: bold;
          font-size: rem-calc(35);
          color:white;
          text-shadow:3px 3px 0 #000,1px 1px 0 #000, -1px -1px 0 #000,
          -1px 1px 0 #000,  1px -1px 0 #000,
           1px 0 0 #000, -1px  0 0 #000,
           0 1px 0 #000,  0 -1px 0 #000;
          @include media-breakpoint-up(md) {
            text-shadow:5px 5px 0 #000,1px 1px 0 #000, -1px -1px 0 #000,
            -1px 1px 0 #000,  1px -1px 0 #000,
             1px 0 0 #000, -1px  0 0 #000,
             0 1px 0 #000,  0 -1px 0 #000;
            font-size: rem-calc(64);
          }
        }
        .day-insta{
          display: flex;
          max-width:600px;
          margin: auto;
          .day-insta__lists{
            display: flex;
            flex-wrap:wrap;
          }
        }
      }
    }
    &.gyouji{
      .page-header{
        h1{
          background-color: transparent;
          padding:0;
        }
      }
      .intro{
        margin-bottom: 3rem;
        @include media-breakpoint-up(md) {
          font-size: rem-calc(20);
        }
      }
      .gyouji__block{
        &:nth-of-type(odd){
          background-color: #FBEBF3;
        }
        &:nth-of-type(even){
          background: repeating-linear-gradient(90deg,#FDF5F9,#FDF5F9 11px,#fff 0,#fff 22px);
        }
      }
      .gyouji__head{
        // display: flex;
        // align-items: center;
        // flex-wrap:wrap;
        margin: 3.5rem 0 0;
        @include media-breakpoint-up(md) {
          margin: 4rem 0 0;
        }
        .gyouji__head__num{
          padding: .5rem 1rem;
          color:white;
          background: $c-hair-second;
          font-size: rem-calc(26);
          // width:21%;
          text-align: center;
          // height: 46px;
          // margin:auto 5% auto 0;
          position: relative;
          @include media-breakpoint-up(md) {
            // height: 60px;
            // width:135px;
            font-size: rem-calc(35);
            // padding:0 0 0 20px
          }
          // &:after{
          //   content: '';
          //   display: block;
          //   position: absolute;
          //   top: 0;
          //   left: 100%;
          //   height: 0;
          //   width: 0;
          //   border-width:23px 0 23px 15px;
          //   border-color:transparent transparent transparent $c-hair-second;
          //   border-style:solid;
          //   @include media-breakpoint-up(md) {
          //     border-width:30px 0 30px 20px;
          //   }
          // }
          .gyouji__head__month{
            font-size: rem-calc(18);
            @include media-breakpoint-up(md) {
              font-size: rem-calc(24);
            }
          }
        }
        .gyouji__head__content{
          padding-bottom: 2rem;
          // width:calc((100% - 26%));
          @include media-breakpoint-up(md) {

          }
        }
        .gyouji__head__lists{
          width:fit-content;
          margin-left: auto;
          margin-right: auto;
          font-size: rem-calc(16);
          @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
          }
          .gyouji__head__item{
            margin-bottom: 0.3rem;
          }
        }
        .gyouji__head__year{
          border:1px solid $c-hair-second;
          border-radius: 5px;
          color:$c-hair-second;
          padding:0 rem(10);
          text-align: center;
          font-size: rem-calc(11);
          margin-left: 0.5rem;
          display: inline-block;
          line-height: 1.4;
          @include media-breakpoint-up(md) {
            font-size: rem-calc(16);
          }
        }
      }
      .gyouji__content{
        // background: repeating-linear-gradient(90deg,#FDF5F9,#FDF5F9 11px,#fff 0,#fff 22px);
        padding:0 0 rem(30);
        @include media-breakpoint-up(md) {
          padding:0 0 rem(60);
        }
        .gyouji__content__inner{
          max-width: 870px;
          margin: auto;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          @include media-breakpoint-down(md) {
            flex-wrap:wrap
          }
        }
        .gyouji__content__block{
          position: relative;
          text-align: center;
          &:not(:last-of-type){
            // margin-right: rem(130);
          }
          @include media-breakpoint-up(lg) {
            &:nth-of-type(odd){
              text-align: left;
            }
            &:nth-of-type(even){
              text-align: right;
            }
          }
        }
        .gyouji__content__description{
            // display: flex;
            // justify-content: center;
            // align-items: flex-end;
            margin-top: -60px;
          
          .description__head{
            color: $c-hair-second;
            background-color: #fff;
            text-align: center;
            font-size: rem-calc(15);
            font-weight: bold;
            border-top: 1px solid $c-hair-second;
            border-bottom: 1px solid $c-hair-second;
            @include media-breakpoint-up(md) {
              font-size: rem-calc(22);
            }
          }
          .description__content{
            @include media-breakpoint-up(md) {
              max-width:410px;
            }
          }
          .description__img{
            img{
              position: relative;
            }
          }
          
        }
      }
      .gyouji__title{
        img{
          transform:translateY(-35px);
        }
        
      }
      section{
        &.colabo{
          width: 100vw;
          margin-left: calc(-50vw + 50%);

        }
      }
      .colabo{
        padding:0;
        margin-bottom: 2rem;
        @include media-breakpoint-up(lg) {
          padding:0 0 rem(0);
        }
        &__inner{
          width:100%;
          max-width:1100px;
          margin:0 auto;
        }
        &.colabo--01{
          // background-image: linear-gradient(-45deg, #fff 25%, #FED3A7 25%, #FED3A7 50%, #fff 50%, #fff 75%, #FED3A7 75%, #FED3A7);
          // background-image: linear-gradient(-45deg, #fff 5%, #FED3A7 25%, #FED3A7 50%, #fff 50%, #fff 75%, #FED3A7 75%, #FED3A7);
          // background-size: 1px 20px;
          background:repeating-linear-gradient(-45deg, #fff 0, #fff 2px, #FADAE8 2px, #FADAE8 25px);
          // background-color: #FED3A7;
          padding:rem(30) 0;
          @include media-breakpoint-up(md) {
            padding:rem(60) 0 rem(30);
          }
        }
        &.colabo--02{
          background:repeating-linear-gradient(-45deg, #fff 0, #fff 2px, #FED3A7 2px, #FED3A7 25px);
          // background-color: #E0EAB7;
          padding:rem(30) 0;
          @include media-breakpoint-up(md) {
            padding:rem(60) 0 rem(30);
          }
        }
        &.colabo--03{
          background:repeating-linear-gradient(-45deg, #fff 0, #fff 2px, #E0EAB7 2px, #E0EAB7 25px);
          // background-color: #E0EAB7;
          padding:rem(30) 0;
          @include media-breakpoint-up(md) {
            padding:rem(60) 0 rem(30);
          }
        }
        .colabo__content__inner{
          max-width: 870px;
          margin: auto;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          // @include media-breakpoint-down(md) {
          //   flex-wrap:wrap
          // }
        }
        .colabo__head{
          margin:0 0 1rem;
        }
        .colabo__content__block{
          position: relative;
          text-align: center;
          &:not(:last-of-type){
            // margin-right: rem(130);
          }
          @include media-breakpoint-up(lg) {
            &:nth-of-type(odd){
              text-align: left;
            }
            &:nth-of-type(even){
              text-align: right;
            }
          }
          .colabo__content__img--01{
            @include media-breakpoint-up(md) {
              // transform: translateY(40px);
            }
          }
        }
      }
      .colabo__charm{
        margin-bottom: 2rem;
      }
      .colabo__charm__description{
        background-color: #FED3A7;
        padding:rem(10) rem(15);
        font-weight: bold;
        @include media-breakpoint-up(md) {
          padding:rem(35);
          font-size: rem-calc(16);
        }
      }
      .colabo__htc-iryo{
        max-width:850px;
        margin: auto;
        @include media-breakpoint-up(md){
          text-align: center;
          width: 100% !important;
        }
      }
      .sdgs{
        width: 100vw;
        margin-left: calc(-50vw + 50%);
        position:relative;
        &__bg{
          position:absolute;
          background-color:#F8DCEA;
          top:0; left:0; right:0; bottom:0;
          clip-path:inset(12.5vw 0 0 0);
          z-index: -1;
          @include media-breakpoint-up(lg) {
            clip-path:inset(109px 0 0 0);
          }
          &._01{
            background-color:#F8DCEA;
          }
          &._03{
            background-color:#EDF8F2;
          }
        }
        &__icon{
          display:table;
          margin:0 auto;
          width:25vw;
          max-width:230px;
          
        }
        &__content{
          max-width:850px;
          margin:0 auto;
        }
        &__btn{
          
        }
      }
      &.iryo{
        .gyouji__block{
          &:nth-of-type(odd){
            background-color: #F5F9EC;
          }
          &:nth-of-type(even){
            background: repeating-linear-gradient(90deg,#F5F9EC,#F5F9EC 11px,#fff 0,#fff 22px);
          }
        }
      }
      &.iryo{
        // .gyouji__block{
        //   &:nth-of-type(odd){
        //     background-color: #FBEBF3;
        //   }
        //   &:nth-of-type(even){
        //     background: repeating-linear-gradient(90deg,#FDF5F9,#FDF5F9 11px,#fff 0,#fff 22px);
        //   }
        // }
        .gyouji__head{
          .gyouji__head__num{
            background: $p-green;
            &:after{
              border-color:transparent transparent transparent $p-green;
            }
          }
          .gyouji__head__year{
            border:1px solid $p-green;
            color:$p-green;
          }
        }
        .gyouji__content{
          // background: repeating-linear-gradient(90deg,#F5F9EC,#F5F9EC 11px,#fff 0,#fff 22px);
        }
        .icon_pink-box{
          width: 1em;
          height: 1em;
        }
      }
      &.hsc{
        // .gyouji__content__description{
        //   display:block;
        //   .description__img{
        //     span{
        //       position:relative;
        //       display:inline-block;
        //       width: 100%;
        //       .fukidashi{
        //         width:60vw;
        //         max-width:330px;
        //         position: absolute;
        //         top:-20%;right:1%;
        //         display: inline-block;
        //         @include media-breakpoint-up(lg) {
        //           top:-30%;right:18%;
        //         }

               
        //       }
        //       img:not(.fukidashi){
        //         width:40vw;
        //         max-width: 194px;
        //         display:inline-block;
        //         margin-right:auto;
        //         @include media-breakpoint-up(md) {
        //           margin-right:20%;
        //         }
                
        //       }
        //     }
        //   }
        // }
      }
      &.htc{
        .gyouji__block{
          &:nth-of-type(odd){
            background-color: #FDF0E4;
          }
          &:nth-of-type(even){
            background: repeating-linear-gradient(90deg,#FDF0E4,#FDF0E4 11px,#fff 0,#fff 22px);
          }
        }
        .gyouji__head{
          .gyouji__head__num{
            background: $c-cook;
            &:after{
              border-color:transparent transparent transparent $c-cook;
            }
          }
          .gyouji__head__year{
            border:1px solid $c-cook;
            color:$c-cook;
          }
        }
        .gyouji__content{
          // background: repeating-linear-gradient(90deg,#FFF1E5,#FFF1E5 11px,#fff 0,#fff 22px);
          
          .gyouji__content__description{
            .description__head{
              color: $c-cook;
              border-top: 1px solid $c-cook;
              border-bottom: 1px solid $c-cook;
            }
          }
        }
        .icon_pink-box{
          width: 1em;
          height: 1em;
        }
        .gyouji__content__description{
          margin-top:-80px;
        }
        // .colabo{
        //   // &.colabo--01{
        //   //   background:repeating-linear-gradient(-45deg, #fff 0, #fff 2px, #FADAE8 2px, #FADAE8 25px);
            
        //   // }

        // }
        .sdgs{
         
          &__content {
            width: 100%;
            position:relative;
            max-width:1000px;
            margin: 0 auto;
            @include media-breakpoint-down(sm){
              width: 92%;
            }
            
          }
          &__bg-top,&__bg-bottom{
            width:100%;
          
          }
          &__bg-bottom{
              display: flex;
            
          }
          &__content-wrap{
            width: 100%;
            // background: url('../img/pages/htc/bg-gyouji_sdgs02.svg') left top/contain repeat-y;
            border-left: 9px solid #CFA55C;
            border-right: 9px solid #CFA55C;
            background-color: #D7EFFC;
            padding: 65px 0 25px;
            @include media-breakpoint-down(md){
              border-width: 7px;
              // border-left: 7px solid #CFA55C;
              // border-right: 7px solid #CFA55C;
            }
            @include media-breakpoint-down(sm){
              padding: 24px 0 20px;
              border-width: 5px;
              
            }
            @media(max-width:660px){
              border-width: 0.9vw;
            }
          }
          &__content-in{
            width: 88%;
            max-width: 857px;
            margin-inline: auto;
            gap: 26px 46px;
            
            .item{
              width:calc(50% - 23px);
              @include media-breakpoint-down(sm){
                width: 100%;
              }
            }
            .sdgs__btn{
              a{
                width:100%;
                max-width:220px;
              }
            }
            
          }
        }
        // .gyouji__content__description{
        //   
        //   .description__img{
        //     margin-top:-80px;
        //   }
        // }
      }
    }
    &.hutankeigen{
      .hutatkeigen__inner{
        max-width:730px;
        margin: auto;
      }
      .hutankeigen__block--01{
        max-width:630px;
        margin: auto;
        .hutankeigen__block__text{
          @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
            font-weight: bold;
          }
        }
      }
      .page-contents{
        .point{
          text-align: center;
          figcaption{
            font-size: rem-calc(16);
            font-weight: bold;
            @include media-breakpoint-up(md) {
              font-size: rem-calc(20);
              font-weight: 900;
            }
          }
        }
        .graf__wrap{
          @include media-breakpoint-down(sm) {
            overflow: scroll;
          }
          .graf__img{
            @include media-breakpoint-down(sm) {
              width:130%;
              max-width:130%;
            }
          }
        }
        .bloquote{
          display: flex;
          align-items: flex-start;
          .bloquote__text{
            font-weight: bold;
            font-size: rem-calc(20);
          }
        }
        .tel__wrap{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          .number{
            font-weight: bold;
            font-size: rem-calc(37);
            line-height: 1.2;
            @include media-breakpoint-up(md) {
              font-size: rem-calc(71);
            }
            a{
              color:$black;
              text-decoration: underline;
            }
          }
          .tel-icon{
            @include media-breakpoint-down(sm) {
              width: 46px;
            }
          }
        }
        .time{
          font-size: rem-calc(15);
          @include media-breakpoint-up(md) {
            font-size: rem-calc(28);
          }
        }
        .hutankeigen-acc{
          li{
            border:none;
            margin-bottom: 2rem;
          }
          .accBtn{
            background-color: $gray;
            color:white;
            border:none;
            font-weight: bold;
            font-size: rem-calc(21);
            @include media-breakpoint-down(sm) {
              padding: 1rem 10px;
            }
            @include media-breakpoint-up(md) {
              font-size: rem-calc(25);
            }
            &:after{
              border-color:transparent white white transparent;
              width:12px;
              height: 12px;
              border-width:2px;
              right: 11px;
              @include media-breakpoint-up(md) {
                width:20px;
                height: 20px;
                right: 39px;
                border-width: 4px;
              }
            }
            &:before{
              content: '';
              position: absolute;
              margin: auto;
              display: block;
              background-color: #fff;
              transition:transform .2s;
              top: 0;
              right: 16px;
              bottom: 0;
              width: 2px;
              height:15px;
              @include media-breakpoint-up(md) {
                bottom: 5px;
                right: 47px;
                width:4px;
                height: 25px;
              }
            }
            &.is_active{
              &:before{
                transform: rotate(-180deg)translateY(-3px);
              }
            }
          }
          .accContent{
            padding:3rem 0;
          }
        }
      }
    }
    &.hs-teachers{
      $mintyo:'Noto Serif JP', serif;
      main{
        margin-bottom: 0;
      }
      .f-mintyo{
        font-family: $mintyo;
      }
      .section{
        padding-top: rem(80);
        padding-bottom: rem(80);
        @include media-breakpoint-up(md) {
          padding-bottom: rem(120);
        }
      }
      .page-header{
        margin-bottom: 7rem;
        @include media-breakpoint-up(md) {
          margin-bottom: 4rem;
        }
        .head__wrap{
          position: relative;
          h1{
            font-family: $mintyo;
            position: absolute;
            right: 0;
            left: 0;
            font-size: rem(35);
            font-weight: bold;
            margin: auto;
            color:white;
            z-index: 10;
            padding: 1rem 0.5rem;
            @include media-breakpoint-up(md) {
              bottom: 5%;
            }
            @include media-breakpoint-down(sm) {
              top:90%;
            }
            &:before{
              content: '';
              display: block;
              position: absolute;
              left: 50%;
              top: 0;
              background-color: rgba(#E61874,.6);
              width: 100vw;
              height:100%;
              margin-left: -50vw;
              z-index: -1;
            }
          }
        }
      }
      .teachers__intro{
        font-family: $mintyo;
        .teachers__intro__text{
          font-size: rem(28);
          @include media-breakpoint-up(md) {
            font-size: rem(32);
          }
        }
        .text__inner{
          line-height: lh(65,28);
          @include media-breakpoint-up(md) {
            line-height: lh(71,32);
            letter-spacing: ls(94);
          }
        }
        .text__aside{
          font-size: rem(16);
          @include media-breakpoint-up(md) {
            font-size: rem(20);
          }
        }
      }
      .section__qualification{
        background-color: #F5F1F1;
        position: relative;
        &:after{
          content: '';
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width:3rem;
          height:3rem;
          background-color: #F5F1F1;
          transform: rotate(45deg)translate(1rem,1rem);
        }
        .head__wrap{
          font-size: rem(32);
          font-weight: bold;
          margin-bottom: rem(50);
          @include media-breakpoint-up(md) {
            font-size: rem(40);
          }
        }
        .sectino__intro{
          font-size: rem(16);
          line-height: lh(49,23);
          margin-bottom: 3rem;
          @include media-breakpoint-up(md) {
            font-size: rem(23);
          }
        }
        .qualification__lists{
          .qualification__list{
            .qualification__list__inner{
              border-width:1px;
              border-style:solid;
              background-color: #fff;
              height: 100%;
              padding:1rem 5px;
              font-weight: 500;
            }
            &.qualification__list--01{
              .qualification__list__inner{
                color:#E9579A;
                border-color:currentColor;
              }
            }
            &.qualification__list--02{
              .qualification__list__inner{
                color:#9BC823;
                border-color:currentColor;
              }
            }
            &.qualification__list--03{
              .qualification__list__inner{
                color:#F29117;
                border-color:currentColor;
              }
            }
            &.qualification__list--04{
              .qualification__list__inner{
                color:#29BBEF;
                border-color:currentColor;
              }
            }
            &.qualification__list--05{
              .qualification__list__inner{
                color:#ED7DAE;
                border-color:currentColor;
              }
            }
            &.qualification__list--06{
              .qualification__list__inner{
                color:#5DBFAD;
                border-color:currentColor;
              }
            }
          }
          .qualification__name{
            font-size: rem(23);
            @include media-breakpoint-up(md) {
              font-size: rem(20);
            }
          }
          .qualification__body{
            font-size: rem(20);
            margin-bottom: 0;
            &.qualification__body--num{
              font-size: rem(35);
            }
          }
        }
      }
      .section__work{
        position: relative;
        &:after{
          content: '';
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width:3rem;
          height:3rem;
          background-color: white;
          transform: rotate(45deg)translate(1rem,1rem);
        }
        .head__wrap{
          font-size: rem(32);
          font-weight: bold;
          margin-bottom: rem(50);
          @include media-breakpoint-up(md) {
            font-size: rem(40);
          }
        }
        .sectino__intro{
          font-size: rem(16);
          line-height: lh(49,23);
          margin-bottom: 3rem;
          @include media-breakpoint-up(md) {
            font-size: rem(23);
            margin-bottom: 5rem;
          }
        }
        .work__head{
          font-size: rem(33);
          color:#F29117;
          margin-bottom: 0;
          @include media-breakpoint-up(md) {
            font-size: rem(49);
          }
        }
        .work__num{
          font-size: rem(120);
          line-height: 1;
          @include media-breakpoint-up(md) {
            font-size: rem(203);
          }
        }
        .work__num__per{
          font-size: rem(70);
          @include media-breakpoint-up(md) {
            font-size: rem(125);
          }
        }
        .work__aside{
          font-size: rem(21);
          color:#F29117;
          @include media-breakpoint-up(md) {
            font-size: rem(30);
          }
        }
      }
      .section__experience{
        background: repeating-linear-gradient(-45deg,#FCF6E1,#FCF6E1 7px,#FEEEB4 0,#FEEEB4 14px);
        .experience__inner{
          background-color: #fff;
          border-radius: 50px;
          padding:rem(30) rem(10) rem(60);
          margin-bottom: rem(70);
          @include media-breakpoint-up(md) {
            padding:rem(100) rem(50);
            margin-bottom: rem(100);
          }
          &.experience__inner--02{
            .experience__list{
            }
            .experience__head{
              color:white;
              text-align: center;
              font-weight: bold;
              font-size: rem(20);
              padding:rem(10) .5rem;
              @include media-breakpoint-up(md) {
                font-size: rem(29);
              }
              &.experience__head--01{
                background-color:#E9579A;
              }
              &.experience__head--02{
                background-color:#F29117;
              }
              &.experience__head--03{
                background-color:#29BBEF;
              }
              &.experience__head--04{
                background-color:#ED7DAE;
              }
              &.experience__head--05{
                background-color:#5DBFAD;
              }
            }
            .experience__description{
              font-size: rem(16);
              @include media-breakpoint-up(md) {
                font-size: rem(20);
              }
            }
          }
          &.experience__inner--04{
            // padding:rem(30) rem(10) rem(60);
            @include media-breakpoint-up(md) {
              padding:rem(60) rem(50);
            }
            .head{
              font-size: rem(24);
              @include media-breakpoint-up(md) {
                font-size: rem(26);
              }
            }
            .inquiry__block{
              padding:1.5rem;
              font-size: rem(16);
              background-color: #F5F5F5;
              @include media-breakpoint-up(md) {
                font-size: rem(21);
              }
            }
            .tel__block{
              a{
                background-color: #F5F5F5;
                color:#4B4B4B;
                font-size: rem(32);
                padding:1rem .5rem;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                @include opacity-href();
                @include media-breakpoint-up(md) {
                  padding:.5rem;
                  font-size: rem(37);
                }
              }
            }
          }
        }
        .head__wrap{
          margin-bottom: rem(25);
          text-align: center;
          font-size: rem(28);
          @include media-breakpoint-up(md) {
            font-size: rem(43);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: rem(54);
          }
        }
        .intro__wrap{
          font-size: rem(16);
          line-height: lh(31,16);
          margin-bottom: rem(50);
          @include media-breakpoint-up(md) {
            font-size: rem(23);
            line-height: lh(49,23);
            margin-bottom: rem(130);
          }
        }
        .head{
          position: relative;
          font-weight: 900;
          line-height: lh(44,28);
          font-size: rem(28);
            @include media-breakpoint-up(md) {
            font-size: rem(43);
            line-height: lh(82,43);
          }
          .head__item{
            display: block;
            margin: auto auto 1rem;
            @include media-breakpoint-up(md) {
              margin: auto;
              position: absolute;
              right: 105%;
              bottom: 0;
            }
            @include media-breakpoint-down(sm) {
              width: per(70,375);
            }
          }
        }
        .marker{
          background:linear-gradient(180deg,transparent 70%,#FFFF63 70%);
        }
        .experience__check{
          font-size: rem(17);
          font-weight: bold;
          @include media-breakpoint-up(md) {
            font-size: rem(23);
          }
        }
        .schedule{
          @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap:wrap;
          }
          .schedule__block{
            @include media-breakpoint-up(md) {
              width:50%;
              &:first-of-type{
                padding-right: 5%;
                border-right:1px solid #707070;
              }
              &:last-of-type{
                padding-left: 5%;
              }
            }
          }
          .schedule__head{
            font-weight: bold;
            font-size: rem(23);
            @include media-breakpoint-up(md) {
              font-size: rem(41);
            }
          }
          .schedule__cicle{
            .schedule__cicle__inner{
              font-weight: bold;
              font-size: rem(20);
              background-color: #F4CB35;
              border-radius: 50px;
              color:white;
              padding:.5rem 3rem;
              @include media-breakpoint-up(md) {
                font-size: rem(26);
              }
            }
          }
        }
      }
    }
    &.shakaijin{
      .section{
        padding-top: rem(80);
        padding-bottom: rem(80);
        @include media-breakpoint-up(md) {
          padding-top: rem(120);
          padding-bottom: rem(120);
        }
      }
      .for-all{
        @include media-breakpoint-up(md) {
          display: flex;
          flex-wrap:wrap;
        }
        .for-all__list{
          @include media-breakpoint-up(md) {
            width:calc((100% - 120px) / 7);
          }
          &:not(:last-of-type){
            margin-right: 20px;
          }
          @include media-breakpoint-up(md) {
            // display: inline-block;
          }
        }
      }
      .intro-strength{
        margin-bottom: rem(110);
        font-size: rem(17);
        @include media-breakpoint-up(md) {
          font-size: rem(28);
        }
      }
      .page-header{
        .page-head{
          margin-bottom: rem(80);
          @include media-breakpoint-up(md) {
            margin-bottom: rem(110);
          }
        }
      }
      .point-head{
        &.point-head--01{
          position: relative;
          line-height: 1;
          &:before{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 10px;
            width: 100%;
            display: block;
            background-color: #D4EBF5;
            z-index: -1;
          }
        }
      }
      .section__road{
        .road__body01{
          font-size: rem(23);
          line-height: lh(49,23);
        }
        .road__body02{
          font-size: rem(23);
          font-weight: bold;
          color:#0092E5;
        }
        .road__body03{
          font-size: rem(32);
          font-weight: bold;
          color:#0092E5;
          .road__body03__strong{
            color:#DE0063;
          }
          .road__body03__inner{
            .mini__block{
              display: inline-block;
              // border-bottom: 3px double #DE0063;
              position: relative;
              &:after{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height:5px;
                border-top: 1px solid #DE0063;
                border-bottom: 1px solid #DE0063;
              }
              // background:linear-gradient(180deg,transparent 70%,#FFFF63 70%);
            }
          }
        }
        .road__hutan{
          position: relative;
          padding-top: rem(60);
          padding-bottom: rem(60);
          &:before{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 100vw;
            margin-left: -50vw;
            background-color: #D4EBF5;
            z-index: -1;
          }
        }
      }
      .section__merit{
        .merit__intro{
          text-align: center;
          font-size: rem(23);
          margin-bottom: rem(50);
          line-height: lh(49,23);
          @include media-breakpoint-up(md) {
            margin-bottom: rem(100);
          }
        }
        .merit__head{
          &.merit__head--02{
            margin-top: rem(70);
            @include media-breakpoint-up(md) {
              margin-top: rem(140);
            }
          }
          &.merit__head--03{
            margin-top: rem(140);
          }
        }
        .marker{
          background:linear-gradient(180deg,transparent 70%,#F4CB35 70%);
        }
        .merit__num{
          font-size: rem(120);
          line-height: 1;
          font-weight: 900;
          color:#005C9B;
          @include media-breakpoint-up(md) {
            font-size: rem(236);
          }
        }
        .merit__num__per{
          font-size: rem(70);
          font-weight: 900;
          color:#005C9B;
          @include media-breakpoint-up(md) {
            font-size: rem(79);
          }
        }
        .merit__aside{
          // font-size: rem(21);
          // color:#F29117;
          margin-bottom: rem(140);
          @include media-breakpoint-up(md) {
            font-size: rem(18);
          }
        }
        .merit__shikaku{
          font-weight: bold;
          font-size: rem(32);
          color:#0092E5;
          display: inline-block;
          border-bottom: 7px double;
        }
        .acc--shikaku{
          .shikaku__list{
            border:none;
            .accBtn{
              border-width:1px;
              border-style:solid;
              font-size: rem(28);
              font-weight: bold;
              text-align: center;
              padding: 1rem 20px;
              &:after{
                border-width:1px;
                right: 15px;
              }
              &.is_active{
                color:white!important;
                &:after{
                  border-color:transparent #fff #fff transparent!important;
                }
              }
            }
            .accContent{
              color:white;
              font-weight: normal;
              .accContent__intro{
                margin-bottom: 2rem;
              }
              .accContent__head{
                font-size: rem(20);
                margin-bottom: 1rem;
                text-align: center;
              }
              .accContent__link{
                margin-bottom: 2rem;
                a{
                  font-size: rem(20);
                  text-align: center;
                  display: block;
                  background-color: white;
                  padding:.5rem 1.5rem .5rem 1rem;
                  border-radius: 50px;
                  @include opacity-href();
                  position: relative;
                  &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    right: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-width: 1px;
                    border-color:transparent currentColor currentColor transparent;
                    border-style:solid;
                    width:10px;
                    height:10px;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
            &.shikaku__list--01{
              .accContent__link{
                a{
                  color:#E9579A;
                }
              }
              .accContent{
                background-color: #E9579A;
              }
              .accBtn{
                color:#E9579A;
                &:after{
                  border-color:transparent #E9579A #E9579A transparent;
                }
                &.is_active{
                  background-color: #E9579A;
                  border-color:#E9579A;
                }
              }
            }
            &.shikaku__list--02{
              .accContent__link{
                a{
                  color:#9BC823;
                }
              }
              .accContent{
                background-color: #9BC823;
              }
              .accBtn{
                color:#9BC823;
                &:after{
                  border-color:transparent #9BC823 #9BC823 transparent;
                }
                &.is_active{
                  background-color: #9BC823;
                  border-color:#9BC823;
                }
              }
            }
            &.shikaku__list--03{
              .accContent__link{
                a{
                  color:#F29117;
                }
              }
              .accContent{
                background-color: #F29117;
              }
              .accBtn{
                color:#F29117;
                &:after{
                  border-color:transparent #F29117 #F29117 transparent;
                }
                &.is_active{
                  background-color: #F29117;
                  border-color:#F29117;
                }
              }
            }
            &.shikaku__list--04{
              .accContent__link{
                a{
                  color:#29BBEF;
                }
              }
              .accContent{
                background-color: #29BBEF;
              }
              .accBtn{
                color:#29BBEF;
                &:after{
                  border-color:transparent #29BBEF #29BBEF transparent;
                }
                &.is_active{
                  background-color: #29BBEF;
                  border-color:#29BBEF;
                }
              }
            }
            &.shikaku__list--05{
              .accContent__link{
                a{
                  color:#ED7DAE;
                }
              }
              .accContent{
                background-color: #ED7DAE;
              }
              .accBtn{
                color:#ED7DAE;
                &:after{
                  border-color:transparent #ED7DAE #ED7DAE transparent;
                }
                &.is_active{
                  background-color: #ED7DAE;
                  border-color:#ED7DAE;
                }
              }
            }
            &.shikaku__list--06{
              .accContent__link{
                a{
                  color:#5DBFAD;
                }
              }
              .accContent{
                background-color: #5DBFAD;
              }
              .accBtn{
                color:#5DBFAD;
                &:after{
                  border-color:transparent #5DBFAD #5DBFAD transparent;
                }
                &.is_active{
                  background-color: #5DBFAD;
                  border-color:#5DBFAD;
                }
              }
            }
          }
          .accBtn{
            // border:1px solid black;
          }
        }
      }
      .section__interview{
        background-color: #D4EBF5;
        .section__head{
          margin-bottom: rem(50);
          @include media-breakpoint-up(md) {
            margin-bottom: rem(90);
          }
        }
        .interview__list{
          margin-bottom: rem(100);
          @include media-breakpoint-up(md) {
            margin-bottom: rem(70);

          }
        }
        .interview__head{
          font-size: rem(24);
          font-weight: bold;
          color:#0092E5;
          margin-bottom: 1.5rem;
          @include media-breakpoint-up(md) {
            font-size: rem(30);
          }
        }
        .interview__name{
          font-size: rem(21);
          font-weight: bold;
          color:#0092E5;
          margin-bottom: 1rem;
        }
        .interview__job-change{
          margin-bottom: 1.5rem;
        }
        .interview__content{
          // font-size: rem(18);
          @include media-breakpoint-up(md) {
            font-size: rem(20);
          }
        }
      }
      .section__signal{
        .signal__inner{
          background-color: #FFFAC0;
          padding:rem(70) rem(10);
          border-radius: 45px;
        }
        .sub__head{
          color:#F29117;
          font-weight: 500;
          font-size: rem(23);
          @include media-breakpoint-up(md) {
            font-size: rem(32);
          }
        }
        .section__head{
          color:#F29117;
          font-weight: bold;
          font-size: rem(33);
          @include media-breakpoint-up(md) {
            font-size: rem(46);
          }
        }
        .section__intro{
          font-size: rem(20);
          line-height: lh(40,20);
        }
        .signal__study-list{
          font-weight: bold;
          font-size: rem(25);
        }
        .inquiry__item{
          margin-top: rem(70);
        }
        .icon-tel{
          position: absolute;
          left: 20px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        .txt{
          font-size: rem(20);
        }
        .inquiry__item__inner{
          position: relative;
          border:1px solid #1A1311;
          padding:4rem .5rem 3rem;
          height: 100%;
          &:before,
          &:after{
            content: '';
            display: block;
            position: absolute;
            width:rem(30);
            height:rem(30);
          }
          &:before{
            left: -3px;
            top: -3px;
            border-left:5px solid #1A1311;
            border-top:5px solid #1A1311;
          }
          &:after{
            right: -3px;
            top: -3px;
            border-right:5px solid #1A1311;
            border-top:5px solid #1A1311;
          }
        }
        .inquiry__item__inner-second{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          &:before,
          &:after{
            content: '';
            display: block;
            position: absolute;
            width:rem(30);
            height:rem(30);
          }
          &:before{
            left: -3px;
            bottom: -3px;
            border-left:5px solid #1A1311;
            border-bottom:5px solid #1A1311;
          }
          &:after{
            right: -3px;
            bottom: -3px;
            border-right:5px solid #1A1311;
            border-bottom:5px solid #1A1311;
          }
        }
        .inquiry__icon{
          position: absolute;
          top: 0;
          left: 50%;
          margin: auto;
          transform: translate(-50%,-50%);
          background-color: #FFFAC0;
          display: inline-block;
          padding: 0 2rem;
        }
        .inquiry__description{
          margin-bottom: 2rem;
          font-size: rem-calc(12);
          @include media-breakpoint-up(md) {
            font-size: rem-calc(16);
          }
        }
      }
    }
    &.facility{
      .p-article{
        &__img{
          @include media-breakpoint-up(md) {
            max-width:220px;
          }
          @include media-breakpoint-up(lg) {
            max-width:300px;
          }
          
        }
        &__head{
          font-size:rem(18);
          font-weight:bold;
          @include media-breakpoint-up(md) {
            margin-top:15px;
          }
        }
        &__item{
          margin-top:10px;
          &--m{
            @include media-breakpoint-up(md) {
              margin-top:40px;
            }

          }
        }
        &__description{
          font-size:rem(14);
        }
        &__thumb{
          text-align: center;
          margin-top:10px;
          @include media-breakpoint-up(md) {
            margin-top:0;
          }
        }
        &__body{
          margin-top:15px;
          @include media-breakpoint-up(md) {
            margin-top:0;
          }
        }
        
      }
    }
    &.htc.page-restaurant{
      h2{
        &.head-ch.restaurant{
          background: linear-gradient(90deg, #EFDA3A 0, #EFDA3A 15%, #e2e2e2 0) no-repeat;
          background-size: 100% 6px;
          background-position: bottom;
        }
      }
      .label {
        min-width: 160px;
        padding: 0.3em 1em;
        display: inline-block;
        border: 1px solid #FA7402;
        color: #FA7402;
        text-align: center;
      }
      .label.fill02 {
        background-color:#FA7402;
        color:#fff;
      }
      .label-large {
        font-size: 1.125rem;
        font-weight: bold;
      }
      .color-fill02{
        color:#FA7402;
      }
      .r-5{
        border-radius:5px;
      }
      .box-contact {
        margin-bottom: 50px;
        padding: 70px 30px;
        text-align: center;
        background-size: auto auto;
        background-color: white;
        background-image: -webkit-repeating-linear-gradient(135deg, transparent, transparent 10px, seashell 10px, seashell 20px);
        background-image: repeating-linear-gradient(-45deg, transparent, transparent 10px, seashell 10px, seashell 20px);
      }
      .page-header {
        position: relative;
        aspect-ratio: 1500/864;
        background-color: #ff7603;
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        -webkit-animation: restaurant-mv-pc 10s linear infinite normal forwards;
        animation: restaurant-mv-pc 10s linear infinite normal forwards;
        @media (min-aspect-ratio: 16/10) and (min-height: 780px){
          min-height: calc(100vh - 80px);
          aspect-ratio: initial;
          background-position: center;
          background-size: cover;
        }
        @include media-breakpoint-down(sm){
          background-size: 100%;
          
          aspect-ratio: 375/607;
          // tbackground-position: top 70px left;
          -webkit-animation: restaurant-mv-sp 10s linear infinite normal forwards;
          animation: restaurant-mv-sp 10s linear infinite normal forwards;
        }
      }
   @-webkit-keyframes restaurant-mv-pc {
    0% {
        background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
    }
    42% {
        background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
    }
    50% {
        background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
    }
    92% {
        background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
    }
    99.9%, to {
        background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
    }
  }
  @keyframes restaurant-mv-pc {
      0% {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
      }
      42% {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
      }
      50% {
          background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
      }
      92% {
          background-image: url("../img/pages/htc/bg-restaurant-mv02-pc.jpg");
      }
      99.9%, to {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-pc.jpg");
      }
  }
  @-webkit-keyframes restaurant-mv-sp {
      0% {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
      }
      42% {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
      }
      50% {
          background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
      }
      92% {
          background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
      }
      99.9%, to {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
      }
  }
  @keyframes restaurant-mv-sp {
      0% {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
      }
      42% {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
      }
      50% {
          background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
      }
      92% {
          background-image: url("../img/pages/htc/bg-restaurant-mv02-sp.jpg");
      }
      99.9%, to {
          background-image: url("../img/pages/htc/bg-restaurant-mv01-sp.jpg");
      }
  }
      .page-header .container {
        // min-height: calc(100vh - 80px);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(sm){
          align-items: flex-start;
          padding-top: 50px;
        }
      }
      // .page-header .container h1 {
        // margin: 0px;
        // padding: 0px;
        // background: transparent;
        // width: 100%;
        // max-width: 430px;
        // aspect-ratio: 497/511;
        // background: url('../img/pages/htc/title-restaurant-01.svg') center/cover no-repeat;
        // animation: restaurant-title-sp 10s linear infinite normal forwards;
        // @include media-breakpoint-down(sm){
        //   animation: restaurant-title-pc 10s linear infinite normal forwards;
        // }
        // @include media-breakpoint-down(md){
        //   max-width: 250px;
        // }
      // }
      .page-header .container h1{
        display: none;
      }
      // @keyframes restaurant-title-sp {
      //   0% {
      //       background-image: url("../img/pages/htc/title-restaurant-01.svg");
      //   }
      //   46% {
      //       background-image: url("../img/pages/htc/title-restaurant-01.svg");
      //   }
      //   50% {
      //       background-image: url("../img/pages/htc/title-restaurant-02.svg");
      //   }
      //   96% {
      //       background-image: url("../img/pages/htc/title-restaurant-02.svg");
      //   }
      //   99.9%, to {
      //       background-image: url("../img/pages/htc/title-restaurant-01.svg");
      //   }
      // }
      // @keyframes restaurant-title-pc {
      //   0% {
      //       background-image: url("../img/pages/htc/title-restaurant-01.svg");
      //   }
      //   46% {
      //       background-image: url("../img/pages/htc/title-restaurant-01.svg");
      //   }
      //   50% {
      //       background-image: url("../img/pages/htc/title-restaurant-02.svg");
      //   }
      //   96% {
      //       background-image: url("../img/pages/htc/title-restaurant-02.svg");
      //   }
      //   99.9%, to {
      //       background-image: url("../img/pages/htc/title-restaurant-01.svg");
      //   }
      // }
      .page-restaurant h4.restaurant__title{
        font-size: 20px;
        @include media-breakpoint-down(md){
          font-size: 18px;
        }
      }
      h4.restaurant__title::before{
        content: '';
        display: inline-block;
        width: 22px;
        height: 22px;
        border-radius: 1000px;
        border: 5px solid #FA7402;
        margin-right: 10px;
        transform: translateY(3px);
        @include media-breakpoint-down(md){
          width: 20px;
          height: 20px;
        }
    
      }
      .flow-restaurant-list {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        counter-reset: count-number;
    }
    .flow-restaurant-list--item {
        padding-bottom: 40px;
    }
    .flow-restaurant-list--item:before {
        counter-increment: count-number;
        content: counter(count-number);
        margin-left: auto;
        margin-bottom: 12px;
        display: block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: #FA7402;
        color: #fff;
        font-size: 1.625rem;
        font-weight: bold;
        text-align: center;
        margin-inline: auto;
      }
      .flow-restaurant--title {
        margin-bottom: 0.2em;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        @include media-breakpoint-down(sm){
          max-width: 445px;
          margin-inline: auto;
        }
      }
      .flow-restaurant--body {
        text-align: center;
        @include media-breakpoint-down(sm){
          max-width: 445px;
          margin-inline: auto;
          text-align: left;
        }
      }
      .flow-restaurant-box--wrapper{
        gap: 20px 30px;
      }
      .flow-restaurant-box--item{
        width: 100%;
        max-width: 445px;
      }
      .restaurant-three{
        @include media-breakpoint-down(sm){
          max-width: 445px;
          margin-inline: auto;
        }
      }
    }
  }
  &.hsc,
  &.htc,
  &.iryo{
    .hero{
      padding-top: 0 !important;
      position: relative;
          
        .hero-slider{
          width: 100%;
          background-color: #fff;
         
        }
        .hero-slider__item{
          width: 100%;
        
          
          img{
            width: 100%;
           
          }

        }    
    }
    .hero__elm{
      .pickup{
        margin-top: 0;
        @include media-breakpoint-down(md){
          margin-top: -19.4%;
          position: relative;
          z-index: 3;
        }
      }
    }
    .section--02{
      background: none;
      background-color: $white;
      @include media-breakpoint-down(sm){
        padding-bottom: 40px;
      }
    }
    .simple-link-nav {
      padding: 16px 0;
     

      @include media-breakpoint-up(md){
        padding: 42px 0 38px;
        margin-bottom: 70px;
      }
      &> .container{
        max-width: 1100px !important;
        @include media-breakpoint-down(md){
          padding-inline: 40px;
        }
      }
      ul{
        @include media-breakpoint-up(lg){
          display: flex;
          
          align-items: center;
          column-gap: 40px;
          flex-wrap: wrap;
        }
      }
      li{
        a{
          text-decoration: underline;
          color: #333;
          width: 100%;
          position: relative;
          white-space: nowrap;
          line-height: 2;
          @include media-breakpoint-up(md){
            padding-right: 20px;
          }
          &::after{
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #000;
            clip-path: path('M5,10l5-4.612-.163-.146L5.116,9.6V0H4.884V9.6L.163,5.243,0,5.388,5,10m0,1.36L-1.487,5.377.173,3.891l3.71,3.428V-1H6.116V7.319l3.71-3.428,1.66,1.486Z');
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            margin: auto 0;
            transform:rotate(-90deg);
            @include media-breakpoint-up(md){
              right: 0;
            }
          }
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    .banner-slider{
      &> .container{
        max-width: 1100px !important;
      }
    }
    .banner-slide{
      margin-top: 70px;
    }
    .news{
      background: #fff url(#{$dir}/pages/bg01.png);
      padding: 60px 0 80px;
      @include media-breakpoint-down(sm){
        padding: 30px 0 80px;
      }
    }
    .banner-slide__main{
      position: relative;
      padding: 0 34px;
     

      .slide-arrow{
        position: absolute;
        top: 0; bottom: 0;
        margin: auto 0;
        width: 34px;
        border: none;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0,0,0,.26);
        transition: box-shadow 0.3s linear, transform 0.3s ease;
        @media(max-width:767.98px){
          width: 26px;
        }
        &:focus{
          outline: none;
          box-shadow: 0 3px 12px rgba(0,0,0,.26);
          transform: scale(1.05);
        }
        span{
          width: 17px;
          height:34px;
          background-color: #000;
          clip-path: polygon(0 0, 100% 50%, 0% 100%);
          @media(max-width:767.98px){
            width: 11px;
            height:23px;
          }
        }

      }
      .prev-arrow{
        left: 0;
        span{
          transform: rotate(-180deg);
        }
      }
      .next-arrow{
        right: 0;
      }
    }
    .banner-slide__main__img{
      aspect-ratio: 396/150;
      transition: transform 0.3s ease;
      @media(min-width:768px){
        transform: scale(0.8);
      }
      &.slick-active{
        &.slick-current + .slick-active {
          @media(min-width:768px){
            transform: scale(1.0);
          }
        }
      }
      img{
        height: 100%;
        width: auto;
        object-fit: contain;
        margin-inline: auto;
      }
    }
    .p-movie {
      margin-top: 80px;
      @include media-breakpoint-down(md){
        margin-top: 0;
      }
      .movie-index{
        width: 100%;
        max-width: 860px;
        aspect-ratio: 16/9;
        margin-inline: auto;
        iframe{
          width: 100%;
          height: 100%;
        }
      }
    }
    .p-point__title{
      width: 40vw;
      max-width: 614px;
      margin-inline: auto;
      @include media-breakpoint-down(md){
        width: 72vw;
      }
    }
    .p-point__block{
      &:nth-of-type(1){
        width: 100%;
        max-width: 1210px;
        margin-inline: auto
      }
      &:nth-of-type(2){
        width: 100%;
        max-width: 680px;
        margin-inline: auto
      }
    }
    .p-point-lower.p-showY{
    
      transform:translate(10px, 30px);
      &.is_scrolled{
        transform:translate(10px, 0px);
      }
      
    }

    .p-accreditation{
      .item{
        align-self: flex-start;
        &:nth-of-type(2){
          margin-top: 190px;
          @include media-breakpoint-down(md){
            margin-top: 0;
          }
        }
        .item-inner{
          position: relative;
          z-index: 1;
          aspect-ratio: 1;
          @include media-breakpoint-down(md){
            
            max-width: 400px;
            margin-inline: auto;
          }
          &::before{
            content: '';
            display: inline-block;
            max-width: 220px;
            max-height: 220px;
            aspect-ratio: 1;
            background: url(#{$dir}/pages/bg_gekkei-wh.png) center/cover no-repeat;
            position: absolute;
            inset: 0;
            margin: auto;
            z-index: -1;
          }
        }
        .title{
          font-size: 30px;
          color: #089B99;
          line-height: calc(45 / 30);
          @include media-breakpoint-down(md){
            font-size: 28px;
          }
        }
        .text{
          font-size: 16px;
          @include media-breakpoint-down(md){
            font-size: 15px;
          }
        }
      }
    }
    .p-campus{
      // @include font-gradient();
      .p-campas__list{
        gap: 20px;
        @include media-breakpoint-down(md){
          gap: 10px;
        }
      }
      .order__elm{
        width: calc((100% / 4) - 15px );
        @include media-breakpoint-down(md){
          width: calc((100% / 2) - 5px );
        }

        &.crown{
          position: relative;
          &::after{
            content: '';
            display: inline-block;
            width: 74px;
            height: 74px;
            background: url(#{$dir}/pages/icon-crown.png) center/cover no-repeat;
            position: absolute;
            top: 13px;
            right: 9px;
            @include media-breakpoint-down(md){
              width: 45px;
              height: 45px;
              top: 5px;
              right: 5px;
            }
          }
        }

        
      }
    }
    .visitors-list{
      display: flex;
      @include media-breakpoint-down(md){
        box-shadow: 6px 6px 0 black;
        flex-wrap: wrap;
        border: 1px solid black;
      }
      li{
        width: calc(100% / 6);
        position: relative;
        z-index: 1;
        transition: transform 0.3s ease;
        @include media-breakpoint-down(md){
          width: 100%;
        }
        &::before{
          @include media-breakpoint-up(lg){
            content: '';
            display: inline-block;
            background-color: black;
            position: absolute;
            top: 6px;
            left: 6px;
            right: -6px;
            bottom: -6px;
            z-index: -1;
            transition: bottom 0.2s ease,right 0.2s ease;
          }
        }
        &:hover{
          @include media-breakpoint-up(lg){
            transform: translate(6px, 6px);
          }
          &::before{
            @include media-breakpoint-up(lg){
              bottom: 0;
              right: 0;
              left: 0;
        
            }
          }
        }
        &:not(:last-of-type){
          a{
            @include media-breakpoint-up(lg){
              border-right: none;
            }
          }
        }
       
        a{
          display: block;
          height: 100%;
          padding: 15px 15px 33px;
          color: #333333;
          // font-size: 20px;
          font-size: unquote('min(1.3vw, 20px)'); 
          position: relative;
          line-height: 1.2;
          border: 1px solid black;
          background-color: $white;
          z-index: 1;
          @include media-breakpoint-down(md){
            font-size: 17px;
            padding: 13px 21px 13px;
            border: none;
            border-bottom: 1px solid black;
          }
         
          
          &::after{
            content: '';
            display: inline-block;
            width: 16px;
            height: 18px;
            background-color: #333;
            clip-path: path('M8.418,19.307a.5.5,0,0,1-.359-.152L-.359,10.482a.5.5,0,0,1,.005-.7l.274-.274a.5.5,0,0,1,.713.006l7.089,7.316V0a.5.5,0,0,1,.5-.5h.392a.5.5,0,0,1,.5.5V16.829L16.2,9.512a.5.5,0,0,1,.713-.006l.274.274a.5.5,0,0,1,.005.7L8.777,19.155A.5.5,0,0,1,8.418,19.307Z');
            position: absolute;
            bottom: 12px;
            right: 12px;
            transform: rotate(-90deg);
            transition: transform 0.3s ease;
            @include media-breakpoint-down(md){
              top: 0; bottom: 0;
              margin: auto 0;
            }
          }
          &:hover{
            text-decoration: none;
            &::after{
              @include media-breakpoint-up(lg){
                transform: translateX(5px) rotate(-90deg);
              }
            }
          }
          .small{
            // font-size: 14px;
            font-size: unquote('min(0.85vw, 14px)'); 
            @include media-breakpoint-down(md){
              font-size: 13px
            }

          }
          
        }
      }
    }
    .p-opencampus{
      width: 100%;
      max-width: 1000px;
      margin-inline: auto;
    }
    .p-opencampus-img{
      &:nth-of-type(2){
        // max-width: 837px;
        @include media-breakpoint-down(md){
          max-width: 55vw;
        }
      }
    }
    .p-opencampus-btn{
      width: 92%;
      max-width: 1000px;
      display: flex;
      gap: 10px 20px;
      @include media-breakpoint-down(md){
        flex-direction: column;
      }
      
    }
    .p-instagram{
      background-color: #fff;
      // color:white;
      .f-century{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      
        &::before{
          content: '';
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url(#{$dir}/common/icon_insta.png) center/cover no-repeat;
          margin-right: 12px;
          opacity: 0;
          transition: opacity 0.3s linear 1.5s;
        }
      }
      .is_scrolled{
        .f-century::before{
          opacity: 1;
        }
      }
    }
    .p-now-future{
      width: 100%;
      max-width:900px;
      margin-inline:auto;
    }
  }
  &.hsc{
    $school-color:$c-hair-second;
    &.school-home{
      .head__wrap{
        &:not(.other-color){
          color:$school-color;
        }
      }
      
      // .section--01{
      //   background-color: $white;
      // }
      .hero {
        padding-top: 0;
        background-color: #FAE5EE;
        // .hero-slider{
        //   width: 100%;
        //   background-color: #fff;

        // }
        // .hero-slider__item{
        //   width: 100%;
        //   img{
        //     width: 100%;
        //   }

        // }
        // background-color: $school-color;
        // background: url(#{$dir}/pages/hsc/bg-hero.png) top center/100% no-repeat;
        // @media (min-aspect-ratio: 16/10) {
        //   min-height: calc(100vh - 80px);
        // }
        // @include media-breakpoint-down(md){
        //   background: url(#{$dir}/pages/hsc/bg-hero_sp.png) top center/100% no-repeat;
        //   padding-top: 140%;
        // }
        // @include media-breakpoint-down(sm){
          
        // }
        // .hero-img{
        //   z-index: 2;
        //   @include media-breakpoint-up(lg) {
        //     max-width:52vw;
        //   }
        //   @include media-breakpoint-down(md){
        //     max-width:84%;
        //     bottom: -10%;
        //   }
        // }
        // .hero-text-cat{
        //   width: 11vw;
        //   position: absolute;
        //   top: 6%;
        //   left: 6vw;
        //   @include media-breakpoint-down(md){
        //     width: 30vw;
        //     left: 4vw;
        //     top: 3%;
        //   }
        // }
        // .hero-text{
        //   width: 32vw;
        //   top: 15%;
        //   right: auto;
        //   left: 6vw;
        //   @include media-breakpoint-down(md){
        //     display: block;
        //     width: 88vw;
        //     left: 4vw;
        //     top: 11%;
        //   }
        // }
        // .hero-text-lower{
        //   width: 51vw;
        //   position: absolute;
        //   left: 6vw;
        //   top: 70%;
        //   @include media-breakpoint-down(md){
        //     width: 94vw;
        //     top: 84%;
        //     left: 4vw;
        //   }
        //   &::after{
        //     background-color: #E2166F;
        //   }
        //   img{
        //     width: 100%;
        //   }
        // }
        // .hero-item{
        //   transform: scale(1) !important;
        //   opacity: 1 !important;
        // }
      //   .hero-item--01{
      //     left: 24%;
      //     top: 2%;
      //     background: url(#{$dir}/pages/hsc/img_hero-item01.png)no-repeat;
      //     background-size: 100% !important;
      //     width: 16vw;
      //     aspect-ratio: 249/147;
      //     min-height: calc(16vw * 0.6);
      //     top: 20%;
      //     right: 18vw;
      //     left: auto;
      //     animation: none !important;
      //     z-index: 0;
      //     @include media-breakpoint-down(md) {
      //       background: url(#{$dir}/pages/hsc/img_hero-item01_sp.png) center/100% no-repeat;
      //       width:13vw;
      //       aspect-ratio: 52/135;
      //       min-height: calc(13vw * 2.6);
      //       top: -9%;
      //       right: 9vw;
      //     }
      //     @include media-breakpoint-down(sm) {
            
      //     }
      //   }
      //   .hero-item--02{
      //     top: 60%;
      //     right: 6vw;
      //     background: url(#{$dir}/pages/hsc/img_hero-item02.png)no-repeat;
      //     background-size: 100% !important;
      //     width: 18vw;
      //     aspect-ratio: 312/318;
      //     min-height: calc(18vw * 1.01);
      //     animation: none !important;
      //     z-index: 0;
      //     @include media-breakpoint-down(md) {
      //       background: url(#{$dir}/pages/hsc/img_hero-item02_sp.png)no-repeat;
      //       width: 100%;
      //       top: 52%;
      //       right: 0;

      //     }
      //   }
    }
    .simple-link-nav {
      background-color: #FAE5EE;
    }
      
     
      
      .section--03,.section--05{
        background: none;
        background-color: #FAE5EE;
       
      }
      
     
      

      .p-open-img{
        &:after,
        &:before,
        .line{
          background-color: $school-color;
        }
      }
    }
    .p-target-menu{
      background-color: $school-color;
      a{
        &:hover{
          color:$school-color;
          &:after{
            background-color: $school-color;
          }
          &:before{
            border-color:$school-color;
          }
        }
      }
    }
    
    .p-access{
      background: #fff url(#{$dir}/pages/hsc/bg_circle01.png)no-repeat;
      background-size: 1105px 1077px;
      background-position: top center;
      .head__wrap{
        color:$school-color;
      }
      @include font-gradient();
    }
    .section--04{
      background-color: #fff;
    }
    .section--05{
      .container{
        max-width: 1000px !important;
      }
    }
    .section--06{
      background-color:white;
      // color:white;
      overflow: hidden;
      .head__wrap{
        // color:white;
      }
      .inner{
        position: relative;
        font-weight: bold;
        @include media-breakpoint-up(md) {
          display: flex;
        }
        h3{
          background: #333;
          font-weight: bold;
          margin-bottom: 1.5rem;
          opacity:0;
          @include media-breakpoint-up(md) {
            padding:.5rem .5rem .5rem 5rem;
            transform: translateX(-110px);
            width: calc(100% + 80px);
          }
          @include media-breakpoint-down(sm) {
            text-align: center;
            font-size: rem-calc(25);
            padding:.5rem;
            transform: translateX(-30px);
          }
          &.is_scrolled{
            transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
            opacity:1;
            @include media-breakpoint-up(md) {
              transform: translateX(-80px);
            }
            @include media-breakpoint-down(sm) {
              transform: translateX(0);
            }
          }
        }
      }
      .elm__block{
        &.elm__block--img{
          width: 30vw;
          min-width: 450px;
          @include media-breakpoint-down(sm) {
            width:100%;
            min-width: auto;
          }
        }
      }
      .second__content{
        max-width:965px;
        margin: auto;
        li{
          margin-bottom: rem-calc(20);
        }
      }
      .second__head{
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem-calc(20);
        @include media-breakpoint-up(md) {
          &:before,
          &:after{
            content: "";
            height: 1px;
            flex-grow: 1;
            background-color: #fff;
          }
          &:before{
            margin-right: 1rem;
          }
          &:after{
            margin-left: 1rem;
          }
        }
      }
      .bg__item{
        position: absolute;
        &.bg__item--01{
          width: 17vw;
          max-width: 269px;
          top: 50%;
          left: 2%;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        &.bg__item--02{
          width: 14vw;
          max-width: 217px;
          top: 45%;
          right: 5%;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }
    .section--07{
      background: repeating-linear-gradient(-45deg,#FEF7FA,#FEF7FA 13px,#fff 0,#fff 26px);
    }
    .section--08{
      background: #fff url(#{$dir}/pages/bg01.png);
    }
    
    .section--09{
      background-color: #fff;
      .title{
        background:rgba($school-color,.9);
        // background: linear-gradient(45deg, rgba(#82e8f5,.9) 0%,rgba(#ed87de,.9) 100%);
      }
    }
    .section--10,.section--11{
      background-color: #fff;
      
    }
    .visitors-list{
      a{
        .color-pink{
          color: #E85298;
        }
      }
    }
    
   
  }
  &.htc{
    $school-color:$c-cook-second;
    &.school-home{
      .head__wrap{
        &:not(.other-color){
          color:$school-color;
        }
      }

      .hero {
        background-color: #FFF1DB;
      }
      .simple-link-nav {
        background-color: #FFF1DB;
      }
      .p-open-img{
        &:after,
        &:before,
        .line{
          background-color: $school-color;
        }
      }
    }
    .color-orange{
      color: #FA7402;
    }
    .section--03,.section--05{
      background: none;
      background-color: #FFF1DB;
     
    }
    .p-target-menu{
      background-color: $school-color;
      a{
        &:hover{
          color:$school-color;
          &:after{
            background-color: $school-color;
          }
          &:before{
            border-color:$school-color;
          }
        }
      }
    }
    .p-instagram{
      // background-color: $school-color;
      // color:white;
      .head__wrap{
        color: #FA7402;
      }
    }
    .p-access{
      background: #fff url(#{$dir}/pages/htc/bg_circle01.png)no-repeat;
      background-size: 1105px 1077px;
      background-position: top center;
      .head__wrap{
        color:$school-color;
      }
      @include font-gradient($start-color:#00BAB4,$end-color:#92D514);
    }
    .section--04{
      background-color: #fff;
    }
    .section--05{
      &> .container{
        max-width: 1000px !important;
      }
    }
    .section--06{
      background-color: $white;
      color: #333;
      overflow: hidden;
      .head__wrap{
        color: #FA7402;
      }
      .course__title{
        font-size: 40px;
        width: fit-content;
        margin-inline: auto;
        border-bottom: 6px solid #333;
        margin-bottom: 35px;
        font-weight: 700;
        @include media-breakpoint-down(sm){
          font-size: 30px;
          margin-bottom: 20px;
        }
        &.license{
          font-size: 45px;
          @include media-breakpoint-down(sm){
            font-size: 26px;
          }
          span{
            font-size: 35px;
            @include media-breakpoint-down(sm){
              font-size: 21px;
            }
          }
        }
        
      }
      .course__text{
        font-size: 28px;
        @include media-breakpoint-down(sm){
          font-size: 16px;
        }
      }
      .elm{
        max-width: 930px;
        margin-inline: auto;
      }
      .elm__card-wrap{
        gap: 30px;
      }
      .elm__card-item{
        width: calc(50% - 15px);

        @include media-breakpoint-down(sm){
          width: 100%;
        }
        img{
          width: 100%;
          filter:drop-shadow(0 3px 10px rgba(0,0,0,.25));
        }
      }
      .color-wrap{
        @include media-breakpoint-up(lg){
          background-color:#FFF1DB;
          padding: 70px 0 60px;
        }
      }
      .course__lower{
        padding-top: 50px ;
        @include media-breakpoint-up(lg){
         
          padding-top: 70px;
        }
        
        
      }
      // .course__intro{
      //   position: relative;
      //   margin-bottom: 1.5rem;
      //   @include media-breakpoint-up(lg) {
      //     display: flex;
      //     align-items: center;
      //     margin-bottom: 3rem;
      //   }
      //   .course__intro-head{
      //     font-size: rem-calc(30);
      //     font-weight: bold;
      //     background-color: $gray;
      //     padding: 0.5rem 1.5rem;
      //     letter-spacing:ls(200);
      //     word-break: keep-all;
      //     display: inline-block;
      //     margin-bottom: 1rem;
      //     @include media-breakpoint-up(lg) {
      //       font-size: rem-calc(50); 
      //       display: block;
      //     }
      //   }
      //   .course__intro-content{
      //     font-size: rem-calc(16);
      //     font-weight: bold;
      //     color:$gray;
      //     @include media-breakpoint-up(lg) {
      //       font-size: rem-calc(30); 
      //     }
      //   }
      // }
      // .course__lists{}
      // .course__item{
      //   margin-bottom: 1rem;
      // }
      .course__item__link{
        background-color: $gray;
        display: flex;
        align-items: center;
        color:$gray;
        @include opacity-href();
        position: relative;
        margin-bottom: 0.5rem;
        padding:.5rem 1.8rem .5rem .5rem;
        @include media-breakpoint-up(lg) {
          padding: .5rem;
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 14px;
          bottom: 0;
          margin: auto;
          transform: rotate(-45deg);
          width: 10px;
          height: 10px;
          border-bottom: 2px solid white;
          border-right: 2px solid white;
          @include media-breakpoint-up(lg) {
            right: 20px;
            width: 20px;
            height: 20px;
            border-bottom: 5px solid white;
            border-right: 5px solid white;
          }
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          right: 13px;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: white;
          width: 15px;
          height: 2px;
          @include media-breakpoint-up(lg) {
            right: 19px;
            width: 25px;
            height: 5px;
          }
        }
      }
      // .course__item__year{
      //   background-color: #fff;
      //   font-size: rem-calc(16);
      //   border-radius: 5px;
      //   padding:.2rem 1rem;
      //   margin-right: 1rem;
      //   @include media-breakpoint-up(md) {
      //     font-size: rem-calc(25);
      //   }
      // }
      // .course__item__subject{
      //   margin-bottom: 0; 
      //   font-weight: bold;
      //   font-size: rem-calc(21);
      //   color:white;
      //   @include media-breakpoint-up(md) {
      //     letter-spacing: ls(200);
      //     font-size: rem-calc(30);
      //   }
      // }
      // .course__item__description{
      //   color:white;
      //   font-size: rem-calc(18);
      //   color:$gray;
      //   padding-left: 6.1rem;
      //   @include media-breakpoint-up(md) {
      //     padding-left: 7.6rem;
      //     font-size: rem-calc(24);
      //   }
      // }
      // .inner{
      //   position: relative;
      //   font-weight: bold;
      //   @include media-breakpoint-up(lg) {
      //     display: flex;
      //   }
      //   h3{
      //     background: #333;
      //     font-weight: bold;
      //     margin-bottom: 1.5rem;
      //     @include media-breakpoint-down(md) {
      //       text-align: center;
      //       font-size: rem-calc(25);
      //       padding:.5rem;
      //       transform: translateX(-30px);
      //     }
      //     // &.is_scrolled{
      //     //   transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
      //     //   opacity:1;
      //     //   @include media-breakpoint-up(md) {
      //     //     transform: translateX(-80px);
      //     //   }
      //     //   @include media-breakpoint-down(sm) {
      //     //     transform: translateX(0);
      //     //   }
      //     // }
      //   }
      // }
      // .elm__block{
      //   &.elm__block--img{
      //     @include media-breakpoint-up(lg) {
      //       width:47%;
      //     }
      //   }
      //   &.elm__block--content{
      //     @include media-breakpoint-up(lg) {
      //       width:50%;
      //       margin-left: 3%;
      //     }
      //   }
      // }
      // .second__content{
      //   max-width:965px;
      //   margin: auto;
      //   li{
      //     margin-bottom: rem-calc(20);
      //   }
      // }
      // .second__head{
      //   font-weight: bold;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   font-size: rem-calc(20);
      //   @include media-breakpoint-up(md) {
      //     &:before,
      //     &:after{
      //       content: "";
      //       height: 1px;
      //       flex-grow: 1;
      //       background-color: #fff;
      //     }
      //     &:before{
      //       margin-right: 1rem;
      //     }
      //     &:after{
      //       margin-left: 1rem;
      //     }
      //   }
      // }
      // .bg__item{
      //   position: absolute;
        // &.bg__item--01{
        //   top: -5%;
        //   left: -10%;
        //   @include media-breakpoint-down(md) {
        //     display: none;
        //   }
        // }
        // &.bg__item--02{
        //   top: 0%;
        //   right: -8%;
        //   @include media-breakpoint-down(md) {
        //     top: 6%;
        //     right: -7%;
        //     width: 40%;
        //   }
        // }
        // &.bg__item--03{
        //   bottom: -20%;
        //   right: -12%;
        //   @include media-breakpoint-down(md) {
        //     display: none;
        //   }
        // }
        // &.bg__item--04{
        //   bottom: 0;
        //   right: -17%;
        //   @include media-breakpoint-down(md) {
        //     display: none;
        //   }
        // }
        // &.bg__item--04{
        //   bottom: -7%;
        //   right: 0;
        //   @include media-breakpoint-down(sm) {
        //     bottom: 0%;
        //     right: 0;
        //     width: 40%;
        //   }
        // }
      // }
    // }
    }
    .p-accreditation{
      .item{
        .title{
          color: #FA7402;
        }

      }
    }
    .section--07{
      background: repeating-linear-gradient(-45deg,#FEF6EE,#FEF6EE 13px,#fff 0,#fff 26px);
    }
    .p-campus{
      @include font-gradient($sp-font:20,$start-color:#00BAB4,$end-color:#92D514);
      .img__block{
        @include media-breakpoint-up(lg) {
          margin-top: -3rem;
        }
      }
    }
    .section--08{
      background: #fff url(#{$dir}/pages/bg01.png);
      .title{
        background:rgba($school-color,.9);
        // background: linear-gradient(45deg, rgba(#00BAB4,.9) 0%,rgba(#92D514,.9) 100%);
      }
    }
    .section--09,.section--10,.section--11{
      background-color: $white;
    }
  }
  &.iryo{
    $school-color:$c-hukusi-seocond;
    &.school-home{
      .head__wrap{
        &:not(.other-color){
          color:$c-hukusi;
        }
      }
     
      .hero {
        background-color: #E6F8DA;
        // .hero-img{
        //   width:per(543,1500);
        //   @include media-breakpoint-down(md) {
        //     width: 64%;
        //     z-index: 10;
        //   }
        //   @include media-breakpoint-down(sm) {
        //     width: 85%;
        //   }
        // }
        // .hero-item--01{
        //   left: 26%;
        //   top: 14%;
        //   background: url(#{$dir}/pages/iryo/img_hero-item01.png)no-repeat;
        //   background-size: 200%;
        //   width: per(207,1500);
        //   height: per(492,760);
        //   @include media-breakpoint-down(md) {
        //     background: url(#{$dir}/pages/iryo/img_hero-item01_sp.png)no-repeat;
        //     background-size: 200%;
        //     width: per(117,375);
        //     height: 68%;
        //     top: 15%;
        //     left: 4%;
        //   }
        //   @include media-breakpoint-down(sm) {
        //     height: per(252,465);
        //     // height: 30.7%;
        //   }
        // }
        // .hero-item--02{
        //   top: 7%;
        //   right: 26%;
        //   background: url(#{$dir}/pages/iryo/img_hero-item02.png)no-repeat;
        //   background-size: 200%;
        //   width: per(192,1500);
        //   height: per(607,760);
        //   @include media-breakpoint-down(md) {
        //     width: per(115,375);
        //     height: 98%;
        //     top: 3%;
        //     right: 0%;
        //     z-index: 0;
        //   }
        //   @include media-breakpoint-down(sm) {
        //     height: per(385,465);
        //     // height: 58.4%;
        //   }
        // }
        // .hero-school__text{
        //   position: absolute;
        //   left: 25%;
        //   top: 3%;
        //   z-index: 10;
        //   width: 22%;
        //   @include media-breakpoint-down(md) {
        //     width: per(178,375);
        //     left:3%;
        //     img{
        //       width: 100%;
        //     }
        //   }
        // }
      }
      .simple-link-nav {
        background-color: #E6F8DA;
          }
      .section--03,.section--05{
        background: none;
        background-color: #E6F8DA;
       
    }
    .section--05{
      &> .container{
        max-width: 1000px !important;
      }
    }
    .section--06{
        background-color: $white;
      overflow: hidden;
        .head__wrap{
          margin-bottom: 2.5rem;
          color: #43AA88;
          h2{
            &::after{
              background-color: #43AA88;
            }
      }
      }
      .course__item{
        margin-bottom: 1rem;
      }
      
       
   
      .inner{
        position: relative;
        font-weight: bold;
        @include media-breakpoint-up(lg) {
          display: flex;
        }
        // h3{
        //   background: #333;
        //   font-weight: bold;
        //   margin-bottom: 1.5rem;
        //   opacity:0;
        //   @include media-breakpoint-up(md) {
        //     padding:.5rem .5rem .5rem 5rem;
        //     transform: translateX(-110px);
        //     width: calc(100% + 80px);
        //   }
        //   @include media-breakpoint-down(sm) {
        //     text-align: center;
        //     font-size: rem-calc(25);
        //     padding:.5rem;
        //     transform: translateX(-30px);
        //   }
        //   &.is_scrolled{
        //     transition:2.5s cubic-bezier(0.08, 0.27, 0.17, 1);
        //     opacity:1;
        //     @include media-breakpoint-up(md) {
        //       transform: translateX(-80px);
        //     }
        //     @include media-breakpoint-down(sm) {
        //       transform: translateX(0);
        //     }
        //   }
        // }
      }
       
      // .second__content{
      //   max-width:965px;
      //   margin: auto;
      //   li{
      //     margin-bottom: rem-calc(20);
      //   }
      // }
      // .second__head{
      //   font-weight: bold;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   font-size: rem-calc(20);
      //   @include media-breakpoint-up(md) {
      //     &:before,
      //     &:after{
      //       content: "";
      //       height: 1px;
      //       flex-grow: 1;
      //       background-color: #fff;
      //     }
      //     &:before{
      //       margin-right: 1rem;
      //     }
      //     &:after{
      //       margin-left: 1rem;
      //     }
      //   }
      // }
        
      }
     
      .elm{
        max-width: 1000px;
        margin-inline: auto;
      }
      .elm__card-item{
        width: calc(50% - 30px);

        @include media-breakpoint-down(sm){
          width: 100%;
        }
        img{
          width: 100%;
          filter:drop-shadow(0 3px 10px rgba(0,0,0,.25));
          }
        }
      .elm__card-wrap{
        gap: 40px 60px;
      }
     
      .p-open-img{
        &:after,
        &:before,
        .line{
          background-color: $school-color;
        }
      }
    }
    .p-target-menu{
      background-color: $school-color;
      a{
        &:hover{
          color:$school-color;
          &:after{
            background-color: $school-color;
          }
          &:before{
            border-color:$school-color;
          }
        }
      }
    }
    .p-instagram{
      background-color: $school-color;
      color:white;
    }
    .p-access{
      background: #fff url(#{$dir}/pages/iryo/bg_circle01.png)no-repeat;
      background-size: 1105px 1077px;
      background-position: top center;
      .head__wrap{
        color:$school-color;
      }
      @include font-gradient($start-color:#FFA3A7,$end-color:#62B7FF,$center-color:#BBACFA);
          }
    .section--04{
      background-color: #fff;
        }
   
    .section--07{
      background: repeating-linear-gradient(-45deg,#EDF7F8,#EDF7F8 13px,#fff 0,#fff 26px);
    }
    .p-campus{
      @include font-gradient($sp-font:20,$start-color:#FFA3A7,$end-color:#62B7FF,$center-color:#BBACFA);
      .img__block{
        @include media-breakpoint-up(lg) {
          margin-top: -3rem;
        }
      }
    }
    .section--08{
      background: #fff url(#{$dir}/pages/bg01.png);
      .title{
        background:rgba($school-color,.9);
        // background: linear-gradient(45deg, rgba(#FFA3A7,.9) 0%,rgba(#62B7FF,.9) 100%);
      }
    }
    .section--09,.section--10,.section--11,.section--12{
      background-color: $white;
    }
    .visitors-list{
      a{
        .color-pink{
          color: #43AA88;
        }
      }
    }
    .section--12{
      color: #333;
      .head__wrap{
        color: #43AA88;
      }
    }
  }

  &.common{
    main{
      margin-bottom: 10rem;
    }
    &.search{
      .head__wrap{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: rem(50);
        @include media-breakpoint-up(md) {
          margin-bottom: rem(70);
        }
      }
      .section__head{
        font-weight: bold;
        font-size: rem-calc(25);
        position: relative;
        &:before,
        &:after{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 230px;
          height: 7px;
        }
        &:before{
          left:calc(100% + 35px);
          background: url(#{$dir}/common/img_page_search_item08.svg)no-repeat;
          background-size: cover;
        }
        &:after{
          right:calc(100% + 35px);
          background: url(#{$dir}/common/img_page_search_item08.svg)no-repeat;
          background-size: cover;
        }
      }
      .job-lists__item{
        margin-bottom: 2.5rem;
      }
      .job-lists{
        margin-bottom: 3rem;
      }
      .section{
        padding:6rem 0;
        .p-btn{
          a{
            font-size: rem-calc(10);
            .txt{
              color:$black!important;
              min-width: 160px;
            }
          }
        }
        .job__thumb{
          width: 100%;
        }
        .job__name{
          font-size: rem-calc(18);
          font-weight: bold;
        }
        .job__description{
          font-size: rem-calc(14);
        }
        &.section__biyo{
          background-color: #FEF4F9;
          .section__head,
          .job__name{
            color:#F172AC;
          }
          .job__thumb{
            border-bottom: 2px solid #F172AC;
          }
        }
        &.section__food{
          background-color: #FFF4EB;
          .section__head,
          .job__name{
            color:#FF7603;
          }
          .job__thumb{
            border-bottom: 2px solid #FF7603;
          }
        }
        &.section__fukushi{
          background-color: #EDF7FB;
          .section__head,
          .job__name{
            color:#1A9CCC;
          }
          .job__thumb{
            border-bottom: 2px solid #1A9CCC;
          }
        }
        &.section__hoiku{
          background-color: #FDF6F6;
          .section__head,
          .job__name{
            color:#E48E8F;
          }
          .job__thumb{
            border-bottom: 2px solid #E48E8F;
          }
        }
        &.section__iryo{
          background-color: #F0F8F6;
          .section__head,
          .job__name{
            color:#43AA88;
          }
          .job__thumb{
            border-bottom: 2px solid #43AA88;
          }
        }
      }
    }
    &.entrance{
      .section{
        padding-top: 5rem;
        padding-bottom: 5rem;
        position: relative;
      }
      .small__head{
        position: relative;
        text-align: center;
        font-size: rem-calc(22);
        font-weight: bold;
        margin-bottom: rem(45);
        @include media-breakpoint-up(md) {
          font-size: rem-calc(25);
        }
        &:before{
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          height: 15px;
          width: 100%;
          display: block;
          background-color: #BCD6EF;
          z-index: -1;
        }
      }
      .point{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        transform: translateY(-50%);
      }
      .section__employment{
        position: relative;
        &:before{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100vw;
          background: url(#{$dir}/common/img_page_entrance_bg01.jpg)no-repeat;
          // background-size: 100%;
          height:100%;
          z-index: -1;
          @include media-breakpoint-up(md) {
            height:72%;
            background: url(#{$dir}/common/img_page_entrance_bg01.jpg)no-repeat;
            background-size: cover;
          }
        }
        .employment__wrap{
          color:white;


          .link-item{
            img{
              box-shadow: 0 3px 16px rgba(0,0,0,.26);
            }
          }
        }
        .achievement{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .achievement__head{
            position: relative;
            padding:0 1rem;
            font-size: rem-calc(30);
            &:after,
            &:before{
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width:3px;
              height:1.2em;
              background: white;
            }
            &:before{
              left: 0;
              transform: skew(25deg);
            }
            &:after{
              right: 0;
              transform: skew(-25deg);
            }
          }
          .achievement__content{
            font-size: rem-calc(109);
            line-height: 1;
            font-weight: bold;
            @include media-breakpoint-up(md) {
              font-size: rem-calc(139);
            }
          }
          .achievement__content__per{
            font-size: rem-calc(71);
            @include media-breakpoint-up(md) {
              font-size: rem-calc(91);
            }
          }
        }
      }
      .section__dormitory{
        background: repeating-linear-gradient(-45deg,#def2fb,#def2fb 7px,#eef8fd 0,#eef8fd 14px);
        padding-bottom: 8rem;
      }
      .section__inquiry{
        position: relative;
        padding-top: 9rem;
        padding-bottom: 9rem;
        &:before,
        &:after{
          content: '';
          position: absolute;
          left: 0;
          display: block;
          width: 100vw;
          height: 35px;
          background: repeating-linear-gradient(55deg,#C5E6CC,#C5E6CC 10px,#fff 0,#fff 20px,#E6C5DA 0,#E6C5DA 30px,#fff 0,#fff 40px);
        }
        &:before{
          top: 0;
        }
        &:after{
          bottom: 0;
        }
        .entrance__inquiry{
          // margin-top: rem(90);
          // margin-top: rem(-20);
          .inquiry__item{
            margin-top: rem(70);
          }
          .icon-tel{
            position: absolute;
            left: 30px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          .inquiry__item__inner{
            position: relative;
            border:1px solid #1A1311;
            padding:4rem .5rem 3rem;
            height: 100%;
            &:before,
            &:after{
              content: '';
              display: block;
              position: absolute;
              width:rem(30);
              height:rem(30);
            }
            &:before{
              left: -3px;
              top: -3px;
              border-left:5px solid #1A1311;
              border-top:5px solid #1A1311;
            }
            &:after{
              right: -3px;
              top: -3px;
              border-right:5px solid #1A1311;
              border-top:5px solid #1A1311;
            }
          }
          .inquiry__item__inner-second{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            &:before,
            &:after{
              content: '';
              display: block;
              position: absolute;
              width:rem(30);
              height:rem(30);
            }
            &:before{
              left: -3px;
              bottom: -3px;
              border-left:5px solid #1A1311;
              border-bottom:5px solid #1A1311;
            }
            &:after{
              right: -3px;
              bottom: -3px;
              border-right:5px solid #1A1311;
              border-bottom:5px solid #1A1311;
            }
          }
          .inquiry__icon{
            position: absolute;
            top: 0;
            left: 50%;
            margin: auto;
            transform: translate(-50%,-50%);
            background-color: white;
            display: inline-block;
            padding: 0 2rem;
          }
          .inquiry__head{
            margin-bottom: rem(35);
          }
          .inquiry__description{
            margin-bottom: 3rem;
            font-size: rem-calc(12);
            @include media-breakpoint-up(md) {
              font-size: rem-calc(16);
            }
          }
        }
      }
    }
    &.dormitory{
      .section{
        padding-top: 5rem;
        padding-bottom: 5rem;
      }
      .dormitory__intro{
        font-size: rem-calc(18);
        color:#1A9CCC;
        line-height: lh(44,22);
        margin-bottom: rem(80);
        @include media-breakpoint-up(md) {
          margin-bottom: rem(140);
          line-height: lh(34,18);
          padding-left: 9%;
          font-size: rem-calc(22);
        }
      }
      .dormitory__slide__item{
        text-align: center;
        img{
          max-height:383px;
          margin-left:auto;
          margin-right:auto;
        }
      }
      .page__links{
        margin: rem(90) 0 rem(35);
        @include media-breakpoint-up(md) {
          margin: rem(60) 0 rem(20);
        }
      }
      .section__dormitory{
        .head__wrap{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: rem(100);
          @include media-breakpoint-up(lg) {
            &:before,
            &:after{
              content: '';
              flex-grow: 1;
              height: 15px;
              width: 100%;
              background: repeating-linear-gradient(-45deg,#52A7F0,#52A7F0 2px,#fff 0,#fff 7px);
            }
            &:before{
              margin-right: 1rem;
            }
            &:after{
              margin-left: 1rem;
            }
          }
        }
        .head{
          @include media-breakpoint-up(lg) {
            flex: 1 0 auto;
          }
          @include media-breakpoint-up(md) {
            display: inline-block;
            position: relative;
            padding-left: 6rem;
            padding-right: 6rem;
          }
        }
        .head__item{
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          display: none;
          @include media-breakpoint-up(md) {
            display: inline;
          }
          &.head__item--01{
            left:0;
          }
          &.head__item--02{
            right:0;
          }
        }
      }
      .section__mv{
        margin-bottom: rem(50);
        @include media-breakpoint-up(md) {
          margin-bottom: rem(110);
        }
      }
      .madori__lists{
        font-size: rem(13);
      }
      .slick-dots{
        li{
          margin: 0 1px;
          &:before{
            content: none!important;
          }
          button{
            position: relative;
            &:before{
              font-size: rem-calc(15);
              left:0;
            }
          }
          &.slick-active{
            button{
              &:before{
                color:#FFEC00;
              }
            }
          }
        }
      }
    }
  }
}

// footer link-btn
body.page-btm-link .page-contents{
  margin-bottom: 0px;
}
.link-page-btm{
  margin-top: 4rem;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding:rem(75) 0 rem(100);
  @include media-breakpoint-up(md) {
    padding: 30px;
  }
  &:before{
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: #11A2B4;
  }
  
  // light green
  &.link-page-btm--01::before{
    background-color: #43aa88;
  }
  // orange
  &.link-page-btm--02::before{
    background-color: #FA7402;
  }
  // deep green
  &.link-page-btm--03::before{
    background-color: #43966c;
  }
  .link-page-btm--heading{
    margin-bottom: 1rem;
    padding: 0px 25px;
    position: relative;
    z-index: 1;
    font-size: 1.25rem;
    color: #fff;
    font-weight: bold;
  }
  
  .link-page-btm--heading::before,
  .link-page-btm--heading::after{
      content: "";
      display: block;
      width: 6px;
      height: 1.2rem;
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      left: 6px;
      border-left: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: skew(30deg, 0deg);
  }
  .link-page-btm--heading::after{
      left: auto;
      right: 6px;
      transform: skew(-30deg, 0deg);
  }
  .link-page-btm--btn{
      padding: 20px 0px;
      position: relative;
      z-index: 1;
  }
}
// .link-page-btm::before{
//   // background-image: url('../img/pages/bg-link-page-btm.svg');
// }
// .p-btn.p-btn--lower a{
//     border: 1px solid #fff;
//     box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
// }

.list-merit--wrap{
    padding: 2rem 50px;
    position: relative;
}
.list-merit--wrap::before,
.list-merit--wrap::after{
    content: "";
    display: block;
    position: absolute;
    top: 25%;
    margin-top: -15px;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.list-merit--wrap::before{
    left: 0px;
    width: 55px;
    height: 36px;
}
.list-merit--wrap::after{
    right: 0px;
    width: 60px;
    height: 42px;
}
body.child-page .list-merit--wrap::before{
    background-image: url('../img/pages/hsc/deco-jobpractical-merit-left.svg');
}
body.child-page .list-merit--wrap::after{
    background-image: url('../img/pages/hsc/deco-jobpractical-merit-right.svg');
}