footer{
  background-color: $orange;
  color:#333;
  padding:4rem 0;
  position: relative;
  @include media-breakpoint-down(sm) {
    padding:4rem 0 5rem;
  }
  .footer-link-top{
    position: absolute;
    right: 1.5rem;
    top: -7rem;
    @include media-breakpoint-down(sm) {
      right: 0.5rem;
      top: -5rem;
    }
    a{
      display: block;
      text-align: center;
    }
    .ch{
      @include media-breakpoint-down(sm) {
        width: 60px;
      }
    }
    .txt{
      @include media-breakpoint-down(sm) {
        width: 90px;
      }
    }
    & + .inner{
      @include media-breakpoint-up(md) {
        flex-wrap:nowrap;
      }
    }
  }
  .tel{
    font-size: rem-calc(30);
    @include opacity-href();
    color:$black;
    white-space: nowrap;
  }
  .time{
    font-size: rem-calc(14);
  }
  .container{
    max-width:1200px;
  }
  .sns{
    @include media-breakpoint-up(md) {
      width:33%;
    }
  }
  .sdg{
    @include media-breakpoint-up(md) {
      width:30%;
    }
  }
  .inner{
  
      
      flex-wrap:wrap;
      
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-start;
        flex-wrap:nowrap;
      }
      
  }
  .company__info{
    // margin-top: 2.5rem;
    @include media-breakpoint-up(md) {
      width:37%;
      // display: flex;
      // align-items: center;
      .inner{
        @include media-breakpoint-up(md) {
          flex-wrap:wrap;
          align-items: flex-start;
        }
      }
    }
    address{
      @include media-breakpoint-down(sm) {
        // display: flex;
        // justify-content: center;
      }
    }
    .inner{
      // @include media-breakpoint-up(md) {
      //   display: flex;
      //   align-items: center;
      // }
    }
    @include media-breakpoint-down(sm) {
    }
    .logo{
      // margin-right: 2rem;
      @include media-breakpoint-down(sm) {
        margin: auto;
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }
  .schools{
    margin-top: 1.5rem;
  }
}
body{
  &.child-page{
    footer{
      .schools{
        img{
          border:1px solid white;
        }
      }
      .footer__nav{
        li{
          margin-bottom: 1rem;
        }
        a{
          @include opacity-href();
          color:white;
        }
      }
      .sub__menu{
        list-style: unset;
        padding-left: 1rem;
        li{
          a{
            position: relative;
            &:before{
              content: '';
              display: block;
              position: absolute;
              left: -0.8rem;
              top: 0.5rem;
              margin: auto;
              width:3px;
              height: 3px;
              border-radius: 50%;
              background-color: #fff;
            }
          }
        }
      }
      .footer__aside{
        padding-top:5rem;
        padding-bottom:5rem;
      }
      .ch-yt{
        @include media-breakpoint-up(md) {
          margin-left: auto;
          margin-top: -2rem;
        }
      }
      .ch-yt__head{
        font-size: rem-calc(14);
        position: relative;
        &:after{
          content: '';
          display: block;
          position: absolute;
          left: 100%;
          top: 10px;
          border-bottom: 1px solid #fff;
          width:18px;
          height: 1px;
          transform: rotate(-45deg);
          transform-origin: right center;
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          right: 100%;
          top: 10px;
          border-bottom: 1px solid #fff;
          width:18px;
          height: 1px;
          transform: rotate(45deg);
          transform-origin: left center;
        }
      }
      .ch-label{
        background-color: #fff;
        padding:.1rem 1rem;
      }
    }
    &:not(.parent-group){
      footer{
        padding:0;
      }
    }
  }
  &.hsc{
    footer{
      background-color: #EB6EA5;
      .footer__nav{
        padding:5rem 0;
        background: url(#{$dir}/common/bg_footer01.jpg)no-repeat;
        background-size: cover;
        background-position: center center;
      }
      .ch-label{
        color:#EB6EA5;
      }
    }
  }
  &.htc{
    footer{
      background-color:#FF962C;
      .footer__nav{
        padding:5rem 0;
        background: url(#{$dir}/common/bg_footer02.jpg)no-repeat;
        background-size: cover;
        background-position: center center;
      }
      .ch-label{
        color:#FF962C;
      }
    }
  }
  &.iryo{
    footer{
      background-color:#82C436;
      .footer__nav{
        padding:5rem 0;
        background: url(#{$dir}/common/bg_footer03.jpg)no-repeat;
        background-size: cover;
        background-position: center center;
      }
      .ch-label{
        color:#82C436;
      }
    }
  }
}