body{
    
    &.interview{
        .mv{
            padding: 25px 0 69px;
            position: relative;
            overflow: clip;
            @include media-breakpoint-up(md){
                padding: 53px 0 70px;
            }
            &__bg{
                position: absolute;
                inset: 0;
                background-color: #F4CB35;
                z-index: -2;
                &::after{
                    content: '';
                    display: inline-block;
                    background: url($dir + '/lp/interview/bg-interview-parts01.png') top left/532px 215px repeat;
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                }
            }
           
            &__slider{
                //見切りサイズ
                $size: 1500px;
                @media(min-width:$size){
                    display: flex;
                    justify-content: space-around;
                    align-items: flex-end;
                    column-gap: 35px;
                    width: calc(100% - 100px);
                    margin-inline: auto;
                }
                .slick-list{
                    overflow: visible;
                }
                .slider-item{
                    transform-style: preserve-3d;
                    perspective: 600px;
                    perspective-origin: left top;
                    width: fit-content;
                    margin-inline: 20px;
                    position: relative;
                    max-width: 190px;
                    @include media-breakpoint-up(sm){
                        max-width: 100%;
                    }
                    
                    @media(min-width:$size){
                        min-width: 170px;
                    }
                    @keyframes b{
                        0%{
                            transform: rotate3d(0,1,0,0deg);
                           
                        }
                        50%{
                            transform: rotate3d(0,1,0,-90deg);
                        }
                        100%{
                            transform: rotate3d(0,1,0,-90deg);
                        }
                    }
                    @keyframes b-sp{
                        0%{
                            transform: rotate3d(0,1,0,0deg);
                           
                        }
                        50%{
                            transform: rotate3d(0,1,0,-90deg);
                        }
                        100%{
                            transform: rotate3d(0,1,0,-90deg);
                        }
                    }
                    @keyframes a{
                        0%{
                            transform: rotate3d(0,1,0,90deg);
                        }
                        50%{
                            transform: rotate3d(0,1,0,0deg);
                        }
                        100%{
                            transform: rotate3d(0,1,0,0deg);
                        }
                    }
                    @keyframes s{
                        0%{
                            opacity: 0;
                            transform: translateY(20px);
                        }
                        50%{
                            opacity: 1; 
                        }
                        100%{
                            opacity: 1;
                            transform: translateY(0px);
                        }
                    }
                    //アニメーション設定
                    @mixin currentMove1{
                        img{
                            &.before{
                                
                                // transform: rotate3d(0,1,0,180deg);
                                opacity: 0 !important;
                                animation:b 1.4s cubic-bezier(.42,.23,.27,1.02) forwards ;
                            }
                            &.after{
                                opacity: 1 !important;
                                animation:a 1.6s cubic-bezier(.42,.23,.27,1.02) forwards 0.1s;
                                // visibility: visible !important;
                                // opacity: 1 !important;
                                // transform: scale(1) !important;
                            }
                        }
                        .top::before{
                            
                            transform-origin: bottom center;
                            opacity: 0;
                            animation: s 0.3s cubic-bezier(.2,.7,.72,1.51) 0.6s forwards;
                            
                        }
                    }
                    &:hover{
                        opacity: 1;
                        img{
                            opacity: 1;
                        }
                        // @media (any-hover: hover) and (min-width:1025px) {
                        @media (any-hover: hover){
                            @include currentMove1;
                        }
                    }
                    &.current{
                        // @media (any-hover: none) and (max-width:1024.98px) {
                        @media (any-hover: none){
                            @include currentMove1;
                            img{
                                &.before, &.after{
                                    animation-delay: 1.5s;
                                    transition-delay: 1.5s;
                                }
                            }
                            .top::before{
                                animation-delay: 2.1s;
                                transition-delay: 1.6s;
                            }
                        }
                    }
                    
                    .top{
                        position: relative;
                        z-index: -1;
                        @media(max-width:571.98px){
                            transform: translateY(-80px);
                             
                        }
                        @include media-breakpoint-up(sm){
                            padding-bottom: unquote('max(80px, 7vw)');
                        }
                        @media(min-width: $size){
                            width: 14.7vw;
                        }
                        &::before{
                            content: '';
                            display: inline-block;
                            width: 56px;
                            height: 41px;
                            
                            position: absolute;
                            top: 16%;
                            right: 0;
                            @media(min-width:572px){
                                
                                right: 5%;
                            }

                            
                            @media(min-width:$size){
                                width: 3.7vw;
                                height: auto;
                                aspect-ratio: 56/41;
                            }
                        }
                        
                        
                        img{
                            backface-visibility: hidden;
                            @media(max-width:571.98px){
                                // transform: translateY(-80px);
                                 
                            }
                            @media(min-width:$size){
                                width: 100%;
                            }
                            &.before{
                                position: relative;
                                // transition: transform 0.6s cubic-bezier(.94,.1,.75,.85), opacity 0.6s linear;
                                transition: opacity 0.3s cubic-bezier(.84,.05,.94,.68);
                            }
                            &.after{
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                transform-origin:right center;
                                transition: opacity 0.3s cubic-bezier(.84,.05,.94,.68) 0.1s;
                                transform: rotate3d(0,1,0,90deg);
                                z-index: 2;
                                @media(max-width:571.98px){
                                    top: -30px;
                                } 
                            }

                        }
                    }
                    &.interview--01{
                        .top{
                            &::before{
                                background: url($dir + '/lp/interview/text-interveiw-fukidashi01@2x.png') center/cover no-repeat;
                                @media(min-width:572px){
                                    top: 19%; 

                                }
                            }
                            img{
                                
                                &.after{
                                    @media(max-width:571.98px){
                                        top: -30px;
                                    } 
                                }
                            }
                        }
                        // 個別アニメーション設定
                        @mixin moveIndex1{
                            .top::before{
                                top: 29%; 
                                @media(max-width:571.98px){
                                    top: 25% !important; 
                                }
                            }
                        }
                        &:hover{
                            // @media (any-hover: hover) and (min-width:1025px) {
                            @media (any-hover: hover){
                                @include moveIndex1;
                            }
                        }
                        &.current{
                            // @media (any-hover: none) and (max-width:1024.98px) {
                            @media (any-hover: none){
                                @include moveIndex1;
                            }
                            
                        }
                       
                    }
                    &.interview--02{
                        .top{
                            @media(max-width:571.98px){
                                transform: translateY(-40px);
                            }
                            &::before{
                                background: url($dir + '/lp/interview/text-interveiw-fukidashi02@2x.png') center/cover no-repeat;
                                top: 10%;
                                right:5%;
                               
                            }
                            img{
                                
                                &.after{
                                    @media(max-width:571.98px){
                                        top: -70px;
                                    } 
                                }
                            }
                        }
                        //個別アニメーション設定
                        @mixin moveIndex2{
                            .top::before{
                                top: 29%; 
                                @media(max-width:571.98px){
                                    top: 15% !important; 
                                }
                            }
                        }
                        &:hover{
                            @media (any-hover: hover) {
                                @include moveIndex2;
                            }
                        }
                        &.current{
                            @media (any-hover: none){
                                @include moveIndex2;
                            }
                        }
                    }
                    &.interview--03{
                        .top{
                            @media(max-width:571.98px){
                                transform: translateY(-60px);
                            }
                            &::before{
                                background: url($dir + '/lp/interview/text-interveiw-fukidashi03@2x.png') center/cover no-repeat;
                                top: 19%; 
                                right: 0;
                                
                            }
                            img{
                                
                                &.after{
                                    @media(max-width:571.98px){
                                        top: -50px;
                                    } 
                                }
                            }
                            
                           
                        }
                        //個別アニメーション設定
                        @mixin moveIndex3{
                            .top::before{
                                top: 29%; 
                                @media(max-width:571.98px){
                                    top: 20% !important; 
                                }
                            }
                        }
                        &:hover{
                            // @media (any-hover: hover) and (min-width:1025px) {
                            @media (any-hover: hover){
                                @include moveIndex3;
                            }
                        }
                        &.current{
                            // @media (any-hover: none) and (max-width:1024.98px) {
                            @media (any-hover: none){
                                @include moveIndex3;
                            }
                        }
                    }
                    &.interview--04{
                        .top{
                            @media(max-width:571.98px){
                                transform: translateY(-60px);
                            }
                            &::before{
                                background: url($dir + '/lp/interview/text-interveiw-fukidashi04@2x.png') center/cover no-repeat;
                                right: 5%;
                                @media(min-width:572px){
                                    top: 16%;
                                    right: 12%;

                                } 
                            }
                            img{
                                
                                &.after{
                                    @media(max-width:571.98px){
                                        top: -60px;
                                    } 
                                }
                            }
                            
                           
                        }
                        //個別アニメーション設定
                        @mixin moveIndex4{
                            .top::before{
                                top: 34%; 
                                @media(max-width:571.98px){
                                    top: 26% !important;
                                } 
                            }
                        }
                        &:hover{
                            // @media (any-hover: hover) and(min-width:1025px) {
                            @media (any-hover: hover){
                                @include moveIndex4;
                            }
                        }
                        &.current{
                            // @media (any-hover: none) and (max-width:1024.98px) {
                            @media (any-hover: none){
                                @include moveIndex4;
                            }
                        }
                    }
                    &.interview--05{
                        .top{
                            @media(max-width:571.98px){
                                transform: translateY(-75px);
                            }
                            &::before{
                                background: url($dir + '/lp/interview/text-interveiw-fukidashi05@2x.png') center/cover no-repeat;
                                @media(min-width:572px){
                                    top: 19%; 

                                }
                            }
                            // img{
                                
                            //     &.after{
                            //         @media(max-width:571.98px){
                            //             top: -60px;
                            //         } 
                            //     }
                            // }
                           
                        }
                        //個別アニメーション設定
                        @mixin moveIndex5{
                            .top::before{
                                top: 29%; 
                                @media(max-width:571.98px){
                                    top: 25% !important; 
                                }
                            }
                        }
                        &:hover{
                            // @media (any-hover: hover) and (min-width:1025px) {
                            @media (any-hover: hover){
                                @include moveIndex5;
                            }
                        }
                        &.current{
                            // @media (any-hover: none) and (max-width:1024.98px) {
                            @media (any-hover: none){
                                @include moveIndex5;
                            }
                        }
                    }
                    &.interview--06{
                        .top{
                            @media(max-width:571.98px){
                                transform: translateY(-70px);
                            }
                            &::before{
                                background: url($dir + '/lp/interview/text-interveiw-fukidashi06@2x.png') center/cover no-repeat;
                                @media(min-width:572px){
                                    right: 10%; 

                                }
                            }
                            img{
                                
                                &.after{
                                    @media(max-width:571.98px){
                                        top: -45px;
                                    } 
                                }
                            }
                            
                        }
                        //個別アニメーション設定
                        @mixin moveIndex6{
                            .top::before{
                                top: 34%; 
                                @media(max-width:571.98px){
                                    top: 28% !important; 
                                }
                            }
                        }
                        &:hover{
                            // @media (any-hover: hover) and (min-width:1025px){
                            @media (any-hover: hover){
                                @include moveIndex6;
                            }
                        }
                        &.current{
                            // @media (any-hover: none) and (max-width:1024.98px) {
                            @media (any-hover: none){
                                @include moveIndex6;
                            }
                        }
                    }
                    .bottom{
                        width: 187px;
                        position: absolute;
                        bottom: 20px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        z-index: 3;
                        @include media-breakpoint-up(sm){
                            width: 197px;
                            bottom: 0;
                        }
                        
                        
                        @media(min-width:$size){
                            width: 100%;
                        }
                        img{
                            width: 100%;
                        }
                    }
                    &:nth-of-type(odd){
                        .bottom{
                            transform-origin: top left;
                        }
                    }
                    &:nth-of-type(even){
                        .bottom{
                            transform-origin: top right;
                        }
                    }
                    //アニメション
                    @mixin currentMove2{
                        .bottom{
                            animation: rr 0.8s ease-in-out forwards 0.3s;
                        }
                    }
                    &:hover{
                        // @media (any-hover: hover) and(min-width:1025px) {
                        @media (any-hover: hover){
                            @include currentMove2;
                            
                        }
                    }
                    &.current{
                        // @media (any-hover: none) and(max-width:1024.98px) {
                        @media (any-hover: none){
                            @include currentMove2;
                            .bottom{
                                animation-delay: 1.8s;
                            }
                            
                        }
                    }
                    @keyframes rr{
                        0%{
                           
                          }
                          20%{
                            transform: rotate(4deg);
                          }
                          40%{
                            opacity: 1;
                            transform: rotate(4deg);
                          }
                          60%{
                            transform: rotate(-2deg);
                            animation-direction: alternate;
                          }
                          80%{
                            transform: rotate(2deg);
                            animation-direction: alternate;
                          }
                          100%{
                            opacity: 1;
                            transform: rotate(0deg);
                            animation-direction: alternate;
                          }
                    }
                }
                .slide-arrow{
                    background-color: transparent;
                    border: none;
                    width: 50px;
                    height: 50px;
                    background: url($dir + '/lp/interview/img-interview-btn.png') center/cover no-repeat ;
                    position: absolute;
                    top: 30%;
                    z-index: 5;
                    transition: opacity 0.3s linear;
                    &.prev-arrow,&.next-arrow{

                        @include media-breakpoint-up(sm){
                            left: 0;
                            right: 0;
                            margin-inline: auto;
                        }
                        @include media-breakpoint-up(lg){
                            margin-inline: 0;
                            top: 0;
                            left: auto;
                        }
                        &:focus{
                            border-radius: 1000px;
                        }
                    }
                    &.prev-arrow{
                        left: 15%;
                        
                        @include media-breakpoint-up(sm){
                            translate: -160px 0;
                        }
                        // left: 32%;
                        @include media-breakpoint-up(lg){
                            translate: 0;
                            right: 200px;
                        }
                       
                    }
                    &.next-arrow{
                        right: 15%;
                        // right: 32%;
                        transform: rotate(180deg);
                        @include media-breakpoint-up(sm){
                            translate: 160px 0;
                        }
                        
                        @include media-breakpoint-up(lg){
                            translate: 0;
                            right: 100px;
                            
                        }
                    }
                    
                }
                &:not(.first){
                    .slide-arrow{
                        opacity: 1;

                    }
                }

            }
        }
        .individual{
            position: relative;
            padding: 53px 0 100px;
            
            @include media-breakpoint-up(lg){
                padding: 120px 0 180px;
            }
            &__container{
                @include media-breakpoint-up(xl){
                    max-width: 1140px !important;
                }
            }
            &__bg{
                position: absolute;
                inset: 0;
                background: url($dir + '/lp/interview/bg-interview-individual.png') top left/contain repeat;
                z-index: -1;
            }
            &__content{
                @include media-breakpoint-up(md){
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 16px 20px;
                }
                @include media-breakpoint-up(xl){
                    gap: 36px 30px;
                }
            }
            &__item{
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 0 30px rgba(110,89,21,.16);
                padding: 26px 15px 40px;
                position: relative;
                @include media-breakpoint-up(md){
                    align-self: self-start;
                }
                @include media-breakpoint-up(lg){
                    border-radius: 20px;
                    padding: 55px 40px 50px;
                }
                .category{
                    position: absolute;
                    top: 16px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
                &.hsc{
                    --department-color:#D85691;
                    .category{
                        background: url($dir + '/lp/interview/text-interview-kitabi.svg') center/cover no-repeat;
                        width: 58%;
                        aspect-ratio: 214/45;
                        @include media-breakpoint-up(lg){
                            max-width: 317px;
                            top: 32px;
                        }
                    }
                    .student{
                        .person{
                            @include media-breakpoint-up(lg){
                                width: 43%;
                                padding-right: 10px;
                            }
                        }
                        .text{
                            @include media-breakpoint-up(lg){
                                margin-left: -10px;
                                width: 57%;
                            }
                            @include media-breakpoint-up(xl){
                                margin-left: -20px;
                            }

                            .d-range-none{
                                @include media-breakpoint-up(md){
                                    display: none;
                                }
                                @include media-breakpoint-up(lg){
                                    display: block;
                                }
                                @include media-breakpoint-up(xl){
                                    display: none;
                                }
                            }
                        }
                    }
                }
                &.htc{
                    --department-color:#EE8122;
                    .category{
                        background: url($dir + '/lp/interview/text-interview-kitatech.svg') center/cover no-repeat;
                        width: 96%;
                        aspect-ratio: 334/45;
                        @include media-breakpoint-up(lg){
                            max-width: 529px;
                            top: 21px;
                        }
                    }
                    &.course{
                        --department-color:#A57E58;
                    }
                }
                &.iryo{
                    
                    .category{
                        background: url($dir + '/lp/interview/text-interview-kitawel.svg') center/cover no-repeat; 
                        width: 88%;
                        aspect-ratio: 325/46;
                        @include media-breakpoint-up(lg){
                            max-width: 483px;
                            top: 32px;
                            aspect-ratio: 325/45;
                        }
                        // @-moz-document url-prefix() {
                        //     aspect-ratio: 325/46;
                        // }
                    }
                    &.kaigo{
                        --department-color:#5692C9;
                    }
                    &.kodomo{
                        --department-color:#EB817F;
                    }
                    &.yakugyo{
                        --department-color:#6EB57E;
                    }
                }
                .top{
                   
                    .student{
                        position: relative;
                        z-index: 1;
                        @include media-breakpoint-up(xl){
                            column-gap: 20px;
                        }
                        .person{
                            width: 47%;
                            img{
                                @include media-breakpoint-up(lg){
                                    max-width: 188px;
                                }
                            }
                        }
                        .text{
                            width: 53%;
                            margin-bottom: 40px;
                            
                        }
                        .department {
                            display: inline;
                            font-weight: 700;
                            color: #fff;
                            padding: 2px 5px;
                            line-height: 2;
                            background-color: var(--department-color);
                            box-decoration-break: clone;
	                        -webkit-box-decoration-break: clone;
                            @include media-breakpoint-up(lg){
                                font-size: rem(16);
                                
                            }
                        }
                        .name{
                            font-size: rem(16);
                            font-weight: 700;
                            @include media-breakpoint-up(lg){
                                font-size: rem(20);
                                margin-top: 6px;
                            }
                            .sml{
                                font-size: rem(11);
                                font-weight: 400;
                                @include media-breakpoint-up(lg){
                                    font-size: rem(13);
                                }
                            }
                        }
                    }
                }
                .item-content{
                   
                    .q-title {
                        font-size: rem(14);
                        font-weight: 700;
                        column-gap: 8px;
                        margin-bottom: 12px;
                        @include media-breakpoint-up(lg){
                            font-size: rem(16); 
                        }
                        .q-icon{
                            font-size: rem(13);
                            
                            color: #fff;
                            background-color: var(--department-color);
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            border-radius: 1000px;
                            @include media-breakpoint-up(lg){
                                font-size: rem(16); 
                                width: 34px;
                                height: 34px;
                            }
                        }
                        .text{
                            
                        }
                    }
                    .a-title{
                        color: var(--department-color);
                        font-weight: 700;
                        font-size: rem(16);
                        padding-bottom: 6px;
                        border-bottom: 1px solid #A3A3A3;
                        margin-bottom: 12px;
                        @include media-breakpoint-up(lg){
                            font-size: rem(20);
                        }
                    }
                    .state-img {
                        margin-bottom: 60px;
                        img{     
                            border-radius: 5px;
                            @include media-breakpoint-up(lg){
                                border-radius: 10px;
                            }
                        }
                    }
                    .desc-text{
                        letter-spacing: ls(80);
                        line-height: calc(24 / 14);
                        margin-bottom: 20px;
                        @include media-breakpoint-up(lg){
                            font-size: rem(16);
                            margin-bottom: 30px;
                        }
                    }
                    .p-btn{
                        .txt{
                            width: 278px;
                            min-width: initial;
                            border-width: 1px 0 1px 1px;
                            font-weight: 400;
                            box-shadow: 0 3px 6px rgba(0,0,0,.16);
                            
                        }
                        span{
                            &::after{
                                width: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}