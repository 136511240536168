// px to rem
$font-size-root: 16 !default;

@function rem($px) {
	@return ($px / $font-size-root) + rem;
}

// Foundation sites ver.
@function rem-calc($px) {
	@return rem($px);
}

@function per($target,$wrap){
	@return percentage($target / $wrap);
}

@function ls($space){
	@return ($space / 1000) + em;
}
@function lh($lineHeight,$fontSize){
	@return $lineHeight / $fontSize;
}
@function f-min($auto,$max){
	
	@return unquote('min('+ $auto + ',' +  $max + ')'); 
	  
}